<template>
  <button @click="dialog = true" class="open-button">
    （ <span class="underline">{{ props.stores.length }}店舗</span> ）
  </button>
  <v-dialog v-model="dialog" class="dialog">
    <div class="contents">
      <header>
        <div class="title">この条件に含まれる店舗一覧</div>
        <div class="store-count">{{ props.stores.length }}店舗</div>
      </header>
      <table>
        <thead>
          <tr>
            <th>都道府県</th>
            <th>店舗名</th>
            <th>住所</th>
          </tr>
        </thead>
        <tbody>
          <tr :key="store.storeId" v-for="store in sortedStores">
            <th>{{ prefectureMap[store.prefectureId]['name'] }}</th>
            <th>{{ store.name }}</th>
            <th>{{ store.address }}</th>
          </tr>
        </tbody>
      </table>
    </div>
  </v-dialog>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import { Store } from '@/commons/interfaces/responses/store'
import { useStore } from 'vuex'
import { Prefecture } from '@/features/Dashboard/types'

const store = useStore()

const props = withDefaults(
  defineProps<{
    stores: Store[]
  }>(),
  {
    stores: () => []
  }
)

const dialog = ref<boolean>(false)

const sortedStores = computed<Store[]>(() =>
  [...props.stores].sort((a, b) => a.prefectureId - b.prefectureId)
)

const prefectureMap = computed<{ [key: number]: Prefecture }>(() => store.getters.prefectureMap)
</script>

<style lang="scss" scoped>
.open-button {
  font: normal normal normal 13px/40px Noto Sans JP;
  letter-spacing: 0px;
  color: #333333;
}

.dialog {
  width: 864px;
  max-height: 797px;

  .contents {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24.5px;
    padding: 35px 40px;
    width: 864px;
    max-height: 797px;
    border-radius: 6px;
    background: white;
    overflow-y: scroll;

    header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .title {
        text-align: left;
        font: normal normal bold 15px/28px Noto Sans JP;
        letter-spacing: 0px;
        color: #222222;
      }

      .store-count {
        font: normal normal normal 15px/40px Noto Sans JP;
        letter-spacing: 0px;
        color: #999999;
      }
    }

    table {
      border: 1px solid #cccccc;
      border-collapse: collapse;
      width: 100%;

      thead {
        background: #f5f5f5 0% 0% no-repeat padding-box;
        letter-spacing: 0px;
        color: #222222;

        tr th {
          font: normal normal bold 12px/50px Noto Sans JP;
        }
      }

      tbody {
        background: #ffffff 0% 0% no-repeat padding-box;
        letter-spacing: 0px;
        color: #222222;

        tr th {
          font: normal normal normal 12px/50px Noto Sans JP;
        }
      }

      tr th {
        border: 1px solid #cccccc;
        padding: 0 12px;
      }

      tr th:first-child {
        text-align: center;
      }

      tr th:nth-child(2) {
        text-align: left;
      }

      tr th:nth-child(3) {
        text-align: left;
      }
    }
  }
}

.underline {
  text-decoration: underline;
}
</style>
