<template>
  <div class="favorite-edit">
    <div class="side-bar">
      <div class="heading">
        <div>条件の作成</div>
        <v-divider class="mx-2" />
      </div>
      <div class="nav">
        <router-link
          :to="{ name: 'FavoriteList' }"
          class="nav-link"
        >
          一覧に戻る
        </router-link>
      </div>
    </div>
    <div class="main">
      <div class="edit-card">
        <div class="name">
          <div class="wrap">
            <CustomInput
              v-model="name"
              variant="borderless"
              width="530px"
              height="26px"
              class="name-input"
            />
            <span class="count">
              <span :class="{ 'error-color': name.length > 30 }">
                {{ name.length }}
              </span>
              / 30
            </span>
          </div>
          <hr :class="{ error: name.length > 30 }">
        </div>
        <div class="meta">
          <p>[最終更新者] {{ updatedBy }}</p>
          <p>
            [最終更新日時] {{ convertDateAndTimeSlashDelimiter(updatedAt) }}
          </p>
          <p>[最終更新内容] {{ updateNote }}</p>
        </div>
      </div>

      <article class="result-card">
        <header>
          <h1>表示中の条件</h1>
          <button
            class="update"
            @click="conditionDialog = true"
          >
            <img
              class="update-img"
              src="@/assets/svg/cached.svg"
            >
            条件を変更
          </button>
        </header>
        <FavoriteConditionCard :favorite="selectedFavorite" />

        <header>
          <h1>店舗一覧</h1>
          <span class="count">
            {{ filteredStores.length }} 店舗 見つかりました
          </span>
        </header>
        <FavoriteStoreTable :stores="filteredStores" />

        <div class="button-wrap">
          <div class="delete">
            <DeleteButton @click="deleteDialog = true" />
            <span>この条件を削除</span>
          </div>
          <CustomButton
            variant="primary"
            size="md"
            width="360px"
            height="46px"
            :disabled="
              filteredStores.length === 0 ||
                name.length === 0 ||
                name.length > 30
            "
            @click="editDialog = true"
          >
            条件を更新
          </CustomButton>
          <span class="space" />
        </div>
      </article>
    </div>
    <FavoriteConditionDialog
      v-if="selectedFavorite"
      v-model="conditionDialog"
      :favorite="selectedFavorite"
      @click-search="clickSearch($event)"
    />
    <EditDialog
      v-model="editDialog"
      type="favorite"
      :loading="loading"
      @click-edit="clickEdit($event)"
    />
    <DeleteDialog
      v-if="selectedFavorite"
      v-model="deleteDialog"
      type="favorite"
      :targets="[selectedFavorite.name!]"
      :loading="loading"
      @click-delete="clickDelete"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, computed } from "vue";
import FavoriteStoreTable from "@/features/Dashboard/components/FavoriteStoreTable.vue";
import FavoriteConditionCard from "@/features/Dashboard/components/FavoriteConditionCard.vue";
import FavoriteConditionDialog from "@/features/Dashboard/components/FavoriteConditionDialog.vue";
import DeleteButton from "@/features/Dashboard/components/DeleteButton.vue";
import EditDialog from "@/features/Dashboard/components/EditDialog.vue";
import DeleteDialog from "@/features/Dashboard/components/DeleteDialog.vue";
import { Favorite } from "@/features/Dashboard/types";
import { Store } from "@/commons/interfaces/responses/store";
import { convertDateAndTimeSlashDelimiter as convertDateAndTimeSlashDelimiterUtil } from "@/commons/utils/dateUtil";
import { filterStoresByFavorite } from "@/features/Dashboard/utils";
import {
  deleteStoresFavorites,
  getStoresFavorites,
  putStoresFavorites,
} from "@/features/Dashboard/axios";
import * as notify from "@/plugins/notification";
import { useStore } from "vuex";
import { useRouter, useRoute } from 'vue-router';


export default defineComponent({
  name: "FavoriteEdit",
  components: {
    FavoriteStoreTable,
    FavoriteConditionCard,
    FavoriteConditionDialog,
    DeleteButton,
    EditDialog,
    DeleteDialog,
  },
  props: {
    favorite: {
      type: Object as PropType<Favorite>,
      default: undefined,
    },
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const loading = ref(false);
    const name = ref("");
    const updatedBy = ref("");
    const updatedAt = ref("");
    const updateNote = ref("");
    const selectedFavorite = ref<Favorite>();
    const conditionDialog = ref(false);
    const editDialog = ref(false);
    const deleteDialog = ref(false);

    const filteredStores = computed((): Store[] => {
      if (!selectedFavorite.value) return [];
      return filterStoresByFavorite({
        stores: store.state.stores,
        favorite: selectedFavorite.value,
      });
    });
    const created = async() => {    
      loading.value = true;
      if (props.favorite) {
        selectedFavorite.value = props.favorite;
        name.value = props.favorite.name ?? "";
        updatedBy.value = props.favorite.updated_by ?? "";
        updatedAt.value = props.favorite.updated_at ?? "";
        updateNote.value = props.favorite.update_note ?? "";
      }
      // 店舗一覧
      if (!store.state.stores.length)
        await store.dispatch("fetchStores");
      // 選択可能な店舗形態一覧
      if (!store.state.storeTypes.length)
        await store.dispatch("fetchStoreTypes");
      // 都道府県一覧
      if (!store.state.prefectures.length)
        await store.dispatch("fetchPrefectures");
      // 市区町村一覧
      if (!store.state.cities.length)
        await store.dispatch("fetchCities");
      // 直接編集ページにアクセスした際
      if (route.params.id && !selectedFavorite.value) {
        await getStoresFavorites()
          .then((res) => {
            const f = res.data.favorites.find(
              (favorite) => favorite.id === route.params.id
            );
            if (f) {
              selectedFavorite.value = f;
              name.value = f?.name ?? "";
              updatedBy.value = f?.updated_by ?? "";
              updatedAt.value = f?.updated_at ?? "";
              updateNote.value = f?.update_note ?? "";
            } else {
              router.push({ name: "NotFound" });
            }
          })
          .catch(() => {
            notify.notifyErrorMessage("お気に入り条件の取得に失敗しました。");
          });
      }
      loading.value = false;
    };
    const convertDateAndTimeSlashDelimiter = (date: string) => {
      return date && convertDateAndTimeSlashDelimiterUtil(date);
    };
    const clickSearch = (event: { favorite: Favorite }) => {
      selectedFavorite.value = event.favorite;
      conditionDialog.value = false;
    };
    const clickEdit = async(event: { updateNote: string }) => {
      loading.value = true;
      await putStoresFavorites({
        id: route.params.id as string,
        name: name.value,
        prefectureIds: selectedFavorite.value?.prefectureIds ?? [],
        cityIds: selectedFavorite.value?.cityIds ?? [],
        chainWords: selectedFavorite.value?.chainWords ?? [],
        storeTypes: selectedFavorite.value?.storeTypes ?? [],
        storeNameWords: selectedFavorite.value?.storeNameWords ?? [],
        update_note: event.updateNote,
      })
        .then(() => {
          router.push({ name: "FavoriteList" });
        })
        .catch(() => {
          notify.notifyErrorMessage("お気に入り条件が作成できませんでした。");
        });
      loading.value = false;
    };
    const clickDelete = async() => {
      loading.value = true;
      await deleteStoresFavorites({
        favoritesIds: [route.params.id as string],
      })
        .then(() => {
          router.push({ name: "FavoriteList" });
        })
        .catch(() => {
          notify.notifyErrorMessage("お気に入り条件が作成できませんでした。");
        });
      loading.value = false;
    };
    created();

    return {
      loading,
      name,
      updatedBy,
      updatedAt,
      updateNote,
      selectedFavorite,
      conditionDialog,
      editDialog,
      deleteDialog,      
      filteredStores,
      convertDateAndTimeSlashDelimiter,
      clickSearch,
      clickEdit,
      clickDelete,
    };
  },
});
</script>

<style lang="scss" scoped>
.favorite-edit {
  display: flex;
  margin-top: 32.5px;

  .side-bar {
    display: flex;
    flex-direction: column;
    min-width: 206.5px;
    height: 80px;

    .heading {
      display: flex;
      align-items: center;
      width: 100%;
      height: 35px;
      font-size: 14px;
      color: #888;
    }

    .nav {
      margin-top: auto;
      text-align: left;

      .nav-link {
        text-decoration: none;
        font-size: 14px;
        color: #222222;
      }
    }
  }

  .main {
    margin-left: 33.5px;
    width: 100%;

    .edit-card {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      padding: 23px 25px;
      height: 170px;
      background-color: #ffffff;
      box-shadow: 0px 0px 3px #00000029;

      .name {
        max-width: 600px;
        .wrap {
          display: flex;
          .name-input {
            font-size: 18px;
            font-weight: bold;
            color: #222222;
          }
          .count {
            margin-left: auto;
          }
          .error-color {
            color: #be0000;
          }
        }

        hr {
          margin-top: 7px;
          margin-bottom: 20px;
          border-top: 1px solid #cccccc;
        }

        .error {
          border-top: 1px solid #bc0711;
        }
      }

      .meta {
        p {
          margin-bottom: 4px;
          font-size: 13px;
          text-align: left;
          color: #666666;
        }
      }
    }

    .result-card {
      display: flex;
      flex-direction: column;
      padding: 30px;
      background-color: #ffffff;
      box-shadow: 0px 0px 3px #00000029;

      header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        h1 {
          margin: 0;
          font-size: 15px;
          font-weight: bold;
          color: #222222;
        }

        .update {
          display: flex;
          align-items: center;

          text-align: right;
          font: normal normal normal 14px/28px Noto Sans JP;
          letter-spacing: 0px;
          color: #4d99d0;

          .update-img {
            margin-right: 5px;
          }
        }

        .count {
          font-size: 15px;
          font-weight: bold;
          color: #ebac00;
        }
      }
    }

    .button-wrap {
      display: flex;
      justify-content: space-between;
      margin-top: 36px;

      .delete {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 156px;
        height: 46px;

        span {
          font-size: 15px;
          color: #666666;
        }
      }

      .space {
        width: 156px;
        height: 46px;
      }
    }
  }

  .ml-10px {
    margin-left: 10px;
  }

  .mrb-8px {
    margin-right: 8px;
    margin-bottom: 7px;
  }

  .mb-69px {
    margin-bottom: 69px;
  }

  .justify-center {
    justify-content: center;
  }

  .justify-between {
    justify-content: space-between;
  }
}
</style>
