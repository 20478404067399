<template>
  <v-container class="pa-0" fluid>
    <v-row class="title-container" dense no-gutters>
      <v-col style="display: flex; align-items: center">
        <h1 class="title-text">来店特性</h1>
        <UpdateBadge type="month" style="margin-left: 15px" />
      </v-col>
    </v-row>
    <v-row dense no-gutters class="card-container">
      <v-col>
        <ChainSelector
          :initial-chain-id="chainId"
          :initial-prefecture-ids="prefectureIds.map((id) => Number(id))"
          :handle-update="
            (chain, prefectureIds) =>
              setParams({
                chainId: chain.id,
                prefectureIds: prefectureIds.map((id) => id.toString())
              })
          "
        />
      </v-col>
    </v-row>
    <v-row dense no-gutters class="card-container">
      <v-col>
        <v-card height="'650px'" class="card">
          <v-card-title class="pa-0 card-title d-flex align-center">
            <span>来店頻度</span>
            <AlertTooltip
              v-if="frequencyChartAlert"
              class="ml-2"
              text="取得データボリュームが少なく、統計上の信頼性の低いデータが含まれています。該当箇所は、参考値としてご参照ください。"
            />
            <v-spacer />
            <ChartDescriptionTooltip
              menu-key="analytics"
              sub-menu-key="visitEngagement"
              chart-key="visitFrequency"
              class="mr-27px"
            />
            <DownloadButton
              label="CSVデータ"
              :disabled="!chainId"
              :get-file-id="() => downloadVisitFrequencyChart(chainId, prefectureIds)"
              :csv-name="`チェーン分析_来店頻度_${getStartMonthOf13Month()}-${getEndMonthOf13Month()}`"
            />
          </v-card-title>
          <v-container class="pa-0" fluid>
            <v-row>
              <v-col style="position: relative">
                <div v-show="!chainId" class="unselected_card">
                  チェーンを選択するとチャートが表示されます。
                </div>
                <div v-show="chainId">
                  <LoadingImg v-if="frequencyChartLoading" :height="'550px'" />
                  <NoDataChart v-else-if="frequencyChartData.length <= 1" />
                  <ColumnChart
                    v-else
                    :chart-data="frequencyChartData"
                    is-stacked="percent"
                    :legends="frequencyChartLegends"
                    :is-alert="frequencyChartAlert"
                  />
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-row dense no-gutters class="card-container">
      <v-col>
        <v-card height="'650px'" class="card">
          <v-card-title class="pa-0 card-title d-flex align-center">
            <span>エンゲージメント</span>
            <AlertTooltip
              v-if="engagementChartAlert"
              class="ml-2"
              text="取得データボリュームが少なく、統計上の信頼性の低いデータが含まれています。該当箇所は、参考値としてご参照ください。"
            />
            <v-spacer />
            <ChartDescriptionTooltip
              menu-key="analytics"
              sub-menu-key="visitEngagement"
              chart-key="engagement"
              class="mr-27px"
            />
            <DownloadButton
              label="CSVデータ"
              :disabled="!chainId"
              :get-file-id="() => downloadEngagementChart(chainId, prefectureIds)"
              :csv-name="`チェーン分析_エンゲージメント_${getStartMonthOf13Month()}-${getEndMonthOf13Month()}`"
            />
          </v-card-title>
          <v-container class="pa-0" fluid>
            <v-row>
              <v-col style="position: relative">
                <div v-show="!chainId" class="unselected_card">
                  チェーンを選択するとチャートが表示されます。
                </div>
                <div v-show="chainId">
                  <LoadingImg v-if="engagementChartLoading" :height="'550px'" />
                  <NoDataChart v-else-if="engagementChartData.length <= 1" />
                  <ColumnChart
                    v-else
                    :chart-data="engagementChartData"
                    is-stacked="percent"
                    :legends="engagementChartLegends"
                    :is-alert="engagementChartAlert"
                  />
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-row dense no-gutters class="card-container mb-0">
      <v-col>
        <v-card height="'650px'" class="card">
          <v-card-title class="pa-0 card-title d-flex align-center">
            <span>コホート分析</span>
            <AlertTooltip
              v-if="cohortChartAlert"
              class="ml-2"
              text="取得データボリュームが少なく、統計上の信頼性の低いデータが含まれています。該当箇所は、参考値としてご参照ください。"
            />
            <v-spacer />
            <ChartDescriptionTooltip
              menu-key="analytics"
              sub-menu-key="visitEngagement"
              chart-key="cohort"
              class="mr-27px"
            />
            <DownloadButton
              label="CSVデータ"
              :disabled="!chainId"
              :get-file-id="() => downloadCohortAnalysisChart(chainId, prefectureIds)"
              :csv-name="`チェーン分析_コホート分析_${getStartMonthOf13Month()}-${getEndMonthOf13Month()}`"
            />
          </v-card-title>
          <v-container class="pa-0" fluid>
            <v-row>
              <v-col style="position: relative">
                <div v-show="!chainId" class="unselected_card">
                  チェーンを選択するとテーブルが表示されます。
                </div>
                <div v-show="chainId">
                  <LoadingImg v-if="cohortChartLoading" :height="'550px'" />
                  <NoDataChart v-else-if="isNoDataInCohortChart" />
                  <CohortTable v-else :items="cohortChartData" :is-alert="cohortChartAlert" />
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import ChainSelector from '@/commons/components/ChainSelector/ChainSelector.vue'
import UpdateBadge from '@/commons/components/Elements/UpdateBadge.vue'
import { COLOR } from '@/commons/enums'
import useChainQueryParams from '@/commons/hooks/use-chain-query-params'
import {
  CohortResponse,
  EngagementResponse,
  FrequencyResponse
} from '@/features/ShopAnalytics/interfaces/response'
import {
  processCohortTableData,
  processEngagementChartData,
  processFrequencyChartData
} from '@/features/ShopAnalytics/utils/visitPattern'
import { computed, ref, watch } from 'vue'
import { getStartMonthOf13Month, getEndMonthOf13Month } from '@/commons/utils/dateUtil'
import LoadingImg from '@/commons/components/loadingImg.vue'
import NoDataChart from '@/features/ShopAnalytics/components/Common/noDataChart.vue'
import ColumnChart from '@/features/ShopAnalytics/components/Common/columnChart.vue'
import {
  getVisitFrequencyChart,
  downloadVisitFrequencyChart,
  getEngagementChart,
  downloadEngagementChart,
  getCohortAnalysisChart,
  downloadCohortAnalysisChart
} from '@/features/ChainAnalytics/visitPattern/axios'
import * as notify from '@/plugins/notification'
import AlertTooltip from '@/commons/components/Elements/AlertTooltip.vue'
import ChartDescriptionTooltip from '@/commons/components/Elements/ChartDescriptionTooltip.vue'
import DownloadButton from '@/commons/components/Elements/DownloadButton.vue'
import CohortTable from '@/features/ShopAnalytics/components/VisitEngagement/cohortTable.vue'
import { visitPatternTableItem } from '@/features/ShopAnalytics/interfaces/component'

/* --------------------------------------------------------------------------
  選択済みチェーン・都道府県
 ---------------------------------------------------------------------------*/

const { chainId, prefectureIds, setParams } = useChainQueryParams()

/* --------------------------------------------------------------------------
  来店頻度
 ---------------------------------------------------------------------------*/

const frequencyChartLoading = ref<boolean>(false)
const frequencyChartResponse = ref<FrequencyResponse | undefined>(undefined)
const frequencyChartData = computed<(string | number)[][]>(() => {
  if (!frequencyChartResponse.value) return []
  return processFrequencyChartData(frequencyChartResponse.value)
})
const frequencyChartAlert = computed<boolean>(() => {
  if (!frequencyChartResponse.value) return false
  return frequencyChartResponse.value.visitCounts.some(({ isAlert }) => isAlert)
})
const frequencyChartLegends: { text: string; color: string }[] = [
  { text: '月1回', color: COLOR.RED },
  { text: '月2回', color: COLOR.BLUE },
  { text: '月3回', color: COLOR.GREEN },
  { text: '月4回以上', color: COLOR.ORANGE },
  { text: '月8回以上', color: COLOR.PURPLE },
  { text: '参考値（データ不十分）', color: COLOR.GRAY }
]
const fetchFrequencyChart = async () => {
  if (frequencyChartLoading.value) return

  try {
    frequencyChartLoading.value = true
    frequencyChartResponse.value = await getVisitFrequencyChart(
      chainId.value,
      prefectureIds.value
    ).then((res) => res.data)
  } catch {
    notify.notifyErrorMessage('来店頻度が表示できませんでした。')
  } finally {
    frequencyChartLoading.value = false
  }
}

/* --------------------------------------------------------------------------
  エンゲージメント
 ---------------------------------------------------------------------------*/

const engagementChartLoading = ref<boolean>(false)
const engagementChartResponse = ref<EngagementResponse | undefined>(undefined)
const engagementChartData = computed<(string | number)[][]>(() => {
  if (!engagementChartResponse.value) return []
  return processEngagementChartData(engagementChartResponse.value)
})
const engagementChartAlert = computed<boolean>(() => {
  if (!engagementChartResponse.value) return false
  return engagementChartResponse.value.visitCounts.some(({ isAlert }) => isAlert)
})
const engagementChartLegends: { text: string; color: string }[] = [
  { text: '新規', color: COLOR.RED },
  { text: '継続', color: COLOR.BLUE },
  { text: '復活', color: COLOR.GREEN },
  { text: '離反（当月）', color: COLOR.ORANGE },
  { text: '離反（先月）', color: COLOR.PURPLE },
  { text: '参考値（データ不十分）', color: COLOR.GRAY }
]
const fetchEngagementChart = async () => {
  if (engagementChartLoading.value) return

  try {
    engagementChartLoading.value = true
    engagementChartResponse.value = await getEngagementChart(
      chainId.value,
      prefectureIds.value
    ).then((res) => res.data)
  } catch {
    notify.notifyErrorMessage('エンゲージメントが表示できませんでした。')
  } finally {
    engagementChartLoading.value = false
  }
}

/* --------------------------------------------------------------------------
  コホート分析
 ---------------------------------------------------------------------------*/

const cohortChartLoading = ref<boolean>(false)
const cohortChartResponse = ref<CohortResponse | undefined>(undefined)
const cohortChartData = computed<visitPatternTableItem[]>(() => {
  if (!cohortChartResponse.value) return []
  return processCohortTableData(cohortChartResponse.value)
})
const cohortChartAlert = computed<boolean>(() => {
  if (!cohortChartResponse.value) return false
  return cohortChartResponse.value.visitCounts.some(({ isAlert }) => isAlert)
})
const isNoDataInCohortChart = computed<boolean>(
  () =>
    !cohortChartResponse.value ||
    cohortChartResponse.value.visitCounts.length === 0 ||
    cohortChartResponse.value?.visitCounts.every(({ visitorCohort }) =>
      visitorCohort.every(({ visitor }) => !visitor)
    )
)
const fetchCohortChart = async () => {
  if (cohortChartLoading.value) return

  try {
    cohortChartLoading.value = true
    cohortChartResponse.value = await getCohortAnalysisChart(
      chainId.value,
      prefectureIds.value
    ).then((res) => res.data)
  } catch {
    notify.notifyErrorMessage('コホート分析が表示できませんでした。')
  } finally {
    cohortChartLoading.value = false
  }
}

/* --------------------------------------------------------------------------
  created
 ---------------------------------------------------------------------------*/

if (chainId.value) {
  Promise.all([fetchFrequencyChart(), fetchEngagementChart(), fetchCohortChart()])
}

/* --------------------------------------------------------------------------
  watch
 ---------------------------------------------------------------------------*/

watch([chainId, prefectureIds], () => {
  if (chainId.value) {
    Promise.all([fetchFrequencyChart(), fetchEngagementChart(), fetchCohortChart()])
  }
})
</script>

<style lang="scss" scoped>
.mr-27px {
  margin-right: 27px;
}
</style>
