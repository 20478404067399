import { City } from '@/features/Dashboard/types'

/**
 * 市区町村IDから市区町村名を取得するためのマッピングオブジェクトを返す関数
 *
 * **Usage**
 *
 * ```ts
 * const cities = [
 *   { name: "河東郡音更町", cityId: "01-631", prefectureId: 1 },
 *   { name: "河東郡士幌町", cityId: "01-632", prefectureId: 1 },
 *   // ...
 * ]
 *
 * const cityMap = getCityMap(cities)
 *
 * console.log(cityMap)           // { "01-631": { name: "河東郡音更町", cityId: "01-631", prefectureId: 1 }, "01-632": { name: "河東郡士幌町", cityId: "01-632", prefectureId: 1 }, ... }
 * console.lop(cityMap["01-631"]) // { name: "河東郡音更町", cityId: "01-631", prefectureId: 1 }
 * console.lop(cityMap["01-632"]) // { name: "河東郡士幌町", cityId: "01-632", prefectureId: 1 }
 * ```
 *
 * **Details**
 *
 * この関数は、市区町村のデータを含む配列から、市区町村IDをキーとし、対応する市区町村名を値とするオブジェクトマップを生成します。市区町村データは`{ cityId: string, name: string, prefectureId: number }`の形式であり、`cityId`は市区町村の一意の識別子、`name`は市区町村の名前です。このマップを使用することで、市区町村IDから直接、市区町村名を簡単に参照できます。
 *
 */
export function getCityMap(cities: City[]): { [key: string]: City } {
  const cityMap = cities.reduce<{ [key: string]: City }>((acc, city) => {
    acc[city.cityId] = city
    return acc
  }, {})

  return cityMap
}
