import {
  ChainCompareChartResponse,
  FrequencyResponse,
  EngagementResponse,
} from '@/features/ChainCompare/visitPattern/interface/response'
import { convertMonthlySlashDelimiter } from "@/commons/utils/dateUtil";

const getTooltipHTML = (
  granularity: string,
  startDate: string,
  endDate: string,
  label: string,
  labelTitle: string,
  value: string
) => {
  return `<div style='display: flex; justify-content: center; align-items: center; height: 90px; color: #222222; font-size: 12px !important;'>
            <div style='display:table-cell; vertical-align:middle; text-align: left;'>
              <div>${granularity}</div>
              <div>[期間] <b>＜${startDate} - ${endDate}＞</b></div>
              <div>[${labelTitle}] <b>${label}</b></div>
              <div>[構成比] <b>${value}</b></div>
            </div>
          </div>`;
};

const getEmptyTooltipHTML = (
  granularity: string,
  startDate: string,
  endDate: string,
  label: string,
  labelTitle: string
) => {
  return `<div style='display: flex; justify-content: center; align-items: center; height: 90px; color: #222222; font-size: 12px !important;'>
            <div style='display:table-cell; vertical-align:middle; text-align: left;'>
              <div>${granularity}</div>
              <div>[期間] <b>＜${startDate} - ${endDate}＞</b></div>
              <div>[${labelTitle}] <b>${label}</b></div>
            </div>
          </div>`;
};

const getSectionColor = (isAlert: boolean) => {
  return isAlert ? "color: #BBBBBB; stroke-width: 0" : "";
};

/**
 * 来店頻度データの値が全て存在することを確認する
 * @param frequencyData 来店頻度データ
 * @returns 来店頻度データが全て1以上かどうか
 */
const hasEnoughFrequencyCounts = (frequencyDate: {
  isAlert: boolean;
  oncePerMonth: number;
  twicePerMonth: number;
  threeTimesPerMonth: number;
  fourTimesPerMonth: number;
  eightTimesPerMonth: number;
  chainName: string;
}) => {
  return [
    frequencyDate.oncePerMonth ?? NaN,
    frequencyDate.twicePerMonth ?? NaN,
    frequencyDate.threeTimesPerMonth ?? NaN,
    frequencyDate.fourTimesPerMonth ?? NaN,
    frequencyDate.eightTimesPerMonth ?? NaN,
  ].every((count) => count > 0);
};

/**
 * エンゲージメントデータの値が全て存在することを確認する
 * @param engagementDate エンゲージメントデータ
 * @returns エンゲージメントデータが全て1以上かどうか
 */
const hasEnoughEngagementCounts = (engagementDate: {
  isAlert: boolean;
  engagementNew: number;
  engagementContinue: number;
  engagementInactive: number;
  engagementLeaveThisMonth: number;
  engagementLeaveLastMonth: number;
  chainName: string;
}) => {
  return [
    engagementDate.engagementNew ?? NaN,
    engagementDate.engagementContinue ?? NaN,
    engagementDate.engagementInactive ?? NaN,
    engagementDate.engagementLeaveThisMonth ?? NaN,
    engagementDate.engagementLeaveLastMonth ?? NaN,
  ].every((count) => count > 0);
};

/**
 * 来店頻度レスポンスデータをチャート用に加工する関数
 * @param frequencyResponses 来店頻度データ
 */
export function processFrequencyChart(
  frequencyResponses: ChainCompareChartResponse<FrequencyResponse>[]
) {
  let minDate = "", maxDate = "";
  const chainVisitCounts = frequencyResponses
    .sort((a, b) => {
      return a.orderIndex - b.orderIndex;
    })
    .map((chainResponse) => {
      const visitCountSum = chainResponse.visitCounts.reduce(
        (previous, current) => {
          if (minDate === "") minDate = current.date;
          else if (new Date(minDate) > new Date(current.date))
            minDate = current.date;

          if (maxDate === "") maxDate = current.date;
          else if (new Date(maxDate) < new Date(current.date))
            maxDate = current.date;

          return {
            isAlert: previous.isAlert || current.isAlert,
            oncePerMonth: previous.oncePerMonth + current.oncePerMonth,
            twicePerMonth: previous.twicePerMonth + current.twicePerMonth,
            threeTimesPerMonth:
              previous.threeTimesPerMonth + current.threeTimesPerMonth,
            fourTimesPerMonth:
              previous.fourTimesPerMonth + current.fourTimesPerMonth,
            eightTimesPerMonth:
              previous.eightTimesPerMonth + current.eightTimesPerMonth,
          };
        },
        {
          isAlert: false,
          oncePerMonth: 0,
          twicePerMonth: 0,
          threeTimesPerMonth: 0,
          fourTimesPerMonth: 0,
          eightTimesPerMonth: 0,
        }
      );
      return {
        chainName: chainResponse.chainName,
        ...visitCountSum,
      };
    });
  const isEveryFrequencyDataEnough = chainVisitCounts.every((e) =>
    hasEnoughFrequencyCounts(e)
  );
  const body = chainVisitCounts.map((e) => {
    const isEnough = hasEnoughFrequencyCounts(e);
    const sum =
      e.oncePerMonth +
      e.twicePerMonth +
      e.threeTimesPerMonth +
      e.fourTimesPerMonth +
      e.eightTimesPerMonth;
    return [
      e.chainName,
      e.oncePerMonth,
      getTooltipHTML(
        e.chainName,
        convertMonthlySlashDelimiter(minDate),
        convertMonthlySlashDelimiter(maxDate),
        "月1回",
        "来店頻度",
        String((Math.round((e.oncePerMonth / sum) * 1000) / 10).toFixed(1)) +
          "%"
      ),
      getSectionColor(e.isAlert),
      e.twicePerMonth,
      getTooltipHTML(
        e.chainName,
        convertMonthlySlashDelimiter(minDate),
        convertMonthlySlashDelimiter(maxDate),
        "月2回",
        "来店頻度",
        String((Math.round((e.twicePerMonth / sum) * 1000) / 10).toFixed(1)) +
          "%"
      ),
      getSectionColor(e.isAlert),
      e.threeTimesPerMonth,
      getTooltipHTML(
        e.chainName,
        convertMonthlySlashDelimiter(minDate),
        convertMonthlySlashDelimiter(maxDate),
        "月3回",
        "来店頻度",
        String(
          (Math.round((e.threeTimesPerMonth / sum) * 1000) / 10).toFixed(1) +
            "%"
        )
      ),
      getSectionColor(e.isAlert),
      e.fourTimesPerMonth,
      getTooltipHTML(
        e.chainName,
        convertMonthlySlashDelimiter(minDate),
        convertMonthlySlashDelimiter(maxDate),
        "月4回以上",
        "来店頻度",
        String(
          (Math.round((e.fourTimesPerMonth / sum) * 1000) / 10).toFixed(1)
        ) + "%"
      ),
      getSectionColor(e.isAlert),
      e.eightTimesPerMonth,
      getTooltipHTML(
        e.chainName,
        convertMonthlySlashDelimiter(minDate),
        convertMonthlySlashDelimiter(maxDate),
        "月8回以上",
        "来店頻度",
        String(
          (Math.round((e.eightTimesPerMonth / sum) * 1000) / 10).toFixed(1) +
            "%"
        )
      ),
      getSectionColor(e.isAlert),
      ...(isEveryFrequencyDataEnough
        ? []
        : [
            isEnough ? 0 : 1,
            isEnough
              ? ""
              : getEmptyTooltipHTML(
                  e.chainName,
                  convertMonthlySlashDelimiter(minDate),
                  convertMonthlySlashDelimiter(maxDate),
                  "参考値（データ不十分）",
                  "来店頻度"
                ),
            getSectionColor(e.isAlert),
          ]),
      e.isAlert,
    ];
  });
  const legends = [
    "チェーン名",
    "月1回",
    { type: "string", role: "tooltip", p: { html: true } },
    { role: "style" },
    "月2回",
    { type: "string", role: "tooltip", p: { html: true } },
    { role: "style" },
    "月3回",
    { type: "string", role: "tooltip", p: { html: true } },
    { role: "style" },
    "月4回以上",
    { type: "string", role: "tooltip", p: { html: true } },
    { role: "style" },
    "月8回以上",
    { type: "string", role: "tooltip", p: { html: true } },
    { role: "style" },
    ...(isEveryFrequencyDataEnough
      ? []
      : [
          "参考値（データ不十分）",
          { type: "string", role: "tooltip", p: { html: true } },
          { role: "style" },
        ]),
    false,
  ];

  return [legends, ...body];
}

/**
 * エンゲージメントレスポンスデータをチャート用に加工する関数
 * @param engagementResponses エンゲージメントデータ
 */
export function processEngagementChart(
  engagementResponses: ChainCompareChartResponse<EngagementResponse>[]
) {
  let minDate = "", maxDate = "";
  const chainVisitCounts = engagementResponses
    .sort((a, b) => {
      return a.orderIndex - b.orderIndex;
    })
    .map((chainResponse) => {
      const visitCountSum = chainResponse.visitCounts.reduce(
        (previous, current) => {
          if (minDate === "") minDate = current.date;
          else if (new Date(minDate) > new Date(current.date))
            minDate = current.date;

          if (maxDate === "") maxDate = current.date;
          else if (new Date(maxDate) < new Date(current.date))
            maxDate = current.date;

          return {
            isAlert: previous.isAlert || current.isAlert,
            engagementNew: previous.engagementNew + current.engagementNew,
            engagementContinue:
              previous.engagementContinue + current.engagementContinue,
            engagementInactive:
              previous.engagementInactive + current.engagementInactive,
            engagementLeaveThisMonth:
              previous.engagementLeaveThisMonth +
              current.engagementLeaveThisMonth,
            engagementLeaveLastMonth:
              previous.engagementLeaveLastMonth +
              current.engagementLeaveLastMonth,
          };
        },
        {
          isAlert: false,
          engagementNew: 0,
          engagementContinue: 0,
          engagementInactive: 0,
          engagementLeaveThisMonth: 0,
          engagementLeaveLastMonth: 0,
        }
      );
      return {
        chainName: chainResponse.chainName,
        ...visitCountSum,
      };
    });
  const isEveryEngagementDataEnough = chainVisitCounts.every((e) =>
    hasEnoughEngagementCounts(e)
  );
  const body = chainVisitCounts.map((e) => {
    const isEnough = hasEnoughEngagementCounts(e);
    const sum =
      e.engagementNew +
      e.engagementContinue +
      e.engagementInactive +
      e.engagementLeaveThisMonth +
      e.engagementLeaveLastMonth;
    return [
      e.chainName,
      e.engagementNew,
      getTooltipHTML(
        e.chainName,
        convertMonthlySlashDelimiter(minDate),
        convertMonthlySlashDelimiter(maxDate),
        "新規",
        "エンゲージメント",
        String((Math.round((e.engagementNew / sum) * 1000) / 10).toFixed(1)) +
          "%"
      ),
      getSectionColor(e.isAlert),
      e.engagementContinue,
      getTooltipHTML(
        e.chainName,
        convertMonthlySlashDelimiter(minDate),
        convertMonthlySlashDelimiter(maxDate),
        "継続",
        "エンゲージメント",
        String(
          (Math.round((e.engagementContinue / sum) * 1000) / 10).toFixed(1)
        ) + "%"
      ),
      getSectionColor(e.isAlert),
      e.engagementInactive,
      getTooltipHTML(
        e.chainName,
        convertMonthlySlashDelimiter(minDate),
        convertMonthlySlashDelimiter(maxDate),
        "復活",
        "エンゲージメント",
        String(
          (Math.round((e.engagementInactive / sum) * 1000) / 10).toFixed(1)
        ) + "%"
      ),
      getSectionColor(e.isAlert),
      e.engagementLeaveThisMonth,
      getTooltipHTML(
        e.chainName,
        convertMonthlySlashDelimiter(minDate),
        convertMonthlySlashDelimiter(maxDate),
        "離反（当月）",
        "エンゲージメント",
        String(
          (Math.round((e.engagementLeaveThisMonth / sum) * 1000) / 10).toFixed(
            1
          )
        ) + "%"
      ),
      getSectionColor(e.isAlert),
      e.engagementLeaveLastMonth,
      getTooltipHTML(
        e.chainName,
        convertMonthlySlashDelimiter(minDate),
        convertMonthlySlashDelimiter(maxDate),
        "離反（先月）",
        "エンゲージメント",
        String(
          (Math.round((e.engagementLeaveLastMonth / sum) * 1000) / 10).toFixed(
            1
          )
        ) + "%"
      ),
      getSectionColor(e.isAlert),
      ...(isEveryEngagementDataEnough
        ? []
        : [
            isEnough ? 0 : 1,
            isEnough
              ? ""
              : getEmptyTooltipHTML(
                  e.chainName,
                  convertMonthlySlashDelimiter(minDate),
                  convertMonthlySlashDelimiter(maxDate),
                  "参考値（データ不十分）",
                  "エンゲージメント"
                ),
            getSectionColor(e.isAlert),
          ]),
      e.isAlert,
    ];
  });
  const legends = [
    "チェーン名",
    "新規",
    { type: "string", role: "tooltip", p: { html: true } },
    { role: "style" },
    "継続",
    { type: "string", role: "tooltip", p: { html: true } },
    { role: "style" },
    "復活",
    { type: "string", role: "tooltip", p: { html: true } },
    { role: "style" },
    "離反（当月）",
    { type: "string", role: "tooltip", p: { html: true } },
    { role: "style" },
    "離反（先月）",
    { type: "string", role: "tooltip", p: { html: true } },
    { role: "style" },
    ...(isEveryEngagementDataEnough
      ? []
      : [
          "参考値（データ不十分）",
          { type: "string", role: "tooltip", p: { html: true } },
          { role: "style" },
        ]),
    false,
  ];

  return [legends, ...body];
}
