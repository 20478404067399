import { axiosClient } from "../../plugins/axios";
import { AxiosResponse } from "axios";
import { PeriodResponse } from "@/commons/interfaces";

/**
 * 有効期限の取得
 *
 * @returns 有効期限を取得する axios の Promise
 */
export function getPeriod(): Promise<AxiosResponse<PeriodResponse>> {
  return axiosClient.get<PeriodResponse>("/period/");
}
