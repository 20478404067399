<template>
  <div class="my-info">
    <div class="title">
      <b>あなたの情報</b>
    </div>
    <table
      class="my-info-table"
      cellspacing="0"
    >
      <tbody>
        <tr>
          <th class="department">
            部署名
          </th>
          <td class="department">
            {{ user ? user.department : "ー" }}
          </td>
        </tr>
        <tr>
          <th>氏名</th>
          <td>
            {{ user ? user.name : "ー" }}
          </td>
        </tr>
        <tr>
          <th>メールアドレス</th>
          <td>
            {{ user ? user.mail : "ー" }}
          </td>
        </tr>
      </tbody>
    </table>
    <div class="my-info-action">
      <custom-button
        v-if="isGeneralUser"
        class="delete-button"
        variant="inverse"
        width="140px"
        height="46px"
        :disabled="disabledDelete || loading"
        @click="onClickDeleteButton"
      >
        削除
      </custom-button>
      <custom-button
        class="edit-button"
        variant="primary"
        width="140px"
        height="46px"
        :disabled="disabledEdit || loading"
        @click="onClickEditButton(user?.userId)"
      >
        編集
      </custom-button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { MyUserResponse } from "@/features/UserSetting/interfaces/response";
import { UserType } from "@/features/Dashboard/enums";

export default defineComponent({
  name: "MyInfo",
  props: {
    user: {
      type: Object as PropType<MyUserResponse> | undefined,
      default: undefined,
    },
    userCount: {
      type: Number,
      default: 0,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["delete"],
  computed: {
    disabledDelete() {
      return (
        typeof this.user === "undefined" ||
        (this.userCount === 1 && typeof this.user !== "undefined")
      );
    },
    disabledEdit() {
      return typeof this.user === "undefined";
    },
    isGeneralUser() {
      return this.user?.userType === UserType.GENERAL;
    },
  },
  methods: {
    onClickDeleteButton() {
      if (this.disabledDelete) return;
      this.$emit("delete", this.user?.userId);
    },
    onClickEditButton(id: string | undefined) {
      if (typeof id === "undefined") return;
      this.$router.push({ name: "UserEdit", params: { id: id } });
    },
  },
});
</script>

<style lang="scss" scoped>
.my-info {
  height: 262px;
}
.title {
  text-align: left;
  font-size: 1.25rem!important;
  font-weight: 500;
  letter-spacing: .0125em!important;
  color: #222222;
  margin-bottom: 14px;
  line-height: 2rem;
  font-family: Roboto,sans-serif!important;
  
}
.my-info-table {
  margin: 20px 0px;
  width: 100%;

  .department {
    border-top-width: 1px;
  }
}
tr {
  display: block;
  th {
    font-size: 15px;
    min-width: 200px;
    height: 46px;
    background: #f5f5f5 0% 0% no-repeat padding-box;
    border: 1px solid #cccccc;
    border-top-width: 0px;
    border-right-width: 0;
    border-collapse: collapse;
    text-align: start;
    padding-left: 15px;
  }
  td {
    font-size: 15px;
    width: 100%;
    height: 46px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #cccccc;
    border-top-width: 0;
    border-collapse: collapse;
    text-align: start;
    padding-left: 15px;
  }
}
.my-info-action {
  text-align: end;
  .delete-button {
    margin-right: 20px;
    color: #666666;
    font-size: 15px;
  }
  .edit-button {
    font-size: 15px;
  }
}
</style>
