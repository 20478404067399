<template>
  <button
    class="custom-button"
    :class="{
      'custom-primary': variant === 'primary',
      'custom-inverse': variant === 'inverse',
      'custom-outline': variant === 'outline',
      'custom-danger': variant === 'danger',
      xs: size === 'xs',
      sm: size === 'sm',
      md: size === 'md',
      disabled: disabled,
    }"
    :style="`width: ${width}; height: ${height}`"
    v-bind="$attrs"
    :disabled="disabled"
  >
    <div
      v-if="$slots.startIcon"
      class="start-icon"
    >
      <slot name="startIcon" />
    </div>
    <slot />
    <div
      v-if="$slots.endIcon"
      class="end-icon"
    >
      <slot name="endIcon" />
    </div>
  </button>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    variant: {
      type: String as PropType<"primary" | "inverse" | "outline" | "danger">,
      default: "primary",
    },
    size: { type: String as PropType<"xs" | "sm" | "md">, default: "sm" },
    width: { type: String, default: "auto" },
    height: { type: String, default: "auto" },
    disabled: { type: Boolean, default: false },
  },
});
</script>

<style lang="scss" scoped>
.custom-button {
  position: relative;
  border-radius: 4px;
  box-shadow: 1px 1px 0px 0px rgba(0, 0, 0, 0.16);

  .start-icon {
    position: absolute;
    top: 50%;
    left: 18px;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
  }

  .end-icon {
    position: absolute;
    top: 50%;
    right: 18px;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
  }
}

.disabled {
  color: #ffffff !important;
  background-color: #cccccc !important;
  border-color: #cccccc !important;
  box-shadow: none;
}

// variants
.custom-primary {
  background-color: #0e182e;
  color: #ffffff;
}

.custom-inverse {
  background-color: #ffffff;
  color: #222222;
  border: 1px solid #cccccc;
}

.custom-outline {
  border: 1px solid #cccccc;
  box-shadow: none;
}

.custom-danger {
  background-color: #be0000;
  color: #fff;
}

// size
.xs {
  height: 30px;
  padding: 4px 10px 6px 10px;
  font-size: 12px;
  height: 30px;
}

.sm {
  padding: 5.33px 27px 5.67px 27px;
  font-weight: bold;
  font-size: 13px;
}

.md {
  padding: 12px 59px 13px 59px;
  font-weight: bold;
  font-size: 15px;
}
</style>
