import { axiosClient } from '@/plugins/axios'
import { AxiosResponse } from 'axios'
import { convertHyphenDelimiter } from '@/commons/utils/dateUtil'
import { DownloadResponse } from '@/commons/interfaces/responses/download'
import qs from 'qs'

/**
 * 性別年代別チェーン比較DL
 * @param {string[]} chainIds 対象のチェーンIDs
 * @param {string[]} prefectureIds 対象の都道府県IDs
 * @param {string} start 期間開始日(yyyy-MM-dd)
 * @param {string} end 期間終了日(yyyy-MM-dd)
 * @returns
 */
export function downloadAgeGenderCSV(
  chainIds: string[],
  prefectureIds: string[],
  start: string,
  end: string
): Promise<AxiosResponse<DownloadResponse>> {
  return axiosClient.get<DownloadResponse>('/chains/chart/age-gender/chain-comparison/download/', {
    params: {
      chainIds,
      prefectureIds,
      start: convertHyphenDelimiter(start),
      end: convertHyphenDelimiter(end)
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat' })
    }
  })
}

/**
 * 行動DNAチェーン比較DL
 * @param {string[]} chainIds 対象のチェーンIDs
 * @param {string[]} prefectureIds 対象の都道府県IDs
 * @param {string} start 期間開始日(yyyy-MM-dd)
 * @param {string} end 期間終了日(yyyy-MM-dd)
 * @param {number} level 階層レベルの指定
 * @returns
 */
export function downloadBehavioralDNACSV(
  chainIds: string[],
  prefectureIds: string[],
  start: string,
  end: string,
  level: 2 | 3
): Promise<AxiosResponse<DownloadResponse>> {
  return axiosClient.get<DownloadResponse>(
    '/chains/chart/behavioral-dna/chain-comparison/download/',
    {
      params: {
        chainIds,
        prefectureIds,
        start: convertHyphenDelimiter(start),
        end: convertHyphenDelimiter(end),
        level: level
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    }
  )
}
