import { DownloadResponse } from '@/commons/interfaces/responses/download'
import { convertHyphenDelimiter } from '@/commons/utils/dateUtil'
import { axiosClient } from '@/plugins/axios'
import { AxiosResponse } from 'axios'
import qs from 'qs'

/**
 * 来店人数チェーン比較DL
 * @param {string[]} chainIds 対象のチェーンIDs
 * @param {string[]} prefectureIds 対象の都道府県IDs
 * @param {number} particle 表示粒度。日別、週別、月別、時間帯別、曜日別の5種。
 * @param {string} start 期間開始日(yyyy-MM-dd)
 * @param {string} end 期間終了日(yyyy-MM-dd)
 * @returns
 */
export function downloadVisitCountChartCSV(
  chainIds: string[],
  prefectureIds: string[],
  particle: number,
  start: string,
  end: string
): Promise<AxiosResponse<DownloadResponse>> {
  return axiosClient.get<DownloadResponse>(
    '/chains/chart/visit-count-chart/chain-comparison/download/',
    {
      params: {
        chainIds,
        prefectureIds,
        particle: particle,
        start: convertHyphenDelimiter(start),
        end: convertHyphenDelimiter(end)
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' })
      }
    }
  )
}
