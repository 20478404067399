<template>
  <v-container
    class="pa-1 mb-1"
    fluid
  >
    <div class="grid-wrap">
      <div class="cols-5">
        <GChart
          type="BarChart"
          :data="leftChartData"
          :options="leftOption"
          :events="leftEvent"
          @ready="(chart, google) => {
            leftChart = chart;
            if (!leftChart) return;
            leftEvent = google.visualization.events as GoogleVizEvents;
            leftEvent.addListener(leftChart, 'select', () => leftSelect());
          }"
        />
      </div>
      <div class="cols-2">
        <GChart
          type="BarChart"
          :data="centerChartData"
          :options="centerOption"
          :events="centerEvent"
          @ready="(chart, google) => {
            centerChart = chart;
            if (!centerChart) return;
            centerEvent = google.visualization.events as GoogleVizEvents;
            centerEvent.addListener(centerChart, 'select', () => centerSelect());
          }"
        />
      </div>
      <div class="cols-2">
        <GChart
          type="BarChart"
          :data="rightChartData"
          :options="rightOption"
          :events="rightEvent"
          @ready="(chart, google) => {
            rightChart = chart;
            if (!rightChart) return;
            rightEvent = google.visualization.events as GoogleVizEvents;
            rightEvent.addListener(rightChart, 'select', () => rightSelect());
          }"
        />
      </div>
    </div>
  </v-container>
</template>
<script setup lang="ts">
import { COLOR } from "@/commons/enums";
import { computed, ref } from "vue";
import { GChart } from "vue-google-charts";
import { GoogleChartOptions, GoogleChartWrapper, GoogleVizEvents } from "vue-google-charts/dist/types";

const props = withDefaults(
  defineProps<{
    chartData: (string | number)[][],
    height: number,
    min: number,
    max: number,
  }>(),
  {
    chartData: () => [],
    height: 550,
    min: 0,
    max: 0,
  }
)

const leftChartData = computed<(string | number)[][]>(() => {
  return props.chartData.map((data: (string | number)[]) => {
    return [data[0], data[1], data[2], data[3]].flat();
  });
})
const centerChartData = computed<(string | number)[][]>(() => {
  return props.chartData.map((data: (string | number)[]) => {
    return [data[0], data[4], data[5], data[6]].flat();
  });
})
const rightChartData = computed<(string | number)[][]>(() => {
  return props.chartData.map((data: (string| number)[]) => {
    return [data[0], data[7], data[8], data[9]].flat();
  });
})

const leftChart = ref<GoogleChartWrapper>()
const centerChart = ref<GoogleChartWrapper>()
const rightChart = ref<GoogleChartWrapper>()

const ticks = [...Array(20)]
  .map((_, i) => i * 5)
  .filter((n) => {
    if (props.min % 5 < 2.5)
      return props.min - 10 < n && n < props.max + 5;
    return props.min - 5 < n && n < props.max + 5;
  });
const leftOption = ref<GoogleChartOptions>({
  chartArea: {
    top: 50,
    left: 175,
    right: 20,
    bottom: 50,
    backgroundColor: {
      fill: "#fff",
    },
  },
  colors: [COLOR.TOTAL],
  hAxis: {
    textStyle: {
      fontSize: 12,
    },
    viewWindow: {
      max: ticks[ticks.length - 1],
      min: ticks[0],
    },
    ticks: ticks,
  },
  height: props.height,
  legend: {
    position: "top",
    alignment: "center",
    textStyle: {
      fontSize: 12,
    },
  },
  tooltip: {
    isHtml: true,
  },
  vAxis: {
    textStyle: {
      fontSize: 10,
    },
    textPosition: "out",
  },
  bar: {
    groupWidth: "17",
  },
})
const centerOption = ref<GoogleChartOptions>({
  chartArea: {
    top: 50,
    left: 20,
    right: 20,
    bottom: 50,
    backgroundColor: {
      fill: "#f5f8fd",
    },
  },
  colors: [COLOR.BLUE],
  hAxis: {
    textStyle: {
      fontSize: 12,
    },
    viewWindow: {
      max: ticks[ticks.length - 1],
      min: ticks[0],
    },
    ticks: ticks,
  },
  height: props.height,
  legend: {
    position: "top",
    alignment: "center",
    textStyle: {
      fontSize: 12,
    },
  },
  tooltip: {
    isHtml: true,
  },
  vAxis: {
    textStyle: {
      fontSize: 10,
    },
    textPosition: "none",
  },
  bar: {
    groupWidth: "17",
  },
})
const rightOption = ref<GoogleChartOptions>({
  chartArea: {
    top: 50,
    left: 20,
    right: 20,
    bottom: 50,
    backgroundColor: {
      fill: "#fff6f7"
    },
  },
  colors: [COLOR.RED],
  hAxis: {
    textStyle: {
      fontSize: 12,
    },
    viewWindow: {
      max: ticks[ticks.length - 1],
      min: ticks[0],
    },
    ticks: ticks,
  },
  height: props.height,
  legend: {
    position: "top",
    alignment: "center",
    textStyle: {
      fontSize: 12,
    },
  },
  tooltip: {
    isHtml: true,
  },
  vAxis: {
    textStyle: {
      fontSize: 10,
    },
    textPosition: "none",
  },
  bar: {
    groupWidth: "17",
  },
})

const leftEvent = ref<GoogleVizEvents>()
const centerEvent = ref<GoogleVizEvents>()
const rightEvent = ref<GoogleVizEvents>()

const emit = defineEmits(['select-column'])
function leftSelect() {
  if (leftChart.value?.getSelection().length) {
    centerChart.value?.setSelection([
      leftChart.value?.getSelection()[0],
    ]);
    rightChart.value?.setSelection([
      leftChart.value?.getSelection()[0],
    ]);
    emit(
      'select-column',
      leftChart.value?.getSelection()[0]
    );
  } else {
    centerChart.value?.setSelection(null);
    rightChart.value?.setSelection(null);
    emit("select-column", null);
  }
}
function centerSelect() {
  if (centerChart.value?.getSelection().length) {
    leftChart.value?.setSelection([
      centerChart.value?.getSelection()[0],
    ]);
    rightChart.value?.setSelection([
      centerChart.value?.getSelection()[0],
    ]);
    emit(
      "select-column",
      centerChart.value?.getSelection()[0]
    );
  } else {
    leftChart.value?.setSelection(null);
    rightChart.value?.setSelection(null);
    emit("select-column", null);
  }
}
function rightSelect() {
  if (rightChart.value?.getSelection().length) {
    leftChart.value?.setSelection([
      rightChart.value?.getSelection()[0],
    ]);
    centerChart.value?.setSelection([
      rightChart.value?.getSelection()[0],
    ]);
    emit(
      "select-column",
      rightChart.value?.getSelection()[0]
    );
  } else {
    leftChart.value?.setSelection(null);
    centerChart.value?.setSelection(null);
    emit("select-column", null);
  }
}
</script>

<style lang="scss" scoped>
.grid-wrap {
  display: grid;
  grid-template-columns: repeat(9, minmax(0, 1fr));

  .cols-5 {
    grid-column: span 5 / span 5;
  }

  .cols-2 {
    grid-column: span 2 / span 2;
  }
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  height: 7px;
  width: 7px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
</style>
