import { axiosClient } from "@/plugins/axios";
import { AxiosResponse } from "axios";
import {
  StoreTypeResponse,
  StoreRegionMapResponse,
  StoreRegionTableResponse,
} from "@/features/RegionalShare/interfaces/response";
import qs from "qs";
import { DownloadResponse } from "@/commons/interfaces/responses/download";

// TODO: commons に寄せる
export function getStoreTypes(): Promise<AxiosResponse<StoreTypeResponse>> {
  return axiosClient.get<StoreTypeResponse>("/store-types/");
}

/**
 * 指定の店舗を中心とした各店舗の来店人数比率を取得
 * @param {string} storeId 店舗ID
 * @param {number[]} storeTypes 業種
 * @param {number} radius 表示範囲
 * @param {string} start 期間開始日(yyyy-MM-dd)
 * @param {string} end 期間終了日(yyyy-MM-dd)
 */
export function getStoreRegionMap(
  storeId: string | undefined,
  storeTypes: number[],
  radius: number,
  start: string,
  end: string
): Promise<AxiosResponse> {
  if (typeof storeId === "undefined") return Promise.reject();
  return axiosClient.get<StoreRegionMapResponse>(
    "/stores/region/ranking-chart/",
    {
      params: {
        storeId: storeId,
        storeTypes: storeTypes,
        radius: radius * 1000,
        start: start,
        end: end,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    }
  );
}

/**
 * 指定の店舗を中心とした13ヶ月分の来店人数比率を取得
 * @param {string} storeId 店舗ID
 * @param {number[]} storeTypes 業種
 * @param {number} radius 表示範囲
 */
export function getStoreRegionTable(
  storeId: string | undefined,
  storeTypes: number[],
  radius: number
): Promise<AxiosResponse> {
  if (typeof storeId === "undefined") return Promise.reject();
  return axiosClient.get<StoreRegionTableResponse>(
    "/stores/region/ranking-table/",
    {
      params: {
        storeId: storeId,
        storeTypes: storeTypes,
        radius: radius * 1000,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    }
  );
}

/**
 * 指定の店舗を中心とした13ヶ月分の来店人数比率DL用URLを生成
 * @param {string} storeId 店舗ID
 * @param {number[]} storeTypes 業種
 * @param {number} radius 表示範囲
 */
export function downloadRegionalShareChart(
  storeId: string | undefined,
  storeTypes: number[],
  radius: number
) {
  if (typeof storeId === "undefined") return Promise.reject();
  return axiosClient.get<DownloadResponse>(
    "/stores/region/ranking-table/download/",
    {
      params: {
        storeId: storeId,
        storeTypes: storeTypes,
        radius: radius * 1000,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    }
  );
}
