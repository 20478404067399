<template>
  <div>
    <div v-if="unselect" class="unselected_card">
      「分析する」をクリックするとマップが表示されます。
    </div>
    <LoadingImg v-else-if="props.loading" :height="'330px'" />
    <div v-else-if="emptyMapData" class="unselected_card">
      <p>このエリアのデータが不足しているため、マップを表示することができません。</p>
    </div>
    <div v-else-if="props.isPrivacyAlert" class="unselected_card">
      <p>
        選択した地域は世帯数が少ないため、プライバシーに配慮し非表示としています。
        分析をするには選択エリアを広げてください。
      </p>
    </div>
    <MglMap
      v-else
      class="map-height"
      :access-token="mapAccessToken"
      :map-style="mapStyle"
      :center="centerPoint"
      :zoom="12"
      :attribution-control="false"
      :logo-position="'bottom-right'"
      :drag-rotate="false"
      :touch-zoom-rotate="false"
      :pitch-with-rotate="false"
      @load="initMap"
    >
      <v-overlay
        contained
        :model-value="props.loading"
        class="align-center justify-center"
        :z-index="3"
        :persistent="true"
        :no-click-animation="true"
      >
        <LoadingImg v-if="props.loading" :height="'600px'" />
      </v-overlay>
      <MglGeojsonLayer
        v-if="areaFeatureLayer"
        ref="areaFeature"
        :source-id="areaFeatureId"
        :source="areaFeatureSource"
        :layer-id="areaFeatureLayerId"
        :layer="areaFeatureLayer"
        :clear-source="true"
      />
      <MglGeojsonLayer
        v-if="trendStoreCircleLayer"
        ref="trendStoreCircle"
        :source-id="trendStoreCircleId"
        :source="trendStoreCircleSource"
        :layer-id="trendStoreCircleLayerId"
        :layer="trendStoreCircleLayer"
        :clear-source="true"
        @mouseenter="mouseOverLayer"
        @mouseleave="mouseLeavePopup"
      />
      <MglGeojsonLayer
        v-if="trendStoreSymbolLayer"
        ref="trendStoreSymbol"
        :source-id="trendStoreSymbolId"
        :source="trendStoreSymbolSource"
        :layer-id="trendStoreSymbolLayerId"
        :layer="trendStoreSymbolLayer"
        :clear-source="true"
      />
      <MglAttributionControl />
      <MglNavigationControl :show-compass="false" position="top-right" />
      <MglScaleControl position="top-left" />
      <MglPopup
        anchor="bottom-left"
        :showed="hidePopup"
        :coordinates="popupCoordinates"
        :close-button="false"
        :close-on-click="false"
      >
        <trend-map-popup
          :title="popupStoreName"
          :storeType="popupStoreType"
          :storeTypeText="STORE_TYPE.toLocalString(popupStoreType)"
          :rank="popupRank"
          @leave-popup="
            () => {
              if (!hoverPopup) hidePopup = true
            }
          "
          @close-popup="
            () => {
              if (!hoverPopup) hidePopup = true
            }
          "
        />
      </MglPopup>
    </MglMap>
  </div>
</template>

<script setup lang="ts">
import { watch, ref, computed, nextTick } from 'vue'
import LoadingImg from '@/commons/components/loadingImg.vue'
import TrendMapPopup from '@/features/Trend/TrendStore/components/TrendMapPopup.vue'
import { MAP_ACCESS_TOKEN, MAP_STYLE } from '@/config'
import {
  MglMap,
  MglAttributionControl,
  MglNavigationControl,
  MglScaleControl,
  MglGeojsonLayer,
  MglPopup
} from '@/vendor/vue-mapbox/main'
import { TrendStoreChartItem, TrendMapFeature } from '@/features/Trend/TrendStore/interfaces'
import {
  STORE_TYPE,
  TOKYO_AREA_ID,
  TOKYO_BBOX_LEFT_DOWN,
  TOKYO_BBOX_RIGHT_TOP
} from '@/commons/enums'

const props = withDefaults(
  defineProps<{
    stores: TrendStoreChartItem[]
    features: TrendMapFeature[]
    loading: boolean
    areaId?: {
      prefectureIds: string[]
      cityIds: string[] | undefined
      townIds: string[] | undefined
    }
    isPrivacyAlert: boolean
  }>(),
  {
    stores: () => [],
    features: () => [],
    loading: false,
    areaId: undefined,
    isPrivacyAlert: false
  }
)

const map = ref()
const mapAccessToken = MAP_ACCESS_TOKEN
const mapStyle = MAP_STYLE
const unselect = computed<boolean>(() => {
  return !props.areaId || props.areaId?.prefectureIds.length === 0
})
const emptyMapData = computed<boolean>(() => {
  return (props.stores.length === 0 && props.features.length === 0) || !centerPoint.value
})
const centerPoint = computed(() => {
  if (props.stores.length === 0) return undefined

  let totalLng = 0,
    totalLat = 0
  props.stores.forEach((store: TrendStoreChartItem) => {
    totalLng += store.longitude
    totalLat += store.latitude
  })
  return [totalLng / props.stores.length, totalLat / props.stores.length]
})

// 店舗マーカー
const trendStoreCircle = ref()
const trendStoreCircleId = 'trendStoreCircleSourceId'
const trendStoreCircleLayerId = 'trendStoreCircleLayerId'
const trendStoreCircleSource = ref()
const trendStoreCircleLayer = ref()
const trendStoreSymbol = ref()
const trendStoreSymbolId = 'trendStoreSymbolSourceId'
const trendStoreSymbolLayerId = 'trendStoreSymbolLayerId'
const trendStoreSymbolSource = ref()
const trendStoreSymbolLayer = ref()
function updateTrendStoreLayer() {
  if (trendStoreCircle.value) resetCompStore()
  else createCompStore()
}
function createCompStore() {
  const features = props.stores
    .map((store: TrendStoreChartItem) => {
      return {
        type: 'Feature',
        properties: {
          rank: store.currentRank,
          name: store.name,
          type: store.storeTypeId,
          address: store.address,
          previousRank: store.prevRank,
          longitude: store.longitude,
          latitude: store.latitude
        },
        geometry: {
          type: 'Point',
          coordinates: [store.longitude, store.latitude]
        }
      }
    })
    .sort((a, b) => b.properties.rank - a.properties.rank)

  trendStoreCircleSource.value = {
    type: 'geojson',
    data: {
      id: trendStoreCircleId,
      type: 'FeatureCollection',
      features: features
    }
  }
  trendStoreCircleLayer.value = {
    id: trendStoreCircleLayerId,
    type: 'circle',
    source: trendStoreCircleSource.value,
    paint: {
      'circle-pitch-scale': 'viewport',
      'circle-radius': 10,
      'circle-color': [
        'match',
        ['get', 'type'],
        STORE_TYPE.SUPER_MARKET.value,
        '#E47075',
        STORE_TYPE.DRUG_STORE.value,
        '#8DB9A4',
        STORE_TYPE.HOME_CENTER.value,
        '#D8B47F',
        STORE_TYPE.CVS_STORE.value,
        '#9278c3',
        '#ffffff'
      ],
      'circle-stroke-color': '#ffffff',
      'circle-stroke-width': 2
    }
  }
  trendStoreSymbolSource.value = {
    type: 'geojson',
    data: {
      id: trendStoreSymbolId,
      type: 'FeatureCollection',
      features: props.stores.map((trendStore: TrendStoreChartItem) => {
        return {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [trendStore.longitude, trendStore.latitude]
          },
          properties: {
            rank: trendStore.currentRank
          }
        }
      })
    }
  }
  trendStoreSymbolLayer.value = {
    id: trendStoreSymbolLayerId,
    type: 'symbol',
    source: trendStoreSymbolSource.value,
    layout: {
      'symbol-sort-key': ['get', 'rank'],
      'text-field': ['get', 'rank'],
      'text-size': 12,
      'text-font': ['Arial Unicode MS Bold']
    },
    paint: {
      'text-color': '#ffffff'
    }
  }
}
function resetCompStore() {
  hidePopup.value = true
  try {
    const map = trendStoreCircle.value.map
    map.removeSource(trendStoreCircleId)
    if (map.getLayer(trendStoreCircleLayerId)) {
      map.removeLayer(trendStoreCircleLayerId)
      map.removeSource(trendStoreCircleLayerId)
    }
  } catch (error) {
    console.error(error)
    throw new Error('Failed to delete layer.')
  }
  trendStoreCircleSource.value = null
  trendStoreCircleLayer.value = null
  try {
    const map = trendStoreSymbol.value.map
    map.removeSource(trendStoreSymbolId)
    if (map.getLayer(trendStoreSymbolLayerId)) {
      map.removeLayer(trendStoreSymbolLayerId)
      map.removeSource(trendStoreSymbolLayerId)
    }
  } catch (error) {
    console.error(error)
    throw new Error('Failed to delete layer.')
  }
  trendStoreSymbolSource.value = null
  trendStoreSymbolLayer.value = null
  ;(async () => {
    await new Promise((resolve) => setTimeout(resolve, 0))
    createCompStore()
  })()
}

// ホバー時ポップアップ
const hidePopup = ref<boolean>(true)
const hoverPopup = ref<boolean>(false)
const popupStoreName = ref<string>()
const popupCoordinates = ref<number[]>()
const popupStoreType = ref<number>()
const popupRank = ref<number>()
function mouseOverLayer(e: any) {
  popupStoreName.value = e.mapboxEvent.features[0].properties.name
  popupCoordinates.value = [
    e.mapboxEvent.features[0].properties.longitude,
    e.mapboxEvent.features[0].properties.latitude
  ]
  popupStoreType.value = e.mapboxEvent.features[0].properties.type
  popupRank.value = e.mapboxEvent.features[0].properties.rank
  nextTick(() => {
    hoverPopup.value = true
    hidePopup.value = false
  })
}
function mouseLeavePopup() {
  hoverPopup.value = false
  if (!hoverPopup.value) hidePopup.value = false
}

// エリアレイヤー
const areaFeature = ref()
const areaFeatureId = 'areaFeatureSourceId'
const areaFeatureLayerId = 'areaFeatureLayerId'
const areaFeatureSource = ref()
const areaFeatureLayer = ref()
function updateAreaFeature() {
  if (areaFeature.value) resetAreaFeature()
  else createAreaFeature()
}
function createAreaFeature() {
  areaFeatureSource.value = {
    type: 'geojson',
    data: {
      id: areaFeatureId,
      type: 'FeatureCollection',
      features: props.features
    }
  }
  areaFeatureLayer.value = {
    id: areaFeatureLayerId,
    type: 'fill',
    source: areaFeatureSource.value,
    paint: {
      'fill-color': '#DE5A69',
      'fill-opacity': 0.6
    }
  }

  fitBoundsMap()
}
function resetAreaFeature() {
  try {
    const map = areaFeature.value.map
    map.removeSource(areaFeatureId)
    if (map.getLayer(areaFeatureLayerId)) {
      map.removeLayer(areaFeatureLayerId)
      map.removeSource(areaFeatureLayerId)
    }
  } catch (error) {
    console.error(error)
    throw new Error('Failed to delete layer.')
  }
  areaFeatureSource.value = null
  areaFeatureLayer.value = null
  ;(async () => {
    await new Promise((resolve) => setTimeout(resolve, 0))
    createAreaFeature()
  })()
}

import mapboxgl from 'mapbox-gl'
async function initMap(event: any) {
  map.value = event.map

  // レイヤーの座標から外周の境界線を取得
  if (props.features.length === 0) {
    console.error('No features found for the specified region layer.')
    return null
  } else {
    fitBoundsMap()
  }
}
const fitBoundsMap = () => {
  const bounds = new mapboxgl.LngLatBounds()
  // NOTE: 東京は離島が含まれているので本州のみの座標で判定
  if (
    props.areaId &&
    props.areaId.prefectureIds &&
    (!props.areaId.cityIds || props.areaId.cityIds.length === 0) &&
    (!props.areaId.townIds || props.areaId.townIds.length === 0) &&
    props.areaId.prefectureIds[0] === TOKYO_AREA_ID.toString()
  ) {
    bounds.extend(TOKYO_BBOX_LEFT_DOWN)
    bounds.extend(TOKYO_BBOX_RIGHT_TOP)
  } else {
    props.features.forEach((feature: TrendMapFeature) => {
      const geometry = feature.geometry
      /*
       * HACK: LngLatBounds.extend()の引数がnumber[]でなく[number, number]で定義されているので厳密にチェック。
       * interfaceから修正すると影響範囲が大きいので暫定対応。
       */
      if (geometry.type === 'Polygon') {
        geometry.coordinates[0].forEach((coord) => {
          if (!Array.isArray(coord[0]) && !Array.isArray(coord[1]))
            bounds.extend([coord[0], coord[1]])
        })
      } else if (geometry.type === 'MultiPolygon') {
        geometry.coordinates.forEach((polygon) => {
          polygon[0].forEach((coord) => {
            if (typeof coord !== 'number') bounds.extend([coord[0], coord[1]])
          })
        })
      }
    })
  }
  props.stores.forEach((store: TrendStoreChartItem) => {
    bounds.extend([store.longitude, store.latitude])
  })
  // 境界線に合わせてズーム
  if (bounds) map.value.fitBounds(bounds, { padding: 20 })
}

const propsObj = ref(props) // props監視用
watch(
  () => propsObj.value.stores,
  () => {
    updateTrendStoreLayer()
  }
)
watch(
  () => propsObj.value.features,
  () => {
    updateAreaFeature()
  }
)
</script>

<style scoped lang="scss">
.map-height {
  height: 600px;
}
</style>
