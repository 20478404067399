<template>
  <div class="sort_column_title">
    <slot class="sort_label" />
    <button
      class="sort_button distance_sort_button"
      @click="onClickAction"
    >
      <img
        v-show="showStatus.up"
        src="@/assets/svg/sort-button.svg"
        class="sort_icon"
      >
      <div
        v-show="showStatus.up && showStatus.down"
        class="sort_icon_spacer"
      />
      <img
        v-show="showStatus.down"
        src="@/assets/svg/sort-button.svg"
        class="sort_icon reverse_x"
      >
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    showStatus: {
      type: Object as PropType<{ up: boolean; down: boolean }>,
      default: () => ({ up: true, down: true }),
      required: true,
    },
  },
  methods: {
    onClickAction() {
      this.$emit("sortAction");
    },
  },
});
</script>

<style scoped>
.sort_column_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sort_button {
  width: 6.78px;
  height: 12px;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sort_label {
  padding: 8px;
}

.sort_icon {
  color: inherit;
}

.sort_icon_spacer {
  height: 100%;
  width: 100%;
}

.reverse_x {
  transform: rotateX(180deg);
}
</style>
