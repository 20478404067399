<template>
  <v-card
    height="'100%'"
    class="store-selector-card"
  >
    <v-container
      fluid
      class="pa-0"
    >
      <div class="store-selector-card-title">
        <span>分析したいリストを選んでください。</span>
        <v-spacer />
        <router-link :to="{ name: 'StoreCompareCreate' }">
          ＋ 新規リストを作成
        </router-link>
      </div>
      <v-row class="ma-0 d-flex justify-center">
        <LoadingImg
          v-if="loading"
          height="44px"
        />
        <search-pull-down v-else />
      </v-row>
      <v-row
        v-if="$store.state.selectedComparisonGroup && !loading"
        class="ma-0 pt-5"
      >
        <span class="current-comparison-group-label">
          ＜表示中の条件＞
          {{ $store.state.selectedComparisonGroup.name }}（<router-link
            class="store-compare-link"
            :to="{
              name: 'StoreCompareEdit',
              params: { id: $store.state.selectedComparisonGroup.id },
            }"
          >この条件を編集・削除</router-link>）</span>
      </v-row>
      <v-row
        v-if="$store.state.selectedComparisonGroup && !loading"
        class="ma-0 pt-2"
      >
        <div style="display: flex; flex-wrap: wrap">
          <div
            v-for="store in selectedStores"
            :key="store.id"
            class="selected_shop"
            :class="{ unselected_shop: !storeVisibility.get(store) }"
            @click="toggleStoreVisible(store)"
          >
            <span
              class="dot"
              :style="{
                color: storeVisibility.get(store) ? store.color : '#AAAAAA',
              }"
            >
              ●
            </span>
            <span
              :style="{
                color: storeVisibility.get(store) ? 'black' : '#AAAAAA',
              }"
            >
              {{ store.name }}
            </span>
          </div>
        </div>
      </v-row>
    </v-container>
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import SearchPullDown from "@/features/StoreCompare/components/SearchPullDown.vue";
import LoadingImg from "@/commons/components/loadingImg.vue";
import {
  ComparisonGroup,
  ComparisonGroupStore,
} from "@/features/StoreCompare/types";

export default defineComponent({
  name: "ShopSelector",
  components: {
    SearchPullDown,
    LoadingImg,
  },
  props: {
    loading: { type: Boolean, default: false },
  },
  computed: {
    // NOTE: watch で監視するために定義
    selectedComparisonGroup(): ComparisonGroup {
      return this.$store.state.selectedComparisonGroup;
    },
    selectedStores(): ComparisonGroup["stores"] {
      if (!this.$store.state.selectedComparisonGroup) {
        return [];
      }
      return [...this.$store.state.selectedComparisonGroup.stores].sort(
        (a, b) => {
          return a.orderIndex - b.orderIndex;
        }
      );
    },
    storeVisibility(): Map<ComparisonGroupStore, boolean> {
      if (this.selectedStores.length === 0) return new Map();
      return this.$store.state.comparisonGroupStoreVisibility;
    },
  },
  watch: {
    selectedComparisonGroup() {
      this.updateStoreVisibility();
    },
  },
  created() {
    this.updateStoreVisibility();
  },
  methods: {
    toggleStoreVisible(store: ComparisonGroupStore) {
      const visibility = this.storeVisibility.get(store);
      if (typeof visibility === "undefined") {
        return;
      }

      const visibleStoreCount = [...this.storeVisibility.entries()].filter(
        (value) => value[1]
      ).length;
      if (visibleStoreCount <= 1 && visibility == true) return;

      const _visibility = new Map(this.storeVisibility.entries());
      _visibility.set(store, !visibility);
      this.$store.commit("setSelectedComparisonGroupVisibleStores", {
        visibility: _visibility,
      });
    },
    updateStoreVisibility() {
      const _visibility = new Map(
        this.selectedStores.map((store) => [store, true])
      );
      this.$store.commit("setSelectedComparisonGroupVisibleStores", {
        visibility: _visibility,
      });
    },
  },
});
</script>

<style scoped>
.store-selector-card {
  padding: 23px 30px 29px !important;
}

.store-selector-card-title {
  margin-bottom: 17px;
  display: flex;
  align-items: center;
  height: 30px;
  width: 100%;
  font-size: 15px;
  font-weight: bold;
}

.store-selector-card-title a {
  text-decoration: none;
  color: #4d99d0;
}

.selected_shop {
  display: flex;
  cursor: pointer;
  align-items: center;
  margin-top: 8px;
  padding: 0 13px;
  height: 44px;
  width: fit-content;
  margin-right: 8px;
  font-size: 14px;
  font-weight: bold;
  text-align: 25px;
  color: #333;
  background: #f5f5f5;
  border-radius: 4px;
  box-sizing: border-box;
  border: solid 1px rgba(0, 0, 0, 0);
}

.unselected_shop {
  background-color: rgba(0, 0, 0, 0);
  border: solid 1px #cccccc;
}

.selected_shop .dot {
  margin-right: 4px;
}

.current-comparison-group-label {
  font-size: 14px;
}

.store-compare-link {
  color: #4d99d0;
}
</style>
