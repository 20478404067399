<template>
  <div class="favorite-detail-card">
    <div
      v-if="loading"
      class="loading"
    >
      <loading-img />
    </div>
    <div
      v-else
      class="store-info"
    >
      <h1>{{ favorite.name }}</h1>
      <span>
        [条件] ＜業種＞{{ storeTypes }}&nbsp;&nbsp;＜都道府県＞{{
          prefectures
        }}&nbsp;&nbsp;＜市区町村＞{{ cities }}&nbsp;&nbsp;＜チェーン名＞{{
          chainWords
        }}&nbsp;&nbsp;＜フリーワード＞{{ storeNameWords }}
        <br>
        [更新日時]
        {{ convertDateAndTimeSlashDelimiter(favorite.updated_at) }}
        <br>
        [最終更新者]
        {{ favorite.updated_by }}
        <br>
        [更新内容] {{ favorite.update_note }}
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { City, Favorite, Prefecture } from "@/features/Dashboard/types";
import { convertDateAndTimeSlashDelimiter } from "@/commons/utils/dateUtil";
import { STORE_TYPE } from "@/commons/enums";
import loadingImg from "@/commons/components/loadingImg.vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "FavoriteDetailCard",
  components: { loadingImg },
  props: {
    favorite: {
      type: Object as PropType<Favorite>,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      store: useStore(),
    };
  },
  computed: {
    storeTypes(): string {
      if (!this.favorite) return "";
      return this.favorite.storeTypes
        .map((type) => STORE_TYPE.toLocalString(type))
        .join(",");
    },
    prefectures(): string {
      if (!this.favorite) return "";
      if (this.favorite.prefectureIds.length === 0) return "すべて";
      return this.favorite.prefectureIds
        .map(
          (id) =>
            (this.store.state.prefectures as Prefecture[]).find(
              (p) => p.prefectureId === id
            )?.name
        )
        .filter((name) => name !== undefined)
        .join(",");
    },
    cities(): string {
      if (!this.favorite) return "";
      if (this.favorite.cityIds.length === 0) return "すべて";
      return this.favorite.cityIds
        .map(
          (id) =>
            (this.store.state.cities as City[]).find((c) => c.cityId === id)
              ?.name
        )
        .filter((name) => name !== undefined)
        .join(",");
    },
    chainWords(): string {
      if (!this.favorite) return "";
      if (this.favorite.chainWords.length === 0) return "ー";
      return this.favorite.chainWords.join(",");
    },
    storeNameWords(): string {
      if (!this.favorite) return "";
      if (this.favorite.storeNameWords.length === 0) return "ー";
      return this.favorite.storeNameWords.join(",");
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.loading = true;
      // 都道府県一覧
      if (!this.store.state.prefectures.length)
        await this.store.dispatch("fetchPrefectures");
      // 市区町村一覧
      if (!this.store.state.cities.length)
        await this.store.dispatch("fetchCities");
      this.loading = false;
    },
    convertDateAndTimeSlashDelimiter(date?: string) {
      return date && convertDateAndTimeSlashDelimiter(date);
    },
  },
});
</script>

<style lang="scss" scoped>
.favorite-detail-card {
  display: flex;
  justify-content: space-between;
  padding: 17px 20px 22px 20px;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0px 0px 3px #00000029;

  .loading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .store-info {
    display: flex;
    flex-direction: column;

    h1 {
      margin-bottom: 15px;
      text-align: left;
      font-size: 18px;
      font-weight: bold;
      color: #222222;
    }
    span {
      text-align: left;
      font-size: 13px;
      line-height: 1.7;
      color: #666666;
    }
  }
}
</style>
