<template>
  <div class="regional_map_popup">
    <img
      class="close"
      src="@/assets/svg/close-small.svg"
      @click="$emit('closePopup')"
    >
    <div class="popup_content">
      <div class="popup_name">
        <span :style="'color: ' + storeColor">● </span>
        {{ title }}
      </div>
      <div class="popup_description">
        [業種] {{ storeTypeText }}
      </div>
    </div>
    <div>
      <button
        class="popup_change_store_button"
        @click="$emit('changeStore')"
      >
        基準店舗に設定
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { STORE_TYPE } from "@/commons/enums";

export default defineComponent({
  name: "BizAreaStorePopup",
  props: {
    title: { type: String },
    storeColor: { type: String },
    storeTypeText: { type: String },
  },
  data() {
    return {
      storeTypes: STORE_TYPE,
    };
  },
});
</script>

<style scoped lang="scss">
.regional_map_popup {
  width: 220px;
  background: #ffffff 0% 0% no-repeat padding-box;
  text-align: left;
  border-radius: 3.5px;
}
.close {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}
.popup_content {
  height: 67.5px;
  padding-top: 12px;
  padding-left: 15px;
  padding-right: 15px;
}
.popup_name {
  font: normal normal bold 13px/19px Noto Sans JP;
  letter-spacing: 0px;
  color: #333333;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.popup_description {
  font: normal normal normal 12px/20px Noto Sans JP;
  letter-spacing: 0px;
  margin-bottom: 14.5px;
}
.popup_change_store_button {
  width: 100%;
  height: 32.5px;
  border-top: 1px solid #dddddd;

  font: normal normal normal 12px/26px Noto Sans JP;
  letter-spacing: 0px;
  color: #4d99d0;
}
.supermarket {
  color: #e47075;
}
.drugstore {
  color: #87b8a1;
}
.homeCenter {
  color: #cb9e56;
}
</style>
