<template>
  <CompareUpdate type="create" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import CompareUpdate from "@/features/Dashboard/components/CompareUpdate.vue";

export default defineComponent({
  components: { CompareUpdate },
});
</script>
