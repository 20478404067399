<template>
  <div class="chip">
    <span>{{ props.name }}</span>
    <button v-if="!!props.onDelete" type="button" class="close" @click="props.onDelete">
      <img src="@/assets/svg/close-small.svg" />
    </button>
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    name: string
    onDelete: () => void
  }>(),
  {
    name: '',
    onDelete: undefined
  }
)
</script>

<style scoped>
.chip {
  display: flex block;
  align-items: center;
  gap: 9px;
  padding: 5px 11px 6px 10px;
  text-align: left;
  font: normal normal normal 13px/26px Noto Sans JP;
  letter-spacing: 0px;
  color: #222222;
  background: #eaeff2 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
}

.close {
  display: flex;
  align-items: center;
  cursor: pointer;
}
</style>
