import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

/**
 * [チェーン分析] チェーンと都道府県の選択状態をクエリパラメータで管理するためのカスタムフック
 *
 * **Usage**
 *
 * ```ts
 * const { chainId, prefectureIds, setParams } = useChainQueryParams()
 *
 * console.log(query)         // ?chainId=hoge&prefectureIds=1&prefectureIds=2
 * console.log(chainId)       // hoge
 * console.log(prefectureIds) // ["1", "2"]
 *
 * setParams({ chainId: "fuga", prefectureIds: ["3"]})
 *
 * console.log(query)         // ?chainId=fuga&prefectureIds=3
 * console.log(chainId)       // fuga
 * console.log(prefectureIds) // ["3"]
 * ```
 *
 * **Details**
 *
 * このカスタムフックはチェーンと都道府県の選択状態をURLのクエリパラメータを通じて管理するためのものです。このフックを使用することで、アプリケーション内でユーザーの選択をURLに同期させ、ブラウザの戻る/進む操作や、URLの直接共有による状態の再現が可能になります。
 *
 */
export default function useChainQueryParams(shouldUpdateVuex: boolean = true) {
  const route = useRoute()
  const router = useRouter()
  const store = useStore()

  const chainId = computed<string | undefined>(() => {
    const queryChainId = typeof route.query.chainId === 'string' ? route.query.chainId : undefined

    return queryChainId
  })

  const prefectureIds = computed<string[]>(() => {
    let queryPrefectureIds: string[] = []

    if (typeof route.query.prefectureIds === 'string') {
      queryPrefectureIds = [route.query.prefectureIds]
    } else if (Array.isArray(route.query.prefectureIds)) {
      queryPrefectureIds = route.query.prefectureIds as string[]
    }

    return queryPrefectureIds
  })

  const setParams = (params: { chainId?: string; prefectureIds?: string[] }) => {
    if (shouldUpdateVuex) {
      if (params.chainId) {
        store.commit('setSelectedChainId', { chainId: params.chainId })
      }

      if (params.prefectureIds) {
        store.commit('setSelectedPrefectureIdsOfChain', {
          prefectureIds: params.prefectureIds.map((id) => Number(id))
        })
      }
    }

    router.push({
      query: {
        ...route.query,
        ...(params.chainId ? { chainId: params.chainId } : {}),
        ...(params.prefectureIds ? { prefectureIds: params.prefectureIds } : {})
      }
    })
  }

  return {
    chainId,
    prefectureIds,
    setParams
  }
}
