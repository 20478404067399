<template>
  <v-container
    fluid
    class="pa-0"
  >
    <GChart
      class="chart_size"
      type="ColumnChart"
      :data="chartData"
      :options="chartOptions"
    />
    <v-row
      class="my-0"
      dense
    >
      <v-col>
        <div
          class="my-0"
          style="
            display: flex;
            align-items: center;
            font-size: 12px;
            color: #666;
          "
        >
          <chart-legend :legends="legends" />
          <v-spacer />
          <alert-caption
            v-if="isAlert"
            variants="square"
          />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { GChart } from "vue-google-charts";
import chartLegend from "@/features/ShopAnalytics/components/Visitor/chartLegend.vue";
import { COLOR } from "@/commons/enums";

export default defineComponent({
  name: "ColumnChart",
  components: {
    GChart,
    chartLegend,
  },
  props: {
    chartData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    isAlert: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    colors: { type: Array, default: () => [] },
    legends: {
      type: Array as PropType<{ text: string; color: string }[]>,
      default: () => {
        return [];
      },
    },
    isStacked: { type: [Boolean, String], default: false },
  },
  computed: {
    chartOptions() {
      let defaultColors = [
        COLOR.RED,
        COLOR.BLUE,
        COLOR.GREEN,
        COLOR.ORANGE,
        COLOR.PURPLE,
        COLOR.GRAY,
      ];

      let vAxis = {};
      if (typeof this.isStacked == "string" && this.isStacked == "percent") {
        vAxis = {
          textStyle: {
            fontSize: 12,
          },
          baselineColor: "#ccc",
          gridlines: {
            color: "#ddd",
          },
          minValue: 0,
          format: "###.#%",
        };
      } else {
        vAxis = {
          textStyle: {
            fontSize: 12,
          },
          baselineColor: "#ccc",
          gridlines: {
            color: "#ddd",
          },
          minValue: 0,
        };
      }

      return {
        chartArea: {
          top: 25,
          left: 75,
          right: 10,
          bottom: 50,
        },
        height: 550,
        colors: this.colors.length > 0 ? this.colors : defaultColors,
        legend: { position: "none" },
        bar: { groupWidth: "37.5%" },
        isStacked: this.isStacked,
        hAxis: {
          textStyle: {
            fontSize: 12,
          },
        },
        tooltip: {
          isHtml: true,
        },
        vAxis: vAxis,
      };
    },
  },
});
</script>

<style scoped>
.chart_size {
  width: 100%;
}

.square {
  display: inline-block;
  margin-right: 6px;
  width: 10px;
  height: 10px;
  border: 2px solid #666;
  background: #bbbbbb;
}
</style>
