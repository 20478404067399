import { axiosClient } from '@/plugins/axios'
import { AxiosResponse } from 'axios'
import { GeoPrefectureResponse } from '@/commons/interfaces'

/**
 * 都道府県ポリゴンデータの署名付きURL取得
 *
 * @param prefectureId - 都道府県ID
 * @returns Response Promise
 */
export function getPrefectureGeo(prefectureId: string): Promise<AxiosResponse<GeoPrefectureResponse>> {
  return axiosClient.get<GeoPrefectureResponse>('/prefecture/geo/', { params: { prefectureId:prefectureId } })
}