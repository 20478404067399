import axios, { AxiosInstance, AxiosResponse } from "axios";
import { CHART_DESCRIPTION_PUBLIC_URL } from "@/config";

/**
 * チャート説明の json を取得
 */
export function getChartDescriptionJson(): Promise<AxiosResponse> {
  // NOTE: セッショントークンが不要なので独自のインスタンスを生成
  const request: AxiosInstance = axios.create({
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
    timeout: 0, // unlimited
  });
  return request.get(CHART_DESCRIPTION_PUBLIC_URL);
}
