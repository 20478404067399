<template>
  <button
    :disabled="prefectures.length === 0"
    class="prefecture-filter-button"
    @click="dialog = true"
  >
    都道府県で絞り込み
    <span v-show="filter.prefectureIds.length > 0">（{{ filter.prefectureIds.length }}）</span>
  </button>
  <v-dialog v-model="dialog" class="dialog">
    <div class="contents">
      <header>
        <div class="title">都道府県で絞り込み<span class="sub-text">（複数選択可）</span></div>
        <button v-if="selectedIds.length > 0" @click="handleClear" class="reset-filter-button">
          全ての選択を解除
        </button>
        <button v-else @click="handleCheckAll" class="reset-filter-button">全て選択</button>
      </header>
      <form>
        <div
          v-for="prefecture in prefectures"
          :key="prefecture.prefectureId"
          class="checkbox-label"
        >
          <input
            type="checkbox"
            :id="prefecture.prefectureId.toString()"
            :value="prefecture.prefectureId.toString()"
            v-model="selectedIds"
          />
          <label :for="prefecture.prefectureId.toString()">
            {{ prefecture.name }}（{{ prefecture.storeCount }}店舗)
          </label>
        </div>
      </form>
      <div class="button-wrapper">
        <CustomButton
          size="md"
          width="280px"
          height="46px"
          :disabled="selectedIds.length === 0"
          @click="handleClickFilter"
        >
          絞り込む
        </CustomButton>
      </div>
    </div>
  </v-dialog>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import CustomButton from '../Elements/CustomButton.vue'
import { PrefectureWithStoreCount } from '@/features/Dashboard/types'
import { ChainFilter } from './types'

const props = withDefaults(
  defineProps<{
    filter: ChainFilter
    prefectures: PrefectureWithStoreCount[]
    handleFilter: (filter: ChainFilter) => void
  }>(),
  {
    handleFilter: () => undefined
  }
)

const dialog = ref<boolean>(false)
const selectedIds = ref<string[]>([])

watch(
  () => props.prefectures,
  () => {
    // 絞り込み済みでなく、チェーンが更新された場合は、チェーンに紐づくすべての都道府県を選択する
    if (props.filter.prefectureIds.length === 0) {
      handleCheckAll()
    }
    // 絞り込み済みの場合、選択済み ID を引き継いだ上で新しいチェーンに紐づく都道府県のみでフィルタリングする
    else {
      selectedIds.value = selectedIds.value.filter((selectedId) =>
        props.prefectures.some(({ prefectureId }) => selectedId === prefectureId.toString())
      )
    }
  }
)

watch(
  () => dialog.value,
  () => {
    if (props.filter.prefectureIds.length === 0) {
      handleCheckAll()
    } else {
      selectedIds.value = props.filter.prefectureIds.map((id) => id.toString())
    }
  }
)

const handleClear = () => {
  selectedIds.value = []
}

const handleCheckAll = () => {
  selectedIds.value = props.prefectures.map(({ prefectureId }) => prefectureId.toString())
}

const handleClickFilter = () => {
  dialog.value = false
  props.handleFilter({ prefectureIds: selectedIds.value.map((id) => Number(id)) })
}
</script>

<style lang="scss" scoped>
.prefecture-filter-button {
  color: #4d99d0;
  font: normal normal normal 14px/30px Noto Sans JP;
  letter-spacing: 0px;
}

.prefecture-filter-button:disabled {
  color: #cccccc;
}

.dialog {
  width: 864px;

  .contents {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 35px 40px;
    width: 864px;
    border-radius: 6px;
    background: white;
    overflow-y: auto;
    max-height: 90%;

    header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      .title {
        color: var(--unnamed-color-222222);
        text-align: right;
        font: normal normal bold 15px/28px Noto Sans JP;
        letter-spacing: 0px;

        .sub-text {
          color: var(---666666);
          text-align: right;
          font: normal normal normal 15px/28px Noto Sans JP;
          letter-spacing: 0px;
        }
      }

      .reset-filter-button {
        color: var(---4d99d0（年代2）);
        text-align: right;
        font: normal normal normal 14px/30px Noto Sans JP;
        letter-spacing: 0px;
        color: #4d99d0;
      }
    }

    form {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: 0px;
      grid-row-gap: 25px;
      width: 100%;
      margin-top: 20px;
      padding: 30px;
      background: #f5f5f5 0% 0% no-repeat padding-box;
      border: 1px solid #dddddd;

      .checkbox-label {
        display: flex;
        align-items: center;
        gap: 10px;
        text-align: left;
        font: normal normal normal 14px/20px Noto Sans JP;
        letter-spacing: 0px;
        color: #000000;

        input {
          cursor: pointer;
          width: 16px;
          height: 16px;
        }

        label {
          cursor: pointer;
        }
      }
    }

    .button-wrapper {
      margin-top: 46px;
    }
  }
}
</style>
