<template>
  <div>
    <div class="search-box">
      <div class="search-box-inner">
        <input
          v-model="word"
          type="text"
          placeholder="チェーン名の一部を入力"
          @keydown.down="handleKeyDown"
          @keydown.up="handleKeyUp"
          @keydown.enter="handleEnter"
          @input="handleInput"
          @change="handleChange"
        />
        <img v-show="word.length === 0" src="@/assets/svg/magnify.svg" />
        <img
          v-show="word.length > 0"
          src="@/assets/svg/close-middle.svg"
          class="cursor-pointer"
          @click.prevent="handleClose"
        />
      </div>
      <div v-if="filteredChains.length > 0" class="list-container">
        <ul>
          <li
            v-for="chain of filteredChains"
            :key="chain.id"
            :class="{
              'is-focused': isFocused(chain),
              'is-selected': isSelected(chain),
              'is-disabled': isDisabled
            }"
            @click="handleSelectChain(chain)"
            @mouseover="handleSetFocus(chain)"
            @mouseleave="handleUnsetFocus(chain)"
          >
            <span class="name-store-type">
              <span class="name">
                {{ chain.name + (isSelected(chain) ? ' ＜選択済みのチェーン＞' : '') }}
              </span>
              <span class="store-type">
                （{{ STORE_TYPE.toLocalString(chain.storeType) }}・{{ chain.stores.length }}店舗）
              </span>
            </span>
          </li>
        </ul>
      </div>
      <div v-if="isNotFound" class="not-found">
        キーワードに合致する結果が⾒つかりませんでした。
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { STORE_TYPE } from '@/commons/enums'
import { Chain } from '@/commons/interfaces'
import { containsAllKeywords } from '@/commons/utils/keyword-match'
import { debounce } from 'lodash'
import { computed, ref, watch } from 'vue'

const LIST_LIMIT = 300

/* --------------------------------------------------------------------------
  props
 ---------------------------------------------------------------------------*/

const props = withDefaults(
  defineProps<{
    initialWord: string
    multiple: boolean
    selectLimit: number
    chains: Chain[]
    selectedIds: string[]
    handleUpdateChain: (chain: Chain) => void
  }>(),
  {
    initialWord: '',
    multiple: false,
    selectLimit: 6,
    chains: () => [],
    selectedIds: () => [],
    handleUpdateChain: () => undefined
  }
)

/* --------------------------------------------------------------------------
  core
 ---------------------------------------------------------------------------*/

const word = ref<string>(props.initialWord)
const focusedChain = ref<Chain | null>(null)
const filteredChains = ref<Chain[]>([]) // debounce で更新する関係上 computed ではなく ref で定義
const isNotFound = ref<boolean>(false) // debounce で更新する関係上 computed ではなく ref で定義
const isDisabled = computed<boolean>(
  () => props.multiple && props.selectedIds.length === props.selectLimit
)

const handleInput = debounce(() => {
  if (word.value) {
    filteredChains.value = props.chains
      .filter((chain) => containsAllKeywords(chain.name, word.value))
      .slice(0, LIST_LIMIT)
  } else {
    filteredChains.value = []
  }

  isNotFound.value = filteredChains.value.length === 0 && word.value.length > 0
}, 200)

const handleKeyDown = () => {
  if (filteredChains.value.length === 0 || isDisabled.value) return

  if (!focusedChain.value) {
    focusedChain.value = filteredChains.value[0] ?? null
  } else {
    const nextIndex =
      (filteredChains.value.indexOf(focusedChain.value) + 1) % filteredChains.value.length
    focusedChain.value = filteredChains.value[nextIndex]
  }
}

const handleKeyUp = () => {
  if (filteredChains.value.length === 0 || isDisabled.value) return

  if (!focusedChain.value) {
    focusedChain.value = filteredChains.value[filteredChains.value.length - 1] ?? null
  } else {
    const prevIndex =
      (filteredChains.value.indexOf(focusedChain.value) + filteredChains.value.length - 1) %
      filteredChains.value.length
    focusedChain.value = filteredChains.value[prevIndex]
  }
}

const handleEnter = (event: KeyboardEvent) => {
  if (focusedChain.value && !event.isComposing) {
    handleSelectChain(focusedChain.value)
  }
}

const handleChange = () => {
  focusedChain.value = null
}

const handleClose = () => {
  filteredChains.value = []
  focusedChain.value = null
  word.value = ''
}

const handleSetFocus = (chain: Chain) => {
  focusedChain.value = chain
}

const handleUnsetFocus = (chain: Chain) => {
  if (focusedChain.value === chain) focusedChain.value = null
}

const handleSelectChain = (chain: Chain) => {
  filteredChains.value = []
  focusedChain.value = null
  word.value = ''
  props.handleUpdateChain(chain)
}

const isFocused = (chain: Chain) => focusedChain.value === chain

const isSelected = (chain: Chain) => props.multiple && props.selectedIds.includes(chain.id)

/* --------------------------------------------------------------------------
  created
 ---------------------------------------------------------------------------*/

if (props.initialWord.length > 0) {
  word.value = props.initialWord
}

/* --------------------------------------------------------------------------
  watch
 ---------------------------------------------------------------------------*/

watch(
  () => props.chains,
  () => {
    word.value = ''
    focusedChain.value = null
    filteredChains.value = []
    isNotFound.value = false
  }
)
</script>

<style scoped>
.search-box {
  position: relative;
  width: 100%;
}
.search-box-inner {
  display: flex;
  padding: 11px 15px 13px;
  width: 100%;
  height: 44px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  background: #ffffff;
  font-size: 14px;
}
.search-box-inner:hover {
  border: 1px solid #999999;
}
.search-box-inner:focus {
  outline: 1px solid #999999;
}
.search-box-inner input {
  margin-right: auto;
  width: 100%;
  height: 100%;
  outline: none;
}
.search-box-inner input::placeholder {
  color: #999999;
}
.list-container {
  position: absolute;
  width: 100%;
  top: 47px;
  left: 0;
  padding: 14px 16px;
  max-height: 350px;
  background: #fff;
  border: 1px solid #cccccc;
  border-radius: 4px;
  overflow-y: auto;
  z-index: 4;
  color: #000000;
}
.list-container ul {
  text-align: left;
  list-style: none;
  padding: 0;
}
.list-container ul li {
  cursor: pointer;
  height: 36px;
  padding: 8px 11px 8px 9px;
}
.is-focused {
  background: #f5f5f5;
}
.is-selected {
  background: #f4fbff 0% 0% no-repeat padding-box;
}
.is-disabled {
  color: #666666;
  pointer-events: none;
}
.name-store-type {
  display: flex;
  align-content: center;
}
.name-store-type .name {
  font-weight: bold;
  font-size: 14px;
}
.name-store-type .store-type {
  color: #666666;
  margin-left: 13px;
  font-size: 13px;
}
.not-found {
  position: absolute;
  width: 100%;
  top: 47px;
  left: 0;
  padding: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border: 1px solid #cccccc;
  border-radius: 4px;
  font-size: 13px;
  z-index: 2;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
