<template>
  <v-card
    height="'100%'"
    class="store-selector-card"
  >
    <v-container
      fluid
      class="pa-0"
    >
      <div class="store-selector-card-title">
        <span>分析したい店舗を選んでください。</span>
      </div>
      <LoadingImg
        v-if="loading"
        height="44px"
      />
      <div v-else>
        <search-auto-complement />
        <div
          v-if="$store.state.selectedStore"
          class="selected_shop"
        >
          <span class="dot">●</span>
          {{ $store.state.selectedStore.name }}
        </div>
      </div>
    </v-container>
  </v-card>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LoadingImg from "@/commons/components/loadingImg.vue";
import SearchAutoComplement from "@/commons/components/StoreSelector/SearchAutoComplete.vue";

export default defineComponent({
  name: "ShopSelector",
  components: {
    LoadingImg,
    SearchAutoComplement,
  },
  props: {
    loading: { type: Boolean, default: false },
  },
});
</script>

<style scoped>
.store-selector-card {
  padding: 23px 30px 29px !important;
}

.store-selector-card-title {
  margin-bottom: 17px;
  display: flex;
  align-items: center;
  height: 30px;
  width: 100%;
  font-size: 15px;
  font-weight: bold;
}

.store-selector-card-title a {
  color: #4d99d0;
}

.selected_shop {
  height: 42px;
  width: fit-content;
  margin-top: 12px;
  padding: 10px 25px 13px 13px;
  font-size: 14px;
  font-weight: bold;
  text-align: 25px;
  color: #333;
  background: #f5f5f5;
  border-radius: 6px;
}
.dot {
  margin-right: 4px;
  color: #de5a69;
}
</style>
