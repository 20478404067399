// import Vue from "vue";
import { axiosClient } from "@/plugins/axios";
import { AxiosResponse } from "axios";
import { convertHyphenDelimiter } from "@/commons/utils/dateUtil";
import {
  VisitCountGraph,
  VisitCountTable,
  VisitCountComparisonTable,
} from "@/features/ShopAnalytics/interfaces/response";
import { DownloadResponse } from "@/commons/interfaces/responses/download";

/**
 * 来店人数チャート取得
 * @param {string} storeId 店舗ID
 * @param {string} start 期間開始日(yyyy-MM-dd)
 * @param {string} end 期間終了日(yyyy-MM-dd)
 * @param {number} particle 表示粒度(1: 日別, 2: 週別, 3: 月別, 4: 時間帯別, 5: 曜日別)
 * @param {number} subType 内訳(0: 指定なし, 1: 性別, 2: 年代別)
 * @param {AbortSignal} signal APIの中断用オブジェクト
 */
export async function getVisitCountGraph(
  storeId: string | undefined,
  start: string,
  end: string,
  particle: 1 | 2 | 3 | 4 | 5,
  subType: 0 | 1 | 2,
  signal?: AbortSignal
): Promise<AxiosResponse<VisitCountGraph>> {
  if (typeof storeId === "undefined") return Promise.reject();
  return await axiosClient.get<VisitCountGraph>(
    "/stores/chart/visit-count-graph/",
    {
      params: {
        store: storeId,
        start: convertHyphenDelimiter(start),
        end: convertHyphenDelimiter(end),
        particle: particle,
        type: subType,
      },
      signal: signal,
    }
  );
}

/**
 * 来店人数テーブル取得
 * @param {string} storeId 店舗ID
 * @param {string} start 期間開始日(yyyy-MM-dd)
 * @param {string} end 期間終了日(yyyy-MM-dd)
 * @param {number} particle 表示粒度(1: 日別, 2: 週別, 3: 月別, 4: 時間帯別, 5: 曜日別)
 */
export function getVisitCountTable(
  storeId: string | undefined,
  start: string,
  end: string,
  particle: 1 | 2 | 3 | 4 | 5
): Promise<AxiosResponse> {
  if (typeof storeId === "undefined") return Promise.reject();
  return axiosClient.get<VisitCountTable>("/stores/chart/visit-count-table/", {
    params: {
      store: storeId,
      start: convertHyphenDelimiter(start),
      end: convertHyphenDelimiter(end),
      particle: particle,
    },
  });
}

/**
 * 期間比較来店人数テーブル取得
 * @param {string} string 店舗ID
 * @param {Array.<Object>} period 期間
 * @param {string} period.start 期間開始日(yyyy-MM-dd)
 * @param {string} period.end 期間開始日(yyyy-MM-dd)
 * @param {number} particle 表示粒度(1: 日別, 2: 週別, 3: 月別, 4: 時間帯別, 5: 曜日別)
 */
export function getVisitCountComparisonGraph(
  storeId: string | undefined,
  period: {
    start: string;
    end: string;
  }[],
  particle: 1 | 2 | 3 | 4 | 5
): Promise<AxiosResponse> {
  if (typeof storeId === "undefined") return Promise.reject();

  period.forEach((item: { start: string; end: string }) => {
    item.start = convertHyphenDelimiter(item.start);
    item.end = convertHyphenDelimiter(item.end);
  });
  return axiosClient.post<VisitCountComparisonTable>(
    "/stores/chart/visit-count-comparison/",
    {
      store: storeId,
      period: period,
      particle: particle,
    }
  );
}

/**
 * 来店人数テーブルDL用URL生成
 * @param {string} storeId 店舗ID
 * @param {string} start 期間開始日(yyyy-MM-dd)
 * @param {string} end 期間終了日(yyyy-MM-dd)
 * @param {number} particle 表示粒度(1: 日別, 2: 週別, 3: 月別, 4: 時間帯別, 5: 曜日別)
 */
export function downloadVisitCountCsv(
  storeId: string | undefined,
  start: string | undefined,
  end: string | undefined,
  particle: 1 | 2 | 3 | 4 | 5
): Promise<AxiosResponse> {
  if (storeId === undefined || start === undefined || end === undefined)
    return Promise.reject();
  return axiosClient.get<DownloadResponse>(
    "/stores/chart/visit-count-table/download/",
    {
      params: {
        store: storeId,
        start: convertHyphenDelimiter(start),
        end: convertHyphenDelimiter(end),
        particle: particle,
      },
    }
  );
}

/**
 * 期間比較来店人数テーブルDL用URL生成
 * @param {string} storeId 店舗ID
 * @param {Array.<Object>} period 期間
 * @param {string} period.start 期間開始日(yyyy-MM-dd)
 * @param {string} period.end 期間開始日(yyyy-MM-dd)
 * @param {number} particle 表示粒度(1: 日別, 2: 週別, 3: 月別, 4: 時間帯別, 5: 曜日別)
 */
export function downloadVisitCountComparisonCsv(
  storeId: string | undefined,
  period: {
    start: string | undefined;
    end: string | undefined;
  }[],
  particle: 1 | 2 | 3 | 4 | 5
): Promise<AxiosResponse> {
  if (typeof storeId === "undefined") return Promise.reject();
  if (period.some((p) => p.start === undefined || p.end === undefined))
    return Promise.reject();

  period.forEach((item) => {
    item.start = convertHyphenDelimiter(item.start as string);
    item.end = convertHyphenDelimiter(item.end as string);
  });
  return axiosClient.post<DownloadResponse>(
    "/stores/chart/visit-count-comparison/download/",
    {
      store: storeId,
      period: period,
      particle: particle,
    }
  );
}
