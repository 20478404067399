export const OPERATION = {
  CREATE: { value: 1, text: "新規作成" },
  EDIT: { value: 2, text: "編集" },
  DELETE: { value: 3, text: "削除" },
} as const;

export const TARGET = {
  FAVORITE: { value: 1, text: "お気に入り条件" },
  COMPARE: { value: 2, text: "店舗比較リスト" },
} as const;

export const UserType = {
  FULL_ADMIN: 1,
  GENERAL: 2,
  LIMITED_ADMIN: 3,
};
