import Papa from 'papaparse'
import { Feature, GeoJSON } from '@/commons/types/GeoJSON'

export async function csvToGeoJson(url: string): Promise<GeoJSON> {
  const response = await fetch(url)
  const csv = await response.text()

  return new Promise((resolve, reject) => {
    Papa.parse<any[]>(csv, {
      header: false,
      skipEmptyLines: true,
      complete: (results) => {
        const features: Feature[] = results.data.map((row, index) => {
          const id = row[0]
          const bbox = JSON.parse(row[1])
          const geometry = JSON.parse(row[2])

          return {
            id: index,
            type: 'Feature',
            bbox: [bbox.xmin, bbox.ymin, bbox.xmax, bbox.ymax],
            geometry,
            properties: {
              id
            }
          }
        })

        const geojson: GeoJSON = {
          type: 'FeatureCollection',
          features: features
        }

        resolve(geojson)
      },
      error: (error: unknown) => {
        reject(error)
      }
    })
  })
}
