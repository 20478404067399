import { axiosClient } from "@/plugins/axios";
import { AxiosResponse } from "axios";
import { DownloadResponse } from "@/commons/interfaces/responses/download";
import qs from "qs";
import { convertHyphenDelimiter } from "@/commons/utils/dateUtil";

/**
 * 来店頻度チャートDL用URL生成
 * @param storeIds 店舗IDのリスト
 */
export function downloadVisitFrequencyChart(
  storeIds: string[],
  start: string,
  end: string
): Promise<AxiosResponse<DownloadResponse>> {
  return axiosClient.get<DownloadResponse>(
    "/stores/chart/visit-frequency/store-comparison/download/",
    {
      params: {
        store: storeIds,
        start: convertHyphenDelimiter(start),
        end: convertHyphenDelimiter(end),
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    }
  );
}

/**
 * エンゲージメントチャートDL用URL生成
 * @param storeIds 店舗IDのリスト
 */
export function downloadEngagementChart(
  storeIds: string[],
  start: string,
  end: string
): Promise<AxiosResponse<DownloadResponse>> {
  return axiosClient.get<DownloadResponse>(
    "/stores/chart/engagement/store-comparison/download/",
    {
      params: {
        store: storeIds,
        start: convertHyphenDelimiter(start),
        end: convertHyphenDelimiter(end),
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    }
  );
}
