import { axiosClient } from "@/plugins/axios";
import { AxiosResponse } from "axios";
import { convertHyphenDelimiter } from "@/commons/utils/dateUtil";
import { DownloadResponse } from "@/commons/interfaces/responses/download";
import qs from "qs";

/**
 * 来店人数店舗比較DL
 * @param {string[]} storeIds 対象の店舗IDs
 * @param {number} particle 表示粒度。日別、週別、月別、時間帯別、曜日別の5種。
 * @param {string} start 期間開始日(yyyy-MM-dd)
 * @param {string} end 期間終了日(yyyy-MM-dd)
 * @returns
 */
export function downloadVisitCountChartCSV(
  storeIds: string[],
  particle: number,
  start: string,
  end: string
): Promise<AxiosResponse<DownloadResponse>> {
  return axiosClient.get<DownloadResponse>(
    "/stores/chart/visit-count-chart/store-comparison/download/",
    {
      params: {
        store: storeIds,
        particle: particle,
        start: convertHyphenDelimiter(start),
        end: convertHyphenDelimiter(end),
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    }
  );
}
