<template>
  <div class="card-container mb-0">
    <v-card-title class="pa-0 d-flex align-center">
      <div class="map-title">
        <div class="map-title-left">商圏マップ・シェア獲得率</div>
        <div class="map-title-right">
          <ChartDescriptionTooltip
            menu-key="analytics"
            sub-menu-key="bizArea"
            chart-key="bizAreaMap"
          />
        </div>
      </div>
    </v-card-title>

    <div class="mb-20px">
      <FilterForm :selected-prefecture-ids="props.selectedPrefectureIds" :on-apply="handleApply" />
    </div>
    <v-container class="pa-0" fluid>
      <v-row class="my-0" dense>
        <v-col class="py-0">
          <div v-if="!props.baseChainId" class="no-area-overlay">
            <span>基準となるチェーンを選択してください</span>
          </div>
          <BizAreaMap
            v-else
            :is-loading="isLoading"
            :selected-prefecture-ids="props.selectedPrefectureIds"
            :base-chain-id="props.baseChainId"
            :granularity="granularity"
            :features="canShownFeatures"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script setup lang="ts">
import ChartDescriptionTooltip from '@/commons/components/Elements/ChartDescriptionTooltip.vue'
import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { BizAreaAnalysisComparisonMapResponse, ComparisonMapBoxFeatures } from '../types'
import BizAreaMap from './BizAreaMap.vue'
import { GRANULARITY } from '@/commons/enums'
import * as notify from '@/plugins/notification'
import { getBizAreaComparisonMap } from '../axios'
import { AxiosError } from 'axios'
import FilterForm from '@/commons/components/BizArea/FilterForm.vue'
import { toRefs } from 'vue'

/* --------------------------------------------------------------------------
  props
 ---------------------------------------------------------------------------*/

const props = withDefaults(
  defineProps<{
    baseChainId: string | undefined
    initialSelectedGranularity?: (typeof GRANULARITY)[keyof typeof GRANULARITY]
    selectedChainIds?: string[]
    selectedPrefectureIds?: number[]
    onAlertAreaNamesChange?: (areaNames: string[]) => void
    onGranularityChange?: (granularity: (typeof GRANULARITY)[keyof typeof GRANULARITY]) => void
  }>(),
  {
    baseChainId: undefined,
    initialSelectedGranularity: GRANULARITY.PREFECTURE,
    selectedChainIds: () => [],
    selectedPrefectureIds: () => [],
    onAlertAreaNamesChange: () => undefined,
    onGranularityChange: () => undefined
  }
)

/* --------------------------------------------------------------------------
  Vuex
 ---------------------------------------------------------------------------*/

const store = useStore()
const startMonth = computed<string>(() => store.state.startMonth)
const endMonth = computed<string>(() => store.state.endMonth)
const chainIdToColorMap = computed<Map<string, string>>(() => store.state.chainIdToColorMap)

/* --------------------------------------------------------------------------
  core
 ---------------------------------------------------------------------------*/

// 適用されている表示単位（都道府県、市区町村、町丁目）
const granularity = ref<(typeof GRANULARITY)[keyof typeof GRANULARITY]>(
  props.initialSelectedGranularity
)

// ローディング
const isLoading = ref<boolean>(false)

// チェーン比較の商圏マップ取得 API レスポンス
const areasResponse = ref<BizAreaAnalysisComparisonMapResponse | undefined>(undefined)

// フィルタ条件
const filterConfig = ref<{
  prefectureIds: number[]
  cityIds?: string[]
  granularity: (typeof GRANULARITY)[keyof typeof GRANULARITY]
}>({
  prefectureIds: [],
  cityIds: undefined,
  granularity: GRANULARITY.PREFECTURE
})

// 適用ボタンで発火するコールバック関数
const handleApply = (args: { prefectureId?: number; cityIds?: string[] }) => {
  const { prefectureId, cityIds } = args

  // 絞り込みを行っていない状態で絞り込み解除を行った場合は何もしない
  if (!prefectureId && !cityIds && filterConfig.value.granularity === GRANULARITY.PREFECTURE) return

  const newGranularity =
    cityIds && cityIds.length > 0 ? GRANULARITY.TOWN_AND_AREA : GRANULARITY.MUNICIPALITIES

  filterConfig.value = {
    prefectureIds: prefectureId ? [prefectureId] : [],
    cityIds: cityIds && cityIds.length > 0 ? cityIds : undefined,
    granularity: newGranularity
  }

  if (props.onGranularityChange) {
    props.onGranularityChange(newGranularity)
  }

  fetchAreas()
}

// チェーン比較の商圏マップ取得関数
const fetchAreas = async () => {
  if (isLoading.value || chainIdToColorMap.value.size !== props.selectedChainIds.length) return

  if (!props.selectedPrefectureIds.some((id) => filterConfig.value.prefectureIds.includes(id))) {
    filterConfig.value = {
      prefectureIds: props.selectedPrefectureIds,
      cityIds: undefined,
      granularity: GRANULARITY.PREFECTURE
    }
  }

  if (
    props.selectedChainIds.length === 0 ||
    !props.baseChainId ||
    !props.selectedChainIds.includes(props.baseChainId)
  ) {
    areasResponse.value = undefined
    return
  }

  try {
    isLoading.value = true

    const { prefectureIds, cityIds, granularity } = (() => {
      if (filterConfig.value.granularity === GRANULARITY.PREFECTURE) {
        return {
          prefectureIds: props.selectedPrefectureIds,
          cityIds: undefined,
          granularity: GRANULARITY.PREFECTURE
        }
      } else {
        return filterConfig.value
      }
    })()

    const res = await getBizAreaComparisonMap({
      baseChainId: props.baseChainId,
      chainIds: props.selectedChainIds,
      prefectureIds,
      cityIds,
      start: startMonth.value,
      end: endMonth.value,
      mapOptions: {
        granularity
      }
    })
    areasResponse.value = res.data
  } catch (e: any) {
    if (e.code !== AxiosError.ERR_CANCELED)
      notify.notifyErrorMessage('商圏ランキングが表示できませんでした。')
  } finally {
    isLoading.value = false
  }
}

// プライバシーアラートが発生している地域の色を塗らないように対象から外す
const canShownFeatures = computed<ComparisonMapBoxFeatures[]>(() => {
  if (!areasResponse.value) return []
  return areasResponse.value.features.filter(({ properties }) => !properties.isPrivacyAlert)
})

// チェーン比較の商圏マップ API レスポンスから算出したアラート情報
const alertAreaNames = computed<string[]>(() => {
  if (!areasResponse.value) return []
  return areasResponse.value.features
    .filter(({ properties }) => properties.isPrivacyAlert)
    .map(({ properties }) => properties.areaName)
})

/* --------------------------------------------------------------------------
  created
 -------------------------------------------------------------------------- */

if (props.selectedChainIds.length > 0 && props.baseChainId) {
  fetchAreas()
}

/* --------------------------------------------------------------------------
  watch
 ---------------------------------------------------------------------------*/

const propsToRefs = toRefs(props)

watch([propsToRefs.baseChainId, propsToRefs.selectedChainIds, startMonth, endMonth], () => {
  fetchAreas()
})

watch(chainIdToColorMap, () => {
  fetchAreas()
})

watch(
  () => alertAreaNames,
  () => {
    props.onAlertAreaNamesChange(alertAreaNames.value)
  },
  { deep: true }
)
</script>

<style lang="scss" scoped>
.mb-0 {
  margin-bottom: 0;
}
.mb-16px {
  margin-bottom: 16px;
}
.mb-20px {
  margin-bottom: 20px;
}
.mb-21px {
  margin-bottom: 21px;
}
.mr-27px {
  margin-right: 27px;
}
.font-bold {
  font-weight: bold;
}
.map-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;
}

.map-title-left {
  text-align: left;
  font: normal normal bold 16px/40px Noto Sans JP;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.map-title-right {
  display: flex;
  gap: 14px;
  align-items: center;
}

.apply-button {
  display: flex block;
  align-items: center;
  justify-content: center;
  margin-left: 15px;
  width: 50px;
  height: 28px;
  padding: 0 !important;
  background: #222222 0% 0% no-repeat padding-box;
  box-shadow: 1px 1px 0px #00000029;
  border-radius: 4px;
  text-align: center;
  font: normal normal bold 12px/40px Noto Sans JP;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.biz-area-sub-setting {
  height: 48px;
  background-color: #f5f5f5;
  opacity: 0.9;
  padding: 13px 15px 14px 16px;
  display: flex;
  align-items: center;
  margin-left: 42px;
  font-size: 14px;
}

.card-container {
  height: 100%;
  .no-area-overlay {
    background: #eeeeee;
    width: 100%;
    height: 100%;
    min-height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.card-top {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.biz-area-table-title {
  display: flex;
  b {
    margin-right: 8px;
    font: normal normal bold 14px/30px Noto Sans JP;
  }
}
.base-store {
  display: flex;
  align-items: center;
  text-align: left;
  font: normal normal normal 14px/30px Noto Sans JP;
  color: #666666;
}
</style>
