import { BehavioralDna, TrendBehavioralDnaResponse } from "@/features/Trend/TrendStore/interfaces";

export function processBehavioralDnaChartData(response: TrendBehavioralDnaResponse) {
  type GetTooltipElement = {
    category: string;
    score: string;
    type: "total" | "male" | "female";
  };
  const getTooltipElement = ({ category, score, type }: GetTooltipElement) => {
    const typeDisplay = (() => {
      if (type === "total") return "合計";
      if (type === "male") return "男性";
      if (type === "female") return "女性";
    })();
    return `
      <div style='display: flex; justify-content: center; align-items: center; height: 60px; color: #222222; font-size: 12px !important;'>
        <div style='display:table-cell; vertical-align:middle; text-align: left;'>
          <div>[カテゴリ] <b>${category}</b></div>
          <div>[${typeDisplay}スコア] <b>${score}</b></div>
        </div>
      </div>
    `;
  };
  const isTrueOrNull = (value: boolean | null) => value || value === null;
  const tmpArray: (
    | string
    | number
    | { type: string; role: string; p: { html: boolean } }
    | { role: string }
  )[][] = response.visitCounts.map((item: BehavioralDna) => {
    return [
      item.firstCategory.name + " › " + item.secondCategory.name,
      item.secondCategory.deviationValue.total ?? NaN,
      getTooltipElement({
        category: item.firstCategory.name + " › " + item.secondCategory.name,
        score: String(item.secondCategory.deviationValue.total ?? "--"),
        type: "total",
      }),
      isTrueOrNull(item.secondCategory.deviationValue.totalAlert) ? "#bbb" : "",
      item.secondCategory.deviationValue.male ?? NaN,
      getTooltipElement({
        category: item.firstCategory.name + " › " + item.secondCategory.name,
        score: String(item.secondCategory.deviationValue.male ?? "--"),
        type: "male",
      }),
      isTrueOrNull(item.secondCategory.deviationValue.maleAlert) ? "#bbb" : "",
      item.secondCategory.deviationValue.female ?? NaN,
      getTooltipElement({
        category: item.firstCategory.name + " › " + item.secondCategory.name,
        score: String(item.secondCategory.deviationValue.female ?? "--"),
        type: "female",
      }),
      item.secondCategory.deviationValue.femaleAlert ||
      item.secondCategory.deviationValue.femaleAlert === null
        ? "#bbb"
        : "",
    ];
  });
  tmpArray.unshift([
    "categoryName",
    "全体",
    { type: "string", role: "tooltip", p: { html: true } },
    { role: "style" },
    "男性",
    { type: "string", role: "tooltip", p: { html: true } },
    { role: "style" },
    "女性",
    { type: "string", role: "tooltip", p: { html: true } },
    { role: "style" },
  ]);
  return tmpArray;
}

export function processBehavioralDnaThirdCategoryData(
  response: TrendBehavioralDnaResponse
) {
  return response.visitCounts.map(
    (item: BehavioralDna) => {
      return {
        firstCategoryName: item.firstCategory.name,
        secondCategoryName: item.secondCategory.name,
        thirdCategory: item.thirdCategory.sort((a, b) => {
          if (a.name !== b.name) {
            if (a.name > b.name) return -1;
            if (a.name < b.name) return 1;
          }
          return 0;
        }),
      };
    }
  );
}