<template>
  <v-container
    class="pa-0"
    fluid
    style="position: relative"
  >
    <div
      v-if="hasAlert"
      class="overlay"
    >
      <div class="overlay-message">
        <p>取得データボリュームが少なく、</p>
        <p>統計上の信頼性の低いデータが含まれています。</p>
        <p>参考値としてご参照ください。</p>
        <v-btn
          variant="text"
          style="text-decoration: underline; color: white"
          @click="onClickVOverlay()"
        >
          閉じる
        </v-btn>
      </div>
    </div>
    <GChart
      type="AreaChart"
      :data="chartData"
      :options="options"
    />
  </v-container>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { GChart } from "vue-google-charts";

export default defineComponent({
  name: "BizAreaShareChart",
  components: {
    GChart,
  },
  props: {
    chartData: {
      type: Array,
      default() {
        return [];
      },
    },
    hasAlert: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  emits: ["clickApproveAlert"],
  computed: {
    options() {
      return {
        areaOpacity: 0.1,
        chartArea: {
          top: 10,
          left: 75,
          right: 40,
          bottom: 50,
        },
        colors: ["DE5A69"],
        hAxis: {
          format: "percent",
          textStyle: {
            fontSize: 12,
          },
          slantedText: true,
          slantedTextAngle: 270,
        },
        height: 330,
        isStacked: true,
        legend: {
          position: "none",
        },
        lineWidth: 3,
        pointSize: 6,
        tooltip: {
          isHtml: true,
        },
        vAxis: {
          format: "percent",
          textStyle: {
            fontSize: 12,
          },
          baselineColor: "#ccc",
          gridlines: {
            color: "#ddd",
          },
          minValue: 0,
        },
      };
    },
  },
  methods: {
    onClickVOverlay() {
      this.$emit("clickApproveAlert");
    },
  },
});
</script>

<style scoped>
::-webkit-scrollbar {
  -webkit-appearance: none;
  height: 7px;
  width: 7px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.overlay {
  z-index: 3;
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
}
.overlay-message {
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}
.overlay-message p {
  margin-bottom: 11px;
}
</style>
