export * from "./process";

import { COLOR, SUB_TYPE } from "@/commons/enums";
/**
 * チャートデータから凡例用データに加工して出力
 * @param chartData チャートのデータ
 * @param subType 内訳
 * @param isCompare 比較期間があるかどうか
 * @returns { text: string, color: string }[]
 */
export function getLegends(
  chartData: (string | number | any)[][],
  subType: (typeof SUB_TYPE)[keyof typeof SUB_TYPE],
  isCompare: boolean
) {
  if (chartData.length === 0) return [];

  if (isCompare)
    // [ [ "date", "2022/07/04-2022/07/10", { "role": "tooltip" }, { "role": "style" },
    //             "2022/06/27-2022/07/03", { "role": "tooltip" }, { "role": "style" } ]
    return [
      { text: chartData[0][1], color: COLOR.RED },
      { text: chartData[0][4], color: COLOR.BLUE },
    ];

  if (subType === SUB_TYPE["NONE"])
    // [[ "date", "2022/07/04-2022/07/10", { "role": "tooltip" }, { "role": "style" }] ... ]
    return [{ text: chartData[0][1], color: COLOR.RED }];

  if (subType === SUB_TYPE["GENDER"])
    // [[ "date", "男性", { "role": "tooltip" ... }, { "role": "style" },
    //            "女性", { "role": "tooltip" }, { "role": "style" }] ... ]
    return [
      { text: chartData[0][1], color: COLOR.BLUE },
      { text: chartData[0][4], color: COLOR.RED },
    ];

  if (subType === SUB_TYPE["AGE"])
    // [[ "date", "10代", { "role": "tooltip" }, { "role": "style" },
    //            "20代", { "role": "tooltip" }, { "role": "style" },
    //            "30代", { "role": "tooltip" }, { "role": "style" },
    //            "40代", { "role": "tooltip" }, { "role": "style" },
    //            "50代", { "role": "tooltip" }, { "role": "style" },
    //            "60代〜", { "role": "tooltip" }, { "role": "style" }] ... ]
    return [
      { text: chartData[0][1], color: COLOR.RED },
      { text: chartData[0][4], color: COLOR.BLUE },
      { text: chartData[0][7], color: COLOR.GREEN },
      { text: chartData[0][10], color: COLOR.ORANGE },
      { text: chartData[0][13], color: COLOR.PURPLE },
      { text: chartData[0][16], color: COLOR.BROWN },
    ];

  return [];
}
