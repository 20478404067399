import {
  VisitCountGraph,
  VisitCountGraphItem,
} from "@/features/ShopAnalytics/interfaces/response";
import { COLOR, DAYS, PARTICLE_TYPE } from "@/commons/enums";

const getTooltipHTML = (
  granularity: string,
  value: number,
  particle: (typeof PARTICLE_TYPE)["DAY"] | (typeof PARTICLE_TYPE)["HOUR"]
) => {
  let value1 = "";
  let value2 = "";
  switch (particle) {
    case PARTICLE_TYPE["HOUR"]: {
      value1 = "[時間帯] ";
      value2 = granularity;
      break;
    }
    default: {
      value1 = "[曜日] ";
      value2 = granularity + "曜日";
      break;
    }
  }
  return `<div style='display: flex; justify-content: center; align-items: center; height: 55px; color: #222222; font-size: 12px !important;'>
            <div style='display:table-cell; vertical-align:middle; text-align: left;'>
              <dev>${value1}<b>${value2}</b></dev>
              <div>[平均来店人数] <b>${value.toLocaleString(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}人</b></div>
            </div>
          </div>`;
};

const getComparisonTooltipHTML = (
  baseStart: string,
  baseEnd: string,
  comparisonStart: string,
  comparisonEnd: string,
  granularity: string,
  baseValue: number,
  comparisonValue: number,
  particle: (typeof PARTICLE_TYPE)["DAY"] | (typeof PARTICLE_TYPE)["HOUR"]
) => {
  let value1 = "";
  let value2 = "";
  switch (particle) {
    case PARTICLE_TYPE["HOUR"]: {
      value1 = "[時間帯] ";
      value2 = granularity;
      break;
    }
    default: {
      value1 = "[曜日] ";
      value2 = granularity + "曜日";
      break;
    }
  }
  return `<div style='display: flex; justify-content: center; align-items: center; height: 150px; min-width: 150px; color: #222222; font-size: 12px !important;'>
            <div style='display:table-cell; vertical-align:middle; text-align: left;'>
              <div style='text-align: left'>＜<span style="color: ${
                COLOR.RED
              }">● </span>${baseStart} - ${baseEnd}＞</div>
              <div style='text-align: left'>${value1}<b>${value2}</b></div>
              <div style='margin-bottom: 10px'>[平均来店人数] <b>${baseValue.toLocaleString(
                undefined,
                { minimumFractionDigits: 0, maximumFractionDigits: 0 }
              )}人</b></div>
              <div style='text-align: left'>＜<span style="color: ${
                COLOR.BLUE
              }">● </span>${comparisonStart} - ${comparisonEnd}＞</div>
              <div style='text-align: left'>${value1}<b>${value2}</b></div>
              <div style='text-align: left'>[平均来店人数] <b>${comparisonValue.toLocaleString(
                undefined,
                { minimumFractionDigits: 0, maximumFractionDigits: 0 }
              )}人</b></div>
            </div>
          </div>`;
};

const getAlertStyle = (isAlert: boolean | undefined) => {
  return isAlert ? "#bbbbbb" : "";
};

export function processChartData(
  particle: (typeof PARTICLE_TYPE)["DAY"] | (typeof PARTICLE_TYPE)["HOUR"],
  response: VisitCountGraph,
  start: string,
  end: string
): (string | number)[][] {
  /**
   * '/stores/chart/visit-count-graph/' で受け取った
   * レスポンスデータを google chart のデータ形式に加工するメソッド
   * ex)
   * [
   *   ["曜日", "2022-01-01 - 2022-03-01", { role: "tooltip" }, { role: "style"} ],
   *   ["月",   10000,                     <div>...</div>,      "#eeeeee"        ],
   *   ["火",   20000,                     <div>...</div>,      ""               ],
   *   ["水",   30000,                     <div>...</div>,      "#eeeeee"        ],
   *   ...
   * ]
   */
  let processedData: (string | number | any)[][] = [];

  if (particle === PARTICLE_TYPE["DAY"])
    response.visitCounts.chartItems.forEach((element) => {
      const tmp: (string | number)[] = [];
      tmp.push(element.granularity + "曜日");
      tmp.push(element.value);
      tmp.push(getTooltipHTML(element.granularity, element.value, particle));
      tmp.push(getAlertStyle(element.isAlert));
      processedData.push(tmp);
    });

  if (particle === PARTICLE_TYPE["HOUR"]) {
    const times: string[] = ["8-10", "11-13", "14-16", "17-19", "20-22"];

    processedData = times.map((time: string) => {
      const targetData = response.visitCounts.chartItems.filter(
        (chartItem: VisitCountGraphItem) => {
          return time === chartItem.granularity;
        }
      );
      const legend = `${time.replace("-", "〜")}時`;
      let value1 = 0 as number | null,
        tooltipElement1 = "",
        pointElement1 = "",
        value2 = 0 as number | null,
        tooltipElement2 = "",
        pointElement2 = "";
      targetData.forEach((element) => {
        if (element.subIndex === DAYS.WEEKDAYS.value) {
          value1 = element.value;
          tooltipElement1 = getTooltipHTML(legend, element.value, particle);
          pointElement1 = getAlertStyle(element.isAlert);
        } else if (element.subIndex === DAYS.HOLIDAYS.value) {
          value2 = element.value;
          tooltipElement2 = getTooltipHTML(legend, element.value, particle);
          pointElement2 = getAlertStyle(element.isAlert);
        }
      });
      return [
        legend,
        value1,
        tooltipElement1,
        pointElement1,
        value2,
        tooltipElement2,
        pointElement2,
      ];
    });
  }

  let header: (string | object)[] = [];
  if (particle === PARTICLE_TYPE["DAY"]) {
    header = [
      "曜日",
      `${start} - ${end}`,
      { type: "string", role: "tooltip", p: { html: true } },
      { role: "style" },
    ];
  } else if (particle === PARTICLE_TYPE["HOUR"]) {
    header = [
      "時間",
      `${DAYS.WEEKDAYS.text} ${start} - ${end}`,
      { type: "string", role: "tooltip", p: { html: true } },
      { role: "style" },
      `${DAYS.HOLIDAYS.text} ${start} - ${end}`,
      { type: "string", role: "tooltip", p: { html: true } },
      { role: "style" },
    ];
  }

  processedData.unshift(header);
  return processedData;
}

export function processComparisonChartData(
  particle: (typeof PARTICLE_TYPE)["DAY"] | (typeof PARTICLE_TYPE)["HOUR"],
  base_response: VisitCountGraph,
  base_start: string,
  base_end: string,
  comparison_response: VisitCountGraph,
  comparison_start: string,
  comparison_end: string
): (string | number)[][] {
  /**
   * '/stores/chart/visit-count-graph/' で受け取った
   * レスポンスデータを google chart のデータ形式に加工するメソッド
   * ex)
   * [
   *   ["曜日", "2022-01-01 - 2022-03-01", { role: "tooltip" }, { role: "style"}, "2022-04-01 - 2022-06-01", { role: "tooltip" }, { role: "style"} ],
   *   ["月",   10000,                     <div>...</div>,      "#eeeeee",        30000,                     <div>...</div>,      "#eeeeee"        ],
   *   ["火",   20000,                     <div>...</div>,      "",               10000,                     <div>...</div>,      ""               ],
   *   ["水",   30000,                     <div>...</div>,      "#eeeeee",        10000,                     <div>...</div>,      "#eeeeee"        ],
   *   ...
   * ]
   */
  const processedData: (string | number | any)[][] = [];
  const header = [
    particle === PARTICLE_TYPE["DAY"] ? "曜日" : "時間",
    `${base_start} - ${base_end}`,
    { type: "string", role: "tooltip", p: { html: true } },
    { role: "style" },
    `${comparison_start} - ${comparison_end}`,
    { type: "string", role: "tooltip", p: { html: true } },
    { role: "style" },
  ];

  processedData.push(header);

  if (particle === PARTICLE_TYPE["DAY"])
    base_response.visitCounts.chartItems.forEach((element, index) => {
      const tmp: (string | number)[] = [];
      const cChartItem = comparison_response.visitCounts.chartItems[index];

      tmp.push(element.granularity + "曜日");
      tmp.push(element.value);
      tmp.push(
        getComparisonTooltipHTML(
          base_start,
          base_end,
          comparison_start,
          comparison_end,
          element.granularity,
          element.value,
          cChartItem.value,
          particle
        )
      );
      tmp.push(getAlertStyle(element.isAlert));
      tmp.push(cChartItem.value);
      tmp.push(
        getComparisonTooltipHTML(
          base_start,
          base_end,
          comparison_start,
          comparison_end,
          element.granularity,
          element.value,
          cChartItem.value,
          particle
        )
      );
      tmp.push(getAlertStyle(cChartItem.isAlert));
      processedData.push(tmp);
    });

  if (particle === PARTICLE_TYPE["HOUR"]) {
    let baseValue = 0;
    let comparisonValue = 0;
    base_response.visitCounts.chartItems.forEach((element, index) => {
      const cChartItem = comparison_response.visitCounts.chartItems[index];
      baseValue += element.value;
      comparisonValue += cChartItem.value;

      const tmp: (string | number)[] = [];
      const legend = `${element.granularity.replace("-", "〜")}時`;
      tmp.push(legend);
      tmp.push(baseValue);
      tmp.push(
        getComparisonTooltipHTML(
          base_start,
          base_end,
          comparison_start,
          comparison_end,
          legend,
          element.value,
          cChartItem.value,
          particle
        )
      );
      tmp.push(getAlertStyle(element.isAlert));
      tmp.push(comparisonValue);
      tmp.push(
        getComparisonTooltipHTML(
          base_start,
          base_end,
          comparison_start,
          comparison_end,
          legend,
          element.value,
          cChartItem.value,
          particle
        )
      );
      tmp.push(getAlertStyle(cChartItem.isAlert));
      processedData.push(tmp);
      // 初期化
      baseValue = 0;
      comparisonValue = 0;
    });
  }

  return processedData;
}
