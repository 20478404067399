<template>
  <div class="view-container">
    <div class="title">
      マニュアル
    </div>
    <div class="text">
      『ショッパーみえーる』の操作ガイドをご覧いただけます。
    </div>
    <div
      class="link-container"
      @click="openLink"
    >
      <div class="link-left">
        <div class="link-head">
          PDF
        </div>
        <div class="link-text">
          ショッパーみえーる 操作ガイド Ver1.0（2022年12月版）
        </div>
      </div>
      <div class="link-right">
        <img
          class="link-icon"
          src="@/assets/svg/external-link-gray.svg"
        >
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "ManualView",
  methods: {
    openLink() {
      window.open(
        "https://storage.googleapis.com/mieru-frontend-static/%E3%82%B7%E3%83%A7%E3%83%83%E3%83%8F%E3%82%9A%E3%83%BC%E3%81%BF%E3%81%88%E3%83%BC%E3%82%8B_%E6%93%8D%E4%BD%9C%E3%82%AB%E3%82%99%E3%82%A4%E3%83%88%E3%82%99.pdf",
        "_blank"
      );
    },
  },
});
</script>

<style lang="scss" scoped>
.view-container {
  width: 100%;
  height: 600px;
  margin-top: 35.5px;
  padding: 47px 60px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 3px #00000029;
  text-align: left;
  letter-spacing: 0px;
  color: #222222;

  .title {
    margin-bottom: 40px;
    font: normal normal 700 24px/40px Noto Sans JP;
    font-size: 1.25rem!important;    
    letter-spacing: .0125em!important;
    font-family: Roboto,sans-serif!important;
  }
  .text {
    font: normal normal normal 14px/30px Noto Sans JP;
    margin-bottom: 30px;
  }
  .link-container {
    width: 100%;
    height: 55px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 1px 1px 0px #00000029;
    border: 1px solid #cccccc;
    padding: 17px 20px;
    cursor: pointer;
    align-items: center;
    display: flex;

    .link-left {
      width: 100%;
      float: left;
      display: flex;

      .link-head {
        width: 32px;
        height: 20px;
        background: #0e182e 0% 0% no-repeat padding-box;
        border-radius: 2px;
        font: normal normal bold 10px/30px Noto Sans JP;
        color: #ffffff;
        text-align: center;
        line-height: 2;
      }
      .link-text {
        margin-left: 14px;
        font: normal normal normal 14px/20px Noto Sans JP;
      }
    }
    .link-right {
      float: right;

      .link-icon {
        text-align: end;
        width: 13px;
        height: 13px;
      }
    }
  }
}
</style>
