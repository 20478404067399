<template>
  <input
    v-model="childText"
    :type="type"
    :placeholder="placeholder"
    class="custom-input"
    :class="{
      'custom-primary': variant === 'primary',
      'custom-borderless': variant === 'borderless',
      'disabled-form': readonly,
      'error-input': error,
    }"
    :style="`width: ${width}; height: ${height}; min-width: ${childMinWidth}; min-height: ${childMinHeight}`"
    :readonly="readonly"
  >
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    variant: {
      type: String as PropType<"primary" | "borderless">,
      default: "primary",
    },
    modelValue: {type: String, default: "", required: true },
    type: {
      type: String,
      default() {
        return "text";
      },
    },
    placeholder: { type: String, default: "" },
    width: { type: String, default: "360px" },
    height: { type: String, default: "44px" },
    minWidth: { type: String, default: "" },
    minHeight: { type: String, default: "" },
    readonly: { type: Boolean, default: false },
    error: { type: Boolean, default: false },
  },
  emits: ['update:modelValue'],
  computed: {
    childText: {
      get() {
        return this.modelValue;
      },
      set(val: string) {
        this.$emit("update:modelValue", val);
      },
    },
    childMinWidth(): string {
      if (!this.minWidth) return this.width;
      else return this.minWidth;
    },
    childMinHeight(): string {
      if (!this.minHeight) return this.height;
      else return this.minHeight;
    },
  },
});
</script>

<style lang="scss" scoped>
// variants
.custom-primary {
  background: white;
  padding: 12px 15px;
  border: 1px solid #ccc;
  border-radius: 4px;

  &:hover {
    outline: none;
    border: 1px solid #666;
  }

  &:focus {
    outline: none;
    border: 1px solid #666;
  }
}

.custom-borderless {
  &:hover {
    outline: none;
  }

  &:focus {
    outline: none;
  }
}

.custom-input {
  font: normal normal normal 14px/20px Noto Sans JP;

  &::placeholder {
    color: #999;
  }
}
.error-input {
  border: 1px solid #bc0711 !important;
  background: #fff5f5 !important;
}
input.disabled-form {
  background-color: #f5f5f5 !important;
}
</style>
