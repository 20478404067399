import { axiosClient } from "../../plugins/axios";
import { AxiosResponse } from "axios";
import { ViewableCompanyResponse } from "@/commons/interfaces";
import { Company } from "@/features/Dashboard/types";

/**
 * 企業一覧の取得
 *
 * @returns 使用できる企業の一覧を取得する axios の Promise
 */
export function getViewableCompanies(): Promise<
  AxiosResponse<ViewableCompanyResponse>
> {
  return axiosClient.get<ViewableCompanyResponse>("/viewable-companies/");
}

/**
 * 企業の設定
 *
 * @returns 使用する企業を設定する axios の Promise
 */
export function setViewableCompanies(
  company: Company
): Promise<AxiosResponse<ViewableCompanyResponse>> {
  return axiosClient.put<ViewableCompanyResponse>("/viewable-companies/", {
    companyId: company.id,
  });
}
