import { AgeGenderResponse } from "@/features/ShopAnalytics/interfaces/response";

export function hasAlertInAgeGenderChart(data: AgeGenderResponse) {
  /**
   * '/stores/chart/age-gender/' で受け取った
   * レスポンスデータに isAlert = true が含まれているか
   */
  for (let i = 0; i < data.visitCounts.length; i++) {
    for (let j = 0; j < data.visitCounts[i].chartItems.length; j++) {
      if (data.visitCounts[i].chartItems[j].isAlert) return true;
    }
  }
  return false;
}

export function hasAlertInAgeGenderCompareChart(
  baseResponse: AgeGenderResponse,
  compareResponse: AgeGenderResponse
) {
  for (let i = 0; i < baseResponse.visitCounts.length; i++) {
    for (let j = 0; j < baseResponse.visitCounts[i].chartItems.length; j++) {
      if (baseResponse.visitCounts[i].chartItems[j].isAlert) return true;
    }
  }
  for (let i = 0; i < compareResponse.visitCounts.length; i++) {
    for (let j = 0; j < compareResponse.visitCounts[i].chartItems.length; j++) {
      if (compareResponse.visitCounts[i].chartItems[j].isAlert) return true;
    }
  }
  return false;
}
