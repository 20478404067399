import { axiosClient } from '@/plugins/axios'
import { AxiosResponse } from 'axios'
import { GeoCitiesResponse } from '@/commons/interfaces'

/**
 * 市区町村ポリゴンデータの署名付きURL取得
 *
 * @param prefectureId - 都道府県ID
 * @returns Response Promise
 */
export function getCitiesGeo(prefectureId: string): Promise<AxiosResponse<GeoCitiesResponse>> {
  return axiosClient.get<GeoCitiesResponse>('/cities/geo/', { params: { prefectureId:prefectureId } })
}