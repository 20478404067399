import { axiosClient } from '@/plugins/axios'
import { AxiosResponse } from 'axios'
import { convertHyphenDelimiter } from '@/commons/utils/dateUtil'
import { CombinationChainResponse } from '@/features/ChainAnalytics/combination/interfaces'
import { DownloadResponse } from '@/commons/interfaces/responses/download'
import { GENDER } from '@/commons/enums'

/**
 * 併用チェーン取得
 * @param {string | undefined} chainId チェーンID
 * @param {number[]} prefectureIds 都道府県ID群
 * @param {string} start 期間開始日(YYYY-mm-DD)
 * @param {string} end 期間終了日(YYYY-mm-DD)
 * @param {string | null} gender 性別(male | female)
 */
export function getCombinationAnalysisChart(
  chainId: string | undefined,
  prefectureIds: number[],
  start: string,
  end: string,
  gender: (typeof GENDER)[keyof typeof GENDER]['value'] | undefined
): Promise<AxiosResponse<CombinationChainResponse>> {
  if (!chainId) return Promise.reject()
  return axiosClient.get<CombinationChainResponse>(
    '/chains/chart/combination-analysis/',
    {
      params: {
        chainId,
        prefectureIds,
        start: convertHyphenDelimiter(start),
        end: convertHyphenDelimiter(end),
        gender
      }
    }
  )
}

/**
 * 併用チェーンCSVダウンロード
 * @param {string | undefined} chainId チェーンID
 * @param {number[]} prefectureIds 都道府県ID群
 * @param {string} start 期間開始日(YYYY-mm-DD)
 * @param {string} end 期間終了日(YYYY-mm-DD)
 * @param {string | null} gender 性別(male | female)
 */
export function downloadCombinationAnalysisChart(
  chainId: string | undefined,
  prefectureIds: number[],
  start: string,
  end: string,
  gender: string | undefined
): Promise<AxiosResponse<DownloadResponse>> {
  if (!chainId) return Promise.reject()
  return axiosClient.get<DownloadResponse>(
    '/chains/chart/combination-analysis/download/',
    {
      params: {
        chainId,
        prefectureIds,
        start: convertHyphenDelimiter(start),
        end: convertHyphenDelimiter(end),
        gender
      }
    }
  )
}
