import { axiosClient } from "../../plugins/axios";
import { AxiosResponse } from "axios";
import { convertHyphenDelimiter } from "@/commons/utils/dateUtil";
import { StoresResponse } from "@/commons/interfaces/responses/store";
import {
  StoresComparisonResponse,
  StoreTypeResponse,
} from "@/commons/interfaces";

/**
 * 契約形態に基づく店舗一覧取得
 */
export function getStores(): Promise<AxiosResponse> {
  return axiosClient.get<StoresResponse>("/stores/");
}

/**
 * 契約形態に基づく店舗一覧取得(チェーン用の開店・閉店フラグ付き)
 */
export function getStoresWithOpenClose(start: string, end: string): Promise<AxiosResponse> {
  return axiosClient.get<StoresResponse>("/stores/", {
    params: {
      start: convertHyphenDelimiter(start),
      end: convertHyphenDelimiter(end),
    }
  } );
}

/**
 * 店舗比較リストの取得
 */
export function getStoresComparison(): Promise<
  AxiosResponse<StoresComparisonResponse>
> {
  return axiosClient.get<StoresComparisonResponse>(
    "/stores/comparison/?showMeta=True"
  );
}

/**
 * 企業タイプの取得
 */
export function getStoreTypes(): Promise<AxiosResponse<StoreTypeResponse>> {
  return axiosClient.get<StoreTypeResponse>("/store-types/");
}
