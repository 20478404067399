<template>
  <div class="display-count-pulldown">
    <span class="label">表示件数</span>
    <CustomSelector
      v-model="item"
      width="220px"
      height="38px"
      :items="items"
      size="sm"
      class="mr-3px"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { FILTER_TYPE } from "@/features/RegionalShare/enums";

export default defineComponent({
  name: "DisplayCountPulldown",
  data() {
    return {
      item: FILTER_TYPE.ALL,
      items: [
        FILTER_TYPE.ALL,
        FILTER_TYPE.SHARE_RANK,
        FILTER_TYPE.BASE_AND_SHARE_RANK,
      ],
    };
  },
  watch: {
    item() {
      this.$store.commit("setRegionalShareFilterType", { type: this.item });
    },
  },
});
</script>

<style lang="scss" scoped>
.display-count-pulldown {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-top: 6px;
  margin-left: 30px;
  width: 310px;
  height: 44px;
  background-color: #eeeeee;
  .label {
    font-size: 13px;
    font-weight: bold;
    padding: 13px 16px 13px 13px;
    line-height: 1;
  }
}

.mr-3px {
  margin-right: 3px;
}
</style>
