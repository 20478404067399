import { axiosClient } from '@/plugins/axios'
import { AxiosResponse } from 'axios'
import { GeoTownsResponse, TownResponse } from '../interfaces'

/**
 * 町丁目マスタ取得 API
 *
 * @param cityIds 市区町村IDs
 * @returns 町丁目マスタ取得 Promise
 */
export function getTowns(cityIds: string[]): Promise<AxiosResponse<TownResponse>> {
  if (cityIds.length === 0) return Promise.reject()
  return axiosClient.get<TownResponse>('/towns/', {
    params: {
      cityIds
    }
  })
}

/**
 * 町丁目ポリゴンデータの署名付きURL取得
 *
 * @param prefectureId - 都道府県ID
 * @returns Response Promise
 */
export function getTownsGeo(prefectureId: string): Promise<AxiosResponse<GeoTownsResponse>> {
  return axiosClient.get<GeoTownsResponse>('/towns/geo/', { params: { prefectureId:prefectureId } })
}
