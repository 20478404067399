<template>
  <v-dialog
    :value="dialog"
    max-width="560px"
    @click:outside="close"
  >
    <div class="create-dialog">
      <h1>
        {{ type === "compare" ? "リスト" : "条件" }}名
        <span>（最大30文字。後から編集することもできます）</span>
        <span class="count">
          <span :class="{ 'error-color': name.length > 30 }">
            {{ name.length }}
          </span>
          / 30
        </span>
      </h1>
      <CustomInput
        v-model="name"
        width="480px"
        height="44px"
        :placeholder="type === 'compare' ? 'リスト名を入力' : '条件名を入力'"
        :error="name.length > 30"
      />
      <CustomButton
        variant="primary"
        size="md"
        class="mt-auto"
        width="280px"
        height="46px"
        :disabled="name.length > 30 || loading"
        @click="click"
      >
        この名称で登録
      </CustomButton>
    </div>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from "vue";

export default defineComponent({
  name: "CreateDialog",
  model: {
    prop: "dialog",
    event: "change-dialog",
  },
  props: {
    dialog: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    type: {
      type: String as PropType<"compare" | "favorite">,
      default: "compare",
    },
  },
  emits: ["change-dialog", "clickCreate"],
  setup(props, { emit }) {
    const name = ref("");
    const close = () => {
      emit("change-dialog", false);
    }
    const click = () => {
      emit("clickCreate", { name: name.value });
    }
    return {
      name,
      close,
      click,
    }
  }  
});
</script>

<style lang="scss" scoped>
.create-dialog {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 35px 40px;
  width: 560px;
  height: 220px;
  border-radius: 6px;
  background: white;

  h1 {
    display: flex;
    margin-bottom: 14px;
    text-align: left;
    font-size: 15px;
    font-weight: bold;
    color: #222222;
    width: 100%;

    span {
      font-weight: normal;
      color: #666666;
    }

    .count {
      margin-left: auto;
      font-weight: normal;
      color: #666666;
    }

    .error-color {
      color: #be0000;
    }
  }

  .mb-26px {
    margin-bottom: 26px;
  }

  .mt-auto {
    margin-top: auto;
  }

  .mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
