<template>
  <v-container fluid class="biz_area_table pa-0" style="background-color: white">
    <v-data-table
      v-model:items-per-page="itemsPerPage"
      v-model:page="page"
      :items="areas"
      :items-per-page-options="[10, 20]"
    >
      <template #headers>
        <tr class="table_head">
          <th class="column_head">エリア名</th>
          <th class="column_population">
            <SortButton
              :show-status="{
                up: sortBy.column !== 'population' || !sortBy.desc,
                down: sortBy.column !== 'population' || sortBy.desc
              }"
              @sort-action="sortAreasBy({ targetColumn: 'population' })"
            >
              <div />
              居住人口
            </SortButton>
          </th>
          <th v-for="chain in chains" :key="chain.id" class="column_value">
            <SortButton
              :show-status="{
                up: sortBy.column !== 'chainId' || sortBy.chainId !== chain.id || !sortBy.desc,
                down: sortBy.column !== 'chainId' || sortBy.chainId !== chain.id || sortBy.desc
              }"
              @sort-action="sortAreasBy({ targetColumn: 'chainId', chainId: chain.id })"
            >
              <div />
              <div class="mx-1 chain_name" :title="chain.name">
                {{ chain.name }}
              </div>
            </SortButton>
          </th>
        </tr>
      </template>
      <template #body="props">
        <tr v-for="area in props.items" :key="area.areaId" class="table_body">
          <td class="column_head">
            {{ area.areaName }}
          </td>
          <td class="column_population">
            {{ area.population.toLocaleString() }}
          </td>
          <td
            v-for="chain in area.chains"
            :key="chain.chainId"
            class="column_value"
            :class="{
              'bg-light-blue': chain.chainId === baseChainId,
              'bg-gray': chain.isAlert
            }"
          >
            <span class="text">
              {{ !isNaN(chain.visitRatio) ? (chain.visitRatio * 100).toFixed(1) + '%' : '---' }}
            </span>
            <div class="bar">
              <div
                :style="`width: ${getVisitRatiosWidth(chain.visitRatio * 100)}`"
                :class="{
                  default: chain.chainId !== baseChainId,
                  basechain: chain.chainId === baseChainId
                }"
              />
            </div>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-container>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue'
import { Chain } from '@/commons/interfaces'
import SortButton from '@/commons/components/Elements/SortButton.vue'
import { Area } from '../types'
import { useStore } from 'vuex'

const props = withDefaults(
  defineProps<{
    areas?: Area[]
    baseChainId?: string
    selectedChainIds?: string[]
  }>(),
  {
    areas: () => [],
    baseChainId: undefined,
    selectedChainIds: () => []
  }
)

const store = useStore()
const chainMap = computed<Map<string, Chain>>(() => store.getters.chainMap)

const sortBy = ref({
  column: 'chainId' as 'total' | 'population' | 'chainId',
  desc: true,
  chainId: null as string | null
})
const page = ref(1)
const itemsPerPage = ref(10)

const chains = computed(() => {
  if (props.selectedChainIds.length === 0) return []

  const chains = (
    props.selectedChainIds.map((id) => chainMap.value.get(id)).filter((chain) => !!chain) as Chain[]
  ).filter((value) => value.id != props.baseChainId)

  const baseChain = props.baseChainId ? chainMap.value.get(props.baseChainId) : undefined

  return [...(baseChain ? [baseChain] : []), ...chains]
})

const areas = computed(() => {
  const copyAreas: Area[] = []
  props.areas.forEach((area) => {
    const baseChain = area.chains.find((chain) => chain.chainId === props.baseChainId)

    let chains = []
    if (baseChain) {
      chains = [baseChain, ...area.chains.filter((chain) => chain.chainId !== props.baseChainId)]
      if (sortBy.value.column === 'chainId' && !sortBy.value.chainId) {
        sortBy.value.chainId = baseChain?.chainId
      }
    } else {
      chains = area.chains
    }

    copyAreas.push({
      areaId: area.areaId,
      areaName: area.areaName,
      total: area.total,
      population: area.population,
      chains: chains
    })
  })

  copyAreas.sort((a, b) => {
    if (sortBy.value.column === 'population')
      return sortBy.value.desc ? b.population - a.population : a.population - b.population
    if (sortBy.value.column === 'chainId')
      return sortByChainId(sortBy.value.chainId, sortBy.value.desc, b, a)
    return b.total - a.total
  })

  return copyAreas
})

const areasVisitRatioMax = computed(() =>
  Math.max(
    ...areas.value
      .flatMap((area) => area.chains)
      .filter((chain) => !isNaN(chain.visitRatio))
      .map((chain) => chain.visitRatio * 100)
  )
)

const sortByChainId = (chainId: string | null, desc: boolean, first: Area, second: Area) => {
  const firstValue: number =
    first.chains.find((chain) => chain.chainId === chainId)?.visitRatio ?? 0
  const secondValue: number =
    second.chains.find((chain) => chain.chainId === chainId)?.visitRatio ?? 0

  // NOTE: ソート時NaNは常に最後列
  if (isNaN(firstValue) && isNaN(secondValue)) return 0
  if (isNaN(firstValue)) return -1
  if (isNaN(secondValue)) return 1

  return desc ? firstValue - secondValue : secondValue - firstValue
}

const sortAreasBy = (args: {
  targetColumn: 'total' | 'population' | 'chainId'
  chainId?: string
}) => {
  const { targetColumn, chainId } = args

  if (
    (sortBy.value.column !== 'chainId' && sortBy.value.column === targetColumn) ||
    (sortBy.value.column === 'chainId' && sortBy.value.chainId === chainId)
  ) {
    sortBy.value.desc = !sortBy.value.desc
  } else {
    sortBy.value.column = targetColumn
    sortBy.value.desc = true
    sortBy.value.chainId = targetColumn !== 'chainId' ? null : chainId ?? null
  }
}

const getVisitRatiosWidth = (value: number) => {
  if (areasVisitRatioMax.value === 0 || isNaN(value)) return '0'
  return (value / areasVisitRatioMax.value) * 100 + '%'
}

watch(
  [itemsPerPage, sortBy],
  () => {
    page.value = 1
  },
  { deep: true }
)
</script>

<style lang="scss" scoped>
.biz_area_table {
  background-color: white;

  .v-data-table {
    white-space: nowrap;

    th {
      border-top: 1px solid #dddddd !important;
      border-bottom: 1px solid #dddddd !important;
    }

    .column_head {
      border-left: 1px solid #dddddd !important;
      border-right: 1px solid #dddddd !important;
      z-index: 1;
      min-width: 260px;
      max-width: 260px;
      text-align: start;
      position: sticky;
      left: 0px;
      white-space: pre-wrap;
    }

    .column_population {
      border-right: 1px solid #dddddd !important;
      z-index: 1;
      min-width: 130px;
      max-width: 130px;
      text-align: center;
      position: sticky;
      left: 260px;
      white-space: pre-wrap;
    }

    .column_value {
      border-right: 1px solid #dddddd !important;
      min-width: 200px;
      max-width: 200px;
      text-align: center;
      font-size: 13px;

      .chain_name {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    .table_head {
      background-color: #f5f5f5;
      th {
        height: 36px;
        font-size: 12px;
        color: #222222;
        font-weight: bold;
        background-color: #f5f5f5;
        padding: 0 10px;
        border-top: 1px solid #dddddd !important;
      }
    }
    .table_body {
      td {
        height: 36px;
        font-size: 12px;
        color: #222222;
        background-color: white;
      }

      .bg-light-blue {
        background-color: #f4fbff !important;
        color: #222222 !important;
      }
      .bg-gray {
        background-color: #aaaaaa !important;
      }

      .column_value {
        position: relative;

        .text {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          -webkit-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
        }

        .bar {
          .default {
            height: 22px;
            background-color: #eeeeee;
          }
          .basechain {
            height: 22px;
            background-color: #4d99d0;
            opacity: 0.35;
          }
        }
      }
    }
  }
}
</style>
