<template>
  <div>
    <div class="favorite-header">
      <div class="nav">
        <router-link
          :to="{ name: 'FavoriteList' }"
          class="link"
        >
          お気に入り条件一覧
        </router-link>
        &nbsp;&nbsp;＞&nbsp;&nbsp;{{ favorite?.name }}
      </div>
      <v-spacer />
      <CustomButton
        variant="inverse"
        size="md"
        width="220px"
        height="46px"
        @click="clickEdit"
      >
        編集する
      </CustomButton>
      <DeleteButton
        width="46px"
        height="46px"
        class="ml-10px"
        :shadow="true"
        @click="dialog = true"
      />
    </div>
    <FavoriteDetailCard
      v-if="favorite"
      :favorite="favorite"
      class="mb-20px"
    />
    <div class="filter">
      <CustomInput
        v-model="search"
        placeholder="店舗名・住所の一部で検索"
        width="360px"
        height="40px"
      />
      <span>この条件に含まれる店舗：{{ filteredStores.length }}件</span>
    </div>
    <LoadingImg
      v-if="loading"
      height="600px"
    />
    <FavoriteDetailTable
      v-else-if="filteredStores.length > 0"
      :stores="filteredStores"
      :sort-by="sortBy"
      @set-sort-by="setSortBy($event)"
    />
    <NoList
      v-else
      class="mb-50px"
    />
    <DeleteDialog
      v-if="favorite"
      v-model="dialog"
      type="favorite"
      :targets="[favorite.name!]"
      :loading="loading"
      @click-delete="clickDelete"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, reactive, computed } from "vue";
import NoList from "@/features/Dashboard/components/NoList.vue";
import LoadingImg from "@/commons/components/loadingImg.vue";
import DeleteButton from "@/features/Dashboard/components/DeleteButton.vue";
import FavoriteDetailCard from "@/features/Dashboard/components/FavoriteDetailCard.vue";
import FavoriteDetailTable from "@/features/Dashboard/components/FavoriteDetailTable.vue";
import DeleteDialog from "@/features/Dashboard/components/DeleteDialog.vue";
import { filterStoresByFavorite } from "@/features/Dashboard/utils";
import {
  getStoresFavorites,
  deleteStoresFavorites,
} from "@/features/Dashboard/axios";
import { Favorite } from "@/features/Dashboard/types";
import { Store } from "@/commons/interfaces/responses/store";
import * as notify from "@/plugins/notification";
import {useStore} from "vuex";
import { useRouter, useRoute } from 'vue-router';

export default defineComponent({
  name: "FavoriteList",
  components: {
    DeleteButton,
    FavoriteDetailCard,
    FavoriteDetailTable,
    NoList,
    LoadingImg,
    DeleteDialog,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const loading = ref(false);
    const favorite = ref<Favorite>();
    const favorites = ref<Favorite[]>([]);
    const search = ref("");
    const dialog = ref(false);
    const sortBy = reactive({
      column: "prefecture",
      desc: true,
    } as { column: "prefecture" | "chainName" | "storeType"; desc: boolean; });

    const filteredStores = computed((): Store[] => {     
      const stores = filterStoresByFavorite({
        stores: store.state.stores,
        favorite: favorite.value,
      });
      return stores
        .filter(
          (store) =>
            store.name.indexOf(search.value) > -1 ||
            store.address.indexOf(search.value) > -1
        )
        .sort((a, b) => {
          if (sortBy.column === "prefecture")
            return sortBy.desc
              ? a.prefectureId - b.prefectureId
              : b.prefectureId - a.prefectureId;
          if (sortBy.column === "chainName")
            return sortBy.desc
              ? (b.chainName !== null ? b.chainName : "").localeCompare(
                  a.chainName !== null ? a.chainName : "",
                  "ja"
                )
              : (a.chainName !== null ? a.chainName : "").localeCompare(
                  b.chainName !== null ? b.chainName : "",
                  "ja"
                );
          if (sortBy.column === "storeType")
            return sortBy.desc
              ? a.storeType - b.storeType
              : b.storeType - a.storeType;
          return Number(a.orderIndex) - Number(b.orderIndex);
        });
    });

    const fetchData = async() => {
      loading.value = true;
      // お気に入り条件一覧
      await getStoresFavorites()
        .then((res) => {
          favorites.value = res.data.favorites;
        })
        .catch(() => {
          notify.notifyErrorMessage("お気に入り条件の取得に失敗しました。");
        });
      // 店舗一覧
      if (!store.state.stores.length)
        await store.dispatch("fetchStores");
      // 都道府県一覧
      if (!store.state.prefectures.length)
        await store.dispatch("fetchPrefectures");

      // パスパラメータからお気に入り条件を特定
      if (route.params["id"]) {
        const id = route.params["id"];
       favorite.value = favorites.value.find((favorite) => favorite.id === id);
        if (!favorite.value) router.push({ name: "NotFound" });
      }
      loading.value = false;
    };
    const setSortBy = (event: { type: "prefecture" | "chainName" | "storeType"; desc: boolean; }) => {
      if (sortBy.column === event.type) {
        sortBy.desc = !sortBy.desc;
      } else {
        sortBy.column = event.type;
        sortBy.desc = true;
      }
    };
    const clickEdit = () => {
      router.push({
        name: "FavoriteEdit",
        // NOTE: router 経由で props にデータを渡す場合でも params は string しか許容されないため any でキャスト
        params: {
          id: route.params["id"],
          favorite:favorite.value as any,
        },
      });
    };
    const clickDelete = async() => {
      loading.value = true;
      await deleteStoresFavorites({
        favoritesIds: [route.params.id as string],
      })
        .then(() => {
          router.replace({ name: "FavoriteList" });
        })
        .catch(() => {
          notify.notifyErrorMessage("お気に入り条件が削除できませんでした。");
        });
      loading.value = false;
    };

    // HACK: 詳細用のデータを取得するために一覧取得APIを毎回叩いてしまうのでキャッシュなどを利用してパフォーマンスを改善
    fetchData();

    return {
      loading,
      favorite,
      search,
      dialog,
      sortBy,
      filteredStores,
      setSortBy,
      clickEdit,
      clickDelete,      
    };
  },
});
</script>

<style lang="scss" scoped>
.favorite-header {
  display: flex;
  align-items: center;
  margin-top: 26px;
  margin-bottom: 28px;
  height: 46px;

  .nav {
    font: normal normal normal 13px/20px Noto Sans JP;
    letter-spacing: 0px;
    color: #666666;

    .link {
      color: #4d99d0;
      text-decoration: none;
    }
  }
}

.filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  margin-bottom: 19px;

  span {
    font-size: 14px;
    color: #222222;
  }
}

.mb-20px {
  margin-bottom: 20px;
}

.mb-50px {
  margin-bottom: 50px;
}

.ml-10px {
  margin-left: 10px;
}
</style>
