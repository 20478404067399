import { Store } from "@/commons/interfaces/responses/store";
import { Favorite } from "@/features/Dashboard/types";

/**
 * 指定したお気に入り条件で店舗をフィルタリングする関数
 *
 * @param args - 店舗一覧とお気に入り条件
 * @returns お気に入り条件でフィルタリングされた店舗群
 */
export const filterStoresByFavorite = (args: {
  stores: Store[];
  favorite: Favorite | undefined;
}) => {
  const { stores, favorite } = args;
  let filtered = stores;

  if (!favorite || favorite.storeTypes.length === 0) return [];

  // 都道府県
  if (favorite.prefectureIds.length > 0) {
    filtered = filtered.filter((store) =>
      favorite.prefectureIds.includes(store.prefectureId)
    );
  }

  // 市区町村
  if (favorite.cityIds.length > 0) {
    filtered = filtered.filter((store) =>
      favorite.cityIds.includes(store.cityId)
    );
  }

  // ストアタイプ
  if (favorite.storeTypes.length > 0) {
    filtered = filtered.filter((store) =>
      favorite.storeTypes.includes(store.storeType)
    );
  }

  // チェーンワード
  if (favorite.chainWords.length > 0) {
    filtered = Array.from(
      new Set(
        favorite.chainWords.flatMap((word) =>
          filtered
            .filter((store) => store.chainName !== null)
            .filter((store) => (store.chainName as string) === word)
        )
      )
    );
  }

  // フリーワード
  if (favorite.storeNameWords.length > 0) {
    filtered = Array.from(
      new Set(
        favorite.storeNameWords.flatMap((word) =>
          filtered.filter((store) => ~store.name.indexOf(word))
        )
      )
    );
  }

  return filtered;
};
