import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import { useStore } from "vuex";
import NotFound from "@/features/NotFound/NotFoundView.vue";
import Login from "@/features/Login/loginView.vue";
import Dashboard from "@/features/Dashboard/Dashboard.vue";
import FavoriteList from "@/features/Dashboard/FavoriteList.vue";
import FavoriteDetail from "@/features/Dashboard/FavoriteDetail.vue";
import FavoriteCreate from "@/features/Dashboard/FavoriteCreate.vue";
import FavoriteEdit from "@/features/Dashboard/FavoriteEdit.vue";
import CompareList from "@/features/Dashboard/CompareList.vue";
import CompareCreate from "@/features/Dashboard/CompareCreate.vue";
import CompareEdit from "@/features/Dashboard/CompareEdit.vue";
import DashboardHistory from "@/features/Dashboard/DashboardHistory.vue";
import ShopAnalyticsVisitor from "@/features/ShopAnalytics/visitorView.vue";
import ShopAnalyticsTrendVisitor from "@/features/ShopAnalytics/trendVisitorView.vue";
import ShopAnalyticsPersona from "@/features/ShopAnalytics/personaView.vue";
import ShopAnalyticsVisitPatternView from "@/features/ShopAnalytics/visitPatternView.vue";
import ShopAnalyticsBizArea from "@/features/ShopAnalytics/bizAreaView.vue";
import ShopAnalyticsCombination from "@/features/ShopAnalytics/combinationView.vue";
import StoreCompareVisitor from "@/features/StoreCompare/visitor/VisitorView.vue";
import StoreCompareVisitPattern from "@/features/StoreCompare/visitPattern/VisitPatternView.vue";
import StoreCompareTrendVisitor from "@/features/StoreCompare/trendVisitor/TrendVisitorView.vue";
import StoreComparePersona from "@/features/StoreCompare/persona/PersonaView.vue";
import StoreCompareBizArea from "@/features/StoreCompare/bizArea/BizArea.vue";
import RegionalShare from "@/features/RegionalShare/regionalShareView.vue";
import ChainAnalyticsVisitor from "@/features/ChainAnalytics/visitor/Visitor.vue";
import ChainAnalyticsTrendVisitor from "@/features/ChainAnalytics/trendVisitor/TrendVisitor.vue";
import ChainAnalyticsPersona from "@/features/ChainAnalytics/persona/Persona.vue";
import ChainAnalyticsVisitPattern from "@/features/ChainAnalytics/visitPattern/VisitPattern.vue";
import ChainAnalyticsBizArea from "@/features/ChainAnalytics/bizArea/BizArea.vue";
import ChainAnalyticsCombination from "@/features/ChainAnalytics/combination/Combination.vue";
import ChainCompareVisitor from "@/features/ChainCompare/visitor/VisitorView.vue";
import ChainCompareVisitPattern from "@/features/ChainCompare/visitPattern/VisitPatternView.vue";
import ChainCompareTrendVisitor from "@/features/ChainCompare/trendVisitor/TrendVisitorView.vue";
import ChainComparePersona from "@/features/ChainCompare/persona/PersonaView.vue";
import ChainCompareBizArea from "@/features/ChainCompare/bizArea/BizArea.vue";
import TrendStore from "@/features/Trend/TrendStore/TorendStoreView.vue";
import TrendChain from "@/features/Trend/TrendChain/TorendChainView.vue";
import UserSetting from "@/features/UserSetting/UserSettingView.vue";
import UserSettingCreate from "@/features/UserSetting/UserSettingCreateView.vue";
import UserSettingInvitation from "@/features/UserSetting/UserSettingInvitationView.vue";
import UserSettingEdit from "@/features/UserSetting/UserSettingEditView.vue";
import PasswordReset from "@/features/UserSetting/PasswordResetView.vue";
import NewsList from "@/features/News/NewsListView.vue";
import News from "@/features/News/NewsView.vue";
import Faq from "@/features/Faq/FaqView.vue";
import Manual from "@/features/Manual/ManualView.vue";
import { ROUTE_TYPE } from "@/commons/types/route";
import { notifyErrorMessage } from "@/plugins/notification";
import { checkRegister } from "@/features/UserSetting/axios";
import { sessionCheck } from "@/commons/axios/auth";
import { AxiosResponse } from "axios";
import { AuthResponse } from "@/commons/types/Auth";
import { getChartDescriptionJson } from "@/commons/axios/chartDescription";

declare global {
  interface Window {
    dataLayer: any;
  }
}
window.dataLayer = window.dataLayer || [];

const routes: RouteRecordRaw[] = [
  {
    path: "/:pathMatch(.*)",
    redirect: "/404",
  },
  {
    path: "/404",
    name: "NotFound",
    component: NotFound,
    meta: {
      title: "ページが見つかりません | ショッパーみえーる",
      hasNavigation: false,
      routeType: ROUTE_TYPE.other,
      requiredAuth: false,
      requiredStores: false,
      requiredComparisonGroup: false,
    },
  },
  {
    path: "/",
    redirect: "/login",
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      title: "ショッパーみえーる | ショッパー・アナリティクスツール",
      hasNavigation: false,
      routeType: ROUTE_TYPE.other,
      requiredAuth: false,
      requiredStores: false,
      requiredComparisonGroup: false,
    },
  },
  {
    path: "/logout",
    name: "Logout",
    // TODO: コンポーネント実装後に置換
    component: Dashboard,
    meta: {
      title: "ショッパーみえーる",
      hasNavigation: false,
      routeType: ROUTE_TYPE.other,
      requiredAuth: false,
      requiredStores: false,
      requiredComparisonGroup: false,
    },
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    redirect: "/dashboard/favorite/list",
    meta: {
      title: "ショッパーみえーる",
      hasNavigation: true,
      routeType: ROUTE_TYPE.dashboard,
      requiredAuth: true,
      requiredStores: false,
      requiredComparisonGroup: false,
    },
    children: [
      {
        path: "favorite/list",
        name: "FavoriteList",
        component: FavoriteList,
        meta: {
          title: "お気に入り条件 | ショッパーみえーる",
          hasNavigation: true,
          routeType: ROUTE_TYPE.favorite,
          requiredAuth: true,
          requiredStores: true,
          requiredComparisonGroup: false,
        },
      },
      {
        path: "favorite/list/:id",
        name: "FavoriteDetail",
        component: FavoriteDetail,
        meta: {
          title: "お気に入り条件詳細 | ショッパーみえーる",
          hasNavigation: true,
          routeType: ROUTE_TYPE.favorite,
          requiredAuth: true,
        },
      },
      {
        path: "compare/list",
        name: "CompareList",
        component: CompareList,
        meta: {
          title: "店舗比較リスト | ショッパーみえーる",
          hasNavigation: true,
          routeType: ROUTE_TYPE.compareList,
          requiredAuth: true,
          requiredStores: true,
          requiredComparisonGroup: true,
        },
      },
      {
        path: "history",
        name: "History",
        component: DashboardHistory,
        meta: {
          title: "更新履歴 | ショッパーみえーる",
          hasNavigation: true,
          routeType: ROUTE_TYPE.dashboard,
          requiredAuth: true,
          requiredStores: false,
          requiredComparisonGroup: false,
        },
      },
    ],
  },
  {
    path: "/store/favorite/create/:favorite?",
    name: "FavoriteCreate",
    component: FavoriteCreate,
    props: (route) => ({ favorite: route.params.favorite }),
    meta: {
      title: "お気に入り条件作成 | ショッパーみえーる",
      hasNavigation: true,
      routeType: ROUTE_TYPE.favorite,
      requiredAuth: true,
      requiredStores: true,
      requiredComparisonGroup: false,
    },
  },
  {
    path: "/store/favorite/edit",
    alias: "/store/favorite/edit/",
    redirect: { name: "FavoriteList" },
  },
  {
    path: "/store/favorite/edit/:id",
    name: "FavoriteEdit",
    component: FavoriteEdit,
    props: true,
    meta: {
      title: "お気に入り条件編集 | ショッパーみえーる",
      hasNavigation: true,
      routeType: ROUTE_TYPE.favorite,
      requiredAuth: true,
      requiredStores: true,
      requiredComparisonGroup: false,
    },
  },
  {
    path: "/store/compare/create",
    name: "StoreCompareCreate",
    component: CompareCreate,
    meta: {
      title: "店舗比較リスト作成 | ショッパーみえーる",
      hasNavigation: true,
      routeType: ROUTE_TYPE.compareList,
      requiredAuth: true,
      requiredStores: true,
      requiredComparisonGroup: true,
    },
  },
  {
    path: "/store/compare/edit",
    alias: "/store/compare/edit/",
    redirect: { name: "CompareList" },
  },
  {
    path: "/store/compare/edit/:id",
    name: "StoreCompareEdit",
    component: CompareEdit,
    meta: {
      title: "店舗比較リスト編集 | ショッパーみえーる",
      hasNavigation: true,
      routeType: ROUTE_TYPE.compareList,
      requiredAuth: true,
      requiredStores: true,
      requiredComparisonGroup: true,
    },
  },
  {
    path: "/store/analytics/visitor/:id?",
    name: "ShopAnalyticsVisitor",
    alias: "/store/analytics",
    component: ShopAnalyticsVisitor,
    meta: {
      title: "店舗分析 来店人数推移 | ショッパーみえーる",
      hasNavigation: true,
      routeType: ROUTE_TYPE.analytics,
      requiredAuth: true,
      requiredStores: true,
      requiredComparisonGroup: false,
    },
  },
  {
    path: "/store/analytics/trend-visitor/:id?",
    name: "ShopAnalyticsTrendVisitor",
    alias: "/store/analytics/trend-visitor",
    component: ShopAnalyticsTrendVisitor,
    meta: {
      title: "店舗分析 曜日/時間別人数 | ショッパーみえーる",
      hasNavigation: true,
      routeType: ROUTE_TYPE.analytics,
      requiredAuth: true,
      requiredStores: true,
      requiredComparisonGroup: false,
    },
  },
  {
    path: "/store/analytics/persona/:id?",
    name: "ShopAnalyticsPersona",
    component: ShopAnalyticsPersona,
    meta: {
      title:
        "店舗分析 ペルソナ特性(店舗分析年代性別 / 店舗分析行動DNA) | ショッパーみえーる",
      hasNavigation: true,
      routeType: ROUTE_TYPE.analytics,
      requiredAuth: true,
      requiredStores: true,
      requiredComparisonGroup: false,
    },
  },
  {
    path: "/store/analytics/visit-engagement/:id?",
    name: "ShopAnalyticsVisitEngagement",
    component: ShopAnalyticsVisitPatternView,
    meta: {
      title: "店舗分析 来店特性 | ショッパーみえーる",
      hasNavigation: true,
      routeType: ROUTE_TYPE.analytics,
      requiredAuth: true,
      requiredStores: true,
      requiredComparisonGroup: false,
    },
  },
  {
    path: "/store/analytics/biz-area/:id?",
    name: "ShopAnalyticsBizArea",
    component: ShopAnalyticsBizArea,
    meta: {
      title:
        "店舗分析 商圏分析(商圏マップ / 商圏ランキング) | ショッパーみえーる",
      hasNavigation: true,
      routeType: ROUTE_TYPE.analytics,
      requiredAuth: true,
      requiredStores: true,
      requiredComparisonGroup: false,
    },
  },
  {
    path: "/store/analytics/combination/:id?",
    name: "ShopAnalyticsCombination",
    component: ShopAnalyticsCombination,
    meta: {
      title:
        "店舗分析 併用分析 | ショッパーみえーる",
      hasNavigation: true,
      routeType: ROUTE_TYPE.analytics,
      requiredAuth: true,
      requiredStores: true,
      requiredComparisonGroup: false,
    },
  },
  {
    path: "/store/compare/visitor/:id?",
    name: "StoreCompareVisitor",
    alias: "/store/compare",
    component: StoreCompareVisitor,
    meta: {
      title: "店舗比較 来店人数推移 | ショッパーみえーる",
      hasNavigation: true,
      routeType: ROUTE_TYPE.compare,
      requiredAuth: true,
      requiredStores: true,
      requiredComparisonGroup: true,
    },
  },
  {
    path: "/store/compare/trend-visitor/:id?",
    name: "StoreCompareTrendVisitor",
    component: StoreCompareTrendVisitor,
    meta: {
      title: "店舗比較 曜日/時間別人数 | ショッパーみえーる",
      hasNavigation: true,
      routeType: ROUTE_TYPE.compare,
      requiredAuth: true,
      requiredStores: true,
      requiredComparisonGroup: true,
    },
  },
  {
    path: "/store/compare/persona/:id?",
    name: "StoreComparePersona",
    component: StoreComparePersona,
    meta: {
      title:
        "店舗比較 ペルソナ特性(店舗分析年代性別 / 店舗分析行動DNA) | ショッパーみえーる",
      hasNavigation: true,
      routeType: ROUTE_TYPE.compare,
      requiredAuth: true,
      requiredStores: true,
      requiredComparisonGroup: true,
    },
  },
  {
    path: "/store/compare/visit-engagement/:id?",
    name: "StoreCompareVisitEngagement",
    component: StoreCompareVisitPattern,
    meta: {
      title: "店舗比較 来店特性 | ショッパーみえーる",
      hasNavigation: true,
      routeType: ROUTE_TYPE.compare,
      requiredAuth: true,
      requiredStores: true,
      requiredComparisonGroup: true,
    },
  },
  {
    path: "/store/compare/biz-area/:id?",
    name: "StoreCompareBizArea",
    component: StoreCompareBizArea,
    meta: {
      title:
        "店舗比較 商圏分析(商圏マップ / 商圏ランキング) | ショッパーみえーる",
      hasNavigation: true,
      routeType: ROUTE_TYPE.compare,
      requiredAuth: true,
      requiredStores: true,
      requiredComparisonGroup: true,
    },
  },
  {
    path: "/store/area/:id?",
    name: "StoreArea",
    component: RegionalShare,
    meta: {
      title: "店舗別シェア | ショッパーみえーる",
      hasNavigation: true,
      routeType: ROUTE_TYPE.regional,
      requiredAuth: true,
      requiredStores: true,
      requiredComparisonGroup: false,
    },
  },
  {
    path: "/chain/analytics/visitor/",
    name: "ChainAnalyticsVisitor",
    alias: "/chain/analytics",
    component: ChainAnalyticsVisitor,
    meta: {
      title: "チェーン分析 来店人数推移 | ショッパーみえーる",
      hasNavigation: true,
      routeType: ROUTE_TYPE.chainAnalytics,
      requiredAuth: true,
      requiredStores: true,
      requiredComparisonGroup: false,
    },
  },
  {
    path: "/chain/analytics/trend-visitor/",
    name: "ChainAnalyticsTrendVisitor",
    alias: "/chain/analytics/trend-visitor",
    component: ChainAnalyticsTrendVisitor,
    meta: {
      title: "チェーン分析 曜日/時間別人数 | ショッパーみえーる",
      hasNavigation: true,
      routeType: ROUTE_TYPE.chainAnalytics,
      requiredAuth: true,
      requiredStores: true,
      requiredComparisonGroup: false,
    },
  },
  {
    path: "/chain/analytics/persona/",
    name: "ChainAnalyticsPersona",
    component: ChainAnalyticsPersona,
    meta: {
      title:
        "チェーン分析 ペルソナ特性(チェーン分析年代性別 / チェーン分析行動DNA) | ショッパーみえーる",
      hasNavigation: true,
      routeType: ROUTE_TYPE.chainAnalytics,
      requiredAuth: true,
      requiredStores: true,
      requiredComparisonGroup: false,
    },
  },
  {
    path: "/chain/analytics/visit-engagement/",
    name: "ChainAnalyticsVisitEngagement",
    component: ChainAnalyticsVisitPattern,
    meta: {
      title: "チェーン分析 来店特性 | ショッパーみえーる",
      hasNavigation: true,
      routeType: ROUTE_TYPE.chainAnalytics,
      requiredAuth: true,
      requiredStores: true,
      requiredComparisonGroup: false,
    },
  },
  {
    path: "/chain/analytics/biz-area/",
    name: "ChainAnalyticsBizArea",
    component: ChainAnalyticsBizArea,
    meta: {
      title:
        "チェーン分析 商圏分析(商圏マップ / 商圏ランキング) | ショッパーみえーる",
      hasNavigation: true,
      routeType: ROUTE_TYPE.chainAnalytics,
      requiredAuth: true,
      requiredStores: true,
      requiredComparisonGroup: false,
    },
  },
  {
    path: "/chain/analytics/combination/",
    name: "ChainAnalyticsCombination",
    component: ChainAnalyticsCombination,
    meta: {
      title:
        "チェーン分析 併用分析 | ショッパーみえーる",
      hasNavigation: true,
      routeType: ROUTE_TYPE.chainAnalytics,
      requiredAuth: true,
      requiredStores: true,
      requiredComparisonGroup: false,
    },
  },
  {
    path: "/chain/compare/visitor/",
    name: "ChainCompareVisitor",
    alias: "/chain/compare",
    component: ChainCompareVisitor,
    meta: {
      title: "チェーン比較 来店人数推移 | ショッパーみえーる",
      hasNavigation: true,
      routeType: ROUTE_TYPE.chainCompare,
      requiredAuth: true,
      requiredStores: true,
      requiredComparisonGroup: true,
    },
  },
  {
    path: "/chain/compare/trend-visitor/",
    name: "ChainCompareTrendVisitor",
    component: ChainCompareTrendVisitor,
    meta: {
      title: "チェーン比較 曜日/時間別人数 | ショッパーみえーる",
      hasNavigation: true,
      routeType: ROUTE_TYPE.chainCompare,
      requiredAuth: true,
      requiredStores: true,
      requiredComparisonGroup: true,
    },
  },
  {
    path: "/chain/compare/persona/",
    name: "ChainComparePersona",
    component: ChainComparePersona,
    meta: {
      title:
        "チェーン比較 ペルソナ特性(チェーン分析年代性別 / チェーン分析行動DNA) | ショッパーみえーる",
      hasNavigation: true,
      routeType: ROUTE_TYPE.chainCompare,
      requiredAuth: true,
      requiredStores: true,
      requiredComparisonGroup: true,
    },
  },
  {
    path: "/chain/compare/visit-engagement/",
    name: "ChainCompareVisitEngagement",
    component: ChainCompareVisitPattern,
    meta: {
      title: "チェーン比較 来店特性 | ショッパーみえーる",
      hasNavigation: true,
      routeType: ROUTE_TYPE.chainCompare,
      requiredAuth: true,
      requiredStores: true,
      requiredComparisonGroup: true,
    },
  },
  {
    path: "/chain/compare/biz-area/",
    name: "ChainCompareBizArea",
    component: ChainCompareBizArea,
    meta: {
      title:
        "チェーン比較 商圏分析(商圏マップ / 商圏ランキング) | ショッパーみえーる",
      hasNavigation: true,
      routeType: ROUTE_TYPE.chainCompare,
      requiredAuth: true,
      requiredStores: true,
      requiredComparisonGroup: true,
    },
  },
  {
    path: "/trend/store/",
    name: "TrendStore",
    component: TrendStore,
    meta: {
      title: "トレンド 居住者に人気の店舗 | ショッパーみえーる",
      hasNavigation: true,
      routeType: ROUTE_TYPE.trend,
      requiredAuth: true,
      requiredStores: true,
      requiredComparisonGroup: false,
      requiredChain: false,
    },
  },
  {
    path: "/trend/chain/",
    name: "TrendChain",
    component: TrendChain,
    meta: {
      title: "トレンド 居住者に人気のチェーン | ショッパーみえーる",
      hasNavigation: true,
      routeType: ROUTE_TYPE.trend,
      requiredAuth: true,
      requiredStores: true,
      requiredComparisonGroup: false,
      requiredChain: true,
    },
  },
  {
    path: "/news/list",
    name: "NewsList",
    component: NewsList,
    meta: {
      title: "お知らせ一覧 | ショッパーみえーる",
      hasNavigation: true,
      routeType: ROUTE_TYPE.other,
      requiredAuth: true,
      requiredStores: false,
      requiredComparisonGroup: false,
    },
  },
  {
    path: "/news/:id",
    name: "News",
    component: News,
    meta: {
      title: "お知らせ詳細 | ショッパーみえーる",
      hasNavigation: true,
      routeType: ROUTE_TYPE.other,
      requiredAuth: true,
      requiredStores: false,
      requiredComparisonGroup: false,
    },
  },
  {
    path: "/users/setting",
    name: "UserSetting",
    component: UserSetting,
    meta: {
      title: "ユーザー設定 | ショッパーみえーる",
      hasNavigation: true,
      routeType: ROUTE_TYPE.other,
      requiredAuth: true,
      requiredStores: false,
      requiredComparisonGroup: false,
    },
  },
  {
    path: "/users/create",
    name: "UserCreate",
    component: UserSettingCreate,
    meta: {
      title: "ユーザー招待 | ショッパーみえーる",
      hasNavigation: true,
      routeType: ROUTE_TYPE.other,
      requiredAuth: true,
      requiredStores: false,
      requiredComparisonGroup: false,
    },
  },
  {
    path: "/users/invitation",
    alias: "/users/invitation/",
    redirect: { name: "UserSetting" },
  },
  {
    path: "/users/invitation/:id",
    name: "UserInvitation",
    component: UserSettingInvitation,
    meta: {
      title: "ユーザー登録 | ショッパーみえーる",
      hasNavigation: true,
      routeType: ROUTE_TYPE.other,
      requiredAuth: false,
      requiredStores: false,
      requiredComparisonGroup: false,
    },
  },
  {
    path: "/users/edit",
    alias: "/users/edit/",
    redirect: { name: "UserSetting" },
  },
  {
    path: "/users/edit/:id",
    name: "UserEdit",
    component: UserSettingEdit,
    meta: {
      title: "ユーザー編集 | ショッパーみえーる",
      hasNavigation: true,
      routeType: ROUTE_TYPE.other,
      requiredAuth: true,
      requiredStores: false,
      requiredComparisonGroup: false,
    },
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: PasswordReset,
    meta: {
      title: "パスワード再設定 | ショッパーみえーる",
      hasNavigation: false,
      routeType: ROUTE_TYPE.other,
      requiredAuth: false,
      requiredStores: false,
      requiredComparisonGroup: false,
    },
  },
  {
    path: "/manual",
    name: "Manual",
    component: Manual,
    meta: {
      title: "マニュアル | ショッパーみえーる",
      hasNavigation: true,
      routeType: ROUTE_TYPE.other,
      requiredAuth: true,
      requiredStores: false,
      requiredComparisonGroup: false,
    },
  },
  {
    path: "/faq",
    name: "FAQ",
    component: Faq,
    meta: {
      title: "FAQ | ショッパーみえーる",
      hasNavigation: true,
      routeType: ROUTE_TYPE.other,
      requiredAuth: true,
      requiredStores: false,
      requiredComparisonGroup: false,
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from) {
    // 同じページの場合はスクロールをそのままにする
    if (to.name === from.name) return;
    else return { top: 0 };
  },
});

import { PRODUCT_FEATURE } from "@/commons/enums";
router.beforeEach(async (to) => {
  const store = useStore();
  if (to.name === "UserInvitation") {
    if (
      typeof to.query.mail === "undefined" ||
      !to.query.mail ||
      typeof to.params.id === "undefined" ||
      !to.params.id
    ) {
      notifyErrorMessage("無効な招待URLです。");
      return { name: "Login" }
    }
    const res = await checkRegister(String(to.query.mail), String(to.params.id))
      .then(() => {
        return null;
      })
      .catch(() => {
        notifyErrorMessage("無効な招待URLです。");
        return { name: "Login" }
      });
    if (res) return res
  } else if (to.matched.some((record) => !record.meta.requiredAuth && to.name !== 'Login')) {
    ;
  } else if (to.meta?.requiredAuth) {
    const res = await sessionCheck()
      .then(async (res: AxiosResponse<AuthResponse>) => {
        store.dispatch("fetchVersion", res.data.version);
        store.dispatch("fetchUserType", res.data.userType);
        store.dispatch("fetchProductFeatures", res.data.productFeatures);
        store.dispatch("fetchViewableCompanies");

        // NOTE: 契約形態に基づいたアクセス制限
        if (res.data.productFeatures.length === 0) {
          notifyErrorMessage("アクセス権限がありません。");
          return { name: "Login" }
        }

        if (
          (
            to.meta?.routeType === ROUTE_TYPE.dashboard ||
            to.meta?.routeType === ROUTE_TYPE.favorite ||
            to.meta?.routeType === ROUTE_TYPE.compareList ||
            to.meta?.routeType === ROUTE_TYPE.analytics ||
            to.meta?.routeType === ROUTE_TYPE.compare ||
            to.meta?.routeType === ROUTE_TYPE.regional
          ) &&
          !res.data.productFeatures.includes(PRODUCT_FEATURE.YOKU) &&
          res.data.productFeatures.includes(PRODUCT_FEATURE.HIROKU)
        ) {
          notifyErrorMessage("アクセス権限がありません。");
          return { name: "ChainAnalyticsVisitor" }
        }

        if (
          (
            to.meta?.routeType === ROUTE_TYPE.chainAnalytics ||
            to.meta?.routeType === ROUTE_TYPE.chainCompare ||
            to.meta?.routeType === ROUTE_TYPE.trend
          ) &&
          !res.data.productFeatures.includes(PRODUCT_FEATURE.HIROKU) &&
          res.data.productFeatures.includes(PRODUCT_FEATURE.YOKU)
        ) {
          notifyErrorMessage("アクセス権限がありません。");
          return { name: "Dashboard" }
        }

        if (!store.state.descriptions) {
          getChartDescriptionJson().then((value) => {
            store.dispatch("fetchDescriptions", value.data);
          });
        }

        // NOTE: ログイン状態でリロードしたときはここで期間の初期化を行う
        if (to.meta?.requiredAuth && !store.state.availablePeriod) {
          await store.dispatch("fetchPeriod");
          store.dispatch("initDate");
          store.dispatch("initMonth");
        }
        return null

      })
      .catch(() => {
        return { name: "Login" }
      });
    if (res) return res
  }
});

const DEFAULT_TITLE = "ショッパーみえーる";
router.afterEach((to) => {
  document.title = to.meta?.title as string || DEFAULT_TITLE;
});

export default router;

declare module "vue-router" {
  interface RouteMeta {
    hasNavigation: boolean;
    routeType: number;
  }
}
