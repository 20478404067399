import { Feature, GeoJSON } from '@/commons/types/GeoJSON'
import booleanOverlap from '@turf/boolean-overlap'
import booleanWithin from '@turf/boolean-within'

/**
 * feature.properties.id を key, feature を value とした Map を返す関数。
 * feature.properties.id には 都道府県、市区町村、町丁目 ID が格納される。
 * @param features - geojson の features
 * @returns feature 取得用 Map
 */
export function getFeatureMap(features: Feature[]): Map<string, Feature> {
  const featureMap = new Map<string, Feature>()

  features.forEach((feature) => {
    if (feature.properties.id) {
      featureMap.set(feature.properties.id, feature)
    }
  })
  return featureMap
}

/**
 * GeoJSON を id 配列より高速でフィルタリングするための関数
 * @param geojson
 * @param ids
 * @param featureMap
 * @returns
 */
export function filterGeoJsonById(
  geojson: GeoJSON,
  ids: string[],
  featureMap: Map<string, Feature>
): GeoJSON {
  const filteredGeoJson = {
    type: geojson.type,
    features: ids.map((id) => featureMap.get(id)).filter((feature) => !!feature) as Feature[]
  }
  return filteredGeoJson
}

/**
 * 中心座標から選択した距離に含まれる feature.properties.id を bbox で検索し返す関数
 * @param geojson
 * @param center
 * @param dist
 * @returns
 */
export function getIdsByDist(geojson: GeoJSON, center: number[], dist: number): string[] {
  const latDist = dist / 111
  const lonDist = dist / (111 * Math.cos(center[1] * (Math.PI / 180)))

  const buffer = {
    type: 'Feature',
    geometry: {
      type: 'Polygon',
      coordinates: [
        [
          [center[0] - lonDist, center[1] - latDist],
          [center[0] + lonDist, center[1] - latDist],
          [center[0] + lonDist, center[1] + latDist],
          [center[0] - lonDist, center[1] + latDist],
          [center[0] - lonDist, center[1] - latDist]
        ]
      ]
    },
    properties: {}
  }

  const ids = geojson.features
    .filter((feature) => {
      const bbox = feature?.bbox
      const polygon = getBBoxPolygon(bbox)

      if (!polygon) return false

      return (
        booleanWithin(polygon as any, buffer as any) ||
        booleanOverlap(polygon as any, buffer as any)
      )
    })
    .map((feature) => feature.properties.id)

  return ids
}

/**
 * bbox の polygon を取得する関数
 * @param bbox - [xmin, ymin, xmax, ymax]
 * @returns bbox のポリゴン
 */
export function getBBoxPolygon(bbox: number[] | undefined) {
  if (!bbox || bbox.length !== 4) return undefined

  const coordinates = [
    [bbox[0], bbox[1]],
    [bbox[2], bbox[1]],
    [bbox[2], bbox[3]],
    [bbox[0], bbox[3]],
    [bbox[0], bbox[1]]
  ]

  const polygon = {
    type: 'Feature',
    geometry: {
      type: 'Polygon',
      coordinates: [coordinates]
    },
    properties: {}
  }

  return polygon
}

/**
 * 全 feature を覆う bbox を返す関数
 * @param features
 * @returns
 */
export function getTotalBBox(features: Feature[]) {
  const totalBBox = features.reduce(
    (prev, current) => {
      const newXMin = Math.min(prev[0], current?.bbox ? current.bbox[0] : Number.POSITIVE_INFINITY)
      const newYMin = Math.min(prev[1], current?.bbox ? current.bbox[1] : Number.POSITIVE_INFINITY)
      const newXMax = Math.max(prev[2], current?.bbox ? current.bbox[2] : Number.NEGATIVE_INFINITY)
      const newYMax = Math.max(prev[3], current?.bbox ? current.bbox[3] : Number.NEGATIVE_INFINITY)
      return [newXMin, newYMin, newXMax, newYMax]
    },
    [
      Number.POSITIVE_INFINITY,
      Number.POSITIVE_INFINITY,
      Number.NEGATIVE_INFINITY,
      Number.NEGATIVE_INFINITY
    ]
  )

  return totalBBox
}
