<template>
  <details>
    <summary>
      <span class="summary-inner">
        <slot name="title" />
        <div class="summary-icon" />
      </span>
    </summary>
    <div class="divider" />
    <div class="details-content">
      <slot name="content" />
    </div>
  </details>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ExpansionPanel",
});
</script>

<style lang="scss" scoped>
details {
  background: #f5f5f5;
  border-radius: 4px;

  summary {
    padding: 17px 20px;
    cursor: pointer;
    list-style: none; // デフォルトの三角アイコン削除

    ::-webkit-details-marker {
      display: none; // デフォルトの三角アイコン削除
    }

    .summary-inner {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .summary-icon {
        display: inline-block;
        height: 21px;
        width: 21px;
        background-image: url("~@/assets/svg/+.svg");
      }
    }
  }

  .details-content {
    transition: 0.5s;
    line-height: 2;
    padding: 0 20px 22px;
  }

  .divider {
    width: 100%;
    border: 0 solid #dddddd;
    border-top-width: 1px;
    margin-bottom: 21px;
  }
}

details[open] summary .summary-inner .summary-icon {
  background-image: url("~@/assets/svg/-.svg");
}
</style>
