<template>
  <v-dialog
    :value="dialog"
    max-width="780px"
    @click:outside="close"
  >
    <div class="contract-conflict-dialog">
      <h1>
        メールアドレスが他の企業アカウントと競合しています。フォームよりお問い合わせください。
      </h1>

      <CustomButton
        size="md"
        width="480px"
        height="60px"
        :disabled="loading"
        @click="openContactForm()"
      >
        「ショッパーみえーる」お問い合わせフォーム
        <template #endIcon>
          <img src="@/assets/svg/external-link.svg">
        </template>
      </CustomButton>
    </div>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ContractConflictDialog",

  model: {
    prop: "dialog",
    event: "change-dialog",
  },

  props: {
    dialog: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
  },

  methods: {
    openContactForm() {
      // お問い合わせフォーム(外部リンク)を新規タブで開く
      window.open("https://mkt.unerry.co.jp/form/inquirymieru");
    },
    close() {
      this.$emit("change-dialog", false);
    },
  },
});
</script>

<style lang="scss" scoped>
.contract-conflict-dialog {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 54px 76px 36px 76px;
  width: 780px;
  height: 220px;
  border-radius: 6px;
  background: white;

  h1 {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: bold;
    color: #222222;
    width: 100%;
  }
}
</style>
