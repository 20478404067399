<template>
  <MglMap
    class="map-height"
    :access-token="mapAccessToken"
    :map-style="mapStyle"
    :center="centerPoint"
    :zoom="12"
    :attribution-control="false"
    :logo-position="'bottom-right'"
    :drag-rotate="false"
    :touch-zoom-rotate="false"
    :pitch-with-rotate="false"
    @load="initMap"
  >
    <v-overlay
      contained
      :model-value="mapLoading"
      class="align-center justify-center"
      :z-index="3"
      :persistent="true"
      :no-click-animation="true"
    >
      <LoadingImg v-if="mapLoading" :height="'600px'" />
    </v-overlay>
    <v-overlay
      contained
      :model-value="isAlertOverlayOpen"
      content-class="w-100 h-100"
      scrim="rgba(0,0,0,0)"
      :z-index="3"
      :persistent="true"
      :no-click-animation="true"
    >
      <div
        class="d-flex align-center justify-center w-100 h-100"
        style="background-color: rgba(0, 0, 0, 0.6)"
      >
        <div class="overlay-message">
          <p>取得データボリュームが少なく、</p>
          <p>統計上の信頼性の低いデータが含まれています。</p>
          <p>参考値としてご参照ください。</p>
          <v-btn
            variant="text"
            style="text-decoration: underline"
            @click="() => (isAlertOverlayOpen = false)"
          >
            閉じる
          </v-btn>
        </div>
      </div>
    </v-overlay>
    <MglMarker
      :coordinates="centerPoint"
      @mouseenter="mouseOverLayerOnBaseStore"
      @mouseleave="mouseLeavePopup"
    >
      <template #marker>
        <div>
          <img src="@/assets/svg/center-pin.svg" />
        </div>
      </template>
    </MglMarker>
    <MglGeojsonLayer
      v-if="combinationStoreCircleLayer"
      ref="combinationStoreCircle"
      :source-id="combinationStoreCircleId"
      :source="combinationStoreCircleSource"
      :layer-id="combinationStoreCircleLayerId"
      :layer="combinationStoreCircleLayer"
      :clear-source="true"
      @mouseenter="mouseOverLayerOnCombinationStore"
      @mouseleave="mouseLeavePopup"
    />
    <MglGeojsonLayer
      v-if="combinationStoreSymbolLayer"
      ref="combinationStoreSymbol"
      :source-id="combinationStoreSymbolId"
      :source="combinationStoreSymbolSource"
      :layer-id="combinationStoreSymbolLayerId"
      :layer="combinationStoreSymbolLayer"
      :clear-source="true"
    />
    <MglAttributionControl />
    <MglNavigationControl :show-compass="false" position="top-right" />
    <MglScaleControl position="top-left" />
    <!-- 店舗ポップアップ -->
    <MglPopup
      anchor="bottom-left"
      :showed="hidePopup"
      :coordinates="popupCoordinates"
      :close-button="false"
      :close-on-click="false"
    >
      <combination-map-popup
        :title="popupStoreName"
        :store-type="popupStoreType"
        :store-type-text="STORE_TYPE.toLocalString(popupStoreType)"
        :combination-ratio="popupCombinationRatio"
        :is-alert="hasAlert"
        @leave-popup="
          () => {
            if (!hoverPopup) hidePopup = true
          }
        "
        @close-popup="
          () => {
            if (!hoverPopup) hidePopup = true
          }
        "
        @move-store="
          () => {
            router.push({
              name: 'ShopAnalyticsVisitor',
              params: { id: popupStoreId }
            })
          }
        "
      />
    </MglPopup>
  </MglMap>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue'
import {
  MglMap,
  MglMarker,
  MglAttributionControl,
  MglNavigationControl,
  MglScaleControl,
  MglPopup,
  MglGeojsonLayer
} from '@/vendor/vue-mapbox/main'
import { CombinationMapFeatures } from '@/features/ShopAnalytics/interfaces/component'
import LoadingImg from '@/commons/components/loadingImg.vue'
import { MAP_ACCESS_TOKEN, MAP_STYLE } from '@/config'
import CombinationMapPopup from './CombinationMapPopup.vue'
import { Store } from '@/commons/interfaces/responses/store'
import { STORE_TYPE } from '@/commons/enums'

import { useRouter } from 'vue-router'
import { nextTick } from 'vue'

const router = useRouter()

const props = withDefaults(
  defineProps<{
    baseStore: Store | undefined
    combinationStores: CombinationMapFeatures[]
    mapLoading: boolean
    hasAlert: boolean
  }>(),
  {
    storePoint: undefined,
    combinationStores: () => [],
    mapLoading: false,
    hasAlert: false
  }
)
const emit = defineEmits(['resetActive', 'update:viewRadius', 'clickLayer', 'updateRoute'])
const map = ref()
const mapAccessToken = MAP_ACCESS_TOKEN
const mapStyle = MAP_STYLE

// NOTE: [{longtitude(経度)}, {latitude(緯度)}]
const centerPoint = computed<number[]>(() => {
  return props.baseStore ? [props.baseStore.longitude, props.baseStore.latitude] : []
})

// 併用店舗レイヤー
const combinationStoreCircle = ref()
const combinationStoreCircleId = 'combinationStoreCircleSourceId'
const combinationStoreCircleLayerId = 'combinationStoreCircleLayerId'
const combinationStoreCircleSource = ref()
const combinationStoreCircleLayer = ref()
const combinationStoreSymbol = ref()
const combinationStoreSymbolId = 'combinationStoreSymbolSourceId'
const combinationStoreSymbolLayerId = 'combinationStoreSymbolLayerId'
const combinationStoreSymbolSource = ref()
const combinationStoreSymbolLayer = ref()
function updateCombinationStoreLayer() {
  if (combinationStoreCircle.value) resetCompStore()
  else createCompStore()
}
function createCompStore() {
  combinationStoreCircleSource.value = {
    type: 'geojson',
    data: {
      id: combinationStoreCircleId,
      type: 'FeatureCollection',
      features: props.combinationStores
    }
  }
  combinationStoreCircleLayer.value = {
    id: combinationStoreCircleLayerId,
    type: 'circle',
    source: combinationStoreCircleSource.value,
    layout: {
      'circle-sort-key': ['*', -1, ['get', 'rank']]
    },
    paint: {
      'circle-pitch-scale': 'viewport',
      'circle-radius': 10,
      'circle-color': [
        'match',
        ['get', 'storeType'],
        STORE_TYPE.SUPER_MARKET.value,
        '#E47075',
        STORE_TYPE.DRUG_STORE.value,
        '#8DB9A4',
        STORE_TYPE.HOME_CENTER.value,
        '#D8B47F',
        STORE_TYPE.CVS_STORE.value,
        '#9278c3',
        '#ffffff'
      ],
      'circle-stroke-color': '#ffffff',
      'circle-stroke-width': 2
    }
  }
  combinationStoreSymbolSource.value = {
    type: 'geojson',
    data: {
      id: combinationStoreSymbolId,
      type: 'FeatureCollection',
      features: props.combinationStores.map((combinationStore: CombinationMapFeatures) => {
        return {
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [
              combinationStore.properties.longitude,
              combinationStore.properties.latitude
            ]
          },
          properties: {
            rank: combinationStore.properties.rank
          }
        }
      })
    }
  }
  combinationStoreSymbolLayer.value = {
    id: combinationStoreSymbolLayerId,
    type: 'symbol',
    source: combinationStoreSymbolSource.value,
    layout: {
      'symbol-sort-key': ['get', 'rank'],
      'text-field': ['get', 'rank'],
      'text-size': 12,
      'text-font': ['Arial Unicode MS Bold']
    },
    paint: {
      'text-color': '#ffffff'
    }
  }
}
function resetCompStore() {
  hidePopup.value = true
  try {
    const map = combinationStoreCircle.value.map
    map.removeSource(combinationStoreCircleId)
    if (map.getLayer(combinationStoreCircleLayerId)) {
      map.removeLayer(combinationStoreCircleLayerId)
      map.removeSource(combinationStoreCircleLayerId)
    }
  } catch (error) {
    console.error(error)
    throw new Error('Failed to delete layer.')
  }
  combinationStoreCircleSource.value = null
  combinationStoreCircleLayer.value = null
  try {
    const map = combinationStoreSymbol.value.map
    map.removeSource(combinationStoreSymbolId)
    if (map.getLayer(combinationStoreSymbolLayerId)) {
      map.removeLayer(combinationStoreSymbolLayerId)
      map.removeSource(combinationStoreSymbolLayerId)
    }
  } catch (error) {
    console.error(error)
    throw new Error('Failed to delete layer.')
  }
  combinationStoreSymbolSource.value = null
  combinationStoreSymbolLayer.value = null
  ;(async () => {
    await new Promise((resolve) => setTimeout(resolve, 0))
    createCompStore()
  })()
}

const hidePopup = ref<boolean>(true)
const hoverPopup = ref<boolean>(false)
const popupStoreId = ref<string>()
const popupStoreName = ref<string>()
const popupCoordinates = ref<number[]>()
const popupStoreType = ref<number>()
const popupCombinationRatio = ref<number>()
function mouseOverLayerOnBaseStore() {
  if (!props.baseStore) return
  popupStoreName.value = props.baseStore?.name
  popupCoordinates.value = [props.baseStore?.longitude, props.baseStore?.latitude]
  popupStoreType.value = props.baseStore?.storeType
  popupCombinationRatio.value = undefined
  nextTick(() => {
    hoverPopup.value = true
    hidePopup.value = false
  })
}

const isAlertOverlayOpen = ref<boolean>(props.hasAlert)

function mouseOverLayerOnCombinationStore(e: any) {
  popupStoreId.value = e.mapboxEvent.features[0].properties.storeId
  popupStoreName.value = e.mapboxEvent.features[0].properties.storeName
  popupCoordinates.value = [
    e.mapboxEvent.features[0].properties.longitude,
    e.mapboxEvent.features[0].properties.latitude
  ]
  popupStoreType.value = e.mapboxEvent.features[0].properties.storeType
  popupCombinationRatio.value = e.mapboxEvent.features[0].properties.combinationRatio
  nextTick(() => {
    hoverPopup.value = true
    hidePopup.value = false
  })
}
function mouseLeavePopup() {
  hoverPopup.value = false
  if (!hoverPopup.value) hidePopup.value = false
}

async function initMap(event: any) {
  map.value = event.map

  // 併用店舗が全て地図上に収まるようズーム
  let southPoint: number, westPoint: number, northPoint: number, eastPoint: number
  southPoint = northPoint = props.combinationStores[0].properties.latitude
  westPoint = eastPoint = props.combinationStores[0].properties.longitude
  props.combinationStores.forEach((cStore: CombinationMapFeatures) => {
    southPoint = Math.min(southPoint, cStore.properties.latitude)
    westPoint = Math.min(westPoint, cStore.properties.longitude)
    northPoint = Math.max(northPoint, cStore.properties.latitude)
    eastPoint = Math.max(eastPoint, cStore.properties.longitude)
  })
  // 店舗のマーカーが見切れるので少しずらす
  map.value.fitBounds([
    [westPoint, southPoint - 0.001], // southwestern corner of the bounds
    [eastPoint, northPoint + 0.001] // northeastern corner of the bounds
  ])
}

const propsObj = ref(props) // props監視用
watch(
  () => propsObj.value.combinationStores,
  () => {
    updateCombinationStoreLayer()
  }
)

watch(
  () => props.hasAlert,
  () => (isAlertOverlayOpen.value = props.hasAlert)
)
</script>

<style scoped>
.map-height {
  height: 600px;
}
.overlay-message {
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: white;
}
.overlay-message p {
  margin-bottom: 11px;
}
.reset-button {
  position: absolute;
  bottom: 35px;
  right: 10px;
  width: 129px;
  height: 28px;
  background: #222222 0% 0% no-repeat padding-box;
  box-shadow: 1px 1px 0px #00000029;
  border-radius: 4px;
  display: flex;
  align-items: flex-end;
}
.reset-button div {
  margin: 0 auto 9px;
  color: #ffffff;
  font: normal normal bold 12px/12px Noto Sans JP;
}
</style>
