<template>
  <div class="favorite-condition-card">
    <p>[業種] {{ storeTypeText }}</p>
    <p>[都道府県] {{ prefectureText }}</p>
    <p>[市区町村] {{ cityText }}</p>
    <p>[チェーン] {{ chainWordsText }}</p>
    <p>[フリーワード] {{ freeWordsText }}</p>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from "vue";
import { Favorite, Prefecture, City } from "@/features/Dashboard/types";
import { STORE_TYPE } from "@/commons/enums";
import {useStore} from "vuex";

export default defineComponent({
  name: "FavoriteConditionCard",
  props: {
    favorite: {
      type: Object as PropType<Favorite>,
      default: undefined,
    },
  },
  setup(props){
    const store = useStore();
    const storeTypeText = computed<string>(() => {
      if (!props.favorite) return "";
      return props.favorite.storeTypes
        .map((type) => STORE_TYPE.toLocalString(type))
        .join(", ");
    });
    const prefectureText = computed(() =>{     
      if (!props.favorite) return "";
      if (props.favorite.prefectureIds.length === 0) return "すべて";
      return props.favorite.prefectureIds
        .map((id) => {
          const prefecture = (
            store.state.prefectures as Prefecture[]
          ).find((prefecture) => prefecture.prefectureId === id);
          return prefecture?.name;
        })
        .filter((p) => p !== undefined)
        .join(", ");
    });
    const cityText = computed(
      () => {
        if (!props.favorite) return "";
        if (props.favorite.cityIds.length === 0) return "すべて";
        return props.favorite.cityIds
          .map((id) => {
            const city = (store.state.cities as City[]).find(
              (city) => city.cityId === id
            );
            return city?.name;
          })
          .filter((p) => p !== undefined)
          .join(", ");
      }
    );
    const chainWordsText = computed(() => {
      if (!props.favorite) return "";
      if (props.favorite.chainWords.length === 0) return "すべて";
      return props.favorite.chainWords.join(", ");
    });
    const freeWordsText = computed(() => {
      if (!props.favorite) return "";
      if (props.favorite.storeNameWords.length === 0) return "ー";
      return props.favorite.storeNameWords.join(", ");
    });
    

    return {
      store,
      storeTypeText,
      prefectureText,
      cityText,
      chainWordsText,
      freeWordsText,
    }
  },
});
</script>

<style lang="scss" scoped>
.favorite-condition-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20.5px;
  padding: 19px 22px;
  height: 200px;
  background-color: #f5f5f5;
  border: 1px solid #eeeeee;
  text-align: left;

  p {
    margin: 0;
    font-size: 14px;
    color: #222222;
  }
}
</style>
