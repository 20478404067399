<template>
  <v-container
    fluid
    class="pa-0"
  >
    <v-data-table
      :items="items"
      :items-per-page="50"
      density="comfortable"
    >
      <template #headers>
        <tr height="60">
          <th class="table-header-x">
            初回来店の月
          </th>
          <th class="table-header-x">
            0ヶ月後
          </th>
          <th class="table-header-x">
            1ヶ月後
          </th>
          <th class="table-header-x">
            2ヶ月後
          </th>
          <th class="table-header-x">
            3ヶ月後
          </th>
          <th class="table-header-x">
            4ヶ月後
          </th>
          <th class="table-header-x">
            5ヶ月後
          </th>
        </tr>
      </template>
      <template #body="props">
        <tr
          v-for="item in props.items"
          :key="item.granularity"
          height="60"
        >
          <th class="table-header-y">
            {{ item.granularity }}
          </th>
          <th :style="cellColor(item.zero, item.isAlert)">
            <span v-if="item.zero || item.zero === 0.0">{{ (Math.round(item.zero * 10) / 10).toFixed(1) }}%</span>
          </th>
          <th :style="cellColor(item.one, item.isAlert)">
            <span v-if="item.one || item.one === 0.0">{{ (Math.round(item.one * 10) / 10).toFixed(1) }}%</span>
          </th>
          <th :style="cellColor(item.two, item.isAlert)">
            <span v-if="item.two || item.two === 0.0">{{ (Math.round(item.two * 10) / 10).toFixed(1) }}%</span>
          </th>
          <th :style="cellColor(item.three, item.isAlert)">
            <span v-if="item.three || item.three === 0.0">{{ (Math.round(item.three * 10) / 10).toFixed(1) }}%</span>
          </th>
          <th :style="cellColor(item.four, item.isAlert)">
            <span v-if="item.four || item.four === 0.0">{{ (Math.round(item.four * 10) / 10).toFixed(1) }}%</span>
          </th>
          <th :style="cellColor(item.five, item.isAlert)">
            <span v-if="item.five || item.five === 0.0">{{ (Math.round(item.five * 10) / 10).toFixed(1) }}%</span>
          </th>
        </tr>
      </template>
      <template #bottom />
    </v-data-table>
    <v-row
      class="my-0"
      dense
    >
      <v-col>
        <div
          class="my-0"
          style="
            display: flex;
            align-items: center;
            font-size: 12px;
            color: #666;
          "
        >
          <v-spacer />
          <alert-caption
            v-if="isAlert"
            variants="square"
          />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import * as tableItemInterface from "@/features/ShopAnalytics/interfaces/component";

export default defineComponent({
  name: "CohortTable",
  props: {
    items: {
      type: Array as PropType<tableItemInterface.visitPatternTableItem[]>,
      default() {
        return [];
      },
    },
    isAlert: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  methods: {
    cellColor(ratio: number | null, isAlert: boolean) {
      if (ratio === null) return "";

      if (isAlert) return "background: #bbbbbb; text-align: center;";
      if (ratio <= 0) return "background: #ffffff; text-align: center;";
      if (ratio < 10)
        return "background: #EFC3C2; text-align: center; color: #ffffff;";
      if (ratio < 20)
        return "background: #E9B2B2; text-align: center; color: #ffffff;";
      if (ratio < 30)
        return "background: #E4A1A4; text-align: center; color: #ffffff;";
      if (ratio < 40)
        return "background: #DC8E93; text-align: center; color: #ffffff;";
      if (ratio < 50)
        return "background: #D67C84; text-align: center; color: #ffffff;";
      if (ratio < 60)
        return "background: #D06A75; text-align: center; color: #ffffff;";
      if (ratio < 70)
        return "background: #CA5966; text-align: center; color: #ffffff;";
      if (ratio < 80)
        return "background: #C44756; text-align: center; color: #ffffff;";
      if (ratio < 90)
        return "background: #BD3547; text-align: center; color: #ffffff;";
      if (ratio < 100)
        return "background: #B82438; text-align: center; color: #ffffff;";
      if (ratio === 100)
        return "background: #B11229; text-align: center; color: #ffffff;";
      return "";
    },
  },
});
</script>

<style scoped>
th {
  font-weight: 400;
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
  font-size: .75rem;
}

.table-header-x {
  text-align: center !important;
  color: rgba(0,0,0,.6);
  font-weight: 700 !important;
}
.table-header-y {
  text-align: center !important;
  color: #333;
  font-weight: 700 !important;
  width: 13em;
}
</style>

<style>
.v-data-table tr:hover th {
  background-color: #ffffff;
}
</style>
