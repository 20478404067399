import { Chain } from '@/commons/interfaces'
import { GoogleChartData } from '@/commons/types/GoogleChart'
import { Point } from '@/commons/types/Mapbox'
import { AreaListItem } from '@/features/ShopAnalytics/interfaces/component'
import {
  BizAreaAnalysisResponse,
  BizAreaValue,
  MapBoxFeatures
} from '@/features/ShopAnalytics/interfaces/response'

const CHART_COLOR_RED = '#B82F39'
const CHART_COLOR_LIGHT_RED = '#DE5A69'
const TOOLTIP_PROPERTIES = { type: 'string', role: 'tooltip', p: { html: true } }

/**
 * チャートデータ用に加工するための関数
 */
export function processBizAreaRankingData(
  response: BizAreaAnalysisResponse,
  selectedChain: Chain,
  areaList: AreaListItem[]
): GoogleChartData {
  const header = createHeader(selectedChain.name)
  const dataRows = response.areas.map((area, index) => createDataRow(area, index, areaList))

  return [header, ...dataRows]
}

function createHeader(chainName: string) {
  return ['Area Name', chainName || '', { role: 'style' }, TOOLTIP_PROPERTIES]
}

function createDataRow(area: BizAreaValue, index: number, areaList: AreaListItem[]) {
  const areaNameIndexed = `${index + 1}．${area.areaName}`
  const tooltipContent = createTooltip(area.areaName, area.visitRatio)
  const color = getColorForArea(areaNameIndexed, areaList)

  return [areaNameIndexed, area.visitRatio, color, tooltipContent]
}

function createTooltip(title: string, value: number) {
  const percentageValue = (Math.round(value * 1000) / 10).toFixed(1) + '%'
  return `
    <div style='display: flex; justify-content: center; align-items: center; height: 55px; color: #222222; font-size: 12px !important;'>
      <div style='display:table-cell; vertical-align:middle; text-align: left;'>
        <div>${title}</div>
        <div>[構成比] <b>${percentageValue}</b></div>
      </div>
    </div>
  `
}

function getColorForArea(areaNameIndexed: string, areaList: AreaListItem[]) {
  const matchResult = areaNameIndexed.match(/^\d+．(.+)$/)
  if (!matchResult) {
    return CHART_COLOR_LIGHT_RED
  }

  const areaName = matchResult[1]
  const isAreaPresent = areaList.some((item) => item.areaName === areaName.replace(/^\d+．/, ''))
  return isAreaPresent ? CHART_COLOR_RED : CHART_COLOR_LIGHT_RED
}

/**
 * 複数の Points(座標) より中心座標を算出する関数
 */
export function getCenter(points: Point[]): Point | undefined {
  if (points.length === 0) return undefined

  const total = points.reduce(
    (acc, current): Point => ({ lat: acc.lat + current.lat, lng: acc.lng + current.lng }),
    { lat: 0, lng: 0 }
  )

  return {
    lat: total.lat / points.length,
    lng: total.lng / points.length
  }
}

/**
 * 複数の feature(座標を含むデータ) より中心座標を算出する関数
 */
export function getCenterPoint(features: MapBoxFeatures[]): Point {
  const total = features.reduce(
    (acc, current): Point => {
      return {
        lat: acc.lat + current.properties.latitude,
        lng: acc.lng + current.properties.longitude
      }
    },
    { lat: 0, lng: 0 }
  )

  return {
    lat: total.lat / features.length,
    lng: total.lng / features.length
  }
}
