import moment from "moment";
import {
  StoreRegionMapResponse,
  StoreRegionMapItem,
  StoreRegionTableResponse,
  StoreRegionTableItem,
} from "@/features/RegionalShare/interfaces/response";

/**
 * 月次更新テーブルにおける期間開始日の初期値を返す。
 * 8日にデータが更新されるため、8日より前は2ヶ月前、8日以降は1ヶ月前の月の初めを返す。
 * @returns 月次更新テーブルにおける期間開始日の初期値
 */
export const getInitialStartDate = () => {
  const SUBTRACT_NUM = moment().date() < 8 ? 2 : 1;
  return moment()
    .subtract(SUBTRACT_NUM, "month")
    .startOf("month")
    .format("YYYY/MM/DD");
};

/**
 * 月次更新テーブルにおける期間終了日の初期値を返す。
 * 8日にデータが更新されるため、8日より前は2ヶ月前、8日以降は1ヶ月前の月終わりを返す。
 * @returns 月次更新テーブルにおける期間終了日の初期値
 */
export const getInitialEndDate = () => {
  const SUBTRACT_NUM = moment().date() < 8 ? 2 : 1;
  return moment()
    .subtract(SUBTRACT_NUM, "month")
    .endOf("month")
    .format("YYYY/MM/DD");
};

export function processingBaseStoreData(response: StoreRegionMapResponse) {
  return [
    {
      type: "Feature",
      properties: {
        storeId: response.baseStore.storeId,
        storeName: response.baseStore.name,
        storeType: response.baseStore.type,
        visitCount: response.baseStore.visitCount,
        longitude: response.baseStore.longitude,
        latitude: response.baseStore.latitude,
      },
      geometry: {
        type: "Point",
        coordinates: [
          response.baseStore.longitude,
          response.baseStore.latitude,
        ],
      },
    },
  ];
}
export function processingCompStoreData(response: StoreRegionMapResponse) {
  return response.compStores.map((compStore: StoreRegionMapItem) => {
    return {
      type: "Feature",
      properties: {
        storeId: compStore.storeId,
        storeName: compStore.name,
        storeType: compStore.type,
        visitCount: compStore.visitCount,
        longitude: compStore.longitude,
        latitude: compStore.latitude,
      },
      geometry: {
        type: "Point",
        coordinates: [compStore.longitude, compStore.latitude],
      },
    };
  });
}
export function getMaxValue(response: StoreRegionMapResponse) {
  return response.compStores.reduce(
    (previous: StoreRegionMapItem, current: StoreRegionMapItem) => {
      if (previous.visitCount > current.visitCount) return previous;
      else return current;
    },
    response.baseStore
  ).visitCount;
}

export function getTableMonthly(response: StoreRegionTableResponse) {
  const baseMonthlyPeriod = response.baseStore.visitCount.map(
    (item: { date: string; value: number }) => {
      return item.date;
    }
  );
  let compMonthlyPeriod: string[] = [];
  response.compStores.forEach((store: StoreRegionTableItem) => {
    const tmpArray = store.visitCount.map(
      (item: { date: string; value: number }) => {
        return item.date;
      }
    );
    compMonthlyPeriod = compMonthlyPeriod.concat(tmpArray);
  });
  // 重複排除してArrayで返却
  return Array.from(
    new Set(baseMonthlyPeriod.concat(compMonthlyPeriod).sort())
  );
}
export function processingBaseStoreTableData(
  response: StoreRegionTableResponse
) {
  const baseStore = response.baseStore;
  // NOTE: 店舗によって各月のデータが揃わない可能性があるので無い月のデータを補完する
  const monthly = getTableMonthly(response);
  const tmpComplementCount: any[] = [];
  monthly.forEach((monthlyDate) => {
    if (
      !baseStore.visitCount.some(
        (item: { date: string; value: number }) => item.date === monthlyDate
      )
    )
      tmpComplementCount.push({ date: monthlyDate, value: 0 });
  });
  baseStore.visitCount = baseStore.visitCount.concat(tmpComplementCount);

  // 月順でソート
  baseStore.visitCount.sort((a, b) => {
    return a.date > b.date ? 1 : -1;
  });
  return baseStore;
}
export function processingCompStoreTableData(
  response: StoreRegionTableResponse
) {
  const compStores = response.compStores;
  // NOTE: 店舗によって各月のデータが揃わない可能性があるので無い月のデータを補完する
  const monthly = getTableMonthly(response);
  compStores.forEach((compStore: StoreRegionTableItem) => {
    const tmpComplementCount: any[] = [];
    monthly.forEach((monthlyDate) => {
      if (
        !compStore.visitCount.some(
          (item: { date: string; value: number }) => item.date === monthlyDate
        )
      )
        tmpComplementCount.push({ date: monthlyDate, value: 0 });
    });
    compStore.visitCount = compStore.visitCount.concat(tmpComplementCount);

    // 月順でソート
    compStore.visitCount.sort((a, b) => {
      return a.date > b.date ? 1 : -1;
    });
  });
  return compStores;
}
