<template>
  <MglMap
    v-if="centerPoint"
    class="map-height"
    :access-token="MAP_ACCESS_TOKEN"
    :map-style="MAP_STYLE"
    :center="initialCenterPoint"
    :zoom="initialZoom"
    :attribution-control="false"
    :logo-position="'bottom-right'"
    :drag-rotate="false"
    :touch-zoom-rotate="false"
    :pitch-with-rotate="false"
    @load="initMap"
    @click="onClickMap"
  >
    <!-- ローディング -->
    <v-overlay
      contained
      :model-value="mapLoading"
      class="align-center justify-center"
      :z-index="3"
      :persistent="true"
      :no-click-animation="true"
    >
      <LoadingImg v-if="mapLoading" :height="'600px'" />
    </v-overlay>

    <!-- データ不足警告 -->
    <v-overlay
      contained
      :model-value="hasAlert"
      content-class="w-100 h-100"
      scrim="rgba(0,0,0,0)"
      :z-index="3"
      :persistent="true"
      :no-click-animation="true"
    >
      <div
        class="d-flex align-center justify-center w-100 h-100"
        style="background-color: rgba(0, 0, 0, 0.6)"
      >
        <div class="overlay-message">
          <p>取得データボリュームが少なく、</p>
          <p>統計上の信頼性の低いデータが含まれています。</p>
          <p>参考値としてご参照ください。</p>
          <v-btn variant="text" style="text-decoration: underline" @click="onClickVOverlay()">
            閉じる
          </v-btn>
        </div>
      </div>
    </v-overlay>

    <!-- 帰属 -->
    <MglAttributionControl />
    <!-- +/- ズームコントローラー -->
    <MglNavigationControl :show-compass="false" position="top-right" />
    <!-- 縮尺 -->
    <MglScaleControl position="top-left" />
    <!-- 店舗情報表示ボックス -->
    <div class="store-info">
      <MapInformationBox :text="hoveredStoreInfo" />
    </div>

    <!-- チェーンポップアップ -->
    <MglPopup
      :showed="hidePopup"
      :coordinates="popupCoordinates"
      anchor="bottom-left"
      :close-button="false"
      :close-on-click="false"
    >
      <div @mousemove="handleMouseMovePopup" @mouseleave="handleMouseLeavePopup">
        <BizAreaMapPopup
          :title="areaName"
          :text="(Math.round(visitRatio * 1000) / 10).toFixed(1) + '%'"
          @close-popup="handlePopupClose"
        />
      </div>
    </MglPopup>

    <button class="reset-button" @click="$emit('resetActive')">
      <div>エリアの選択を解除</div>
    </button>
  </MglMap>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue'
import { MAP_ACCESS_TOKEN, MAP_STYLE } from '@/config'
import {
  MglMap,
  MglAttributionControl,
  MglNavigationControl,
  MglScaleControl,
  MglPopup
} from '@/vendor/vue-mapbox/main'
import BizAreaMapPopup from '@/features/ShopAnalytics/components/BizArea/BizAreaMapPopup.vue'
import LoadingImg from '@/commons/components/loadingImg.vue'
import { AreaListItem } from '@/features/ShopAnalytics/interfaces/component'
import { MapBoxFeatures } from '@/features/ShopAnalytics/interfaces/response'
import getDistance from '@/commons/utils/map/get-distance'
import {
  GRANULARITY,
  TOKYO_AREA_ID,
  TOKYO_BBOX_LEFT_DOWN,
  TOKYO_BBOX_RIGHT_TOP
} from '@/commons/enums'
import { BBox } from '@/commons/types/GeoJSON'
import { Point } from '@/commons/types/Mapbox'
import MapInformationBox from '@/commons/components/Map/MapInformationBox.vue'
import { Store } from '@/commons/interfaces/responses/store'
import centerPin from '@/assets/svg/center-pin.svg'
import { getOverallBbox } from '@/commons/utils/geojson/get-overall-bbox'
import { debounce } from 'lodash'

const props = withDefaults(
  defineProps<{
    mapLoading: boolean
    hasAlert: boolean
    featureCollection: MapBoxFeatures[]
    maxRatio: number
    activeArea: AreaListItem[]
    dataCenterPoint: { lat: number; lng: number } | undefined
    granularity: (typeof GRANULARITY)[keyof typeof GRANULARITY]
    granularityRadioButtonState: (typeof GRANULARITY)[keyof typeof GRANULARITY]
    prefectureIds: string[]
    stores: Store[]
    onCenterChange?: (center: Point) => void
    onZoomStart?: () => void
    onZoomEnd?: () => void
    onScaleChange?: (scale: number) => void
    onBoundsChange?: (bbox: BBox) => void
  }>(),
  {
    mapLoading: false,
    hasAlert: false,
    featureCollection: () => [],
    maxRatio: 1,
    activeArea: () => [],
    dataCenterPoint: undefined,
    granularity: GRANULARITY.PREFECTURE,
    granularityRadioButtonState: GRANULARITY.PREFECTURE,
    prefectureIds: () => [],
    stores: () => [],
    onCenterChange: () => undefined,
    onZoomStart: () => undefined,
    onZoomEnd: () => undefined,
    onScaleChange: () => undefined,
    onBoundsChange: () => undefined
  }
)

const emits = defineEmits(['resetActive', 'clickLayer', 'clickApproveAlert'])

const onClickVOverlay = () => {
  emits('clickApproveAlert')
}

const sourceId = 'bizAreaSourceId'
const layerId = 'bizAreaLayerId'
const highlightLayerId = 'highlightBizAreaLayerId'
const storesSourceId = 'storesSourceId'

// Mapbox GI JS Map オブジェクト
// ※ vue-mapbox 側で any 定義されているため any で定義
const map = ref<any>(null)

// マップの中心座標
const centerPoint = computed(() => {
  return props.dataCenterPoint ? [props.dataCenterPoint.lng, props.dataCenterPoint.lat] : undefined
})

// マップの中心座標の初期値
const initialCenterPoint = props.dataCenterPoint
  ? [props.dataCenterPoint.lng, props.dataCenterPoint.lat]
  : undefined

const viewPoint = ref(
  props.dataCenterPoint
    ? { lat: props.dataCenterPoint.lat, lng: props.dataCenterPoint.lng }
    : undefined
)

const scale = ref(0)

// 都道府県選択時のズームレベル
const PREFECTURE_ZOOM_LEVEL = 8
// 縮尺 (表示領域の縦の距離) がおよそ 40km を示すズームレベル。
// 閾値が 40km なので 40 より少し短めに設定。
const FORTY_KM_ZOOM_LEVEL = 10
// ズーム値
const initialZoom = ref<number>(
  Number(props.granularity) === GRANULARITY.PREFECTURE ? PREFECTURE_ZOOM_LEVEL : FORTY_KM_ZOOM_LEVEL
)

// ポリゴンを画面内にフィットさせる関数
const fitOverallBounds = (features: MapBoxFeatures[]) => {
  if (!map.value) return

  const prefectureIds = props.prefectureIds.map((id) => id.toString().padStart(2, '0'))

  const bounds = getOverallBbox(
    features.filter(
      (feature: MapBoxFeatures) =>
        !!feature.geometry && prefectureIds.includes(feature.properties.areaId.split('-')[0] ?? '')
    )
  )

  const isInvalid = bounds
    .flat()
    .some(
      (value) => value === Number.POSITIVE_INFINITY || value === Number.NEGATIVE_INFINITY || !value
    )

  if (!isInvalid) map.value.fitBounds(bounds, { padding: 20 })
}

/* --------------------------------------------------------------------------
  色塗り関連
 -------------------------------------------------------------------------- */

const updateBizArea = () => {
  if (!map.value) return

  // 既存のデータソースを更新
  if (map.value.getSource(sourceId)) {
    const source = map.value.getSource(sourceId)
    source.setData({
      id: sourceId,
      type: 'FeatureCollection',
      features: props.featureCollection
    })
  } else {
    // データソースが存在しない場合は新規作成
    map.value.addSource(sourceId, {
      type: 'geojson',
      data: {
        id: sourceId,
        type: 'FeatureCollection',
        features: props.featureCollection
      }
    })
  }

  // 既存の店舗クラスタ用データソースを更新
  if (map.value.getSource(storesSourceId)) {
    const source = map.value.getSource(storesSourceId)
    source.setData(storesSource.value?.data)
  } else {
    // 店舗クラスタ用データソースが存在しない場合は新規作成
    map.value.addSource(storesSourceId, storesSource.value)
  }

  // レイヤーの追加（存在しない場合のみ）
  if (!map.value.getLayer(layerId)) {
    map.value.addLayer({
      id: layerId,
      type: 'fill',
      source: sourceId,
      layout: {},
      paint: {
        'fill-color': [
          'interpolate',
          ['linear'],
          ['get', 'visitRatio'],
          0,
          '#F8DDE0',
          props.maxRatio,
          '#D62F41'
        ],
        'fill-opacity': 0.6
      }
    })
  }

  if (!map.value.getLayer(highlightLayerId)) {
    map.value.addLayer({
      id: highlightLayerId,
      type: 'line',
      source: sourceId,
      layout: {},
      paint: {
        'line-width': 1
      },
      filter: ['in', 'areaId', '']
    })
  }

  if (!map.value.getLayer(storesIconLayer.value?.id)) {
    map.value.addLayer(storesIconLayer.value)
  }

  if (!map.value.getLayer(storesClustersLayer.value?.id)) {
    map.value.addLayer(storesClustersLayer.value)
  }

  if (!map.value.getLayer(storesCountLayer.value?.id)) {
    map.value.addLayer(storesCountLayer.value)
  }

  if (props.featureCollection) {
    fitOverallBounds(props.featureCollection)
  }

  if (storesIconLayer.value && map.value.getLayer(storesIconLayer.value.id)) {
    map.value.moveLayer(storesIconLayer.value.id)
  }

  if (storesClustersLayer.value && map.value.getLayer(storesClustersLayer.value.id)) {
    map.value.moveLayer(storesClustersLayer.value.id)
  }

  if (storesCountLayer.value && map.value.getLayer(storesCountLayer.value.id)) {
    map.value.moveLayer(storesCountLayer.value.id)
  }
}

const onClickMap = (e: any) => {
  const selectedFeatures = map.value.queryRenderedFeatures(e.mapboxEvent.point)
  if (selectedFeatures.length === 0) return
  emits('clickLayer', selectedFeatures[0].properties.areaId)
}

/* --------------------------------------------------------------------------
  アラート関連
 -------------------------------------------------------------------------- */

// map オブジェクトから scale を算出する関数
const getScale = () => {
  const bounds = map.value.getBounds()
  const northLat = bounds.getNorth()
  const southLat = bounds.getSouth()
  const { lng: centerLng } = bounds.getCenter()
  const scale = getDistance({ lng: centerLng, lat: northLat }, { lng: centerLng, lat: southLat })
  return scale
}

/* --------------------------------------------------------------------------
  ポップアップ関連
 -------------------------------------------------------------------------- */

const hidePopup = ref(true)
const hoveringArea = ref(false)
const hoveringPopup = ref(false)
const popupCoordinates = ref([0, 0])
const areaName = ref('')
const visitRatio = ref(0.0)

const handleMouseMoveArea = (e: any) => {
  hoveringArea.value = true
  if (e.features[0].properties.areaId === TOKYO_AREA_ID.toString()) {
    popupCoordinates.value = [
      (TOKYO_BBOX_LEFT_DOWN[0] + TOKYO_BBOX_RIGHT_TOP[0]) / 2,
      (TOKYO_BBOX_LEFT_DOWN[1] + TOKYO_BBOX_RIGHT_TOP[1]) / 2
    ]
  } else {
    popupCoordinates.value = [e.features[0].properties.longitude, e.features[0].properties.latitude]
  }
  areaName.value = e.features[0].properties.areaName
  visitRatio.value = e.features[0].properties.visitRatio
  hidePopup.value = false
}

const handleMouseLeaveArea = () => {
  hoveringArea.value = false
}

const handleMouseMovePopup = () => {
  hoveringPopup.value = true
}

const handleMouseLeavePopup = () => {
  hoveringPopup.value = false
  handlePopupClose()
}

const handlePopupClose = () => {
  hidePopup.value = true
  popupCoordinates.value = [0, 0]
  areaName.value = ''
  visitRatio.value = 0.0
}

/* --------------------------------------------------------------------------
  店舗クラスタ関連
 -------------------------------------------------------------------------- */

// 店舗ピンホバーに表示するテキスト
const hoveredStoreInfo = ref<string | undefined>()

const storesSource = computed(() => {
  if (props.stores.length === 0) return undefined
  return {
    type: 'geojson',
    data: {
      id: storesSourceId,
      type: 'FeatureCollection',
      features: props.stores.map((store) => ({
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [store.longitude, store.latitude]
        },
        properties: {
          store
        }
      }))
    },
    cluster: true,
    clusterMaxZoom: 14,
    clusterRadius: 30
  }
})

const storesIconLayer = computed(() => {
  if (props.stores.length === 0) return undefined
  return {
    id: 'stores-icon',
    type: 'symbol',
    source: storesSourceId,
    filter: ['!', ['has', 'point_count']],
    layout: {
      'icon-image': 'store-pin',
      'icon-allow-overlap': true
    }
  }
})

const storesClustersLayer = computed(() => {
  if (props.stores.length === 0) return undefined
  return {
    id: 'stores-cluster',
    type: 'circle',
    source: storesSourceId,
    filter: ['has', 'point_count'],
    paint: {
      'circle-radius': ['step', ['get', 'point_count'], 17.5, 15, 16, 25, 20, 50, 28, 150, 32],
      'circle-color': '#4D99D0',
      'circle-stroke-color': '#ffffff',
      'circle-stroke-width': 2,
      'circle-opacity': 0.8
    }
  }
})

const storesCountLayer = computed(() => {
  if (props.stores.length === 0) return undefined
  return {
    id: 'cluster-count',
    type: 'symbol',
    source: storesSourceId,
    filter: ['has', 'point_count'],
    layout: {
      'text-field': '{point_count}',
      'text-size': 11,
      'text-font': ['Open Sans Bold', 'Arial Unicode MS Bold']
    },
    paint: {
      'text-color': '#ffffff'
    }
  }
})

/* --------------------------------------------------------------------------
  watch
 -------------------------------------------------------------------------- */

watch(
  () => props.featureCollection,
  () => {
    updateBizArea()
  }
)

watch(
  () => props.activeArea as Array<AreaListItem>,
  (newVal: Array<AreaListItem>) => {
    if (newVal.length === 0) {
      map.value.setFilter(highlightLayerId, ['in', 'areaId', ''])
    } else {
      const activeAreaIds: string[] = newVal.map((activeArea: AreaListItem) => {
        return activeArea.areaId
      })
      map.value.setFilter(highlightLayerId, ['in', 'areaId', ...activeAreaIds])
    }
  }
)

async function initMap(event: any) {
  map.value = event.map

  viewPoint.value = map.value.getCenter()

  map.value.on('moveend', () => {
    const center: Point = map.value.getCenter()
    viewPoint.value = center
    props.onCenterChange(center)
  })

  scale.value = getScale()
  props.onScaleChange(getScale())

  map.value.on('zoomstart', () => {
    props.onZoomStart()
  })

  map.value.on('zoomend', () => {
    props.onZoomEnd()
    scale.value = getScale()
    props.onScaleChange(getScale())
  })

  map.value.on('mouseenter', 'stores-icon', (e: any) => {
    const store = JSON.parse(e.features[0].properties.store)
    hoveredStoreInfo.value = store.name
  })

  map.value.on('mouseleave', 'stores-icon', () => {
    hoveredStoreInfo.value = undefined
  })

  map.value.on('mousemove', layerId, (e: any) => {
    handleMouseMoveArea(e)
  })

  map.value.on(
    'mouseleave',
    layerId,
    debounce(() => {
      handleMouseLeaveArea()
      if (!hoveringPopup.value && !hoveringArea.value) {
        handlePopupClose()
      }
    }, 50)
  )

  const pinIcon = new Image(15, 20)
  pinIcon.onload = () => map.value.addImage('store-pin', pinIcon)
  pinIcon.src = centerPin

  if (props.featureCollection) {
    updateBizArea()
  }
}

defineExpose({ viewPoint, scale })
</script>

<style scoped>
.map-height {
  height: 600px;
}
.overlay-message {
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  color: white;
}
.overlay-message p {
  margin-bottom: 11px;
}
.reset-button {
  position: absolute;
  bottom: 35px;
  right: 10px;
  width: 129px;
  height: 28px;
  background: #222222 0% 0% no-repeat padding-box;
  box-shadow: 1px 1px 0px #00000029;
  border-radius: 4px;
  display: flex;
  align-items: flex-end;
}
.reset-button div {
  margin: 0 auto 9px;
  color: #ffffff;
  font: normal normal bold 12px/12px Noto Sans JP;
}
.store-info {
  position: absolute;
  bottom: 10px;
  left: 10px;
}

.map-alert {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  margin: auto;
  padding: 0 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 350px;
  height: 83px;
  border: 1px solid var(---666666);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #666666;
  text-align: left;
  font: normal normal normal 13px/26px Noto Sans JP;
  letter-spacing: 0px;
  color: #333333;
  opacity: 0.85;
}
</style>
