import { convertHyphenDelimiter } from '@/commons/utils/dateUtil'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

/**
 * 期間のクエリパラメータを更新するためのカスタムフック
 *
 * **Usage**
 *
 * ```ts
 * const { setPeriodParams } = usePeriodQueryParams()
 *
 * setPeriodParams({
 *   startDate: "2024-01-01",
 *   endDate: "2024-01-02",
 * })
 * ```
 *
 * **Details**
 *
 * このフックは、URL のクエリパラメータを通じてアプリケーションの状態を更新するためのメソッド setPeriodParams を提供します。setPeriodParams メソッドを使用して、ユーザーが選択した期間（startDate と endDate）および比較期間（compareStartDate と compareEndDate）を設定できます。これらの期間は、URL のクエリパラメータとしてエンコードされ、ページのリロードなしにアプリケーションの状態を反映します。また、isStoreToUpdate パラメータを true に設定することで、Vuex ストアの状態も同時に更新することができます。
 *
 */
export default function usePeriodQueryParams() {
  const route = useRoute()
  const router = useRouter()
  const store = useStore()

  const setPeriodParams = (
    params: {
      startDate?: string
      endDate?: string
      compareStartDate?: string
      compareEndDate?: string
      startMonth?: string
      endMonth?: string
    },
    isStoreToUpdate = true
  ) => {
    const { startDate, endDate, compareStartDate, compareEndDate, startMonth, endMonth } = params
    const period =
      startDate && endDate
        ? `${convertHyphenDelimiter(startDate)}_${convertHyphenDelimiter(endDate)}`
        : undefined
    const c_period =
      compareStartDate && compareEndDate
        ? `${convertHyphenDelimiter(compareStartDate)}_${convertHyphenDelimiter(compareEndDate)}`
        : undefined
    const m_period =
      startMonth && endMonth
        ? `${convertHyphenDelimiter(startMonth)}_${convertHyphenDelimiter(endMonth)}`
        : undefined

    if (isStoreToUpdate) {
      
      if (period)
        store.commit('setDate', {
          startDate,
          endDate
        })

      // NOTE: 比較期間は空文字可
      store.commit('setCompareDate', {
        startDate: compareStartDate ? compareStartDate : '',
        endDate: compareEndDate ? compareEndDate: ''
      })

      if (m_period)
        store.commit('setMonth', {
          startMonth,
          endMonth
        })
    }

    router.replace({
      query: {
        ...route.query,
        ...(period || m_period
          ? {
              period: period ?? m_period
            }
          : {}),
        c_period
      }
    })
  }

  return { setPeriodParams }
}
