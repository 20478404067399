<template>
  <v-container
    class="pa-1 mb-1"
    fluid
  >
    <div class="grid-wrap">
      <div class="cols-5">
        <GChart
          type="BarChart"
          :data="leftChartData"
          :options="
            option({
              height: height,
              position: 'left',
            })
          "
          :events="leftEvent"
          @ready="chart => leftChart = chart"
        />
      </div>
      <div class="cols-2">
        <GChart
          type="BarChart"
          :data="centerChartData"
          :options="
            option({
              height: height,
              position: 'center',
            })
          "
          :events="centerEvent"
          @ready="chart => centerChart = chart"
        />
      </div>
      <div class="cols-2">
        <GChart
          type="BarChart"
          :data="rightChartData"
          :options="
            option({
              height: height,
              position: 'right',
            })
          "
          :events="rightEvent"
          @ready="chart => rightChart = chart"
        />
      </div>
    </div>
  </v-container>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";
import { GChart } from "vue-google-charts";

export default defineComponent({
  name: "BehavioralDNAChart",
  components: {
    GChart,
  },
  props: {
    chartData: {
      type: Array as PropType<(string | number)[][]>,
      default: () => {
        return [];
      },
    },
    height: {
      type: Number,
      default: () => {
        return 550;
      },
    },
    min: { type: Number, required: true },
    max: { type: Number, required: true  },
  },
  emits: ["selectColum"],
  data() {
    return {
      option: (args: {
        height: number;
        position: "left" | "center" | "right";
      }): any => { // vue-google-chartsの型定義がいいかげんなのでanyで
        const { height, position } = args;
        const ticks = [...Array(20)]
          .map((_, i) => i * 5)
          .filter((n) => {
            if (this.min % 5 < 2.5)
              return this.min - 10 < n && n < this.max + 5;
            return this.min - 5 < n && n < this.max + 5;
          });
        return {
          chartArea: {
            top: 50,
            left: position === "left" ? 175 : 20,
            right: 20,
            bottom: 50,
            backgroundColor: {
              fill: (() => {
                if (position === "left") return "#fff";
                if (position === "center") return "#f5f8fd";
                if (position === "right") return "#fff6f7";
              })(),
            },
          },
          colors: (() => {
            if (position === "left") return ["#55A07C"];
            if (position === "center") return ["#4D99D0"];
            if (position === "right") return ["#DE5A69"];
          })(),
          hAxis: {
            textStyle: {
              fontSize: 12,
            },
            viewWindow: {
              max: ticks[ticks.length - 1],
              min: ticks[0],
            },
            ticks: ticks,
          },
          height: height,
          legend: {
            position: "top",
            alignment: "center",
            textStyle: {
              fontSize: 12,
            },
          },
          tooltip: {
            isHtml: true,
          },
          vAxis: {
            textStyle: {
              fontSize: 10,
            },
            textPosition: position !== "left" ? "none" : "out",
          },
          bar: {
            groupWidth: 17,
          },
        };
      },
      // 以下、vue-google-chartsの型定義がいいかげんなのでanyで
      leftChart: null as any,
      centerChart: null as any,
      rightChart: null as any,
      leftEvent: {select: this.leftSelect} as any,
      centerEvent: {select: this.centerSelect} as any, 
      rightEvent: {select: this.rightSelect} as any,
    };
  },
  computed: {
    leftChartData(): (string | number)[][] {
      return this.chartData.map((data) => {
        return [data[0], data[1], data[2], data[3]].flat();
      });
    },
    centerChartData(): (string | number)[][] {
      return this.chartData.map((data) => {
        return [data[0], data[4], data[5], data[6]].flat();
      });
    },
    rightChartData(): (string | number)[][] {
      return this.chartData.map((data) => {
        return [data[0], data[7], data[8], data[9]].flat();
      });
    },
  },
  methods: {
    leftSelect() {
      if (this.leftChart.getSelection().length) {
        this.centerChart.setSelection([
          this.leftChart.getSelection()[0],
        ]);
        this.rightChart.setSelection([
          this.leftChart.getSelection()[0],
        ]);
        this.$emit(
          "selectColum",
          this.leftChart.getSelection()[0]
        );
      } else {
        this.centerChart.setSelection(null);
        this.rightChart.setSelection(null);
        this.$emit("selectColum", null);
      }
    },
    centerSelect() {
      if (this.centerChart.getSelection().length) {
        this.leftChart.setSelection([
          this.centerChart.getSelection()[0],
        ]);
        this.rightChart.setSelection([
          this.centerChart.getSelection()[0],
        ]);
        this.$emit(
          "selectColum",
          this.centerChart.getSelection()[0]
        );
      } else {
        this.leftChart.setSelection(null);
        this.rightChart.setSelection(null);
        this.$emit("selectColum", null);
      }
    },
    rightSelect() {
      if (this.rightChart.getSelection().length) {
        this.leftChart.setSelection([
          this.rightChart.getSelection()[0],
        ]);
        this.centerChart.setSelection([
          this.rightChart.getSelection()[0],
        ]);
        this.$emit(
          "selectColum",
          this.rightChart.getSelection()[0]
        );
      } else {
        this.leftChart.setSelection(null);
        this.centerChart.setSelection(null);
        this.$emit("selectColum", null);
      }
    },
  }
});
</script>

<style lang="scss" scoped>
.grid-wrap {
  display: grid;
  grid-template-columns: repeat(9, minmax(0, 1fr));

  .cols-5 {
    grid-column: span 5 / span 5;
  }

  .cols-2 {
    grid-column: span 2 / span 2;
  }
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  height: 7px;
  width: 7px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
</style>
