export enum ROUTE_TYPE {
  other,
  dashboard,
  favorite,
  compareList,
  analytics,
  compare,
  regional,
  chainAnalytics,
  chainCompare,
  trend
}
