<template>
  <svg
    class="logo"
    xmlns="http://www.w3.org/2000/svg"
    width="180.231"
    height="27"
    viewBox="0 0 180.231 27"
  >
    <g
      id="_1064"
      data-name="1064"
      transform="translate(217.022 378.14)"
    >
      <g
        id="_1056"
        data-name="1056"
        transform="translate(-130.212 -378.14)"
      >
        <g
          id="_1055"
          data-name="1055"
        >
          <g
            id="_1054"
            data-name="1054"
          >
            <g
              id="_1053"
              data-name="1053"
            >
              <g
                id="_1052"
                data-name="1052"
              >
                <path
                  id="_936"
                  data-name="936"
                  d="M921.7,380.964l-.008,0a.552.552,0,0,0-.712.321,11.879,11.879,0,1,1-.208-8.919,12.069,12.069,0,0,1,.455,1.234.552.552,0,0,0,1.056-.326,13.221,13.221,0,0,0-.5-1.347,12.976,12.976,0,0,0-24.027,9.791l-3.705,1.6-2.759-1.579a.574.574,0,0,0-.5-.024l-6.97,3.019a3.313,3.313,0,1,0,2.635,6.081l6.968-3.014a.557.557,0,0,0,.322-.377l.738-3.091,3.692-1.6a12.89,12.89,0,0,0,6.9,6.415h0a12.983,12.983,0,0,0,16.926-7.478A.553.553,0,0,0,921.7,380.964Z"
                  transform="translate(-881.833 -364.089)"
                  fill="currentColor"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
      <g
        id="_1063"
        data-name="1063"
        transform="translate(-216.777 -375.731)"
      >
        <path
          id="_937"
          data-name="937"
          d="M916.28,257.979a26.261,26.261,0,0,1,3.887,2.142c.529.317,1.956,1.164,1.956,1.613,0,.582-1.692,2.909-2.274,2.909a1.451,1.451,0,0,1-.661-.344,45.819,45.819,0,0,0-4.469-2.856c-.291-.159-.555-.291-.555-.529C914.165,260.412,915.7,257.979,916.28,257.979Zm.264,11.238c.608-.132,2.486-.846,3.173-1.111a16.43,16.43,0,0,0,6.4-3.887,23.613,23.613,0,0,0,4.654-8.3c.106-.291.264-.687.661-.687.37,0,1.19.767,1.507,1.058.344.344,1.322,1.3,1.322,1.692a7.955,7.955,0,0,1-.582,1.745c-2.856,6.875-7.4,10.524-14.253,13.089-.714.264-.767.264-1.084.714a.293.293,0,0,1-.238.185c-.264,0-.608-.767-.714-1-.37-.74-.926-2.089-1.269-2.75a1.043,1.043,0,0,1-.132-.37C915.989,269.349,916.36,269.27,916.544,269.217Zm6.584-14.675c.37.264,1.772,1.243,1.772,1.613,0,.582-1.719,2.8-2.248,2.8-.185,0-.529-.291-.688-.423a49.088,49.088,0,0,0-4.336-3.041c-.159-.106-.5-.317-.5-.529,0-.5,1.745-2.671,2.274-2.671C919.876,252.294,922.573,254.145,923.129,254.542Z"
          transform="translate(-914.165 -252.294)"
          fill="currentColor"
          stroke="currentColor"
          stroke-miterlimit="10"
          stroke-width="0.49"
        />
        <path
          id="_938"
          data-name="938"
          d="M1031.506,280.279c.846,0,1.4.317,1.4,1.216V291.65c0,.555.026,1.137.026,1.719,0,1-.132,1.163-1.19,1.163h-1c-.767,0-.979-.106-1.084-.529-.079-.291-.212-.344-.926-.344h-5.473c-.926,0-1.878.053-2.8.053-.582,0-.714-.132-.714-1.428v-.925c0-.608.053-1,.608-1,.608,0,1.877.053,2.856.053h5.817a.5.5,0,0,0,.5-.5v-.82a.486.486,0,0,0-.5-.5h-5.553c-.979,0-2.115.053-2.829.053-.634,0-.687-.4-.687-1.4v-.688c0-.846.026-1.216.635-1.216.317,0,.978.053,1.666.053h6.769a.5.5,0,0,0,.5-.5v-.82a.5.5,0,0,0-.5-.5H1023.2c-.9,0-1.825.053-2.75.053-.661,0-.714-.317-.714-1.4v-.793c0-.793.053-1.164.608-1.164.4,0,.846.026,1.428.026Z"
          transform="translate(-997.474 -274.357)"
          fill="currentColor"
          stroke="currentColor"
          stroke-miterlimit="10"
          stroke-width="0.49"
        />
        <path
          id="_939"
          data-name="939"
          d="M1100.611,282.407a1.738,1.738,0,0,1,.212.582c0,.661-2.327,1.692-2.723,1.692-.291,0-.45-.317-.555-.529a32.9,32.9,0,0,0-2.089-3.49,1.065,1.065,0,0,1-.238-.529c0-.555,2.168-1.534,2.644-1.534a.841.841,0,0,1,.529.37A21.842,21.842,0,0,1,1100.611,282.407Zm9.81-4.072c.793.264,1.56.5,1.56.9,0,.132-.132.264-.344.423-.238.185-.291.185-.423.661-.9,2.961-1.56,4.971-3.6,7.4a15.246,15.246,0,0,1-7.457,4.813,3.573,3.573,0,0,1-.872.238c-.423,0-1.111-.978-1.375-1.348-.026-.053-.079-.106-.132-.185a2.74,2.74,0,0,1-.528-1.084c0-.344.185-.449.476-.529a18.6,18.6,0,0,0,4.31-1.8,10.514,10.514,0,0,0,3.887-3.728,15.384,15.384,0,0,0,1.957-5.9c.053-.238.132-.582.4-.582A16.936,16.936,0,0,1,1110.421,278.335Zm-6.954-.9a22.841,22.841,0,0,1,2.3,3.57,1.3,1.3,0,0,1,.159.5c0,.635-2.3,1.666-2.7,1.666-.291,0-.449-.291-.582-.529a27.2,27.2,0,0,0-2.142-3.57.69.69,0,0,1-.185-.449c0-.5,2.168-1.508,2.671-1.508A.791.791,0,0,1,1103.467,277.435Z"
          transform="translate(-1057.033 -271.883)"
          fill="currentColor"
          stroke="currentColor"
          stroke-miterlimit="10"
          stroke-width="0.49"
        />
        <path
          id="_940"
          data-name="940"
          d="M1183.372,259.995c.265.132,1.877,1,2.036,1.137.105.08.211.159.211.317,0,.132-.053.185-.238.291a1.339,1.339,0,0,0-.661.714,46.686,46.686,0,0,1-5.077,7.695c-.4.476-1.878,2.274-2.406,2.274-.45,0-3.279-1.692-3.279-2.353a.683.683,0,0,1,.238-.449,31.163,31.163,0,0,0,6.875-9.837c.132-.291.238-.555.449-.555A8.339,8.339,0,0,1,1183.372,259.995Zm8.329.053a21.881,21.881,0,0,1,1.9,2.327c.82,1.111,4.151,6.161,4.151,7.3,0,.582-1.243,1.216-1.692,1.454a6.11,6.11,0,0,1-1.93.714c-.344,0-.5-.317-.608-.608a41.186,41.186,0,0,0-5-8.62.979.979,0,0,1-.317-.608c0-.4,1-1.084,1.348-1.322a4.047,4.047,0,0,1,1.56-.82A1.162,1.162,0,0,1,1191.7,260.048Zm6.849-1.216a2.763,2.763,0,1,1-2.777-2.776A2.771,2.771,0,0,1,1198.55,258.831Zm-4.178,0a1.411,1.411,0,0,0,1.4,1.4,1.394,1.394,0,0,0,1.4-1.4,1.377,1.377,0,0,0-1.4-1.4A1.411,1.411,0,0,0,1194.372,258.831Z"
          transform="translate(-1119.168 -255.262)"
          fill="currentColor"
          stroke="currentColor"
          stroke-miterlimit="10"
          stroke-width="0.49"
        />
        <path
          id="_941"
          data-name="941"
          d="M1307.651,289.512c1.252,0,2.611-.106,2.8-.106.552,0,.637.291.637,1.058v1.269c0,1.454-.021,1.9-.7,1.9-.828,0-1.8-.079-2.738-.079h-9.764c-.934,0-1.931.079-2.717.079-.722,0-.722-.5-.722-1.9v-1.216c0-.793.064-1.111.616-1.111.3,0,1.486.106,2.823.106Z"
          transform="translate(-1214.247 -281.579)"
          fill="currentColor"
          stroke="currentColor"
          stroke-miterlimit="10"
          stroke-width="0.49"
        />
      </g>
      <path
        id="_942"
        data-name="942"
        d="M974.992,380.308a24.176,24.176,0,0,0,5.8-1.706,2.1,2.1,0,0,1,1-.317c.682,0,2.657,1.877,2.657,2.56,0,.17-.171.317-.293.439a19.473,19.473,0,0,0-2.486,4.144.938.938,0,0,0-.146.439c0,.414.293.488.951.683.537.146.975.317,1.122.317.365,0,.488-.195.561-.536a18.782,18.782,0,0,0,.219-1.974c0-.195-.024-.365-.024-.561a.444.444,0,0,1,.415-.487c.195,0,2.242.414,3.461.658.293.049.585.2.585.366,0,.122-.122.244-.414.512-.122.1-.122.414-.171.658-.1.609-.317,1.584-.488,2.218a1.112,1.112,0,0,0-.073.39.812.812,0,0,0,.561.78c.707.366,1.389.755,2.1,1.146.244.122.536.317.536.634,0,.609-1.8,3.315-2.389,3.315-.171,0-.512-.317-.707-.512a.932.932,0,0,1-.146-.122c-.293-.268-1.146-1.024-1.511-1.024-.268,0-.414.244-.585.536a16.9,16.9,0,0,1-3.412,3.924,6.111,6.111,0,0,1-1.511.975c-.463,0-2.8-1.56-2.8-2.17,0-.244.39-.463.585-.561,2.267-1.194,4.12-3.656,4.12-4.193,0-.439-.439-.585-.926-.755a6.969,6.969,0,0,0-1.463-.414.758.758,0,0,0-.682.487c-1,2.072-2.267,5.095-4.973,5.095a3.5,3.5,0,0,1-3.389-3.705c0-2.755,2.438-4.729,4.924-5.338a10.483,10.483,0,0,1,1.487-.244c.536-.049.658-.439.829-.8a20.025,20.025,0,0,0,.927-2.023.256.256,0,0,0-.268-.268,1.417,1.417,0,0,0-.536.2,9.567,9.567,0,0,1-2.267.853c-.682,0-1.1-.878-1.34-1.414a6.671,6.671,0,0,1-.634-1.852C974.188,380.454,974.334,380.406,974.992,380.308Zm.634,9.287a2.28,2.28,0,0,0-1.609,1.828c0,.22.122.488.366.488.561,0,1.511-1.853,1.511-2.1C975.894,389.669,975.772,389.6,975.626,389.6Z"
        transform="translate(-1082.46 -753.431)"
        fill="currentColor"
        stroke="currentColor"
        stroke-miterlimit="10"
        stroke-width="0.49"
      />
      <path
        id="_943"
        data-name="943"
        d="M1088.751,373.911c.727,0,2.256,2.282,2.256,2.733,0,.3-.251.451-.477.6-.651.476-2.006,1.705-2.633,2.282-.376.351-.6.6-.6.778,0,.3.175.4.577.552a2.432,2.432,0,0,1,1.58,2.608,12.85,12.85,0,0,1-.176,1.956,13.4,13.4,0,0,0-.175,1.354c0,.3.1.5.426.5a7.994,7.994,0,0,0,2.984-1.254,2.278,2.278,0,0,0,.2-.126,3.437,3.437,0,0,1,1.329-.6c.251,0,.3.251.326.451.075.477.175,2.031.175,2.508,0,.878-.1,1.028-.5,1.229a14.652,14.652,0,0,1-5.09,1.379,3.376,3.376,0,0,1-3.486-3.21c0-1.128.4-2.558.4-3.461,0-.251-.1-.5-.4-.5-1.981,0-5.592,5.592-6.269,6.445-.426.552-.677.9-.9.9-.677,0-2.934-1.756-2.934-2.608,0-.276.3-.652,1.279-1.454.627-.527,3.084-3.16,4.213-4.414.978-1.1,1.981-2.182,2.984-3.26.125-.125.326-.326.326-.5a.231.231,0,0,0-.251-.226c-.1,0-1.505.426-1.755.5a16.81,16.81,0,0,1-2.307.527c-.251,0-.451-.1-.7-.5a8.463,8.463,0,0,1-1.229-2.733c0-.376.251-.477.627-.5a45.722,45.722,0,0,0,6.344-1c.226-.05.4-.1.4-.276s-.126-.251-.351-.326c-.953-.326-1.856-.677-2.834-.953-1-.276-1.454-.376-1.454-.827a10.019,10.019,0,0,1,.527-1.831c.376-1.028.426-1.1.727-1.1a2.635,2.635,0,0,1,.853.2c1.128.376,2.282.777,3.436,1.128.928.276,1.329.376,1.329.777a9.771,9.771,0,0,1-.552,1.981.857.857,0,0,0-.076.326.332.332,0,0,0,.326.326.6.6,0,0,0,.226-.05A6.557,6.557,0,0,1,1088.751,373.911Z"
        transform="translate(-1164.758 -746.537)"
        fill="currentColor"
        stroke="currentColor"
        stroke-miterlimit="10"
        stroke-width="0.49"
      />
      <path
        id="_944"
        data-name="944"
        d="M1173.781,409.58a.987.987,0,0,1-.239.527,6.013,6.013,0,0,1-4.812,2.909,7.11,7.11,0,0,1-3.692-1.229,6.368,6.368,0,0,0-3.049-1.254,2.684,2.684,0,0,0-2.192,1.3c-.191.276-.31.426-.452.426s-.286-.1-.5-.3l-1.406-1.279c-.262-.226-.381-.4-.381-.577a1.111,1.111,0,0,1,.238-.527,5.864,5.864,0,0,1,4.788-2.934,7.121,7.121,0,0,1,3.764,1.3,6.01,6.01,0,0,0,2.978,1.2,2.615,2.615,0,0,0,2.216-1.329c.19-.276.31-.426.453-.426.119,0,.262.1.477.3l1.453,1.3C1173.661,409.229,1173.781,409.4,1173.781,409.58Z"
        transform="translate(-1229.225 -775.811)"
        fill="currentColor"
        stroke="currentColor"
        stroke-miterlimit="10"
        stroke-width="0.49"
      />
      <path
        id="_945"
        data-name="945"
        d="M1250.7,377.314c0,.15-.151.276-.276.351a21.28,21.28,0,0,0-3.085,2.533.655.655,0,0,0-.225.451.558.558,0,0,0,.577.527c.075,0,.175-.025.276-.025a8.3,8.3,0,0,1,1.755.3,5.913,5.913,0,0,1,4.388,5.843c0,4.79-4.138,7.172-8.5,7.172-2.357,0-5.542-.953-5.542-3.837a3.67,3.67,0,0,1,3.761-3.485,4.42,4.42,0,0,1,3.385,1.6,4.355,4.355,0,0,1,.678,1.254.7.7,0,0,0,.6.476c.6,0,1.806-1.254,1.806-2.909,0-2.132-1.73-3.084-3.661-3.084a7.16,7.16,0,0,0-4.84,1.931,33.335,33.335,0,0,0-3.085,3.16c-.351.451-.552.627-.652.627-.577,0-2.533-2.056-2.533-2.533,0-.3.251-.527.778-.928.627-.5,4.79-4.639,5.667-5.492.477-.476.853-.928,1.279-1.4.176-.176.351-.326.351-.577,0-.3-.4-.351-.5-.351-.076,0-2.809.5-3.461.6a1.222,1.222,0,0,1-.3.025c-.577,0-.727-2.834-.727-3.31v-.251c0-.376.075-.376.8-.376h.576a25.156,25.156,0,0,0,6.646-.9,3.881,3.881,0,0,1,1.078-.4C1248.795,374.3,1250.7,376.762,1250.7,377.314Zm-6.846,12.614a.721.721,0,0,0-.8.677c0,.677.852,1,1.429,1a.591.591,0,0,0,.627-.552A1.249,1.249,0,0,0,1243.855,389.928Z"
        transform="translate(-1291.147 -750.29)"
        fill="currentColor"
        stroke="currentColor"
        stroke-miterlimit="10"
        stroke-width="0.49"
      />
    </g>
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "MieruLogo",
});
</script>