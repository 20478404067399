import { axiosClient } from "@/plugins/axios";
import { AxiosResponse } from "axios";
import { convertHyphenDelimiter } from "@/commons/utils/dateUtil";
import { WeekHourHeatMapResponse } from "@/features/ShopAnalytics/interfaces/response";
import { DownloadResponse } from "@/commons/interfaces/responses/download";

/**
 * 曜日時間別ヒートマップ取得
 * @param {string} storeId 店舗ID
 * @param {string} start 期間開始日(yyyy-MM-dd)
 * @param {string} end 期間終了日(yyyy-MM-dd)
 */
export function getWeekHourHeatMap(
  storeId: string | undefined,
  start: string,
  end: string
): Promise<AxiosResponse> {
  if (typeof storeId === "undefined") return Promise.reject();
  return axiosClient.get<WeekHourHeatMapResponse>(
    "/stores/chart/week-and-every-hour-heatmap/",
    {
      params: {
        store: storeId,
        start: convertHyphenDelimiter(start),
        end: convertHyphenDelimiter(end),
      },
    }
  );
}

/**
 * 曜日時間別ヒートマップDL用URL生成
 * @param {string} storeId 店舗ID
 * @param {string} start 期間開始日(yyyy-MM-dd)
 * @param {string} end 期間終了日(yyyy-MM-dd)
 */
export function downloadWeekHourHeatMapCsv(
  storeId: string | undefined,
  start: string,
  end: string
): Promise<AxiosResponse> {
  if (typeof storeId === "undefined") return Promise.reject();
  return axiosClient.get<DownloadResponse>(
    "/stores/chart/week-and-every-hour-heatmap/download/",
    {
      params: {
        store: storeId,
        start: convertHyphenDelimiter(start),
        end: convertHyphenDelimiter(end),
      },
    }
  );
}

export function downloadComparisonWeekHourHeatMapCsv(
  storeId: string | undefined,
  period: {
    start: string;
    end: string;
  }[]
): Promise<AxiosResponse> {
  if (typeof storeId === "undefined") return Promise.reject();
  period.forEach((item: { start: string; end: string }) => {
    item.start = convertHyphenDelimiter(item.start);
    item.end = convertHyphenDelimiter(item.end);
  });

  return axiosClient.post<DownloadResponse>(
    "/stores/chart/week-and-every-hour-heatmap-comparison/download/",
    {
      store: storeId,
      period: period,
    }
  );
}
