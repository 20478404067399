<template>
  <v-container
    class="pa-1 mb-1 mt-20px"
    fluid
  >
    <div class="grid-wrap">
      <div class="cols-5">
        <v-container
          class="pa-0 mb-2"
          style="text-align: center"
        >
          <p
            style="font-size: 12px"
            class="mb-0"
          >
            <span
              class="legend"
              style="background-color: #55a07c"
            />{{
              chartData[0][1]
            }}
          </p>
          <p
            style="font-size: 12px"
            class="mb-0"
          >
            <span
              class="legend"
              style="background-color: #a9cfbd"
            />{{
              chartData[0][4]
            }}
          </p>
        </v-container>
        <GChart
          type="BarChart"
          :data="leftChartData"
          :options="
            option({ height: height, position: 'left', max: maxValue + 5 })
          "
          :events="leftEvent"
          @ready="chart => leftChart = chart"
        />
      </div>
      <div class="cols-2">
        <v-container
          class="pa-0 mb-2"
          style="text-align: center"
        >
          <p
            style="font-size: 12px"
            class="mb-0"
          >
            <span
              class="legend"
              style="background-color: #4d99d0"
            />{{
              chartData[0][7]
            }}
          </p>
          <p
            style="font-size: 12px"
            class="mb-0"
          >
            <span
              class="legend"
              style="background-color: #a5cce7"
            />{{
              chartData[0][10]
            }}
          </p>
        </v-container>
        <GChart
          type="BarChart"
          :data="centerChartData"
          :options="
            option({
              height: height,
              position: 'center',
              max: maxValue + 5,
            })
          "
          :events="centerEvent"
          @ready="chart => centerChart = chart"
        />
      </div>
      <div class="cols-2">
        <v-container
          class="pa-0 mb-2"
          style="text-align: center"
        >
          <p
            style="font-size: 12px"
            class="mb-0"
          >
            <span
              class="legend"
              style="background-color: #de5a69"
            />{{
              chartData[0][13]
            }}
          </p>
          <p
            style="font-size: 12px"
            class="mb-0"
          >
            <span
              class="legend"
              style="background-color: #eeacb3"
            />{{
              chartData[0][16]
            }}
          </p>
        </v-container>
        <GChart
          type="BarChart"
          :data="rightChartData"
          :options="
            option({
              height: height,
              position: 'right',
              max: maxValue + 5,
            })
          "
          :events="rightEvent"
          @ready="chart => rightChart = chart"
        />
      </div>
    </div>
  </v-container>
</template>
<script lang="ts">
import { COLOR } from "@/commons/enums";
import { defineComponent, PropType } from "vue";
import { GChart } from "vue-google-charts";

export default defineComponent({
  name: "BehavioralDNACompareChart",
  components: {
    GChart,
  },
  props: {
    chartData: {
      type: Array as PropType<(string | number)[][]>,
      default: () => {
        return [];
      },
    },
    height: {
      type: Number,
      default: () => {
        return 500;
      },
    },
    min: { type: Number, required: true },
    max: { type: Number, required: true },
  },
  emits: ["selectColum"],
  data() {
    return {
      option: (args: {
        height: number;
        position: "left" | "center" | "right";
        max: number;
      }): any => { // vue-google-chartsの型定義がいいかげんなのでanyで
        const { height, position } = args;
        const ticks = [...Array(20)]
          .map((_, i) => i * 5)
          .filter((n) => {
            if (this.min % 5 < 2.5)
              return this.min - 10 < n && n < this.max + 5;
            return this.min - 5 < n && n < this.max + 5;
          });
        return {
          chartArea: {
            top: 10,
            left: position === "left" ? 175 : 20,
            right: 20,
            bottom: 50,
            backgroundColor: {
              fill: (() => {
                if (position === "left") return "#fff";
                if (position === "center") return "#f5f8fd";
                if (position === "right") return "#fff6f7";
              })(),
            },
          },
          colors: (() => {
            if (position === "left") return [COLOR.TOTAL, COLOR.TOTAL2];
            if (position === "center") return [COLOR.BLUE, COLOR.BLUE2];
            if (position === "right") return [COLOR.RED, COLOR.RED2];
          })(),
          hAxis: {
            textStyle: {
              fontSize: 12,
            },
            viewWindow: {
              max: ticks[ticks.length - 1],
              min: ticks[0],
            },
            ticks: ticks,
          },
          height: height,
          legend: { position: "none" },
          tooltip: {
            isHtml: true,
          },
          vAxis: {
            textStyle: {
              fontSize: 10,
            },
            textPosition: args.position !== "left" ? "none" : "out",
          },
          bar: {
            groupWidth: 17,
          },
        };
      },
      // 以下、vue-google-chartsの型定義がいいかげんなのでanyで
      leftChart: null as any,
      centerChart: null as any,
      rightChart: null as any,
      leftEvent: {select: this.leftSelect} as any,
      centerEvent: {select: this.centerSelect} as any,
      rightEvent: {select: this.rightSelect} as any,
    };
  },
  computed: {
    maxValue() {
      return Math.max(
        ...(this.chartData
          .flat()
          .filter(
            (value) => typeof value === "number" && !isNaN(value)
          ) as number[])
      );
    },
    leftChartData(): (string | number)[][] {
      return this.chartData.map((data) => {
        return [
          data[0],
          data[1],
          data[2],
          data[3],
          data[4],
          data[5],
          data[6],
        ].flat();
      });
    },
    centerChartData(): (string | number)[][] {
      return this.chartData.map((data) => {
        return [
          data[0],
          data[7],
          data[8],
          data[9],
          data[10],
          data[11],
          data[12],
        ].flat();
      });
    },
    rightChartData(): (string | number)[][] {
      return this.chartData.map((data) => {
        return [
          data[0],
          data[13],
          data[14],
          data[15],
          data[16],
          data[17],
          data[18],
        ].flat();
      });
    },
  },
  methods: {
    leftSelect() {
      if (this.leftChart?.getSelection().length) {
        this.centerChart?.setSelection([
          this.leftChart?.getSelection()[0],
        ]);
        this.rightChart?.setSelection([
          this.leftChart?.getSelection()[0],
        ]);
        this.$emit(
          "selectColum",
          this.leftChart?.getSelection()[0]
        );
      } else {
        this.centerChart?.setSelection(null);
        this.rightChart?.setSelection(null);
        this.$emit("selectColum", null);
      }
    },
    centerSelect() {
      if (this.centerChart?.getSelection().length) {
        this.leftChart?.setSelection([
          this.centerChart?.getSelection()[0],
        ]);
        this.rightChart?.setSelection([
          this.centerChart?.getSelection()[0],
        ]);
        this.$emit(
          "selectColum",
          this.centerChart?.getSelection()[0]
        );
      } else {
        this.leftChart?.setSelection(null);
        this.rightChart?.setSelection(null);
        this.$emit("selectColum", null);
      }
    },
    rightSelect() {
      if (this.rightChart?.getSelection().length) {
        this.leftChart?.setSelection([
          this.rightChart?.getSelection()[0],
        ]);
        this.centerChart?.setSelection([
          this.rightChart?.getSelection()[0],
        ]);
        this.$emit(
          "selectColum",
          this.rightChart?.getSelection()[0]
        );
      } else {
        this.leftChart?.setSelection(null);
        this.centerChart?.setSelection(null);
        this.$emit("selectColum", null);
      }
    },
  }
});
</script>

<style lang="scss" scoped>
.grid-wrap {
  display: grid;
  grid-template-columns: repeat(9, minmax(0, 1fr));

  .cols-5 {
    grid-column: span 5 / span 5;
  }

  .cols-2 {
    grid-column: span 2 / span 2;
  }
}
.chart_ {
  width: 100%;
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  height: 7px;
  width: 7px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.legend {
  display: inline-block;
  margin-right: 6px;
  width: 24px;
  height: 12px;
}
.mt-20px {
  margin-top: 20px !important;
}
</style>
