import moment from "moment";
import {
  FrequencyResponse,
  EngagementResponse,
  CohortResponse,
} from "../../interfaces/response";
import { visitPatternTableItem } from "../../interfaces/component";

const getTooltipHTML = (
  granularity: string,
  label: string,
  labelTitle: string,
  value: number
) => {
  return `<div style='display: flex; justify-content: center; align-items: center; height: 70px; color: #222222; font-size: 12px !important;'>
            <div style='display:table-cell; vertical-align:middle; text-align: left;'>
              <div>${granularity}</div>
              <div>[${labelTitle}] <b>${label}</b></div>
              <div>[構成比] <b>${
                (Math.round(value * 1000) / 10).toFixed(1) + "%"
              }</b></div>
            </div>
          </div>`;
};
const getEmptyTooltipHTML = (granularity: string) => {
  return `<div style='display: flex; justify-content: center; align-items: center; height: 55px; color: #222222; font-size: 12px !important;'>
            <div style='display:table-cell; vertical-align:middle; text-align: left;'>
              <div>${granularity}</div>
              <div><b>データがありません<b></div>
            </div>
          </div>`;
};
const getSectionColor = (isAlert: boolean) => {
  return isAlert ? "color: #BBBBBB; stroke-width: 0" : "";
};

export function processFrequencyChartData(data: FrequencyResponse) {
  /**
   * '/stores/chart/visit-frequency/', '/stores/chart/engagement/'で受け取った
   * レスポンスデータをフロントのチャートのデータ形式に加工するメソッド
   */
  const processedData: (string | number | any)[][] = [];
  processedData.push([
    "月",
    "月1回",
    { type: "string", role: "tooltip", p: { html: true } },
    { role: "style" },
    "月2回",
    { type: "string", role: "tooltip", p: { html: true } },
    { role: "style" },
    "月3回",
    { type: "string", role: "tooltip", p: { html: true } },
    { role: "style" },
    "月4回以上",
    { type: "string", role: "tooltip", p: { html: true } },
    { role: "style" },
    "月8回以上",
    { type: "string", role: "tooltip", p: { html: true } },
    { role: "style" },
    "参考値（データ不十分）",
    { type: "string", role: "tooltip", p: { html: true } },
    { role: "style" },
  ]);
  data.visitCounts
    .sort((a, b) => {
      return new Date(a.date) > new Date(b.date) ? 1 : -1;
    })
    .forEach((element) => {
      const sum =
        element.oncePerMonth +
        element.twicePerMonth +
        element.threeTimesPerMonth +
        element.fourTimesPerMonth +
        element.eightTimesPerMonth;
      const tmp = [
        moment(element.date).format("YYYY/MM"),
        element.oncePerMonth,
        getTooltipHTML(
          moment(element.date).format("YYYY/MM"),
          "月1回",
          "来店頻度",
          element.oncePerMonth / sum
        ),
        getSectionColor(element.isAlert),
        element.twicePerMonth,
        getTooltipHTML(
          moment(element.date).format("YYYY/MM"),
          "月2回",
          "来店頻度",
          element.twicePerMonth / sum
        ),
        getSectionColor(element.isAlert),
        element.threeTimesPerMonth,
        getTooltipHTML(
          moment(element.date).format("YYYY/MM"),
          "月3回",
          "来店頻度",
          element.threeTimesPerMonth / sum
        ),
        getSectionColor(element.isAlert),
        element.fourTimesPerMonth,
        getTooltipHTML(
          moment(element.date).format("YYYY/MM"),
          "月4回以上",
          "来店頻度",
          element.fourTimesPerMonth / sum
        ),
        getSectionColor(element.isAlert),
        element.eightTimesPerMonth,
        getTooltipHTML(
          moment(element.date).format("YYYY/MM"),
          "月8回以上",
          "来店頻度",
          element.eightTimesPerMonth / sum
        ),
        getSectionColor(element.isAlert),
        NaN,
        getEmptyTooltipHTML(moment(element.date).format("YYYY/MM")),
        "",
      ];
      if (
        element.oncePerMonth === 0 &&
        element.twicePerMonth === 0 &&
        element.threeTimesPerMonth === 0 &&
        element.fourTimesPerMonth === 0 &&
        element.eightTimesPerMonth === 0
      ) {
        tmp[16] = 1;
        tmp[17] = getEmptyTooltipHTML(moment(element.date).format("YYYY/MM"));
      }
      processedData.push(tmp);
    });
  return processedData;
}

export function processEngagementChartData(data: EngagementResponse) {
  /**
   * '/stores/chart/visit-frequency/', '/stores/chart/engagement/'で受け取った
   * レスポンスデータをフロントのチャートのデータ形式に加工するメソッド
   */
  const processedData: (string | number | any)[][] = [];
  processedData.push([
    "月",
    "新規",
    { type: "string", role: "tooltip", p: { html: true } },
    { role: "style" },
    "継続",
    { type: "string", role: "tooltip", p: { html: true } },
    { role: "style" },
    "復活",
    { type: "string", role: "tooltip", p: { html: true } },
    { role: "style" },
    "離反（当月）",
    { type: "string", role: "tooltip", p: { html: true } },
    { role: "style" },
    "離反（先月）",
    { type: "string", role: "tooltip", p: { html: true } },
    { role: "style" },
    "参考値（データ不十分）",
    { type: "string", role: "tooltip", p: { html: true } },
    { role: "style" },
  ]);
  data.visitCounts
    .sort((a, b) => {
      return new Date(a.date) > new Date(b.date) ? 1 : -1;
    })
    .forEach((element) => {
      const sum =
        element.engagementNew +
        element.engagementContinue +
        element.engagementInactive +
        element.engagementLeaveThisMonth +
        element.engagementLeaveLastMonth;

      const tmp = [
        moment(element.date).format("YYYY/MM"),
        element.engagementNew,
        getTooltipHTML(
          moment(element.date).format("YYYY/MM"),
          "新規",
          "エンゲージメント",
          element.engagementNew / sum
        ),
        getSectionColor(element.isAlert),
        element.engagementContinue,
        getTooltipHTML(
          moment(element.date).format("YYYY/MM"),
          "継続",
          "エンゲージメント",
          element.engagementContinue / sum
        ),
        getSectionColor(element.isAlert),
        element.engagementInactive,
        getTooltipHTML(
          moment(element.date).format("YYYY/MM"),
          "復活",
          "エンゲージメント",
          element.engagementInactive / sum
        ),
        getSectionColor(element.isAlert),
        element.engagementLeaveThisMonth,
        getTooltipHTML(
          moment(element.date).format("YYYY/MM"),
          "離反（当月）",
          "エンゲージメント",
          element.engagementLeaveThisMonth / sum
        ),
        getSectionColor(element.isAlert),
        element.engagementLeaveLastMonth,
        getTooltipHTML(
          moment(element.date).format("YYYY/MM"),
          "離反（先月）",
          "エンゲージメント",
          element.engagementLeaveLastMonth / sum
        ),
        getSectionColor(element.isAlert),
        NaN,
        getEmptyTooltipHTML(moment(element.date).format("YYYY/MM")),
        "",
      ];
      if (
        element.engagementNew === 0 &&
        element.engagementContinue === 0 &&
        element.engagementInactive === 0 &&
        element.engagementLeaveThisMonth === 0 &&
        element.engagementLeaveLastMonth === 0
      ) {
        tmp[16] = 1;
        tmp[17] = getEmptyTooltipHTML(moment(element.date).format("YYYY/MM"));
      }
      processedData.push(tmp);
    });
  return processedData;
}

export function processCohortTableData(data: CohortResponse) {
  /**
   * '/stores/chart/cohort_analysis/' で受け取った
   * レスポンスデータをフロントのテーブルのデータ形式に加工するメソッド
   */
  const cohortTableData = [] as visitPatternTableItem[];

  data.visitCounts.forEach((visitCount) => {
    const tmp = {
      granularity: moment(visitCount.yearMonth).format("YYYY年MM月"),
      zero: 100 as number | null,
      one: null as number | null,
      two: null as number | null,
      three: null as number | null,
      four: null as number | null,
      five: null as number | null,
      isAlert: false,
    };

    visitCount.visitorCohort.forEach((visitorCohort) => {
      if (visitorCohort.elapsedMonth === 0) tmp.zero = visitorCohort.visitor;
      if (visitorCohort.elapsedMonth === 1) tmp.one = visitorCohort.visitor;
      if (visitorCohort.elapsedMonth === 2) tmp.two = visitorCohort.visitor;
      if (visitorCohort.elapsedMonth === 3) tmp.three = visitorCohort.visitor;
      if (visitorCohort.elapsedMonth === 4) tmp.four = visitorCohort.visitor;
      if (visitorCohort.elapsedMonth === 5) tmp.five = visitorCohort.visitor;
    });
    tmp.isAlert = visitCount.isAlert;
    cohortTableData.push(tmp);
  });

  return cohortTableData.sort((a, b) => {
    return a.granularity > b.granularity ? 1 : -1;
  });
}
