import { NewsListResponse, NewsResponse } from "@/features/News/types/response";
import { AxiosResponse } from "axios";
import { axiosClient } from "@/plugins/axios";

export function GetNews(): Promise<AxiosResponse<NewsListResponse>> {
  return axiosClient.get<NewsListResponse>("/news/");
}

export function GetNewsFromId(
  newsId: string
): Promise<AxiosResponse<NewsResponse>> {
  return axiosClient.get<NewsResponse>(`/news/${newsId}/`);
}
