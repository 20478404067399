import { Point } from '@/commons/types/Mapbox'
import distance from '@turf/distance'
import { point } from '@turf/helpers'

export default function getDistance(point1: Point, point2: Point): number {
  const from = point([point1.lng, point1.lat])
  const to = point([point2.lng, point2.lat])

  return distance(from, to, { units: 'kilometers' })
}
