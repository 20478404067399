import { axiosClient } from "@/plugins/axios";
import { AxiosResponse } from "axios";
import { DownloadResponse } from "@/commons/interfaces/responses/download";

/**
 * CSVダウンロード
 * @param {string} fileId ダウンロードするファイルID
 */
export function downloadFile(
  fileId: string | undefined
): Promise<AxiosResponse<DownloadResponse>> {
  if (typeof fileId === "undefined") return Promise.reject();
  return axiosClient.get<DownloadResponse>(`/file/download/${fileId}`);
}
