<template>
  <div
    ref="customSelector"
    class="font selector default-border"
    :class="{
      'selector-disabled': disabled,
      'hover-border': hover,
      'active-border': open,
    }"
    @click="open = !open"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <span>{{ selectedItem?.text }}</span>
    <img
      class="triangle-down"
      src="@/assets/svg/triangle-down.svg"
    >
    <transition name="fade">
      <div
        v-show="open"
        class="list"
      >
        <div
          v-for="item in items"
          :key="item.value"
          style="padding: 8px 10px"
          class="list-item"
          :class="{ 'selected-list': modelValue === item.value }"
          @click="selectItem(item)"
        >
          {{ item.text }}
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
type Item = {
  value: number | string;
  text: string;
};
</script>

<script setup lang="ts">
import { ref, computed, onMounted, onBeforeUnmount } from 'vue';

const props = withDefaults(
  defineProps<{
    modelValue: number,
    items: Item[],
    disabled: boolean
  }>(),
  {
    modelValue: undefined,
    items: () => [],
    disabled: false,
  }
)
const emit = defineEmits(['update:modelValue'])

const open = ref<boolean>(false)
const hover = ref<boolean>(false)
const customSelector = ref<HTMLImageElement>()

const selectedItem = computed<Item | undefined>(() => {
  if (props.modelValue === null) return;
  return props.items.find((value) => value.value === props.modelValue);
})

onMounted(() => {
  window.addEventListener("click", close);
});
onBeforeUnmount(() => {
  window.removeEventListener("click", close);
});
function selectItem(item: Item) {
  emit("update:modelValue", item.value);
};
function close(event: any) {
  if (!customSelector.value?.contains(event.target)) {
    open.value = false;
  }
};
</script>

<style scoped>
.font {
  font-size: 12px;
  color: #222222;
}

.selector {
  position: relative;
  display: flex;
  align-items: center;
  padding: 3px 5px 5px 10px;
  min-width: 140px;
  height: 35px;
  border-radius: 4px;
  background: #fff;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}

.default-border {
  outline: 1px solid #ccc;
}

.hover-border {
  outline: 1px solid #999;
}

.active-border {
  outline: 2px solid #999;
}

.selector-disabled {
  background: #e5e5e5;
  border-color: #e5e5e5;
  pointer-events: none;
}

.list {
  position: absolute;
  left: 50%;
  top: 40px;
  transform: translateX(-50%);
  min-width: 144px;
  border: 1px solid #ccc;
  border-radius: 4px;
  z-index: 1;
}

.list-item {
  background: #fff;
  transition: all 0.1s ease-in-out;
}

.list-item:hover {
  background: #f5f5f5;
}

.selected-list {
  background: #eaeff2 !important;
}

.triangle-down {
  margin-top: 3px;
  margin-right: 5px;
  margin-left: auto;
}

/* animation */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
