import {
  AgeGenderResponse,
  ageGender,
  ageGenderChartItem,
  BehavioralDnaResponse,
  CompareBehavioralDnaResponse,
  BehavioralDNA,
  CompareBehavioralDna,
  ThirdCategory,
  CompareThirdCategory,
} from "@/features/ChainAnalytics/persona/interfaces/response";
import { COLOR } from "@/commons/enums";

/**
 * 引数が true か null かを判定
 * @param value 引数
 * @returns true か null か
 */
const isTrueOrNull = (value: boolean | null) => value || value === null;

/**
 * 性別年代別チャートの最大値を取得する
 * @param {AgeGenderResponse} response 性別年代別チャート用データ
 * @returns {Number} 最大値
 */
export function getAgeGenderChartMaxValue(response: AgeGenderResponse) {
  let maxValue = 0;
  response.visitCounts.forEach((data: ageGender) => {
    data.chartItems.forEach((item: ageGenderChartItem) => {
      if (item.value >= maxValue) maxValue = item.value;
    });
  });
  return maxValue;
}

export function processAgeGenderChartData(data: AgeGenderResponse) {
  const tmpArray = [
    [
      "age",
      "男性",
      { type: "string", role: "tooltip", p: { html: true } },
      "女性",
      { type: "string", role: "tooltip", p: { html: true } },
    ],
    ["10代", 0, "", 0, ""],
    ["20代", 0, "", 0, ""],
    ["30代", 0, "", 0, ""],
    ["40代", 0, "", 0, ""],
    ["50代", 0, "", 0, ""],
    ["60代", 0, "", 0, ""],
  ];
  data.visitCounts.forEach((data: ageGender) => {
    switch (data.age) {
      case "teen":
        data.chartItems.forEach((item: ageGenderChartItem) => {
          if (item.gender === "male") {
            tmpArray[1].splice(
              1,
              2,
              item.value,
              `<div style='display: flex; justify-content: center; align-items: center; height: 55px; color: #222222; font-size: 12px !important;'>
                <div style='display:table-cell; vertical-align:middle; text-align: left;'>
                  <div>[性別×年代] <b>男性・10代</b></div>
                  <div>[来店人数] <b>${item.value.toLocaleString()}人</b></div>
                </div>
              </div>`
            );
          } else if (item.gender === "female") {
            tmpArray[1].splice(
              3,
              2,
              item.value,
              `<div style='display: flex; justify-content: center; align-items: center; height: 55px; color: #222222; font-size: 12px !important;'>
                  <div style='display:table-cell; vertical-align:middle; text-align: left;'>
                    <div>[性別×年代] <b>女性・10代</b></div>
                    <div>[来店人数] <b>${item.value.toLocaleString()}人</b></div>
                  </div>
              </div>`
            );
          }
        });
        break;
      case "twenties":
        data.chartItems.forEach((item: ageGenderChartItem) => {
          if (item.gender === "male") {
            tmpArray[2].splice(
              1,
              2,
              item.value,
              `<div style='display: flex; justify-content: center; align-items: center; height: 55px; color: #222222; font-size: 12px !important;'>
                <div style='display:table-cell; vertical-align:middle; text-align: left;'>
                  <div>[性別×年代] <b>男性・20代</b></div>
                  <div>[来店人数] <b>${item.value.toLocaleString()}人</b></div>
                </div>
              </div>`
            );
          } else if (item.gender === "female") {
            tmpArray[2].splice(
              3,
              2,
              item.value,
              `<div style='display: flex; justify-content: center; align-items: center; height: 55px; color: #222222; font-size: 12px !important;'>
                <div style='display:table-cell; vertical-align:middle; text-align: left;'>
                  <div>[性別×年代] <b>女性・20代</b></div>
                  <div>[来店人数] <b>${item.value.toLocaleString()}人</b></div>
                </div>
              </div>`
            );
          }
        });
        break;
      case "thirties":
        data.chartItems.forEach((item: ageGenderChartItem) => {
          if (item.gender === "male") {
            tmpArray[3].splice(
              1,
              2,
              item.value,
              `<div style='display: flex; justify-content: center; align-items: center; height: 55px; color: #222222; font-size: 12px !important;'>
                <div style='display:table-cell; vertical-align:middle; text-align: left;'>
                  <div>[性別×年代] <b>男性・30代</b></div>
                  <div>[来店人数] <b>${item.value.toLocaleString()}人</b></div>
                </div>
              </div>`
            );
          } else if (item.gender === "female") {
            tmpArray[3].splice(
              3,
              2,
              item.value,
              `<div style='display: flex; justify-content: center; align-items: center; height: 55px; color: #222222; font-size: 12px !important;'>
                <div style='display:table-cell; vertical-align:middle; text-align: left;'>
                  <div>[性別×年代] <b>女性・30代</b></div>
                  <div>[来店人数] <b>${item.value.toLocaleString()}人</b></div>
                </div>
              </div>`
            );
          }
        });
        break;
      case "forties":
        data.chartItems.forEach((item: ageGenderChartItem) => {
          if (item.gender === "male") {
            tmpArray[4].splice(
              1,
              2,
              item.value,
              `<div style='display: flex; justify-content: center; align-items: center; height: 55px; color: #222222; font-size: 12px !important;'>
                <div style='display:table-cell; vertical-align:middle; text-align: left;'>
                  <div>[性別×年代] <b>男性・40代</b></div>
                  <div>[来店人数] <b>${item.value.toLocaleString()}人</b></div>
                </div>
              </div>`
            );
          } else if (item.gender === "female") {
            tmpArray[4].splice(
              3,
              2,
              item.value,
              `<div style='display: flex; justify-content: center; align-items: center; height: 55px; color: #222222; font-size: 12px !important;'>
                <div style='display:table-cell; vertical-align:middle; text-align: left;'>
                  <div>[性別×年代] <b>女性・40代</b></div>
                  <div>[来店人数] <b>${item.value.toLocaleString()}人</b></div>
                </div>
              </div>`
            );
          }
        });
        break;
      case "fifties":
        data.chartItems.forEach((item: ageGenderChartItem) => {
          if (item.gender === "male") {
            tmpArray[5].splice(
              1,
              2,
              item.value,
              `<div style='display: flex; justify-content: center; align-items: center; height: 55px; color: #222222; font-size: 12px !important;'>
                <div style='display:table-cell; vertical-align:middle; text-align: left;'>
                  <div>[性別×年代] <b>男性・50代</b></div>
                  <div>[来店人数] <b>${item.value.toLocaleString()}人</b></div>
                </div>
              </div>`
            );
          } else if (item.gender === "female") {
            tmpArray[5].splice(
              3,
              2,
              item.value,
              `<div style='display: flex; justify-content: center; align-items: center; height: 55px; color: #222222; font-size: 12px !important;'>
                <div style='display:table-cell; vertical-align:middle; text-align: left;'>
                  <div>[性別×年代] <b>女性・50代</b></div>
                  <div>[来店人数] <b>${item.value}人</b></div>
                </div>
              </div>`
            );
          }
        });
        break;
      case "sixties":
        data.chartItems.forEach((item: ageGenderChartItem) => {
          if (item.gender === "male") {
            tmpArray[6].splice(
              1,
              2,
              item.value,
              `<div style='display: flex; justify-content: center; align-items: center; height: 55px; color: #222222; font-size: 12px !important;'>
                <div style='display:table-cell; vertical-align:middle; text-align: left;'>
                  <div>[性別×年代] <b>男性・60代</b></div>
                  <div>[来店人数] <b>${item.value.toLocaleString()}人</b></div>
                </div>
              </div>`
            );
          } else if (item.gender === "female") {
            tmpArray[6].splice(
              3,
              2,
              item.value,
              `<div style='display: flex; justify-content: center; align-items: center; height: 55px; color: #222222; font-size: 12px !important;'>
                <div style='display:table-cell; vertical-align:middle; text-align: left;'>
                  <div>[性別×年代] <b>女性・60代</b></div>
                  <div>[来店人数] <b>${item.value.toLocaleString()}人</b></div>
                </div>
              </div>`
            );
          }
        });
        break;
    }
  });
  return tmpArray;
}

export function processBehavioralDnaChartData(response: BehavioralDnaResponse) {
  type GetTooltipElement = {
    category: string;
    score: string;
    type: "total" | "male" | "female";
  };
  const getTooltipElement = ({ category, score, type }: GetTooltipElement) => {
    const typeDisplay = (() => {
      if (type === "total") return "合計";
      if (type === "male") return "男性";
      if (type === "female") return "女性";
    })();
    return `
      <div style='display: flex; justify-content: center; align-items: center; height: 60px; color: #222222; font-size: 12px !important;'>
        <div style='display:table-cell; vertical-align:middle; text-align: left;'>
          <div>[カテゴリ] <b>${category}</b></div>
          <div>[${typeDisplay}スコア] <b>${score}</b></div>
        </div>
      </div>
    `;
  };
  const tmpArray: (
    | string
    | number
    | { type: string; role: string; p: { html: boolean } }
    | { role: string }
  )[][] = response.visitCounts.map((item: BehavioralDNA) => {
    return [
      item.firstCategory.name + " › " + item.secondCategory.name,
      item.secondCategory.deviationValue.total ?? NaN,
      getTooltipElement({
        category: item.firstCategory.name + " › " + item.secondCategory.name,
        score: String(item.secondCategory.deviationValue.total ?? "--"),
        type: "total",
      }),
      isTrueOrNull(item.secondCategory.deviationValue.totalAlert) ? "#bbb" : "",
      item.secondCategory.deviationValue.male ?? NaN,
      getTooltipElement({
        category: item.firstCategory.name + " › " + item.secondCategory.name,
        score: String(item.secondCategory.deviationValue.male ?? "--"),
        type: "male",
      }),
      isTrueOrNull(item.secondCategory.deviationValue.maleAlert) ? "#bbb" : "",
      item.secondCategory.deviationValue.female ?? NaN,
      getTooltipElement({
        category: item.firstCategory.name + " › " + item.secondCategory.name,
        score: String(item.secondCategory.deviationValue.female ?? "--"),
        type: "female",
      }),
      item.secondCategory.deviationValue.femaleAlert ||
      item.secondCategory.deviationValue.femaleAlert === null
        ? "#bbb"
        : "",
    ];
  });
  tmpArray.unshift([
    "categoryName",
    "全体",
    { type: "string", role: "tooltip", p: { html: true } },
    { role: "style" },
    "男性",
    { type: "string", role: "tooltip", p: { html: true } },
    { role: "style" },
    "女性",
    { type: "string", role: "tooltip", p: { html: true } },
    { role: "style" },
  ]);
  return tmpArray;
}
export function processBehavioralDnaThirdCategoryData(
  response: BehavioralDnaResponse | CompareBehavioralDnaResponse
) {
  return response.visitCounts.map(
    (item: BehavioralDNA | CompareBehavioralDna) => {
      return {
        firstCategoryName: item.firstCategory.name,
        secondCategoryName: item.secondCategory.name,
        thirdCategory: item.thirdCategory.sort((a, b) => {
          if (a.name !== b.name) {
            if (a.name > b.name) return -1;
            if (a.name < b.name) return 1;
          }
          return 0;
        }),
      };
    }
  );
}

export function processAgeGenderCompareChartData(
  baseStartDate: string,
  baseEndDate: string,
  compareStartDate: string,
  compareEndDate: string,
  baseResponse: AgeGenderResponse,
  compareResponse: AgeGenderResponse
) {
  const tmpArray = [
    [
      "gender",
      "男性: " + baseStartDate + " - " + baseEndDate,
      { type: "string", role: "tooltip", p: { html: true } },
      "男性: " + compareStartDate + " - " + compareEndDate,
      { type: "string", role: "tooltip", p: { html: true } },
      "女性: " + baseStartDate + " - " + baseEndDate,
      { type: "string", role: "tooltip", p: { html: true } },
      "女性: " + compareStartDate + " - " + compareEndDate,
      { type: "string", role: "tooltip", p: { html: true } },
    ],
    ["10代", 0, "", 0, "", 0, "", 0, ""],
    ["20代", 0, "", 0, "", 0, "", 0, ""],
    ["30代", 0, "", 0, "", 0, "", 0, ""],
    ["40代", 0, "", 0, "", 0, "", 0, ""],
    ["50代", 0, "", 0, "", 0, "", 0, ""],
    ["60代", 0, "", 0, "", 0, "", 0, ""],
  ];
  // 基準日と比較日付のデータを加工
  baseResponse.visitCounts.forEach((data: ageGender, index) => {
    const cData = compareResponse.visitCounts[index];
    switch (data.age) {
      case "teen":
        data.chartItems.forEach((item: ageGenderChartItem, index) => {
          const cItem = cData.chartItems[index];
          if (item.gender === "male") {
            tmpArray[1].splice(
              1,
              2,
              item.value,
              `<div style='display: flex; justify-content: center; align-items: center; height: 150px; min-width: 150px; color: #222222; font-size: 12px !important;'>
                <div style='display:table-cell; vertical-align:middle; text-align: left;'>
                  <div style='text-align: left'>＜<span style="color: ${
                    COLOR.BLUE
                  }">● </span>${baseStartDate} - ${baseEndDate}＞</div>
                  <div style='text-align: left'><div>[性別×年代] <b>男性・10代</b></div>
                  <div style='margin-bottom: 10px'>[来店人数] <b>${item.value.toLocaleString()}人</b></div>
                  <div style='text-align: left'>＜<span style="color: ${
                    COLOR.BLUE2
                  }">● </span>${compareStartDate} - ${compareEndDate}＞</div>
                  <div style='text-align: left'><div>[性別×年代] <b>男性・10代</b></div>
                  <div>[来店人数] <b>${cItem.value.toLocaleString()}人</b></div>
                </div>
              </div>`
            );
            tmpArray[1].splice(
              3,
              2,
              cItem.value,
              `<div style='display: flex; justify-content: center; align-items: center; height: 150px; min-width: 150px; color: #222222; font-size: 12px !important;'>
                  <div style='display:table-cell; vertical-align:middle; text-align: left;'>
                    <div style='text-align: left'>＜<span style="color: ${
                      COLOR.BLUE
                    }">● </span>${baseStartDate} - ${baseEndDate}＞</div>
                    <div style='text-align: left'><div>[性別×年代] <b>男性・10代</b></div>
                    <div style='margin-bottom: 10px'>[来店人数] <b>${item.value.toLocaleString()}人</b></div>
                    <div style='text-align: left'>＜<span style="color: ${
                      COLOR.BLUE2
                    }">● </span>${compareStartDate} - ${compareEndDate}＞</div>
                    <div style='text-align: left'><div>[性別×年代] <b>男性・10代</b></div>
                    <div>[来店人数] <b>${cItem.value.toLocaleString()}人</b></div>
                  </div>
                </div>`
            );
          } else if (item.gender === "female") {
            tmpArray[1].splice(
              5,
              2,
              item.value,
              `<div style='display: flex; justify-content: center; align-items: center; height: 150px; min-width: 150px; color: #222222; font-size: 12px !important;'>
                 <div style='display:table-cell; vertical-align:middle; text-align: left;'>
                   <div style='text-align: left'>＜<span style="color: ${
                     COLOR.RED
                   }">● </span>${baseStartDate} - ${baseEndDate}＞</div>
                   <div style='text-align: left'><div>[性別×年代] <b>女性・10代</b></div>
                   <div style='margin-bottom: 10px'>[来店人数] <b>${item.value.toLocaleString()}人</b></div>
                   <div style='text-align: left'>＜<span style="color: ${
                     COLOR.RED2
                   }">● </span>${compareStartDate} - ${compareEndDate}＞</div>
                   <div style='text-align: left'><div>[性別×年代] <b>女性・10代</b></div>
                   <div>[来店人数] <b>${cItem.value.toLocaleString()}人</b></div>
                 </div>
               </div>`
            );
            tmpArray[1].splice(
              7,
              2,
              cItem.value,
              `<div style='display: flex; justify-content: center; align-items: center; height: 150px; min-width: 150px; color: #222222; font-size: 12px !important;'>
                 <div style='display:table-cell; vertical-align:middle; text-align: left;'>
                   <div style='text-align: left'>＜<span style="color: ${
                     COLOR.RED
                   }">● </span>${baseStartDate} - ${baseEndDate}＞</div>
                   <div style='text-align: left'><div>[性別×年代] <b>女性・10代</b></div>
                   <div style='margin-bottom: 10px'>[来店人数] <b>${item.value.toLocaleString()}人</b></div>
                   <div style='text-align: left'>＜<span style="color: ${
                     COLOR.RED2
                   }">● </span>${compareStartDate} - ${compareEndDate}＞</div>
                   <div style='text-align: left'><div>[性別×年代] <b>女性・10代</b></div>
                   <div>[来店人数] <b>${cItem.value.toLocaleString()}人</b></div>
                 </div>
               </div>`
            );
          }
        });
        break;
      case "twenties":
        data.chartItems.forEach((item: ageGenderChartItem, index) => {
          const cItem = cData.chartItems[index];
          if (item.gender === "male") {
            tmpArray[2].splice(
              1,
              2,
              item.value,
              `<div style='display: flex; justify-content: center; align-items: center; height: 150px; min-width: 150px; color: #222222; font-size: 12px !important;'>
                 <div style='display:table-cell; vertical-align:middle; text-align: left;'>
                   <div style='text-align: left'>＜<span style="color: ${
                     COLOR.BLUE
                   }">● </span>${baseStartDate} - ${baseEndDate}＞</div>
                   <div style='text-align: left'><div>[性別×年代] <b>男性・20代</b></div>
                   <div style='margin-bottom: 10px'>[来店人数] <b>${item.value.toLocaleString()}人</b></div>
                   <div style='text-align: left'>＜<span style="color: ${
                     COLOR.BLUE2
                   }">● </span>${compareStartDate} - ${compareEndDate}＞</div>
                   <div style='text-align: left'><div>[性別×年代] <b>男性・20代</b></div>
                   <div>[来店人数] <b>${cItem.value.toLocaleString()}人</b></div>
                 </div>
               </div>`
            );
            tmpArray[2].splice(
              3,
              2,
              cItem.value,
              `<div style='display: flex; justify-content: center; align-items: center; height: 150px; min-width: 150px; color: #222222; font-size: 12px !important;'>
                 <div style='display:table-cell; vertical-align:middle; text-align: left;'>
                   <div style='text-align: left'>＜<span style="color: ${
                     COLOR.BLUE
                   }">● </span>${baseStartDate} - ${baseEndDate}＞</div>
                   <div style='text-align: left'><div>[性別×年代] <b>男性・20代</b></div>
                   <div style='margin-bottom: 10px'>[来店人数] <b>${item.value.toLocaleString()}人</b></div>
                   <div style='text-align: left'>＜<span style="color: ${
                     COLOR.BLUE2
                   }">● </span>${compareStartDate} - ${compareEndDate}＞</div>
                   <div style='text-align: left'><div>[性別×年代] <b>男性・20代</b></div>
                   <div>[来店人数] <b>${cItem.value.toLocaleString()}人</b></div>
                 </div>
               </div>`
            );
          } else if (item.gender === "female") {
            tmpArray[2].splice(
              5,
              2,
              item.value,
              `<div style='display: flex; justify-content: center; align-items: center; height: 150px; min-width: 150px; color: #222222; font-size: 12px !important;'>
                 <div style='display:table-cell; vertical-align:middle; text-align: left;'>
                   <div style='text-align: left'>＜<span style="color: ${
                     COLOR.RED
                   }">● </span>${baseStartDate} - ${baseEndDate}＞</div>
                   <div style='text-align: left'><div>[性別×年代] <b>女性・20代</b></div>
                   <div style='margin-bottom: 10px'>[来店人数] <b>${item.value.toLocaleString()}人</b></div>
                   <div style='text-align: left'>＜<span style="color: ${
                     COLOR.RED2
                   }">● </span>${compareStartDate} - ${compareEndDate}＞</div>
                   <div style='text-align: left'><div>[性別×年代] <b>女性・20代</b></div>
                   <div>[来店人数] <b>${cItem.value.toLocaleString()}人</b></div>
                 </div>
               </div>`
            );
            tmpArray[2].splice(
              7,
              2,
              cItem.value,
              `<div style='display: flex; justify-content: center; align-items: center; height: 150px; min-width: 150px; color: #222222; font-size: 12px !important;'>
                 <div style='display:table-cell; vertical-align:middle; text-align: left;'>
                   <div style='text-align: left'>＜<span style="color: ${
                     COLOR.RED
                   }">● </span>${baseStartDate} - ${baseEndDate}＞</div>
                   <div style='text-align: left'><div>[性別×年代] <b>女性・20代</b></div>
                   <div style='margin-bottom: 10px'>[来店人数] <b>${item.value.toLocaleString()}人</b></div>
                   <div style='text-align: left'>＜<span style="color: ${
                     COLOR.RED2
                   }">● </span>${compareStartDate} - ${compareEndDate}＞</div>
                   <div style='text-align: left'><div>[性別×年代] <b>女性・20代</b></div>
                   <div>[来店人数] <b>${cItem.value.toLocaleString()}人</b></div>
                 </div>
               </div>`
            );
          }
        });
        break;
      case "thirties":
        data.chartItems.forEach((item: ageGenderChartItem, index) => {
          const cItem = cData.chartItems[index];
          if (item.gender === "male") {
            tmpArray[3].splice(
              1,
              2,
              item.value,
              `<div style='display: flex; justify-content: center; align-items: center; height: 150px; min-width: 150px; color: #222222; font-size: 12px !important;'>
                 <div style='display:table-cell; vertical-align:middle; text-align: left;'>
                   <div style='text-align: left'>＜<span style="color: ${
                     COLOR.BLUE
                   }">● </span>${baseStartDate} - ${baseEndDate}＞</div>
                   <div style='text-align: left'><div>[性別×年代] <b>男性・30代</b></div>
                   <div style='margin-bottom: 10px'>[来店人数] <b>${item.value.toLocaleString()}人</b></div>
                   <div style='text-align: left'>＜<span style="color: ${
                     COLOR.BLUE2
                   }">● </span>${compareStartDate} - ${compareEndDate}＞</div>
                   <div style='text-align: left'><div>[性別×年代] <b>男性・30代</b></div>
                   <div>[来店人数] <b>${cItem.value.toLocaleString()}人</b></div>
                 </div>
               </div>`
            );
            tmpArray[3].splice(
              3,
              2,
              cItem.value,
              `<div style='display: flex; justify-content: center; align-items: center; height: 150px; min-width: 150px; color: #222222; font-size: 12px !important;'>
                 <div style='display:table-cell; vertical-align:middle; text-align: left;'>
                   <div style='text-align: left'>＜<span style="color: ${
                     COLOR.BLUE
                   }">● </span>${baseStartDate} - ${baseEndDate}＞</div>
                   <div style='text-align: left'><div>[性別×年代] <b>男性・30代</b></div>
                   <div style='margin-bottom: 10px'>[来店人数] <b>${item.value.toLocaleString()}人</b></div>
                   <div style='text-align: left'>＜<span style="color: ${
                     COLOR.BLUE2
                   }">● </span>${compareStartDate} - ${compareEndDate}＞</div>
                   <div style='text-align: left'><div>[性別×年代] <b>男性・30代</b></div>
                   <div>[来店人数] <b>${cItem.value.toLocaleString()}人</b></div>
                 </div>
               </div>`
            );
          } else if (item.gender === "female") {
            tmpArray[3].splice(
              5,
              2,
              item.value,
              `<div style='display: flex; justify-content: center; align-items: center; height: 150px; min-width: 150px; color: #222222; font-size: 12px !important;'>
                 <div style='display:table-cell; vertical-align:middle; text-align: left;'>
                   <div style='text-align: left'>＜<span style="color: ${
                     COLOR.RED
                   }">● </span>${baseStartDate} - ${baseEndDate}＞</div>
                   <div style='text-align: left'><div>[性別×年代] <b>女性・30代</b></div>
                   <div style='margin-bottom: 10px'>[来店人数] <b>${item.value.toLocaleString()}人</b></div>
                   <div style='text-align: left'>＜<span style="color: ${
                     COLOR.RED2
                   }">● </span>${compareStartDate} - ${compareEndDate}＞</div>
                   <div style='text-align: left'><div>[性別×年代] <b>女性・30代</b></div>
                   <div>[来店人数] <b>${cItem.value.toLocaleString()}人</b></div>
                 </div>
               </div>`
            );
            tmpArray[3].splice(
              7,
              2,
              cItem.value,
              `<div style='display: flex; justify-content: center; align-items: center; height: 150px; min-width: 150px; color: #222222; font-size: 12px !important;'>
                 <div style='display:table-cell; vertical-align:middle; text-align: left;'>
                   <div style='text-align: left'>＜<span style="color: ${
                     COLOR.RED
                   }">● </span>${baseStartDate} - ${baseEndDate}＞</div>
                   <div style='text-align: left'><div>[性別×年代] <b>女性・30代</b></div>
                   <div style='margin-bottom: 10px'>[来店人数] <b>${item.value.toLocaleString()}人</b></div>
                   <div style='text-align: left'>＜<span style="color: ${
                     COLOR.RED2
                   }">● </span>${compareStartDate} - ${compareEndDate}＞</div>
                   <div style='text-align: left'><div>[性別×年代] <b>女性・30代</b></div>
                   <div>[来店人数] <b>${cItem.value.toLocaleString()}人</b></div>
                 </div>
               </div>`
            );
          }
        });
        break;
      case "forties":
        data.chartItems.forEach((item: ageGenderChartItem, index) => {
          const cItem = cData.chartItems[index];
          if (item.gender === "male") {
            tmpArray[4].splice(
              1,
              2,
              item.value,
              `<div style='display: flex; justify-content: center; align-items: center; height: 150px; min-width: 150px; color: #222222; font-size: 12px !important;'>
                  <div style='display:table-cell; vertical-align:middle; text-align: left;'>
                    <div style='text-align: left'>＜<span style="color: ${
                      COLOR.BLUE
                    }">● </span>${baseStartDate} - ${baseEndDate}＞</div>
                    <div style='text-align: left'><div>[性別×年代] <b>男性・40代</b></div>
                    <div style='margin-bottom: 10px'>[来店人数] <b>${item.value.toLocaleString()}人</b></div>
                    <div style='text-align: left'>＜<span style="color: ${
                      COLOR.BLUE2
                    }">● </span>${compareStartDate} - ${compareEndDate}＞</div>
                    <div style='text-align: left'><div>[性別×年代] <b>男性・40代</b></div>
                    <div>[来店人数] <b>${cItem.value.toLocaleString()}人</b></div>
                  </div>
                </div>`
            );
            tmpArray[4].splice(
              3,
              2,
              cItem.value,
              `<div style='display: flex; justify-content: center; align-items: center; height: 150px; min-width: 150px; color: #222222; font-size: 12px !important;'>
                  <div style='display:table-cell; vertical-align:middle; text-align: left;'>
                    <div style='text-align: left'>＜<span style="color: ${
                      COLOR.BLUE
                    }">● </span>${baseStartDate} - ${baseEndDate}＞</div>
                    <div style='text-align: left'><div>[性別×年代] <b>男性・40代</b></div>
                    <div style='margin-bottom: 10px'>[来店人数] <b>${item.value.toLocaleString()}人</b></div>
                    <div style='text-align: left'>＜<span style="color: ${
                      COLOR.BLUE2
                    }">● </span>${compareStartDate} - ${compareEndDate}＞</div>
                    <div style='text-align: left'><div>[性別×年代] <b>男性・40代</b></div>
                    <div>[来店人数] <b>${cItem.value.toLocaleString()}人</b></div>
                  </div>
                </div>`
            );
          } else if (item.gender === "female") {
            tmpArray[4].splice(
              5,
              2,
              item.value,
              `<div style='display: flex; justify-content: center; align-items: center; height: 150px; min-width: 150px; color: #222222; font-size: 12px !important;'>
                  <div style='display:table-cell; vertical-align:middle; text-align: left;'>
                    <div style='text-align: left'>＜<span style="color: ${
                      COLOR.RED
                    }">● </span>${baseStartDate} - ${baseEndDate}＞</div>
                    <div style='text-align: left'><div>[性別×年代] <b>女性・40代</b></div>
                    <div style='margin-bottom: 10px'>[来店人数] <b>${item.value.toLocaleString()}人</b></div>
                    <div style='text-align: left'>＜<span style="color: ${
                      COLOR.RED2
                    }">● </span>${compareStartDate} - ${compareEndDate}＞</div>
                    <div style='text-align: left'><div>[性別×年代] <b>女性・40代</b></div>
                    <div>[来店人数] <b>${cItem.value.toLocaleString()}人</b></div>
                  </div>
                </div>`
            );
            tmpArray[4].splice(
              7,
              2,
              cItem.value,
              `<div style='display: flex; justify-content: center; align-items: center; height: 150px; min-width: 150px; color: #222222; font-size: 12px !important;'>
                  <div style='display:table-cell; vertical-align:middle; text-align: left;'>
                    <div style='text-align: left'>＜<span style="color: ${
                      COLOR.RED
                    }">● </span>${baseStartDate} - ${baseEndDate}＞</div>
                    <div style='text-align: left'><div>[性別×年代] <b>女性・40代</b></div>
                    <div style='margin-bottom: 10px'>[来店人数] <b>${item.value.toLocaleString()}人</b></div>
                    <div style='text-align: left'>＜<span style="color: ${
                      COLOR.RED2
                    }">● </span>${compareStartDate} - ${compareEndDate}＞</div>
                    <div style='text-align: left'><div>[性別×年代] <b>女性・40代</b></div>
                    <div>[来店人数] <b>${cItem.value.toLocaleString()}人</b></div>
                  </div>
                </div>`
            );
          }
        });
        break;
      case "fifties":
        data.chartItems.forEach((item: ageGenderChartItem, index) => {
          const cItem = cData.chartItems[index];
          if (item.gender === "male") {
            tmpArray[5].splice(
              1,
              2,
              item.value,
              `<div style='display: flex; justify-content: center; align-items: center; height: 150px; min-width: 150px; color: #222222; font-size: 12px !important;'>
                  <div style='display:table-cell; vertical-align:middle; text-align: left;'>
                    <div style='text-align: left'>＜<span style="color: ${
                      COLOR.BLUE
                    }">● </span>${baseStartDate} - ${baseEndDate}＞</div>
                    <div style='text-align: left'><div>[性別×年代] <b>男性・50代</b></div>
                    <div style='margin-bottom: 10px'>[来店人数] <b>${item.value.toLocaleString()}人</b></div>
                    <div style='text-align: left'>＜<span style="color: ${
                      COLOR.BLUE2
                    }">● </span>${compareStartDate} - ${compareEndDate}＞</div>
                    <div style='text-align: left'><div>[性別×年代] <b>男性・50代</b></div>
                    <div>[来店人数] <b>${cItem.value.toLocaleString()}人</b></div>
                  </div>
                </div>`
            );
            tmpArray[5].splice(
              3,
              2,
              cItem.value,
              `<div style='display: flex; justify-content: center; align-items: center; height: 150px; min-width: 150px; color: #222222; font-size: 12px !important;'>
                  <div style='display:table-cell; vertical-align:middle; text-align: left;'>
                    <div style='text-align: left'>＜<span style="color: ${
                      COLOR.BLUE
                    }">● </span>${baseStartDate} - ${baseEndDate}＞</div>
                    <div style='text-align: left'><div>[性別×年代] <b>男性・50代</b></div>
                    <div style='margin-bottom: 10px'>[来店人数] <b>${item.value.toLocaleString()}人</b></div>
                    <div style='text-align: left'>＜<span style="color: ${
                      COLOR.BLUE2
                    }">● </span>${compareStartDate} - ${compareEndDate}＞</div>
                    <div style='text-align: left'><div>[性別×年代] <b>男性・50代</b></div>
                    <div>[来店人数] <b>${cItem.value.toLocaleString()}人</b></div>
                  </div>
                </div>`
            );
          } else if (item.gender === "female") {
            tmpArray[5].splice(
              5,
              2,
              item.value,
              `<div style='display: flex; justify-content: center; align-items: center; height: 150px; min-width: 150px; color: #222222; font-size: 12px !important;'>
                  <div style='display:table-cell; vertical-align:middle; text-align: left;'>
                    <div style='text-align: left'>＜<span style="color: ${
                      COLOR.RED
                    }">● </span>${baseStartDate} - ${baseEndDate}＞</div>
                    <div style='text-align: left'><div>[性別×年代] <b>女性・50代</b></div>
                    <div style='margin-bottom: 10px'>[来店人数] <b>${item.value.toLocaleString()}人</b></div>
                    <div style='text-align: left'>＜<span style="color: ${
                      COLOR.RED2
                    }">● </span>${compareStartDate} - ${compareEndDate}＞</div>
                    <div style='text-align: left'><div>[性別×年代] <b>女性・50代</b></div>
                    <div>[来店人数] <b>${cItem.value.toLocaleString()}人</b></div>
                  </div>
                </div>`
            );
            tmpArray[5].splice(
              7,
              2,
              cItem.value,
              `<div style='display: flex; justify-content: center; align-items: center; height: 150px; min-width: 150px; color: #222222; font-size: 12px !important;'>
                  <div style='display:table-cell; vertical-align:middle; text-align: left;'>
                    <div style='text-align: left'>＜<span style="color: ${
                      COLOR.RED
                    }">● </span>${baseStartDate} - ${baseEndDate}＞</div>
                    <div style='text-align: left'><div>[性別×年代] <b>女性・50代</b></div>
                    <div style='margin-bottom: 10px'>[来店人数] <b>${item.value.toLocaleString()}人</b></div>
                    <div style='text-align: left'>＜<span style="color: ${
                      COLOR.RED2
                    }">● </span>${compareStartDate} - ${compareEndDate}＞</div>
                    <div style='text-align: left'><div>[性別×年代] <b>女性・50代</b></div>
                    <div>[来店人数] <b>${cItem.value.toLocaleString()}人</b></div>
                  </div>
                </div>`
            );
          }
        });
        break;
      case "sixties":
        data.chartItems.forEach((item: ageGenderChartItem, index) => {
          const cItem = cData.chartItems[index];
          if (item.gender === "male") {
            tmpArray[6].splice(
              1,
              2,
              item.value,
              `<div style='display: flex; justify-content: center; align-items: center; height: 150px; min-width: 150px; color: #222222; font-size: 12px !important;'>
                  <div style='display:table-cell; vertical-align:middle; text-align: left;'>
                    <div style='text-align: left'>＜<span style="color: ${
                      COLOR.BLUE
                    }">● </span>${baseStartDate} - ${baseEndDate}＞</div>
                    <div style='text-align: left'><div>[性別×年代] <b>男性・60代</b></div>
                    <div style='margin-bottom: 10px'>[来店人数] <b>${item.value.toLocaleString()}人</b></div>
                    <div style='text-align: left'>＜<span style="color: ${
                      COLOR.BLUE2
                    }">● </span>${compareStartDate} - ${compareEndDate}＞</div>
                    <div style='text-align: left'><div>[性別×年代] <b>男性・60代</b></div>
                    <div>[来店人数] <b>${cItem.value.toLocaleString()}人</b></div>
                  </div>
                </div>`
            );
            tmpArray[6].splice(
              3,
              2,
              cItem.value,
              `<div style='display: flex; justify-content: center; align-items: center; height: 150px; min-width: 150px; color: #222222; font-size: 12px !important;'>
                  <div style='display:table-cell; vertical-align:middle; text-align: left;'>
                    <div style='text-align: left'>＜<span style="color: ${
                      COLOR.BLUE
                    }">● </span>${baseStartDate} - ${baseEndDate}＞</div>
                    <div style='text-align: left'><div>[性別×年代] <b>男性・60代</b></div>
                    <div style='margin-bottom: 10px'>[来店人数] <b>${item.value.toLocaleString()}人</b></div>
                    <div style='text-align: left'>＜<span style="color: ${
                      COLOR.BLUE2
                    }">● </span>${compareStartDate} - ${compareEndDate}＞</div>
                    <div style='text-align: left'><div>[性別×年代] <b>男性・60代</b></div>
                    <div>[来店人数] <b>${cItem.value.toLocaleString()}人</b></div>
                  </div>
                </div>`
            );
          } else if (item.gender === "female") {
            tmpArray[6].splice(
              5,
              2,
              item.value,
              `<div style='display: flex; justify-content: center; align-items: center; height: 150px; min-width: 150px; color: #222222; font-size: 12px !important;'>
                  <div style='display:table-cell; vertical-align:middle; text-align: left;'>
                    <div style='text-align: left'>＜<span style="color: ${
                      COLOR.RED
                    }">● </span>${baseStartDate} - ${baseEndDate}＞</div>
                    <div style='text-align: left'><div>[性別×年代] <b>女性・60代</b></div>
                    <div style='margin-bottom: 10px'>[来店人数] <b>${item.value.toLocaleString()}人</b></div>
                    <div style='text-align: left'>＜<span style="color: ${
                      COLOR.RED2
                    }">● </span>${compareStartDate} - ${compareEndDate}＞</div>
                    <div style='text-align: left'><div>[性別×年代] <b>女性・60代</b></div>
                    <div>[来店人数] <b>${cItem.value.toLocaleString()}人</b></div>
                  </div>
                </div>`
            );
            tmpArray[6].splice(
              7,
              2,
              cItem.value,
              `<div style='display: flex; justify-content: center; align-items: center; height: 150px; min-width: 150px; color: #222222; font-size: 12px !important;'>
                  <div style='display:table-cell; vertical-align:middle; text-align: left;'>
                    <div style='text-align: left'>＜<span style="color: ${
                      COLOR.RED
                    }">● </span>${baseStartDate} - ${baseEndDate}＞</div>
                    <div style='text-align: left'><div>[性別×年代] <b>女性・60代</b></div>
                    <div style='margin-bottom: 10px'>[来店人数] <b>${item.value.toLocaleString()}人</b></div>
                    <div style='text-align: left'>＜<span style="color: ${
                      COLOR.RED2
                    }">● </span>${compareStartDate} - ${compareEndDate}＞</div>
                    <div style='text-align: left'><div>[性別×年代] <b>女性・60代</b></div>
                    <div>[来店人数] <b>${cItem.value.toLocaleString()}人</b></div>
                  </div>
                </div>`
            );
          }
        });
        break;
    }
  });

  return tmpArray;
}

export function getAgeGenderCompareChartMaxValue(
  baseResponse: AgeGenderResponse,
  compareResponse: AgeGenderResponse
) {
  let maxValue = 0;
  baseResponse.visitCounts.forEach((data: ageGender) => {
    data.chartItems.forEach((item: ageGenderChartItem) => {
      if (item.value >= maxValue) maxValue = item.value;
    });
  });
  compareResponse.visitCounts.forEach((data: ageGender) => {
    data.chartItems.forEach((item: ageGenderChartItem) => {
      if (item.value >= maxValue) maxValue = item.value;
    });
  });
  return maxValue;
}

export function processBehavioralDnaCompareChartData(
  baseStartDate: string,
  baseEndDate: string,
  compareStartDate: string,
  compareEndDate: string,
  response: CompareBehavioralDnaResponse
) {
  const tmpArray: (
    | string
    | number
    | { type: string; role: string; p: { html: boolean } }
    | { role: string }
  )[][] = response.visitCounts.map((item: CompareBehavioralDna) => {
    return [
      item.firstCategory.name + " › " + item.secondCategory.name,
      item.secondCategory.deviationValue.total[0].value ?? NaN,
      `<div style='display: flex; justify-content: center; align-items: center; height: 150px; min-width: 150px; color: #222222; font-size: 12px !important;'>
        <div style='display:table-cell; vertical-align:middle; text-align: left;'>
        <div style='text-align: left'>＜<span style="color: ${
          COLOR.TOTAL
        }">● </span>${baseStartDate} - ${baseEndDate}＞</div>
        <div style='text-align: left'>[カテゴリ] <b>${
          item.firstCategory.name
        } › ${item.secondCategory.name}</b>
        <div style='margin-bottom: 10px'>[全体スコア] <b>${
          item.secondCategory.deviationValue.total[0].value ?? "--"
        }</b></div>
        <div style='text-align: left'>＜<span style="color: ${
          COLOR.TOTAL2
        }">● </span>${compareStartDate} - ${compareEndDate}＞</div>
        <div style='text-align: left'>[カテゴリ] <b>${
          item.firstCategory.name
        } › ${item.secondCategory.name}</b></div>
        <div>[全体スコア] <b>${
          item.secondCategory.deviationValue.total[1].value ?? "--"
        }</b></div>
        </div>
      </div>`,
      isTrueOrNull(item.secondCategory.deviationValue.total[0].isAlert)
        ? "#bbb"
        : "",
      item.secondCategory.deviationValue.total[1].value ?? NaN,
      `<div style='display: flex; justify-content: center; align-items: center; height: 150px; min-width: 150px; color: #222222; font-size: 12px !important;'>
        <div style='display:table-cell; vertical-align:middle; text-align: left;'>
          <div style='text-align: left'>＜<span style="color: ${
            COLOR.TOTAL
          }">● </span>${baseStartDate} - ${baseEndDate}＞</div>
          <div style='text-align: left'>[カテゴリ] <b>${
            item.firstCategory.name
          } › ${item.secondCategory.name}</b>
          <div style='margin-bottom: 10px'>[全体スコア] <b>${
            item.secondCategory.deviationValue.total[0].value
          }</b></div>
          <div style='text-align: left'>＜<span style="color: ${
            COLOR.TOTAL2
          }">● </span>${compareStartDate} - ${compareEndDate}＞</div>
          <div style='text-align: left'>[カテゴリ] <b>${
            item.firstCategory.name
          } › ${item.secondCategory.name}</b></div>
          <div>[全体スコア] <b>${
            item.secondCategory.deviationValue.total[1].value ?? "--"
          }</b></div>
        </div>
      </div>`,
      isTrueOrNull(item.secondCategory.deviationValue.total[1].isAlert)
        ? "#ccc"
        : "",
      item.secondCategory.deviationValue.male[0].value ?? NaN,
      `<div style='display: flex; justify-content: center; align-items: center; height: 150px; min-width: 150px; color: #222222; font-size: 12px !important;'>
        <div style='display:table-cell; vertical-align:middle; text-align: left;'>
          <div style='text-align: left'>＜<span style="color: ${
            COLOR.BLUE
          }">● </span>${baseStartDate} - ${baseEndDate}＞</div>
          <div style='text-align: left'>[カテゴリ] <b>${
            item.firstCategory.name
          } › ${item.secondCategory.name}</b>
          <div style='margin-bottom: 10px'>[男性スコア] <b>${
            item.secondCategory.deviationValue.male[0].value ?? "--"
          }</b></div>
          <div style='text-align: left'>＜<span style="color: ${
            COLOR.BLUE2
          }">● </span>${compareStartDate} - ${compareEndDate}＞</div>
          <div style='text-align: left'>[カテゴリ] <b>${
            item.firstCategory.name
          } › ${item.secondCategory.name}</b></div>
          <div>[男性スコア] <b>${
            item.secondCategory.deviationValue.male[1].value ?? "--"
          }</b></div>
        </div>
      </div>`,
      isTrueOrNull(item.secondCategory.deviationValue.male[0].isAlert)
        ? "#bbb"
        : "",
      item.secondCategory.deviationValue.male[1].value ?? NaN,
      `<div style='display: flex; justify-content: center; align-items: center; height: 150px; min-width: 150px; color: #222222; font-size: 12px !important;'>
        <div style='display:table-cell; vertical-align:middle; text-align: left;'>
          <div style='text-align: left'>＜<span style="color: ${
            COLOR.BLUE
          }">● </span>${baseStartDate} - ${baseEndDate}＞</div>
          <div style='text-align: left'>[カテゴリ] <b>${
            item.firstCategory.name
          } › ${item.secondCategory.name}</b>
          <div style='margin-bottom: 10px'>[男性スコア] <b>${
            item.secondCategory.deviationValue.male[0].value ?? "--"
          }</b></div>
          <div style='text-align: left'>＜<span style="color: ${
            COLOR.BLUE2
          }">● </span>${compareStartDate} - ${compareEndDate}＞</div>
          <div style='text-align: left'>[カテゴリ] <b>${
            item.firstCategory.name
          } › ${item.secondCategory.name}</b></div>
          <div>[男性スコア] <b>${
            item.secondCategory.deviationValue.male[1].value ?? "--"
          }</b></div>
        </div>
      </div>`,
      isTrueOrNull(item.secondCategory.deviationValue.male[1].isAlert)
        ? "#ccc"
        : "",
      item.secondCategory.deviationValue.female[0].value ?? NaN,
      `<div style='display: flex; justify-content: center; align-items: center; height: 150px; min-width: 150px; color: #222222; font-size: 12px !important;'>
        <div style='display:table-cell; vertical-align:middle; text-align: left;'>
          <div style='text-align: left'>＜<span style="color: ${
            COLOR.RED
          }">● </span>${baseStartDate} - ${baseEndDate}＞</div>
          <div style='text-align: left'>[カテゴリ] <b>${
            item.firstCategory.name
          } › ${item.secondCategory.name}</b>
          <div style='margin-bottom: 10px'>[女性スコア] <b>${
            item.secondCategory.deviationValue.female[0].value ?? "--"
          }</b></div>
          <div style='text-align: left'>＜<span style="color: ${
            COLOR.RED2
          }">● </span>${compareStartDate} - ${compareEndDate}＞</div>
          <div style='text-align: left'>[カテゴリ] <b>${
            item.firstCategory.name
          } › ${item.secondCategory.name}</b></div>
          <div>[女性スコア] <b>${
            item.secondCategory.deviationValue.female[1].value ?? "--"
          }</b></div>
        </div>
      </div>`,
      isTrueOrNull(item.secondCategory.deviationValue.female[0].isAlert)
        ? "#bbb"
        : "",
      item.secondCategory.deviationValue.female[1].value ?? NaN,
      `<div style='display: flex; justify-content: center; align-items: center; height: 150px; min-width: 150px; color: #222222; font-size: 12px !important;'>
        <div style='display:table-cell; vertical-align:middle; text-align: left;'>
          <div style='text-align: left'>＜<span style="color: ${
            COLOR.RED
          }">● </span>${baseStartDate} - ${baseEndDate}＞</div>
          <div style='text-align: left'>[カテゴリ] <b>${
            item.firstCategory.name
          } › ${item.secondCategory.name}</b>
          <div style='margin-bottom: 10px'>[女性スコア] <b>${
            item.secondCategory.deviationValue.female[0].value ?? "--"
          }</b></div>
          <div style='text-align: left'>＜<span style="color: ${
            COLOR.RED2
          }">● </span>${compareStartDate} - ${compareEndDate}＞</div>
          <div style='text-align: left'>[カテゴリ] <b>${
            item.firstCategory.name
          } › ${item.secondCategory.name}</b></div>
          <div>[女性スコア] <b>${
            item.secondCategory.deviationValue.female[1].value ?? "--"
          }</b></div>
        </div>
      </div>`,
      isTrueOrNull(item.secondCategory.deviationValue.female[1].isAlert)
        ? "#ccc"
        : "",
    ];
  });
  tmpArray.unshift([
    "categoryName",
    "全体: " + baseStartDate + " - " + baseEndDate,
    { type: "string", role: "tooltip", p: { html: true } },
    { role: "style" },
    "全体: " + compareStartDate + " - " + compareEndDate,
    { type: "string", role: "tooltip", p: { html: true } },
    { role: "style" },
    "男性: " + baseStartDate + " - " + baseEndDate,
    { type: "string", role: "tooltip", p: { html: true } },
    { role: "style" },
    "男性: " + compareStartDate + " - " + compareEndDate,
    { type: "string", role: "tooltip", p: { html: true } },
    { role: "style" },
    "女性: " + baseStartDate + " - " + baseEndDate,
    { type: "string", role: "tooltip", p: { html: true } },
    { role: "style" },
    "女性: " + compareStartDate + " - " + compareEndDate,
    { type: "string", role: "tooltip", p: { html: true } },
    { role: "style" },
  ]);

  return tmpArray;
}

export function processBehavioralDnaThirdCategoryChartData(
  l1l2CategoryName: string,
  data: ThirdCategory[]
) {
  let tmpArray: (
    | string
    | number
    | null
    | { type: string; role: string; p: { html: boolean } }
    | { role: string }
  )[][] = [];
  tmpArray = data.map((item: ThirdCategory) => {
    return [
      item.name,
      item.deviationValue.total ?? NaN,
      `<div style='display: flex; justify-content: center; align-items: center; min-height: 70px; color: #222222; font-size: 12px !important;'>
        <div style='display:table-cell; vertical-align:middle; text-align: left;'>
          <div>[カテゴリ] <b>${l1l2CategoryName}</b></div>
          <div>[詳細カテゴリ] <b>${item.name}</b></div>
          <div>[全体スコア] <b>${item.deviationValue.total ?? "--"}</b></div>
        </div>
      </div>`,
      isTrueOrNull(item.deviationValue.totalAlert) ? "#bbb" : "",
      item.deviationValue.male ?? NaN,
      `<div style='display: flex; justify-content: center; align-items: center; min-height: 70px; color: #222222; font-size: 12px !important;'>
        <div style='display:table-cell; vertical-align:middle; text-align: left;'>
          <div>[カテゴリ] <b>${l1l2CategoryName}</b></div>
          <div>[詳細カテゴリ] <b>${item.name}</b></div>
          <div>[男性スコア] <b>${item.deviationValue.male ?? "--"}</b></div>
        </div>
      </div>`,
      isTrueOrNull(item.deviationValue.maleAlert) ? "#bbb" : "",
      item.deviationValue.female ?? NaN,
      `<div style='display: flex; justify-content: center; align-items: center; min-height: 70px; color: #222222; font-size: 12px !important;'>
        <div style='display:table-cell; vertical-align:middle; text-align: left;'>
          <div>[カテゴリ] <b>${l1l2CategoryName}</b></div>
          <div>[詳細カテゴリ] <b>${item.name}</b></div>
          <div>[女性スコア] <b>${item.deviationValue.female ?? "--"}</b></div>
        </div>
      </div>`,
      isTrueOrNull(item.deviationValue.femaleAlert) ? "#bbb" : "",
    ];
  });
  tmpArray.unshift([
    "categoryName",
    "全体",
    { type: "string", role: "tooltip", p: { html: true } },
    { role: "style" },
    "男性",
    { type: "string", role: "tooltip", p: { html: true } },
    { role: "style" },
    "女性",
    { type: "string", role: "tooltip", p: { html: true } },
    { role: "style" },
  ]);
  return tmpArray;
}

export function processComparisonBehavioralDnaThirdCategoryChartData(
  data: CompareThirdCategory[],
  baseStartDate?: string,
  baseEndDate?: string,
  compareStartDate?: string,
  compareEndDate?: string
) {
  let tmpArray: (
    | string
    | number
    | null
    | { type: string; role: string; p: { html: boolean } }
    | { role: string }
  )[][] = [];
  tmpArray = data.map((item: CompareThirdCategory) => {
    return [
      item.name,
      item.deviationValue.total[0].value ?? NaN,
      `<div style='display: flex; justify-content: center; align-items: center; height: 150px; min-width: 150px; color: #222222; font-size: 12px !important;'>
        <div style='display:table-cell; vertical-align:middle; text-align: left;'>
        <div style='text-align: left'>＜<span style="color: ${
          COLOR.TOTAL
        }">● </span>${baseStartDate} - ${baseEndDate}＞</div>
        <div style='text-align: left'>[詳細カテゴリ] <b>${item.name}</b>
        <div style='margin-bottom: 10px'>[全体スコア] <b>${
          item.deviationValue.total[0].value ?? "--"
        }</b></div>
        <div style='text-align: left'>＜<span style="color: ${
          COLOR.TOTAL2
        }">● </span>${compareStartDate} - ${compareEndDate}＞</div>
        <div style='text-align: left'>[詳細カテゴリ] <b>${item.name}</b></div>
        <div>[全体スコア] <b>${
          item.deviationValue.total[1].value ?? "--"
        }</b></div>
        </div>
      </div>`,
      isTrueOrNull(item.deviationValue.total[0].isAlert) ? "#bbb" : "",
      item.deviationValue.total[1].value ?? NaN,
      `<div style='display: flex; justify-content: center; align-items: center; height: 150px; min-width: 150px; color: #222222; font-size: 12px !important;'>
        <div style='display:table-cell; vertical-align:middle; text-align: left;'>
        <div style='text-align: left'>＜<span style="color: ${
          COLOR.TOTAL
        }">● </span>${baseStartDate} - ${baseEndDate}＞</div>
        <div style='text-align: left'>[詳細カテゴリ] <b>${item.name}</b>
        <div style='margin-bottom: 10px'>[全体スコア] <b>${
          item.deviationValue.total[0].value ?? "--"
        }</b></div>
        <div style='text-align: left'>＜<span style="color: ${
          COLOR.TOTAL2
        }">● </span>${compareStartDate} - ${compareEndDate}＞</div>
        <div style='text-align: left'>[詳細カテゴリ] <b>${item.name}</b></div>
        <div>[全体スコア] <b>${
          item.deviationValue.total[1].value ?? "--"
        }</b></div>
        </div>
      </div>`,
      isTrueOrNull(item.deviationValue.total[1].isAlert) ? "#ccc" : "",
      item.deviationValue.male[0].value ?? NaN,
      `<div style='display: flex; justify-content: center; align-items: center; height: 150px; min-width: 150px; color: #222222; font-size: 12px !important;'>
        <div style='display:table-cell; vertical-align:middle; text-align: left;'>
        <div style='text-align: left'>＜<span style="color: ${
          COLOR.BLUE
        }">● </span>${baseStartDate} - ${baseEndDate}＞</div>
        <div style='text-align: left'>[詳細カテゴリ] <b>${item.name}</b>
        <div style='margin-bottom: 10px'>[男性スコア] <b>${
          item.deviationValue.male[0].value ?? "--"
        }</b></div>
        <div style='text-align: left'>＜<span style="color: ${
          COLOR.BLUE2
        }">● </span>${compareStartDate} - ${compareEndDate}＞</div>
        <div style='text-align: left'>[詳細カテゴリ] <b>${item.name}</b></div>
        <div>[男性スコア] <b>${
          item.deviationValue.male[1].value ?? "--"
        }</b></div>
        </div>
      </div>`,
      isTrueOrNull(item.deviationValue.male[0].isAlert) ? "#bbb" : "",
      item.deviationValue.male[1].value ?? NaN,
      `<div style='display: flex; justify-content: center; align-items: center; height: 150px; min-width: 150px; color: #222222; font-size: 12px !important;'>
        <div style='display:table-cell; vertical-align:middle; text-align: left;'>
        <div style='text-align: left'>＜<span style="color: ${
          COLOR.BLUE
        }">● </span>${baseStartDate} - ${baseEndDate}＞</div>
        <div style='text-align: left'>[詳細カテゴリ] <b>${item.name}</b>
        <div style='margin-bottom: 10px'>[男性スコア] <b>${
          item.deviationValue.male[0].value ?? "--"
        }</b></div>
        <div style='text-align: left'>＜<span style="color: ${
          COLOR.BLUE2
        }">● </span>${compareStartDate} - ${compareEndDate}＞</div>
        <div style='text-align: left'>[詳細カテゴリ] <b>${item.name}</b></div>
        <div>[男性スコア] <b>${
          item.deviationValue.male[1].value ?? "--"
        }</b></div>
        </div>
      </div>`,
      isTrueOrNull(item.deviationValue.male[1].isAlert) ? "#ccc" : "",
      item.deviationValue.female[0].value ?? NaN,
      `<div style='display: flex; justify-content: center; align-items: center; height: 150px; min-width: 150px; color: #222222; font-size: 12px !important;'>
        <div style='display:table-cell; vertical-align:middle; text-align: left;'>
        <div style='text-align: left'>＜<span style="color: ${
          COLOR.RED
        }">● </span>${baseStartDate} - ${baseEndDate}＞</div>
        <div style='text-align: left'>[詳細カテゴリ] <b>${item.name}</b>
        <div style='margin-bottom: 10px'>[女性スコア] <b>${
          item.deviationValue.female[0].value ?? "--"
        }</b></div>
        <div style='text-align: left'>＜<span style="color: ${
          COLOR.RED2
        }">● </span>${compareStartDate} - ${compareEndDate}＞</div>
        <div style='text-align: left'>[詳細カテゴリ] <b>${item.name}</b></div>
        <div>[女性スコア] <b>${
          item.deviationValue.female[1].value ?? "--"
        }</b></div>
        </div>
      </div>`,
      isTrueOrNull(item.deviationValue.female[0].isAlert) ? "#bbb" : "",
      item.deviationValue.female[1].value ?? NaN,
      `<div style='display: flex; justify-content: center; align-items: center; height: 150px; min-width: 150px; color: #222222; font-size: 12px !important;'>
        <div style='display:table-cell; vertical-align:middle; text-align: left;'>
        <div style='text-align: left'>＜<span style="color: ${
          COLOR.RED
        }">● </span>${baseStartDate} - ${baseEndDate}＞</div>
        <div style='text-align: left'>[詳細カテゴリ] <b>${item.name}</b>
        <div style='margin-bottom: 10px'>[女性スコア] <b>${
          item.deviationValue.female[0].value ?? "--"
        }</b></div>
        <div style='text-align: left'>＜<span style="color: ${
          COLOR.RED2
        }">● </span>${compareStartDate} - ${compareEndDate}＞</div>
        <div style='text-align: left'>[詳細カテゴリ] <b>${item.name}</b></div>
        <div>[女性スコア] <b>${
          item.deviationValue.female[1].value ?? "--"
        }</b></div>
        </div>
      </div>`,
      isTrueOrNull(item.deviationValue.female[1].isAlert) ? "#ccc" : "",
    ];
  });
  tmpArray.unshift([
    "categoryName",
    "全体: " + baseStartDate + " - " + baseEndDate,
    { type: "string", role: "tooltip", p: { html: true } },
    { role: "style" },
    "全体: " + compareStartDate + " - " + compareEndDate,
    { type: "string", role: "tooltip", p: { html: true } },
    { role: "style" },
    "男性: " + baseStartDate + " - " + baseEndDate,
    { type: "string", role: "tooltip", p: { html: true } },
    { role: "style" },
    "男性: " + compareStartDate + " - " + compareEndDate,
    { type: "string", role: "tooltip", p: { html: true } },
    { role: "style" },
    "女性: " + baseStartDate + " - " + baseEndDate,
    { type: "string", role: "tooltip", p: { html: true } },
    { role: "style" },
    "女性: " + compareStartDate + " - " + compareEndDate,
    { type: "string", role: "tooltip", p: { html: true } },
    { role: "style" },
  ]);
  return tmpArray;
}
