<template>
  <v-card height="'650px'" class="card">
    <v-card-title class="pa-0 card-title d-flex align-center">
      <span>性別✕年代構成</span>
      <AlertTooltip
        v-if="chartAlert"
        class="ml-2"
        text="取得データボリュームが少なく統計上の信頼性が低い日が含まれています。該当日は参考値としてご参照ください。"
      />
      <v-spacer />
      <ChartDescriptionTooltip
        menu-key="compare"
        sub-menu-key="persona"
        chart-key="genderAgeStructure"
        class="mr-27px"
      />
      <DownloadButton
        label="CSVデータ"
        :disabled="props.selectedChainIds.length === 0"
        :get-file-id="
          () =>
            downloadAgeGenderCSV(
              props.selectedChainIds,
              props.selectedPrefectureIds.map((id) => id.toString()),
              startDate,
              endDate
            )
        "
        :csv-name="`チェーン比較_性別✕年代構成_${convertSlashDelimiter(
          startDate
        )}-${convertSlashDelimiter(endDate)}`"
      />
    </v-card-title>
    <v-container class="pa-0" fluid>
      <v-row class="my-0" dense>
        <v-col class="py-0">
          <div v-show="props.selectedChainIds.length === 0" class="unselected_card">
            チェーンを選択するとチャートが表示されます。
          </div>
          <div v-show="props.selectedChainIds.length > 0">
            <LoadingImg v-if="chartLoading" :height="`${200 * props.selectedChainIds.length}px`" />
            <NoDataChart v-else-if="isNoData" />
            <div v-else>
              <AgeGenderChart :chart-data="chartData" :has-alert="chartAlert" />
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script setup lang="ts">
import AlertTooltip from '@/commons/components/Elements/AlertTooltip.vue'
import ChartDescriptionTooltip from '@/commons/components/Elements/ChartDescriptionTooltip.vue'
import DownloadButton from '@/commons/components/Elements/DownloadButton.vue'
import LoadingImg from '@/commons/components/loadingImg.vue'
import { Chain, ChainWithColor } from '@/commons/interfaces'
import NoDataChart from '@/features/ShopAnalytics/components/Common/noDataChart.vue'
import { ref, watch, computed } from 'vue'
import { useStore } from 'vuex'
import * as notify from '@/plugins/notification'
import { convertSlashDelimiter } from '@/commons/utils/dateUtil'
import { getAgeGenderChart } from '@/features/ChainAnalytics/persona/axios'
import { AgeGenderResponse } from '@/features/ChainAnalytics/persona/interfaces/response'
import { processAgeGenderChart } from '../utils/process'
import { AgeGendersChartData } from '../types'
import AgeGenderChart from './AgeGenderChart.vue'
import { downloadAgeGenderCSV } from '../axios'

/* --------------------------------------------------------------------------
  props
 ---------------------------------------------------------------------------*/

const props = withDefaults(
  defineProps<{
    selectedChainIds?: string[]
    selectedPrefectureIds?: number[]
  }>(),
  {
    selectedChainIds: (): string[] => [],
    selectedPrefectureIds: (): number[] => []
  }
)

/* --------------------------------------------------------------------------
  Vuex
 ---------------------------------------------------------------------------*/

const store = useStore()
const startDate = computed<string>(() => store.state.startDate)
const endDate = computed<string>(() => store.state.endDate)
const chainMap = computed<Map<string, Chain>>(() => store.getters.chainMap)
const chainIdToColorMap = computed<Map<string, string>>(() => store.state.chainIdToColorMap)

/* --------------------------------------------------------------------------
  core
 ---------------------------------------------------------------------------*/

const selectedChains = computed<ChainWithColor[]>(() =>
  props.selectedChainIds.reduce((acc: ChainWithColor[], id: string): ChainWithColor[] => {
    const chain = chainMap.value.get(id)
    if (!chain) return acc
    return [...acc, { ...chain, color: chainIdToColorMap.value.get(id) ?? '#AAA' }]
  }, [])
)
const chartLoading = ref<boolean>(false)
const chartResponses = ref<Map<ChainWithColor, AgeGenderResponse>>(new Map())
const chartData = computed<AgeGendersChartData>(() =>
  processAgeGenderChart(
    Array.from(chartResponses.value).map(([chain, { visitCounts }]) => ({
      chain,
      visitCounts
    }))
  )
)
const chartAlert = computed<boolean>(() => {
  if (chartResponses.value.size === 0) return false
  return Array.from(chartResponses.value.values()).some(({ visitCounts }) =>
    visitCounts.some(({ chartItems }) => chartItems.some(({ isAlert }) => isAlert))
  )
})
const isNoData = computed<boolean>(() => {
  if (chartResponses.value.size === 0) return true
  return Array.from(chartResponses.value.values()).every(({ visitCounts }) =>
    visitCounts.every(({ chartItems }) => chartItems.every(({ value }) => !value))
  )
})

const fetchChart = async () => {
  if (
    chartLoading.value ||
    !props.selectedChainIds.length ||
    !props.selectedPrefectureIds.length ||
    chainIdToColorMap.value.size !== props.selectedChainIds.length
  )
    return

  const fetchings = selectedChains.value.map((chain, index) =>
    getAgeGenderChart(chain.id, startDate.value, endDate.value, props.selectedPrefectureIds).then(
      (res) => {
        // NOTE: チェーンのランク順ではなく選択順とする。
        chartResponses.value.set({ ...chain, orderIndex: index }, res.data)
      }
    )
  )

  try {
    chartLoading.value = true
    chartResponses.value.clear()
    await Promise.all(fetchings)
  } catch {
    notify.notifyErrorMessage('性別✕年代構成チャートが表示できませんでした。')
  } finally {
    chartLoading.value = false
  }
}

/* --------------------------------------------------------------------------
  created
 -------------------------------------------------------------------------- */

fetchChart()

/* --------------------------------------------------------------------------
  watch
 ---------------------------------------------------------------------------*/

watch(
  () => [props.selectedChainIds, props.selectedPrefectureIds],
  () => {
    if (props.selectedChainIds.length > 0 && props.selectedPrefectureIds.length > 0) {
      fetchChart()
    }
  },
  { deep: true }
)
watch(chainIdToColorMap, () => {
  fetchChart()
})
</script>

<style lang="scss" scoped>
.mr-27px {
  margin-right: 27px;
}
</style>
