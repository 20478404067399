<template>
  <v-container
    fluid
    class="biz_area_table pa-0"
    style="background-color: white"
  >
    <v-data-table
      v-model:items-per-page="itemsPerPage"
      v-model:page="page"
      :items="areasDataFromProps"
      :items-per-page-options="[10, 20]"
    >
      <template #headers>
        <tr class="table_head">
          <th class="column_head">
            エリア名
          </th>
          <th class="column_population">
            <sort-button
              :show-status="{
                up: sortBy.column !== 'population' || !sortBy.desc,
                down: sortBy.column !== 'population' || sortBy.desc,
              }"
              @sort-action="sortAreasBy({ targetColumn: 'population' })"
            >
              <div />
              居住人口
            </sort-button>
          </th>
          <th
            v-for="store in stores"
            :key="store.id"
            class="column_value"
          >
            <sort-button
              :show-status="{
                up:
                  sortBy.column !== 'storeId' ||
                  sortBy.storeId !== store.id ||
                  !sortBy.desc,
                down:
                  sortBy.column !== 'storeId' ||
                  sortBy.storeId !== store.id ||
                  sortBy.desc,
              }"
              @sort-action="
                sortAreasBy({ targetColumn: 'storeId', storeId: store.id })
              "
            >
              <div />
              <div
                class="mx-1 store_name"
                :title="store.name"
              >
                {{ store.name }}
              </div>
            </sort-button>
          </th>
        </tr>
      </template>
      <template #body="props">
        <tr
          v-for="area in props.items"
          :key="area.areaId"
          class="table_body"
        >
          <td class="column_head">
            {{ area.areaName }}
          </td>
          <td class="column_population">
            {{ area.population.toLocaleString() }}
          </td>
          <td
            v-for="store in area.stores"
            :key="store.storeId"
            class="column_value"
            :class="{
              'bg-light-blue': store.storeId === baseStoreId,
              'bg-gray': store.isAlert,
            }"
          >
            <span class="text">
              {{
                !isNaN(store.visitRatio)
                  ? (store.visitRatio * 100).toFixed(1) + "%"
                  : "---"
              }}
            </span>
            <div class="bar">
              <div
                :style="`width: ${getVisitRatiosWidth(
                  store.visitRatio * 100
                )}`"
                :class="{
                  default: store.storeId !== baseStoreId,
                  basestore: store.storeId === baseStoreId,
                }"
              />
            </div>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Area } from "@/features/StoreCompare/bizArea/types";
import { deepCopy } from "@/commons/utils";
import { ComparisonGroup } from "@/commons/interfaces";
import SortButton from "@/commons/components/Elements/SortButton.vue";

export default defineComponent({
  name: "BizAreaTable",
  components: {
    SortButton,
  },
  props: {
    areas: { type: Array as PropType<Area[]>, required: true },
    baseStoreId: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      sortBy: { column: "total", desc: true, storeId: null } as {
        column: "total" | "population" | "storeId";
        desc: boolean;
        storeId: string | null;
      },
      areasDataFromProps: [] as Area[],
      page: 1,
      itemsPerPage: 10,
    };
  },
  computed: {
    areasVisitRatioMax() {
      return Math.max(
        ...this.areas
          .flatMap((area) => area.stores)
          .filter((store) => !isNaN(store.visitRatio))
          .map((store) => store.visitRatio * 100)
      );
    },
    stores(): { id: string; name: string }[] {
      if (this.$store.state.selectedComparisonGroup === null) return [];
      const stores = deepCopy(
        (this.$store.state.selectedComparisonGroup as ComparisonGroup).stores
      );
      const baseStore = (
        this.$store.state.selectedComparisonGroup as ComparisonGroup
      ).stores.find((s) => s.id === this.baseStoreId);
      if (baseStore) {
        return [
          baseStore,
          ...stores
            .filter((s) => s.id !== this.baseStoreId)
            .sort((a, b) => a.orderIndex - b.orderIndex),
        ];
      }
      return (
        this.$store.state.selectedComparisonGroup as ComparisonGroup
      ).stores.sort((a, b) => a.orderIndex - b.orderIndex);
    },
  },
  watch: {
    areas() {
      this.initAreasDataFromProps();
    },
    baseStoreId() {
      this.initAreasDataFromProps();
    },
    areasDataFromProps() {
      this.page = 1;
    },
    itemsPerPage() {
      this.page = 1;
    },
  },
  created() {
    this.initAreasDataFromProps();
  },
  methods: {
    initAreasDataFromProps() {
      const tmp: Area[] = this.areas.map((area) => {
        const baseStore = area.stores.find(
          (s) => s.storeId === this.baseStoreId
        );
        if (baseStore) {
          area.stores = [
            baseStore,
            ...area.stores.filter((s) => s.storeId !== baseStore.storeId),
          ];
        }
        return area;
      });

      this.areasDataFromProps = tmp.sort((a, b) => {
        for (let index = 0; index < a.stores.length; index++) {
          if (
            isNaN(b.stores[index].visitRatio) &&
            !isNaN(a.stores[index].visitRatio)
          ) {
            return -1;
          } else if (
            !isNaN(b.stores[index].visitRatio) &&
            isNaN(a.stores[index].visitRatio)
          ) {
            return 1;
          } else if (
            !isNaN(b.stores[index].visitRatio) &&
            !isNaN(a.stores[index].visitRatio) &&
            b.stores[index].visitRatio !== a.stores[index].visitRatio
          ) {
            return b.stores[index].visitRatio - a.stores[index].visitRatio;
          }
        }
        return b.areaName > a.areaName ? -1 : 1;
      });
    },
    getVisitRatiosWidth(value: number): string {
      if (this.areasVisitRatioMax === 0 || isNaN(value)) return "0";
      return (value / this.areasVisitRatioMax) * 100 + "%";
    },
    sortAreasBy(args: {
      targetColumn: "total" | "population" | "storeId";
      storeId?: string;
    }) {
      const { targetColumn, storeId } = args;

      if (
        (this.sortBy.column !== "storeId" &&
          this.sortBy.column === targetColumn) ||
        (this.sortBy.column === "storeId" && this.sortBy.storeId === storeId)
      ) {
        this.sortBy.desc = !this.sortBy.desc;
      } else {
        this.sortBy.column = targetColumn;
        this.sortBy.desc = true;
        this.sortBy.storeId =
          targetColumn !== "storeId" ? null : storeId ?? null;
      }

      this.areasDataFromProps = this.areasDataFromProps.sort((a, b) => {
        if (this.sortBy.column === "total")
          return this.sortBy.desc ? b.total - a.total : a.total - b.total;
        if (this.sortBy.column === "population")
          return this.sortBy.desc
            ? b.population - a.population
            : a.population - b.population;
        if (this.sortBy.column === "storeId")
          return this.sortByStoreId(storeId, this.sortBy.desc, b, a);
        return b.total - a.total;
      });
    },
    sortByStoreId(
      storeId: string | undefined,
      desc: boolean,
      first: Area,
      second: Area
    ): number {
      const firstValue: number =
        first.stores.find((store) => store.storeId === storeId)?.visitRatio ??
        0;
      const secondValue: number =
        second.stores.find((store) => store.storeId === storeId)?.visitRatio ??
        0;

      // NOTE: ソート時NaNは常に最後列
      if (isNaN(firstValue) && isNaN(secondValue)) return 0;
      if (isNaN(firstValue)) return -1;
      if (isNaN(secondValue)) return 1;

      return desc ? firstValue - secondValue : secondValue - firstValue;
    },
  },
});
</script>

<style lang="scss" scoped>
.biz_area_table {
  background-color: white;

  .v-data-table {
    white-space: nowrap;

    th {
      border-top: 1px solid #dddddd !important;
      border-bottom: 1px solid #dddddd !important;
    }

    .column_head {
      border-left: 2px solid #dddddd !important;
      border-right: 2px solid #dddddd !important;
      z-index: 1;
      min-width: 260px;
      max-width: 260px;
      text-align: start;
      position: sticky;
      left: 0px;
      white-space: pre-wrap;
    }

    .column_population {
      border-right: 2px solid #dddddd !important;
      z-index: 1;
      min-width: 130px;
      max-width: 130px;
      text-align: center;
      position: sticky;
      left: 260px;
      white-space: pre-wrap;
    }

    .column_value {
      border-right: 2px solid #dddddd !important;
      min-width: 200px;
      max-width: 200px;
      text-align: center;
      font-size: 13px;

      .store_name {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }

    .table_head {
      background-color: #f5f5f5;
      th {
        height: 36px;
        font-size: 12px;
        color: #222222;
        font-weight: bold;
        background-color: #f5f5f5;
        padding: 0 10px;
        border-top: 2px solid #dddddd !important;
      }
    }
    .table_body {
      td {
        height: 36px;
        font-size: 12px;
        color: #222222;
        background-color: white;
        border-top: 1px solid #dddddd !important;
        border-bottom: 1px solid #dddddd !important;
      }

      .bg-light-blue {
        background-color: #f4fbff !important;
        color: #222222 !important;
      }
      .bg-gray {
        background-color: #aaaaaa !important;
      }

      .column_value {
        position: relative;

        .text {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          -webkit-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
        }

        .bar {
          .default {
            height: 22px;
            background-color: #eeeeee;
          }
          .basestore {
            height: 22px;
            background-color: #4d99d0;
            opacity: 0.35;
          }
        }
      }
    }
  }
}
</style>
