// import Vue from "vue";
import { axiosClient } from "@/plugins/axios";
import { AxiosResponse } from "axios";
import { convertHyphenDelimiter } from "@/commons/utils/dateUtil";
import { DownloadResponse } from "@/commons/interfaces/responses/download";
import qs from "qs";

/**
 * 性別年代別店舗比較DL
 * @param {string[]} storeIds 対象の店舗IDs
 * @param {string} start 期間開始日(yyyy-MM-dd)
 * @param {string} end 期間終了日(yyyy-MM-dd)
 * @returns
 */
export function downloadAgeGenderCSV(
  storeIds: string[],
  start: string,
  end: string
): Promise<AxiosResponse<DownloadResponse>> {
  return axiosClient.get<DownloadResponse>(
    "/stores/chart/age-gender/store-comparison/download/",
    {
      params: {
        store: storeIds,
        start: convertHyphenDelimiter(start),
        end: convertHyphenDelimiter(end),
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    }
  );
}

/**
 * 行動DNA店舗比較DL
 * @param {string[]} storeIds 対象の店舗IDs
 * @param {string} start 期間開始日(yyyy-MM-dd)
 * @param {string} end 期間終了日(yyyy-MM-dd)
 * @returns
 */
export function downloadBehavioralDNACSV(
  storeIds: string[],
  start: string,
  end: string,
  level: number
): Promise<AxiosResponse<DownloadResponse>> {
  return axiosClient.get<DownloadResponse>(
    "/stores/chart/behavioral-dna/store-comparison/download/",
    {
      params: {
        store: storeIds,
        start: convertHyphenDelimiter(start),
        end: convertHyphenDelimiter(end),
        level: level,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    }
  );
}
