<template>
  <div class="store-chip">
    <span
      class="dot"
      :class="{
        'dot-red': store.storeType === STORE_TYPE.SUPER_MARKET['value'],
        'dot-green': store.storeType === STORE_TYPE.DRUG_STORE['value'],
        'dot-yellow': store.storeType === STORE_TYPE.HOME_CENTER['value'],
        'dot-purple': store.storeType === STORE_TYPE.CVS_STORE['value'],
      }"
    >
      ●
    </span>
    {{ store.name }}
    <button @click="clickClose(store)">
      <img src="@/assets/svg/close-middle.svg">
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { STORE_TYPE } from "@/commons/enums";
import { Store } from "@/commons/interfaces/responses/store";

export default defineComponent({
  name: "StoreChip",
  props: {
    store: {
      type: Object as PropType<Store>,
      required: true,
    },
  },
  emits: ["clickClose"],
  data() {
    return {
      STORE_TYPE: STORE_TYPE,
    };
  },
  methods: {
    clickClose(store: Store) {
      this.$emit("clickClose", { store: store });
    },
  },
});
</script>

<style lang="scss" scoped>
.store-chip {
  display: flex;
  height: 44px;
  width: fit-content;
  padding: 10px 13px 13px;
  font-size: 14px;
  font-weight: bold;
  text-align: 25px;
  color: #333;
  background: #f5f5f5;
  border-radius: 6px;

  .dot {
    margin-right: 4px;
  }

  .dot-red {
    color: #e47075;
  }

  .dot-green {
    color: #87b8a1;
  }

  .dot-yellow {
    color: #cb9e56;
  }

  .dot-purple {
    color: #9278c3;
  }

  button {
    display: flex;
    align-items: center;
    margin-left: 14px;
  }
}
</style>
