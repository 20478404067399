<template>
  <v-card
    height="'650px'"
    class="card"
  >
    <v-card-title class="pa-0 card-title d-flex align-center">
      <span>性別✕年代構成</span>
      <alert-tooltip
        v-if="hasAlert"
        class="ml-2"
        text="取得データボリュームが少なく統計上の信頼性が低い日が含まれています。該当日は参考値としてご参照ください。"
      />
      <v-spacer />
      <chart-description-tooltip
        menu-key="compare"
        sub-menu-key="persona"
        chart-key="genderAgeStructure"
        class="mr-27px"
      />
      <download-button
        label="CSVデータ"
        :disabled="!$store.state.selectedComparisonGroup"
        :get-file-id="getFileId"
        :csv-name="csvName"
      />
    </v-card-title>
    <v-container
      class="pa-0"
      fluid
    >
      <v-row
        class="my-0"
        dense
      >
        <v-col class="py-0">
          <div
            v-show="
              $store.getters.selectedComparisonGroupVisibleStores
                .length === 0
            "
            class="unselected_card"
          >
            店舗を選択するとチャートが表示されます。
          </div>
          <div
            v-show="
              $store.getters.selectedComparisonGroupVisibleStores.length >
                0
            "
          >
            <loadingImg
              v-if="chartLoading"
              :height="ageGenderChartHeight"
            />
            <no-data-chart v-else-if="isNoData" />
            <div v-else>
              <age-gender-chart
                :chart-data="chartData"
                :has-alert="hasAlert"
              />
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import AgeGenderChart from "@/features/StoreCompare/persona/components/AgeGenderChart.vue";
// TODO: common に寄せる
import NoDataChart from "@/features/ShopAnalytics/components/Common/noDataChart.vue";
import { AgeGendersChartData } from "@/features/StoreCompare/persona/types";
import LoadingImg from "@/commons/components/loadingImg.vue";
import { AxiosResponse } from "axios";

export default defineComponent({
  components: {
    LoadingImg,
    AgeGenderChart,
    NoDataChart,
  },
  props: {
    chartData: {
      type: Array as PropType<AgeGendersChartData>,
      default: () => [],
    },
    chartLoading: {
      type: Boolean,
    },
    hasAlert: {
      type: Boolean,
    },
    getFileId: {
      type: Function as PropType<() => Promise<AxiosResponse<any, any>>>,
      default: undefined,
    },
    csvName: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    ageGenderChartHeight(): string {
      return `${
        200 * this.$store.getters.selectedComparisonGroupVisibleStores.length
      }px`;
    },
    isNoData(): boolean {
      return this.chartData
        .flatMap((data) => data.graph.flatMap((value) => value))
        .filter((data) => typeof data === "number")
        .every((data) => data === 0);
    },
  },
});
</script>

<style scoped>
.v-btn--active {
  color: #fff !important;
  background: #0e182e !important;
}
.mr-27px {
  margin-right: 27px;
}
</style>
