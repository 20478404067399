<template>
  <v-container
    class="pa-0"
    fluid
  >
    <v-row
      class="title-container"
      dense
      no-gutters
    >
      <v-col style="display: flex; align-items: center">
        <h1 class="title-text">
          来店人数推移
        </h1>
        <update-badge
          type="week"
          style="margin-left: 15px"
        />
      </v-col>
      <v-spacer />
      <date-picker
        :start-date="$store.state.startDate"
        :end-date="$store.state.endDate"
        :compare-start-date="$store.state.compareStartDate"
        :compare-end-date="$store.state.compareEndDate"
        @update-period="updatePeriod"
      />
    </v-row>
    <v-row
      dense
      no-gutters
      class="card-container"
    >
      <v-col>
        <store-selector :loading="storeLoading" />
      </v-col>
    </v-row>
    <v-row
      dense
      no-gutters
      class="card-container mb-0"
    >
      <v-col>
        <v-card height="'650px'">
          <v-container
            class="pa-0"
            fluid
          >
            <v-row
              class="d-flex justify-space-between my-0"
              dense
              no-gutters
            >
              <v-col
                class="d-flex align-center text-left"
                style="margin-top: 9px; margin-bottom: 30px"
              >
                <v-btn-toggle
                  v-model.number="particle"
                  mandatory
                  :divided="true"
                  density="compact"
                >
                  <v-btn
                    max-height="35px"
                    min-height="35px"
                    max-width="35px"
                    min-width="35px"
                    :value="particleType.DATE"
                    :border="true"
                  >
                    <span style="font-size: 12px">日</span>
                  </v-btn>
                  <v-btn
                    max-height="35px"
                    min-height="35px"
                    max-width="35px"
                    min-width="35px"
                    :value="particleType.WEEK"
                    :border="true"
                  >
                    <span style="font-size: 12px">週</span>
                  </v-btn>
                  <v-btn
                    max-height="35px"
                    min-height="35px"
                    max-width="35px"
                    min-width="35px"
                    :value="particleType.MONTH"
                    :border="true"
                  >
                    <span style="font-size: 12px">月</span>
                  </v-btn>
                </v-btn-toggle>
                <alert-tooltip
                  v-if="hasAlertInChartData"
                  class="ml-2"
                  text="取得データボリュームが少なく、統計上の信頼性の低いデータが含まれています。該当箇所は、参考値としてご参照ください。"
                />
                <v-spacer />
                <span
                  v-if="!isComparison"
                  style="font-size: 12px"
                >内訳</span>
                <div
                  v-if="!isComparison"
                  style="margin-left: 10px; width: 140px"
                >
                  <sub-type-selector
                    v-model="subType"
                    style="margin-left: 8px"
                    :items="subTypes"
                    :disabled="isComparison"
                  />
                </div>
                <chart-description-tooltip
                  menu-key="analytics"
                  sub-menu-key="visitors"
                  chart-key="visitors"
                  class="ml-30px"
                />
                <download-button
                  label="CSVデータ"
                  :disabled="!selectedStore"
                  :get-file-id="getVisitCountFileId"
                  :csv-name="csvName"
                  class="download-button"
                />
              </v-col>
            </v-row>
            <v-row
              class="my-0"
              dense
            >
              <v-col class="py-0">
                <div
                  v-show="!selectedStore"
                  class="unselected_card"
                >
                  店舗を選択するとチャートが表示されます。
                </div>
                <div v-show="selectedStore">
                  <loadingImg
                    v-if="chartLoading"
                    :height="'550px'"
                  />
                  <no-data-chart v-else-if="isNoChartData" />
                  <div v-else>
                    <line-chart
                      v-if="!isComparison"
                      :chart-data="chartData"
                      :sub-type="subType"
                      :is-alert="hasAlertInChartData !== false"
                      :legends="lineChartLegends"
                    />
                    <compare-line-chart
                      v-else
                      :chart-data="compareChartData"
                      :is-alert="hasAlertInChartData !== false"
                      :legends="compareLineChartLegends"
                    />
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
          <div class="divider" />
          <v-container
            class="pa-0"
            fluid
          >
            <div
              v-show="!selectedStore"
              class="unselected_card"
            >
              店舗を選択するとテーブルが表示されます。
            </div>
            <div v-show="selectedStore">
              <loadingImg
                v-if="chartLoading"
                :height="'550px'"
              />
              <no-data-chart v-else-if="isNoTableData" />
              <div v-else>
                <visitor-table
                  v-if="!isComparison"
                  :total="totalData"
                  :items="tableData"
                />
                <compare-table
                  v-else
                  :items="compareTableData"
                />
              </div>
            </div>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";
// component
import DatePicker from "@/features/ShopAnalytics/components/Common/datePicker.vue";
import StoreSelector from "@/commons/components/StoreSelector/StoreSelector.vue";
import LineChart from "@/features/ShopAnalytics/components/Visitor/lineChart.vue";
import CompareLineChart from "@/features/ShopAnalytics/components/Visitor/compareLineChart.vue";
import VisitorTable from "@/features/ShopAnalytics/components/Visitor/visitorTable.vue";
import CompareTable from "@/features/ShopAnalytics/components/Visitor/compareTable.vue";
import SubTypeSelector from "@/features/ShopAnalytics/components/Visitor/subTypeSelector.vue";
import UpdateBadge from "@/features/ShopAnalytics/components/Common/updateBadge.vue";
import NoDataChart from "@/features/ShopAnalytics/components/Common/noDataChart.vue";

// util
import {
  convertHyphenDelimiter,
  convertSlashDelimiter,
} from "@/commons/utils/dateUtil";
import { getPeriodByRouterQueryPeriod } from "@/commons/utils";
import {
  processVisitorChartData,
  processingVisitorTotalData,
  processingVisitorTableData,
  processVisitorComparisonChartData,
  getLegends,
} from "@/features/ShopAnalytics/utils/visitor";

import * as notify from "@/plugins/notification";
// enum
import { PARTICLE_TYPE, POINT_STYLE, SUB_TYPE } from "@/commons/enums";

// interface
import { AxiosResponse } from "axios";
import * as tableItemInterface from "@/features/ShopAnalytics/interfaces/component";

import {
  VisitCountGraph,
  VisitCountTable,
  VisitorTableItem,
  VisitCountComparisonTable,
} from "@/features/ShopAnalytics/interfaces/response";
import { Store } from "@/commons/interfaces/responses/store";
// axios
import {
  getVisitCountGraph,
  getVisitCountTable,
  getVisitCountComparisonGraph,
  downloadVisitCountCsv,
  downloadVisitCountComparisonCsv,
} from "@/features/ShopAnalytics/axios/visitor";
import loadingImg from "@/commons/components/loadingImg.vue";

const QUERY_UNIT = {
  DATE: "date",
  WEEK: "week",
  MONTH: "month",
} as const;

const QUERY_SUB_INDICATOR = {
  NONE: "none",
  GENDER: "gender",
  AGE: "age",
} as const;

export default defineComponent({
  name: "VisitorView",
  components: {
    loadingImg,
    DatePicker,
    StoreSelector,
    LineChart,
    CompareLineChart,
    VisitorTable,
    CompareTable,
    SubTypeSelector,
    UpdateBadge,
    NoDataChart,
  },
  data() {
    return {
      created: false,
      storeLoading: false,
      tab: 0,
      chartData: [] as any[],
      compareChartData: [] as any[],
      particleType: PARTICLE_TYPE,
      particle:
        PARTICLE_TYPE.DATE as (typeof PARTICLE_TYPE)[keyof typeof PARTICLE_TYPE],
      chartLoading: false,
      totalData: undefined as VisitorTableItem | undefined,
      tableData: [] as VisitorTableItem[],
      compareTableData: [] as tableItemInterface.compareTableItem[],
      tableLoading: false,
      subType: SUB_TYPE.NONE as (typeof SUB_TYPE)[keyof typeof SUB_TYPE],
      subTypes: [
        { text: "なし", value: SUB_TYPE.NONE },
        { text: "性別", value: SUB_TYPE.GENDER },
        { text: "年代", value: SUB_TYPE.AGE },
      ],
    };
  },
  computed: {
    hasAlertInChartData() {
      if (this.isComparison) {
        return (this as any).compareChartData.some(
          (value: any[]) =>
            value[3] === POINT_STYLE.RED["alert"] ||
            value[6] === POINT_STYLE.BLUE["alert"]
        );
      } else {
        if (this.subType === SUB_TYPE.NONE)
          return (this as any).chartData.some(
            (value: any[]) => value[3] === POINT_STYLE.RED["alert"]
          );
        if (this.subType === SUB_TYPE.GENDER)
          return (this as any).chartData.some(
            (value: any[]) =>
              value[3] === POINT_STYLE.BLUE["alert"] ||
              value[6] === POINT_STYLE.RED["alert"]
          );
        if (this.subType === SUB_TYPE.AGE)
          return (this as any).chartData.some(
            (value: any[]) =>
              value[3] === POINT_STYLE.RED["alert"] ||
              value[6] === POINT_STYLE.BLUE["alert"] ||
              value[9] === POINT_STYLE.GREEN["alert"] ||
              value[12] === POINT_STYLE.ORANGE["alert"] ||
              value[15] === POINT_STYLE.PURPLE["alert"] ||
              value[18] === POINT_STYLE.BROWN["alert"]
          );
        return false;
      }
    },
    selectedStore(): Store | null {
      return this.$store.state.selectedStore;
    },
    isComparison(): boolean {
      return (
        this.$store.state.compareStartDate.length !== 0 &&
        this.$store.state.compareEndDate.length !== 0
      );
    },
    isNoChartData(): boolean {
      if (this.isComparison) {
        return this.compareChartData.length <= 1;
      } else {
        return this.chartData.length <= 1;
      }
    },
    isNoTableData(): boolean {
      if (this.isComparison) {
        return this.compareTableData.length <= 0;
      } else {
        return this.tableData.length <= 0;
      }
    },
    hasRouterQueryPeriod(): boolean {
      return this.$route.query.period !== undefined;
    },
    hasRouterQueryComparePeriod(): boolean {
      return this.$route.query.c_period !== undefined;
    },
    hasRouterQueryUnit(): boolean {
      return this.$route.query.unit !== undefined;
    },
    hasRouterQuerySubIndicator(): boolean {
      return this.$route.query.sub_indicator !== undefined;
    },
    lineChartLegends(): { text: string; color: string }[] {
      return getLegends(this.chartData, this.subType, this.isComparison);
    },
    compareLineChartLegends(): { text: string; color: string }[] {
      return getLegends(this.compareChartData, this.subType, this.isComparison);
    },
    csvName(): string {
      const { start, end, compareStart, compareEnd } = {
        start: convertSlashDelimiter(this.start),
        end: convertSlashDelimiter(this.end),
        compareStart: convertSlashDelimiter(this.compareStart),
        compareEnd: convertSlashDelimiter(this.compareEnd),
      };
      return !this.isComparison
        ? `店舗分析_来店人数_${start}-${end}`
        : `店舗分析_来店人数_${start}-${end}_${compareStart}-${compareEnd}`;
    },
    start(): string | undefined {
      switch (this.particle) {
        case PARTICLE_TYPE.DATE:
          return this.$store.state.startDate as string;
        case PARTICLE_TYPE.WEEK:
          return this.$store.getters.startDateOfWeek as string;
        case PARTICLE_TYPE.MONTH:
          return this.$store.getters.startDateOfMonth as string;
        default:
          return void 0;
      }
    },
    end(): string | undefined {
      switch (this.particle) {
        case PARTICLE_TYPE.DATE:
          return this.$store.state.endDate as string;
        case PARTICLE_TYPE.WEEK:
          return this.$store.getters.endDateOfWeek as string;
        case PARTICLE_TYPE.MONTH:
          return this.$store.getters.endDateOfMonth as string;
        default:
          return void 0;
      }
    },
    compareStart(): string | undefined {
      switch (this.particle) {
        case PARTICLE_TYPE.DATE:
          return this.$store.state.compareStartDate as string;
        case PARTICLE_TYPE.WEEK:
          return this.$store.getters.comparisonStartDateOfWeek as string;
        case PARTICLE_TYPE.MONTH:
          return this.$store.getters.comparisonStartDateOfMonth as string;
        default:
          return void 0;
      }
    },
    compareEnd(): string | undefined {
      switch (this.particle) {
        case PARTICLE_TYPE.DATE:
          return this.$store.state.compareEndDate as string;
        case PARTICLE_TYPE.WEEK:
          return this.$store.getters.comparisonEndDateOfWeek as string;
        case PARTICLE_TYPE.MONTH:
          return this.$store.getters.comparisonEndDateOfMonth as string;
        default:
          return void 0;
      }
    },
    getVisitCountFileId(): () => Promise<AxiosResponse<any, any>> {
      return () => {
        if (!this.isComparison)
          return downloadVisitCountCsv(
            this.selectedStore?.storeId,
            this.start,
            this.end,
            this.particle
          );
        else
          return downloadVisitCountComparisonCsv(
            this.selectedStore?.storeId,
            [
              {
                start: this.start,
                end: this.end,
              },
              {
                start: this.compareStart,
                end: this.compareEnd,
              },
            ],
            this.particle
          );
      };
    },
  },
  watch: {
    async selectedStore() {
      if (this.created) {
        await this.pushRoute();
        await this.fetchAll();
      }
    },
    async particle() {
      if (this.created) {
        await this.fetchAll();
        await this.pushRoute();
      }
    },
    async subType() {
      if (this.created) {
        await this.fetchAll();
        await this.pushRoute();
      }
    },
  },
  async created() {
    // クエリパラメータからデータ更新
    this.setDateFromRouterQuery();
    this.setCompareDateFromRouterQuery();
    this.setParticleFromRouterQuery();
    this.setSubTypeFromRouterQuery();

    await this.fetchAll();
    this.created = true;
  },
  methods: {
    async fetchAll() {
      // 店舗一覧取得
      this.storeLoading = true;

      if (!this.$store.state.stores.length)
        await this.$store.dispatch("fetchStores");

      // id があれば選択店舗を設定
      if (this.$route.params["id"]) {
        await this.$store.dispatch("specifiedStore", this.$route.params["id"]);
        if (!this.selectedStore) this.$router.push({ name: "NotFound" });
      } else this.$store.commit("initStore");

      this.storeLoading = false;

      // 比較なし
      if (this.$store.state.selectedStore && !this.isComparison) {
        this.fetchChartData();
        this.fetchTableData();
      }

      // 比較時
      if (this.$store.state.selectedStore && this.isComparison) {
        this.fetchCompareData();
        this.fetchCompareTableData();
      }
    },
    setDateFromRouterQuery() {
      if (this.hasRouterQueryPeriod) {
        let tmp = getPeriodByRouterQueryPeriod(this.$route.query.period as string);
        if (tmp !== undefined) this.$store.commit("setDate", tmp);
      }
    },
    setCompareDateFromRouterQuery() {
      if (this.hasRouterQueryComparePeriod) {
        let tmp = getPeriodByRouterQueryPeriod(this.$route.query.c_period as string);
        if (tmp !== undefined) this.$store.commit("setCompareDate", tmp);
      }
    },
    setParticleFromRouterQuery() {
      if (!this.hasRouterQueryUnit) return;
      switch (this.$route.query.unit) {
        case QUERY_UNIT.DATE:
          this.particle = PARTICLE_TYPE.DATE;
          return;
        case QUERY_UNIT.WEEK:
          this.particle = PARTICLE_TYPE.WEEK;
          return;
        case QUERY_UNIT.MONTH:
          this.particle = PARTICLE_TYPE.MONTH;
          return;
        default:
          // CHECK: 不正のパラメータを受け取った場合はエラー等投げるか
          return;
      }
    },
    setSubTypeFromRouterQuery() {
      if (!this.hasRouterQuerySubIndicator) return;
      switch (this.$route.query.sub_indicator) {
        case QUERY_SUB_INDICATOR.NONE:
          this.subType = SUB_TYPE.NONE;
          return;
        case QUERY_SUB_INDICATOR.GENDER:
          this.subType = SUB_TYPE.GENDER;
          return;
        case QUERY_SUB_INDICATOR.AGE:
          this.subType = SUB_TYPE.AGE;
          return;
        default:
          // CHECK: 不正のパラメータを受け取った場合はエラー等投げるか
          return;
      }
    },
    fetchChartData() {
      this.chartLoading = true;
      this.chartData.splice(0, 0);

      let startPeriod: string, endPeriod: string;
      switch (this.particle) {
        case PARTICLE_TYPE.DATE:
          // 日別
          startPeriod = this.$store.state.startDate;
          endPeriod = this.$store.state.endDate;
          break;
        case PARTICLE_TYPE.WEEK:
          // 週別
          startPeriod = this.$store.getters.startDateOfWeek;
          endPeriod = this.$store.getters.endDateOfWeek;
          break;
        case PARTICLE_TYPE.MONTH:
          // 月別
          startPeriod = this.$store.getters.startDateOfMonth;
          endPeriod = this.$store.getters.endDateOfMonth;
          break;
        default:
          notify.notifyErrorMessage("不正な表示粒度が設定されています。");
          return;
      }
      getVisitCountGraph(
        this.selectedStore?.storeId,
        startPeriod,
        endPeriod,
        this.particle,
        this.subType
      )
        .then((res: AxiosResponse<VisitCountGraph>) => {
          this.chartData = processVisitorChartData(res.data, this.particle);
        })
        .catch(() => {
          notify.notifyErrorMessage("来店人数推移が表示できませんでした。");
        })
        .finally(() => {
          this.chartLoading = false;
        });
    },
    fetchTableData() {
      this.tableLoading = true;
      this.totalData = undefined;
      this.tableData.splice(0, 0);

      let startPeriod: string, endPeriod: string;
      switch (this.particle) {
        case PARTICLE_TYPE.DATE:
          startPeriod = this.$store.state.startDate;
          endPeriod = this.$store.state.endDate;
          break;
        case PARTICLE_TYPE.WEEK: {
          startPeriod = this.$store.getters.startDateOfWeek;
          endPeriod = this.$store.getters.endDateOfWeek;
          break;
        }
        case PARTICLE_TYPE.MONTH:
          startPeriod = this.$store.getters.startDateOfMonth;
          endPeriod = this.$store.getters.endDateOfMonth;
          break;
        default:
          notify.notifyErrorMessage("不正な表示粒度が設定されています。");
          return;
      }
      getVisitCountTable(
        this.selectedStore?.storeId,
        startPeriod,
        endPeriod,
        this.particle
      )
        .then((res: AxiosResponse<VisitCountTable>) => {
          this.totalData = processingVisitorTotalData(res.data);
          this.tableData = processingVisitorTableData(res.data, this.particle);
        })
        .catch(() => {
          notify.notifyErrorMessage("来店人数推移の表が表示できませんでした。");
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    fetchCompareData() {
      this.chartLoading = true;
      this.compareChartData.splice(0, 0);

      let baseStartPeriod: string,
        baseEndPeriod: string,
        comparisonStartPeriod: string,
        comparisonEndPeriod: string;
      switch (this.particle) {
        case 1:
          // 日別
          baseStartPeriod = this.$store.state.startDate;
          baseEndPeriod = this.$store.state.endDate;
          comparisonStartPeriod = this.$store.state.compareStartDate;
          comparisonEndPeriod = this.$store.state.compareEndDate;
          break;
        case 2: {
          // 週別
          baseStartPeriod = this.$store.getters.startDateOfWeek;
          baseEndPeriod = this.$store.getters.endDateOfWeek;
          comparisonStartPeriod = this.$store.getters.comparisonStartDateOfWeek;
          comparisonEndPeriod = this.$store.getters.comparisonEndDateOfWeek;
          break;
        }
        case 3:
          // 月別
          baseStartPeriod = this.$store.getters.startDateOfMonth;
          baseEndPeriod = this.$store.getters.endDateOfMonth;
          comparisonStartPeriod =
            this.$store.getters.comparisonStartDateOfMonth;
          comparisonEndPeriod = this.$store.getters.comparisonEndDateOfMonth;
          break;
        default:
          notify.notifyErrorMessage("不正な表示粒度が設定されています。");
          return;
      }
      const baseChart = getVisitCountGraph(
        this.selectedStore?.storeId,
        baseStartPeriod,
        baseEndPeriod,
        this.particle,
        SUB_TYPE.NONE
      );
      const comparisonChart = getVisitCountGraph(
        this.selectedStore?.storeId,
        comparisonStartPeriod,
        comparisonEndPeriod,
        this.particle,
        SUB_TYPE.NONE
      );
      Promise.all([baseChart, comparisonChart])
        .then((res: AxiosResponse<VisitCountGraph>[]) => {
          this.compareChartData = processVisitorComparisonChartData(
            res[0].data,
            res[1].data,
            this.particle
          );
        })
        .catch(() => {
          notify.notifyErrorMessage(
            "来店人数推移（期間比較）が表示できませんでした。"
          );
        })
        .finally(() => {
          this.chartLoading = false;
        });
    },
    // 期間比較テーブルの仮表示用メソッド
    fetchCompareTableData() {
      this.tableLoading = true;
      this.tableData.splice(0, 0);

      let baseStartPeriod: string,
        baseEndPeriod: string,
        comparisonStartPeriod: string,
        comparisonEndPeriod: string;
      switch (this.particle) {
        case 1:
          // 日別
          baseStartPeriod = this.$store.state.startDate;
          baseEndPeriod = this.$store.state.endDate;
          comparisonStartPeriod = this.$store.state.compareStartDate;
          comparisonEndPeriod = this.$store.state.compareEndDate;
          break;
        case 2: {
          // 週別
          baseStartPeriod = this.$store.getters.startDateOfWeek;
          baseEndPeriod = this.$store.getters.endDateOfWeek;
          comparisonStartPeriod = this.$store.getters.comparisonStartDateOfWeek;
          comparisonEndPeriod = this.$store.getters.comparisonEndDateOfWeek;
          break;
        }
        case 3:
          // 月別
          baseStartPeriod = this.$store.getters.startDateOfMonth;
          baseEndPeriod = this.$store.getters.endDateOfMonth;
          comparisonStartPeriod =
            this.$store.getters.comparisonStartDateOfMonth;
          comparisonEndPeriod = this.$store.getters.comparisonEndDateOfMonth;
          break;
        default:
          notify.notifyErrorMessage("不正な表示粒度が設定されています。");
          return;
      }
      getVisitCountComparisonGraph(
        this.selectedStore?.storeId,
        [
          {
            start: baseStartPeriod,
            end: baseEndPeriod,
          },
          {
            start: comparisonStartPeriod,
            end: comparisonEndPeriod,
          },
        ],
        this.particle
      )
        .then((res: AxiosResponse<VisitCountComparisonTable>) => {
          // 初期化
          this.compareTableData.length = 0;
          if (
            res.data.visitCounts.some((visitCount) => {
              return (
                [
                  visitCount.total,
                  visitCount.female,
                  visitCount.male,
                  visitCount.teen,
                  visitCount.twenties,
                  visitCount.thirties,
                  visitCount.forties,
                  visitCount.fifties,
                  visitCount.sixties,
                ] as unknown[]
              ).includes(null);
            })
          )
            throw new Error("来店人数推移の表（期間比較）の値が不正です");
          this.compareTableData.push({
            index: 1,
            shopId: this.$store.state.selectedStore.storeId,
            shopName: this.$store.state.selectedStore.name,
            baseData: res.data.visitCounts[0],
            compareData: res.data.visitCounts[1],
          });
        })
        .catch(() => {
          notify.notifyErrorMessage(
            "来店人数推移の表（期間比較）が表示できませんでした。"
          );
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },

    /**
     * datePicker コンポーネントから更新する用のメソッド
     */
    async updatePeriod(period: {
      startDate: string;
      endDate: string;
      compareStartDate: string;
      compareEndDate: string;
    }) {
      this.$store.commit("setDate", {
        startDate: period.startDate,
        endDate: period.endDate,
      });
      this.$store.commit("setCompareDate", {
        startDate: period.compareStartDate,
        endDate: period.compareEndDate,
      });
      await this.pushRoute();
      await this.fetchAll();
    },
    async pushRoute() {
      if (!this.$store.state.selectedStore) return;

      const tmp = {
        start: convertHyphenDelimiter(this.$store.state.startDate),
        end: convertHyphenDelimiter(this.$store.state.endDate),
        compareStart: convertHyphenDelimiter(
          this.$store.state.compareStartDate
        ),
        compareEnd: convertHyphenDelimiter(this.$store.state.compareEndDate),
        unit:
          this.particle === PARTICLE_TYPE.DATE
            ? QUERY_UNIT.DATE
            : this.particle === PARTICLE_TYPE.WEEK
            ? QUERY_UNIT.WEEK
            : QUERY_UNIT.MONTH,
        subIndicator:
          this.subType === SUB_TYPE.NONE
            ? QUERY_SUB_INDICATOR.NONE
            : this.subType === SUB_TYPE.GENDER
            ? QUERY_SUB_INDICATOR.GENDER
            : QUERY_SUB_INDICATOR.AGE,
      };

      if (!this.$store.getters.isCompare) {
        // NOTE: 他ページに遷移後にreplaceが呼び出されると戻される対策
        if (this.$route.name === "ShopAnalyticsVisitor") {
          await this.$router
            .replace({
              name: "ShopAnalyticsVisitor",
              params: { id: this.$store.state.selectedStore.storeId },
              query: {
                period: `${tmp.start}_${tmp.end}`,
                unit: tmp.unit,
                sub_indicator: tmp.subIndicator,
              },
            })
            .catch(() => undefined);
        }
      } else {
        // NOTE: 他ページに遷移後にreplaceが呼び出されると戻される対策
        if (this.$route.name === "ShopAnalyticsVisitor") {
          await this.$router
            .replace({
              name: "ShopAnalyticsVisitor",
              params: { id: this.$store.state.selectedStore.storeId },
              query: {
                period: `${tmp.start}_${tmp.end}`,
                c_period: `${tmp.compareStart}_${tmp.compareEnd}`,
                unit: tmp.unit,
                sub_indicator: tmp.subIndicator,
              },
            })
            .catch(() => undefined);
        }
      }
    },
  },
});
</script>

<style scoped>
.v-btn {
  color: rgba(0, 0, 0, 0.87) !important;
  background: #f5f5f5 !important;
  opacity: 0.8 !important;
}

.v-btn--active {
  color: #fff !important;
  background: #0e182e !important;
  opacity: 1 !important;
}

.divider {
  border-bottom: 5px solid #eee;
  margin: 40px -30px;
}

.download-button {
  display: flex;
  align-items: center;
  margin-left: 29.4px;
}

.ml-30px {
  margin-left: 30px;
}
</style>
