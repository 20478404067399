import { axiosClient } from "@/plugins/axios";
import { AxiosResponse } from "axios";
import { convertHyphenDelimiter } from "@/commons/utils/dateUtil";
import {
  AgeGenderResponse,
  BehavioralDnaResponse,
} from "@/features/ChainAnalytics/persona/interfaces/response";
import { DownloadResponse } from "@/commons/interfaces/responses/download";

/**
 * 性別年代構成チャート取得
 * @param {string} chainId チェーンID
 * @param {string} start 期間開始日(yyyy-MM-dd)
 * @param {string} end 期間終了日(yyyy-MM-dd)
 * @param {Array<number>} prefectureIds 都道府県ID群
 * @param {AbortSignal} signal APIの中断用オブジェクト
 */
export function getAgeGenderChart(
  chainId: string | undefined,
  start: string,
  end: string,
  prefectureIds: number[],
  signal?: AbortSignal
): Promise<AxiosResponse<AgeGenderResponse>> {
  if (typeof chainId === "undefined") return Promise.reject();
  return axiosClient.get<AgeGenderResponse>("/chains/chart/age-gender/", {
    params: {
      chainId,
      start: convertHyphenDelimiter(start),
      end: convertHyphenDelimiter(end),
      prefectureIds: prefectureIds
    },
    signal: signal,
  });
}

/**
 * 性別年代構成チャートDL用URL生成
 * @param {string} chainId チェーンID
 * @param {string} start 期間開始日(yyyy-MM-dd)
 * @param {string} end 期間終了日(yyyy-MM-dd)
 * @param {Array<number>} prefectureIds 都道府県ID群
 */
export function downloadAgeGenderChart(
  chainId: string | undefined,
  start: string,
  end: string,
  prefectureIds: number[]
): Promise<AxiosResponse> {
  if (typeof chainId === "undefined") return Promise.reject();
  return axiosClient.get<DownloadResponse>(
    "/chains/chart/age-gender/download/",
    {
      params: {
        chainId,
        start: convertHyphenDelimiter(start),
        end: convertHyphenDelimiter(end),
        prefectureIds: prefectureIds
      },
    }
  );
}

/**
 * 期間比較年代構成チャートDL用URL生成
 * @param {string} chainId チェーンID
 * @param {Array.<Object>} period 期間
 * @param {string} period.start 期間開始日(yyyy-MM-dd)
 * @param {string} period.end 期間開始日(yyyy-MM-dd)
 * @param {Array<number>} prefectureIds 都道府県ID群
 */
export function downloadAgeGenderCompareChart(
  chainId: string | undefined,
  period: {
    start: string;
    end: string;
  }[],
  prefectureIds: number[]
): Promise<AxiosResponse> {
  if (typeof chainId === "undefined") return Promise.reject();

  period.forEach((item: { start: string; end: string }) => {
    item.start = convertHyphenDelimiter(item.start);
    item.end = convertHyphenDelimiter(item.end);
  });
  return axiosClient.post<DownloadResponse>(
    "/chains/chart/age-gender-comparison/download/",
    {
      chainId,
      period: period,
      prefectureIds: prefectureIds
    }
  );
}

/**
 * 行動DNAチャート取得
 * @param {string} chainId チェーンID
 * @param {string} start 期間開始日(yyyy-MM-dd)
 * @param {string} end 期間終了日(yyyy-MM-dd)
 * @param {Array<number>} prefectureIds 都道府県ID群
 * @param {AbortSignal} signal APIの中断用オブジェクト
 */
export function getBehavioralDnaChart(
  chainId: string | undefined,
  start: string,
  end: string,
  prefectureIds: number[],
  signal?: AbortSignal
): Promise<AxiosResponse<BehavioralDnaResponse>> {
  if (typeof chainId === "undefined") return Promise.reject();
  return axiosClient.get<BehavioralDnaResponse>(
    "/chains/chart/behavioral-dna/",
    {
      params: {
        chainId,
        start: convertHyphenDelimiter(start),
        end: convertHyphenDelimiter(end),
        prefectureIds: prefectureIds
      },
      signal: signal,
    }
  );
}

/**
 * 期間比較行動DNAチャート取得
 * @param {string} chainId チェーンID
 * @param {Array.<Object>} period 期間
 * @param {string} period.start 期間開始日(yyyy-MM-dd)
 * @param {string} period.end 期間開始日(yyyy-MM-dd)
 * @param {Array<number>} prefectureIds 都道府県ID群
 */
export function getBehavioralDnaCompareChart(
  chainId: string | undefined,
  period: {
    start: string;
    end: string;
  }[],
  prefectureIds: number[]
): Promise<AxiosResponse> {
  if (typeof chainId === "undefined") return Promise.reject();

  period.forEach((item: { start: string; end: string }) => {
    item.start = convertHyphenDelimiter(item.start);
    item.end = convertHyphenDelimiter(item.end);
  });
  return axiosClient.post<BehavioralDnaResponse>(
    "/chains/chart/behavioral-dna-comparison/",
    {
      chainId,
      period: period,
      prefectureIds: prefectureIds
    }
  );
}

/**
 * 行動DNAチャートDL用URL生成
 * @param {string} chainId チェーンID
 * @param {string} start 期間開始日(yyyy-MM-dd)
 * @param {string} end 期間終了日(yyyy-MM-dd)
 * @param {number} level 取得する行動DNAの粒度
 * @param {Array<number>} prefectureIds 都道府県ID群
 */
export function downloadBehavioralDnaChart(
  chainId: string | undefined,
  start: string,
  end: string,
  level: number,
  prefectureIds: number[]
): Promise<AxiosResponse> {
  if (typeof chainId === "undefined") return Promise.reject();
  return axiosClient.get<DownloadResponse>(
    "/chains/chart/behavioral-dna/download/",
    {
      params: {
        chainId,
        start: convertHyphenDelimiter(start),
        end: convertHyphenDelimiter(end),
        level: level,
        prefectureIds: prefectureIds
      },
    }
  );
}

/**
 * 期間比較行動DNAチャートDL用URL生成
 * @param {string} chainId チェーンID
 * @param {Array.<Object>} period 期間
 * @param {string} period.start 期間開始日(yyyy-MM-dd)
 * @param {string} period.end 期間開始日(yyyy-MM-dd)
 * @param {number} level 取得する行動DNAの粒度
 * @param {Array<number>} prefectureIds 都道府県ID群
 */
export function downloadBehavioralDnaCompareChart(
  chainId: string | undefined,
  period: {
    start: string;
    end: string;
  }[],
  level: number,
  prefectureIds: number[]
): Promise<AxiosResponse> {
  if (typeof chainId === "undefined") return Promise.reject();

  period.forEach((item: { start: string; end: string }) => {
    item.start = convertHyphenDelimiter(item.start);
    item.end = convertHyphenDelimiter(item.end);
  });
  return axiosClient.post<DownloadResponse>(
    "/chains/chart/behavioral-dna-comparison/download/",
    {
      chainId,
      period: period,
      level: level,
      prefectureIds: prefectureIds
    }
  );
}
