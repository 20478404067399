<template>
  <div style="padding: 5px 0">
    <v-data-table
      :items="items"
      density="comfortable"
    >
      <template #headers>
        <tr>
          <th
            rowspan="2"
            class="bg-gray font-bold text-black"
          />
          <th
            rowspan="2"
            class="bg-gray font-bold text-center text-black"
          >
            来店人数合計
          </th>
          <th
            colspan="2"
            class="bg-gray font-bold text-black"
          >
            性別
          </th>
          <th
            colspan="6"
            class="bg-gray font-bold text-black"
          >
            年代
          </th>
        </tr>
        <tr>
          <th class="bg-gray font-bold text-center text-black">
            男性
          </th>
          <th class="bg-gray font-bold text-center text-black">
            女性
          </th>
          <th class="bg-gray font-bold text-center text-black">
            10代
          </th>
          <th class="bg-gray font-bold text-center text-black">
            20代
          </th>
          <th class="bg-gray font-bold text-center text-black">
            30代
          </th>
          <th class="bg-gray font-bold text-center text-black">
            40代
          </th>
          <th class="bg-gray font-bold text-center text-black">
            50代
          </th>
          <th class="bg-gray font-bold text-center text-black">
            60代〜
          </th>
        </tr>
      </template>
      <template #body="props">
        <template
          v-for="item in props.items"
          :key="item.shopName"
        >
          <!-- 基準期間 -->
          <tr name="{{shopName}}_row2">
            <th
              class="text-left font-normal"
              style="width: 14em"
            >
              <span>
                {{ convertSlash(item.baseData.period) }}
              </span>
            </th>
            <th class="text-right">
              <span class="font-normal">
                {{ item.baseData.total.toLocaleString() }}
              </span>
            </th>
            <th class="text-right font-normal">
              <span>
                {{ item.baseData.male.toLocaleString() }}
              </span>
              <span class="text-gray">
                <br>({{
                  calculatePer(item.baseData.male, item.baseData.total)
                }})
              </span>
            </th>
            <th class="text-right font-normal">
              <span>
                {{ item.baseData.female.toLocaleString() }}
              </span>
              <span class="text-gray">
                <br>({{
                  calculatePer(item.baseData.female, item.baseData.total)
                }})
              </span>
            </th>
            <th class="text-right font-normal">
              <span>
                {{ item.baseData.teen.toLocaleString() }}
              </span>
              <span class="text-gray">
                <br>({{
                  calculatePer(item.baseData.teen, item.baseData.total)
                }})
              </span>
            </th>
            <th class="text-right font-normal">
              <span>
                {{ item.baseData.twenties.toLocaleString() }}
              </span>
              <span class="text-gray">
                <br>({{
                  calculatePer(item.baseData.twenties, item.baseData.total)
                }})
              </span>
            </th>
            <th class="text-right font-normal">
              <span>
                {{ item.baseData.thirties.toLocaleString() }}
              </span>
              <span class="text-gray">
                <br>({{
                  calculatePer(item.baseData.thirties, item.baseData.total)
                }})
              </span>
            </th>
            <th class="text-right font-normal">
              <span>
                {{ item.baseData.forties.toLocaleString() }}
              </span>
              <span class="text-gray">
                <br>({{
                  calculatePer(item.baseData.forties, item.baseData.total)
                }})
              </span>
            </th>
            <th class="text-right font-normal">
              <span>
                {{ item.baseData.fifties.toLocaleString() }}
              </span>
              <span class="text-gray">
                <br>({{
                  calculatePer(item.baseData.fifties, item.baseData.total)
                }})
              </span>
            </th>
            <th class="text-right font-normal">
              <span>
                {{ item.baseData.sixties.toLocaleString() }}
              </span>
              <span class="text-gray">
                <br>({{
                  calculatePer(item.baseData.sixties, item.baseData.total)
                }})
              </span>
            </th>
          </tr>
          <!-- 比較期間 -->
          <tr name="{{shopName}}_row3">
            <th
              class="text-left font-normal"
              style="width: 14em"
            >
              <span>
                {{ convertSlash(item.compareData.period) }}
              </span>
            </th>
            <th class="text-right">
              <span class="font-normal">
                {{ item.compareData.total.toLocaleString() }}
              </span>
            </th>
            <th class="text-right font-normal">
              <span>
                {{ item.compareData.male.toLocaleString() }}
              </span>
              <span class="text-gray">
                <br>({{
                  calculatePer(item.compareData.male, item.compareData.total)
                }})
              </span>
            </th>
            <th class="text-right font-normal">
              <span>
                {{ item.compareData.female.toLocaleString() }}
              </span>
              <span class="text-gray">
                <br>({{
                  calculatePer(
                    item.compareData.female,
                    item.compareData.total
                  )
                }})
              </span>
            </th>
            <th class="text-right font-normal">
              <span>
                {{ item.compareData.teen.toLocaleString() }}
              </span>
              <span class="text-gray">
                <br>({{
                  calculatePer(item.compareData.teen, item.compareData.total)
                }})
              </span>
            </th>
            <th class="text-right font-normal">
              <span>
                {{ item.compareData.twenties.toLocaleString() }}
              </span>
              <span class="text-gray">
                <br>({{
                  calculatePer(
                    item.compareData.twenties,
                    item.compareData.total
                  )
                }})
              </span>
            </th>
            <th class="text-right font-normal">
              <span>
                {{ item.compareData.thirties.toLocaleString() }}
              </span>
              <span class="text-gray">
                <br>({{
                  calculatePer(
                    item.compareData.thirties,
                    item.compareData.total
                  )
                }})
              </span>
            </th>
            <th class="text-right font-normal">
              <span>
                {{ item.compareData.forties.toLocaleString() }}
              </span>
              <span class="text-gray">
                <br>({{
                  calculatePer(
                    item.compareData.forties,
                    item.compareData.total
                  )
                }})
              </span>
            </th>
            <th class="text-right font-normal">
              <span>
                {{ item.compareData.fifties.toLocaleString() }}
              </span>
              <span class="text-gray">
                <br>({{
                  calculatePer(
                    item.compareData.fifties,
                    item.compareData.total
                  )
                }})
              </span>
            </th>
            <th class="text-right font-normal">
              <span>
                {{ item.compareData.sixties.toLocaleString() }}
              </span>
              <span class="text-gray">
                <br>({{
                  calculatePer(
                    item.compareData.sixties,
                    item.compareData.total
                  )
                }})
              </span>
            </th>
          </tr>
          <tr name="{{shopName}}_row4">
            <th class="font-bold text-center">
              変化率
            </th>
            <th class="text-right">
              {{
                calculateGrowthRate(
                  item.baseData.total,
                  item.compareData.total
                )
              }}
            </th>
            <th class="font-bold text-right">
              {{
                calculateGrowthRate(item.baseData.male, item.compareData.male)
              }}
            </th>
            <th class="font-bold text-right">
              {{
                calculateGrowthRate(
                  item.baseData.female,
                  item.compareData.female
                )
              }}
            </th>
            <th class="font-bold text-right">
              {{
                calculateGrowthRate(item.baseData.teen, item.compareData.teen)
              }}
            </th>
            <th class="font-bold text-right">
              {{
                calculateGrowthRate(
                  item.baseData.twenties,
                  item.compareData.twenties
                )
              }}
            </th>
            <th class="font-bold text-right">
              {{
                calculateGrowthRate(
                  item.baseData.thirties,
                  item.compareData.thirties
                )
              }}
            </th>
            <th class="font-bold text-right">
              {{
                calculateGrowthRate(
                  item.baseData.forties,
                  item.compareData.forties
                )
              }}
            </th>
            <th class="font-bold text-right">
              {{
                calculateGrowthRate(
                  item.baseData.fifties,
                  item.compareData.fifties
                )
              }}
            </th>
            <th class="font-bold text-right">
              {{
                calculateGrowthRate(
                  item.baseData.sixties,
                  item.compareData.sixties
                )
              }}
            </th>
          </tr>
        </template>
      </template>
      <template #bottom />
    </v-data-table>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { compareTableItem } from "@/features/ShopAnalytics/interfaces/component";

export default defineComponent({
  name: "CompareTable",
  props: {
    items: {
      type: Array as PropType<compareTableItem[]>, required: true,
    },
  },
  computed: {
    baseVisitorTotal(): number {
      if (this.items.length <= 0) return 0;

      let total = 0;
      this.items.forEach((item: compareTableItem) => {
        total = total + item.baseData.total;
      });
      return total;
    },
    compareVisitorTotal(): number {
      if (this.items.length <= 0) return 0;

      let total = 0;
      this.items.forEach((item: compareTableItem) => {
        total = total + item.compareData.total;
      });
      return total;
    },
    baseMaleTotal(): number {
      if (this.items.length <= 0) return 0;

      let total = 0;
      this.items.forEach((item: compareTableItem) => {
        total = total + item.baseData.male;
      });
      return total;
    },
    compareMaleTotal(): number {
      if (this.items.length <= 0) return 0;

      let total = 0;
      this.items.forEach((item: compareTableItem) => {
        total = total + item.compareData.male;
      });
      return total;
    },
    baseFemaleTotal(): number {
      if (this.items.length <= 0) return 0;

      let total = 0;
      this.items.forEach((item: compareTableItem) => {
        total = total + item.baseData.female;
      });
      return total;
    },
    compareFemaleTotal(): number {
      if (this.items.length <= 0) return 0;

      let total = 0;
      this.items.forEach((item: compareTableItem) => {
        total = total + item.compareData.female;
      });
      return total;
    },
    baseTeenTotal(): number {
      if (this.items.length <= 0) return 0;

      let total = 0;
      this.items.forEach((item: compareTableItem) => {
        total = total + item.baseData.teen;
      });
      return total;
    },
    compareTeenTotal(): number {
      if (this.items.length <= 0) return 0;

      let total = 0;
      this.items.forEach((item: compareTableItem) => {
        total = total + item.compareData.teen;
      });
      return total;
    },
    baseTwentiesTotal(): number {
      if (this.items.length <= 0) return 0;

      let total = 0;
      this.items.forEach((item: compareTableItem) => {
        total = total + item.baseData.twenties;
      });
      return total;
    },
    compareTwentiesTotal(): number {
      if (this.items.length <= 0) return 0;

      let total = 0;
      this.items.forEach((item: compareTableItem) => {
        total = total + item.compareData.twenties;
      });
      return total;
    },
    baseThirtiesTotal(): number {
      if (this.items.length <= 0) return 0;

      let total = 0;
      this.items.forEach((item: compareTableItem) => {
        total = total + item.baseData.thirties;
      });
      return total;
    },
    compareThirtiesTotal(): number {
      if (this.items.length <= 0) return 0;

      let total = 0;
      this.items.forEach((item: compareTableItem) => {
        total = total + item.compareData.thirties;
      });
      return total;
    },
    baseFortiesTotal(): number {
      if (this.items.length <= 0) return 0;

      let total = 0;
      this.items.forEach((item: compareTableItem) => {
        total = total + item.baseData.forties;
      });
      return total;
    },
    compareFortiesTotal(): number {
      if (this.items.length <= 0) return 0;

      let total = 0;
      this.items.forEach((item: compareTableItem) => {
        total = total + item.compareData.forties;
      });
      return total;
    },
    baseFiftiesTotal(): number {
      if (this.items.length <= 0) return 0;

      let total = 0;
      this.items.forEach((item: compareTableItem) => {
        total = total + item.baseData.fifties;
      });
      return total;
    },
    compareFiftiesTotal(): number {
      if (this.items.length <= 0) return 0;

      let total = 0;
      this.items.forEach((item: compareTableItem) => {
        total = total + item.compareData.fifties;
      });
      return total;
    },
    baseSixtiesTotal(): number {
      if (this.items.length <= 0) return 0;

      let total = 0;
      this.items.forEach((item: compareTableItem) => {
        total = total + item.baseData.sixties;
      });
      return total;
    },
    compareSixtiesTotal(): number {
      if (this.items.length <= 0) return 0;

      let total = 0;
      this.items.forEach((item: compareTableItem) => {
        total = total + item.compareData.sixties;
      });
      return total;
    },
  },
  methods: {
    calculatePer(top: number, bottom: number) {
      return ((top / bottom) * 100).toFixed(1) + "%";
    },
    calculateGrowthRate(base: number, comparison: number) {
      // 基準期間に対して比較期間の変化率を求める
      return (((comparison - base) / base) * 100).toFixed(1) + "%";
    },
    /**
     * 期間をスラッシュ区切りに変換する
     * ex. "2022-01-01 - 2022-02-01" → "2022/01/01 - 2022/02/01"
     * @param {string} period "2022-01-01 - 2022-02-01"
     * @returns スラッシュ区切りの期間 "2022/01/01 - 2022/02/01"
     */
    convertSlash(period: string) {
      return period
        .replace(" - ", "$")
        .replaceAll("-", "/")
        .replace("$", " - ");
    },
  },
});
</script>

<style scoped>
.v-data-table :deep(table) {
  border-collapse: collapse !important;
  border: 1px solid #ccc !important;
}
.v-data-table :deep(td) {
  border-collapse: collapse !important;
  border: 1px solid #ccc !important;
}
.v-data-table :deep(th) {
  border-collapse: collapse !important;
  border: 1px solid #ccc !important;
  font-size: 13px !important;
  user-select: text !important;
}
.bg-gray {
  background: #eee;
}
.bg-light-gray {
  background: #f5f5f5;
}
.bg-light-gray:hover {
  background: #f5f5f5;
}
.text-red {
  color: #be0000;
}
.text-blue {
  color: #4d99d0;
}
.text-black {
  color: #333333 !important;
}
.text-gray {
  color: #666666;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.font-bold {
  font-weight: bold !important;
}
.font-normal {
  font-weight: normal !important;
}
.cap {
  font-size: 11px;
}
</style>
