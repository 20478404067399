import { useCookies } from 'vue3-cookies'

import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios'
import { API_URL } from '@/config'
import router from '@/router/index'

declare module 'axios' {
  interface AxiosRequestConfig {
    cancelPreviousRequests?: boolean;
  }
}

const pendingRequests: { [key: string]: AbortController } = {};

const axiosClient: AxiosInstance = axios.create({
  baseURL: API_URL,
  headers: { 'Content-Type': 'application/json' },
  timeout: 0, // unlimited
  withCredentials: true,
  paramsSerializer: { indexes: null }
})

const removePendingRequest = (url: string | undefined, abort = false): void => {
  // pendingRequests にリクエスト URL が含まれているかどうかを確認する
  if (url && pendingRequests[url]) {
    // 進行中の呼出しを中止したい場合は中止します
    if (abort) {
      pendingRequests[url].abort();
    }
    // 保留中のリクエストからリクエスト URL を削除します
    delete pendingRequests[url];
  }
};

axiosClient.interceptors.request.use(
  (config: any) => {
    if (router?.currentRoute.value.meta?.requiredAuth) {
      // 認証が必要なページにのみトークンをheaderに付与
      const { cookies } = useCookies()
      config.headers['X-CSRFToken'] = cookies?.get('csrftoken')
    }

    if (config?.cancelPreviousRequests && config?.url && !config.signal) {
      removePendingRequest(config.url, true);

      const abortController = new AbortController();
      config.signal = abortController.signal;
      pendingRequests[config.url] = abortController;
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

axiosClient.interceptors.response.use(
  (response: AxiosResponse) => {
    removePendingRequest(response.request.responseURL);
    return response;
  },
  (error: AxiosError) => {
    removePendingRequest(error.config?.url);
    throw error;
  }
);

export { axiosClient }
