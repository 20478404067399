import { axiosClient } from "@/plugins/axios";
import { AxiosResponse } from "axios";
import { LoginResponse, AuthResponse } from "@/commons/types/Auth";

/**
 * ログイン
 * @param {Object} arg ログイン情報
 * @param {string} arg.mail メールアドレス
 * @param {string} arg.password パスワード
 */
export function login(
  arg: loginParameter
): Promise<AxiosResponse<LoginResponse>> {
  return axiosClient.post<LoginResponse>(`/login/`, {
    mail: arg.mail,
    password: arg.password,
  });
}

/**
 * ログアウト
 */
export function logout(): Promise<AxiosResponse> {
  return axiosClient.post("/logout/");
}

export function sessionCheck(): Promise<AxiosResponse<AuthResponse>> {
  return axiosClient.get<AuthResponse>(`/login/check/`);
}

export interface loginParameter {
  mail: string;
  password: string;
}
