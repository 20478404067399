<template>
  <div
    class="delete-icon"
    :class="{ 'box-shadow': shadow }"
    :style="`width: ${width}; height: ${height}`"
    v-bind="$attrs"
  >
    <img src="@/assets/svg/trash.svg">
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "DeleteButton",
  props: {
    width: { type: String, default: "40px" },
    height: { type: String, default: "40px" },
    shadow: { type: Boolean, default: false },
  },
});
</script>

<style lang="scss" scoped>
.delete-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #cccccc;
  background-color: #ffffff;
  border-radius: 4px;
  cursor: pointer;
}

.box-shadow {
  box-shadow: 1px 1px 0px #00000029;
}
</style>
