import { RegexTable } from './regex-table'

/**
 * 検索ワードが検索対象にマッチするかを判定する関数
 *
 * **Usage**
 *
 * ```ts
 * containsAllKeywords("hogefuga", "hoge")      // true
 * containsAllKeywords("Hoge", "hoge")          // true
 * containsAllKeywords("hogefuga", "hoge fuga") // true
 * containsAllKeywords("fuga", "hoge")          // false
 * ```
 *
 * **Details**
 *
 * `containsAllKeywords` 関数は、指定された `target` 文字列内に `keyword` が存在するかどうかを大文字小文字を区別せずに検証します。検索ワード `keyword` は、空白で区切られた複数の単語を含むことができ、`target` 内でそれらがすべて見つかる場合のみ `true` を返します。各単語は、英数字や一部の記号を含め、全角・半角の違いを吸収するように `RegexTable` を使用して正規表現に変換された上で検証されます。
 *
 */
export function containsAllKeywords(target: string, keyword: string): boolean {
  const trimmedKeyword = keyword.trim()
  if (trimmedKeyword.length === 0) {
    return false
  }

  let words = trimmedKeyword.split(/\s+/).filter((s) => s.length > 0)
  words = words.map((w) => {
    return w.replace(/[a-zA-Zａ-ｚＡ-Ｚ０-９0-9&＆.*+{}[\]-]/g, (c) => {
      return RegexTable[c] || c
    })
  })

  const regexps = words.map((w) => new RegExp(w, 'i'))

  return regexps.every((regexp) => target.match(regexp) !== null)
}
