import { computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

/**
 * [チェーン比較] チェーンと都道府県の選択状態をクエリパラメータで管理するためのカスタムフック
 *
 * **Usage**
 *
 * ```ts
 * const { chainIds, prefectureIds, setParams } = useChainsQueryParams()
 *
 * console.log(query)         // ?chainIds=hoge&chainIds=fuga&prefectureIds=1&prefectureIds=2
 * console.log(chainIds)       // ["hoge", "fuga"]
 * console.log(prefectureIds) // ["1", "2"]
 *
 * setParams({ chainIds: ["piyo"], prefectureIds: ["3"]})
 *
 * console.log(query)         // ?chainIds=piyo&prefectureIds=3
 * console.log(chainIds)       // piyo
 * console.log(prefectureIds) // ["3"]
 * ```
 *
 * **Details**
 *
 * このカスタムフックはチェーンと都道府県の選択状態をURLのクエリパラメータを通じて管理するためのものです。このフックを使用することで、アプリケーション内でユーザーの選択をURLに同期させ、ブラウザの戻る/進む操作や、URLの直接共有による状態の再現が可能になります。
 *
 */
export default function useChainsQueryParams(shouldUpdateVuex: boolean = true) {
  const route = useRoute()
  const router = useRouter()
  const store = useStore()

  const chainIds = computed<string[]>(() => {
    return Array.isArray(route.query.chainIds)
      ? (route.query.chainIds as string[])
      : ([route.query.chainIds].filter((id) => !!id) as string[])
  })

  const prefectureIds = computed<string[]>(() => {
    let queryPrefectureIds: string[] = []

    if (typeof route.query.prefectureIds === 'string') {
      queryPrefectureIds = [route.query.prefectureIds]
    } else if (Array.isArray(route.query.prefectureIds)) {
      queryPrefectureIds = route.query.prefectureIds as string[]
    }

    return queryPrefectureIds
  })

  const setParams = (params: { chainIds?: string[]; prefectureIds?: string[] }) => {
    if (shouldUpdateVuex) {
      if (params.chainIds) {
        store.commit('setSelectedChainIds', {
          chainIds: params.chainIds
        })
      }

      if (params.prefectureIds) {
        store.commit('setSelectedPrefectureIdsOfChains', {
          prefectureIds: params.prefectureIds.map((id) => Number(id))
        })
      }
    }

    router.push({
      query: {
        ...route.query,
        ...(params.chainIds ? { chainIds: params.chainIds } : {}),
        ...(params.prefectureIds ? { prefectureIds: params.prefectureIds } : {})
      }
    })
  }

  return {
    chainIds,
    prefectureIds,
    setParams
  }
}
