<template>
  <v-container
    class="pa-0"
    fluid
  >
    <v-row
      class="title-container"
      dense
      no-gutters
    >
      <v-col style="display: flex; align-items: center">
        <h1 class="title-text">
          ペルソナ特性
        </h1>
        <update-badge
          type="week"
          style="margin-left: 15px"
        />
      </v-col>
      <v-spacer />
      <date-picker
        :start-date="$store.state.startDate"
        :end-date="$store.state.endDate"
        :compare-start-date="$store.state.compareStartDate"
        :compare-end-date="$store.state.compareEndDate"
        @update-period="updatePeriod"
      />
    </v-row>
    <v-row
      dense
      no-gutters
      class="card-container"
    >
      <v-col>
        <store-selector :loading="storeLoading" />
      </v-col>
    </v-row>
    <v-row
      dense
      no-gutters
      class="card-container"
    >
      <v-col>
        <v-card
          :height="'100%'"
          class="card"
        >
          <v-card-title
            :class="
              !selectedStore || emptyAgeGenderData
                ? 'pa-0 d-flex align-center age-gender-card-title'
                : 'pa-0 d-flex align-center age-gender-card-title age-gender-card-title-margin'
            "
          >
            <span>性別✕年代構成</span>
            <alert-tooltip
              v-if="hasAlertInAgeGender"
              class="ml-2"
              text="取得データボリュームが少なく、統計上の信頼性の低いデータが含まれています。該当箇所は、参考値としてご参照ください。"
            />
            <v-spacer />
            <chart-description-tooltip
              menu-key="analytics"
              sub-menu-key="persona"
              chart-key="genderAgeStructure"
              class="mr-27px"
            />
            <download-button
              label="CSVデータ"
              :disabled="!selectedStore"
              :get-file-id="getAgeGenderFileId"
              :csv-name="ageGenderCSVName"
            />
          </v-card-title>
          <v-row
            class="my-0 py-0"
            dense
          >
            <v-col
              class="py-0"
              style="position: relative"
            >
              <div
                v-show="!selectedStore"
                class="unselected_card"
              >
                店舗を選択するとチャートが表示されます。
              </div>
              <div v-show="selectedStore">
                <LoadingImg
                  v-if="ageGenderChartLoading"
                  :height="'425px'"
                />
                <no-data-chart v-else-if="emptyAgeGenderData" />
                <div v-else>
                  <stacked-pyramid-chart
                    v-if="isComparison"
                    :chart-data="baseChartData"
                    :max-value="comparePyramidMaxValue"
                    :has-alert="
                      !ageGenderChartLoading &&
                        hasAlertInAgeGender &&
                        !approveAlertInAgeGender
                    "
                    @click-approve-alert="clickApproveAlertInAgeGender"
                  />
                  <pyramid-chart
                    v-else
                    :chart-data="chartData"
                    :max-value="maxValue"
                    :has-alert="
                      !ageGenderChartLoading &&
                        hasAlertInAgeGender &&
                        !approveAlertInAgeGender
                    "
                    @click-approve-alert="clickApproveAlertInAgeGender"
                  />
                </div>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row
      dense
      no-gutters
      class="card-container mb-0"
    >
      <v-col>
        <BehavioralDNACard
          ref="behavioralDNACard"
          :chart-data="behavioralDnaData"
          :chart-loading="behavioralDnaChartLoading"
          :third-categories="thirdCategoryDatas"
          :is-empty="emptyBehavioralDnaData"
          :get-l2-file-id="getBehavioralDnaL2FileId"
          :get-l3-file-id="getBehavioralDnaL3FileId"
          :l2-c-s-v-name="behavioralDnaL2CSVName"
          :l3-c-s-v-name="behavioralDnaL3CSVName"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DatePicker from "@/features/ShopAnalytics/components/Common/datePicker.vue";
import StoreSelector from "@/commons/components/StoreSelector/StoreSelector.vue";
import BehavioralDNACard from "@/features/ShopAnalytics/components/Persona/BehavioralDNACard.vue";
import PyramidChart from "@/features/ShopAnalytics/components/Persona/pyramidChart.vue";
import StackedPyramidChart from "@/features/ShopAnalytics/components/Persona/stackedPyramidChart.vue";
import UpdateBadge from "@/features/ShopAnalytics/components/Common/updateBadge.vue";
import NoDataChart from "@/features/ShopAnalytics/components/Common/noDataChart.vue";

// interface
import { SelectionChart } from "@/features/ShopAnalytics/interfaces/component";
import { AxiosResponse } from "axios";
import {
  thirdCategory,
  comparisonThirdCategory,
  AgeGenderResponse,
  BehavioralDnaResponse,
  ComparisonBehavioralDnaResponse,
} from "@/features/ShopAnalytics/interfaces/response";
import { Store } from "@/commons/interfaces/responses/store";
// util
import * as notify from "@/plugins/notification";
import {
  convertHyphenDelimiter,
  convertSlashDelimiter,
} from "@/commons/utils/dateUtil";
import { getPeriodByRouterQueryPeriod } from "@/commons/utils";
import {
  getAgeGenderChartMaxValue,
  processAgeGenderChartData,
  processBehavioralDnaChartData,
  processBehavioralDnaThirdCategoryData,
  processAgeGenderCompareChartData,
  getAgeGenderCompareChartMaxValue,
  processBehavioralDnaCompareChartData,
  hasAlertInAgeGender,
  hasAlertInAgeGenderCompare,
} from "@/features/ShopAnalytics/utils/persona";
// axios
import {
  getAgeGenderChart,
  downloadAgeGenderChart,
  downloadAgeGenderComparisonChart,
  getBehavioralDnaChart,
  getBehavioralDnaComparisonChart,
  downloadBehavioralDnaChart,
  downloadBehavioralDnaComparisonChart,
} from "@/features/ShopAnalytics/axios/persona";
import LoadingImg from "@/commons/components/loadingImg.vue";

export default defineComponent({
  name: "PersonaView",
  components: {
    LoadingImg,
    DatePicker,
    StoreSelector,
    BehavioralDNACard,
    PyramidChart,
    StackedPyramidChart,
    UpdateBadge,
    NoDataChart,
  },
  data() {
    return {
      created: false,
      storeLoading: false,
      particle: 0,
      ageGenderChartLoading: false,
      behavioralDnaChartLoading: false,
      chartData: [] as any[],
      maxValue: 0,
      comparePyramidMaxValue: 0,
      selectChart: null as null | SelectionChart,
      behavioralDnaData: [] as any[],
      thirdCategoryDatas: [] as {
        thirdCategory: thirdCategory[] | comparisonThirdCategory[];
        firstCategoryName: string;
        secondCategoryName: string;
      }[],
      baseChartData: [] as any[],
      subParticle: 0,
      compareSelectChart: null as null | SelectionChart,
      hasAlertInAgeGender: false,
      approveAlertInAgeGender: false,
    };
  },
  computed: {
    selectedStore(): Store | null {
      return this.$store.state.selectedStore;
    },
    isComparison(): boolean {
      return (
        this.$store.state.compareStartDate.length !== 0 &&
        this.$store.state.compareEndDate.length !== 0
      );
    },
    hasRouterQueryPeriod(): boolean {
      return this.$route.query.period !== undefined;
    },
    hasRouterQueryComparePeriod(): boolean {
      return this.$route.query.c_period !== undefined;
    },
    emptyAgeGenderData(): boolean {
      if (this.isComparison) {
        if (this.baseChartData.length === 0) return true;
        // 1行目はヘッダー行なので無視
        for (let i = 1; i < this.baseChartData.length; i++) {
          if (
            this.baseChartData[i][1] !== 0 ||
            this.baseChartData[i][3] !== 0 ||
            this.baseChartData[i][5] !== 0 ||
            this.baseChartData[i][7] !== 0
          )
            return false;
        }
        return true;
      } else {
        if (this.chartData.length === 0) return true;
        // 1行目はヘッダー行なので無視
        for (let i = 1; i < this.chartData.length; i++) {
          if (this.chartData[i][1] !== 0 || this.chartData[i][3] !== 0)
            return false;
        }
        return true;
      }
    },
    emptyBehavioralDnaData(): boolean {
      return this.behavioralDnaData.length <= 1;
    },
    getAgeGenderFileId(): () => Promise<AxiosResponse<any, any>> {
      return () => {
        if (!this.isComparison)
          return downloadAgeGenderChart(
            this.selectedStore?.storeId,
            this.$store.state.startDate,
            this.$store.state.endDate
          );
        else
          return downloadAgeGenderComparisonChart(this.selectedStore?.storeId, [
            {
              start: this.$store.state.startDate,
              end: this.$store.state.endDate,
            },
            {
              start: this.$store.state.compareStartDate,
              end: this.$store.state.compareEndDate,
            },
          ]);
      };
    },
    getBehavioralDnaL2FileId(): () => Promise<AxiosResponse<any, any>> {
      return () => {
        if (!this.isComparison)
          return downloadBehavioralDnaChart(
            this.selectedStore?.storeId,
            this.$store.state.startDate,
            this.$store.state.endDate,
            2
          );
        else
          return downloadBehavioralDnaComparisonChart(
            this.selectedStore?.storeId,
            [
              {
                start: this.$store.state.startDate,
                end: this.$store.state.endDate,
              },
              {
                start: this.$store.state.compareStartDate,
                end: this.$store.state.compareEndDate,
              },
            ],
            2
          );
      };
    },
    getBehavioralDnaL3FileId(): () => Promise<AxiosResponse<any, any>> {
      return () => {
        if (!this.isComparison)
          return downloadBehavioralDnaChart(
            this.selectedStore?.storeId,
            this.$store.state.startDate,
            this.$store.state.endDate,
            3
          );
        else
          return downloadBehavioralDnaComparisonChart(
            this.selectedStore?.storeId,
            [
              {
                start: this.$store.state.startDate,
                end: this.$store.state.endDate,
              },
              {
                start: this.$store.state.compareStartDate,
                end: this.$store.state.compareEndDate,
              },
            ],
            3
          );
      };
    },
    ageGenderCSVName(): string {
      const { start, end, compareStart, compareEnd } = {
        start: convertSlashDelimiter(this.$store.state.startDate),
        end: convertSlashDelimiter(this.$store.state.endDate),
        compareStart: convertSlashDelimiter(this.$store.state.compareStartDate),
        compareEnd: convertSlashDelimiter(this.$store.state.compareEndDate),
      };
      if (!this.isComparison) return `性別✕年代構成_${start}-${end}`;
      else
        return `店舗分析_性別✕年代構成_${start}-${end}_${compareStart}-${compareEnd}`;
    },
    behavioralDnaL2CSVName(): string {
      const { start, end, compareStart, compareEnd } = {
        start: convertSlashDelimiter(this.$store.state.startDate),
        end: convertSlashDelimiter(this.$store.state.endDate),
        compareStart: convertSlashDelimiter(this.$store.state.compareStartDate),
        compareEnd: convertSlashDelimiter(this.$store.state.compareEndDate),
      };
      if (!this.isComparison) return `行動DNA_Level2_${start}-${end}`;
      else
        return `店舗分析_行動DNA_Level2_${start}-${end}_${compareStart}-${compareEnd}`;
    },
    behavioralDnaL3CSVName(): string {
      const { start, end, compareStart, compareEnd } = {
        start: convertSlashDelimiter(this.$store.state.startDate),
        end: convertSlashDelimiter(this.$store.state.endDate),
        compareStart: convertSlashDelimiter(this.$store.state.compareStartDate),
        compareEnd: convertSlashDelimiter(this.$store.state.compareEndDate),
      };
      if (!this.isComparison) return `行動DNA_Level3_${start}-${end}`;
      else
        return `店舗分析_行動DNA_Level3_${start}-${end}_${compareStart}-${compareEnd}`;
    },
  },
  watch: {
    async selectedStore() {
      this.selectChart = null;
      if (this.created) {
        await this.pushRoute();
        await this.fetchAll();
      }
    },
  },
  async created() {
    // クエリパラメータからデータ更新
    this.setDateFromRouterQuery();
    this.setCompareDateFromRouterQuery();

    await this.fetchAll();
    this.created = true;
  },
  methods: {
    resetDnaSelectChart() {
      if (typeof this.$refs.behavioralDNACard !== "undefined") {
        (this.$refs.behavioralDNACard as any).resetSelectChart();
      }
    },
    async fetchAll() {
      // 店舗一覧取得
      this.storeLoading = true;

      if (!this.$store.state.stores.length)
        await this.$store.dispatch("fetchStores");

      // id があれば選択店舗を設定
      if (this.$route.params["id"]) {
        await this.$store.dispatch("specifiedStore", this.$route.params["id"]);
        if (!this.selectedStore) this.$router.push({ name: "NotFound" });
      } else this.$store.commit("initStore");

      this.storeLoading = false;

      // 比較なし
      if (this.$store.state.selectedStore && !this.isComparison) {
        this.selectChart = null;
        this.compareSelectChart = null;
        this.approveAlertInAgeGender = false;
        this.resetDnaSelectChart();
        this.fetchChartData();
        this.fetchBehavioralDnaData();
      }

      // 比較時
      if (this.$store.state.selectedStore && this.isComparison) {
        this.selectChart = null;
        this.compareSelectChart = null;
        this.approveAlertInAgeGender = false;
        this.resetDnaSelectChart();
        this.fetchCompareChartData();
        this.fetchCompareBehavioralDnaData();
      }
    },
    setDateFromRouterQuery() {
      if (this.hasRouterQueryPeriod) {
        let tmp = getPeriodByRouterQueryPeriod(this.$route.query.period as string);
        if (tmp !== undefined) this.$store.commit("setDate", tmp);
      }
    },
    setCompareDateFromRouterQuery() {
      if (this.hasRouterQueryComparePeriod) {
        let tmp = getPeriodByRouterQueryPeriod(this.$route.query.c_period as string);
        if (tmp !== undefined) this.$store.commit("setCompareDate", tmp);
      }
    },
    fetchChartData() {
      this.ageGenderChartLoading = true;
      this.chartData.splice(0, 0);

      getAgeGenderChart(
        this.selectedStore?.storeId,
        this.$store.state.startDate,
        this.$store.state.endDate
      )
        .then((res: AxiosResponse<AgeGenderResponse>) => {
          this.maxValue = getAgeGenderChartMaxValue(res.data);
          this.chartData = processAgeGenderChartData(res.data);
          this.hasAlertInAgeGender = hasAlertInAgeGender(res.data);
        })
        .catch(() => {
          notify.notifyErrorMessage(
            "性別年代別チャートが表示できませんでした。"
          );
        })
        .finally(() => {
          this.ageGenderChartLoading = false;
        });
    },
    fetchBehavioralDnaData() {
      this.behavioralDnaChartLoading = true;
      this.behavioralDnaData.splice(0);
      getBehavioralDnaChart(
        this.selectedStore?.storeId,
        this.$store.state.startDate,
        this.$store.state.endDate
      )
        .then((res: AxiosResponse<BehavioralDnaResponse>) => {
          this.behavioralDnaData = processBehavioralDnaChartData(res.data);
          this.thirdCategoryDatas = processBehavioralDnaThirdCategoryData(
            res.data
          );
        })
        .catch(() => {
          notify.notifyErrorMessage("行動DNAが表示できませんでした。");
        })
        .finally(() => {
          this.behavioralDnaChartLoading = false;
        });
    },

    /*
      TODO
      API が完成し次第、リアクティブにパラメータを更新した上で push するよう修正。
      また、更新するタイミングでこちらの関数を呼び出すようにすること。
    */
    async pushRoute() {
      if (!this.$store.state.selectedStore) return;
      if (!this.$store.getters.isCompare) {
        const tmp = {
          start: convertHyphenDelimiter(this.$store.state.startDate),
          end: convertHyphenDelimiter(this.$store.state.endDate),
        };

        // NOTE: 他ページに遷移後にreplaceが呼び出されると戻される対策
        if (this.$route.name === "ShopAnalyticsPersona") {
          await this.$router
            .replace({
              name: "ShopAnalyticsPersona",
              params: { id: this.$store.state.selectedStore.storeId },
              query: {
                period: `${tmp.start}_${tmp.end}`,
              },
            })
            .catch(() => undefined);
        }
      } else {
        const tmp = {
          start: convertHyphenDelimiter(this.$store.state.startDate),
          end: convertHyphenDelimiter(this.$store.state.endDate),
          compareStart: convertHyphenDelimiter(
            this.$store.state.compareStartDate
          ),
          compareEnd: convertHyphenDelimiter(this.$store.state.compareEndDate),
        };

        // NOTE: 他ページに遷移後にreplaceが呼び出されると戻される対策
        if (this.$route.name === "ShopAnalyticsPersona") {
          await this.$router
            .replace({
              name: "ShopAnalyticsPersona",
              params: { id: this.$store.state.selectedStore.storeId },
              query: {
                period: `${tmp.start}_${tmp.end}`,
                c_period: `${tmp.compareStart}_${tmp.compareEnd}`,
              },
            })
            .catch(() => undefined);
        }
      }
    },

    /**
     * datePicker コンポーネントから更新する用のメソッド
     */
    async updatePeriod(period: {
      startDate: string;
      endDate: string;
      compareStartDate: string;
      compareEndDate: string;
    }) {
      this.$store.commit("setDate", {
        startDate: period.startDate,
        endDate: period.endDate,
      });
      this.$store.commit("setCompareDate", {
        startDate: period.compareStartDate,
        endDate: period.compareEndDate,
      });
      await this.pushRoute();
      await this.fetchAll();
    },
    fetchCompareChartData() {
      this.ageGenderChartLoading = true;

      const baseChart = getAgeGenderChart(
        this.selectedStore?.storeId,
        this.$store.state.startDate,
        this.$store.state.endDate
      );
      const comparisonChart = getAgeGenderChart(
        this.selectedStore?.storeId,
        this.$store.state.compareStartDate,
        this.$store.state.compareEndDate
      );
      Promise.all([baseChart, comparisonChart])
        .then((res: AxiosResponse<AgeGenderResponse>[]) => {
          this.comparePyramidMaxValue = getAgeGenderCompareChartMaxValue(
            res[0].data,
            res[1].data
          );
          this.baseChartData = processAgeGenderCompareChartData(
            this.$store.state.startDate,
            this.$store.state.endDate,
            this.$store.state.compareStartDate,
            this.$store.state.compareEndDate,
            res[0].data,
            res[1].data
          );
          this.hasAlertInAgeGender = hasAlertInAgeGenderCompare(
            res[0].data,
            res[1].data
          );
        })
        .catch(() => {
          notify.notifyErrorMessage(
            "性別年代別チャートが表示できませんでした。"
          );
        })
        .finally(() => {
          this.ageGenderChartLoading = false;
        });
    },
    fetchCompareBehavioralDnaData() {
      this.behavioralDnaChartLoading = true;
      this.behavioralDnaData.splice(0);

      getBehavioralDnaComparisonChart(this.selectedStore?.storeId, [
        {
          start: this.$store.state.startDate,
          end: this.$store.state.endDate,
        },
        {
          start: this.$store.state.compareStartDate,
          end: this.$store.state.compareEndDate,
        },
      ])
        .then((res: AxiosResponse<ComparisonBehavioralDnaResponse>) => {
          this.behavioralDnaData = processBehavioralDnaCompareChartData(
            this.$store.state.startDate,
            this.$store.state.endDate,
            this.$store.state.compareStartDate,
            this.$store.state.compareEndDate,
            res.data
          );
          this.thirdCategoryDatas = processBehavioralDnaThirdCategoryData(
            res.data
          );
        })
        .catch(() => {
          notify.notifyErrorMessage("行動DNAが表示できませんでした。");
        })
        .finally(() => {
          this.behavioralDnaChartLoading = false;
        });
    },
    clickApproveAlertInAgeGender() {
      this.approveAlertInAgeGender = true;
    },
  },
});
</script>

<style lang="scss" scoped>
.age-gender-card-title {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  height: 30px;
  width: 100%;
}

.age-gender-card-title-margin {
  margin-bottom: 10px;
}

.age-gender-card-title span {
  font-size: 16px;
  font-weight: bold;
  line-height: 30px;
}

.age-gender-card-title a {
  font-size: 12px;
  font-weight: normal;
  color: #4d99d0;
}

.mr-27px {
  margin-right: 27px;
}
</style>
