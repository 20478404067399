<template>
  <v-container
    class="pa-0 view-container"
    fluid
  >
    <div class="inner-container">
      <div class="view-title">
        <div class="title-text">
          {{ companyName }}
        </div>
        <div class="title-sub">
          <div class="sub-head">
            <div>ご利用可能</div>
            <div>アカウント数</div>
          </div>
          <div class="sub-text">
            {{ userLimit }}
          </div>
        </div>
      </div>
      <div class="view-content">
        <my-info
          :user="myUser"
          :user-count="users.length"
          :loading="dataLoading"
          @delete="onClickDeleteButton"
        />
        <div class="divider" />
        <registered-user-table
          :users="users"
          :user-limit="userLimit"
          @add-user="onClickRegisterButton"
          @delete="onClickDeleteButton"
        />
        <div class="registered-user-table-action">
          <custom-button
            class="add-user"
            variant="primary"
            width="280px"
            height="46px"
            :disabled="users.length >= userLimit"
            @click="onClickRegisterButton"
          >
            ＋ユーザーの新規追加
          </custom-button>
        </div>
      </div>
      <div class="view-footer">
        アカウント数の変更を希望される場合は&nbsp;<a
          href="https://mkt.unerry.co.jp/form/inquirymieru"
          target="_blank"
          rel="noopener noreferrer"
        >お問い合わせフォーム</a>&nbsp;よりご連絡ください。
      </div>
      <v-dialog
        v-model="showDeleteDialog"
        height="220"
        width="500"
        @click:outside="showDeleteDialog = false"
      >
        <div class="dialog-content">
          <div class="dialog-text">
            このユーザー（{{ deleteUserName }}）を<br>削除してよろしいですか？
          </div>
          <custom-button
            class="dialog-action"
            variant="danger"
            width="280px"
            height="46px"
            :disabled="deleteLoading"
            @click="deleteUser"
          >
            このユーザーを削除
          </custom-button>
        </div>
      </v-dialog>
    </div>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";

import MyInfo from "@/features/UserSetting/components/Top/MyInfo.vue";
import RegisteredUserTable from "@/features/UserSetting/components/Top/RegisteredUserTable.vue";

import { getUsers, deleteUser } from "@/features/UserSetting/axios";
import * as notify from "@/plugins/notification";
import {
  RegisteredUsersResponse,
  UserResponse,
} from "@/features/UserSetting/interfaces/response";
import { AxiosResponse } from "axios";

export default defineComponent({
  name: "UserSettingView",
  components: {
    MyInfo,
    RegisteredUserTable,
  },
  data() {
    return {
      companyName: undefined as string | undefined,
      userLimit: 0,
      myUser: undefined as UserResponse | undefined,
      users: [] as UserResponse[],
      showDeleteDialog: false,
      deleteUserId: undefined as string | undefined,
      deleteUserName: undefined as string | undefined,
      deleteLoading: false,
      dataLoading: false,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      this.dataLoading = true;
      getUsers()
        .then((res: AxiosResponse<RegisteredUsersResponse>) => {
          this.companyName = res.data.company.name;
          this.userLimit = res.data.company.userLimit;
          this.myUser = res.data.my_user;
          this.users = res.data.users;
        })
        .catch(() => {
          notify.notifyErrorMessage("ユーザー一覧の取得に失敗しました。");
        })
        .finally(() => {
          this.dataLoading = false;
        });
    },
    onClickRegisterButton() {
      this.$router.push({ name: "UserCreate" });
    },
    onClickDeleteButton(userId: string) {
      this.deleteUserId = userId;
      this.deleteUserName = this.users.find(
        (user: UserResponse) => user.userId === userId
      )?.name;
      this.showDeleteDialog = true;
    },
    deleteUser() {
      if (typeof this.deleteUserId === "undefined") return;

      this.deleteLoading = true;
      deleteUser(this.deleteUserId)
        .then(() => {
          // 削除対象がログインユーザーの場合はリロードを行い、ログアウトかつ Vuex の初期化を行う
          if (this.myUser?.userId === this.deleteUserId) this.$router.go(0);

          notify.notifySuccessMessage("指定のユーザーを削除しました。");
          this.getData();
        })
        .catch(() => {
          notify.notifyErrorMessage("指定のユーザーの削除に失敗しました。");
        })
        .finally(() => {
          this.deleteLoading = false;
          this.showDeleteDialog = false;
        });
    },
  },
});
</script>

<style lang="scss" scoped>
.view-container {
  min-width: 1180px;
  box-shadow: 0px 0px 3px #00000029;
}
.inner-container {
  margin-top: 30px;
  background-color: #ffffff;
  overflow: hidden;
}
.view-title {
  height: 100px;
  background: #0e182e 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 3px #00000029;
  opacity: 1;
  display: flex;
  align-items: center;
}
.title-text {
  min-width: 925px;
  width: 100%;
  color: #ffffff;
  margin-left: 40px;
  overflow-wrap: break-word;
  float: left;
}
.title-sub {
  display: flex;
  color: #ffffff;
  min-width: 210px;
  margin-right: 30px;
  align-items: center;
  float: right;
}
.sub-head {
  text-align: end;
  margin-right: 19px;
  font-size: 13px;
  min-width: 78px;
}
.sub-text {
  text-align: center;
  font-size: 50px;
  letter-spacing: 0px;
  width: 110px;
}
.view-content {
  padding: 28px 40px 0px 40px;
}
.divider {
  border-bottom: 2px solid #eee;
  margin: 40px 0px 21px 0px;
}
.registered-user-table-action {
  text-align: center;
  .add-user {
    font-size: 15px;
  }
}
.view-footer {
  margin: 30px;
  text-align: center;
  font-size: 13px;
  a {
    color: #4d99d0;
  }
}
.dialog-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 76px;
  width: 500px; // コンポーネントとclassどちらも指定しないと崩れるので指定
  height: 220px; // コンポーネントとclassどちらも指定しないと崩れるので指定
  border-radius: 6px;
  background: white;
  .dialog-text {
    font: normal normal bold 15px/28px Noto Sans JP;
    color: #be0000;
  }
  .dialog-action {
    margin-top: 30px;
  }
}
</style>
