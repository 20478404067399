<template>
  <div class="container">
    <div class="radius_form space">
      <span class="circle" />
      <b>距離目安となる円：</b>
      半径
      <input
        class="form space"
        type="number"
        min="0"
        :value="vRadius"
        @input="$emit('update:vRadius', Number(($event.target as HTMLInputElement).value))"
      >
      km
      <button
        class="button"
        @click="$emit('setVRadius')"
      >
        表示
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "CustomCircle",
  props: {
    vRadius: {
      type: Number as PropType<number | null>,
      default: null,
    },
  },
  emits: ["update:vRadius", "setVRadius"],
});
</script>

<style scoped>
.container {
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  height: 45px;
  width: 375px;
}
.affix {
  width: 2em;
}
.space {
  margin: 0px 10px;
}
.radius_form {
  display: flex;
  font-size: 14px;
  align-items: center;
}
.form {
  height: 30px;
  width: 50px;
  outline: 1px solid #cccccc;
  border-radius: 4px;
  opacity: 1;
  text-align: right;
}
.button {
  margin-left: 10px;
  width: 50px;
  height: 28px;
  background: #222222 0% 0% no-repeat padding-box;
  box-shadow: 1px 1px 0px #00000029;
  border-radius: 4px;
  opacity: 1;
  color: #ffffff;
}
.circle {
  display: inline-block;
  margin-right: 6px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid #de5a69;
  background-color: #de5a69;
}
</style>
