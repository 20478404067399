<template>
  <v-container
    class="pa-0"
    fluid
  >
    <v-row class="ma-0">
      <v-col
        cols="6"
        class="pa-0"
      >
        <v-container class="mb-2 left-legends">
          全体
        </v-container>
        <GChart
          type="LineChart"
          :data="totalChartData"
          :options="
            option({
              height: 700,
              position: 'left',
              max: maxValue + 10,
              min: minValue - 10,
            })
          "
          :events="totalChartEvent"
          @ready="chart => totalChart = chart"
        />
      </v-col>
      <v-col
        cols="3"
        class="pa-0"
      >
        <v-container class="pa-0 mb-2 legends">
          男性
        </v-container>
        <GChart
          type="LineChart"
          :data="maleChartData"
          :options="
            option({
              height: 700,
              position: 'center',
              max: maxValue + 10,
              min: minValue - 10,
            })
          "
          :events="maleChartEvent"
          @ready="chart => maleChart = chart"
        />
      </v-col>
      <v-col
        cols="3"
        class="pa-0"
      >
        <v-container class="pa-0 mb-2 legends">
          女性
        </v-container>
        <GChart
          type="LineChart"
          :data="femaleChartData"
          :options="
            option({
              height: 700,
              position: 'right',
              max: maxValue + 10,
              min: minValue - 10,
            })
          "
          :events="femaleChartEvent"
          @ready="chart => femaleChart = chart"
        />
      </v-col>
    </v-row>
    <v-row
      class="my-2"
      dense
    >
      <v-col class="px-2">
        <div class="my-0 chart-legend">
          <chart-legend :legends="legends" />
          <v-spacer />
          <alert-caption
            v-if="hasl1l2Alert"
            variants="circle"
          />
        </div>
      </v-col>
    </v-row>
    <v-row
      v-if="selectedL1L2Category"
      class="ma-0"
    >
      <v-col class="pa-0">
        <div class="divider" />
        <v-card-title class="px-0 card-title d-flex align-center">
          <span>{{ totalChartData[selectedL1L2Category["row"] + 1][0] }}</span>
          <alert-tooltip
            v-if="hasl3Alert"
            class="ml-2"
            text="取得データボリュームが少なく統計上の信頼性が低い日が含まれています。該当日は参考値としてご参照ください。"
          />
          <v-spacer />
          <download-button
            label="CSVデータ"
            :disabled="!isCSVButtonDisabled"
            :get-file-id="getL3FileId"
            :csv-name="l3CSVName"
          />
        </v-card-title>
      </v-col>
    </v-row>
    <v-row
      v-if="selectedL1L2Category"
      class="ma-0"
    >
      <v-col
        cols="6"
        class="pa-0"
      >
        <v-container class="mb-2 left-legends">
          全体
        </v-container>
        <GChart
          type="LineChart"
          :data="thirdCategoryTotalChartData"
          :options="
            option({
              height: levelThreeBehavioralDNAChartHeight,
              position: 'left',
              max: maxValue + 10,
              min: minValue - 10,
            })
          "
        />
      </v-col>
      <v-col
        cols="3"
        class="pa-0"
      >
        <v-container class="pa-0 mb-2 legends">
          男性
        </v-container>
        <GChart
          type="LineChart"
          :data="thirdCategoryMaleChartData"
          :options="
            option({
              height: levelThreeBehavioralDNAChartHeight,
              position: 'center',
              max: maxValue + 10,
              min: minValue - 10,
            })
          "
        />
      </v-col>
      <v-col
        cols="3"
        class="pa-0"
      >
        <v-container class="pa-0 mb-2 legends">
          女性
        </v-container>
        <GChart
          type="LineChart"
          :data="thirdCategoryFemaleChartData"
          :options="
            option({
              height: levelThreeBehavioralDNAChartHeight,
              position: 'right',
              max: maxValue + 10,
              min: minValue - 10,
            })
          "
        />
      </v-col>
    </v-row>
    <v-row
      v-if="selectedL1L2Category"
      class="my-2"
      dense
    >
      <v-col class="px-2">
        <div class="my-0 chart-legend">
          <chart-legend :legends="legends" />
          <v-spacer />
          <alert-caption
            v-show="hasl3Alert"
            variants="circle"
          />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { GChart } from "vue-google-charts";
import chartLegend from "@/features/ShopAnalytics/components/Visitor/chartLegend.vue";
import { POINT_STYLE } from "@/commons/enums";
import { AxiosResponse } from "axios";

export default defineComponent({
  components: {
    GChart,
    chartLegend,
  },
  props: {
    totalChartData: {
      type: Array as PropType<(string | number | object)[][]>,
      required: true,
    },
    maleChartData: {
      type: Array as PropType<(string | number | object | null)[][]>,
      required: true,
    },
    femaleChartData: {
      type: Array as PropType<(string | number | object | null)[][]>,
      required: true,
    },
    thirdCategory: {
      type: Object as PropType<{
        total: (string | number | object)[][];
        male: (string | number | object | null)[][];
        female: (string | number | object | null)[][];
      }>,
      required: true,
    },
    legends: {
      type: Array as PropType<{ text: string; color: string }[]>,
      default: () => [],
    },
    getL3FileId: {
      type: Function as PropType<() => Promise<AxiosResponse<any, any>>>,
      default: undefined,
    },
    l3CSVName: {
      type: String,
      default: undefined,
    },
    colors: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    hasl1l2Alert: {
      type: Boolean,
    },
  },
  data() {
    return {
      selectedL1L2Category: undefined,
      option: (args: {
        height: number;
        position: "left" | "center" | "right";
        max: number;
        min: number;
      }): any => { // vue-google-chartsの型定義がいいかげんなのでanyで
        let colorArray = [];
        colorArray = (this as any).colors;
        return {
          chartArea: {
            top: 10,
            left: args.position === "left" ? 175 : 15,
            right: args.position === "right" ? 0 : 15,
            bottom: 50,
            backgroundColor: {
              fill: (() => {
                if (args.position === "left") return "#fff";
                if (args.position === "center") return "#f5f8fd";
                if (args.position === "right") return "#fff6f7";
              })(),
            },
          },
          colors: colorArray,
          hAxis: {
            textStyle: {
              fontSize: 12,
            },
            viewWindow: {
              max: args.max,
              min: args.min,
            },
            ticks: [50],
          },
          height: args.height,
          isStacked: true,
          legend: { position: "none" },
          lineWidth: 2,
          orientation: "vertical",
          pointSize: 4,
          tooltip: {
            isHtml: true,
          },
          aggregationTarget: "series",
          vAxis: {
            textStyle: {
              fontSize: 10,
            },
            textPosition: args.position === "left" ? "out" : "none",
          },
        };
      },
      // 以下、vue-google-chartsの型定義がいいかげんなのでanyで
      totalChart: null as any,
      maleChart: null as any,
      femaleChart: null as any,
      totalChartEvent: {select: this.totalChartSelect} as any,
      maleChartEvent: {select: this.maleChartSelect} as any,
      femaleChartEvent: {select: this.femaleChartSelect} as any,
    };
  },
  computed: {
    hasl3Alert() {
      return [
        ...this.thirdCategoryTotalChartData.flat().flat(),
        ...this.thirdCategoryMaleChartData.flat().flat(),
        ...this.thirdCategoryFemaleChartData.flat().flat(),
      ].some((e: any) =>
        [
          POINT_STYLE.RED["alert"],
          POINT_STYLE.BLUE["alert"],
          POINT_STYLE.GREEN["alert"],
          POINT_STYLE.ORANGE["alert"],
          POINT_STYLE.PURPLE["alert"],
          POINT_STYLE.BROWN["alert"],
        ].includes(e)
      );
    },
    maxValue() {
      return Math.max(
        ...([
          ...this.totalChartData,
          ...this.maleChartData,
          ...this.femaleChartData,
        ]
          .flat()
          .filter(
            (value) => typeof value === "number" && !isNaN(value)
          ) as number[])
      );
    },
    minValue() {
      return Math.min(
        ...([
          ...this.totalChartData,
          ...this.maleChartData,
          ...this.femaleChartData,
        ]
          .flat()
          .filter(
            (value) => typeof value === "number" && !isNaN(value)
          ) as number[])
      );
    },
    thirdCategoryTotalChartData() {
      if (!(this as any).selectedL1L2Category) return [];
      return this.thirdCategory.total[
        (this as any).selectedL1L2Category["row"]
      ];
    },
    thirdCategoryMaleChartData() {
      if (!(this as any).selectedL1L2Category) return [];
      return this.thirdCategory.male[(this as any).selectedL1L2Category["row"]];
    },
    thirdCategoryFemaleChartData() {
      if (!(this as any).selectedL1L2Category) return [];
      return this.thirdCategory.female[
        (this as any).selectedL1L2Category["row"]
      ];
    },
    levelThreeBehavioralDNAChartHeight(): number {
      const l = Math.max(
        (this as any).thirdCategory.total[
          (this as any).selectedL1L2Category["row"]
        ].length,
        (this as any).thirdCategory.male[
          (this as any).selectedL1L2Category["row"]
        ].length,
        (this as any).thirdCategory.female[
          (this as any).selectedL1L2Category["row"]
        ].length
      );
      return l * 34 + 80;
    },
    isCSVButtonDisabled() {
      return this.$store.state.selectedComparisonGroup || this.$store.state.selectedChainIds;
    },
  },
  methods: {
    totalChartSelect() {
      if (this.totalChart.getSelection().length) {
        const target = this.totalChart.getSelection()[0];
        if (
          !this.selectedL1L2Category ||
          (this.selectedL1L2Category as any).row !== target.row
        ) {
          (this as any).selectedL1L2Category = target;
          this.maleChart.setSelection(target);
          this.femaleChart.setSelection(target);
        } else {
          (this as any).selectedL1L2Category = undefined;
          this.maleChart.setSelection(null);
          this.femaleChart.setSelection(null);
        }
      }
    },
    maleChartSelect() {
      if (this.maleChart.getSelection().length) {
        const target = this.maleChart.getSelection()[0];
        if (
          !this.selectedL1L2Category ||
          (this.selectedL1L2Category as any).row !== target.row
        ) {
          (this as any).selectedL1L2Category = target;
          this.totalChart.setSelection(target);
          this.femaleChart.setSelection(target);
        } else {
          (this as any).selectedL1L2Category = undefined;
          this.totalChart.setSelection(null);
          this.femaleChart.setSelection(null);
        }
      }
    },
    femaleChartSelect() {
      if (this.femaleChart.getSelection().length) {
        const target = this.femaleChart.getSelection()[0];
        if (
          !this.selectedL1L2Category ||
          (this.selectedL1L2Category as any).row !== target.row
        ) {
          (this as any).selectedL1L2Category = target;
          this.totalChart.setSelection(target);
          this.maleChart.setSelection(target);
        } else {
          (this as any).selectedL1L2Category = undefined;
          this.totalChart.setSelection(null);
          this.maleChart.setSelection(null);
        }
      }
    },
  }
});
</script>

<style scoped>
.card-title {
  margin-top: 29px !important;
  margin-bottom: 15px !important;
}
.chart-legend {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #666;
}
.divider {
  border-bottom: 5px solid #eee;
  margin: 10px -30px 0 -30px;
}
.legends {
  text-align: center;
  font: normal normal normal 13px/13px Noto Sans JP;
}
.left-legends {
  padding: 0 0 0 190px !important;
  text-align: center;
  font: normal normal normal 13px/13px Noto Sans JP;
}
</style>
