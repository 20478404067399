import { axiosClient } from "@/plugins/axios";
import { AxiosResponse } from "axios";
import { convertHyphenDelimiter } from "@/commons/utils/dateUtil";
import {
  BizAreaAnalysisResponse,
  BizAreaAnalysisMapResponse,
  DistanceRankingResponse,
} from "@/features/ShopAnalytics/interfaces/response";
import { DownloadResponse } from "@/commons/interfaces/responses/download";

/**
 * 来店人数の距離別カバー率取得
 * @param {string} storeId 店舗ID
 * @param {string} start 期間開始日(yyyy-MM-dd)
 * @param {string} end 期間終了日(yyyy-MM-dd)
 * @param {number} granularity 表示粒度(0: 市区町村, 1: 町丁目)
 * @param {number} radius データ補足範囲(店舗を中心とした半径、単位はkm)
 */
export function getBizAreaAnalysisChart(
  storeId: string | undefined,
  start: string,
  end: string,
  granularity: 0 | 1,
  radius: number
): Promise<AxiosResponse> {
  if (typeof storeId === "undefined") return Promise.reject();
  return axiosClient.get<BizAreaAnalysisResponse>(
    "/stores/chart/trend-area-analysis/",
    {
      params: {
        store: storeId,
        start: convertHyphenDelimiter(start),
        end: convertHyphenDelimiter(end),
        granularity: granularity,
        radius: radius,
      },
    }
  );
}

/**
 * 商圏マップ用ポリゴンデータ取得
 * @param {string} storeId 店舗ID
 * @param {string} start 期間開始日(yyyy-MM-dd)
 * @param {string} end 期間終了日(yyyy-MM-dd)
 * @param {Object} mapOptions 地図の補足情報
 * @param {number} mapOptions.granularity 表示粒度(0: 市区町村, 1: 町丁目)
 * @param {number} mapOptions.radius データ補足範囲(店舗を中心とした半径、単位はkm)
 */
export function getBizAreaAnalysisMap(
  storeId: string | undefined,
  start: string,
  end: string,
  mapOptions: {
    granularity: 0 | 1;
    radius: number;
  }
): Promise<AxiosResponse> {
  if (typeof storeId === "undefined") return Promise.reject();
  return axiosClient.post<BizAreaAnalysisMapResponse>(
    "/stores/chart/trend-area-analysis/geo/",
    {
      store: storeId,
      start: convertHyphenDelimiter(start),
      end: convertHyphenDelimiter(end),
      mapOptions: mapOptions,
    }
  );
}

/**
 * 商圏ランキングチャート取得
 * @param {string} storeId 店舗ID
 * @param {string} start 期間開始日(yyyy-MM-dd)
 * @param {string} end 期間終了日(yyyy-MM-dd)
 */
export function GetDistanceRankingChart(
  storeId: string | undefined,
  start: string,
  end: string
): Promise<AxiosResponse> {
  if (typeof storeId === "undefined") return Promise.reject();
  return axiosClient.get<DistanceRankingResponse>(
    "/stores/chart/trend-area-analysis/distance-ranking/",
    {
      params: {
        store: storeId,
        start: convertHyphenDelimiter(start),
        end: convertHyphenDelimiter(end),
      },
    }
  );
}

/**
 * 来店人数の距離別カバー率DL用URL生成
 * @param {string} storeId 店舗ID
 * @param {string} start 期間開始日(yyyy-MM-dd)
 * @param {string} end 期間終了日(yyyy-MM-dd)
 */
export function downloadBizAreaAnalysisDistanceRankingChart(
  storeId: string | undefined,
  start: string,
  end: string
): Promise<AxiosResponse> {
  if (typeof storeId === "undefined") return Promise.reject();
  return axiosClient.get<DownloadResponse>(
    "/stores/chart/trend-area-analysis/distance-ranking/download/",
    {
      params: {
        store: storeId,
        start: convertHyphenDelimiter(start),
        end: convertHyphenDelimiter(end),
      },
    }
  );
}

/**
 * 商圏ランキングDL用URL生成
 * @param {string} storeId 店舗ID
 * @param {string} start 期間開始日(yyyy-MM-dd)
 * @param {string} end 期間終了日(yyyy-MM-dd)
 * @param {number} granularity 表示粒度(0: 市区町村, 1: 町丁目)
 * @param {number} radius データ補足範囲(店舗を中心とした半径、単位はkm)
 */
export function downloadBizAreaAnalysisChart(
  storeId: string | undefined,
  start: string,
  end: string,
  granularity: 0 | 1,
  radius: number
): Promise<AxiosResponse> {
  if (typeof storeId === "undefined") return Promise.reject();
  return axiosClient.get<DownloadResponse>(
    "/stores/chart/trend-area-analysis/download/",
    {
      params: {
        store: storeId,
        start: convertHyphenDelimiter(start),
        end: convertHyphenDelimiter(end),
        granularity: granularity,
        radius: radius,
      },
    }
  );
}
