import {
  BizAreaAnalysisResponse,
  DistanceRankingResponse,
} from "@/features/ShopAnalytics/interfaces/response";

export function hasAlertInBizAreaAnalysis(data: BizAreaAnalysisResponse) {
  /**
   * '/stores/chart/trend-area-analysis/' で受け取った
   * レスポンスデータに isAlert = true が含まれているか
   */
  if (data.isAlert === null) return true;
  return data.isAlert;
}

export function hasAlertInDistanceRanking(data: DistanceRankingResponse) {
  /**
   * '/stores/chart/trend-area-analysis/distance-ranking/' で受け取った
   * レスポンスデータに isAlert = true が含まれているか
   */
  if (data.isAlert === null) return true;
  return data.isAlert;
}
