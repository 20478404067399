import moment from "moment";
import {
  VisitCountGraph,
  VisitCountGraphItem,
  VisitCountTable,
  VisitorTableItem,
} from "@/features/ChainAnalytics/visitor/interfaces/response";
import {
  getDayOfWeekString,
  getGenderLabelByValue,
  getAgeLabelByValue,
} from "@/commons/utils/enumUtil";
import {
  SUB_TYPE,
  GENDER,
  AGE,
  PARTICLE_TYPE,
  POINT_STYLE,
  COLOR,
} from "@/commons/enums";
import {
  convertSlashDelimiter,
  convertMonthlySlashDelimiter,
} from "@/commons/utils/dateUtil";

export function processVisitorChartData(
  response: VisitCountGraph,
  particle: (typeof PARTICLE_TYPE)[keyof typeof PARTICLE_TYPE]
): any[] {
  // 取得したデータから重複なしの日付一覧を生成
  const responseDays: string[] = [
    ...new Set<string>(
      response.visitCounts.chartItems.map(
        (chartItem: VisitCountGraphItem) => chartItem.granularity
      )
    ),
  ];
  // 日付昇順にソート
  responseDays.sort((first, second) => {
    if (new Date(first) < new Date(second)) return -1;
    else if (new Date(first) > new Date(second)) return 1;
    else return 0;
  });

  const chartData: any[] = responseDays.map((date: string) => {
    const targetData = response.visitCounts.chartItems.filter(
      (chartItem: VisitCountGraphItem) => {
        return date === chartItem.granularity;
      }
    );
    switch (response.visitCounts.chartType) {
      case SUB_TYPE.NONE: {
        const tooltipElement = createTooltipElement(
          response.visitCounts.chartType,
          targetData[0],
          particle
        );
        const pointElement = createPointElement(
          response.visitCounts.chartType,
          targetData[0]
        );
        return [
          particle === PARTICLE_TYPE["MONTH"]
            ? convertMonthlySlashDelimiter(date)
            : convertSlashDelimiter(date),
          targetData[0].value,
          tooltipElement,
          pointElement,
        ];
      }
      case SUB_TYPE.GENDER: {
        let value1 = 0 as number | null,
          tooltipElement1 = "",
          pointElement1 = "",
          value2 = 0 as number | null,
          tooltipElement2 = "",
          pointElement2 = "";
        targetData.forEach((data: VisitCountGraphItem) => {
          if (data.subIndex === GENDER.MALE.value) {
            value1 = data.value;
            tooltipElement1 = createTooltipElement(
              response.visitCounts.chartType,
              data,
              particle
            );
            pointElement1 = createPointElement(
              response.visitCounts.chartType,
              data
            );
          } else if (data.subIndex === GENDER.FEMALE.value) {
            value2 = data.value;
            tooltipElement2 = createTooltipElement(
              response.visitCounts.chartType,
              data,
              particle
            );
            pointElement2 = createPointElement(
              response.visitCounts.chartType,
              data
            );
          }
        });
        return [
          particle === PARTICLE_TYPE["MONTH"]
            ? convertMonthlySlashDelimiter(date)
            : convertSlashDelimiter(date),
          value1,
          tooltipElement1,
          pointElement1,
          value2,
          tooltipElement2,
          pointElement2,
        ];
      }
      case SUB_TYPE.AGE: {
        let value1 = 0 as number | null,
          tooltipElement1 = "",
          pointElement1 = "",
          value2 = 0 as number | null,
          tooltipElement2 = "",
          pointElement2 = "",
          value3 = 0 as number | null,
          tooltipElement3 = "",
          pointElement3 = "",
          value4 = 0 as number | null,
          tooltipElement4 = "",
          pointElement4 = "",
          value5 = 0 as number | null,
          tooltipElement5 = "",
          pointElement5 = "",
          value6 = 0 as number | null,
          tooltipElement6 = "",
          pointElement6 = "";
        targetData.forEach((data: VisitCountGraphItem) => {
          if (data.subIndex === AGE.TEEN.value) {
            value1 = data.value;
            tooltipElement1 = createTooltipElement(
              response.visitCounts.chartType,
              data,
              particle
            );
            pointElement1 = createPointElement(
              response.visitCounts.chartType,
              data
            );
          } else if (data.subIndex === AGE.TWENTIES.value) {
            value2 = data.value;
            tooltipElement2 = createTooltipElement(
              response.visitCounts.chartType,
              data,
              particle
            );
            pointElement2 = createPointElement(
              response.visitCounts.chartType,
              data
            );
          } else if (data.subIndex === AGE.THIRTIES.value) {
            value3 = data.value;
            tooltipElement3 = createTooltipElement(
              response.visitCounts.chartType,
              data,
              particle
            );
            pointElement3 = createPointElement(
              response.visitCounts.chartType,
              data
            );
          } else if (data.subIndex === AGE.FORTIES.value) {
            value4 = data.value;
            tooltipElement4 = createTooltipElement(
              response.visitCounts.chartType,
              data,
              particle
            );
            pointElement4 = createPointElement(
              response.visitCounts.chartType,
              data
            );
          } else if (data.subIndex === AGE.FIFTIES.value) {
            value5 = data.value;
            tooltipElement5 = createTooltipElement(
              response.visitCounts.chartType,
              data,
              particle
            );
            pointElement5 = createPointElement(
              response.visitCounts.chartType,
              data
            );
          } else if (data.subIndex === AGE.SIXTIES.value) {
            value6 = data.value;
            tooltipElement6 = createTooltipElement(
              response.visitCounts.chartType,
              data,
              particle
            );
            pointElement6 = createPointElement(
              response.visitCounts.chartType,
              data
            );
          }
        });
        return [
          particle === PARTICLE_TYPE["MONTH"]
            ? convertMonthlySlashDelimiter(date)
            : convertSlashDelimiter(date),
          value1,
          tooltipElement1,
          pointElement1,
          value2,
          tooltipElement2,
          pointElement2,
          value3,
          tooltipElement3,
          pointElement3,
          value4,
          tooltipElement4,
          pointElement4,
          value5,
          tooltipElement5,
          pointElement5,
          value6,
          tooltipElement6,
          pointElement6,
        ];
      }
    }
  });

  let firstColumn: (string | object)[];

  const legend =
    particle === PARTICLE_TYPE["MONTH"]
      ? convertMonthlySlashDelimiter(responseDays[0]) +
        "-" +
        convertMonthlySlashDelimiter(responseDays.slice(-1)[0])
      : particle === PARTICLE_TYPE["WEEK"]
      ? convertSlashDelimiter(responseDays[0]) +
        "週" +
        "-" +
        convertSlashDelimiter(responseDays.slice(-1)[0]) +
        "週"
      : convertSlashDelimiter(responseDays[0]) +
        "-" +
        convertSlashDelimiter(responseDays.slice(-1)[0]);

  switch (response.visitCounts.chartType) {
    case SUB_TYPE.NONE:
      firstColumn = [
        "date",
        legend,
        { type: "string", role: "tooltip", p: { html: true } },
        { type: "string", role: "style" },
      ];
      break;
    case SUB_TYPE.GENDER:
      firstColumn = [
        "date",
        "男性",
        { type: "string", role: "tooltip", p: { html: true } },
        { type: "string", role: "style" },
        "女性",
        { type: "string", role: "tooltip", p: { html: true } },
        { type: "string", role: "style" },
      ];
      break;
    case SUB_TYPE.AGE:
      firstColumn = [
        "date",
        "10代",
        { type: "string", role: "tooltip", p: { html: true } },
        { type: "string", role: "style" },
        "20代",
        { type: "string", role: "tooltip", p: { html: true } },
        { type: "string", role: "style" },
        "30代",
        { type: "string", role: "tooltip", p: { html: true } },
        { type: "string", role: "style" },
        "40代",
        { type: "string", role: "tooltip", p: { html: true } },
        { type: "string", role: "style" },
        "50代",
        { type: "string", role: "tooltip", p: { html: true } },
        { type: "string", role: "style" },
        "60代〜",
        { type: "string", role: "tooltip", p: { html: true } },
        { type: "string", role: "style" },
      ];
      break;
    default:
      return [];
  }
  chartData.unshift(firstColumn);

  return chartData;
}

function createPointElement(
  subType: number,
  chartItem: VisitCountGraphItem,
  compare = false
) {
  if (compare) {
    if (chartItem.isAlert) return POINT_STYLE.BLUE["alert"];
    else return POINT_STYLE.BLUE["default"];
  }

  if (subType === SUB_TYPE.NONE) {
    if (chartItem.isAlert) return POINT_STYLE.RED["alert"];
    else return POINT_STYLE.RED["default"];
  }

  if (subType === SUB_TYPE.GENDER) {
    if (chartItem.subIndex === GENDER.MALE.value) {
      if (chartItem.isAlert) return POINT_STYLE.BLUE["alert"];
      else return POINT_STYLE.BLUE["default"];
    } else if (chartItem.subIndex === GENDER.FEMALE.value) {
      if (chartItem.isAlert) return POINT_STYLE.RED["alert"];
      else return POINT_STYLE.RED["default"];
    }
  }

  if (subType === SUB_TYPE.AGE) {
    if (chartItem.subIndex === AGE.TEEN.value) {
      if (chartItem.isAlert) return POINT_STYLE.RED["alert"];
      else return POINT_STYLE.RED["default"];
    } else if (chartItem.subIndex === AGE.TWENTIES.value) {
      if (chartItem.isAlert) return POINT_STYLE.BLUE["alert"];
      else return POINT_STYLE.BLUE["default"];
    } else if (chartItem.subIndex === AGE.THIRTIES.value) {
      if (chartItem.isAlert) return POINT_STYLE.GREEN["alert"];
      else return POINT_STYLE.GREEN["default"];
    } else if (chartItem.subIndex === AGE.FORTIES.value) {
      if (chartItem.isAlert) return POINT_STYLE.ORANGE["alert"];
      else return POINT_STYLE.ORANGE["default"];
    } else if (chartItem.subIndex === AGE.FIFTIES.value) {
      if (chartItem.isAlert) return POINT_STYLE.PURPLE["alert"];
      else return POINT_STYLE.PURPLE["default"];
    } else if (chartItem.subIndex === AGE.SIXTIES.value) {
      if (chartItem.isAlert) return POINT_STYLE.BROWN["alert"];
      else return POINT_STYLE.BROWN["default"];
    }
  }
  return "";
}

export function processingVisitorTotalData(response: VisitCountTable) {
  let visitorTotal = 0,
    manTotal = 0,
    womanTotal = 0,
    teenTotal = 0,
    twentiesTotal = 0,
    thirtiesTotal = 0,
    fortiesTotal = 0,
    fiftiesTotal = 0,
    sixtiesTotal = 0;

  response.visitCounts.forEach((data: VisitorTableItem) => {
    // 各列ごとの合計値を計算
    visitorTotal = visitorTotal + data.total;
    manTotal = manTotal + data.male;
    womanTotal = womanTotal + data.female;
    teenTotal = teenTotal + data.teen;
    twentiesTotal = twentiesTotal + data.twenties;
    thirtiesTotal = thirtiesTotal + data.thirties;
    fortiesTotal = fortiesTotal + data.forties;
    fiftiesTotal = fiftiesTotal + data.fifties;
    sixtiesTotal = sixtiesTotal + data.sixties;
  });

  return {
    granularity: "(合計)",
    total: visitorTotal,
    male: manTotal,
    female: womanTotal,
    teen: teenTotal,
    twenties: twentiesTotal,
    thirties: thirtiesTotal,
    forties: fortiesTotal,
    fifties: fiftiesTotal,
    sixties: sixtiesTotal,
  };
}

export function processingVisitorTableData(
  response: VisitCountTable,
  particle: (typeof PARTICLE_TYPE)[keyof typeof PARTICLE_TYPE]
) {
  response.visitCounts.forEach((data: VisitorTableItem) => {
    const rowDate = new Date(data.granularity);
    if (particle === PARTICLE_TYPE["MONTH"])
      data.granularity =
        rowDate.getFullYear() + "年" + (rowDate.getMonth() + 1) + "月";
    else
      data.granularity =
        rowDate.getFullYear() +
        "年" +
        (rowDate.getMonth() + 1) +
        "月" +
        rowDate.getDate() +
        "日（" +
        getDayOfWeekString(data.granularity) +
        "）";
  });

  return response.visitCounts;
}

export function processVisitorCompareChartData(
  baseResponse: VisitCountGraph,
  compareResponse: VisitCountGraph,
  particle: (typeof PARTICLE_TYPE)[keyof typeof PARTICLE_TYPE]
): any[] {
  // 日付順にソート
  const base = baseResponse.visitCounts.chartItems.sort(
    (first: VisitCountGraphItem, second: VisitCountGraphItem) => {
      if (new Date(first.granularity) > new Date(second.granularity)) return 1;
      else if (new Date(first.granularity) < new Date(second.granularity)) return -1;
      else return 0;
    }
  );

  const compare = compareResponse.visitCounts.chartItems.sort(
    (first: VisitCountGraphItem, second: VisitCountGraphItem) => {
      if (new Date(first.granularity) > new Date(second.granularity)) return 1;
      else if (new Date(first.granularity) < new Date(second.granularity)) return -1;
      else return 0;
    }
  );

  const baseStart = base[0].granularity;
  const baseEnd = base.slice(-1)[0].granularity;
  const compareStart = compare[0].granularity;
  const compareEnd = compare.slice(-1)[0].granularity;

  let dateStrList: string[];
  if (base.length >= compare.length) {
    dateStrList = base.map((e: VisitCountGraphItem) => {
      return e.granularity;
    });
  } else {
    dateStrList = compare.map((e: VisitCountGraphItem) => {
      return e.granularity;
    });
  }

  const processedChartData: any = [];
  dateStrList.forEach((dateStr, i) => {
    const date = moment(dateStr).format("YYYY-MM-DD");
    let baseValue = null;
    let baseTooltipElement =
      "<div style='display:table-cell; vertical-align:middle;' />";
    let basePointElement = "";
    let compareValue = null;
    let compareTooltipElement =
      "<div style='display:table-cell; vertical-align:middle;' />";
    let comparePointElement = "";

    if (base[i]?.granularity) {
      baseValue = base[i].value;
      baseTooltipElement = createCompareTooltipElement(
        base[i],
        particle,
        baseStart,
        baseEnd,
        COLOR.RED
      );
      basePointElement = createPointElement(
        baseResponse.visitCounts.chartType,
        base[i]
      );
    }
    if (compare[i]?.granularity) {
      compareValue = compare[i].value;
      compareTooltipElement = createCompareTooltipElement(
        compare[i],
        particle,
        compareStart,
        compareEnd,
        COLOR.BLUE
      );
      comparePointElement = createPointElement(
        compareResponse.visitCounts.chartType,
        compare[i],
        true
      );
    }

    processedChartData.push([
      particle === PARTICLE_TYPE["MONTH"]
        ? convertMonthlySlashDelimiter(date)
        : convertSlashDelimiter(date),
      baseValue,
      baseTooltipElement,
      basePointElement,
      compareValue,
      compareTooltipElement,
      comparePointElement,
    ]);
  });

  const baseLegend =
    particle === PARTICLE_TYPE["MONTH"]
      ? convertMonthlySlashDelimiter(baseStart) +
        "-" +
        convertMonthlySlashDelimiter(baseEnd)
      : convertSlashDelimiter(baseStart) + "-" + convertSlashDelimiter(baseEnd);

  const compareLegend =
    particle === PARTICLE_TYPE["MONTH"]
      ? convertMonthlySlashDelimiter(compareStart) +
        "-" +
        convertMonthlySlashDelimiter(compareEnd)
      : convertSlashDelimiter(compareStart) +
        "-" +
        convertSlashDelimiter(compareEnd);

  processedChartData.unshift([
    "date",
    baseLegend,
    { type: "string", role: "tooltip", p: { html: true } },
    { type: "string", role: "style" },
    compareLegend,
    { type: "string", role: "tooltip", p: { html: true } },
    { type: "string", role: "style" },
  ]);

  return processedChartData;
}

function createTooltipElement(
  subType: number,
  chartItem: VisitCountGraphItem,
  particle: (typeof PARTICLE_TYPE)[keyof typeof PARTICLE_TYPE]
) {
  let title = "";
  let value = "";
  let value1 = "";
  let value2 = "";
  let value3 = "";

  switch (particle) {
    case PARTICLE_TYPE["WEEK"]: {
      title = convertSlashDelimiter(chartItem.granularity) + "週";
      break;
    }
    case PARTICLE_TYPE["MONTH"]: {
      title = convertMonthlySlashDelimiter(chartItem.granularity);
      break;
    }
    default: {
      title =
        convertSlashDelimiter(chartItem.granularity) +
        " (" +
        getDayOfWeekString(chartItem.granularity) +
        ")";
      break;
    }
  }

  if (subType === SUB_TYPE.NONE) {
    value = "[来店人数] ";
    value1 = chartItem.value?.toLocaleString() + "人";

    return `<div style='display: flex; justify-content: center; align-items: center; height: 55px; color: #222222; font-size: 12px !important;'>
              <div style='display:table-cell; vertical-align:middle; text-align: left;'>
                <div>${title}</div>
                <div>${value}<b>${value1}</b></div>
              </div>
            </div>`;
  }

  if (subType === SUB_TYPE.GENDER) {
    value = "[性別] ";
    value1 = getGenderLabelByValue(Number(chartItem.subIndex));
    value2 = "[来店人数] ";
    value3 = chartItem.value?.toLocaleString() + "人";

    return `<div style='display: flex; justify-content: center; align-items: center; height: 70px; color: #222222; font-size: 12px !important;'>
              <div style='display:table-cell; vertical-align:middle; text-align: left;'>
                <div>${title}</div>
                <div>${value}<b>${value1}</b></div>
                <div>${value2}<b>${value3}</b></div>
              </div>
            </div>`;
  }

  if (subType === SUB_TYPE.AGE) {
    value = "[年代] ";
    value1 = getAgeLabelByValue(Number(chartItem.subIndex));
    value2 = "[来店人数] ";
    value3 = chartItem.value?.toLocaleString() + "人";

    return `<div style='display: flex; justify-content: center; align-items: center; height: 70px; color: #222222; font-size: 12px !important;'>
              <div style='display:table-cell; vertical-align:middle; text-align: left;'>
                <div>${title}</div>
                <div>${value}<b>${value1}</b></div>
                <div>${value2}<b>${value3}</b></div>
              </div>
            </div>`;
  }
  return "";
}

function createCompareTooltipElement(
  chartItem: VisitCountGraphItem,
  particle: (typeof PARTICLE_TYPE)[keyof typeof PARTICLE_TYPE],
  startPeriod: string,
  endPeriod: string,
  color: string
) {
  let period = "";
  let title = "";
  let value = "";
  let value1 = "";

  switch (particle) {
    case PARTICLE_TYPE["WEEK"]: {
      period = `＜<span style="color: ${color}">● </span>${convertSlashDelimiter(
        startPeriod
      )}週 ~ ${convertSlashDelimiter(endPeriod)}週＞`;
      title = convertSlashDelimiter(chartItem.granularity) + "週";
      break;
    }
    case PARTICLE_TYPE["MONTH"]: {
      period = `＜<span style="color: ${color}">● </span>${convertMonthlySlashDelimiter(
        startPeriod
      )} ~ ${convertMonthlySlashDelimiter(endPeriod)}＞`;
      title = convertMonthlySlashDelimiter(chartItem.granularity);
      break;
    }
    default: {
      period = `＜<span style="color: ${color}">● </span>${convertSlashDelimiter(
        startPeriod
      )} ~ ${convertSlashDelimiter(endPeriod)}＞`;
      title =
        convertSlashDelimiter(chartItem.granularity) +
        " (" +
        getDayOfWeekString(chartItem.granularity) +
        ")";
      break;
    }
  }

  value = "[来店人数] ";
  value1 = chartItem.value?.toLocaleString() + "人";

  return `<div style='display: flex; justify-content: center; align-items: center; height: 70px; color: #222222; font-size: 12px !important;'>
            <div style='display:table-cell; vertical-align:middle; text-align: left;'>
              <div>${period}</div>
              <div>${title}</div>
              <div>${value}<b>${value1}</b></div>
            </div>
          </div>`;
}
