import moment from 'moment'
import { VisitCountGraphItem } from '@/features/ShopAnalytics/interfaces/response'
import { MonthlyVisitRateResponse } from '@/features/ChainCompare/visitor/types'
import { COLOR, PARTICLE_TYPE, POINT_STYLE } from '@/commons/enums'
import { convertSlashDelimiter, convertMonthlySlashDelimiter } from '@/commons/utils/dateUtil'
import { getDayOfWeekString } from '@/commons/utils/enumUtil'
import { ComparisonGraph } from '../../types'
import { COMPARE_PARTICLE_TYPE } from '@/features/StoreCompare/visitor/enum'
import { Chain } from '@/commons/interfaces'

type TargetItem = {
  chainName: string
  chainColor: string
  value: string
}

/**
 * チェーン比較 - 来店人数推移グラフ 日・週・月 のレスポンスデータを google charts の形式に加工する関数
 */
export function processChart(
  graphs: ComparisonGraph[],
  particle: (typeof PARTICLE_TYPE)[keyof typeof PARTICLE_TYPE]
): (string | number | object | undefined)[] {
  if (graphs.length === 0) {
    return []
  }

  // 最終的に返す配列
  const processed: (string | number | object | undefined)[] = []

  // ヘッダーとなるカラムの追加
  let header: (string | number | object | undefined)[] = ['date']

  // 元データをソート
  graphs.sort((a, b) => {
    return a.orderIndex - b.orderIndex
  })

  graphs.forEach((graph) => {
    header = [
      ...header,
      graph.chainName,
      { type: 'string', role: 'tooltip', p: { html: true } },
      { type: 'string', role: 'style' }
    ]
  })
  processed.push(header)

  // 重複なしで日付を一覧で取得
  const days: string[] = [
    ...new Set(
      graphs
        .map((e) => [e.visitCounts.chartItems.map((chartItem) => chartItem.granularity)].flat())
        .flat()
    )
  ]

  // 日付昇順にソート
  days.sort((first, second) => {
    if (new Date(first) < new Date(second)) return -1
    else if (new Date(first) > new Date(second)) return 1
    else return 0
  })

  // 日付昇順にグラフ用のデータを入れる
  days.forEach((day) => {
    // ["2022/01/01"]
    let tmp: (string | number | object | undefined)[] = [
      particle === PARTICLE_TYPE['MONTH']
        ? convertMonthlySlashDelimiter(day)
        : convertSlashDelimiter(day)
    ]
    // ["2022/01/01", 1000, <div>...</div>, "point { ... }", ... ]

    const targetItemList: TargetItem[] = []
    graphs.forEach((graph) => {
      const targetItem = graph.visitCounts.chartItems.find((chartItem) => {
        return chartItem.granularity === day
      })

      targetItemList.push({
        chainName: graph.chainName,
        chainColor: graph.color,
        value:
          targetItem === void 0 ? '来店人数データなし' : targetItem?.value.toLocaleString() + '人'
      })
    })

    graphs.forEach((graph) => {
      const targetItem = graph.visitCounts.chartItems.find((chartItem) => {
        return chartItem.granularity === day
      })
      tmp = [
        ...tmp,
        targetItem?.value ?? NaN,
        createTooltipElement(
          particle,
          targetItem !== void 0 ? targetItem.granularity : '',
          targetItemList
        ),
        createPointElement({ chartItem: targetItem, color: graph.color })
      ]
    })

    processed.push(tmp)
  })

  return processed
}

/**
 * チェーン比較 - 来店人数推移グラフ 月別比率のレスポンスデータを google charts の形式に加工する関数
 */
export function processMonthlyVisitRateChart(
  response: MonthlyVisitRateResponse,
  start: string,
  end: string,
  selectedChains: (Chain & { color?: string })[]
): (string | number | object | undefined | null)[] {
  const comparisonGroups = response.comparisonGroups

  if (comparisonGroups.length === 0) {
    return []
  }

  // 最終的に返す配列
  const processed: (string | number | object | undefined | null)[] = []

  // ヘッダーとなるカラムの追加
  let header: (string | number | object | undefined)[] = ['date']
  selectedChains.forEach((chain) => {
    header = [
      ...header,
      chain.id,
      { type: 'string', role: 'tooltip', p: { html: true } },
      { type: 'string', role: 'style' }
    ]
  })
  processed.push(header)

  // 日付昇順の一覧
  const days: string[] = []
  const duration = moment(end).diff(moment(start), 'months') + 1
  const day = moment(start)
  for (let i = 0; i < duration; i++) {
    days.push(day.format('YYYY-MM-DD'))
    day.add(1, 'months')
  }

  // アラートの存在確認
  const isAlertList: string[] = []
  days.forEach((day) => {
    const groups = comparisonGroups.filter((group) => group.date === day)
    for (const item of groups) {
      if (item.isAlert) {
        isAlertList.push(day)
        break
      }
    }
  })

  // 日付昇順にグラフ用のデータを入れる
  days.forEach((day) => {
    const tmp: (string | number | object | undefined | null)[] = [convertMonthlySlashDelimiter(day)]

    // ツールチップ用のデータの回収
    const tooltipItemList: {
      chainId: string
      chainName: string
      chainColor: string
      value: string
    }[] = []
    selectedChains.forEach((chain) => {
      const group = comparisonGroups.find(
        (group) => group.date === day && group.chainId === chain.id
      )
      if (group) {
        const color = chain.color !== void 0 ? chain.color : COLOR.RED
        tooltipItemList.push({
          chainId: chain.id,
          chainName: chain.name,
          chainColor: color,
          value: String((Math.round(group.rate * 1000) / 10).toFixed(1)) + '%'
        })
      }
    })

    // グラフ用のデータを入れる
    tooltipItemList.reverse()
    selectedChains.forEach((chain) => {
      const group = comparisonGroups.find(
        (group) => group.date === day && group.chainId === chain.id
      )
      if (group) {
        tmp.push(group.rate * 100)
        tmp.push(createChainCompareTooltipElement(group.date, tooltipItemList))
        tmp.push(getAlertStyle(isAlertList.includes(day)))
      } else {
        tmp.push(NaN)
        tmp.push('')
        tmp.push('')
      }
    })
    processed.push(tmp)
  })

  return processed
}

function createTooltipElement(
  particle: (typeof PARTICLE_TYPE)[keyof typeof PARTICLE_TYPE],
  granularity: string,
  itemList: TargetItem[]
) {
  let title = ''
  switch (particle) {
    case COMPARE_PARTICLE_TYPE.WEEK: {
      title = convertSlashDelimiter(granularity) + '週'
      break
    }
    case COMPARE_PARTICLE_TYPE.MONTH: {
      title = convertMonthlySlashDelimiter(granularity)
      break
    }
    default: {
      title = convertSlashDelimiter(granularity) + ' (' + getDayOfWeekString(granularity) + ')'
      break
    }
  }

  let tooltipElement = `<div 
                          style='
                            display: flex; 
                            justify-content: center; 
                            align-items: center; 
                            min-width: 150px; 
                            color: #222222; 
                            font-size: 12px !important;
                            margin-top: 15px;
                            margin-bottom: 15px;
                          '>
                          <div style='display:table-cell; vertical-align:middle; text-align: left;'>
                            <div style='text-align: left; margin-bottom: 10px'>${title}</div>`
  itemList.forEach((item) => {
    tooltipElement += `<div style='text-align: left'><span style="color: ${item.chainColor}">● </span>${item.chainName}</div>
                       <div style='margin-bottom: 10px'>[来店人数] <b>${item.value}</b></div>`
  })

  tooltipElement += '</div></div>'
  return tooltipElement
}

function createChainCompareTooltipElement(
  granularity: string,
  tooltipItemList: {
    chainId: string
    chainName: string
    chainColor: string
    value: string
  }[]
) {
  const title = convertMonthlySlashDelimiter(granularity)
  let tooltipElement = `<div 
                          style='
                            display: flex; 
                            justify-content: center;
                            align-items: center; 
                            min-width: 150px; 
                            color: #222222; 
                            font-size: 12px !important;
                            margin-top: 15px;
                            margin-bottom: 15px;
                          '>
                          <div style='display:table-cell; vertical-align:middle; text-align: left;'>
                            <div style='text-align: left; margin-bottom: 10px'>${title}</div>`

  tooltipItemList.forEach((item) => {
    tooltipElement += `<div style='text-align: left'><span style="color: ${item.chainColor}">● </span>${item.chainName}</div>
                       <div style='margin-bottom: 10px'>[構成比] <b>${item.value}</b></div>`
  })

  tooltipElement += `</div></div>`
  return tooltipElement
}

function createPointElement(args: { chartItem: VisitCountGraphItem | undefined; color: string }) {
  if (!args.chartItem) return ''
  if (args.chartItem.isAlert) {
    switch (args.color) {
      case COLOR.RED:
        return POINT_STYLE.RED['alert']
      case COLOR.BLUE:
        return POINT_STYLE.BLUE['alert']
      case COLOR.GREEN:
        return POINT_STYLE.GREEN['alert']
      case COLOR.ORANGE:
        return POINT_STYLE.ORANGE['alert']
      case COLOR.PURPLE:
        return POINT_STYLE.PURPLE['alert']
      case COLOR.BROWN:
        return POINT_STYLE.BROWN['alert']
      default:
        return POINT_STYLE.RED['alert']
    }
  }
  return args.color
}

function getAlertStyle(isAlert: boolean | undefined) {
  return isAlert ? 'color: #BBBBBB; stroke-width: 0' : ''
}
