<template>
  <div class="map-popup">
    <h1>{{ name }}</h1>
    <div class="store-type">
      [業種]
      <span
        :class="{
          'dot-red': storeType === STORE_TYPE.SUPER_MARKET['value'],
          'dot-green': storeType === STORE_TYPE.DRUG_STORE['value'],
          'dot-yellow': storeType === STORE_TYPE.HOME_CENTER['value'],
          'dot-purple': storeType === STORE_TYPE.CVS_STORE['value'],
        }"
      >
        ●
      </span>
      {{ STORE_TYPE.toLocalString(storeType) }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { STORE_TYPE } from "@/commons/enums";

export default defineComponent({
  name: "MapPopup",
  props: {
    name: {
      type: String,
      required: true,
    },
    storeType: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    return {
      STORE_TYPE: STORE_TYPE,
    };
  },  
});
</script>

<style lang="scss" scoped>
.map-popup {
  padding: 7px 15px;
  font-family: Noto Sans JP;

  h1 {
    text-align: left;
    font-size: 13px;
    font-weight: bold;
    color: #333333;
  }

  .store-type {
    font-size: 12px;
    color: #666666;
    text-align: left;

    .dot-red {
      color: #e47075 !important;
    }

    .dot-green {
      color: #87b8a1 !important;
    }

    .dot-yellow {
      color: #cb9e56 !important;
    }

    .dot-purple {
      color: #9278c3 !important;
    }
  }
}
</style>
