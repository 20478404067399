<template>
  <v-card class="pa-0 mb-5 box-shadow-none">
    <div class="tab">
      <button
        :data-variant="selectedTab === 'list' ? 'selected' : 'not-selected'"
        class="tab-button"
        @click="handleTabClick('list')"
      >
        リストからエリアを選択
      </button>
      <button
        :data-variant="selectedTab === 'map' ? 'selected' : 'not-selected'"
        class="tab-button"
        @click="handleTabClick('map')"
      >
        地図からエリアを選択
      </button>
    </div>
    <div class="contents">
      <div v-show="selectedTab === 'list'">
        <div class="title">
          エリアを選んでください<span class="sub-text">（都道府県・市区町村単位でも分析可）</span>
        </div>
        <GranularitySelector :on-click-analytics="props.onClickAnalytics" />
      </div>
      <div v-show="selectedTab === 'map'">
        <MapSelector :on-click-analytics="props.onClickAnalytics" />
      </div>
    </div>
  </v-card>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import GranularitySelector from '../../components/GranularitySelector.vue'
import MapSelector from '../../components/MapSelector.vue'

/* --------------------------------------------------------------------------
  props
 ---------------------------------------------------------------------------*/

const props = withDefaults(
  defineProps<{
    onClickAnalytics: (prefectureIds: number[], cityIds: string[], townIds: string[]) => void
  }>(),
  {
    onClickAnalytics: () => undefined
  }
)

/* --------------------------------------------------------------------------
  core
 ---------------------------------------------------------------------------*/

const selectedTab = ref<'list' | 'map'>('list')
const handleTabClick = (tab: 'list' | 'map') => {
  selectedTab.value = tab
}
</script>

<style scoped>
.tab {
  display: flex;
  width: 100%;
  gap: 10px;
  background-color: rgba(245, 245, 245);
}

.tab-button {
  display: flex;
  flex: 0 0 calc(100% / 3);
  justify-content: center;
  align-items: center;
  height: 60px;
  position: relative;
}

.tab-button::after {
  content: '';
  display: flex;
  flex: 0 0 calc(100% / 3);
  background: #ffffff 0% 0% no-repeat padding-box;
  position: absolute;
  bottom: -3px;
  height: 3px;
  width: 100%;
  z-index: 1;
}

.tab-button[data-variant='selected'] {
  background: #ffffff 0% 0% no-repeat padding-box;
  text-align: center;
  font: normal normal bold 15px/40px Noto Sans JP;
  letter-spacing: 0px;
  color: #222222;
  opacity: 1;
  box-shadow: 0px 0px 3px #00000029;
}

.tab-button[data-variant='not-selected'] {
  background-color: #4d99d0;
  text-align: center;
  font: normal normal bold 15px/40px Noto Sans JP;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.contents {
  padding: 21px 30px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 3px #00000029;
  text-align: left;
}

.title {
  text-align: left;
  font: normal normal bold 15px/40px Noto Sans JP;
  letter-spacing: 0px;
  color: #222222;
}

.sub-text {
  text-align: left;
  font: normal normal normal 15px/40px Noto Sans JP;
  letter-spacing: 0px;
  color: #666666;
}

.box-shadow-none {
  box-shadow: none !important;
}
</style>
