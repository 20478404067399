<template>
  <v-card
    height="'650px'"
    class="card"
  >
    <v-card-title class="pa-0 card-title d-flex align-center">
      <span style="margin-right: 20px">行動DNA</span>
      <p style="margin-bottom: 0; font-size: 12px; color: #666">
        折れ線グラフをクリックすると詳細を閲覧できます。
      </p>
      <alert-tooltip
        v-if="hasl1l2Alert"
        class="ml-2"
        text="取得データボリュームが少なく統計上の信頼性が低い日が含まれています。該当日は参考値としてご参照ください。"
      />
      <v-spacer />
      <chart-description-tooltip
        menu-key="compare"
        sub-menu-key="persona"
        chart-key="behavioralDna"
        class="mr-27px"
      />
      <download-button
        label="CSVデータ"
        :disabled="!$store.state.selectedComparisonGroup"
        :get-file-id="getL2FileId"
        :csv-name="l2CSVName"
      />
    </v-card-title>
    <v-container
      class="pa-0"
      fluid
    >
      <v-row
        class="my-0"
        dense
      >
        <v-col class="py-0">
          <div
            v-show="!$store.state.selectedComparisonGroup"
            class="unselected_card"
          >
            店舗を選択するとチャートが表示されます。
          </div>
          <div v-show="$store.state.selectedComparisonGroup">
            <loadingImg
              v-if="chartLoading && chartData"
              :height="'700px'"
            />
            <no-data-chart v-else-if="isNoData" />
            <div v-else>
              <BehavioralDNAChart
                :total-chart-data="chartData.total"
                :male-chart-data="chartData.male"
                :female-chart-data="chartData.female"
                :third-category="chartData.thirdCategory"
                :legends="legends"
                :get-l3-file-id="getL3FileId"
                :l3-c-s-v-name="l3CSVName"
                :colors="colors"
                :hasl1l2-alert="hasl1l2Alert"
              />
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
// TODO: common に寄せる
import NoDataChart from "@/features/ShopAnalytics/components/Common/noDataChart.vue";
import BehavioralDNAChart from "@/features/StoreCompare/persona/components/BehavioralDNAChart.vue";

// types
import { BehavioralDNAChartData } from "@/features/StoreCompare/persona/types";
import LoadingImg from "@/commons/components/loadingImg.vue";
import { AxiosResponse } from "axios";

export default defineComponent({
  components: {
    LoadingImg,
    BehavioralDNAChart,
    NoDataChart,
  },
  props: {
    chartData: {
      type: Object as PropType<BehavioralDNAChartData>,
      required: true,
    },
    chartLoading: {
      type: Boolean,
    },
    legends: {
      type: Array as PropType<{ text: string; color: string }[]>,
      default: () => [],
    },
    hasl1l2Alert: {
      type: Boolean,
    },
    getL2FileId: {
      type: Function as PropType<() => Promise<AxiosResponse<any, any>>>,
      default: undefined,
    },
    getL3FileId: {
      type: Function as PropType<() => Promise<AxiosResponse<any, any>>>,
      default: undefined,
    },
    l2CSVName: {
      type: String,
      default: undefined,
    },
    l3CSVName: {
      type: String,
      default: undefined,
    },
    colors: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },
  computed: {
    isNoData(): boolean {
      return [
        ...this.chartData.total.flat(),
        ...this.chartData.male.flat(),
        ...this.chartData.female.flat(),
      ]
        .filter((value) => typeof value === "number")
        .every((value) => isNaN(value as number));
    },
  },
});
</script>

<style lang="scss" scoped>
.mr-27px {
  margin-right: 27px;
}
</style>
