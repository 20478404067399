import { axiosClient } from '@/plugins/axios'
import { AxiosResponse } from 'axios'
import { convertHyphenDelimiter } from '@/commons/utils/dateUtil'
import {
  VisitCountGraph,
  WeekHourHeatMapResponse
} from '@/features/ShopAnalytics/interfaces/response'
import { DownloadResponse } from '@/commons/interfaces/responses/download'
import { PARTICLE_TYPE, SUB_TYPE } from '@/commons/enums'

/**
 * 曜日時間別ヒートマップ取得
 * @param {string} chainId チェーンID
 * @param {string[]} prefectureIds 都道府県ID
 * @param {string} start 期間開始日(yyyy-MM-dd)
 * @param {string} end 期間終了日(yyyy-MM-dd)
 */
export function getWeekHourHeatMap(
  chainId: string | undefined,
  prefectureIds: string[] | undefined,
  start: string,
  end: string
): Promise<AxiosResponse<WeekHourHeatMapResponse>> {
  if (!chainId) return Promise.reject()
  return axiosClient.get<WeekHourHeatMapResponse>('/chains/chart/week-and-every-hour-heatmap/', {
    params: {
      chainId: chainId,
      prefectureIds: prefectureIds,
      start: convertHyphenDelimiter(start),
      end: convertHyphenDelimiter(end)
    }
  })
}

/**
 * 曜日時間別ヒートマップDL用URL生成
 * @param {string} chainId チェーンID
 * @param {string[]} prefectureIds 都道府県ID
 * @param {string} start 期間開始日(yyyy-MM-dd)
 * @param {string} end 期間終了日(yyyy-MM-dd)
 */
export function downloadWeekHourHeatMapCsv(
  chainId: string | undefined,
  prefectureIds: string[] | undefined,
  start: string,
  end: string
): Promise<AxiosResponse> {
  if (!chainId) return Promise.reject()
  return axiosClient.get<DownloadResponse>('/chains/chart/week-and-every-hour-heatmap/download/', {
    params: {
      chainId: chainId,
      prefectureIds: prefectureIds,
      start: convertHyphenDelimiter(start),
      end: convertHyphenDelimiter(end)
    }
  })
}

/**
 * 曜日時間別ヒートマップ(期間比較)DL用URL生成
 * @param {string} chainId チェーンID
 * @param {string[]} prefectureIds 都道府県ID
 * @param {string} start 期間開始日(yyyy-MM-dd)
 * @param {string} end 期間終了日(yyyy-MM-dd)
 */
export function downloadComparisonWeekHourHeatMapCsv(
  chainId: string | undefined,
  prefectureIds: string[] | undefined,
  period: {
    start: string
    end: string
  }[]
): Promise<AxiosResponse> {
  if (!chainId) return Promise.reject()
  period.forEach((item: { start: string; end: string }) => {
    item.start = convertHyphenDelimiter(item.start)
    item.end = convertHyphenDelimiter(item.end)
  })

  return axiosClient.post<DownloadResponse>(
    '/chains/chart/week-and-every-hour-heatmap-comparison/download/',
    {
      chainId: chainId,
      prefectureIds: prefectureIds,
      period: period
    }
  )
}

/**
 * 来店人数チャート取得
 * @param {string} chainId チェーンID
 * @param {string[]} prefectureIds 都道府県ID
 * @param {string} start 期間開始日(yyyy-MM-dd)
 * @param {string} end 期間終了日(yyyy-MM-dd)
 * @param {number} particle 表示粒度(1: 日別, 2: 週別, 3: 月別, 4: 時間帯別, 5: 曜日別)
 * @param {number} subType 内訳(0: 指定なし, 1: 性別, 2: 年代別)
 * @param {AbortSignal} signal APIの中断用オブジェクト
 */
export async function getVisitCountGraph(
  chainId: string | undefined,
  prefectureIds: string[] | undefined,
  start: string,
  end: string,
  particle: (typeof PARTICLE_TYPE)[keyof typeof PARTICLE_TYPE],
  subType: (typeof SUB_TYPE)[keyof typeof SUB_TYPE],
  signal?: AbortSignal
): Promise<AxiosResponse<VisitCountGraph>> {
  if (!chainId) return Promise.reject()
  return await axiosClient.get<VisitCountGraph>('/chains/chart/visit-count-graph/', {
    params: {
      chainId: chainId,
      prefectureIds: prefectureIds,
      start: convertHyphenDelimiter(start),
      end: convertHyphenDelimiter(end),
      particle: particle,
      type: subType
    },
    signal: signal
  })
}

/**
 * 来店人数テーブルDL用URL生成
 * @param {string} chainId チェーンID
 * @param {string[]} prefectureIds 都道府県ID
 * @param {string} start 期間開始日(yyyy-MM-dd)
 * @param {string} end 期間終了日(yyyy-MM-dd)
 * @param {number} particle 表示粒度(1: 日別, 2: 週別, 3: 月別, 4: 時間帯別, 5: 曜日別)
 */
export function downloadVisitCountCsv(
  chainId: string | undefined,
  prefectureIds: string[] | undefined,
  start: string | undefined,
  end: string | undefined,
  particle: (typeof PARTICLE_TYPE)[keyof typeof PARTICLE_TYPE]
): Promise<AxiosResponse> {
  if (chainId === undefined || start === undefined || end === undefined) return Promise.reject()
  return axiosClient.get<DownloadResponse>('/chains/chart/visit-count-table/download/', {
    params: {
      chainId: chainId,
      prefectureIds: prefectureIds,
      start: convertHyphenDelimiter(start),
      end: convertHyphenDelimiter(end),
      particle: particle
    }
  })
}

/**
 * 期間比較来店人数テーブルDL用URL生成
 * @param {string} chainId チェーンID
 * @param {string[]} prefectureIds 都道府県ID
 * @param {Array.<Object>} period 期間
 * @param {string} period.start 期間開始日(yyyy-MM-dd)
 * @param {string} period.end 期間開始日(yyyy-MM-dd)
 * @param {number} particle 表示粒度(1: 日別, 2: 週別, 3: 月別, 4: 時間帯別, 5: 曜日別)
 */
export function downloadVisitCountComparisonCsv(
  chainId: string | undefined,
  prefectureIds: string[] | undefined,
  period: {
    start: string | undefined
    end: string | undefined
  }[],
  particle: (typeof PARTICLE_TYPE)[keyof typeof PARTICLE_TYPE]
): Promise<AxiosResponse> {
  if (!chainId) return Promise.reject()
  if (period.some((p) => p.start === undefined || p.end === undefined)) return Promise.reject()

  period.forEach((item) => {
    item.start = convertHyphenDelimiter(item.start as string)
    item.end = convertHyphenDelimiter(item.end as string)
  })
  return axiosClient.post<DownloadResponse>('/chains/chart/visit-count-comparison/download/', {
    chainId: chainId,
    prefectureIds: prefectureIds,
    period: period,
    particle: particle
  })
}
