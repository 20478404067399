<template>
  <v-container
    class="pa-0"
    fluid
  >
    <v-row
      class="title-container"
      dense
      no-gutters
    >
      <v-col style="display: flex; align-items: center">
        <h1 class="title-text">
          商圏分析
        </h1>
        <update-badge
          type="month"
          style="margin-left: 15px"
        />
      </v-col>
      <v-spacer />
      <monthly-date-picker
        :start-date="$store.state.startMonth"
        :end-date="$store.state.endMonth"
        @update-period="updatePeriod"
      />
    </v-row>
    <v-row
      dense
      no-gutters
      class="card-container"
    >
      <v-col>
        <store-selector :loading="storeLoading" />
      </v-col>
    </v-row>
    <v-row
      dense
      no-gutters
      class="card-container"
    >
      <v-col style="position: relative">
        <v-card
          class="card"
          tile
        >
          <v-card-title class="pa-0 d-flex align-center card-title">
            <span>距離別カバー率</span>
            <alert-tooltip
              v-if="hasAlertInDistanceRanking"
              class="ml-2"
              text="取得データボリュームが少なく、統計上の信頼性の低いデータが含まれています。該当箇所は、参考値としてご参照ください。"
            />
            <v-spacer />
            <chart-description-tooltip
              menu-key="analytics"
              sub-menu-key="bizArea"
              chart-key="distanceCoverage"
              class="mr-27px"
            />
            <download-button
              label="CSVデータ"
              :disabled="!selectedStore"
              :get-file-id="getBizAreaAnalysisDistanceRankingFileId"
              :csv-name="bizAreaAnalysisDistanceRankingCSVName"
            />
          </v-card-title>
          <div
            v-show="!selectedStore"
            class="unselected_card"
          >
            店舗を選択するとチャートが表示されます。
          </div>
          <v-row
            v-show="selectedStore"
            class="justify-center"
          >
            <LoadingImg
              v-if="shareChartLoading"
              :height="'330px'"
            />
            <no-data-chart v-else-if="emptyAreaShareData" />
            <biz-area-share-chart
              v-else
              :chart-data="areaShareData"
              :has-alert="hasChartAlert"
              @click-approve-alert="clickApproveAlertAreaShareData"
            />
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row
      dense
      no-gutters
      class="card-container mb-0"
    >
      <v-col style="position: relative">
        <v-card
          class="card"
          tile
        >
          <div class="map_title">
            <b>商圏マップ（居住エリア）</b>
            <alert-tooltip
              v-if="hasAlertInBizAreaAnalysisMap"
              class="ml-2"
              text="取得データボリュームが少なく、統計上の信頼性の低いデータが含まれています。該当箇所は、参考値としてご参照ください。"
            />
            <div class="biz_area_sub_setting">
              <span>
                <b>計測範囲：</b>
                半径
                <select
                  v-model.number="radius"
                  class="form space"
                >
                  <option
                    v-for="r in radiusList"
                    :key="r"
                    :value="r"
                  >
                    {{ r }}
                  </option>
                </select>
                km
              </span>
              <span class="granularity_form">
                <b>表示単位：</b>
                <label style="margin-right: 5px">
                  <input
                    v-model.number="granularity"
                    class="space"
                    type="radio"
                    value="0"
                  >
                  市区町村
                </label>
                <label>
                  <input
                    v-model.number="granularity"
                    class="space"
                    type="radio"
                    value="1"
                  >
                  町丁目
                </label>
              </span>
              <button
                class="button"
                @click="fetchAll"
              >
                適用
              </button>
            </div>
            <v-spacer />
            <chart-description-tooltip
              menu-key="analytics"
              sub-menu-key="bizArea"
              chart-key="bizAreaMap"
            />
          </div>
          <div
            v-if="selectedStore"
            class="bizarea-container"
          >
            <no-data-chart v-if="!mapLoading && isBizAreaMapNoData" />
            <biz-area-map
              v-else
              ref="bizAreaMap"
              v-model:view-radius="viewRadius"
              style="padding: 30px"
              :has-alert="hasMapAlert"
              :map-loading="mapLoading"
              :store-point="storePoint"
              :radius="radius"
              :feature-collection="featureCollection"
              :max-ratio="maxRatio"
              :active-area="activeArea"
              @click-layer="updateActiveAreaByAreaId"
              @click-approve-alert="clickApproveAlertMapData"
              @update-route="pushRoute"
              @reset-active="resetActiveArea"
            />
          </div>
          <div
            v-else
            class="unselected_card"
          >
            店舗を選択するとマップが表示されます。
          </div>
          <div class="divider" />
          <div class="map_title">
            <b>来店者の居住地ランキング</b>
            <v-spacer />
            <chart-description-tooltip
              menu-key="analytics"
              sub-menu-key="bizArea"
              chart-key="ranking"
              class="mr-27px"
            />
            <download-button
              label="CSVデータ"
              :disabled="!selectedStore"
              :get-file-id="getBizAreaAnalysisChartFileId"
              :csv-name="bizAreaAnalysisChartCSVName"
            />
          </div>
          <div
            v-show="!selectedStore"
            class="unselected_card"
          >
            店舗を選択するとチャートが表示されます。
          </div>
          <div
            v-show="selectedStore"
            class="bizarea-container"
          >
            <LoadingImg
              v-if="rankingChartLoading"
              :height="'550px'"
            />
            <no-data-chart
              v-else-if="bizAreaChartData.length <= 1 || isBizAreaRankingNoData"
            />
            <biz-area-ranking-chart
              v-else
              :chart-data="bizAreaChartData"
              :has-alert="hasAlertInBizAreaAnalysisRanking"
              @click-chart="updateActiveAreaByAreaName"
              @click-approve-alert="hasAlertInBizAreaAnalysisRanking = false"
            />
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MonthlyDatePicker from "@/commons/components/DatePicker/MonthlyDatePicker.vue";
import StoreSelector from "@/commons/components/StoreSelector/StoreSelector.vue";
import BizAreaShareChart from "@/features/ShopAnalytics/components/BizArea/bizAreaShareChart.vue";
import BizAreaMap from "@/features/ShopAnalytics/components/BizArea/bizAreaMap.vue";
import BizAreaRankingChart from "@/features/ShopAnalytics/components/BizArea/bizAreaRankingChart.vue";
import UpdateBadge from "@/features/ShopAnalytics/components/Common/updateBadge.vue";
import NoDataChart from "@/features/ShopAnalytics/components/Common/noDataChart.vue";
// interface
import { AxiosResponse } from "axios";
import { Store } from "@/commons/interfaces/responses/store";
import {
  DistanceRankingResponse,
  BizAreaAnalysisResponse,
  BizAreaAnalysisMapResponse,
  MapBoxFeatures,
} from "@/features/ShopAnalytics/interfaces/response";
import { AreaListItem } from "@/features/ShopAnalytics/interfaces/component";
// util
import * as notify from "@/plugins/notification";
import {
  convertHyphenDelimiter,
  convertSlashDelimiter,
} from "@/commons/utils/dateUtil";
import { getPeriodByRouterQueryMonthPeriod } from "@/commons/utils";
import {
  processChartData,
  processBizAreaShareChartData,
  hasAlertInDistanceRanking,
  hasAlertInBizAreaAnalysis,
  getBizAreaMaxRate,
  isMapNoData,
  getMapAreaList,
  getRankingAreaList,
  isRankingNoData,
} from "@/features/ShopAnalytics/utils/bizArea";
// axios
import {
  getBizAreaAnalysisChart,
  getBizAreaAnalysisMap,
  GetDistanceRankingChart,
  downloadBizAreaAnalysisDistanceRankingChart,
  downloadBizAreaAnalysisChart,
} from "@/features/ShopAnalytics/axios/bizArea";
import LoadingImg from "@/commons/components/loadingImg.vue";

const GRANULARITY = {
  CITY: { value: 0, text: "city" },
  TOWN: { value: 1, text: "town" },
} as const;

export default defineComponent({
  name: "BizAreaView",
  components: {
    LoadingImg,
    MonthlyDatePicker,
    StoreSelector,
    BizAreaShareChart,
    BizAreaMap,
    BizAreaRankingChart,
    UpdateBadge,
    NoDataChart,
  },
  data() {
    return {
      created: false,
      storeLoading: false,
      storePoint: undefined as
        | {
            lat: number; // 緯度
            lng: number; // 経度
          }
        | undefined,
      mapAreaList: [] as AreaListItem[],
      rankingAreaList: [] as AreaListItem[],
      activeArea: [] as AreaListItem[],
      areaShareData: [] as any,
      approveAlertAreaShareData: false,
      trendMapData: [] as any,
      trendMapSource: [] as any,
      bizAreaChartData: [] as any,
      granularity: GRANULARITY.TOWN[
        "value"
      ] as (typeof GRANULARITY)[keyof typeof GRANULARITY]["value"],
      radius: 5,
      viewRadius: 5,
      featureCollection: [] as MapBoxFeatures[],
      approveAlertMapData: false,
      maxRatio: 1,
      retryCount: 0,
      shareChartLoading: false,
      rankingChartLoading: false,
      hasAlertInDistanceRanking: false,
      hasAlertInBizAreaAnalysisMap: false,
      hasAlertInBizAreaAnalysisRanking: false,
      mapLoading: false,
      radiusList: [
        0.5, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20,
      ],
      isBizAreaMapNoData: false,
      isBizAreaRankingNoData: false,
    };
  },
  computed: {
    selectedStore(): Store | null {
      return this.$store.state.selectedStore;
    },
    hasRouterQueryPeriod(): boolean {
      return this.$route.query.period !== undefined;
    },
    hasRouterQueryRadius(): boolean {
      if (this.$route.query.radius === undefined) return false;
      if (isNaN(Number(this.$route.query.radius))) return false;
      return true;
    },
    hasRouterQueryViewRadius(): boolean {
      if (this.$route.query.v_radius === undefined) return false;
      if (isNaN(Number(this.$route.query.v_radius))) return false;
      return true;
    },
    hasRouterQueryUnit(): boolean {
      return this.$route.query.unit !== undefined;
    },
    emptyAreaShareData(): boolean {
      if (this.areaShareData.length === 0) return true;
      return (
        this.areaShareData
          // 最初はヘッダ、最後は数値固定なのでスキップ
          .slice(1, this.areaShareData.length - 1)
          .every(
            (areaShare: any[]) => areaShare[1] === 0 || areaShare[1] === null
          )
      );
    },
    // 商圏マップ取得でエリア名を取得するようになったら不要
    areaList(): AreaListItem[] {
      const tmpArray: AreaListItem[] = [...this.mapAreaList];
      tmpArray.forEach((mapItem: AreaListItem) => {
        const index = this.rankingAreaList.findIndex(
          (rankngItem: AreaListItem) => mapItem.areaId === rankngItem.areaId
        );
        if (index !== -1) {
          mapItem.areaName = this.rankingAreaList[index].areaName;
        }
      });
      return tmpArray;
    },
    hasChartAlert(): boolean {
      const me = this as any;
      return (
        !me.shareChartLoading &&
        me.hasAlertInDistanceRanking &&
        !me.approveAlertAreaShareData
      );
    },
    hasMapAlert(): boolean {
      const me = this as any;
      return (
        !me.mapLoading &&
        me.hasAlertInBizAreaAnalysisMap &&
        !me.approveAlertMapData
      );
    },
    getBizAreaAnalysisDistanceRankingFileId(): () => Promise<
      AxiosResponse<any, any>
    > {
      return () =>
        downloadBizAreaAnalysisDistanceRankingChart(
          this.selectedStore?.storeId,
          this.$store.state.startMonth,
          this.$store.state.endMonth
        );
    },
    getBizAreaAnalysisChartFileId(): () => Promise<AxiosResponse<any, any>> {
      return () =>
        downloadBizAreaAnalysisChart(
          this.selectedStore?.storeId,
          this.$store.state.startMonth,
          this.$store.state.endMonth,
          this.granularity,
          this.radius
        );
    },
    bizAreaAnalysisDistanceRankingCSVName(): string {
      const { start, end } = {
        start: convertSlashDelimiter(this.$store.state.startMonth),
        end: convertSlashDelimiter(this.$store.state.endMonth),
      };
      return `店舗分析_距離別カバー率_${start}-${end}`;
    },
    bizAreaAnalysisChartCSVName(): string {
      const { start, end } = {
        start: convertSlashDelimiter(this.$store.state.startMonth),
        end: convertSlashDelimiter(this.$store.state.endMonth),
      };
      return `店舗分析_商圏ランキング_${start}-${end}`;
    },
  },
  watch: {
    activeArea(newVal: AreaListItem[]) {
      this.bizAreaChartData = this.bizAreaChartData.map(
        (data: (string | number | any)[], index: number) => {
          if (index === 0)
            return [
              "areaName",
              this.selectedStore?.name ?? "",
              { role: "style" },
              { type: "string", role: "tooltip", p: { html: true } },
            ];
          if (newVal.length === 0) {
            return [
              data[0],
              data[1],
              "#B82F39",
              createBizAreaChartDataTooltipElement(
                data[0].match(/^\d+．(.+)$/)[1],
                data[1]
              ),
            ];
          } else {
            if (
              newVal.find((item: AreaListItem) => item.areaName === data[0])
            ) {
              return [
                data[0],
                data[1],
                "#B82F39",
                createBizAreaChartDataTooltipElement(
                  data[0].match(/^\d+．(.+)$/)[1],
                  data[1]
                ),
              ];
            } else {
              return [
                data[0],
                data[1],
                "#DE5A69",
                createBizAreaChartDataTooltipElement(
                  data[0].match(/^\d+．(.+)$/)[1],
                  data[1]
                ),
              ];
            }
          }
        }
      );
    },
    async selectedStore() {
      if (
        this.$store.state.startMonth < this.$store.state.endMonth &&
        this.created
      ) {
        // TODO: created でも使用しているため共通化
        this.$store.dispatch("initMonth");
        this.storePoint = undefined;
      }
      await this.fetchAll();
    },
  },
  async created() {
    this.storePoint = undefined;

    // クエリパラメータからデータ更新
    this.setDateFromRouterQuery();
    this.setRadiusFromRouterQuery();
    this.setViewRadiusFromRouterQuery();
    this.setUnitFromRouterQuery();

    // 店舗一覧取得
    this.storeLoading = true;
    if (!this.$store.state.stores.length)
      await this.$store.dispatch("fetchStores");
    // id があれば選択店舗を設定
    if (this.$route.params["id"]) {
      await this.$store.dispatch("specifiedStore", this.$route.params["id"]);
      if (!this.selectedStore) this.$router.push({ name: "NotFound" });
    } else this.$store.commit("initStore");
    this.storeLoading = false;

    await this.fetchAll();
  },
  methods: {
    resetActiveArea() {
      this.activeArea = [];
    },
    setDateFromRouterQuery() {
      if (this.hasRouterQueryPeriod) {
        let tmp = getPeriodByRouterQueryMonthPeriod(this.$route.query.period as string);
        if (tmp !== undefined) this.$store.commit("setMonth", tmp);
      }
    },
    setRadiusFromRouterQuery() {
      if (this.hasRouterQueryRadius && Number(this.$route.query.radius) > 0)
        this.radius = Number(this.$route.query.radius);
    },
    setViewRadiusFromRouterQuery() {
      if (
        this.hasRouterQueryViewRadius &&
        Number(this.$route.query.v_radius) > 0
      )
        this.viewRadius = Number(this.$route.query.v_radius);
    },
    setUnitFromRouterQuery() {
      if (!this.hasRouterQueryUnit) return;
      switch (this.$route.query.unit) {
        case GRANULARITY.CITY["text"]:
          this.granularity = GRANULARITY.CITY["value"];
          return;
        case GRANULARITY.TOWN["text"]:
          this.granularity = GRANULARITY.TOWN["value"];
          return;
        default:
          // CHECK: 不正のパラメータを受け取った場合はエラー等投げるか
          return;
      }
    },
    /**
     * datePicker コンポーネントから更新する用のメソッド
     */
    async updatePeriod(period: { startMonth: string; endMonth: string }) {
      this.$store.commit("setMonth", {
        startMonth: period.startMonth,
        endMonth: period.endMonth,
      });
      await this.fetchAll();
    },
    async fetchAll() {
      this.approveAlertAreaShareData = false;
      this.approveAlertMapData = false;

      if (!this.selectedStore) return;

      this.fetchAreaShareData();
      this.fetchMapData();
      this.storePoint = {
        lat: this.selectedStore.latitude,
        lng: this.selectedStore.longitude,
      };

      await this.pushRoute();
    },
    fetchAreaShareData() {
      this.shareChartLoading = true;

      GetDistanceRankingChart(
        this.selectedStore?.storeId,
        this.$store.state.startMonth,
        this.$store.state.endMonth
      )
        .then((res: AxiosResponse<DistanceRankingResponse>) => {
          this.areaShareData = processBizAreaShareChartData(
            res.data,
            this.selectedStore?.name ?? ""
          );
          this.hasAlertInDistanceRanking = hasAlertInDistanceRanking(res.data);
        })
        .catch(() => {
          notify.notifyErrorMessage("距離別カバー率が取得できませんでした。");
        })
        .finally(() => {
          this.shareChartLoading = false;
        });
    },
    fetchMapData() {
      this.mapLoading = true;
      this.rankingChartLoading = true;
      this.activeArea.splice(0);
      this.featureCollection = [];

      // 商圏マップを取得
      getBizAreaAnalysisMap(
        this.selectedStore?.storeId,
        this.$store.state.startMonth,
        this.$store.state.endMonth,
        {
          granularity: this.granularity,
          radius: this.radius,
        }
      )
        .then((res: AxiosResponse<BizAreaAnalysisMapResponse>) => {
          // NOTE: forEachで回す分だけdataで定義した最大値が更新される可能性があるので、最大値を確定させてから代入する
          const maxRate = getBizAreaMaxRate(res.data);
          // NOTE: 0を詰めるとヒートマップの濃度が0~0の範囲となりエラーが出るので0の場合は代入しない
          if (maxRate > 0) this.maxRatio = maxRate;

          this.isBizAreaMapNoData = isMapNoData(res.data);

          this.featureCollection = this.featureCollection.concat(
            res.data.features
          );
          this.mapAreaList = getMapAreaList(res.data);
        })
        .catch(() => {
          notify.notifyErrorMessage("商圏マップが表示できませんでした。");
        })
        .finally(() => {
          this.mapLoading = false;
        });

      // 商圏ランキングを取得
      getBizAreaAnalysisChart(
        this.selectedStore?.storeId,
        this.$store.state.startMonth,
        this.$store.state.endMonth,
        this.granularity,
        this.radius
      )
        .then((res: AxiosResponse<BizAreaAnalysisResponse>) => {
          this.bizAreaChartData = processChartData(
            res.data,
            this.selectedStore?.name ?? ""
          );

          const hasAlert = hasAlertInBizAreaAnalysis(res.data);
          this.hasAlertInBizAreaAnalysisMap = hasAlert;
          this.hasAlertInBizAreaAnalysisRanking = hasAlert;

          this.rankingAreaList = getRankingAreaList(res.data);
          this.isBizAreaRankingNoData = isRankingNoData(res.data);
        })
        .catch(() => {
          notify.notifyErrorMessage("商圏ランキングが表示できませんでした。");
        })
        .finally(() => {
          this.rankingChartLoading = false;
        });
    },

    async pushRoute() {
      if (!this.$store.state.selectedStore) return;

      const tmp = {
        start: convertHyphenDelimiter(this.$store.state.startMonth),
        end: convertHyphenDelimiter(this.$store.state.endMonth),
        unit:
          this.granularity === GRANULARITY.CITY["value"]
            ? GRANULARITY.CITY["text"]
            : GRANULARITY.TOWN["text"],
      };

      // NOTE: 他ページに遷移後にreplaceが呼び出されると戻される対策
      if (this.$route.name === "ShopAnalyticsBizArea") {
        await this.$router
          .replace({
            name: "ShopAnalyticsBizArea",
            params: { id: this.$store.state.selectedStore.storeId },
            query: {
              period: `${tmp.start}_${tmp.end}`,
              radius: String(this.radius),
              v_radius: String(this.viewRadius),
              unit: tmp.unit,
            },
          })
          .catch(() => undefined);
      }
    },
    updateActiveAreaByAreaName(areaName: string) {
      const activeArea: AreaListItem | undefined = this.areaList.find(
        (item: AreaListItem) => item.areaName === areaName
      );
      if (activeArea === undefined) return;

      if (this.activeArea.includes(activeArea)) {
        // ハイライト済みのエリアであれば除外
        this.activeArea = this.activeArea.filter((item: AreaListItem) => item !== activeArea);
      } else {
        // 未ハイライトのエリアであれば追加
        this.activeArea = this.activeArea.concat(activeArea);
      }
    },
    updateActiveAreaByAreaId(areaId: string) {
      const activeArea: AreaListItem | undefined = this.areaList.find(
        (item: AreaListItem) => item.areaId === areaId
      );
      if (activeArea === undefined) return;

      if (this.activeArea.includes(activeArea)) {
        // ハイライト済みのエリアであれば除外
        this.activeArea = this.activeArea.filter((item: AreaListItem) => item !== activeArea);
      } else {
        // 未ハイライトのエリアであれば追加
        this.activeArea = this.activeArea.concat(activeArea);
      }
    },
    clickApproveAlertAreaShareData() {
      this.approveAlertAreaShareData = true;
    },
    clickApproveAlertMapData() {
      this.approveAlertMapData = true;
    },
  },
});

function createBizAreaChartDataTooltipElement(title: string, value: number) {
  return `
    <div style='display: flex; justify-content: center; align-items: center; height: 55px; color: #222222; font-size: 12px !important;'>
      <div style='display:table-cell; vertical-align:middle; text-align: left;'>
        <div>${title}</div>
        <div>[構成比] <b>
          ${(Math.round(value * 1000) / 10).toFixed(1) + "%"}
        </b></div>
      </div>
    </div>
`;
}
</script>

<style scoped>
.map_title {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.biz_area_sub_setting {
  height: 48px;
  background-color: #f5f5f5;
  opacity: 0.9;
  padding: 0px 20px;
  display: flex;
  align-items: center;
  margin-left: 42px;
  font-size: 14px;
}
.granularity_form {
  margin: 0px 20px;
}
.form {
  height: 30px;
  width: 50px;
  outline: 1px solid #cccccc;
  border-radius: 4px;
  opacity: 1;
  text-align: center;
  margin-left: 3px;
  margin-right: 3px;
  background: #ffffff;
}
.button {
  margin-left: 10px;
  width: 50px;
  height: 28px;
  background: #222222 0% 0% no-repeat padding-box;
  box-shadow: 1px 1px 0px #00000029;
  border-radius: 4px;
  opacity: 1;
  color: #ffffff;
  font-weight: bold;
}
.reset_button {
  font-size: 12px;
  font-weight: normal;
  color: #4d99d0;
}
.card-in-column-2 {
  padding-left: 20px;
}
.divider {
  border-bottom: 5px solid #eee;
  margin: 40px -30px;
}
.bizarea-container {
  position: relative;
}
.mr-27px {
  margin-right: 27px;
}
</style>
