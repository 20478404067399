import { axiosClient } from "@/plugins/axios";
import { AxiosResponse } from "axios";
import { convertHyphenDelimiter } from "@/commons/utils/dateUtil";
import { BizAreaAnalysisComparisonMapResponse } from "@/features/StoreCompare/bizArea/interfaces/response";
import { ComparisonTrendAreaAnalysisResponse } from "@/features/StoreCompare/bizArea/types";
import { DownloadResponse } from "@/commons/interfaces/responses/download";
import qs from "qs";

/**
 * 店舗比較の商圏マップを取得
 * @param baseStoreId 基準店舗ID
 * @param store 店舗IDリスト(基準店舗を除く)
 * @param start 期間開始日(yyyy-MM-dd)
 * @param end 期間終了日(yyyy-MM-dd)
 * @param granularity 表示粒度(0: 市区町村, 1: 町丁目)
 * @param radius データ補足範囲(店舗を中心とした半径、単位はkm)
 */
export function getBizAreaComparisonMap(
  baseStoreId: string | undefined,
  store: string[],
  start: string,
  end: string,
  granularity: number,
  radius: number
): Promise<AxiosResponse<BizAreaAnalysisComparisonMapResponse>> {
  if (!baseStoreId) return Promise.reject();
  return axiosClient.post<BizAreaAnalysisComparisonMapResponse>(
    "/stores/chart/trend-area-analysis/store-comparison/geo/",
    {
      baseStore: baseStoreId,
      stores: store,
      start: convertHyphenDelimiter(start),
      end: convertHyphenDelimiter(end),
      mapOptions: {
        granularity: granularity,
        radius: radius,
      },
    }
  );
}

/**
 * 店舗比較の商圏ランキングを取得
 * @param baseStoreId 基準店舗ID
 * @param store 店舗IDリスト(基準店舗を除く)
 * @param start 期間開始日(yyyy-MM-dd)
 * @param end 期間終了日(yyyy-MM-dd)
 * @param granularity 表示粒度(0: 市区町村, 1: 町丁目)
 * @param radius データ補足範囲(店舗を中心とした半径、単位はkm)
 */
export function getBizAreaComparisonTable(
  baseStore: string | undefined,
  store: string[],
  start: string,
  end: string,
  granularity: number,
  radius: number
): Promise<AxiosResponse<ComparisonTrendAreaAnalysisResponse>> {
  if (!baseStore) return Promise.reject();
  return axiosClient.get<ComparisonTrendAreaAnalysisResponse>(
    "/stores/chart/trend-area-analysis/store-comparison/",
    {
      params: {
        baseStore: baseStore,
        store: store,
        start: convertHyphenDelimiter(start),
        end: convertHyphenDelimiter(end),
        granularity: granularity,
        radius: radius,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    }
  );
}

/**
 * 店舗比較の商圏ランキングDL用URLを取得
 * @param baseStoreId 基準店舗ID
 * @param store 店舗IDリスト(基準店舗を除く)
 * @param start 期間開始日(yyyy-MM-dd)
 * @param end 期間終了日(yyyy-MM-dd)
 * @param granularity 表示粒度(0: 市区町村, 1: 町丁目)
 * @param radius データ補足範囲(店舗を中心とした半径、単位はkm)
 */
export function downloadBizAreaComparisonTable(
  baseStore: string | undefined,
  store: string[],
  start: string,
  end: string,
  granularity: number,
  radius: number
): Promise<AxiosResponse<DownloadResponse>> {
  return axiosClient.get<DownloadResponse>(
    "/stores/chart/trend-area-analysis/store-comparison/download/",
    {
      params: {
        baseStore: baseStore,
        store: store,
        start: convertHyphenDelimiter(start),
        end: convertHyphenDelimiter(end),
        granularity: granularity,
        radius: radius,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    }
  );
}
