import { axiosClient } from "@/plugins/axios";
import { AxiosResponse } from "axios";
import {
  CitiesResponse,
  PrefecturesResponse,
  StoresFavoritesResponse,
  PostStoresFavoritesBody,
  PostStoresComparisonBody,
  PutStoresFavoritesBody,
  PutStoresComparisonBody,
  DeleteStoresFavoritesBody,
  StoresHistoryResponse,
} from "@/features/Dashboard/types";

/**
 * お気に入り条件グループリストの取得
 *
 * @returns Response Promise
 */
export function getStoresFavorites(): Promise<
  AxiosResponse<StoresFavoritesResponse>
> {
  return axiosClient.get<StoresFavoritesResponse>("/stores/favorites/");
}

/**
 * お気に入り条件グループリストの登録
 *
 * @param body - リクエストボディ
 * @returns Response Promise
 */
export function postStoresFavorites(
  body: PostStoresFavoritesBody
): Promise<AxiosResponse> {
  return axiosClient.post("/stores/favorites/", body);
}

/**
 * お気に入り条件グループリストの更新
 *
 * @param body - リクエストボディ
 * @returns Response Promise
 */
export function putStoresFavorites(
  body: PutStoresFavoritesBody
): Promise<AxiosResponse> {
  return axiosClient.put("/stores/favorites/", body);
}

/**
 * お気に入り条件グループリストの複数件削除
 *
 * @param body - リクエストボディ
 * @returns Response Promise
 */
export function deleteStoresFavorites(
  body: DeleteStoresFavoritesBody
): Promise<AxiosResponse> {
  return axiosClient.put("/stores/favorites/delete/", body);
}

/**
 * 都道府県一覧取得
 *
 * @returns Response Promise
 */
export function getPrefectures(): Promise<AxiosResponse<PrefecturesResponse>> {
  return axiosClient.get<PrefecturesResponse>("/prefectures/");
}

/**
 * 市区町村一覧取得
 *
 * @returns Response Promise
 */
export function getCities(): Promise<AxiosResponse<CitiesResponse>> {
  return axiosClient.get<CitiesResponse>("/cities/");
}

/**
 * 店舗比較リストの登録
 *
 * @param body - リクエストボディ
 * @returns Response Promise
 */
export function postStoresComparison(
  body: PostStoresComparisonBody
): Promise<AxiosResponse> {
  return axiosClient.post("/stores/comparison/", body);
}

/**
 * 店舗比較リストの更新
 *
 * @param body - リクエストボディ
 * @returns Response Promise
 */
export function putStoresComparison(
  body: PutStoresComparisonBody
): Promise<AxiosResponse> {
  return axiosClient.put("/stores/comparison/", body);
}

/**
 * 店舗比較リストの複数件削除
 *
 * @param groupIds - 削除対象の店舗比較リストid群
 * @returns Response Promise
 */
export function deleteStoresComparison(
  groupIds: string[]
): Promise<AxiosResponse> {
  const params = "?groupIds=" + groupIds.join("&groupIds=");
  return axiosClient.put("/stores/comparison/delete/" + params);
}

/**
 * 更新履歴取得
 *
 * @returns Response Promise
 */
export function getStoresHistory(): Promise<
  AxiosResponse<StoresHistoryResponse>
> {
  return axiosClient.get<StoresHistoryResponse>("/stores/history/");
}
