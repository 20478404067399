<template>
  <v-container class="pa-0" fluid>
    <GChart type="AreaChart" :data="chartData" :options="options" />
    <v-row class="my-0" dense>
      <v-col>
        <div class="my-0" style="display: flex; align-items: center; font-size: 12px; color: #666">
          <chart-legend :legends="legends" />
          <v-spacer />
          <alert-caption v-if="isAlert" variants="circle" />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script setup lang="ts">
import { ref, computed } from 'vue'
import { GChart } from 'vue-google-charts'
import ChartLegend from '@/features/ChainAnalytics/visitor/components/ChartLegend.vue'
import { COLOR } from '@/commons/enums'

const props = withDefaults(
  defineProps<{
    chartData: any[]
    subType: number
    isAlert: boolean
    legends: { text: string; color: string }[]
  }>(),
  {
    chartData: () => [],
    subType: undefined,
    isAlert: false,
    legends: () => []
  }
)

const defaultChartColors = ref([COLOR.RED])
const genderChartColors = ref([COLOR.BLUE, COLOR.RED])
const ageChartColors = ref([
  COLOR.RED,
  COLOR.BLUE,
  COLOR.GREEN,
  COLOR.ORANGE,
  COLOR.PURPLE,
  COLOR.BROWN
])
const options = computed(() => {
  let lineWidth = 3
  let pointSize = 6
  if (props.chartData.length > 31) {
    lineWidth = 2
    pointSize = 0
  }

  let colorArray = []
  if (props.subType === 1) {
    colorArray = genderChartColors.value
  } else if (props.subType === 2) {
    colorArray = ageChartColors.value
  } else colorArray = defaultChartColors.value

  return {
    areaOpacity: 0.1,
    chartArea: {
      top: 25,
      left: 80,
      right: 10,
      bottom: 90
    },
    colors: colorArray,
    hAxis: {
      textStyle: {
        fontSize: 12
      },
      slantedText: true,
      slantedTextAngle: 270
    },
    height: 550,
    isStacked: true,
    legend: {
      position: 'none'
    },
    lineWidth: lineWidth,
    pointSize: pointSize,
    tooltip: {
      isHtml: true
    },
    vAxis: {
      textStyle: {
        fontSize: 12
      },
      baselineColor: '#ccc',
      gridlines: {
        color: '#ddd'
      },
      minValue: 0
    }
  }
})
</script>

<style scoped>
::-webkit-scrollbar {
  -webkit-appearance: none;
  height: 7px;
  width: 7px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.circle {
  display: inline-block;
  margin-right: 6px;
  width: 10px;
  height: 10px;
  border: 2px solid #666;
  border-radius: 50%;
}
</style>
