<template>
  <div class="compare-update">
    <div class="side-bar">
      <div class="heading">
        <div>店舗比較リスト</div>
        <v-divider class="mx-2" />
      </div>
      <div class="nav">
        <router-link
          :to="{ name: 'CompareList' }"
          class="nav-link"
        >
          一覧に戻る
        </router-link>
      </div>
    </div>
    <div class="main">
      <h1 v-if="type === 'create'">
        比較リストを作成
      </h1>

      <!-- HACK: コンポーネントとして切り出す -->
      <div
        v-if="type === 'edit'"
        class="edit-card"
      >
        <div class="name">
          <div class="wrap">
            <CustomInput
              v-model="name"
              variant="borderless"
              width="530px"
              height="26px"
              class="name-input"
            />
            <span class="count">
              <span :class="{ 'error-color': name.length > 30 }">
                {{ name.length }}
              </span>
              / 30
            </span>
          </div>
          <hr :class="{ error: name.length > 30 }">
        </div>
        <div class="meta">
          <p>[最終更新者] {{ updatedBy }}</p>
          <p>[最終更新日時] {{ updatedAt }}</p>
          <p>[最終更新内容] {{ updateNote }}</p>
        </div>
      </div>

      <p :class="{ 'mb-20px': type === 'edit', 'mb-34px': type === 'create' }">
        MAPから店舗を検索し、周辺の比較店舗を選択してください。
      </p>

      <div class="map-card">
        <LoadingImg
          v-if="loading"
          height="580px"
        />
        <StoreSelectMap
          v-else
          :store-types="selectedStoreTypes"
          :selected-stores="selectedStores"
          @update-selected-store="updateSelectedStore($event)"
        />
        <div class="map_footer">
          <div class="head">
            業種
          </div>
          <div class="body">
            <StoreTypeChip
              v-for="storeType in $store.state.storeTypes"
              :key="storeType.id"
              :text="storeType.name"
              :color="
                storeType.id === STORE_TYPE.SUPER_MARKET['value']
                  ? 'red'
                  : storeType.id === STORE_TYPE.DRUG_STORE['value']
                    ? 'green'
                    : storeType.id === STORE_TYPE.HOME_CENTER['value']
                      ? 'yellow'
                      : storeType.id === STORE_TYPE.CVS_STORE['value']
                        ? 'purple'
                        : undefined
              "
              :inactive="!isActive(storeType)"
              class="ml-10px"
              @click="clickStoreType(storeType)"
            />
          </div>
        </div>
      </div>
      <div class="result-card">
        <h1>選択中の店舗<span>（最大6店舗）</span></h1>
        <div class="chips">
          <StoreChip
            v-for="store in selectedStores"
            :key="store.storeId"
            :store="store"
            class="mrb-8px"
            @click-close="removeSelectedStore($event)"
          />
        </div>
      </div>
      <div
        class="button-wrap"
        :class="{
          'justify-center': type === 'create',
          'justify-between': type === 'edit',
        }"
      >
        <div
          v-if="type === 'edit'"
          class="delete"
        >
          <DeleteButton @click="deleteDialog = true" />
          <span>このリストを削除</span>
        </div>
        <CustomButton
          variant="primary"
          size="md"
          width="360px"
          height="46px"
          class="custom-button"
          :disabled="selectedStores.length === 0 || name.length > 30"
          @click="dialog = true"
        >
          リストを{{ type === "create" ? "作成" : "更新" }}
        </CustomButton>
        <div
          v-if="type === 'edit'"
          class="space"
        />
      </div>
      <CreateDialog
        v-if="type === 'create'"
        v-model="dialog"
        :loading="loading"
        @click-create="clickCreate($event)"
      />
      <EditDialog
        v-if="type === 'edit'"
        v-model="dialog"
        :loading="loading"
        @click-edit="clickEdit($event)"
      />
      <DeleteDialog
        v-if="type === 'edit' && id"
        v-model="deleteDialog"
        :targets="[name]"
        :loading="loading"
        @click-delete="clickDelete"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, reactive } from "vue";
import CustomButton from "@/commons/components/Elements/CustomButton.vue";
import LoadingImg from "@/commons/components/loadingImg.vue";
import StoreSelectMap from "@/features/Dashboard/components/StoreSelectMap.vue";
import StoreTypeChip from "@/features/Dashboard/components/StoreTypeChip.vue";
import StoreChip from "@/features/Dashboard/components/StoreChip.vue";
import DeleteButton from "@/features/Dashboard/components/DeleteButton.vue";
import CreateDialog from "@/features/Dashboard/components/CreateDialog.vue";
import EditDialog from "@/features/Dashboard/components/EditDialog.vue";
import DeleteDialog from "@/features/Dashboard/components/DeleteDialog.vue";
import { Store } from "@/commons/interfaces/responses/store";
import { convertDateAndTimeSlashDelimiter } from "@/commons/utils/dateUtil";
import { STORE_TYPE as STORE_TYPE_ENUM } from "@/commons/enums";
import { ComparisonGroup } from "@/commons/interfaces";
import {
  postStoresComparison,
  putStoresComparison,
  deleteStoresComparison,
} from "@/features/Dashboard/axios";
import * as notify from "@/plugins/notification";
import { useStore} from "vuex";
import { useRouter } from 'vue-router';

export default defineComponent({
  name: "CompareUpdate",
  components: {
    CustomButton,
    LoadingImg,
    StoreTypeChip,
    StoreChip,
    DeleteButton,
    StoreSelectMap,
    CreateDialog,
    EditDialog,
    DeleteDialog,
  },
  props: {
    type: {
      type: String as PropType<"create" | "edit">,
      required: true,
    },
    id: {
      type: String,
      default: undefined,
    },
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const loading = ref(false);
    const selectedStores = ref([] as Store[]);
    const selectedStoreTypes = ref<{id:number,name:string}[]>([]);
    const dialog = ref(false);
    const STORE_TYPE = reactive(STORE_TYPE_ENUM);
    const name = ref("");
    const updateNote = ref("");
    const updatedAt = ref("");
    const updatedBy = ref("");
    const deleteDialog = ref(false);

    const created = async() => {     
      loading.value = true;
      // 店舗一覧
      if (!store.state.stores.length)
        await store.dispatch("fetchStores");

      // 店舗比較リスト一覧
      if (!store.state.comparisonGroups.length)
        await store.dispatch("fetchComparisonGroups");

      // 選択可能な店舗形態一覧
      if (!store.state.storeTypes.length)
        await store.dispatch("fetchStoreTypes");

      // 編集
      if (props.type === "edit" && props.id) {
        const group = (
          store.state.comparisonGroups as ComparisonGroup[]
        ).find((group) => group.id === props.id);
        if (!group) {
          notify.notifyErrorMessage("比較リストが取得できませんでした。");
          return;
        }
        name.value = group.name;
        updateNote.value = group.update_note;
        updatedAt.value = convertDateAndTimeSlashDelimiter(group.updated_at);
        updatedBy.value = group.updated_by;

        const ids = group.stores.map((store) => store.id);
        selectedStores.value = (store.state.stores as Store[]).filter(
          (store) => ids.includes(store.storeId)
        );
      }

      selectedStoreTypes.value = store.state.storeTypes;
      loading.value = false;
    };

    const isActive = (type: { id: number; name: string })  => {
      return selectedStoreTypes.value.includes(type);
    };

    const clickStoreType = (type: { id: number; name: string }) =>{
      if (selectedStoreTypes.value.includes(type)) {
        if (selectedStoreTypes.value.length <= 1) return;
        selectedStoreTypes.value = selectedStoreTypes.value.filter(
          (t) => t !== type
        );
      } else selectedStoreTypes.value.push(type);
    };

    const updateSelectedStore = (event: { store: Store }) => {
      // 無ければ追加
      if (!selectedStores.value.some((s) => s.storeId === event.store.storeId)) {
        // 上限(最大6店舗)に達していた場合は何もしない
        if (selectedStores.value.length >= 6) return;
        selectedStores.value.push(event.store);
      }
      // あれば削除
      else
        selectedStores.value = selectedStores.value.filter(
          (s) => s.storeId !== event.store.storeId
        );
    };
    const removeSelectedStore = (event: { store: Store }) => {
      selectedStores.value = selectedStores.value.filter(
        (s) => s.storeId !== event.store.storeId
      );
    };
    const clickCreate = async(event: { name: string }) => {
      loading.value = true;
      await postStoresComparison({
        name: event.name.length ? event.name : "無題の店舗比較リスト",
        store: selectedStores.value.map((store) => store.storeId),
      })
        .then(() => {
          (async () => {
            await store.dispatch("fetchComparisonGroups");
          })();
          return router.push({ name: "CompareList" });
        })
        .catch(() => {
          notify.notifyErrorMessage("比較リストが作成できませんでした。");
        });
      loading.value = false;
    };

    const  clickEdit = async(event: { updateNote: string }) => {
      loading.value = true;
      if( props.id === undefined ){
        loading.value = false;
        return;
      }
      await putStoresComparison({
        id: props.id,
        name: name.value,
        store: selectedStores.value.map((store) => store.storeId),
        update_note: event.updateNote,
      })
        .then(() => {
          (async () => {
            await store.dispatch("fetchComparisonGroups");
          })();
          router.push({ name: "CompareList" });
        })
        .catch(() => {
          notify.notifyErrorMessage("比較リストの更新に失敗しました。");
        });
      loading.value = false;
    };
    const clickDelete = async() => {
      loading.value = true;
      if( props.id === undefined ){
        loading.value = false;
        return;
      }
      await deleteStoresComparison([props.id])
        .then(() => {
          (async () => {
            await store.dispatch("fetchComparisonGroups");
          })();
          router.push({ name: "CompareList" });
        })
        .catch(() => {
          notify.notifyErrorMessage("指定の比較リストが削除できませんでした。");
        });
      loading.value = false;
    };

    created();

    return {
      loading,
      selectedStores,
      selectedStoreTypes,
      dialog,
      STORE_TYPE,
      name,
      updateNote,
      updatedAt,
      updatedBy,
      deleteDialog,
      isActive,
      clickStoreType,
      updateSelectedStore,
      removeSelectedStore,
      clickCreate,
      clickEdit,
      clickDelete,
    };
  },
});
</script>

<style lang="scss" scoped>
.compare-update {
  display: flex;
  margin-top: 32.5px;

  .side-bar {
    display: flex;
    flex-direction: column;
    min-width: 206.5px;
    height: 80px;

    .heading {
      display: flex;
      align-items: center;
      width: 100%;
      height: 35px;
      font-size: 14px;
      color: #888;
    }

    .nav {
      margin-top: auto;
      text-align: left;

      .nav-link {
        text-decoration: none;
        font-size: 14px;
        color: #222222;
      }
    }
  }

  .main {
    margin-left: 33.5px;
    width: 100%;

    h1 {
      margin-bottom: 12px;
      text-align: left;
      font-size: 24px;
      font-weight: bold;
      color: #222222;
    }

    p {
      text-align: left;
      font-size: 14px;
      color: #222222;
    }

    .edit-card {
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;
      padding: 23px 25px;
      height: 170px;
      background-color: #ffffff;
      box-shadow: 0px 0px 3px #00000029;

      .name {
        max-width: 600px;
        .wrap {
          display: flex;
          .name-input {
            font-size: 18px;
            font-weight: bold;
            color: #222222;
          }
          .count {
            margin-left: auto;
          }
          .error-color {
            color: #be0000;
          }
        }

        hr {
          margin-top: 7px;
          margin-bottom: 20px;
          border-top: 1px solid #cccccc;
        }

        .error {
          border-top: 1px solid #bc0711;
        }
      }

      .meta {
        p {
          margin-bottom: 4px;
          font-size: 13px;
          color: #666666;
        }
      }
    }

    .map-card {
      margin-bottom: 24px;
      box-shadow: 0px 0px 3px #00000029;

      .map_footer {
        display: flex;
        align-items: center;
        height: 59px;
        border-top: 1px solid #eeeeee;
        background-color: #ffffff;
        width: 100%;
        min-width: 1100px;

        .head {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          width: 80px;
          background: #f5f5f5 0% 0% no-repeat padding-box;
          font-size: 14px;
          font-weight: bold;
        }

        .body {
          display: flex;
        }
      }
    }

    .result-card {
      margin-bottom: 25px;
      padding: 26px 30px;
      min-height: 200px;
      background-color: #ffffff;
      box-shadow: 0px 0px 3px #00000029;
      border: 4px solid #e4d03c;

      h1 {
        margin-bottom: 25px;
        font-size: 16px;
        font-weight: bold;
        color: #222222;

        span {
          font-weight: normal;
          color: #666666;
        }
      }

      .chips {
        display: flex;
        flex-wrap: wrap;
      }
    }

    .button-wrap {
      display: flex;

      .custom-button {
        margin-bottom: 39px;
      }

      .delete {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 170px;
        height: 46px;

        span {
          font-size: 15px;
          color: #666666;
        }
      }

      .space {
        width: 170px;
        height: 46px;
      }
    }
  }

  .ml-10px {
    margin-left: 10px;
  }

  .mrb-8px {
    margin-right: 8px;
    margin-bottom: 7px;
  }

  .mb-20px {
    margin-bottom: 20px;
  }

  .mb-34px {
    margin-bottom: 34px;
  }

  .mb-69px {
    margin-bottom: 69px;
  }

  .justify-center {
    justify-content: center;
  }

  .justify-between {
    justify-content: space-between;
  }
}
</style>
