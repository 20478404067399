<template>
  <v-container fluid class="share_table" style="background-color: white">
    <div class="table_title d-flex align-center">
      併用率ランキング
      <AlertTooltip
        v-if="hasAlert"
        class="ml-2"
        text="取得データボリュームが少なく、統計上の信頼性の低いデータが含まれています。該当箇所は、参考値としてご参照ください。"
      />
      <v-spacer />
      <download-button
        label="CSVデータ"
        :disabled="!baseStoreData"
        :get-file-id="props.fileId"
        :csv-name="props.csvName"
      />
    </div>
    <loading-img v-if="loading" style="height: 600px" />
    <div v-else-if="baseStoreData === undefined && !loadFinished" class="unselected_card">
      店舗を選択するとチャートが表示されます。
    </div>
    <no-data-chart v-else-if="isNoData && loadFinished" />
    <div v-else>
      <v-data-table
        v-model:items-per-page="itemsPerPage"
        v-model:page="page"
        :items="combinationStoreData"
        :items-per-page-options="[10, 25, 50, -1]"
      >
        <template v-if="baseStoreData" #headers>
          <tr class="table_head">
            <th class="column_head">順位</th>
            <th class="column_name">店舗名</th>
            <th class="column_type column_sortable">
              <sort-button
                class="column_sort_button"
                :show-status="{
                  up: sortBy !== 'storeType' || !sortDesc,
                  down: sortBy !== 'storeType' || sortDesc
                }"
                @sort-action="sortColumn('storeType')"
              />
              <span class="column_sort_text">業種</span>
            </th>
            <td class="column_distance column_sortable">
              <sort-button
                class="column_sort_button"
                :show-status="{
                  up: sortBy !== 'distance' || !sortDesc,
                  down: sortBy !== 'distance' || sortDesc
                }"
                @sort-action="sortColumn('distance')"
              />
              <span class="column_sort_text">距離</span>
            </td>
            <td class="column_address">
              <sort-button
                :show-status="{
                  up: sortBy !== 'address' || !sortDesc,
                  down: sortBy !== 'address' || sortDesc
                }"
                @sort-action="sortColumn('address')"
              >
                エリア名
              </sort-button>
            </td>
            <td class="column_value column_sortable">
              <sort-button
                class="column_sort_button"
                :show-status="{
                  up: sortBy !== 'combinationRatio' || !sortDesc,
                  down: sortBy !== 'combinationRatio' || sortDesc
                }"
                @sort-action="sortColumn('combinationRatio')"
              />
              <span class="column_sort_text">併用率</span>
            </td>
          </tr>
          <tr class="table_body row_base">
            <th class="column_head base_head">基準店舗</th>
            <th class="column_name">
              <router-link
                :to="{
                  name: 'ShopAnalyticsVisitor',
                  params: { id: baseStoreData.storeId }
                }"
                class="store_link"
              >
                {{ baseStoreData.name }}
              </router-link>
            </th>
            <th class="column_type">
              <span
                :class="{
                  supermarket: baseStoreData.storeType === STORE_TYPE.SUPER_MARKET.value,
                  drugstore: baseStoreData.storeType === STORE_TYPE.DRUG_STORE.value,
                  homecenter: baseStoreData.storeType === STORE_TYPE.HOME_CENTER.value,
                  cvsstore: baseStoreData.storeType === STORE_TYPE.CVS_STORE.value
                }"
              >
                ●
              </span>
              {{ STORE_TYPE.toLocalString(baseStoreData.storeType) }}
            </th>
            <th class="column_distance">0m</th>
            <th class="column_address_value">
              {{ baseStoreData.address }}
            </th>
            <th class="column_value">ー</th>
          </tr>
        </template>
        <template #body="props">
          <tr
            v-for="compData in props.items"
            :key="compData.storeId + '_' + compData.name"
            class="table_body"
          >
            <th class="column_head">
              {{ compData.rank }}
            </th>
            <th class="column_name">
              <router-link
                :to="{
                  name: 'ShopAnalyticsVisitor',
                  params: { id: compData.storeId }
                }"
                class="store_link"
              >
                {{ compData.name }}
              </router-link>
            </th>
            <th class="column_type">
              <span
                :class="{
                  supermarket: compData.storeType === STORE_TYPE.SUPER_MARKET.value,
                  drugstore: compData.storeType === STORE_TYPE.DRUG_STORE.value,
                  homecenter: compData.storeType === STORE_TYPE.HOME_CENTER.value,
                  cvsstore: compData.storeType === STORE_TYPE.CVS_STORE.value
                }"
              >
                ●
              </span>
              {{ STORE_TYPE.toLocalString(compData.storeType) }}
            </th>
            <th class="column_distance">{{ Number(compData.distance).toLocaleString() }}m</th>
            <th class="column_address_value">
              {{ compData.address }}
            </th>
            <th class="column_value">
              <div
                class="column_value_fill"
                :style="getBackgroundBarStyle(compData.combinationRatio)"
              />
              <div class="column_value_text">
                {{
                  !Number.isNaN(compData.combinationRatio)
                    ? formatVisitRateValue(compData.combinationRatio) + '%'
                    : 'ー'
                }}
              </div>
            </th>
          </tr>
        </template>
        <template #bottom />
      </v-data-table>
    </div>
  </v-container>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue'
import LoadingImg from '@/commons/components/loadingImg.vue'
import { CombinationTableItem } from '@/features/ShopAnalytics/interfaces/component'
import { STORE_TYPE } from '@/commons/enums'
import SortButton from '@/commons/components/Elements/SortButton.vue'
import NoDataChart from '@/features/ShopAnalytics/components/Common/noDataChart.vue'
import { AxiosResponse } from 'axios'
import AlertTooltip from '@/commons/components/Elements/AlertTooltip.vue'

const props = withDefaults(
  defineProps<{
    loading: boolean
    baseStoreData: CombinationTableItem | undefined
    combinationStoreData: CombinationTableItem[]
    sortBy: string
    sortDesc: boolean
    loadFinished: boolean
    fileId: Promise<AxiosResponse>
    csvName: string
    hasAlert: boolean
  }>(),
  {
    loading: false,
    baseStoreData: undefined,
    combinationStoreData: () => [],
    sortBy: undefined,
    sortDesc: false,
    loadFinished: false,
    csvName: '',
    hasAlert: false
  }
)
const emit = defineEmits(['sort'])

const page = ref<number>(1)
const maxValue = ref<number>(1)
const itemsPerPage = ref<number>(50)

const isNoData = computed((): boolean => {
  return props.baseStoreData === undefined || props.combinationStoreData.length === 0
})
const sortColumn = (sortKey: string) => {
  emit('sort', sortKey)
}
const getMaxValue = () => {
  let _maxValue = 0
  if (props.baseStoreData) {
    const baseMaxValue = props.baseStoreData.combinationRatio
    if (_maxValue < baseMaxValue) _maxValue = baseMaxValue
  }
  let compMaxValue = 0
  if (
    (props.combinationStoreData && props.combinationStoreData.length < itemsPerPage.value) ||
    itemsPerPage.value === -1
  ) {
    props.combinationStoreData?.forEach((compData: CombinationTableItem) => {
      if (compMaxValue < compData.combinationRatio) compMaxValue = compData.combinationRatio
    })
  } else {
    const currentData = props.combinationStoreData.slice(
      (page.value - 1) * itemsPerPage.value,
      page.value * itemsPerPage.value
    )
    currentData.forEach((compData: CombinationTableItem) => {
      if (compMaxValue < compData.combinationRatio) compMaxValue = compData.combinationRatio
    })
  }

  if (_maxValue < compMaxValue) _maxValue = compMaxValue
  maxValue.value = _maxValue
}
const getBackgroundBarStyle = (value: number) => {
  return {
    width: `${(value / maxValue.value) * 100}%`
  }
}
const formatVisitRateValue = (rawValue: number): string => {
  // 割合が0~1の範囲で渡されるのでこれを100倍して0~100の範囲に変換する
  // 小数点以下2桁の部分を四捨五入したいので10倍して四捨五入し、10で割る
  // 小数点以下1桁だけ欲しいのでtoFixedする
  return (Math.round(rawValue * 100 * 10) / 10).toFixed(1)
}

watch(
  () => props.combinationStoreData,
  () => {
    getMaxValue()
  }
)
watch(page, () => {
  getMaxValue()
})
watch(itemsPerPage, () => {
  getMaxValue()
})
</script>

<style>
.share_table .v-table__wrapper {
  overflow-x: hidden;
}
</style>
<style scoped lang="scss">
.v-data-table :deep(table) {
  border-collapse: collapse !important;
  border: 1px solid #ccc !important;
}
.v-data-table :deep(td) {
  border-collapse: collapse !important;
  border: 1px solid #ccc !important;
  font-size: 13px !important;
}
.v-data-table :deep(th) {
  border-collapse: collapse !important;
  border: 1px solid #ccc !important;
  user-select: text !important;
  font-size: 13px !important;
}
.share_table {
  padding: 30px !important;

  .table_title {
    font: normal normal bold 14px/30px Noto Sans JP;
    margin-bottom: 20px;
  }
  tr {
    th {
      padding: 0px 10px !important;
      height: 44px !important;
      background-color: white;
    }
    th:first-child {
      background-color: #f5f5f5;
    }
    th.column_head {
      text-align: center;
      position: sticky;
      left: 0;
    }
  }
  .column_head {
    min-width: 85px;
    width: 85px;
    max-width: 85px;
  }
  .column_name {
    line-height: 2em;
  }
  .column_type {
    min-width: 135px;
    width: 135px;
    max-width: 135px;
  }
  .column_distance {
    min-width: 90px;
    width: 90px;
    max-width: 90px;
  }
  .column_address {
    line-height: 2em;
  }
  .column_address_value {
    font-weight: normal !important;
  }
  .column_value {
    min-width: 125px;
    width: 125px;
    max-width: 125px;
  }
  .table_head {
    th,
    td {
      background-color: #f5f5f5;
      height: 44px;
      font-weight: bold;
    }
    .column_sortable {
      position: relative;
      align-items: center;
      justify-content: end;

      .column_sort_text {
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .column_sort_button {
        position: absolute;
        right: 8.5px;
        cursor: pointer;
        transform: translate(0%, -50%);
      }
    }
  }
  .row_base {
    th {
      background: #f4fbff 0% 0% no-repeat padding-box;
    }
    .base_head {
      background-color: #4d99d0;
      color: #ffffff !important;
      font-weight: bold;
      padding: 0px 8px !important;
    }
    .column_value {
      justify-content: center !important;
    }
  }
  .table_body {
    .column_head {
      font-weight: bold;
    }
    .column_name {
      text-align: start;
      left: 70px;
    }
    .column_type {
      text-align: start;
      position: sticky;
      left: calc(70px + 212px);
    }
    .column_distance {
      text-align: end;
    }
    .column_address {
      text-align: start;
      left: 70px;
    }
    .column_value {
      position: relative;
      text-align: center;

      .column_value_fill {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        height: 26px;
        line-height: 26px;
        background: #eeeeee 0% 0% no-repeat padding-box;
        max-width: 103px; // NOTE: セル幅(125px) - padding(左右10px) - border-width(1px)
      }

      .column_value_text {
        position: absolute;
        inset: 0;
        margin: auto;
        height: 26px;
        line-height: 26px;
        z-index: 2;
      }
    }
  }
}
.supermarket {
  color: #e47075;
}
.drugstore {
  color: #87b8a1;
}
.homecenter {
  color: #cb9e56;
}
.cvsstore {
  color: #9278c3;
}
.sort_button {
  width: 6.78px;
  height: 12px;
  margin-right: 8.4px;
}
.value_bar {
  width: 100%;
  height: 22px;
  background: #eeeeee 0% 0% no-repeat padding-box;
}
.store_link {
  color: #4d99d0;
  text-decoration-line: none;
}
</style>
