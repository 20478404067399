import { axiosClient } from "@/plugins/axios";
import { AxiosResponse } from "axios";
import { DownloadResponse } from "@/commons/interfaces/responses/download";
import qs from "qs";
import { convertHyphenDelimiter } from "@/commons/utils/dateUtil";

/**
 * 来店頻度チャートDL用URL生成
 * @param {string[]} chainIds チェーンIDのリスト
 * @param {string[]} prefectureIds 都道府県IDのリスト
 * @param {string} start 期間開始日(yyyy-MM-dd)
 * @param {string} end 期間終了日(yyyy-MM-dd)
 */
export function downloadVisitFrequencyChart(
  chainIds: string[],
  prefectureIds: string[],
  start: string,
  end: string
): Promise<AxiosResponse<DownloadResponse>> {
  return axiosClient.get<DownloadResponse>(
    "/chains/chart/visit-frequency/chain-comparison/download/",
    {
      params: {
        chainIds,
        prefectureIds,
        start: convertHyphenDelimiter(start),
        end: convertHyphenDelimiter(end),
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    }
  );
}

/**
 * 来店頻度チャートDL用URL生成
 * @param {string[]} chainIds チェーンIDのリスト
 * @param {string[]} prefectureIds 都道府県IDのリスト
 * @param {string} start 期間開始日(yyyy-MM-dd)
 * @param {string} end 期間終了日(yyyy-MM-dd)
 */
export function downloadEngagementChart(
  chainIds: string[],
  prefectureIds: string[],
  start: string,
  end: string
): Promise<AxiosResponse<DownloadResponse>> {
  return axiosClient.get<DownloadResponse>(
    "/chains/chart/engagement/chain-comparison/download/",
    {
      params: {
        chainIds,
        prefectureIds,
        start: convertHyphenDelimiter(start),
        end: convertHyphenDelimiter(end),
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    }
  );
}
