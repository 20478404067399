import { axiosClient } from "@/plugins/axios";
import { AxiosResponse } from "axios";
import {
  RegisteredUsersResponse,
  UserDetailResponse,
} from "@/features/UserSetting/interfaces/response";

export function getUsers(): Promise<AxiosResponse<RegisteredUsersResponse>> {
  return axiosClient.get<RegisteredUsersResponse>("/users/");
}
export function getUser(
  id: string | undefined
): Promise<AxiosResponse<UserDetailResponse>> {
  if (typeof id === "undefined") return Promise.reject();
  return axiosClient.get<UserDetailResponse>(`/users/${id}/`);
}

export function createUser(
  name: string,
  mail: string,
  department: string | undefined
): Promise<AxiosResponse> {
  return axiosClient.post("/users/temporary-registration/", {
    name: name,
    mail: mail,
    department: department,
  });
}

export function registerUser(
  name: string,
  mail: string,
  invitationKey: string,
  department: string | undefined,
  password: string
) {
  return axiosClient.post("/create-users/", {
    name: name,
    mail: mail,
    invitation_key: invitationKey,
    department: department,
    password: password,
  });
}

export function checkRegister(mail: string, invitationKey: string) {
  return axiosClient.get("/users/check-register/", {
    params: {
      mail: mail,
      invitationKey: invitationKey,
    },
  });
}

export function editUser(
  id: string,
  name: string,
  department: string | undefined
): Promise<AxiosResponse> {
  return axiosClient.put(`/users/${id}/`, {
    name: name,
    department: department,
    isActive: true,
  });
}

export function passwordReset(mail: string): Promise<AxiosResponse> {
  return axiosClient.post("/users/reset-link-mail/", {
    mail: mail,
  });
}

export function deleteUser(id: string): Promise<AxiosResponse> {
  return axiosClient.put(`/users/inactivate/`, null, {
    params: {
      userId: id,
    },
  });
}
