<template>
  <button
    class="store-type-chip"
    :class="{ inactive: inactive }"
    :style="`border: 2px solid ${colorCode}`"
    v-bind="$attrs"
  >
    <img :src="checkCircleSvgPath">
    <span>{{ text }}</span>
  </button>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import checkCircleGrayImg from "@/assets/svg/check-circle-gray.svg";
import checkCircleRedImg from "@/assets/svg/check-circle-red.svg";
import checkCircleGreenImg from "@/assets/svg/check-circle-green.svg";
import checkCircleYellowImg from "@/assets/svg/check-circle-yellow.svg";
import checkCirclePurpleImg from "@/assets/svg/check-circle-purple.svg";

const checkCircleImgPath = {
  gray: checkCircleGrayImg,
  red: checkCircleRedImg,
  green: checkCircleGreenImg,
  yellow: checkCircleYellowImg,
  purple: checkCirclePurpleImg,
};

export default defineComponent({
  name: "StoreTypeChip",
  props: {
    text: {
      type: String,
      default: undefined,
    },
    inactive: {
      type: Boolean,
    },
    color: {
      type: String as PropType<"red" | "green" | "yellow" | "purple">,
      default: "red",
    },
  },
  computed: {
    colorCode(): string {
      if (this.color === "red") return "#e47075";
      if (this.color === "green") return "#87b8a1";
      if (this.color === "yellow") return "#cb9e56";
      if (this.color === "purple") return "#a993d3";
      return "#e47075";
    },
    checkCircleSvgPath(): string {
      if (this.inactive) {
        return checkCircleImgPath["gray"];
      } else {
        return checkCircleImgPath[this.color];
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.store-type-chip {
  width: 130px;
  height: 32px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 100px;
  opacity: 1;
  font-size: 12px;
  padding: 0px 9px;
  text-align: start;
  align-items: center;
  display: flex;
  border: 2px solid #e47075;

  span {
    width: 100%;
    text-align: center;
  }
}

.inactive {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cccccc !important;
  color: #999999 !important;
}
</style>
