<template>
  <v-app
    :class="{ 'bg-gray': !isLogin }"
    class="v-app"
  >
    <company-selector v-if="showNavigation && viewableCompanySelector" />
    <app-bar v-if="showNavigation" />
    <v-main
      id="app"
      :class="{
        'sidebar-close-app':
          !show || !hasSubMenu || windowWidth < 1280,
        'sidebar-open-app': show,
        'viewable-companies-app': viewableCompanySelector,
        'sidebar-close-app-app': isResetPassword,
      }"
    >
      <notifications classes="vue-notification" />
      <shop-analytics-navigation
        v-if="showNavigation && hasSubMenu"
        v-model:show="show"
      />
      <v-container
        fluid
        class="main-content"
      >
        <router-view @show="showBar = !showBar" />
      </v-container>
      <tos-dialog
        :dialog="showTos"
        @close-dialog="showTos = false"
      />
    </v-main>
    <v-footer
      class="footer"
      :class="{ 'bg-white': isLogin }"
    >
      <div class="footer-contents-1">
        <span v-if="!isLogin && !isNotFound">
          各数値は、スマートフォンの位置情報（GPS）などを元に、AIで解析・推定しています。
        </span>
      </div>
      <div
        v-if="!isLogin"
        class="footer-line"
      />
      <div
        v-if="isLogin"
        class="footer-spacer"
      />
      <div class="footer-contents-2">
        <router-link
          v-show="!isLogin && !isResetPassword"
          class="tos-margin"
          :to="{ name: 'Manual' }"
        >
          操作ガイド
        </router-link>
        <a
          class="tos-margin"
          @click="showTos = true"
        >利用規約</a>
        <router-link
          v-show="!isLogin && !isResetPassword"
          :to="{ name: 'FAQ' }"
        >
          FAQ・お問い合わせ
        </router-link>
        <v-spacer />
        <div class="copyright">
          ©︎ unerry Inc.
        </div>
      </div>
    </v-footer>
  </v-app>
</template>

<script lang="ts">
import AppBar from "@/commons/components/GlobalNavigation/AppBar.vue";
import ShopAnalyticsNavigation from "@/commons/components/GlobalNavigation/ShopAnalyticsNavigationDrawer.vue";
import TosDialog from "@/commons/components/TosDialog.vue";
import { ROUTE_TYPE } from "@/commons/types/route";
import CompanySelector from "@/commons/components/CompanySelector/CompanySelector.vue";
import { sessionCheck } from "@/commons/axios/auth";
import type { AxiosResponse } from "axios";
import { AuthResponse } from "@/commons/types/Auth";
import { defineComponent, ref } from "vue";
import { useStore } from "vuex";
import { UserType } from "@/features/Dashboard/enums";

export default defineComponent({
  components: {
    CompanySelector,
    AppBar,
    ShopAnalyticsNavigation,
    TosDialog,
  },
  setup() {
    const store = useStore();
    const show = ref(true);
    const showBar = ref(false);
    const windowWidth = ref(window.innerWidth);
    const showTos = ref(false);

    return {
      show,
      showBar,
      windowWidth,
      showTos,
      store,
    };
  },
  computed: {
    isLogin(): boolean {
      return this.$route.name === "Login";
    },
    isResetPassword(): boolean {
      return this.$route.name === "ResetPassword";
    },
    isNotFound(): boolean {
      return this.$route.name === "NotFound";
    },
    showNavigation(): boolean {
      return this.$route.meta?.hasNavigation === true;
    },
    hasSubMenu(): boolean {
      return (
        this.$route.meta?.routeType === ROUTE_TYPE.analytics ||
        this.$route.meta?.routeType === ROUTE_TYPE.compare ||
        this.$route.meta?.routeType === ROUTE_TYPE.chainAnalytics ||
        this.$route.meta?.routeType === ROUTE_TYPE.chainCompare ||
        this.$route.meta?.routeType === ROUTE_TYPE.trend
      )
    },
    hasRouterParamsId(): boolean {
      return this.$route.params["id"] !== undefined;
    },
    viewableCompanySelector(): boolean {
      return this.store.state.userType === UserType.FULL_ADMIN ||
        this.store.state.userType === UserType.LIMITED_ADMIN
    }
  },
  mounted() {
    window.addEventListener("resize", (this as any).calculateWindowWidth);
    sessionCheck().then((res: AxiosResponse<AuthResponse>) => {
      window.dataLayer.push({
        event: "user_property_push",
        userId: res.data.userId,
        mail: res.data.mail,
        companyName: res.data.companyName,
      });
    }).catch(() => {
      // 401 errorが表示されるので catch しておく
    });
  },
  beforeUnmount() {
    window.removeEventListener("resize", (this as any).calculateWindowWidth);
  },
  methods: {
    calculateWindowWidth() {
      (this as any).windowWidth = window.innerWidth;
    },
  },
});
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap");

.v-app {
  overflow-x: scroll;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #222222;
  font-family: "Noto Sans JP";
}

.bg-white {
  background-color: #fff !important;
}

.bg-gray {
  background-color: #f5f5f5 !important;
}

.sidebar-open-app {
  padding: 76px 50px 0 286px !important;
}

.sidebar-close-app {
  padding: 76px 50px 0 !important;
}

.viewable-companies-app {
  padding-top: 121px !important;
}

.sidebar-close-app-app {
  padding-top: 50px !important;
}

.v-card {
  padding: 30px !important;
  border-radius: 0px !important;
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 15%), 0 2px 2px 0 rgb(0 0 0 / 15%),
    0 1px 2px 0 rgb(0 0 0 / 10%) !important;
}

.card {
  padding: 30px !important;
}

.card-title {
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  height: 30px;
  width: 100%;
}

.card-title span {
  font-size: 16px;
  font-weight: bold;
  line-height: 30px;
}

.card-title a {
  font-size: 12px;
  font-weight: normal;
  color: #4d99d0;
}

.container {
  margin: 0 !important;
  padding: 32px 16px !important;
}

.main-content {
  padding: 0 !important;
  min-width: 1000px;
}

.footer {
  display: flex;
  flex-wrap: wrap;
  padding: 0 50px !important;
  margin-top: 30px !important;
  margin-bottom: 50px !important;
  height: 90px !important;
  font-size: 12px;
  color: #666;
  z-index: 5;
  background: none !important;
}

.footer-contents-1 {
  width: 100%;
  text-align: right;
}

.footer-contents-1 span {
  font: normal normal normal 12px/17px Noto Sans JP;
  letter-spacing: 0px;
  color: #666;
}

.footer-line {
  margin: 30px 0 24px 0;
  height: 1px;
  width: 100%;
  background-color: #ccc;
}

.footer-spacer {
  height: 90px;
}

.footer-contents-2 {
  display: flex;
  align-items: flex-end;
  justify-content: left;
  width: 100%;
}

.footer-contents-2 a {
  font: normal normal normal 13px/19px Noto Sans JP;
  letter-spacing: 0px;
  color: #999 !important;
  text-decoration: none;
  cursor: pointer;
}

.footer-contents-2 a:hover {
  color: #666 !important;
}

.copyright {
  font-size: 11px;
  color: #999 !important;
}

.vue-notification {
  padding: 20px !important;
  margin: 10px 10px 1px 10px !important;
  font: normal normal normal 14px/24px Noto Sans JP !important;
  border: none !important;

  &.success {
    background-color: #4d99d0 !important;
  }
  &.info {
    background-color: #2196f3 !important;
  }
  &.error {
    background-color: #be0000 !important;
  }
}

.prepend-text {
  width: 5em;
}

.title-container {
  display: flex;
  align-items: center;
  height: 98px;
}

.title-text {
  font-size: 24px;
  text-align: left;
  color: #222;
}

.card-container {
  margin-bottom: 22px !important;
}

.last-card-container {
  margin-bottom: 30px !important;
}

.unselected_card {
  background-color: #eeeeee;
  display: flex;
  height: 600px;
  width: 100%;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}

.google-visualization-tooltip {
  color: white !important;
  min-width: 125px;
  padding-right: 5px !important;
  padding-left: 5px !important;
  text-align: center;
  display: table;
  font-size: 0px !important;
  pointer-events: none;
}

.mapboxgl-popup-content {
  padding: 0;
}

.theme--light.v-list-item .v-list-item__mask {
  color: rgba(0, 0, 0, 0.38);
  background: #f4fbff !important;
}

.tos-margin {
  margin-right: 24px !important;
}

.news-content p a {
  color: #4d99d0;
}

.news-content h1 {
  font-size: 18px;
  margin-top: 40px;
  margin-bottom: 26px;
}
</style>
