<template>
  <v-dialog
    :value="dialog"
    max-width="560px"
    @click:outside="close"
  >
    <div class="edit-dialog">
      <h1>
        更新内容<span>（50文字まで）</span>
        <span class="count">
          <span :class="{ 'error-color': text.length > 50 }">
            {{ text.length }}
          </span>
          / 50
        </span>
      </h1>
      <CustomTextArea
        v-model:text="text"
        width="480px"
        height="75px"
        placeholder="更新内容を入力"
        :error="text.length > 50"
        @input="handleEvent($event)"
      />
      <CustomButton
        variant="primary"
        size="md"
        class="mt-auto"
        width="280px"
        height="46px"
        :disabled="text.length > 50 || loading"
        @click="clickEdit"
      >
        {{ type === "compare" ? "リスト" : "条件" }}を更新
      </CustomButton>
    </div>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent, PropType, ref } from "vue";
import CustomTextArea from "@/commons/components/Elements/CustomTextArea.vue";

export default defineComponent({
  name: "EditDialog",
  components: { CustomTextArea },
  model: {
    prop: "dialog",
    event: "change-dialog",
  },
  props: {
    dialog: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    type: {
      type: String as PropType<"compare" | "favorite">,
      default: "compare",
    },
  },
  emits: ["change-dialog", "clickEdit"],
  setup(props, { emit }) {
    const text = ref("");
    const close = () => {
      emit("change-dialog", false);
    }
    const clickEdit = () => {
      emit("clickEdit", { updateNote: text.value });
    }
    const handleEvent = (event: any) => {
      if (typeof event === 'string'){
        text.value = event;
      }      
    }
    return {
      text,
      close,
      clickEdit,
      handleEvent,
    }
  },
});
</script>

<style lang="scss" scoped>
.edit-dialog {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 35px 40px;
  width: 560px;
  height: 251px;
  border-radius: 6px;
  background: white;

  h1 {
    display: flex;
    margin-bottom: 14px;
    text-align: left;
    font-size: 15px;
    font-weight: bold;
    color: #222222;
    width: 100%;

    span {
      font-weight: normal;
      color: #666666;
    }

    .count {
      margin-left: auto;
      font-weight: normal;
      color: #666666;
    }

    .error-color {
      color: #be0000;
    }
  }

  .mb-26px {
    margin-bottom: 26px;
  }

  .mt-auto {
    margin-top: auto;
  }

  .mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
