<template>
  <div>
    <div class="search-box" :style="'width: ' + width">
      <div class="search-box-inner">
        <input
          v-model="word"
          type="text"
          placeholder="都道府県、市区町村、町丁目の一部を入力"
          @keydown.down="handleKeyDown"
          @keydown.up="handleKeyUp"
          @keydown.enter="handleEnter"
          @input="handleInput"
          @change="handleChange"
        />
        <img v-show="word.length === 0" src="@/assets/svg/magnify.svg" />
        <img
          v-show="word.length > 0"
          src="@/assets/svg/close-middle.svg"
          class="cursor-pointer"
          @click.prevent="handleClose"
        />
      </div>
      <div v-if="filteredRegions.length > 0" class="list-container" :style="'width: ' + listWidth">
        <ul>
          <li
            v-for="region of filteredRegions"
            :key="region.id"
            :class="{
              'is-focused': isFocused(region)
            }"
            @click="handleSelectRegion(region)"
            @mouseover="handleSetFocus(region)"
            @mouseleave="handleUnsetFocus(region)"
          >
            <span class="name-store-type">
              <span class="name">
                {{ region.name }}
              </span>
            </span>
          </li>
        </ul>
      </div>
      <div v-if="isNotFound" class="not-found" :style="'width: ' + listWidth">
        キーワードに合致する結果が⾒つかりませんでした。
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { containsAllKeywords } from '@/commons/utils/keyword-match'
import { debounce } from 'lodash'
import { ref, computed, watch } from 'vue'
import { SearchRegion } from '../types'
import { useStore } from 'vuex'
import { Prefecture, City, TownResponse } from '@/commons/interfaces'
import { getTowns } from '@/commons/axios/towns'
import { GRANULARITY } from '@/commons/enums'

const LIST_LIMIT = 300

/* --------------------------------------------------------------------------
  props
 ---------------------------------------------------------------------------*/

const props = withDefaults(
  defineProps<{
    selectedPrefectureId: number | undefined
    selectedCityIds: string[]
    granularity?: (typeof GRANULARITY)[keyof typeof GRANULARITY]
    width: string
    listWidth: string
    onRegionUpdate: (region: SearchRegion) => void
  }>(),
  {
    selectedPrefectureId: undefined,
    selectedCityIds: (): string[] => [],
    granularity: GRANULARITY.PREFECTURE,
    width: '100%',
    listWidth: '100%',
    onRegionUpdate: () => undefined
  }
)

/* --------------------------------------------------------------------------
  Vuex
 ---------------------------------------------------------------------------*/

const store = useStore()
const cities = computed<City[]>(() => store.state.cities)
const prefectureMap = computed<Record<number, Prefecture>>(() => store.getters.prefectureMap)
const cityMap = computed<Record<string, City>>(() => store.getters.cityMap)

/* --------------------------------------------------------------------------
  core
 ---------------------------------------------------------------------------*/

const word = ref<string>('')
const focusedRegion = ref<SearchRegion | null>(null)
const filteredRegions = ref<SearchRegion[]>([]) // debounce で更新する関係上 computed ではなく ref で定義
const isNotFound = ref<boolean>(false) // debounce で更新する関係上 computed ではなく ref で定義
const townsLoading = ref<boolean>(false)
const townsRes = ref<TownResponse | undefined>()
const regions = computed<SearchRegion[]>(() => {
  if (!townsRes.value || !props.selectedPrefectureId) {
    return []
  }

  const filteredTowns = townsRes.value?.towns.filter((town) => {
    if (props.granularity === GRANULARITY.PREFECTURE) {
      return town
    }

    if (props.granularity === GRANULARITY.MUNICIPALITIES) {
      return town
    }

    if (props.granularity === GRANULARITY.TOWN_AND_AREA) {
      return props.selectedCityIds.includes(town.cityId)
    }
  })

  return filteredTowns.map((town) => ({
    id: town.townId,
    name:
      prefectureMap.value[props.selectedPrefectureId as number]['name'] +
      cityMap.value[town.cityId]['name'] +
      town.name,
    longitude: town.longitude,
    latitude: town.latitude
  }))
})

const handleInput = debounce(() => {
  if (word.value) {
    filteredRegions.value = regions.value
      .filter((region) => containsAllKeywords(region.name, word.value))
      .slice(0, LIST_LIMIT)
  } else {
    filteredRegions.value = []
  }

  isNotFound.value = filteredRegions.value.length === 0 && word.value.length > 0
}, 200)

const handleKeyDown = () => {
  if (filteredRegions.value.length === 0) return

  if (!focusedRegion.value) {
    focusedRegion.value = filteredRegions.value[0] ?? null
  } else {
    const nextIndex =
      (filteredRegions.value.indexOf(focusedRegion.value) + 1) % filteredRegions.value.length
    focusedRegion.value = filteredRegions.value[nextIndex]
  }
}

const handleKeyUp = () => {
  if (filteredRegions.value.length === 0) return

  if (!focusedRegion.value) {
    focusedRegion.value = filteredRegions.value[filteredRegions.value.length - 1] ?? null
  } else {
    const prevIndex =
      (filteredRegions.value.indexOf(focusedRegion.value) + filteredRegions.value.length - 1) %
      filteredRegions.value.length
    focusedRegion.value = filteredRegions.value[prevIndex]
  }
}

const handleEnter = (event: KeyboardEvent) => {
  if (focusedRegion.value && !event.isComposing) {
    handleSelectRegion(focusedRegion.value)
  }
}

const handleChange = () => {
  focusedRegion.value = null
}

const handleClose = () => {
  filteredRegions.value = []
  focusedRegion.value = null
  word.value = ''
}

const handleSetFocus = (region: SearchRegion) => {
  focusedRegion.value = region
}

const handleUnsetFocus = (region: SearchRegion) => {
  if (focusedRegion.value === region) focusedRegion.value = null
}

const handleSelectRegion = (region: SearchRegion) => {
  filteredRegions.value = []
  focusedRegion.value = null
  word.value = region.name
  props.onRegionUpdate(region)
}

const isFocused = (region: SearchRegion) => focusedRegion.value === region

const fetchTowns = async () => {
  if (townsLoading.value || !cities.value || !cities.value.length) {
    return
  }

  try {
    townsLoading.value = true
    const cityIds = cities.value
      .filter((city) => city.prefectureId === props.selectedPrefectureId)
      .map((city) => city.cityId)
    const res = await getTowns(cityIds)
    townsRes.value = res.data
  } finally {
    townsLoading.value = false
  }
}

/* --------------------------------------------------------------------------
  created
 ---------------------------------------------------------------------------*/

if (props.selectedPrefectureId) {
  fetchTowns()
}

/* --------------------------------------------------------------------------
  watch
 ---------------------------------------------------------------------------*/

watch(
  () => [props.selectedPrefectureId],
  () => {
    fetchTowns()
  }
)
</script>

<style scoped>
.search-box {
  position: relative;
  width: 100%;
}
.search-box-inner {
  display: flex;
  padding: 11px 15px 13px;
  width: 100%;
  height: 44px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  background: #ffffff;
  font-size: 14px;
}
.search-box-inner:hover {
  border: 1px solid #999999;
}
.search-box-inner:focus {
  outline: 1px solid #999999;
}
.search-box-inner input {
  margin-right: auto;
  width: 100%;
  height: 100%;
  outline: none;
}
.search-box-inner input::placeholder {
  color: #999999;
}
.list-container {
  position: absolute;
  width: 100%;
  top: 47px;
  left: 0;
  padding: 14px 16px;
  max-height: 350px;
  background: #fff;
  border: 1px solid #cccccc;
  border-radius: 4px;
  overflow-y: auto;
  z-index: 4;
  color: #000000;
}
.list-container ul {
  text-align: left;
  list-style: none;
  padding: 0;
}
.list-container ul li {
  cursor: pointer;
  height: 36px;
  padding: 8px 11px 8px 9px;
}
.is-focused {
  background: #f5f5f5;
}
.is-selected {
  background: #f4fbff 0% 0% no-repeat padding-box;
}
.is-disabled {
  color: #666666;
  pointer-events: none;
}
.name-store-type {
  display: flex;
  align-content: center;
}
.name-store-type .name {
  font-weight: bold;
  font-size: 14px;
}
.name-store-type .store-type {
  color: #666666;
  margin-left: 13px;
  font-size: 13px;
}
.not-found {
  position: absolute;
  width: 100%;
  top: 47px;
  left: 0;
  padding: 25px;
  display: flex;
  align-regions: center;
  justify-content: center;
  background: #fff;
  border: 1px solid #cccccc;
  border-radius: 4px;
  font-size: 13px;
  z-index: 2;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
