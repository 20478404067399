<template>
  <div>
    <v-btn
      v-if="!show"
      width="24px"
      height="26px"
      icon
      fixed
      class="nav-show-toggle-button, open-button"
      :class="{
        'open-button-viewable-companies': $store.state.viewableCompanies.length >= 2
      }"
      @click.stop="childShow = !childShow"
    >
      <img src="@/assets/svg/sidebar-chevron-right.svg" />
    </v-btn>
    <v-navigation-drawer
      v-model="childShow"
      floating
      :mobile-breakpoint="1280"
      :class="{ 'mobile-navigation-drawer': windowWidth < 1280 }"
    >
      <div
        class="heading"
        :class="{
          'heading-viewable-companies': $store.state.viewableCompanies.length >= 2
        }"
      >
        <div>{{ title }}</div>
        <v-divider class="mx-2" />
        <v-btn
          width="26px"
          height="26px"
          icon
          class="nav-show-toggle-button close-button"
          @click.stop="childShow = !childShow"
        >
          <img src="@/assets/svg/sidebar-chevron-left.svg" />
        </v-btn>
      </div>
      <!-- 各ページへのメニュー -->
      <ul class="navigation">
        <li v-for="m in menuItem" :key="m.name">
          <router-link
            :to="{ name: m.view, params: { id: m.id }, query: m.query }"
            :class="{ 'navigation-link-active': routeName === m.view }"
          >
            {{ m.name }}
          </router-link>
        </li>
      </ul>
    </v-navigation-drawer>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { convertHyphenDelimiter } from '@/commons/utils/dateUtil'
import { ROUTE_TYPE } from '@/commons/types/route'

export default defineComponent({
  name: 'AnalyticsNavigationDrawer',
  props: {
    show: { type: Boolean }
  },
  data() {
    return {
      windowWidth: window.innerWidth
    }
  },
  computed: {
    routeType() {
      return (this as any).$route.meta?.routeType
    },
    routeName() {
      return (this as any).$route.name
    },
    title() {
      switch (this.routeType) {
        case ROUTE_TYPE.analytics:
          return '店舗分析'
        case ROUTE_TYPE.compare:
          return '店舗比較'
        case ROUTE_TYPE.chainAnalytics:
          return 'チェーン分析'
        case ROUTE_TYPE.chainCompare:
          return 'チェーン比較'
        case ROUTE_TYPE.trend:
          return 'トレンド'
        default:
          return ''
      }
    },
    menuItem() {
      const start = convertHyphenDelimiter((this as any).$store.state.startDate)
      const end = convertHyphenDelimiter((this as any).$store.state.endDate)
      const startMonth = convertHyphenDelimiter((this as any).$store.state.startMonth)
      const endMonth = convertHyphenDelimiter((this as any).$store.state.endMonth)
      const compareStart = (this as any).$store.state.compareStartDate
        ? convertHyphenDelimiter((this as any).$store.state.compareStartDate)
        : undefined
      const compareEnd = (this as any).$store.state.compareStartDate
        ? convertHyphenDelimiter((this as any).$store.state.compareEndDate)
        : undefined
      const selectedChainId = (this as any).$store.state.selectedChainId
      const selectedChainIds = (this as any).$store.state.selectedChainIds
      const selectedPrefectureIdsOfChain = (this as any).$store.state.selectedPrefectureIdsOfChain
      const selectedPrefectureIdsOfChains = (this as any).$store.state.selectedPrefectureIdsOfChains

      let routeList = [] as any[]

      switch (this.routeType) {
        case ROUTE_TYPE.analytics:
          const selectedStore =
            (this as any).$store.state.selectedStore !== null
              ? (this as any).$store.state.selectedStore
              : undefined
          routeList = [
            {
              name: '来店人数推移',
              view: 'ShopAnalyticsVisitor',
              id: selectedStore ? selectedStore.storeId : null,
              query: {
                period: `${start}_${end}`,
                c_period: compareStart || compareEnd ? `${compareStart}_${compareEnd}` : undefined,
                unit: 'date',
                subIndicator: 'none'
              }
            },
            {
              name: '曜日/時間別人数',
              view: 'ShopAnalyticsTrendVisitor',
              id: selectedStore ? selectedStore.storeId : null,
              query: {
                period: `${start}_${end}`,
                c_period: compareStart || compareEnd ? `${compareStart}_${compareEnd}` : undefined
              }
            },
            {
              name: 'ペルソナ特性',
              view: 'ShopAnalyticsPersona',
              id: selectedStore ? selectedStore.storeId : null,
              query: {
                period: `${start}_${end}`,
                c_period: compareStart || compareEnd ? `${compareStart}_${compareEnd}` : undefined
              }
            },
            {
              name: '来店特性',
              view: 'ShopAnalyticsVisitEngagement',
              id: selectedStore ? selectedStore.storeId : null
            },
            {
              name: '商圏分析',
              view: 'ShopAnalyticsBizArea',
              id: selectedStore ? selectedStore.storeId : null,
              query: {
                period: `${startMonth}_${endMonth}`,
                radius: 5,
                v_radius: 5,
                unit: 'town'
              }
            },
            {
              name: '併用分析',
              view: 'ShopAnalyticsCombination',
              id: selectedStore ? selectedStore.storeId : null,
              query: {
                period: `${startMonth}_${endMonth}`,
              }
            }
          ]
          break
        case ROUTE_TYPE.compare:
          const selectedGroup =
            (this as any).$store.state.selectedComparisonGroup !== null
              ? (this as any).$store.state.selectedComparisonGroup
              : undefined
          routeList = [
            {
              name: '来店人数推移',
              view: 'StoreCompareVisitor',
              id: selectedGroup ? selectedGroup.id : null,
              query: {
                period: `${start}_${end}`,
                unit: 'date'
              }
            },
            {
              name: '曜日/時間別人数',
              view: 'StoreCompareTrendVisitor',
              id: selectedGroup ? selectedGroup.id : null,
              query: {
                period: `${start}_${end}`
              }
            },
            {
              name: 'ペルソナ特性',
              view: 'StoreComparePersona',
              id: selectedGroup ? selectedGroup.id : null,
              query: {
                period: `${start}_${end}`
              }
            },
            {
              name: '来店特性',
              view: 'StoreCompareVisitEngagement',
              id: selectedGroup ? selectedGroup.id : null
            },
            {
              name: '商圏分析',
              view: 'StoreCompareBizArea',
              id: selectedGroup ? selectedGroup.id : null,
              query: {
                period: `${startMonth}_${endMonth}`,
                radius: 5,
                v_radius: 5,
                unit: 'town'
              }
            }
          ]
          break
        case ROUTE_TYPE.chainAnalytics:
          routeList = [
            {
              name: '来店人数推移',
              view: 'ChainAnalyticsVisitor',
              query: {
                period: `${start}_${end}`,
                c_period: compareStart || compareEnd ? `${compareStart}_${compareEnd}` : undefined,
                unit: 'date',
                subIndicator: 'none',
                chainId: selectedChainId,
                prefectureIds: selectedPrefectureIdsOfChain
              }
            },
            {
              name: '曜日/時間別人数',
              view: 'ChainAnalyticsTrendVisitor',
              query: {
                period: `${start}_${end}`,
                c_period: compareStart || compareEnd ? `${compareStart}_${compareEnd}` : undefined,
                chainId: selectedChainId,
                prefectureIds: selectedPrefectureIdsOfChain
              }
            },
            {
              name: 'ペルソナ特性',
              view: 'ChainAnalyticsPersona',
              query: {
                period: `${start}_${end}`,
                c_period: compareStart || compareEnd ? `${compareStart}_${compareEnd}` : undefined,
                chainId: selectedChainId,
                prefectureIds: selectedPrefectureIdsOfChain
              }
            },
            {
              name: '来店特性',
              view: 'ChainAnalyticsVisitEngagement',
              query: {
                chainId: selectedChainId,
                prefectureIds: selectedPrefectureIdsOfChain
              }
            },
            {
              name: '商圏分析',
              view: 'ChainAnalyticsBizArea',
              query: {
                period: `${startMonth}_${endMonth}`,
                chainId: selectedChainId,
                prefectureIds: selectedPrefectureIdsOfChain
              }
            },
            {
              name: '併用分析',
              view: 'ChainAnalyticsCombination',
              query: {
                period: `${startMonth}_${endMonth}`,
                chainId: selectedChainId,
                prefectureIds: selectedPrefectureIdsOfChain
              }
            }
          ]
          break
        case ROUTE_TYPE.chainCompare:
          routeList = [
            {
              name: '来店人数推移',
              view: 'ChainCompareVisitor',
              query: {
                period: `${start}_${end}`,
                unit: 'date',
                chainIds: selectedChainIds,
                prefectureIds: selectedPrefectureIdsOfChains
              }
            },
            {
              name: '曜日/時間別人数',
              view: 'ChainCompareTrendVisitor',
              query: {
                period: `${start}_${end}`,
                chainIds: selectedChainIds,
                prefectureIds: selectedPrefectureIdsOfChains
              }
            },
            {
              name: 'ペルソナ特性',
              view: 'ChainComparePersona',
              query: {
                period: `${start}_${end}`,
                chainIds: selectedChainIds,
                prefectureIds: selectedPrefectureIdsOfChains
              }
            },
            {
              name: '来店特性',
              view: 'ChainCompareVisitEngagement',
              query: {
                chainIds: selectedChainIds,
                prefectureIds: selectedPrefectureIdsOfChains
              }
            },
            {
              name: '商圏分析',
              view: 'ChainCompareBizArea',
              query: {
                period: `${startMonth}_${endMonth}`,
                chainIds: selectedChainIds,
                prefectureIds: selectedPrefectureIdsOfChains
              }
            }
          ]
          break
        case ROUTE_TYPE.trend:
          routeList = [
            {
              name: '居住者に人気の店舗',
              view: 'TrendStore'
            },
            {
              name: '居住者に人気のチェーン',
              view: 'TrendChain'
            }
          ]
      }
      return routeList
    },
    childShow: {
      get() {
        return (this as any).show
      },
      set(val: any) {
        return (this as any).$emit('update:show', val)
      }
    }
  },
  mounted() {
    window.addEventListener('resize', (this as any).calculateWindowWidth)
  },
  beforeUnmount() {
    window.removeEventListener('resize', (this as any).calculateWindowWidth)
  },
  methods: {
    calculateWindowWidth() {
      ;(this as any).windowWidth = window.innerWidth
    }
  }
})
</script>

<style scoped>
.mobile-navigation-drawer {
  background-color: #f5f5f5 !important;
  opacity: 0.92 !important;
  padding-right: 30px !important;
  width: 290px !important;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.16) !important;
  top: 0px !important;
  height: 100% !important;
}
.v-navigation-drawer {
  z-index: 4 !important;
  transition-property: box-shadow, visibility, left;

  &.v-theme--light {
    background-color: transparent;
  }
}
.v-navigation-drawer__scrim {
  z-index: 2 !important;
  background-color: rgb(33, 33, 33);
  opacity: 0.46;
}

button.nav-show-toggle-button {
  background-color: white;
}

.open-button {
  /* ヘッダーの高さ 77px + ヘッダーからボタンまでの距離 38px */
  top: 114px;
  left: 0;
  background: var(---ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 2px #0000004d !important;
  border-radius: 0px 100px 100px 0px;
  opacity: 1;
  position: fixed;
}

.open-button-viewable-companies {
  top: 159px;
}

.close-button {
  box-shadow: 0px 0px 2px #0000004d;
}

.heading {
  display: flex;
  align-items: center;
  margin: 45px 0 27.5px 50px;
  font-size: 14px;
  color: #888;
}

.heading-viewable-companies {
  margin-top: 90px;
}

.v-divider {
  flex: 1 1 0;
  border-color: rgba(0, 0, 0, 0.12);
  opacity: 1;
}

.navigation {
  margin: 0 30px 0 50px;
  padding: 0;
  list-style: none;
  text-align: left;
  font-size: 14px;
  color: #222;
}

.navigation li {
  margin-bottom: 30px;
}

.navigation li a {
  color: #222;
  text-decoration: none;
}

.navigation li a:hover {
  color: #555;
}

.navigation-link-active {
  font-weight: bold;
}
</style>
