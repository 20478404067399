<template>
  <v-card class="card">
    <v-card-title
      :class="
        !$store.state.selectedStore || isEmpty
          ? 'pa-0 d-flex align-center behavioral-dna-card-title behavioral-dna-card-title-margin'
          : 'pa-0 d-flex align-center behavioral-dna-card-title'
      "
    >
      <span>行動DNA</span>
      <p style="margin-bottom: 0; font-size: 12px; color: #666">
        棒グラフをクリックすると詳細を閲覧できます。
      </p>
      <alert-tooltip
        v-if="hasAlertInBehavioralDnaData"
        class="ml-2"
        text="取得データボリュームが少なく統計上の信頼性が低い日が含まれています。該当日は参考値としてご参照ください。"
      />
      <v-spacer />
      <chart-description-tooltip
        menu-key="analytics"
        sub-menu-key="persona"
        chart-key="behavioralDna"
        class="mr-27px"
      />
      <download-button
        label="CSVデータ"
        :disabled="!$store.state.selectedStore"
        :get-file-id="getL2FileId"
        :csv-name="l2CSVName"
      />
    </v-card-title>
    <v-container
      :class="
        !$store.state.selectedStore || isEmpty ? 'pa-0' : 'pa-0 mb-4'
      "
      fluid
    >
      <v-row
        class="my-0"
        dense
      >
        <v-col class="pa-0">
          <div
            v-show="!$store.state.selectedStore"
            class="unselected_card unselected_dna_card"
          >
            店舗を選択するとチャートが表示されます。
          </div>
          <div v-show="$store.state.selectedStore">
            <LoadingImg
              v-if="chartLoading"
              :height="'720px'"
            />
            <no-data-chart v-else-if="isEmpty" />
            <div v-else>
              <BehavioralDNAChart
                v-if="!$store.getters.isCompare"
                :chart-data="chartData"
                :height="720"
                :min="min"
                :max="max"
                @select-colum="onClickBarChart"
              />
              <BehavioralDNACompareChart
                v-else
                :chart-data="chartData"
                :height="720"
                :min="min"
                :max="max"
                @select-colum="onClickBarChart"
              />
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row
        v-if="!isComparison"
        class="my-0"
        dense
      >
        <v-col class="pa-0">
          <div class="my-0 explanation-of-alerts">
            <v-spacer />
            <alert-caption
              v-if="hasAlertInBehavioralDnaData"
              variants="square"
            />
          </div>
        </v-col>
      </v-row>
      <v-row
        v-if="isComparison"
        class="my-0"
        dense
      >
        <v-col>
          <div class="my-0 explanation-of-alerts">
            <v-spacer />
            <alert-caption
              v-if="hasAlertInBehavioralDnaData"
              variants="two_squares"
            />
          </div>
        </v-col>
      </v-row>
    </v-container>
    <div
      v-if="selectChart"
      class="divider"
    />
    <v-card-title
      v-show="selectChart"
      class="pa-0 behavioral-dna-level-three-card-title"
    >
      <span>{{ levelThreeTitle }}</span>
      <alert-tooltip
        v-if="hasAlertInSubBehavioralDnaData"
        class="ml-2"
        text="取得データボリュームが少なく統計上の信頼性が低い日が含まれています。該当日は参考値としてご参照ください。"
      />
      <v-spacer />
      <download-button
        label="CSVデータ"
        :disabled="!$store.state.selectedStore"
        :get-file-id="getL3FileId"
        :csv-name="l3CSVName"
      />
    </v-card-title>
    <v-container
      class="pa-0"
      fluid
    >
      <v-row
        class="my-0"
        dense
      >
        <v-col class="pa-0">
          <v-row
            v-show="$store.state.selectedStore"
            class="ma-0"
          >
            <BehavioralDNAChart
              v-if="selectChart && !$store.getters.isCompare"
              :chart-data="levelThree"
              :height="levelThreeBehavioralDNAChartHeight"
              :min="min"
              :max="max"
              style="margin-bottom: 10px !important"
            />
            <BehavioralDNACompareChart
              v-if="selectChart && $store.getters.isCompare"
              :chart-data="levelThree"
              :height="levelThreeBehavioralDNAChartHeight"
              :min="min"
              :max="max"
              style="margin-bottom: 10px !important"
            />
          </v-row>
        </v-col>
      </v-row>
      <v-row
        v-if="!isComparison"
        class="my-0"
        dense
      >
        <v-col class="pa-0">
          <div class="my-0 explanation-of-alerts">
            <v-spacer />
            <alert-caption
              v-if="hasAlertInSubBehavioralDnaData && selectChart"
              variants="square"
            />
          </div>
        </v-col>
      </v-row>
      <v-row
        v-if="isComparison"
        class="my-0"
        dense
      >
        <v-col class="pa-0">
          <div class="my-0 explanation-of-alerts">
            <v-spacer />
            <alert-caption
              v-if="hasAlertInSubBehavioralDnaData && selectChart"
              variants="two_squares"
            />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import NoDataChart from "@/features/ShopAnalytics/components/Common/noDataChart.vue";
import BehavioralDNAChart from "@/features/ShopAnalytics/components/Persona/BehavioralDNAChart.vue";
import BehavioralDNACompareChart from "@/features/ShopAnalytics/components/Persona/BehavioralDNACompareChart.vue";

// interface
import { SelectionChart } from "@/features/ShopAnalytics/interfaces/component";
import {
  thirdCategory,
  comparisonThirdCategory,
} from "@/features/ShopAnalytics/interfaces/response";

// util
import {
  processBehavioralDnaThirdCategoryChartData,
  processComparisonBehavioralDnaThirdCategoryChartData,
} from "@/features/ShopAnalytics/utils/persona";
import LoadingImg from "@/commons/components/loadingImg.vue";
import { AxiosResponse } from "axios";

export default defineComponent({
  components: {
    LoadingImg,
    NoDataChart,
    BehavioralDNAChart,
    BehavioralDNACompareChart,
  },
  props: {
    chartData: { type: Array as PropType<(string | number)[][]>, required: true },
    // TODO: chartData から computed で算出
    chartLoading: { type: Boolean },
    thirdCategories: {
      type: Array as PropType<
        {
          thirdCategory: thirdCategory[] | comparisonThirdCategory[];
          firstCategoryName: string;
          secondCategoryName: string;
        }[]
      >,
      required: true,
    },
    hasAlert: { type: Boolean },
    isEmpty: { type: Boolean },
    getL2FileId: {
      type: Function as PropType<() => Promise<AxiosResponse<any, any>>>,
      default: undefined,
    },
    getL3FileId: {
      type: Function as PropType<() => Promise<AxiosResponse<any, any>>>,
      default: undefined,
    },
    l2CSVName: { type: String, default: undefined },
    l3CSVName: { type: String, default: undefined },
  },
  data() {
    return {
      selectChart: null as null | SelectionChart,
      levelThree: [] as any[][],
    };
  },
  computed: {
    thirdCategoriesValues(): number[] {
      return this.thirdCategories.flatMap((c) => {
        if (!this.isComparison)
          return c.thirdCategory.flatMap((t) =>
            Object.values(t.deviationValue)
          );
        else
          return c.thirdCategory.flatMap((t) =>
            Object.values(t.deviationValue).flatMap((dv) =>
              dv.flatMap(
                (v: { isAlert: boolean; period: string; value: number }) =>
                  v.value
              )
            )
          );
      });
    },
    min(): number {
      return Math.min(
        ...([...this.chartData.flat(), ...this.thirdCategoriesValues].filter(
          (value) => typeof value === "number"
        ) as number[])
      );
    },
    max(): number {
      return Math.max(
        ...([...this.chartData.flat(), ...this.thirdCategoriesValues].filter(
          (value) => typeof value === "number"
        ) as number[])
      );
    },
    // watch で監視するために定義
    isComparison(): boolean {
      return (
        this.$store.state.compareStartDate.length !== 0 &&
        this.$store.state.compareEndDate.length !== 0
      );
    },
    levelThreeTitle(): string {
      if (!!this.thirdCategories && !!this.selectChart) {
        return `${
          this.thirdCategories[this.selectChart.row].firstCategoryName
        } > ${this.thirdCategories[this.selectChart.row].secondCategoryName}`;
      } else {
        return "";
      }
    },
    hasAlertInBehavioralDnaData(): boolean {
      if (this.chartData.length === 0) return false;

      if (this.isComparison) {
        return this.chartData.some(
          (value) =>
            value[3] === "#bbb" ||
            value[6] === "#ccc" ||
            value[9] === "#bbb" ||
            value[12] === "#ccc" ||
            value[15] === "#bbb" ||
            value[18] === "#ccc"
        );
      } else {
        return this.chartData.some(
          (value) =>
            value[3] === "#bbb" || value[6] === "#bbb" || value[9] === "#bbb"
        );
      }
    },
    hasAlertInSubBehavioralDnaData(): boolean {
      if (this.levelThree.length === 0) return false;

      if (this.isComparison) {
        return this.levelThree.some(
          (value) =>
            value[3] === "#bbb" ||
            value[6] === "#ccc" ||
            value[9] === "#bbb" ||
            value[12] === "#ccc" ||
            value[15] === "#bbb" ||
            value[18] === "#ccc"
        );
      } else {
        return this.levelThree.some(
          (value) =>
            value[3] === "#bbb" || value[6] === "#bbb" || value[9] === "#bbb"
        );
      }
    },
    levelThreeBehavioralDNAChartHeight(): number {
      if (this.levelThree.length <= 0) return 0;
      return this.levelThree.length * 34 + 80;
    },
  },
  watch: {
    isComparison() {
      this.selectChart = null;
    },
  },
  methods: {
    onClickBarChart(e: SelectionChart) {
      // 左右中央でそれぞれ独立しているので、同じ行数のチャートが選択された場合もサブチャートを非表示にする
      if (!e || e?.row === this.selectChart?.row) {
        this.selectChart = null;
        return;
      }

      if (e.row === null) {
        this.selectChart = null;
        return;
      }

      this.selectChart = e;
      if (!this.isComparison) {
        this.levelThree = processBehavioralDnaThirdCategoryChartData(
          this.levelThreeTitle,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          this.thirdCategories[this.selectChart.row].thirdCategory,  
        );
      } else {
        this.levelThree = processComparisonBehavioralDnaThirdCategoryChartData(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          this.thirdCategories[this.selectChart.row].thirdCategory,
          this.$store.state.startDate,
          this.$store.state.endDate,
          this.$store.state.compareStartDate,
          this.$store.state.compareEndDate
        );
      }
    },
    resetSelectChart(): void {
      this.selectChart = null;
    },
  },
});
</script>

<style scoped>
.behavioral-dna-card-title {
  display: flex;
  align-items: center;
  height: 40px;
  width: 100%;
}

.behavioral-dna-card-title span {
  font-size: 16px;
  font-weight: bold;
  line-height: 30px;
  margin-right: 20px;
}

.behavioral-dna-card-title-margin {
  margin-bottom: 30px;
}

.behavioral-dna-level-three-card-title {
  display: flex;
  align-items: center;
  height: 30px;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 20px;
}

.behavioral-dna-level-three-card-title span {
  font-size: 14px;
  font-weight: bold;
  line-height: 30px;
}

.explanation-of-alerts {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #666;
}

.divider {
  border-bottom: 5px solid #eee;
  margin: 0 -30px 0 -30px;
}

.unselected_dna_card {
  height: 720px;
}

.mr-27px {
  margin-right: 27px;
}
</style>
