import {
  FrequencyResponse,
  EngagementResponse,
  CohortResponse,
} from "../../interfaces/response";

export function hasAlertInFrequencyChartData(data: FrequencyResponse) {
  /**
   * '/stores/chart/visit-frequency/' で受け取った
   * レスポンスデータに isAlert = true が含まれているか
   */
  return data.visitCounts.some((element) => element.isAlert);
}

export function hasAlertInEngagementChartData(data: EngagementResponse) {
  /**
   * '/stores/chart/engagement/', で受け取った
   * レスポンスデータに isAlert = true が含まれているか
   */
  return data.visitCounts.some((element) => element.isAlert);
}

export function hasAlertInCohortChartData(data: CohortResponse) {
  /**
   * '/stores/chart/cohort-analysis/', で受け取った
   * レスポンスデータに isAlert = true が含まれているか
   */
  return data.visitCounts.some((element) => element.isAlert);
}
