<template>
  <v-container
    class="pa-0"
    fluid
  >
    <v-row
      class="title-container"
      dense
      no-gutters
    >
      <v-col style="display: flex; align-items: center">
        <h1 class="title-text">
          来店人数推移
        </h1>
        <update-badge
          type="week"
          style="margin-left: 15px"
        />
      </v-col>
      <v-spacer />
      <date-picker
        :start-date="$store.state.startDate"
        :end-date="$store.state.endDate"
        :compare-start-date="$store.state.compareStartDate"
        :compare-end-date="$store.state.compareEndDate"
        :enable-comparison="false"
        @update-period="updatePeriod"
      />
    </v-row>
    <v-row
      dense
      no-gutters
      class="card-container"
    >
      <v-col>
        <store-selector :loading="storeLoading" />
      </v-col>
    </v-row>
    <v-row
      dense
      no-gutters
      class="card-container mb-0"
    >
      <v-col>
        <chart-card
          v-model:particle="particle"
          :chart-data="chartData"
          :chart-loading="chartLoading"
          :legends="legends"
          :has-alert="hasAlertInChartData"
          :colors="chartColors"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";
// TODO: commons に寄せる
import DatePicker from "@/features/ShopAnalytics/components/Common/datePicker.vue";
import StoreSelector from "@/features/StoreCompare/components/StoreSelector.vue";
import UpdateBadge from "@/features/ShopAnalytics/components/Common/updateBadge.vue";
import ChartCard from "@/features/StoreCompare/visitor/components/ChartCard.vue";
import { getPeriodByRouterQueryPeriod } from "@/commons/utils";
import { convertHyphenDelimiter } from "@/commons/utils/dateUtil";
import { hasAlertInChartData } from "@/features/StoreCompare/utils";
import {
  processChart,
  processMonthlyVisitRateChart,
} from "@/features/StoreCompare/visitor/util";
// TODO: commons に寄せる
import { getVisitCountGraph } from "@/features/ShopAnalytics/axios/visitor";
import { getMonthlyVisitRate } from "@/features/StoreCompare/visitor/axios";
import { SUB_TYPE, PARTICLE_TYPE } from "@/commons/enums";
import { COMPARE_PARTICLE_TYPE } from "@/features/StoreCompare/visitor/enum";
import {
  ComparisonGroup,
  ComparisonGraph,
  ComparisonGroupStore,
} from "@/features/StoreCompare/types";
// TODO: commons に寄せる
import * as notify from "@/plugins/notification";
import moment from "moment";
import { debounce } from "lodash";

const QUERY_UNIT = {
  DATE: "date",
  WEEK: "week",
  MONTH: "month",
  MONTHLY_RATE: "monthly_rate",
} as const;

export default defineComponent({
  components: {
    DatePicker,
    StoreSelector,
    ChartCard,
    UpdateBadge,
  },
  data() {
    return {
      created: false,
      storeLoading: false,
      chartData: [] as (string | number | object | undefined | null)[],
      chartLoading: false,
      hasAlertInChartData: false,
      particle:
        COMPARE_PARTICLE_TYPE.DATE as (typeof COMPARE_PARTICLE_TYPE)[keyof typeof COMPARE_PARTICLE_TYPE],
      chartController: new AbortController(),
    };
  },
  computed: {
    hasRouterQueryPeriod(): boolean {
      return this.$route.query.period !== undefined;
    },
    hasRouterQueryUnit(): boolean {
      return this.$route.query.unit !== undefined;
    },
    // NOTE: watch で監視するために定義
    selectedComparisonGroup(): ComparisonGroup {
      return this.$store.state.selectedComparisonGroup;
    },
    selectedStores(): ComparisonGroupStore[] {
      return this.$store.getters.selectedComparisonGroupVisibleStores;
    },
    legends(): { text: string; color: string }[] {
      if (!this.$store.state.selectedComparisonGroup) return [];
      return this.selectedStores.map((store) => {
        return { text: store.name, color: store.color ?? "" };
      });
    },
    chartColors(): string[] {
      if (this.selectedStores.length === 0) return [];
      return this.selectedStores.map((s) => s.color ?? "");
    },
  },
  watch: {
    selectedComparisonGroup() {
      if (this.created) {
        this.chartController.abort();

        this.fetchChart();
      }
    },
    selectedStores: debounce(function (this: any) {
      if (this.created) {
        this.chartController.abort();

        this.fetchChart();
      }
    }, 500),
    particle() {
      if (this.created) {
        this.chartController.abort();

        this.fetchChart();
      }
    },
  },
  async created() {
    // クエリパラメータからデータ更新
    this.setDateFromRouterQuery();
    this.setParticleFromRouterQuery();

    this.storeLoading = true;
    // 店舗一覧
    if (!this.$store.state.stores.length)
      await this.$store.dispatch("fetchStores");

    // 比較店舗リスト一覧
    if (!this.$store.state.comparisonGroups.length)
      await this.$store.dispatch("fetchComparisonGroups");

    // id があれば選択比較店舗リストを設定,無ければ初期化
    if (this.$route.params["id"]) {
      await this.$store.dispatch(
        "specifiedComparisonGroup",
        this.$route.params["id"]
      );
      if (!this.selectedStores || this.selectedStores.length === 0)
        this.$router.push({ name: "NotFound" });
    } else this.$store.commit("initComparisonGroup");
    this.storeLoading = false;

    // チャート取得
    await this.fetchChart();

    this.created = true;
  },
  methods: {
    initChartData() {
      this.chartData.length = 0;
    },
    setDateFromRouterQuery() {
      if (this.hasRouterQueryPeriod) {
        let tmp = getPeriodByRouterQueryPeriod(this.$route.query.period as string);
        if (tmp !== undefined) this.$store.commit("setDate", tmp);
      }
    },
    setParticleFromRouterQuery() {
      if (!this.hasRouterQueryUnit) return;
      switch (this.$route.query.unit) {
        case QUERY_UNIT.DATE:
          this.particle = COMPARE_PARTICLE_TYPE.DATE;
          return;
        case QUERY_UNIT.WEEK:
          this.particle = COMPARE_PARTICLE_TYPE.WEEK;
          return;
        case QUERY_UNIT.MONTH:
          this.particle = COMPARE_PARTICLE_TYPE.MONTH;
          return;
        case QUERY_UNIT.MONTHLY_RATE:
          this.particle = COMPARE_PARTICLE_TYPE.MONTHLY_RATE;
          return;
        default:
          // CHECK: 不正のパラメータを受け取った場合はエラー等投げるか
          return;
      }
    },
    async fetchChart() {
      if (!this.selectedComparisonGroup || this.selectedStores.length === 0)
        return;

      this.chartLoading = true;

      // チャートデータの初期化
      this.initChartData();

      this.chartController = new AbortController();
      // 月別比率 選択時
      if (this.particle === COMPARE_PARTICLE_TYPE.MONTHLY_RATE) {
        const monthlyRateStartDate: string = moment(this.$store.state.startDate)
          .startOf("month")
          .format("YYYY-MM-DD");
        const monthlyRateEndDate: string = moment(this.$store.state.endDate)
          .startOf("month")
          .format("YYYY-MM-DD");
        await getMonthlyVisitRate(
          this.selectedStores.map((s) => s.id),
          monthlyRateStartDate,
          monthlyRateEndDate,
          this.chartController.signal
        )
          .then((res) => {
            this.chartData = processMonthlyVisitRateChart(
              res.data,
              monthlyRateStartDate,
              monthlyRateEndDate,
              this.selectedStores
            );
            this.hasAlertInChartData = res.data.comparisonGroups.some(
              (group) => group.isAlert
            );
            this.chartLoading = false;
          })
          .catch((e) => {
            if (typeof e === "undefined" || e.code !== "ERR_CANCELED") {
              notify.notifyErrorMessage(
                "来店人数推移表が表示できませんでした。"
              );
              this.chartLoading = false;
            }
          });
      }

      // 日・週・月 選択時
      else {
        const graphs: ComparisonGraph[] = [];
        let canceled = false;
        const { start, end } = (() => {
          switch (this.particle) {
            case PARTICLE_TYPE.WEEK:
              return {
                start: this.$store.getters.startDateOfWeek as string,
                end: this.$store.getters.endDateOfWeek as string,
              };
            case PARTICLE_TYPE.MONTH:
              return {
                start: this.$store.getters.startDateOfMonth as string,
                end: this.$store.getters.endDateOfMonth as string,
              };
            default:
              return {
                start: this.$store.state.startDate as string,
                end: this.$store.state.endDate as string,
              };
          }
        })();
        await Promise.all(
          this.selectedStores.map(async (store) => {
            await getVisitCountGraph(
              store.id,
              start,
              end,
              this.particle,
              SUB_TYPE["NONE"],
              this.chartController.signal
            )
              .then((res) => {
                graphs.push({
                  orderIndex: store.orderIndex,
                  storeName: store.name,
                  color: store.color ?? "",
                  visitCounts: res.data.visitCounts,
                });
              })
              .catch((e) => {
                if (typeof e === "undefined" || e.code !== "ERR_CANCELED") {
                  notify.notifyErrorMessage(
                    "来店人数推移表が表示できませんでした。"
                  );
                } else if (e.code === "ERR_CANCELED") {
                  canceled = true;
                }
              });
          })
        ).then((f) => {
          if (this.selectedStores.length === f.length && !canceled)
            this.chartLoading = false;
        });
        this.chartData = processChart(graphs, this.particle);
        this.hasAlertInChartData = hasAlertInChartData(graphs);
      }
      // クエリパラメータ付与
      await this.pushRoute();
    },

    updatePeriod(period: {
      startDate: string;
      endDate: string;
      compareStartDate: string;
      compareEndDate: string;
    }) {
      this.$store.commit("setDate", {
        startDate: period.startDate,
        endDate: period.endDate,
      });
      this.$store.commit("setCompareDate", {
        startDate: period.compareStartDate,
        endDate: period.compareEndDate,
      });

      this.fetchChart();
    },

    async pushRoute() {
      if (!this.selectedComparisonGroup) return;

      const tmp = {
        start: convertHyphenDelimiter(this.$store.state.startDate),
        end: convertHyphenDelimiter(this.$store.state.endDate),
        unit:
          this.particle === COMPARE_PARTICLE_TYPE.DATE
            ? QUERY_UNIT.DATE
            : this.particle === COMPARE_PARTICLE_TYPE.WEEK
            ? QUERY_UNIT.WEEK
            : this.particle === COMPARE_PARTICLE_TYPE.MONTH
            ? QUERY_UNIT.MONTH
            : QUERY_UNIT.MONTHLY_RATE,
      };

      // NOTE: 他ページに遷移後にreplaceが呼び出されると戻される対策
      if (this.$route.name === "StoreCompareVisitor") {
        await this.$router
          .replace({
            name: "StoreCompareVisitor",
            params: { id: this.$store.state.selectedComparisonGroup.id },
            query: {
              period: `${tmp.start}_${tmp.end}`,
              unit: tmp.unit,
            },
          })
          .catch(() => undefined);
      }
    },
  },
});
</script>
