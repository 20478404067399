<template>
  <CompareUpdate
    :id="id"
    type="edit"
  />
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import type { Ref } from 'vue'

import CompareUpdate from "@/features/Dashboard/components/CompareUpdate.vue";
import { ComparisonGroup } from "@/commons/interfaces";
import { useStore} from "vuex";
import { useRouter, useRoute } from 'vue-router'

export default defineComponent({
  components: { CompareUpdate },
  
  setup() {
    const id: Ref<string> = ref("");
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const created = async() => {
      id.value = route.params.id as string;
      
      // TODO: CompareUpdate をばらして Create, Edit に付与
      if (!store.state.comparisonGroups.length)
        await store.dispatch("fetchComparisonGroups");

      if (
        !store.state.comparisonGroups.some(
          (group: ComparisonGroup) => group.id === id.value
        )
      ) {
        router.push({ name: "NotFound" });
      }
    };
    created();
    
    return {
      id
    };
  },
});
</script>
