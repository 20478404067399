<template>
  <div class="alert-caption">
    <span
      :class="{
        circle: variants === 'circle',
        square: variants === 'square' || variants === 'two_squares',
      }"
    />
    <span
      v-if="variants === 'two_squares'"
      class="square2"
    />
    参考値として表示。《！》をご確認ください。
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    variants: { type: String as PropType<"circle" | "square" | "two_squares"> },
  },
});
</script>

<style scoped>
.alert-caption {
  display: flex;
  justify-content: right;
  align-items: center;
  min-width: 280px;
}
.alert-caption > .circle {
  margin-right: 6px;
  width: 10px;
  height: 10px;
  border: 2px solid #666;
  border-radius: 50%;
}

.alert-caption > .square {
  display: inline-block;
  margin-right: 6px;
  width: 10px;
  height: 10px;
  border: 2px solid #ccc;
  background: #bbbbbb;
}

.alert-caption > .square2 {
  display: inline-block;
  margin-right: 6px;
  width: 10px;
  height: 10px;
  border: 2px solid #ccc;
  background: #cccccc;
}
</style>
