<template>
  <div :style="loadingStyle">
    <img src="@/assets/svg/spinner.svg">
  </div>
</template>

<script lang="ts">
import { defineComponent, computed} from "vue";

export default defineComponent({
  name: "LoadingImg",
  props: {
    height: {
      type: String,
      default: "0",
    },
    directHeight: {
      type: String,
      default: "100px",
    },
  },
  setup(props){
    const loadingStyle = computed(() => {
      return `height: ${props.height}; display: flex; align-items: center; justify-content: center;`;
    });
  
    return {
      loadingStyle,
    };
  },
});
</script>
