<template>
  <v-container
    class="pa-0"
    fluid
  >
    <v-row
      class="title-container"
      dense
      no-gutters
    >
      <v-col style="display: flex; align-items: center">
        <h1 class="title-text">
          商圏分析
        </h1>
        <update-badge
          type="month"
          style="margin-left: 15px"
        />
      </v-col>
      <v-spacer />
      <monthly-date-picker
        :start-date="$store.state.startMonth"
        :end-date="$store.state.endMonth"
        @update-period="updatePeriod"
      />
    </v-row>
    <v-row
      dense
      no-gutters
      class="card-container"
    >
      <v-col>
        <store-selector :loading="storeLoading" />
      </v-col>
    </v-row>
    <v-row
      dense
      no-gutters
      class="card-container mb-0"
    >
      <v-col class="map-column">
        <v-card
          class="card"
          tile
        >
          <div class="map-title">
            <b>商圏マップ（居住エリア）</b>
            <div class="biz-area-sub-setting">
              <span>
                <b>計測範囲：</b>
                半径
                <select
                  v-model.number="radius"
                  class="form space"
                >
                  <option
                    v-for="r in radiusList"
                    :key="r"
                    :value="r"
                  >
                    {{ r }}
                  </option>
                </select>
                km
              </span>
              <span class="granularity_form">
                <b>表示単位：</b>
                <label class="city-form">
                  <input
                    v-model.number="granularity"
                    class="space"
                    type="radio"
                    value="0"
                  >
                  市区町村
                </label>
                <label>
                  <input
                    v-model.number="granularity"
                    class="space"
                    type="radio"
                    value="1"
                  >
                  町丁目
                </label>
              </span>
              <button
                class="button"
                @click="getData"
              >
                適用
              </button>
            </div>
            <v-spacer />
            <chart-description-tooltip
              menu-key="compare"
              sub-menu-key="bizArea"
              chart-key="bizAreaMap"
            />
          </div>
          <div class="description">
            地図中からデータ補足範囲の中心となる「基準店舗」を選び、適用を押してください。
          </div>
          <biz-area-map
            v-model:view-radius="viewRadius"
            :base-store="baseStore"
            :base-store-point="baseStorePoint"
            :selected-stores="selectedStores"
            :radius="radius"
            :feature-collection="features"
            :max-ratio="maxRatio"
            :loading="mapLoading"
            @switch-base-store="switchBaseStore"
            @push-route="pushRoute"
          />
          <div class="divider" />
          <biz-area-table-card
            :areas="tableAreas"
            :base-store="baseStore"
            :privacy-alert-area-names="privacyAlertAreaNames"
            :get-file-id="getBizAreaFileId"
            :csv-name="bizAreaCSVName"
          />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";
// components
import MonthlyDatePicker from "@/commons/components/DatePicker/MonthlyDatePicker.vue";
import BizAreaMap from "@/features/StoreCompare/bizArea/components/BizAreaMap.vue";
import BizAreaTableCard from "@/features/StoreCompare/bizArea/components/BizAreaTableCard.vue";
import UpdateBadge from "@/features/ShopAnalytics/components/Common/updateBadge.vue";
// TODO: commons に寄せる
import StoreSelector from "@/features/StoreCompare/components/StoreSelector.vue";
// types
import {
  ComparisonGroup,
  ComparisonGroupStore,
} from "@/features/StoreCompare/types";
import {
  BizAreaAnalysisComparisonMapResponse,
  ComparisonMapBoxFeatures,
} from "@/features/StoreCompare/bizArea/interfaces/response";
import {
  Area,
  ComparisonTrendAreaAnalysisResponse,
} from "@/features/StoreCompare/bizArea/types";
// functions
import { AxiosResponse } from "axios";
import {
  getBizAreaComparisonMap,
  getBizAreaComparisonTable,
  downloadBizAreaComparisonTable,
} from "@/features/StoreCompare/bizArea/axios";
import {
  processingMapData,
  getMaxRatio,
  getPrivacyAlertAreaNames,
} from "@/features/StoreCompare/bizArea/utils";
import * as notify from "@/plugins/notification";
import {
  convertHyphenDelimiter,
  convertSlashDelimiter,
} from "@/commons/utils/dateUtil";
import { getPeriodByRouterQueryMonthPeriod } from "@/commons/utils";
import { debounce } from "lodash";

const GRANULARITY = {
  CITY: { value: 0, text: "city" },
  TOWN: { value: 1, text: "town" },
} as const;

export default defineComponent({
  components: {
    StoreSelector,
    MonthlyDatePicker,
    BizAreaMap,
    BizAreaTableCard,
    UpdateBadge,
  },
  data() {
    return {
      radiusList: [
        0.5, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20,
      ],
      mapLoading: false,
      tableLoading: false,
      baseStore: undefined as ComparisonGroupStore | undefined,
      baseStorePoint: undefined as number[] | undefined,
      granularity: GRANULARITY.TOWN[
        "value"
      ] as (typeof GRANULARITY)[keyof typeof GRANULARITY]["value"],
      radius: 5,
      viewRadius: 5,
      features: [] as ComparisonMapBoxFeatures[],
      maxRatio: 1,
      tableAreas: [] as Area[],
      privacyAlertAreaNames: [] as string[],
      storeLoading: false,
    };
  },
  computed: {
    // NOTE: watch で監視するために定義
    selectedComparisonGroup(): ComparisonGroup {
      return this.$store.state.selectedComparisonGroup;
    },
    selectedStores(): ComparisonGroupStore[] {
      return this.$store.getters.selectedComparisonGroupVisibleStores;
    },
    legends(): { text: string; color: string }[] {
      if (!this.$store.state.selectedComparisonGroup) return [];
      return this.selectedStores.map((store: ComparisonGroupStore) => {
        return { text: store.name, color: store.color ? store.color : "" };
      });
    },
    hasRouterQueryPeriod(): boolean {
      return this.$route.query.period !== undefined;
    },
    hasRouterQueryRadius(): boolean {
      if (this.$route.query.radius === undefined) return false;
      if (isNaN(Number(this.$route.query.radius))) return false;
      return true;
    },
    hasRouterQueryViewRadius(): boolean {
      if (this.$route.query.v_radius === undefined) return false;
      if (isNaN(Number(this.$route.query.v_radius))) return false;
      return true;
    },
    hasRouterQueryUnit(): boolean {
      return this.$route.query.unit !== undefined;
    },
    getBizAreaFileId(): () => Promise<AxiosResponse<any, any>> {
      return () =>
        downloadBizAreaComparisonTable(
          this.baseStore?.id,
          this.selectedStores.map((store: ComparisonGroupStore) => {
            return store.id;
          }),
          this.$store.state.startMonth,
          this.$store.state.endMonth,
          this.granularity,
          this.radius
        );
    },
    bizAreaCSVName(): string {
      const { start, end } = {
        start: convertSlashDelimiter(this.$store.state.startMonth),
        end: convertSlashDelimiter(this.$store.state.endMonth),
      };
      return `店舗比較_商圏ランキング_${start}-${end}`;
    },
  },
  watch: {
    selectedStores(newVal: ComparisonGroupStore[]) {
      if (newVal.length == 0) return;
      this.baseStore = newVal[0];
    },
    baseStore: debounce(function (
      this: any,
      newVal: ComparisonGroupStore | undefined
    ) {
      this.baseStorePoint = newVal
        ? [newVal.longitude, newVal.latitude]
        : undefined;
      this.getData();
      if (this.viewRadiusSource) this.updateViewRadius();
    },
    500),
  },
  async created() {
    // 初期値取得
    this.setDateFromRouterQuery();
    this.setRadiusFromRouterQuery();
    this.setViewRadiusFromRouterQuery();
    this.setUnitFromRouterQuery();
    this.storeLoading = true;
    // 店舗一覧
    if (!this.$store.state.stores.length)
      await this.$store.dispatch("fetchStores");

    // 比較店舗リスト一覧
    if (!this.$store.state.comparisonGroups.length)
      await this.$store.dispatch("fetchComparisonGroups");

    // id があれば選択比較店舗リストを設定
    if (this.$route.params["id"]) {
      await this.$store.dispatch(
        "specifiedComparisonGroup",
        this.$route.params["id"]
      );
      if (this.selectedStores && this.selectedStores.length > 0) {
        this.baseStorePoint = this.selectedStores[0]
          ? [this.selectedStores[0].longitude, this.selectedStores[0].latitude]
          : undefined;
      } else this.$router.push({ name: "NotFound" });
    } else this.$store.commit("initComparisonGroup");
    // 無ければ初期化
    this.storeLoading = false;
  },
  methods: {
    setDateFromRouterQuery() {
      if (this.hasRouterQueryPeriod) {
        let tmp = getPeriodByRouterQueryMonthPeriod(this.$route.query.period as string);
        if (tmp !== undefined) this.$store.commit("setMonth", tmp);
      }
    },
    setRadiusFromRouterQuery() {
      if (this.hasRouterQueryRadius && Number(this.$route.query.radius) > 0)
        this.radius = Number(this.$route.query.radius);
    },
    setViewRadiusFromRouterQuery() {
      if (
        this.hasRouterQueryViewRadius &&
        Number(this.$route.query.v_radius) > 0
      )
        this.viewRadius = Number(this.$route.query.v_radius);
    },
    setUnitFromRouterQuery() {
      if (!this.hasRouterQueryUnit) return;
      switch (this.$route.query.unit) {
        case GRANULARITY.CITY["text"]:
          this.granularity = GRANULARITY.CITY["value"];
          return;
        case GRANULARITY.TOWN["text"]:
          this.granularity = GRANULARITY.TOWN["value"];
          return;
        default:
          // CHECK: 不正のパラメータを受け取った場合はエラー等投げるか
          return;
      }
    },
    updatePeriod(period: { startMonth: string; endMonth: string }) {
      this.$store.commit("setMonth", {
        startMonth: period.startMonth,
        endMonth: period.endMonth,
      });
      this.getData();
    },
    getData() {
      if (!this.selectedComparisonGroup) return;
      this.getMapData();
      this.getTableData();
      this.pushRoute();
    },
    getMapData() {
      this.mapLoading = true;

      getBizAreaComparisonMap(
        this.baseStore?.id,
        this.selectedStores.map((store: ComparisonGroupStore) => {
          return store.id;
        }),
        this.$store.state.startMonth,
        this.$store.state.endMonth,
        this.granularity,
        this.radius
      )
        .then((res: AxiosResponse<BizAreaAnalysisComparisonMapResponse>) => {
          this.maxRatio = getMaxRatio(res.data);
          this.privacyAlertAreaNames = getPrivacyAlertAreaNames(res.data);
          this.features = processingMapData(res.data, this.selectedStores);
        })
        .catch(() => {
          notify.notifyErrorMessage("商圏マップが表示できませんでした。");
        })
        .finally(() => {
          this.mapLoading = false;
        });
    },
    getTableData() {
      this.tableLoading = true;

      getBizAreaComparisonTable(
        this.baseStore?.id,
        this.selectedStores.map((store: ComparisonGroupStore) => {
          return store.id;
        }),
        this.$store.state.startMonth,
        this.$store.state.endMonth,
        this.granularity,
        this.radius
      )
        .then((res: AxiosResponse<ComparisonTrendAreaAnalysisResponse>) => {
          this.tableAreas = res.data.areas;
        })
        .catch(() => {
          notify.notifyErrorMessage("商圏ランキングが表示できませんでした。");
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    pushRoute() {
      const tmp = {
        start: convertHyphenDelimiter(this.$store.state.startMonth),
        end: convertHyphenDelimiter(this.$store.state.endMonth),
        unit:
          this.granularity === GRANULARITY.CITY["value"]
            ? GRANULARITY.CITY["text"]
            : GRANULARITY.TOWN["text"],
      };

      // NOTE: 他ページに遷移後にreplaceが呼び出されると戻される対策
      if (this.$route.name === "StoreCompareBizArea") {
        this.$router
          .replace({
            name: "StoreCompareBizArea",
            params: { id: this.selectedComparisonGroup.id },
            query: {
              period: `${tmp.start}_${tmp.end}`,
              radius: String(this.radius),
              v_radius: String(this.viewRadius),
              unit: tmp.unit,
            },
          })
          .catch(() => undefined);
      }
    },
    switchBaseStore(e: any) {
      this.baseStore = this.selectedStores.find(
        (store: ComparisonGroupStore) => store.id === e.storeId
      );
    },
    accessToSignedUrl(signedUrl: string, fileName: string) {
      const url = signedUrl;
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
    },
  },
});
</script>

<style lang="scss" scoped>
.map-column {
  position: relative;

  .card {
    .map-title {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .biz-area-sub-setting {
        height: 48px;
        background-color: #f5f5f5;
        opacity: 0.9;
        padding: 0px 20px;
        display: flex;
        align-items: center;
        margin-left: 42px;
        font-size: 14px;

        .form {
          height: 30px;
          width: 50px;
          outline: 1px solid #cccccc;
          border-radius: 4px;
          opacity: 1;
          text-align: center;
          margin-left: 3px;
          margin-right: 3px;
          background: #ffffff;
        }

        .button {
          margin-left: 10px;
          width: 50px;
          height: 28px;
          background: #222222 0% 0% no-repeat padding-box;
          box-shadow: 1px 1px 0px #00000029;
          border-radius: 4px;
          opacity: 1;
          color: #ffffff;
          font-weight: bold;
        }
      }
      .granularity_form {
        margin: 0px 20px;

        .city-form {
          margin-right: 5px;
        }
      }
    }
    .divider {
      border-bottom: 5px solid #eee;
      margin: 40px -30px 30px;
    }
    .description {
      height: 40px;
      background: #4d99d0 0% 0% no-repeat padding-box;
      text-align: center;
      font: normal normal bold 13px/40px Noto Sans JP;
      letter-spacing: 0px;
      color: #ffffff;
    }
  }
}
</style>
