<template>
  <div class="chart-legends">
    <div
      v-for="legend in props.legends"
      :key="legend.text"
      class="chip"
    >
      <span :style="`color: ${legend.color}`">●</span> {{ legend.text }}
    </div>
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    legends: { text: string; color: string }[]
  }>(), {
    legends: () => []
  }
)
</script>

<style scoped>
.chart-legends {
  display: flex;
  flex-wrap: wrap;
}

.chart-legends > .chip {
  margin-right: 22px;
  font-size: 13px;
  color: #333333;
  user-select: none;
}
</style>
