<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="14.116"
    viewBox="0 0 20 14.116"
  >
    <path
      id="パス_1017"
      data-name="パス 1017"
      d="M27.418,19.629a.61.61,0,0,1-.61.61H20.539a.61.61,0,1,1,0-1.22h6.269A.61.61,0,0,1,27.418,19.629Zm-8.311-3.657h7.7a.61.61,0,1,0,0-1.22h-7.7a.61.61,0,1,0,0,1.22Zm7.7,7.31H19.453a.61.61,0,0,0,0,1.22h7.355a.61.61,0,1,0,0-1.22Zm0,4.263H10.1a.61.61,0,0,0,0,1.22H26.81a.61.61,0,1,0,0-1.22Zm-14.15-2.412A5.241,5.241,0,1,1,17.9,19.894,5.241,5.241,0,0,1,12.659,25.133Zm-1.448-3.315,1.826-1.448a.607.607,0,0,0,.232-.476V17.982a.61.61,0,0,0-1.22,0V19.6l-1.595,1.261a.61.61,0,0,0,.378,1.09A.627.627,0,0,0,11.211,21.818Z"
      transform="translate(-7.42 -14.65)"
      :fill="color"
    />
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    color: { type: String, default: "#666666" },
  },
});
</script>
