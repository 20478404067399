<template>
  <v-menu
    v-model="menu"
    bottom
    left
    offset-y
    nudge-bottom="3"
    :close-on-content-click="false"
    transition="fade-transition"
  >
    <template #activator="{ props }">
      <button v-bind="props" aria-label="日付選択ポップオーバー" class="menu">
        <span class="text-color-gray">{{ startDate }} - {{ endDate }}</span>
        <span
          v-if="enableComparison && compareStartDate && compareEndDate"
          class="text-color-light-gray"
        >
          （比較: {{ compareStartDate }} - {{ compareEndDate }}）
        </span>
        <img class="triangle-down" src="@/assets/svg/triangle-down.svg" />
      </button>
    </template>
    <div class="date-picker-container">
      <!-- 前の月ボタン -->
      <div class="nav-button-wrap">
        <button aria-label="先月" class="nav-go-last-button" @click="goLastMonth()">
          <img src="@/assets/svg/triangle-left-white.svg" />
        </button>
      </div>

      <!-- 先月 -->
      <div style="margin-left: 5px">
        <div class="calendar-header">
          {{ getYearMonth(getLastMonth(currentDate)) }}
        </div>
        <div style="display: flex; justify-content: center">
          <table class="table bg-light-gray">
            <thead>
              <th v-for="day in dayOfWeeks" :key="day" class="calendar-day">
                {{ day }}
              </th>
            </thead>
            <tbody>
              <tr v-for="week in calendars.lastMonth" :key="'last-' + week">
                <td
                  v-for="date in week"
                  :key="'last-' + date + mode"
                  :class="{
                    'bg-base': isLastMonth(date),
                    'bg-yellow': isHoveringPeriod(date) && isLastMonth(date),
                    'bg-gray': !isLastMonth(date) || isInvalidPeriod(date) || loading,
                    'pointer-events-none': !isLastMonth(date) || isInvalidPeriod(date) || loading,
                    'bg-blue':
                      (isComparisonPeriod(date) || isSelectingComparisonPeriod(date)) &&
                      !isBasePeriod(date) &&
                      isLastMonth(date),
                    'bg-red':
                      (isBasePeriod(date) || isSelectingBasePeriod(date)) && isLastMonth(date)
                  }"
                  class="calendar-date"
                  @click="clickDate(date)"
                  @mouseover="hoverDate(date)"
                  @mouseout="initHoveringDate()"
                >
                  {{ getDay(date) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- 今月 -->
      <div style="margin-left: 5px">
        <div class="calendar-header">
          {{ getYearMonth(currentDate) }}
        </div>
        <div style="display: flex; justify-content: center">
          <table class="table bg-light-gray">
            <thead>
              <th v-for="day in dayOfWeeks" :key="day" class="calendar-day">
                {{ day }}
              </th>
            </thead>
            <tbody>
              <tr v-for="week in calendars.thisMonth" :key="'this-' + week">
                <td
                  v-for="date in week"
                  :key="'this-' + date + mode"
                  :class="{
                    'bg-base': isThisMonth(date),
                    'bg-yellow': isHoveringPeriod(date) && isThisMonth(date),
                    'bg-gray': !isThisMonth(date) || isInvalidPeriod(date) || loading,
                    'pointer-events-none': !isThisMonth(date) || isInvalidPeriod(date) || loading,
                    'bg-blue':
                      (isComparisonPeriod(date) || isSelectingComparisonPeriod(date)) &&
                      !isBasePeriod(date) &&
                      isThisMonth(date),
                    'bg-red':
                      (isBasePeriod(date) || isSelectingBasePeriod(date)) && isThisMonth(date)
                  }"
                  class="calendar-date"
                  @click="clickDate(date)"
                  @mouseover="hoverDate(date)"
                  @mouseout="initHoveringDate()"
                >
                  {{ getDay(date) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- 来月 -->
      <div style="margin-left: 5px">
        <div class="calendar-header">
          {{ getYearMonth(getNextMonth(currentDate)) }}
        </div>
        <div style="display: flex; justify-content: center">
          <table class="table bg-light-gray">
            <thead>
              <th v-for="day in dayOfWeeks" :key="day" class="calendar-day">
                {{ day }}
              </th>
            </thead>
            <tbody>
              <tr v-for="week in calendars.nextMonth" :key="'next-' + week">
                <td
                  v-for="date in week"
                  :key="'next-' + date + mode"
                  :class="{
                    'bg-base': isNextMonth(date),
                    'bg-yellow': isHoveringPeriod(date) && isNextMonth(date),
                    'bg-gray': !isNextMonth(date) || isInvalidPeriod(date) || loading,
                    'pointer-events-none': !isNextMonth(date) || isInvalidPeriod(date) || loading,
                    'bg-blue':
                      (isComparisonPeriod(date) || isSelectingComparisonPeriod(date)) &&
                      !isBasePeriod(date) &&
                      isNextMonth(date),
                    'bg-red':
                      (isBasePeriod(date) || isSelectingBasePeriod(date)) && isNextMonth(date)
                  }"
                  class="calendar-date"
                  @click="clickDate(date)"
                  @mouseover="hoverDate(date)"
                  @mouseout="initHoveringDate()"
                >
                  {{ getDay(date) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- 次の月ボタン -->
      <div style="margin-left: 5px" class="nav-button-wrap">
        <button aria-label="来月" class="nav-go-next-button" @click="goNextMonth()">
          <img src="@/assets/svg/triangle-right-white.svg" />
        </button>
      </div>

      <!-- 日付フォーム -->
      <div class="form-wrap">
        <div>
          <div class="form-period text-color-gray">期間</div>
          <div style="display: flex; align-items: center">
            <div
              class="period-input"
              :class="{
                'period-base-input-focus': baseStartFocus
              }"
            >
              <input
                v-model="baseStart"
                aria-label="基準期間の開始日"
                style="width: 70px; outline: none"
                :class="{ 'period-input-invalid': !isValidOnBaseStart }"
                @focus="focusBaseStart"
                @blur="blurBaseStart"
              />
              <div v-show="!isValidOnBaseStart" class="icon-wrap">
                <img src="@/assets/svg/alert.svg" alt="基準期間開始日のアラート" />
              </div>
            </div>
            <span style="margin: 0 5px">ー</span>
            <div style="display: flex; align-items: center">
              <div
                class="period-input"
                :class="{
                  'period-base-input-focus': baseEndFocus
                }"
              >
                <input
                  v-model="baseEnd"
                  aria-label="基準期間の終了日"
                  style="width: 70px; outline: none"
                  :class="{ 'period-input-invalid': !isValidOnBaseEnd }"
                  @focus="focusBaseEnd"
                  @blur="blurBaseEnd"
                />
                <div v-show="!isValidOnBaseEnd" class="icon-wrap">
                  <img src="@/assets/svg/alert.svg" alt="基準期間終了日のアラート" />
                </div>
              </div>
            </div>
          </div>
          <div v-if="enableComparison" class="form-line-wrap">
            <hr width="100%" color="#fff" />
          </div>
          <div v-if="enableComparison" class="form-compare-wrap">
            <input
              v-model="compare"
              aria-label="比較チェックボックス"
              type="checkbox"
              class="checkbox"
            />
            <span class="text-color-gray">比較</span>
            <custom-selector
              v-model="selectedCompareType"
              style="margin-left: 8px"
              size="xs"
              width="90px"
              height="24px"
              :items="compareTypes"
              :disabled="!compare"
            />
          </div>
          <div v-if="enableComparison" style="display: flex; align-items: center">
            <div
              class="period-input"
              :class="{
                'period-input-disabled': !compare || selectedCompareType?.text === '前の期間',
                'period-comparison-input-focus': comparisonStartFocus
              }"
            >
              <input
                v-model="comparisonStart"
                aria-label="比較期間の開始日"
                style="width: 70px; outline: none"
                :class="{
                  'period-input-invalid': compare && !isValidOnComparisonStart
                }"
                :disabled="!compare || selectedCompareType?.text === '前の期間'"
                @focus="focusComparisonStart"
                @blur="blurComparisonStart"
              />
              <div v-show="compare && !isValidOnComparisonStart" class="icon-wrap">
                <img src="@/assets/svg/alert.svg" alt="比較期間開始日のアラート" />
              </div>
            </div>
            <span style="margin: 0 5px">ー</span>
            <div
              class="period-input"
              :class="{
                'period-input-disabled': !compare || selectedCompareType?.text === '前の期間',
                'period-comparison-input-focus': comparisonEndFocus
              }"
            >
              <input
                v-model="comparisonEnd"
                aria-label="比較期間の終了日"
                style="width: 70px; outline: none"
                :class="{
                  'period-input-invalid': compare && !isValidOnComparisonEnd
                }"
                :disabled="!compare || selectedCompareType?.text === '前の期間'"
                @focus="focusComparisonEnd"
                @blur="blurComparisonEnd"
              />
              <div v-show="compare && !isValidOnComparisonEnd" class="icon-wrap">
                <img src="@/assets/svg/alert.svg" alt="比較期間終了日のアラート" />
              </div>
            </div>
          </div>
          <div class="form-button-wrap">
            <button
              class="button"
              :class="{ 'button-disabled': !isActivate() }"
              :disabled="!isActivate()"
              @click="clickApplyButton()"
            >
              期間を適用
            </button>
          </div>
        </div>
      </div>
    </div>
  </v-menu>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import moment from 'moment'
import { WEEKS } from '@/commons/enums'

const PERIOD_TYPE = {
  BASE: 'base',
  COMPARE: 'compare'
} as const

const MODE = {
  PREVIEW: 'preview',
  SELECTING: 'selecting'
} as const

const COMPARE_TYPE = {
  PREVIOUS: { value: 0, text: '前の期間' },
  CUSTOM: { value: 1, text: 'カスタム' }
} as const

export default defineComponent({
  props: {
    startDate: { type: String, required: true },
    endDate: { type: String, required: true },
    compareStartDate: { type: String, required: true },
    compareEndDate: { type: String, required: true },
    loading: { type: Boolean, default: false },
    enableComparison: { type: Boolean, default: true }
  },
  data() {
    return {
      menu: false,
      dayOfWeeks: ['月', '火', '水', '木', '金', '土', '日'],
      selectedCompareType: undefined as
        | undefined
        | (typeof COMPARE_TYPE)[keyof typeof COMPARE_TYPE],
      compareTypes: [
        {
          value: COMPARE_TYPE.PREVIOUS['value'],
          text: COMPARE_TYPE.PREVIOUS['text']
        },
        {
          value: COMPARE_TYPE.CUSTOM['value'],
          text: COMPARE_TYPE.CUSTOM['text']
        }
      ],
      currentDate: this.endDate
        ? moment(this.endDate).subtract(1, 'month').format('YYYY/MM/DD')
        : moment().format('YYYY/MM/DD'),
      hoveringDate: '',
      firstSelectDate: '',
      baseStart: this.startDate as string | undefined,
      baseEnd: this.endDate as string | undefined,
      comparisonStart: this.compareStartDate as string | undefined,
      comparisonEnd: this.compareEndDate as string | undefined,
      baseStartFocus: false,
      baseEndFocus: false,
      comparisonStartFocus: false,
      comparisonEndFocus: false,
      compare: false,
      periodType: PERIOD_TYPE.BASE as (typeof PERIOD_TYPE)[keyof typeof PERIOD_TYPE],
      mode: MODE.PREVIEW as (typeof MODE)[keyof typeof MODE]
    }
  },
  computed: {
    calendars() {
      return {
        lastMonth: (this as any).getCalendar((this as any).getLastMonth((this as any).currentDate)),
        thisMonth: (this as any).getCalendar((this as any).currentDate),
        nextMonth: (this as any).getCalendar((this as any).getNextMonth((this as any).currentDate))
      }
    },
    isValidOnBaseStart() {
      // 開始日・終了日の逆転を弾く
      if ((this as any).baseStart > (this as any).baseEnd) return false
      return (this as any).isValid((this as any).baseStart)
    },
    isValidOnBaseEnd() {
      // 開始日・終了日の逆転を弾く
      if ((this as any).baseStart > (this as any).baseEnd) return false
      return (this as any).isValid((this as any).baseEnd)
    },
    isValidOnComparisonStart() {
      // 開始日・終了日の逆転を弾く
      if ((this as any).comparisonStart > (this as any).comparisonEnd) return false
      return (this as any).isValid((this as any).comparisonStart)
    },
    isValidOnComparisonEnd() {
      // 開始日・終了日の逆転を弾く
      if ((this as any).comparisonStart > (this as any).comparisonEnd) return false
      return (this as any).isValid((this as any).comparisonEnd)
    }
  },

  watch: {
    startDate() {
      this.baseStart = this.startDate
    },
    endDate() {
      this.baseEnd = this.endDate
      this.refreshCalendarPosition()
    },
    compare() {
      if (this.compare) {
        this.selectedCompareType =
          this.comparisonStart && this.comparisonEnd ? COMPARE_TYPE.CUSTOM : COMPARE_TYPE.PREVIOUS
      } else {
        this.mode = MODE.PREVIEW
        this.periodType = PERIOD_TYPE.BASE
        this.selectedCompareType = undefined
        this.comparisonStart = ''
        this.comparisonEnd = ''
      }
    },
    selectedCompareType() {
      if (this.selectedCompareType?.value === COMPARE_TYPE.PREVIOUS.value) {
        this.periodType = PERIOD_TYPE.BASE
        this.setPastPeriod()
      }
      if (this.selectedCompareType?.value === COMPARE_TYPE.CUSTOM.value) {
        this.periodType = PERIOD_TYPE.COMPARE
      }
    }
  },
  created() {
    if (!this.enableComparison) {
      this.compare = false
      this.mode = MODE.PREVIEW
      this.periodType = PERIOD_TYPE.BASE
      this.selectedCompareType = undefined
      this.comparisonStart = ''
      this.comparisonEnd = ''
      this.$emit('update-period', {
        startDate: this.baseStart,
        endDate: this.baseEnd,
        compareStartDate: this.comparisonStart,
        compareEndDate: this.comparisonEnd
      })
    }

    if (this.compareStartDate && this.compareEndDate) {
      this.compare = true
    }
  },
  methods: {
    goNextMonth() {
      this.currentDate = moment(this.currentDate).add(1, 'month').format('YYYY/MM/DD')
    },
    goLastMonth() {
      this.currentDate = moment(this.currentDate).add(-1, 'month').format('YYYY/MM/DD')
    },
    isThisMonth(targetDate: string) {
      return moment(targetDate).format('YYYY/MM') === moment(this.currentDate).format('YYYY/MM')
    },
    isNextMonth(targetDate: string) {
      return (
        moment(targetDate).format('YYYY/MM') ===
        moment(this.currentDate).add(1, 'month').format('YYYY/MM')
      )
    },
    isLastMonth(targetDate: string) {
      return (
        moment(targetDate).format('YYYY/MM') ===
        moment(this.currentDate).add(-1, 'month').format('YYYY/MM')
      )
    },
    isBasePeriod(targetDate: string) {
      if (this.mode === MODE.SELECTING && this.periodType === PERIOD_TYPE.BASE) return false
      if (!this.isValidOnBaseStart || !this.isValidOnBaseEnd) return false

      return moment(targetDate).isBetween(
        moment(this.baseStart).add(-1, 'day'),
        moment(this.baseEnd).add(1, 'day')
      )
    },
    isComparisonPeriod(targetDate: string) {
      if (this.mode === MODE.SELECTING && this.periodType === PERIOD_TYPE.COMPARE) return false
      if (!this.isValidOnComparisonStart || !this.isValidOnComparisonEnd) return false

      return moment(targetDate).isBetween(
        moment(this.comparisonStart).add(-1, 'day'),
        moment(this.comparisonEnd).add(1, 'day')
      )
    },
    isHoveringPeriod(targetDate: string) {
      const dayOfHoveringDate = moment(this.hoveringDate).day()

      if (dayOfHoveringDate === WEEKS.SUNDAY['value'])
        return moment(targetDate).isBetween(
          moment(this.hoveringDate).startOf('week').subtract(7, 'day'),
          moment(this.hoveringDate).add(1, 'day')
        )
      else
        return moment(targetDate).isBetween(
          moment(this.hoveringDate).startOf('week'),
          moment(this.hoveringDate).endOf('week').add(1, 'day')
        )
    },
    isSelectingBasePeriod(targetDate: string) {
      if (this.mode === MODE.PREVIEW || this.periodType === 'compare') return false

      return moment(targetDate).isBetween(
        moment(this.getSelectingStartDate()).add(-1, 'day'),
        moment(this.getSelectingEndDate()).add(1, 'day')
      )
    },
    isSelectingComparisonPeriod(targetDate: string) {
      if (this.mode === MODE.PREVIEW || this.periodType === PERIOD_TYPE.BASE) return false

      return moment(targetDate).isBetween(
        moment(this.getSelectingStartDate()).add(-1, 'day'),
        moment(this.getSelectingEndDate()).add(1, 'day')
      )
    },
    isInvalidPeriod(targetDate: string) {
      if (!this.$store.state.availablePeriod) return true

      const { start, end } = this.$store.state.availablePeriod.daily as {
        start: string
        end: string
      }

      return !moment(targetDate).isBetween(moment(start).add(-1, 'day'), moment(end).add(1, 'day'))
    },
    isActivate() {
      return (
        (this.startDate !== this.baseStart ||
          this.endDate !== this.baseEnd ||
          this.compareStartDate !== this.comparisonStart ||
          this.compareEndDate !== this.comparisonEnd) &&
        this.isValidOnBaseStart &&
        this.isValidOnBaseEnd &&
        (this.isValidOnComparisonStart || !this.compare) &&
        (this.isValidOnComparisonEnd || !this.compare)
      )
    },
    isValid(value: string) {
      // 空欄を弾く
      if (!value) return false
      // YYYY/MM/DD 以外かつ存在しない日付を弾く
      if (!moment(value, 'YYYY/MM/DD', true).isValid()) return false
      // 選択不可の日付を弾く
      if (this.isInvalidPeriod(value)) return false

      return true
    },
    getLastMonth(targetDate: string) {
      return moment(targetDate).add(-1, 'month').format('YYYY/MM/DD')
    },
    getNextMonth(targetDate: string) {
      return moment(targetDate).add(1, 'month').format('YYYY/MM/DD')
    },
    getYearMonth(targetDate: string) {
      return moment(targetDate).format('YYYY年MM月')
    },
    getMonday(targetDate: string | undefined) {
      let date = moment(targetDate)
      const dayOfWeekNum = date.day()

      if (dayOfWeekNum === WEEKS.SUNDAY['value'])
        return date.subtract(6, 'day').format('YYYY/MM/DD')
      else return date.startOf('week').add(1, 'day').format('YYYY/MM/DD')
    },
    getSunday(targetDate: string | undefined) {
      let date = moment(targetDate)
      const dayOfWeekNum = date.day()

      if (dayOfWeekNum === WEEKS.SUNDAY['value']) return date.format('YYYY/MM/DD')
      else return date.endOf('week').add(1, 'day').format('YYYY/MM/DD')
    },
    getDay(targetDate: string) {
      return moment(targetDate).format('DD').replace(/^0+/, '')
    },
    /**
     * 対象日付が含まれるカレンダーの最初の日付(月曜日)を返す。
     * @param targetDate 対象日付
     */
    getStartDate(targetDate: string) {
      let date = moment(targetDate)
      // 月初を取得
      date.startOf('month')
      // 曜日を算出(日曜日:0, 月曜日:1, ...)
      const dayOfWeekNum = date.day()
      // 月初が日曜日の場合、１ヶ月前の「月曜日」を返す
      if (dayOfWeekNum === 0) return date.subtract(dayOfWeekNum + 6, 'days')
      // 月初が日曜日以外の場合、その月の「月曜日」を返す
      else return date.subtract(dayOfWeekNum - 1, 'days')
    },
    /**
     * 対象日付が含まれるカレンダーの最後の日付(日曜日)を返す。
     * @param targetDate 対象日付
     */
    getEndDate(targetDate: string) {
      let date = moment(targetDate)
      // その月の最後の日を取得
      date.endOf('month')
      // 曜日を算出(日曜日:0, 月曜日:1, ...)
      const dayOfWeekNum = date.day()
      // カレンダーの最後の日付を返す(月の最後の日から足し算より日曜日を算出)
      return date.add(7 - dayOfWeekNum, 'days')
    },
    /**
     * 対象日付が含まれるカレンダーを返す
     * @param targetDate 対象日付
     */
    getCalendar(targetDate: string) {
      let startDate = this.getStartDate(targetDate)
      // CHECK: カレンダーの週の数は固定するか
      let weekNum = 6
      let calendars: string[][] = []
      for (let week = 0; week < weekNum; week++) {
        let weekRow: string[] = []
        for (let day = 0; day < 7; day++) {
          weekRow = [
            ...weekRow,
            startDate
              .clone()
              .add(day + week * 7, 'days')
              .format('YYYY/MM/DD')
          ]
        }
        calendars = [...calendars, weekRow]
      }
      return calendars
    },
    getSelectingStartDate() {
      const dayOfFirstSelectDate = moment(this.firstSelectDate).day()
      const dayOfHoveringDate = moment(this.hoveringDate).day()

      if (this.firstSelectDate <= this.hoveringDate) {
        if (dayOfFirstSelectDate === WEEKS.SUNDAY['value'])
          return moment(this.firstSelectDate)
            .startOf('week')
            .subtract(6, 'day')
            .format('YYYY/MM/DD')
        if (dayOfFirstSelectDate !== WEEKS.SUNDAY['value'])
          return moment(this.firstSelectDate).startOf('week').add(1, 'day').format('YYYY/MM/DD')
      }

      if (this.firstSelectDate > this.hoveringDate) {
        if (dayOfHoveringDate === WEEKS.SUNDAY['value'])
          return moment(this.hoveringDate).startOf('week').subtract(6, 'day').format('YYYY/MM/DD')

        if (dayOfHoveringDate !== WEEKS.SUNDAY['value'])
          return moment(this.hoveringDate).startOf('week').add(1, 'day').format('YYYY/MM/DD')
      }
    },
    getSelectingEndDate() {
      const dayOfFirstSelectDate = moment(this.firstSelectDate).day()
      const dayOfHoveringDate = moment(this.hoveringDate).day()

      if (this.firstSelectDate <= this.hoveringDate) {
        if (
          dayOfFirstSelectDate === WEEKS.SUNDAY['value'] &&
          dayOfHoveringDate === WEEKS.SUNDAY['value']
        )
          return moment(this.hoveringDate).format('YYYY/MM/DD')
        if (
          dayOfFirstSelectDate !== WEEKS.SUNDAY['value'] &&
          dayOfHoveringDate === WEEKS.SUNDAY['value']
        )
          return moment(this.hoveringDate).endOf('week').subtract(6, 'day').format('YYYY/MM/DD')
        if (dayOfHoveringDate !== WEEKS.SUNDAY['value'])
          return moment(this.hoveringDate).endOf('week').add(1, 'day').format('YYYY/MM/DD')
      }

      if (this.firstSelectDate > this.hoveringDate) {
        if (
          dayOfFirstSelectDate === WEEKS.SUNDAY['value'] &&
          dayOfHoveringDate === WEEKS.SUNDAY['value']
        )
          return moment(this.firstSelectDate).format('YYYY/MM/DD')
        if (
          dayOfFirstSelectDate === WEEKS.SUNDAY['value'] &&
          dayOfHoveringDate !== WEEKS.SUNDAY['value']
        )
          return moment(this.firstSelectDate).endOf('week').subtract(6, 'day').format('YYYY/MM/DD')
        if (dayOfFirstSelectDate !== WEEKS.SUNDAY['value'])
          return moment(this.firstSelectDate).endOf('week').add(1, 'day').format('YYYY/MM/DD')
      }
    },
    /**
     * 比較期間にベースとなる期日の前日含む過去同日数を挿入
     */
    setPastPeriod() {
      const duration = moment(this.baseEnd).diff(moment(this.baseStart), 'days')
      this.comparisonEnd = moment(this.baseStart).subtract(1, 'day').format('YYYY/MM/DD')
      this.comparisonStart = moment(this.comparisonEnd)
        .subtract(duration, 'days')
        .format('YYYY/MM/DD')
    },
    /**
     * 最終日がカレンダーの一番右に表示されるようにする
     */
    refreshCalendarPosition() {
      this.currentDate = moment(this.baseEnd).subtract(1, 'month').format('YYYY/MM/DD')
    },
    clickDate(targetDate: string) {
      if (this.mode === MODE.PREVIEW) {
        this.mode = MODE.SELECTING
        this.firstSelectDate = targetDate
        return
      }

      if (this.mode === MODE.SELECTING) {
        if (this.periodType === PERIOD_TYPE.BASE) {
          this.baseStart = this.getSelectingStartDate()
          this.baseEnd = this.getSelectingEndDate()

          if (this.selectedCompareType?.value === COMPARE_TYPE.PREVIOUS.value) this.setPastPeriod()
        }
        if (this.periodType === PERIOD_TYPE.COMPARE) {
          this.comparisonStart = this.getSelectingStartDate()
          this.comparisonEnd = this.getSelectingEndDate()
        }

        this.hoveringDate = ''
        this.firstSelectDate = ''
        this.mode = MODE.PREVIEW
        return
      }
    },
    hoverDate(targetDate: string) {
      this.hoveringDate = targetDate
    },
    initHoveringDate() {
      this.hoveringDate = ''
    },
    focusBaseStart() {
      this.periodType = 'base'
      this.baseStartFocus = true
    },
    blurBaseStart() {
      this.baseStartFocus = false
      if (this.isValidOnBaseStart) this.baseStart = this.getMonday(this.baseStart)
      if (this.compare && this.selectedCompareType?.value === COMPARE_TYPE['PREVIOUS'].value)
        this.setPastPeriod()
    },
    focusBaseEnd() {
      this.periodType = 'base'
      this.baseEndFocus = true
    },
    blurBaseEnd() {
      this.baseEndFocus = false
      if (this.isValidOnBaseEnd) {
        this.baseEnd = this.getSunday(this.baseEnd)
        this.refreshCalendarPosition()
      }
      if (this.compare && this.selectedCompareType?.value === COMPARE_TYPE['PREVIOUS'].value)
        this.setPastPeriod()
    },
    focusComparisonStart() {
      this.periodType = 'compare'
      this.comparisonStartFocus = true
    },
    blurComparisonStart() {
      this.comparisonStartFocus = false
      if (this.isValidOnComparisonStart) this.comparisonStart = this.getMonday(this.comparisonStart)
    },
    focusComparisonEnd() {
      this.periodType = 'compare'
      this.comparisonEndFocus = true
    },
    blurComparisonEnd() {
      this.comparisonEndFocus = false
      if (this.isValidOnComparisonEnd) {
        this.comparisonEnd = this.getSunday(this.comparisonEnd)
        this.refreshCalendarPosition()
      }
    },
    clickApplyButton() {
      // 各日付を「月」ー「日」に変換
      this.baseStart = this.getMonday(this.baseStart)
      this.baseEnd = this.getSunday(this.baseEnd)
      if (this.comparisonStart !== '') this.comparisonStart = this.getMonday(this.comparisonStart)
      if (this.comparisonEnd !== '') this.comparisonEnd = this.getSunday(this.comparisonEnd)

      this.$emit('update-period', {
        startDate: this.baseStart,
        endDate: this.baseEnd,
        compareStartDate: this.comparisonStart,
        compareEndDate: this.comparisonEnd
      })
      this.menu = false
    }
  }
})
</script>

<style scoped>
.date-picker-container {
  display: flex;
  padding: 14px 14px 14px 7px;
  width: 850px;
  height: 230px;
  background: #eee;
  border: 1px solid #ccc;
  margin-top: 3px;
}
.bg-base {
  background-color: #fff;
  cursor: pointer;
  color: #000 !important;
}
.bg-yellow {
  background-color: #e5d225 !important;
}
.bg-gray {
  background-color: #e5e5e5;
}
.bg-light-gray {
  background-color: #efefef;
}
.bg-red {
  background-color: #eeacb4 !important;
}
.bg-blue {
  background-color: #a6cce7 !important;
}
.table {
  border-spacing: 1px;
}
.text-color-gray {
  color: #222;
}
.text-color-light-gray {
  color: #666;
}
.pointer-events-none {
  pointer-events: none;
}
.menu {
  display: flex;
  align-items: center;
  height: 38px;
  padding: 9px 5px 9px 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #fff;
  font-size: 14px;
  cursor: pointer;
}
.nav-button-wrap {
  display: flex;
  height: 100%;
  align-items: center;
}
.nav-go-last-button {
  padding-top: 2px;
  padding-left: 3px;
  width: 14.5px;
  height: 29px;
  border-radius: 19.5px 0 0 19.5px;
  background: #0e182e;
  cursor: pointer;
  transform: translateX(4px);
}
.nav-go-next-button {
  padding-top: 2px;
  padding-right: 3px;
  width: 14.5px;
  height: 29px;
  border-radius: 0 19.5px 19.5px 0;
  background: #0e182e;
  cursor: pointer;
  transform: translateX(-4px);
}
.calendar-header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 176px;
  height: 27px;
  background-color: #999;
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
  color: #fff;
  font-weight: 600;
  font-size: 13px;
}
.calendar-day {
  background-color: #fff;
  width: 24px;
  height: 23px;
  font-size: 11px;
}
.calendar-date {
  width: 24px;
  height: 23px;
  text-align: center;
  font-size: 11px;
}
.form-wrap {
  display: flex;
  align-items: start;
  width: 250.5px;
  padding-left: 5px;
}
.form-period {
  font-size: 13px;
  margin-bottom: 9px;
  text-align: left;
}
.form-line-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 23px;
}
.form-compare-wrap {
  display: flex;
  align-items: center;
  font-size: 13px;
  margin-bottom: 9px;
}
.form-button-wrap {
  display: flex;
  margin-top: 8px;
}
.period-input {
  display: flex;
  padding-left: 10px;
  font-size: 13px;
  width: 100px;
  height: 28px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: #fff;
}
.period-input-disabled {
  background: #e5e5e5;
  border-color: #e5e5e5;
}
.period-input-invalid {
  color: #c71916;
}
.icon-wrap {
  display: flex;
  align-items: center;
  margin-right: 7.45px;
}
.period-base-input-focus {
  border: 2px solid #dc5b6b;
  z-index: 0;
  outline: 0;
}
.period-comparison-input-focus {
  border: 2px solid #519ace;
  z-index: 0;
  outline: 0;
}
.button {
  padding: 4.23px 15px 6.77px;
  background-color: #222;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  border-radius: 4px;
  box-shadow: 1px 1px 0px 0px rgba(0, 0, 0, 0.16);
}
.button-disabled {
  color: #999;
  background-color: #b5b5b5;
  border-color: #b5b5b5;
  box-shadow: none;
}
.checkbox {
  height: 16px;
  width: 16px;
  margin-right: 5px;
  accent-color: #4d99d0;
}
.checkbox::before {
  border: 1px solid #ccc !important;
}
.triangle-down {
  margin-right: 12.35px;
  margin-left: 33px;
}
</style>

<style>
.v-menu__content {
  box-shadow: none !important;
}
</style>
