import { ChainWithColor } from '@/commons/interfaces'
import {
  ChainsAgeGenders,
  AgeGendersChartData,
  ChainsBehavioralDNA,
  BehavioralDNA,
  BehavioralDNAValue
} from '../../types'
import { ALERT_STYLE } from '../../enums'
import { COLOR, POINT_STYLE } from '@/commons/enums'

// google chart 用のオプション
const GOOGLE_CHART_OPTION = {
  TOOLTIP: {
    type: 'string',
    role: 'tooltip',
    p: { html: true }
  },
  ALERT: { type: 'string', role: 'style' },
  ANNOTATION: { type: 'string', role: 'annotation' }
} as const

/**
 * L1, L2 カテゴリーでソート
 * @param array
 * @returns
 */
function sortFirstAndSecondCategory(array: BehavioralDNA[]) {
  return array.sort((a, b) => {
    if (a.firstCategory.name !== b.firstCategory.name) {
      if (a.firstCategory.name > b.firstCategory.name) return 1
      if (a.firstCategory.name < b.firstCategory.name) return -1
    }
    if (a.secondCategory.name > b.secondCategory.name) return -1
    if (a.secondCategory.name < b.secondCategory.name) return 1
    return 0
  })
}

/**
 * 店舗比較 - 性別✕年代構成グラフのレスポンスデータを google charts の形式に加工する関数
 * @param graphs [{ chainName: "店舗A", visitCounts: [{ age: 0, chartItems: [ { gender: 0, value: 0, isAlert: true } ... ] } ... ] ... ]
 * @returns [
 *   { chainName: "店舗A", graph: [["性別", "10代", {}, {}, "20代", {}, {}, ... ], ["男性", 100, "<div>...</div>", "#eeeeee", ... ] ... ]}
 *   ...
 * ]
 */
// HACK: もっと綺麗に書けそう
export function processAgeGenderChart(graphs: ChainsAgeGenders): AgeGendersChartData {
  if (graphs.length === 0) {
    return []
  }

  // 最終的に返す配列
  const processed: AgeGendersChartData = graphs.map((graph) => {
    // 男性の合計 (割合[%]を計算する際に使用)
    const maleSum = graph.visitCounts.reduce((sum, a) => {
      const value = a.chartItems.find((item) => item.gender === 'male')?.value
      if (value) return sum + value
      return sum
    }, 0)

    // 女性の合計 (割合[%]を計算する際に使用)
    const femaleSum = graph.visitCounts.reduce((sum, a) => {
      const value = a.chartItems.find((item) => item.gender === 'female')?.value
      if (value) return sum + value
      return sum
    }, 0)

    const sum = maleSum + femaleSum

    const graphProcessed: (string | number | object | undefined)[][] = [
      [
        '性別',
        '10代',
        GOOGLE_CHART_OPTION.TOOLTIP,
        GOOGLE_CHART_OPTION.ALERT,
        '20代',
        GOOGLE_CHART_OPTION.TOOLTIP,
        GOOGLE_CHART_OPTION.ALERT,
        '30代',
        GOOGLE_CHART_OPTION.TOOLTIP,
        GOOGLE_CHART_OPTION.ALERT,
        '40代',
        GOOGLE_CHART_OPTION.TOOLTIP,
        GOOGLE_CHART_OPTION.ALERT,
        '50代',
        GOOGLE_CHART_OPTION.TOOLTIP,
        GOOGLE_CHART_OPTION.ALERT,
        '60代',
        GOOGLE_CHART_OPTION.TOOLTIP,
        GOOGLE_CHART_OPTION.ALERT
      ],
      ['男性', 0, '', '', 0, '', '', 0, '', '', 0, '', '', 0, '', '', 0, '', ''],
      ['女性', 0, '', '', 0, '', '', 0, '', '', 0, '', '', 0, '', '', 0, '', '']
    ]

    const chainName = graph.chain.name
    graph.visitCounts.forEach((visitCount) => {
      visitCount.chartItems.forEach((item) => {
        // 男性
        if (item.gender === 'male' && visitCount.age === 'teen') {
          graphProcessed[1][1] = item.value / sum
          graphProcessed[1][2] = createAgeGenderChartTooltipElement({
            chainName: chainName,
            title: '男性・10代',
            value: item.value.toLocaleString(),
            value2: String((Math.round((item.value / sum) * 1000) / 10).toFixed(1))
          })
          graphProcessed[1][3] = getAlertStyle(item.isAlert)
        }
        if (item.gender === 'male' && visitCount.age === 'twenties') {
          graphProcessed[1][4] = item.value / sum
          graphProcessed[1][5] = createAgeGenderChartTooltipElement({
            chainName: chainName,
            title: '男性・20代',
            value: item.value.toLocaleString(),
            value2: String((Math.round((item.value / sum) * 1000) / 10).toFixed(1))
          })
          graphProcessed[1][6] = getAlertStyle(item.isAlert)
        }
        if (item.gender === 'male' && visitCount.age === 'thirties') {
          graphProcessed[1][7] = item.value / sum
          graphProcessed[1][8] = createAgeGenderChartTooltipElement({
            chainName: chainName,
            title: '男性・30代',
            value: item.value.toLocaleString(),
            value2: String((Math.round((item.value / sum) * 1000) / 10).toFixed(1))
          })
          graphProcessed[1][9] = getAlertStyle(item.isAlert)
        }
        if (item.gender === 'male' && visitCount.age === 'forties') {
          graphProcessed[1][10] = item.value / sum
          graphProcessed[1][11] = createAgeGenderChartTooltipElement({
            chainName: chainName,
            title: '男性・40代',
            value: item.value.toLocaleString(),
            value2: String((Math.round((item.value / sum) * 1000) / 10).toFixed(1))
          })
          graphProcessed[1][12] = getAlertStyle(item.isAlert)
        }
        if (item.gender === 'male' && visitCount.age === 'fifties') {
          graphProcessed[1][13] = item.value / sum
          graphProcessed[1][14] = createAgeGenderChartTooltipElement({
            chainName: chainName,
            title: '男性・50代',
            value: item.value.toLocaleString(),
            value2: String((Math.round((item.value / sum) * 1000) / 10).toFixed(1))
          })
          graphProcessed[1][15] = getAlertStyle(item.isAlert)
        }
        if (item.gender === 'male' && visitCount.age === 'sixties') {
          graphProcessed[1][16] = item.value / sum
          graphProcessed[1][17] = createAgeGenderChartTooltipElement({
            chainName: chainName,
            title: '男性・60代',
            value: item.value.toLocaleString(),
            value2: String((Math.round((item.value / sum) * 1000) / 10).toFixed(1))
          })
          graphProcessed[1][18] = getAlertStyle(item.isAlert)
        }

        // 女性
        if (item.gender === 'female' && visitCount.age === 'teen') {
          graphProcessed[2][1] = item.value / sum
          graphProcessed[2][2] = createAgeGenderChartTooltipElement({
            chainName: chainName,
            title: '女性・10代',
            value: item.value.toLocaleString(),
            value2: String((Math.round((item.value / sum) * 1000) / 10).toFixed(1))
          })
          graphProcessed[2][3] = getAlertStyle(item.isAlert)
        }
        if (item.gender === 'female' && visitCount.age === 'twenties') {
          graphProcessed[2][4] = item.value / sum
          graphProcessed[2][5] = createAgeGenderChartTooltipElement({
            chainName: chainName,
            title: '女性・20代',
            value: item.value.toLocaleString(),
            value2: String((Math.round((item.value / sum) * 1000) / 10).toFixed(1))
          })
          graphProcessed[2][6] = getAlertStyle(item.isAlert)
        }
        if (item.gender === 'female' && visitCount.age === 'thirties') {
          graphProcessed[2][7] = item.value / sum
          graphProcessed[2][8] = createAgeGenderChartTooltipElement({
            chainName: chainName,
            title: '女性・30代',
            value: item.value.toLocaleString(),
            value2: String((Math.round((item.value / sum) * 1000) / 10).toFixed(1))
          })
          graphProcessed[2][9] = getAlertStyle(item.isAlert)
        }
        if (item.gender === 'female' && visitCount.age === 'forties') {
          graphProcessed[2][10] = item.value / sum
          graphProcessed[2][11] = createAgeGenderChartTooltipElement({
            chainName: chainName,
            title: '女性・40代',
            value: item.value.toLocaleString(),
            value2: String((Math.round((item.value / sum) * 1000) / 10).toFixed(1))
          })
          graphProcessed[2][12] = getAlertStyle(item.isAlert)
        }
        if (item.gender === 'female' && visitCount.age === 'fifties') {
          graphProcessed[2][13] = item.value / sum
          graphProcessed[2][14] = createAgeGenderChartTooltipElement({
            chainName: chainName,
            title: '女性・50代',
            value: item.value.toLocaleString(),
            value2: String((Math.round((item.value / sum) * 1000) / 10).toFixed(1))
          })
          graphProcessed[2][15] = getAlertStyle(item.isAlert)
        }
        if (item.gender === 'female' && visitCount.age === 'sixties') {
          graphProcessed[2][16] = item.value / sum
          graphProcessed[2][17] = createAgeGenderChartTooltipElement({
            chainName: chainName,
            title: '女性・60代',
            value: item.value.toLocaleString(),
            value2: String((Math.round((item.value / sum) * 1000) / 10).toFixed(1))
          })
          graphProcessed[2][18] = getAlertStyle(item.isAlert)
        }
      })
    })

    return {
      chain: graph.chain,
      maleRatio: maleSum / sum,
      femaleRatio: femaleSum / sum,
      graph: graphProcessed
    }
  })

  return processed.sort((a, b) => a.chain.orderIndex - b.chain.orderIndex)
}

export function processBehavioralDNAChart(graphs: ChainsBehavioralDNA) {
  // 店舗一覧
  const chains = graphs.map((graph) => graph.chain).sort((a, b) => a.orderIndex - b.orderIndex)

  // firstCategory, secondCategory 毎の thirdCategory を全店舗分で重複なしかつ全パターンで抜き出す
  const firstAndSecondCategories = [
    ...new Set(
      graphs.flatMap((graph) => {
        return sortFirstAndSecondCategory(graph.visitCounts).map((v) => {
          return v.firstCategory.name + '-' + v.secondCategory.name
        })
      })
    )
  ].map((category) => category.split('-'))

  const tmp = firstAndSecondCategories.map((firstAndSecondCategory) => {
    const [firstCategory, secondCategory] = firstAndSecondCategory
    const l1l2VisitCountMap = new Map(
      chains.map((chain) => {
        // TODO: name ではなく id で絞れるようにする
        const visitCount = graphs
          .find((value) => value.chain.id === chain.id)
          ?.visitCounts.find(
            (v) =>
              v.firstCategory.name === firstCategory && v.secondCategory.name === secondCategory
          )
        return [
          chain.name,
          // 1st, 2ndカテゴリーに合致しない場合は空を表すオブジェクトを返す
          visitCount?.secondCategory.deviationValue ?? {
            total: NaN,
            totalAlert: false,
            male: NaN,
            maleAlert: false,
            female: NaN,
            femaleAlert: false
          }
        ]
      })
    )
    return [firstAndSecondCategory.join(' > '), l1l2VisitCountMap]
  })

  const header = [
    'category',
    ...chains.flatMap((chain) => [
      chain.name,
      GOOGLE_CHART_OPTION.TOOLTIP,
      GOOGLE_CHART_OPTION.ALERT
    ])
  ]

  const getChart = (key: 'total' | 'male' | 'female') => {
    const alertKey = key === 'total' ? 'totalAlert' : key === 'male' ? 'maleAlert' : 'femaleAlert'

    return [
      header,
      ...tmp.map((element) => [
        element[0],
        ...chains.flatMap((chain) => {
          const behavioralDNAValue = (element[1] as Map<string, BehavioralDNAValue>).get(chain.name)
          return [
            (behavioralDNAValue ?? (new Object() as BehavioralDNAValue))[key] ?? NaN,
            createDNATooltipElement({
              positionKey: key,
              category: element[0] as string as string,
              chains: chains,
              behavioralDNAMap: element[1] as Map<string, BehavioralDNAValue>
            }),
            getAlertPoint(
              (behavioralDNAValue ?? (new Object() as BehavioralDNAValue))[alertKey],
              chain
            )
          ]
        })
      ])
    ]
  }

  return {
    total: getChart('total'),
    male: getChart('male'),
    female: getChart('female')
  }
}

export function processLevelThreeOfBehavioralDNAChart(graphs: ChainsBehavioralDNA) {
  if (graphs.length === 0) return

  // 店舗一覧
  const chains = graphs.map((graph) => graph.chain).sort((a, b) => a.orderIndex - b.orderIndex)

  // 1st, 2nd カテゴリー
  // [
  //   ["生活サービス", "金融機関"],
  //   ["生活サービス", "習い事、生涯学習"],
  //   ...
  // ]
  const firstAndSecondCategories = [
    ...new Set(
      graphs.flatMap((graph) => {
        return sortFirstAndSecondCategory(graph.visitCounts).map((v) => {
          return v.firstCategory.name + '-' + v.secondCategory.name
        })
      })
    )
  ].map((category) => category.split('-'))

  // 3rd カテゴリー (key: [1st, 2nd], value: [3rd_1, 3rd_2, ...])
  // Map {
  //   { ["生活サービス", "習い事、生涯学習"] => ["そろばん教室", "バレエ教室", "自動車学校", ...] }
  //   ...
  // }
  const thirdCategories = new Map(
    firstAndSecondCategories.map((value) => {
      return [
        value,
        [
          ...new Set(
            graphs.flatMap((graph) => {
              const foundThirdCategory = graph.visitCounts.find(
                (v) => v.firstCategory.name === value[0] && v.secondCategory.name === value[1]
              )?.thirdCategory
              if (foundThirdCategory) return foundThirdCategory.map((t) => t.name)
            })
          )
        ]
      ]
    })
  )

  // Map([L1, L2] => Map(L3 => Map(chain => deviationValue)))
  const l3 = new Map(
    firstAndSecondCategories.map((firstAndSecondCategory) => {
      const [firstCategory, secondCategory] = firstAndSecondCategory
      const gottenThirdCategory = thirdCategories.get(firstAndSecondCategory)
      const thirdCategoryMap = new Map(
        gottenThirdCategory?.map((l3C) => {
          const l3CMap = new Map(
            chains.map((chain) => {
              const deviationValue = graphs
                .find((value) => value.chain.id === chain.id)
                ?.visitCounts.find(
                  (v) =>
                    v.firstCategory.name === firstCategory &&
                    v.secondCategory.name === secondCategory
                )
                ?.thirdCategory.find((t) => t.name === l3C)?.deviationValue
              // 3rdカテゴリーに合致しない場合は空を表すオブジェクトを返す
              return [
                chain.name,
                deviationValue ?? {
                  total: NaN,
                  totalAlert: false,
                  male: NaN,
                  maleAlert: false,
                  female: NaN,
                  femaleAlert: false
                }
              ]
            })
          )
          return [l3C, l3CMap]
        })
      )
      return [firstAndSecondCategory, thirdCategoryMap]
    })
  )

  const header = [
    'category',
    ...chains.flatMap((chain) => [
      chain.name,
      GOOGLE_CHART_OPTION.TOOLTIP,
      GOOGLE_CHART_OPTION.ALERT
    ])
  ]

  const getChart = (type: 'total' | 'male' | 'female') => {
    const alertType =
      type === 'total' ? 'totalAlert' : type === 'male' ? 'maleAlert' : 'femaleAlert'

    return [
      ...Array.from(l3.entries()).map((l3C) => {
        const rows: (string | number | null | undefined)[][] = []
        l3C[1].forEach((value, key) => {
          const row: (string | number | null | undefined)[] = [key]
          chains.forEach((chain) => {
            row.push((value.get(chain.name) ?? (new Object() as BehavioralDNAValue))[type] ?? NaN)

            row.push(
              createDNATooltipElement({
                positionKey: type,
                category: key === void 0 ? '' : key,
                chains: chains,
                behavioralDNAMap: value
              })
            )
            row.push(
              getAlertPoint(
                (value.get(chain.name) ?? (new Object() as BehavioralDNAValue))[alertType] ?? NaN,
                chain
              )
            )
          })
          rows.push(row)
        })
        return [
          header,
          ...rows
            .filter((row) => !!row[0])
            .sort((a, b) => {
              if (a[0] !== b[0]) {
                if ((a[0] as string) > (b[0] as string)) return -1
                if ((a[0] as string) < (b[0] as string)) return 1
              }
              return 0
            })
        ]
      })
    ]
  }

  return {
    total: getChart('total'),
    male: getChart('male'),
    female: getChart('female')
  }
}

function createAgeGenderChartTooltipElement(args: {
  chainName: string
  title: string
  value: string
  value2: string
}) {
  if (!args.title || !args.value) return ''
  return `<div style='display: flex; justify-content: center; align-items: center; height: 90px; color: #222222; font-size: 12px !important;'>
            <div style='display:table-cell; vertical-align:middle; text-align: left;'>
              <div>${args.chainName}</div>
              <div>[性別×年代] <b>${args.title}</b></div>
              <div>[来店人数] <b>${args.value}人</b></div>
              <div>[構成比] <b>${args.value2}%</b></div>
            </div>
          </div>`
}

function createDNATooltipElement(args: {
  positionKey: string
  category: string
  chains: ChainWithColor[]
  behavioralDNAMap: Map<string, BehavioralDNAValue>
}) {
  const positionName =
    args.positionKey === 'total' ? '全体' : args.positionKey === 'male' ? '男性' : '女性'
  let tooltipElement = `<div 
                           style='
                            display: flex; 
                            justify-content: center;
                            align-items: center; 
                            min-width: 150px; 
                            color: #222222; 
                            font-size: 12px !important;
                            margin-top: 15px;
                            margin-bottom: 15px;
                          '>
                    <div style='display:table-cell; vertical-align:middle; text-align: left;'>
                    <div>${args.category}</div>
                    <br>`

  const isNaNorNull = (value: number | null) => {
    return value === null || isNaN(value)
  }
  args.chains.flatMap((chain) => {
    const behavioralDNAValue = args.behavioralDNAMap.get(chain.name)
    const value = (() => {
      if (behavioralDNAValue === void 0) return '--'
      return args.positionKey === 'total'
        ? !isNaNorNull(behavioralDNAValue.total)
          ? String(behavioralDNAValue.total)
          : '--'
        : args.positionKey === 'male'
          ? !isNaNorNull(behavioralDNAValue.male)
            ? String(behavioralDNAValue.male)
            : '--'
          : !isNaNorNull(behavioralDNAValue.female)
            ? String(behavioralDNAValue.female)
            : '--'
    })()
    tooltipElement += `<div><span style="color: ${chain.color}">● </span>${chain.name}</div>
                       <div style="margin-bottom: 10px">[${positionName}スコア] <b>${value}</b></div>`
  })
  tooltipElement += '</div></div>'
  return tooltipElement
}

function getAlertStyle(isAlert: boolean | undefined) {
  return isAlert ? ALERT_STYLE : ''
}

function getAlertPoint(isAlert: boolean | undefined, chain: ChainWithColor) {
  if (isAlert) {
    switch (chain.color) {
      case COLOR.RED:
        return POINT_STYLE.RED['alert']
      case COLOR.BLUE:
        return POINT_STYLE.BLUE['alert']
      case COLOR.GREEN:
        return POINT_STYLE.GREEN['alert']
      case COLOR.ORANGE:
        return POINT_STYLE.ORANGE['alert']
      case COLOR.PURPLE:
        return POINT_STYLE.PURPLE['alert']
      case COLOR.BROWN:
        return POINT_STYLE.BROWN['alert']
      default:
        return POINT_STYLE.RED['alert']
    }
  }
  return chain.color
}
