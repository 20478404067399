<template>
  <input
    v-model="childChecked"
    type="checkbox"
    class="custom-checkbox"
  >
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "CustomCheckbox",
  model: {
    prop: "checked",
  },
  props: {
    checked: { type: Boolean },
  },
  computed: {
    childChecked: {
      get() {
        return (this as any).checked;
      },
      set(val: any) {
        (this as any).$emit("input", val);
      },
    },
  },
});
</script>

<style lang="scss" scoped>
.custom-checkbox {
  height: 16px;
  width: 16px;
  accent-color: #4d99d0;
  cursor: pointer;

  &::before {
    border: 1px solid #ccc !important;
  }
}
</style>
