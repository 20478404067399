<template>
  <div>
    <div class="compare-list-header">
      <CustomInput
        v-model="search"
        placeholder="リスト名・店舗名の一部で検索"
        width="360px"
        height="44px"
      />
      <div class="number-of-registrations">
        登録数：{{ filteredComparisonGroups.length }}件
      </div>
      <v-spacer />
      <CustomButton
        variant="primary"
        size="md"
        @click="clickCompareCreate"
      >
        + リストを作成
      </CustomButton>
    </div>
    <LoadingImg
      v-if="loading"
      height="600px"
    />
    <CompareListTable
      v-else-if="filteredComparisonGroups.length > 0"
      :groups="filteredComparisonGroups"
      :sort-by="sortBy"
      @set-sort-by="setSortBy($event)"
    />
    <NoList v-else />
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, reactive } from "vue";
import CompareListTable from "@/features/Dashboard/components/CompareListTable.vue";
import NoList from "@/features/Dashboard/components/NoList.vue";
import LoadingImg from "@/commons/components/loadingImg.vue";
import { ComparisonGroup } from "@/commons/interfaces";
import { useStore} from "vuex";
import { useRouter } from 'vue-router';

export default defineComponent({
  name: "CompareList",
  components: { CompareListTable, NoList, LoadingImg },
  setup() {
    const store = useStore();
    const router = useRouter();
    const loading = ref(false);
    const search = ref("");

    const sortBy = reactive({ column: "updatedAt", desc: true } as {
      column: "name" | "updatedAt";
      desc: boolean;
    });
    const filteredComparisonGroups = computed(() => {
      if (!store.state.comparisonGroups.length) return [];
      return (store.state.comparisonGroups as ComparisonGroup[])
        .filter(
          (group) =>
            group.name.indexOf(search.value) > -1 ||
            group.stores.some((store) => store.name.indexOf(search.value) > -1)
        )
        .sort((a, b) => {
          if (sortBy.column === "name")
            return sortBy.desc
              ? (a.name as string).localeCompare(b.name as string, "ja")
              : (b.name as string).localeCompare(a.name as string, "ja");
          if (sortBy.column === "updatedAt")
            return sortBy.desc
              ? (a.updated_at as string) > (b.updated_at as string)
                ? -1
                : 1
              : (a.updated_at as string) < (b.updated_at as string)
                ? -1
                : 1;
          return Number(a.id) - Number(b.id);
        });
    });
    const fetchData = async () => {     
      loading.value = true;
      if (!store.state.comparisonGroups.length)
        await store.dispatch("fetchComparisonGroups");
      loading.value = false;
    };
    const clickCompareCreate = () => {
      router.push({ name: "StoreCompareCreate" });
    };
    const setSortBy = (event: { type: "name" | "updatedAt" }) => {
      if (sortBy.column === event.type) {
        sortBy.desc = !sortBy.desc;
      } else {
        sortBy.column = event.type;
        sortBy.desc = true;
      }
    };

    fetchData();

    return {
      loading,
      search,
      sortBy,
      filteredComparisonGroups,
      clickCompareCreate,
      setSortBy,
    };
  },  
});
</script>

<style lang="scss" scoped>
.compare-list-header {
  display: flex;
  align-items: center;
  margin-top: 26px;
  margin-bottom: 28px;
  height: 46px;

  .number-of-registrations {
    margin-left: 25px;
    font: normal normal normal 14px/20px Noto Sans JP;
    letter-spacing: 0px;
    color: #222222;
  }
}

.mb-50px {
  margin-bottom: 50px;
}
</style>
