<template>
  <v-container class="pa-0" fluid style="min-width: 1180px">
    <v-row class="trend-title-container" dense no-gutters>
      <v-col style="display: flex; align-items: center">
        <h1 class="title-text">居住者に人気のチェーン</h1>
        <update-badge type="month" style="margin-left: 15px" />
      </v-col>
      <v-spacer />
      <monthly-date-picker
        :date="period"
        @update-period="updatePeriod"
      />
    </v-row>
    <v-row dense no-gutters class="mt-29px">
      <v-col>
        <AreaSelector :on-click-analytics="onClickAnalytics" />
      </v-col>
    </v-row>
    <v-row
      dense
      no-gutters
      class="mb-5"
    >
      <trend-ranking-card
        :area-id="areaId"
        :period="period"
      />
    </v-row>
    <v-row
      dense
      no-gutters
    >
      <behavioral-dna-card
        :area-id="areaId"
        :period="period"
        :type="TREND_TYPE.CHAIN"
      />
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import { ref } from "vue";
import UpdateBadge from "@/commons/components/Elements/UpdateBadge.vue";
import AreaSelector from '@/features/Trend/TrendStore/components/AreaSelector.vue'
import MonthlyDatePicker from '@/features/Trend/components/MonthlyDatePicker.vue'
import TrendRankingCard from '@/features/Trend/TrendChain/components/TrendRankingCard.vue'
import BehavioralDnaCard from '@/features/Trend/TrendStore/components/BehavioralDnaCard.vue'
import { TREND_TYPE } from '@/features/Trend/enums'
import { useRoute } from 'vue-router';
const route = useRoute()

import moment from "moment";
const getInitialStartDate = () => {
  if (typeof route.query.period === 'string') {
    return route.query.period
  } else {
    const SUBTRACT_NUM = moment().date() < 8 ? 2 : 1;
    return moment()
      .subtract(SUBTRACT_NUM, "month")
      .startOf("month")
      .format("YYYY-MM-DD");
  }
};
const period = ref(getInitialStartDate())
function updatePeriod(month: string) {
  period.value = month
}

const areaId = ref<{
  prefectureIds: string[],
  cityIds: string[] | undefined,
  townIds: string[] | undefined
} | undefined>(undefined)
function onClickAnalytics (prefectureIds: number[], cityIds: string[], townIds: string[]) {
  areaId.value = {
    prefectureIds: prefectureIds.map((id: number) => id.toString()),
    cityIds,
    townIds
  }
}
</script>

<style scoped>
.trend-title-container {
  display: flex;
  align-items: center;
  margin-top: 32.5px !important;
  margin-bottom: 10px !important;
}
.mt-29px {
  margin-top: 29px;
}
</style>
