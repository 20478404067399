import { WEEKS, GENDER, AGE } from "@/commons/enums";

/**
 * 指定した日付の曜日を返す
 * @param date 日付
 * @returns 指定した日付の曜日
 */
export function getDayOfWeekString(date: string): string {
  const dayOfWeek = new Date(date).getDay();
  switch (dayOfWeek) {
    case WEEKS.SUNDAY.value:
      return WEEKS.SUNDAY.text;
    case WEEKS.MONDAY.value:
      return WEEKS.MONDAY.text;
    case WEEKS.TUESDAY.value:
      return WEEKS.TUESDAY.text;
    case WEEKS.WEDNESDAY.value:
      return WEEKS.WEDNESDAY.text;
    case WEEKS.THURSDAY.value:
      return WEEKS.THURSDAY.text;
    case WEEKS.FRIDAY.value:
      return WEEKS.FRIDAY.text;
    case WEEKS.SATURDAY.value:
      return WEEKS.SATURDAY.text;
    default:
      return "";
  }
}

export function getGenderLabelByValue(value: number): string {
  switch (value) {
    case GENDER.MALE.value:
      return GENDER.MALE.text;
    case GENDER.FEMALE.value:
      return GENDER.FEMALE.text;
    default:
      return "";
  }
}

export function getAgeLabelByValue(value: number): string {
  switch (value) {
    case AGE.TEEN.value:
      return AGE.TEEN.text;
    case AGE.TWENTIES.value:
      return AGE.TWENTIES.text;
    case AGE.THIRTIES.value:
      return AGE.THIRTIES.text;
    case AGE.FORTIES.value:
      return AGE.FORTIES.text;
    case AGE.FIFTIES.value:
      return AGE.FIFTIES.text;
    case AGE.SIXTIES.value:
      return AGE.SIXTIES.text;
    default:
      return "";
  }
}
