import { axiosClient } from '@/plugins/axios'
import { AxiosResponse } from 'axios'
import { TrendStoreChartResponse, GeoPrefecturesResponse, GeoCitiesResponse, GeoTownsResponse, TrendBehavioralDnaResponse } from '@/features/Trend/TrendStore/interfaces'
import { TREND_TYPE } from '@/features/Trend/enums'
import { DownloadResponse } from "@/commons/interfaces/responses/download";
import { convertMonthlyHyphenDelimiter } from '@/commons/utils/dateUtil'

export function getTrendStoresRanking(
  prefectureIds: string[],
  cityIds: string[] | undefined,
  townIds: string[] | undefined,
  storeTypes: number[],
  period: string,
  summaryUnit: (typeof TREND_TYPE)[keyof typeof TREND_TYPE]
): Promise<AxiosResponse<TrendStoreChartResponse>> {
  if (prefectureIds.length === 0) return Promise.reject()
  return axiosClient.get<TrendStoreChartResponse>(
    '/trend/chart/ranking/',
    {
      params: {
        prefectureIds,
        cityIds,
        townIds,
        storeTypes,
        period: convertMonthlyHyphenDelimiter(period),
        summaryUnit
      }
    }
  )
}
export function downloadTrendStoresRanking(
  prefectureIds: string[],
  cityIds: string[] | undefined,
  townIds: string[] | undefined,
  storeTypes: number[],
  period: string,
  summaryUnit: (typeof TREND_TYPE)[keyof typeof TREND_TYPE]
): Promise<AxiosResponse<DownloadResponse>> {
  if (prefectureIds.length === 0) return Promise.reject()
  return axiosClient.get<DownloadResponse>(
    '/trend/chart/ranking/download/',
    {
      params: {
        prefectureIds,
        cityIds,
        townIds,
        storeTypes,
        period: convertMonthlyHyphenDelimiter(period),
        summaryUnit
      }
    }
  )
}

export function getPrefecturesGeo(prefectureIds: string[]): Promise<AxiosResponse<GeoPrefecturesResponse>> {
  return axiosClient.post<GeoPrefecturesResponse>('/prefectures/geo/', { prefectureIds })
}
export function getCitiesGeo(cityIds: string[]): Promise<AxiosResponse<GeoCitiesResponse>> {
  return axiosClient.post<GeoCitiesResponse>('/cities/geo/', { cityIds })
}
export function getTownsGeo(townIds: string[]): Promise<AxiosResponse<GeoTownsResponse>> {
  return axiosClient.post<GeoTownsResponse>('/towns/geo/', { townIds })
}

export function getTrendBehavioralDna(
  prefectureIds: string[] | undefined,
  cityIds: string[] | undefined,
  townIds: string[] | undefined,
  period: string,
  summaryUnit: (typeof TREND_TYPE)[keyof typeof TREND_TYPE]
): Promise<AxiosResponse<TrendBehavioralDnaResponse>> {
  if (!prefectureIds || prefectureIds.length === 0) return Promise.reject()
  return axiosClient.get<TrendBehavioralDnaResponse>(
    '/trend/chart/behavioral-dna/',
    {
      params: {
        prefectureIds,
        cityIds,
        townIds,
        period: convertMonthlyHyphenDelimiter(period),
        summaryUnit
      }
    }
  )
}

export function downloadBehavioralDnaChart(
  prefectureIds: string[] | undefined,
  cityIds: string[] | undefined,
  townIds: string[] | undefined,
  period: string,
  level: number,
  summaryUnit: (typeof TREND_TYPE)[keyof typeof TREND_TYPE]
): Promise<AxiosResponse> {
  if (!prefectureIds || prefectureIds.length === 0) return Promise.reject();
  return axiosClient.get<DownloadResponse>(
    "/trend/chart/behavioral-dna/download/",
    {
      params: {
        prefectureIds,
        cityIds,
        townIds,
        period: convertMonthlyHyphenDelimiter(period),
        level: level,
        summaryUnit
      },
    }
  );
}
