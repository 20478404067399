<template>
  <div class="map-popup">
    <img
      class="close"
      src="@/assets/svg/close-small.svg"
      @click="$emit('closePopup')"
    >
    <div class="popup_content">
      <h1>{{ title }}</h1>
      <p>
        [構成比] <b>{{ text }}</b>
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "BizAreaMapPopup",
  props: {
    title: { type: String, default: undefined },
    text: { type: String, default: undefined },
  },
  emits: ["closePopup"],
});
</script>

<style lang="scss" scoped>
.map-popup {
  min-width: 150px;
  background: #ffffff 0% 0% no-repeat padding-box;
  text-align: left;
  border-radius: 3.5px;

  .close {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
  }
}
.popup_content {
  padding: 12px 16px;
  font-family: Noto Sans JP;
  color: #222222;
  background: #ffffff;
  text-align: left;

  h1 {
    font-size: 11px;
    font-weight: bold;
  }

  p {
    font-size: 11px;
    margin: 0;
  }
}
</style>
