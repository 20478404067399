<template>
  <v-container
    class="pa-0"
    fluid
  >
    <v-row
      class="title-container"
      dense
      no-gutters
    >
      <v-col style="display: flex; align-items: center">
        <h1 class="title-text">
          ペルソナ特性
        </h1>
        <update-badge
          type="week"
          style="margin-left: 15px"
        />
      </v-col>
      <v-spacer />
      <date-picker
        :start-date="$store.state.startDate"
        :end-date="$store.state.endDate"
        :compare-start-date="$store.state.compareStartDate"
        :compare-end-date="$store.state.compareEndDate"
        :enable-comparison="false"
        @update-period="updatePeriod"
      />
    </v-row>
    <v-row
      dense
      no-gutters
      class="card-container"
    >
      <v-col>
        <store-selector :loading="storeLoading" />
      </v-col>
    </v-row>
    <v-row
      dense
      no-gutters
      class="card-container"
    >
      <v-col>
        <age-gender-card
          :chart-data="ageGenderChartData"
          :chart-loading="ageGenderChartLoading"
          :has-alert="hasAlertInAgeGenderChartData"
          :get-file-id="getAgeGenderFileId"
          :csv-name="ageGenderCSVName"
        />
      </v-col>
    </v-row>
    <v-row
      dense
      no-gutters
      class="card-container mb-0"
    >
      <v-col>
        <BehavioralDNACard
          :chart-data="behavioralDNAChartData"
          :chart-loading="behavioralDNAChartLoading"
          :legends="legends"
          :hasl1l2-alert="hasAlertInBehavioralDNAChartData"
          :get-l2-file-id="getBehavioralDnaL2FileId"
          :get-l3-file-id="getBehavioralDnaL3FileId"
          :l2-c-s-v-name="behavioralDNAL2CSVName"
          :l3-c-s-v-name="behavioralDNAL3CSVName"
          :colors="chartColors"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";
// TODO: commons に寄せる
import DatePicker from "@/features/ShopAnalytics/components/Common/datePicker.vue";
import StoreSelector from "@/features/StoreCompare/components/StoreSelector.vue";
import UpdateBadge from "@/features/ShopAnalytics/components/Common/updateBadge.vue";
import AgeGenderCard from "@/features/StoreCompare/persona/components/AgeGenderCard.vue";
import BehavioralDNACard from "@/features/StoreCompare/persona/components/BehavioralDNACard.vue";

// api
// TODO: commons に寄せる
import {
  getAgeGenderChart,
  getBehavioralDnaChart,
} from "@/features/ShopAnalytics/axios/persona";
import {
  downloadAgeGenderCSV,
  downloadBehavioralDNACSV,
} from "@/features/StoreCompare/persona/axios";

// enum
import { ALERT_STYLE } from "@/features/StoreCompare/persona/enums";
import { POINT_STYLE } from "@/commons/enums";

// utils
import { getPeriodByRouterQueryPeriod } from "@/commons/utils";
import {
  convertHyphenDelimiter,
  convertSlashDelimiter,
} from "@/commons/utils/dateUtil";
import {
  processAgeGenderChart,
  processBehavioralDNAChart,
  processLevelThreeOfBehavioralDNAChart,
} from "@/features/StoreCompare/persona/utils";

// types
// TODO: commons に寄せる
import {
  ComparisonGroup,
  ComparisonGroupStore,
} from "@/features/StoreCompare/types";
import {
  AgeGender,
  AgeGendersChartData,
  BehavioralDNA,
  BehavioralDNAChartData,
} from "@/features/StoreCompare/persona/types";
import * as notify from "@/plugins/notification";
import { AxiosResponse } from "axios";
import { debounce } from "lodash";

export default defineComponent({
  components: {
    DatePicker,
    StoreSelector,
    AgeGenderCard,
    BehavioralDNACard,
    UpdateBadge,
  },
  data() {
    return {
      created: false,
      storeLoading: false,
      ageGenderChartData: [] as AgeGendersChartData,
      ageGenderChartLoading: false,
      behavioralDNAChartData: {
        overall: [],
        total: [],
        male: [],
        female: [],
        thirdCategory: {
          total: [],
          male: [],
          female: [],
        },
      } as BehavioralDNAChartData,
      behavioralDNAChartLoading: false,
      ageGenderChartController: new AbortController(),
      behavioralDNAChartController: new AbortController(),
    };
  },
  computed: {
    hasRouterQueryPeriod(): boolean {
      return this.$route.query.period !== undefined;
    },
    hasRouterQueryUnit(): boolean {
      return this.$route.query.unit !== undefined;
    },
    hasAlertInAgeGenderChartData(): boolean {
      return this.ageGenderChartData
        .flatMap((value) => value.graph.flat())
        .includes(ALERT_STYLE);
    },
    hasAlertInBehavioralDNAChartData(): boolean {
      return [
        ...this.behavioralDNAChartData.total.flat().flat(),
        ...this.behavioralDNAChartData.male.flat().flat(),
        ...this.behavioralDNAChartData.female.flat().flat(),
      ].some((e) =>
        (
          [
            POINT_STYLE.RED["alert"],
            POINT_STYLE.BLUE["alert"],
            POINT_STYLE.GREEN["alert"],
            POINT_STYLE.ORANGE["alert"],
            POINT_STYLE.PURPLE["alert"],
            POINT_STYLE.BROWN["alert"],
          ] as (string | number | object | null)[]
        ).includes(e)
      );
    },
    // NOTE: watch で監視するために定義
    selectedComparisonGroup(): ComparisonGroup {
      return this.$store.state.selectedComparisonGroup;
    },
    selectedStores(): ComparisonGroupStore[] {
      return this.$store.getters.selectedComparisonGroupVisibleStores;
    },
    legends(): { text: string; color: string }[] {
      if (!this.$store.state.selectedComparisonGroup) return [];
      return this.selectedStores.map((store) => {
        return { text: store.name, color: store.color ?? "" };
      });
    },
    chartColors(): string[] {
      if (!this.$store.state.selectedComparisonGroup) return [];
      if (this.selectedStores.length === 0) return [];
      return this.selectedStores.map((s) => s.color ?? "");
    },
    getAgeGenderFileId(): () => Promise<AxiosResponse<any, any>> {
      return () =>
        downloadAgeGenderCSV(
          this.selectedStores.map((s) => s.id),
          this.$store.state.startDate,
          this.$store.state.endDate
        );
    },
    getBehavioralDnaL2FileId(): () => Promise<AxiosResponse<any, any>> {
      return () =>
        downloadBehavioralDNACSV(
          this.selectedStores.map((s) => s.id),
          this.$store.state.startDate,
          this.$store.state.endDate,
          2
        );
    },
    getBehavioralDnaL3FileId(): () => Promise<AxiosResponse<any, any>> {
      return () =>
        downloadBehavioralDNACSV(
          this.selectedStores.map((s) => s.id),
          this.$store.state.startDate,
          this.$store.state.endDate,
          3
        );
    },
    ageGenderCSVName(): string {
      const { start, end } = {
        start: convertSlashDelimiter(this.$store.state.startDate),
        end: convertSlashDelimiter(this.$store.state.endDate),
      };
      return `店舗比較_性別✕年代構成_${start}-${end}`;
    },
    behavioralDNAL2CSVName(): string {
      const { start, end } = {
        start: convertSlashDelimiter(this.$store.state.startDate),
        end: convertSlashDelimiter(this.$store.state.endDate),
      };
      return `店舗比較_行動DNA_Level2_${start}-${end}`;
    },
    behavioralDNAL3CSVName(): string {
      const { start, end } = {
        start: convertSlashDelimiter(this.$store.state.startDate),
        end: convertSlashDelimiter(this.$store.state.endDate),
      };
      return `店舗比較_行動DNA_Level3_${start}-${end}`;
    },
  },
  watch: {
    async selectedComparisonGroup() {
      if (this.created) {
        this.ageGenderChartController.abort();
        this.behavioralDNAChartController.abort();

        await this.fetchAgeGenderChart();
        await this.fetchBehavioralDNAChart();
        await this.pushRoute();
      }
    },
    selectedStores: debounce(async function (this: any) {
      if (this.created) {
        this.ageGenderChartController.abort();
        this.behavioralDNAChartController.abort();

        await this.fetchAgeGenderChart();
        await this.fetchBehavioralDNAChart();
        await this.pushRoute();
      }
    }, 500),
  },
  async created() {
    // クエリパラメータから日時指定を取得
    this.setDateFromRouterQuery();

    this.storeLoading = true;
    // 店舗一覧
    if (!this.$store.state.stores.length)
      await this.$store.dispatch("fetchStores");

    // 比較店舗リスト一覧
    if (!this.$store.state.comparisonGroups.length)
      await this.$store.dispatch("fetchComparisonGroups");

    // id があれば選択比較店舗リストを設定,無ければ初期化
    if (this.$route.params["id"]) {
      await this.$store.dispatch(
        "specifiedComparisonGroup",
        this.$route.params["id"]
      );
      if (!this.selectedStores || this.selectedStores.length === 0)
        this.$router.push({ name: "NotFound" });
    } else this.$store.commit("initComparisonGroup");
    this.storeLoading = false;

    await this.fetchAgeGenderChart();
    await this.fetchBehavioralDNAChart();
    await this.pushRoute();

    this.created = true;
  },
  methods: {
    initAgeGenderChartData() {
      this.ageGenderChartData.length = 0;
    },
    initBehavioralDNAChartData() {
      this.behavioralDNAChartData.overall = [];
      this.behavioralDNAChartData.male = [];
      this.behavioralDNAChartData.female = [];
    },
    setDateFromRouterQuery() {
      if (this.hasRouterQueryPeriod) {
        let tmp = getPeriodByRouterQueryPeriod(this.$route.query.period as string);
        if (tmp !== undefined) this.$store.commit("setDate", tmp);
      }
    },
    async fetchAgeGenderChart() {
      if (!this.selectedComparisonGroup) return;

      this.ageGenderChartLoading = true;

      // チャートデータの初期化
      this.initAgeGenderChartData();

      this.ageGenderChartController = new AbortController();
      const graphs: {
        store: ComparisonGroupStore;
        visitCounts: AgeGender[];
      }[] = [];
      let canceled = false;
      await Promise.all(
        this.selectedStores.map(async (store) => {
          await getAgeGenderChart(
            store.id,
            this.$store.state.startDate,
            this.$store.state.endDate,
            this.ageGenderChartController.signal
          )
            .then((res) => {
              graphs.push({
                store: store,
                visitCounts: res.data.visitCounts,
              });
            })
            .catch((e) => {
              if (typeof e === "undefined" || e.code !== "ERR_CANCELED") {
                notify.notifyErrorMessage(
                  "性別✕年代構成チャートが表示できませんでした。"
                );
              } else if (e.code === "ERR_CANCELED") {
                canceled = true;
              }
            });
        })
      ).then((f) => {
        if (this.selectedStores.length === f.length && !canceled)
          this.ageGenderChartLoading = false;
      });

      this.ageGenderChartData = processAgeGenderChart(graphs);
    },

    async fetchBehavioralDNAChart() {
      if (!this.selectedComparisonGroup) return;

      this.behavioralDNAChartLoading = true;

      // チャートデータの初期化
      this.initBehavioralDNAChartData();

      this.behavioralDNAChartController = new AbortController();
      const graphs: {
        store: ComparisonGroupStore;
        visitCounts: BehavioralDNA[];
      }[] = [];
      let canceled = false;
      await Promise.all(
        this.selectedStores.map(async (store) => {
          await getBehavioralDnaChart(
            store.id,
            this.$store.state.startDate,
            this.$store.state.endDate,
            this.behavioralDNAChartController.signal
          )
            .then((res) => {
              graphs.push({
                store: store,
                visitCounts: res.data.visitCounts,
              });
            })
            .catch((e) => {
              if (typeof e === "undefined" || e.code !== "ERR_CANCELED") {
                notify.notifyErrorMessage(
                  "行動DNAチャートが表示できませんでした。"
                );
              } else if (e.code === "ERR_CANCELED") {
                canceled = true;
              }
            });
        })
      ).then((f) => {
        if (this.selectedStores.length === f.length && !canceled)
          this.behavioralDNAChartLoading = false;
      });

      const processed = processBehavioralDNAChart(graphs);
      const l3Processed = processLevelThreeOfBehavioralDNAChart(graphs);
      /* eslint-disable @typescript-eslint/ban-ts-comment */
      // @ts-ignore
      this.behavioralDNAChartData.total = processed.total;
      // @ts-ignore
      this.behavioralDNAChartData.male = processed.male;
      // @ts-ignore
      this.behavioralDNAChartData.female = processed.female;
      // @ts-ignore
      this.behavioralDNAChartData.thirdCategory = l3Processed;
      /* eslint-enable @typescript-eslint/ban-ts-comment */

    },

    async updatePeriod(period: {
      startDate: string;
      endDate: string;
      compareStartDate: string;
      compareEndDate: string;
    }) {
      this.$store.commit("setDate", {
        startDate: period.startDate,
        endDate: period.endDate,
      });
      this.$store.commit("setCompareDate", {
        startDate: period.compareStartDate,
        endDate: period.compareEndDate,
      });

      if (this.created) {
        await this.fetchAgeGenderChart();
        await this.fetchBehavioralDNAChart();
        await this.pushRoute();
      }
    },

    async pushRoute() {
      if (!this.selectedComparisonGroup) return;

      const tmp = {
        start: convertHyphenDelimiter(this.$store.state.startDate),
        end: convertHyphenDelimiter(this.$store.state.endDate),
      };

      // NOTE: 他ページに遷移後にreplaceが呼び出されると戻される対策
      if (this.$route.name === "StoreComparePersona") {
        await this.$router
          .replace({
            name: "StoreComparePersona",
            params: { id: this.$store.state.selectedComparisonGroup.id },
            query: {
              period: `${tmp.start}_${tmp.end}`,
            },
          })
          .catch(() => undefined);
      }
    },
  },
});
</script>
