<template>
  <v-container
    fluid
    class="share_table"
    style="background-color: white"
  >
    <loading-img
      v-if="loading"
      style="height: 600px"
    />
    <div
      v-else-if="baseData === undefined && !loadFinished"
      class="unselected_card"
    >
      店舗を選択するとチャートが表示されます。
    </div>
    <no-data-chart v-else-if="emptyRegionalShareTableData && loadFinished" />
    <div v-else>
      <v-data-table
        v-model:items-per-page="itemsPerPage"
        v-model:page="page"
        :items="compDatas"
        :items-per-page-options="[10, 25, 50, -1]"
      >
        <template #headers v-if="baseData">
          <tr class="table_head">
            <th class="column_head column_head_spacer" />
            <th class="column_name">
              店舗名
            </th>
            <th class="column_type">
              業種
            </th>
            <td class="column_distance">
              <sort-button
                :show-status="{
                  up: sortBy !== 'distance' || !sortDesc,
                  down: sortBy !== 'distance' || sortDesc,
                }"
                @sort-action="sortColumn('distance')"
              >
                距離
              </sort-button>
            </td>
            <td
              v-for="item in baseData.visitCount"
              :key="'head_caption_' + item.date"
              class="column_value"
            >
              <sort-button
                :show-status="{
                  up: sortBy !== item.date || !sortDesc,
                  down: sortBy !== item.date || sortDesc,
                }"
                @sort-action="sortColumn(item.date)"
              >
                {{ formatMonth(item.date) }}
              </sort-button>
            </td>
          </tr>
          <tr>
            <th class="column_head base_head">
              基準店舗
            </th>
            <th class="column_name base">
              <router-link
                :to="{
                  name: 'ShopAnalyticsVisitor',
                  params: { id: baseData.storeId },
                }"
                class="store_link"
              >
                {{ baseData.name }}
              </router-link>
            </th>
            <th class="column_type base">
              <span
                :class="{
                  supermarket: baseData.type === storeType.SUPER_MARKET.value,
                  drugstore: baseData.type === storeType.DRUG_STORE.value,
                  homecenter: baseData.type === storeType.HOME_CENTER.value,
                  cvsstore: baseData.type === storeType.CVS_STORE.value,
                }"
              >
                ●
              </span>
              {{ storeType.toLocalString(baseData.type) }}
            </th>
            <th class="column_distance base">
              0m
            </th>
            <th
              v-for="item in baseData.visitCount"
              :key="'head_base_' + item.date"
              class="column_value base"
            >
              <div :style="getBackgroundBarStyle(item.value, true)">
                {{ formatVisitRateValue(item.value) }}%
              </div>
            </th>
          </tr>
        </template>
        <template #body="props">
          <tr
            v-for="compData in props.items"
            :key="compData.storeId + '_' + compData.name"
          >
            <th class="column_head" />
            <th class="column_name">
              <router-link
                :to="{
                  name: 'ShopAnalyticsVisitor',
                  params: { id: compData.storeId },
                }"
                class="store_link"
              >
                {{ compData.name }}
              </router-link>
            </th>
            <th class="column_type">
              <span
                :class="{
                  supermarket: compData.type === storeType.SUPER_MARKET.value,
                  drugstore: compData.type === storeType.DRUG_STORE.value,
                  homecenter: compData.type === storeType.HOME_CENTER.value,
                  cvsstore: compData.type === storeType.CVS_STORE.value,
                }"
              >
                ●
              </span>
              {{ storeType.toLocalString(compData.type) }}
            </th>
            <th class="column_distance">
              {{ Number(compData.distance).toLocaleString() }}m
            </th>
            <th
              v-for="compItem in compData.visitCount"
              :key="'body_comp_' + compItem.date"
              class="column_value"
            >
              <div :style="getBackgroundBarStyle(compItem.value, false)">
                {{ formatVisitRateValue(compItem.value) }}%
              </div>
            </th>
          </tr>
        </template>
      </v-data-table>
    </div>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, computed, watch } from "vue";
import LoadingImg from "@/commons/components/loadingImg.vue";
import { StoreRegionTableItem } from "@/features/RegionalShare/interfaces/response";
import { STORE_TYPE } from "@/commons/enums";
import { convertMonthlySlashDelimiter } from "@/commons/utils/dateUtil";
import SortButton from "@/commons/components/Elements/SortButton.vue";
import NoDataChart from "@/features/ShopAnalytics/components/Common/noDataChart.vue";

export default defineComponent({
  name: "RegionalShareTable",
  components: {
    LoadingImg,
    SortButton,
    NoDataChart,
  },
  props: {
    loading: {
      type: Boolean,
    },
    baseData: {
      type: Object as PropType<StoreRegionTableItem> | undefined,
      default: undefined,
    },
    compDatas: {
      type: Array as PropType<StoreRegionTableItem[]>,
      default: () => [],
    },
    sortBy: {
      type: String,
      default: undefined,
    },
    sortDesc: {
      type: Boolean,
    },
    // データ読み込み済みかどうかを判別するためのフラグ
    loadFinished: {
      type: Boolean,
    },
  },
  emits: ["sort"],
  setup(props, { emit }) {
    const page = ref(1);
    const maxValue = ref(1);
    const itemsPerPage = ref(50);

    const emptyRegionalShareTableData = computed((): boolean => {
      return props.baseData === undefined || props.compDatas.length === 0;
    });
    const sortColumn = (sortKey: string) => {
      emit("sort", sortKey);
    };
    const getMaxValue = () => {
      let _maxValue = 0;
      if (props.baseData) {
        const baseMaxValue = props.baseData.visitCount.reduce(
          (
            previus: { date: string; value: number },
            current: { date: string; value: number }
          ) => (previus.value > current.value ? previus : current)
        ).value;
        if (_maxValue < baseMaxValue) _maxValue = baseMaxValue;
      }
      let compMaxValue = 0;
      if (
        props.compDatas && props.compDatas.length < itemsPerPage.value ||
        itemsPerPage.value === -1
      ) {
        props.compDatas?.forEach((compData: StoreRegionTableItem) => {
          const tmpMaxValue = compData.visitCount.reduce(
            (
              nestedPrevius: { date: string; value: number },
              nestedCurrent: { date: string; value: number }
            ) =>
              nestedPrevius.value > nestedCurrent.value
                ? nestedPrevius
                : nestedCurrent
          ).value;
          if (compMaxValue < tmpMaxValue) compMaxValue = tmpMaxValue;
        });
      } else {
        const currentData = props.compDatas.slice(
          (page.value - 1) * itemsPerPage.value,
          page.value * itemsPerPage.value
        );
        currentData.forEach((compData: StoreRegionTableItem) => {
          const tmpMaxValue = compData.visitCount.reduce(
            (
              nestedPrevius: { date: string; value: number },
              nestedCurrent: { date: string; value: number }
            ) =>
              nestedPrevius.value > nestedCurrent.value
                ? nestedPrevius
                : nestedCurrent
          ).value;
          if (compMaxValue < tmpMaxValue) compMaxValue = tmpMaxValue;
        });
      }

      if (_maxValue < compMaxValue) _maxValue = compMaxValue;
        maxValue.value = _maxValue;
    };
    const getBackgroundBarStyle = (value: number, isBase: boolean) => {
      const color = isBase ? "#bad8ef" : "#eeeeee";
      return {
        width: `${(value / maxValue.value) * 100}%`,
        height: "26px",
        "line-height": "26px",
        background: `${color} 0% 0% no-repeat padding-box`,
      };
    };
    const formatMonth = (dateString: string): string => {
      return convertMonthlySlashDelimiter(dateString);
    };
    const formatVisitRateValue = (rawValue: number): string => {
      // 割合が0~1の範囲で渡されるのでこれを100倍して0~100の範囲に変換する
      // 小数点以下2桁の部分を四捨五入したいので10倍して四捨五入し、10で割る
      // 小数点以下1桁だけ欲しいのでtoFixedする
      return (Math.round(rawValue * 100 * 10) / 10).toFixed(1);
    };

    watch(() => props.compDatas, () => {
      getMaxValue();
    });
    watch(page, () => {
      getMaxValue();
    });
    watch(itemsPerPage, () => {
      getMaxValue();
    });

    return {
      storeType: STORE_TYPE,
      page,
      itemsPerPage,
      emptyRegionalShareTableData,
      sortColumn,
      getBackgroundBarStyle,
      formatMonth,
      formatVisitRateValue,
    };
  },
});
</script>

<style scoped lang="scss">
.v-data-table :deep(table) {
  border-collapse: collapse !important;
  border: 1px solid #ccc !important;
}
.v-data-table :deep(td) {
  border-collapse: collapse !important;
  border: 1px solid #ccc !important;
  font-size: 13px !important;
}
.v-data-table :deep(th) {
  border-collapse: collapse !important;
  border: 1px solid #ccc !important;
  user-select: text !important;
  font-size: 13px !important;
}
.v-data-table {
  white-space: nowrap;

  .table_head {
    th,
    td {
      background-color: #f5f5f5;
      height: 44px;
      font-weight: bold;
    }
    td {
      padding: 0 10px;
    }
  }
  tr {
    th.base_head {
      background-color: #4d99d0;
      color: #ffffff !important;
      font-weight: bold;
      padding: 0px 8px !important;
    }
    th.base {
      background: #f4fbff 0% 0% no-repeat padding-box;
    }
    th.column_head {
      z-index: 1;
      min-width: 70px;
      max-width: 70px;
      text-align: center;
      position: sticky;
      left: 0;
    }
    th {
      padding: 0px 10px !important;
      height: 44px !important;
      background-color: white;
    }
  }
  .column_name {
    z-index: 1;
    min-width: 212px;
    max-width: 212px;
    text-align: start;
    position: sticky;
    left: 70px;
    font-weight: bold;
  }
  .column_type {
    z-index: 1;
    text-align: start;
    position: sticky;
    left: calc(70px + 212px);
    font-weight: normal;
  }
  .column_distance {
    min-width: 90px;
    max-width: 90px;
    text-align: end;
    font-weight: normal;
  }
  .column_value {
    min-width: 95px;
    max-width: 95px;
    text-align: start;
    font-weight: bold;
  }
}
.supermarket {
  color: #e47075;
}
.drugstore {
  color: #87b8a1;
}
.homecenter {
  color: #cb9e56;
}
.cvsstore {
  color: #9278c3;
}
.sort_button {
  width: 6.78px;
  height: 12px;
  margin-right: 8.4px;
}
.value_bar {
  width: 100%;
  height: 22px;
  background: #eeeeee 0% 0% no-repeat padding-box;
}
.store_link {
  color: #4d99d0;
  text-decoration-line: none;
}
.share_table {
  padding: 0 30px 30px 30px !important;
}
</style>
