<template>
  <v-dialog
    :value="dialog"
    :attach="true"
    max-width="800px"
    @click:outside="close"
  >
    <div class="favorite-condition-dialog">
      <h1>
        検索条件を入力<span>（複数組み合わせ可。業種は必ず選んでください）</span>
      </h1>
      <table>
        <tr>
          <th>業種</th>
          <td
            colspan="3"
            class="industry"
          >
            <div class="store-types">
              <div
                v-for="type in $store.state.storeTypes"
                :key="type.id"
              >
                <CustomCheckbox
                  v-if="type.id === STORE_TYPE.SUPER_MARKET['value']"
                  :checked="supermarket"
                  class="checkbox"
                  @input="handleEvent($event, 'supermarket')"
                />
                <CustomCheckbox
                  v-if="type.id === STORE_TYPE.DRUG_STORE['value']"
                  :checked="drugstore"
                  class="checkbox"
                  @input="handleEvent($event, 'drugstore')"
                />
                <CustomCheckbox
                  v-if="type.id === STORE_TYPE.HOME_CENTER['value']"
                  :checked="homecenter"
                  class="checkbox"
                  @input="handleEvent($event, 'homecenter')"
                />
                <CustomCheckbox
                  v-if="type.id === STORE_TYPE.CVS_STORE['value']"
                  v-model="cvsstore"
                  class="checkbox"
                />
                <span class="name">{{ type.name }}</span>
              </div>
            </div>
          </td>
        </tr>
        <tr>
          <th>都道府県</th>
          <td class="p-10px">
            <v-autocomplete
              v-model="selectedPrefectureValues"
              :search="prefectureSearch"
              :items="prefectures"
              placeholder="都道府県の一部を入力, 複数選択可"
              multiple
              variant="outlined"
              density="compact"
              hide-no-data
              hide-details
              @update:model-value="forceClearSearchInput('prefectureSearch')"
            />
          </td>
        </tr>
        <tr>
          <th>市区町村</th>
          <td class="p-10px">
            <v-autocomplete
              v-model="selectedCityValues"
              :search="citySearch"
              :items="cities"
              :class="{ disabled: cities.length === 0 }"
              placeholder="市区町村の一部を入力, 複数選択可"
              :disabled="cities.length === 0"
              multiple
              variant="outlined"
              density="compact"
              hide-no-data
              hide-details
              @update:model-value="forceClearSearchInput('citySearch')"
            />
          </td>
        </tr>
        <tr>
          <th>チェーン</th>
          <td
            colspan="3"
            class="p-10px"
          >
            <v-autocomplete
              v-model="chainNameValues"
              :search="chainNameSearch"
              :items="chainNames"
              placeholder="チェーン名の一部を入力, 複数選択可"
              multiple
              variant="outlined"
              density="compact"
              hide-no-data
              hide-details
              @update:model-value="forceClearSearchInput('chainNameSearch')"
            />
          </td>
        </tr>
        <tr>
          <th>店舗名（フリーワード）</th>
          <td
            colspan="3"
            class="p-10px"
          >
            <CustomInput
              v-model="freeWord"
              placeholder="店舗名の一部を入力, カンマ区切りで複数選択可"              
              width="520px"
              height="38px"
              class="border-gray"
            />
          </td>
        </tr>
      </table>
      <CustomButton
        variant="primary"
        size="md"
        class="mt-14px"
        width="280px"
        height="46px"
        :disabled="disabled"
        @click="clickSearch"
      >
        この条件で検索
      </CustomButton>
    </div>
  </v-dialog>
</template>

<script lang="ts">
import CustomCheckbox from "@/commons/components/Elements/CustomCheckbox.vue";
import { Favorite, City, Prefecture } from "@/features/Dashboard/types";
import { Store } from "@/commons/interfaces/responses/store";
import { STORE_TYPE as STORE_TYPE_ENUM } from "@/commons/enums";
import { defineComponent, PropType, computed, ref, watch, nextTick } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  components: { CustomCheckbox },

  model: {
    prop: "dialog",
    event: "change-dialog",
  },

  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    favorite: {
      type: Object as PropType<Favorite>,
      defult: undefined,
    },
  },
  emits: ["clickSearch", "change-dialog"],
  setup(props, { emit }) {
    const store = useStore();
    const STORE_TYPE = STORE_TYPE_ENUM;
    const freeWord = ref("");
    const supermarket = ref(false);
    const drugstore = ref(false);
    const homecenter = ref(false);
    const cvsstore = ref(false);
    const prefectureValues = ref([] as number[]);
    const selectedPrefectureValues = ref([] as string[]);
    const cityValues = ref([] as string[]);
    const selectedCityValues = ref([] as string[]);
    const chainNameValues = ref([] as string[]);
    const prefectureSearch = ref("");
    const citySearch = ref("");
    const chainNameSearch = ref("");
   

    const prefectures = computed(():  string[] => {
      return (store.state.prefectures as Prefecture[]).map(
        (prefecture) => {
          return prefecture.name ;
        }
      );
    });

    const cities = computed((): string[] => {
      return (store.state.cities as City[])
        .filter((city) => prefectureValues.value.includes(city.prefectureId))
        .map((city) => {
          return  city.name;
        });
    });
    const chainNames = computed((): string[] => {
      //const store = useStore();
      return Array.from(
        new Set(
          (store.state.stores as Store[])
            .filter((store) => store.chainName !== null)
            .map((store) => store.chainName as string)
        )
      );
    });
    const disabled = computed((): boolean => {
      return (
        freeWord.value.length === 0 &&
        prefectureValues.value.length === 0 &&
        cityValues.value.length === 0 &&
        !supermarket.value &&
        !drugstore.value &&
        !homecenter.value &&
        !cvsstore.value
      );
    });
    watch(selectedPrefectureValues ,(newPrefectureValues, oldPrefectureValues) => {
      prefectureValues.value.splice(0);
      (store.state.prefectures as Prefecture[]).map(
        (prefecture) => {
          selectedPrefectureValues.value.forEach((selectedPrefectureValue) => {
            if (prefecture.name === selectedPrefectureValue) {
              prefectureValues.value.push(prefecture.prefectureId);
            }
          });          
        }
      );

      // 都道府県の選択数が減った際に選択済み市区町村を絞り込む
      if (newPrefectureValues.length < oldPrefectureValues.length) {
        selectedCityValues.value.splice(0);
        const oldCityValues = cityValues.value.slice();
        cityValues.value = (store.state.cities as City[])
          .filter(
            (city) =>
              prefectureValues.value.includes(city.prefectureId) &&
              oldCityValues.some((id) => city.cityId === id)
          )
          .map((city) => {
            selectedCityValues.value.push(city.name);
            return city.cityId
          });
      }
    });
    watch(selectedCityValues, (newSelectedCities, oldSelectedCities) => {
      let _cities = (store.state.cities as City[]);          
      // 選択された市区町村から市区町村IDを取得
      _cities.forEach((city) => {
        selectedCityValues.value.forEach((selectedCityValue) => {
          if (city.name === selectedCityValue && !cityValues.value.includes(city.cityId)) {
            cityValues.value.push(city.cityId);
          }
        });
      });
      if (newSelectedCities.length < oldSelectedCities.length) {             
        selectedCityValues.value.splice(0);
        cityValues.value.splice(0);
        newSelectedCities.forEach(
            (newSelectedCity) => {
              let _cityId = _cities.find((city: City) => city.name === newSelectedCity)?.cityId;              
              if(_cityId)cityValues.value.push(_cityId);        
            }
          );        
       }    
    });

    const clickSearch = () => {
      emit("clickSearch", { favorite: getFavoriteFromData() });
    };
    const close = () =>{
      emit("change-dialog", false);
    };
    const getFavoriteFromData = (): Favorite => {
      const storeNameWords: string[] =
        freeWord.value.length > 0 ? freeWord.value.split(/,|、/i) : [];
      const storeTypes: number[] = [];
      if (supermarket.value) storeTypes.push(STORE_TYPE.SUPER_MARKET["value"]);
      if (drugstore.value) storeTypes.push(STORE_TYPE.DRUG_STORE["value"]);
      if (homecenter.value) storeTypes.push(STORE_TYPE.HOME_CENTER["value"]);
      if (cvsstore.value) storeTypes.push(STORE_TYPE.CVS_STORE["value"]);

      return {
        prefectureIds: prefectureValues.value,
        cityIds: cityValues.value,
        chainWords: chainNameValues.value,
        storeTypes: storeTypes,
        storeNameWords: storeNameWords,
      };
    };
    const setDataFromFavorite = () => {
      if (!props.favorite) return;
      prefectureValues.value = props.favorite.prefectureIds;
      //表示用の都道府県設定
      let _prefectures = (store.state.prefectures as Prefecture[]);                
      prefectureValues.value.forEach(
          (prefectureValue) => {
            let _prefectureName = _prefectures.find((prefecture: Prefecture) => prefecture.prefectureId === prefectureValue)?.name;
            if(_prefectureName)selectedPrefectureValues.value.push(_prefectureName);        
          }
        );   
      cityValues.value = props.favorite.cityIds;
      cityValues.value.forEach(
        (cityValue) => {
          let _cityName = (store.state.cities as City[]).find((city: City) => city.cityId === cityValue)?.name;
          if(_cityName)selectedCityValues.value.push(_cityName);        
        }
      );

      chainNameValues.value = props.favorite.chainWords;
      supermarket.value = props.favorite.storeTypes.includes(
        STORE_TYPE.SUPER_MARKET["value"]
      );
      drugstore.value = props.favorite.storeTypes.includes(
        STORE_TYPE.DRUG_STORE["value"]
      );
      homecenter.value = props.favorite.storeTypes.includes(
        STORE_TYPE.HOME_CENTER["value"]
      );
      cvsstore.value = props.favorite.storeTypes.includes(
        STORE_TYPE.CVS_STORE["value"]
      );
      freeWord.value = props.favorite.storeNameWords.join(",");
    };
  

    const handleEvent = (event: any, target:string) => {
      // targetの値を変更する
      if (typeof event === 'boolean'){                
        if (target === "supermarket"){
          supermarket.value = event;
        }else if(target === "drugstore"){
          drugstore.value = event;        
        }else if(target === "homecenter"){
          homecenter.value = event;        
        }else if(target === "cvsstore"){
          cvsstore.value = event;        
        }
      }
    };

    // 現状Vuetify3のVAutocompleteは外部からの検索フィールドのクリアが直接サポートされていない為、
    // 一時的な値を設定して空文字に更新することで入力値残留問題に対処
    const TEMP_UPDATE_VALUE = "TEMP_UPDATE_VALUE"

    const forceClearSearchInput = (search: string) => {
      switch (search) {
        case 'prefectureSearch':
          prefectureSearch.value = TEMP_UPDATE_VALUE;
          nextTick(() => prefectureSearch.value = '');
          break;
        case 'citySearch':
          citySearch.value = TEMP_UPDATE_VALUE;
          nextTick(() => citySearch.value = '');
          break;
        case 'chainNameSearch':
          chainNameSearch.value = TEMP_UPDATE_VALUE;
          nextTick(() => chainNameSearch.value = '');
          break;
      }
    };

    setDataFromFavorite();

    return {
      STORE_TYPE,
      freeWord,
      supermarket,
      drugstore,
      homecenter,
      cvsstore,
      selectedPrefectureValues,
      selectedCityValues,
      chainNameValues,
      prefectureSearch,
      citySearch,
      chainNameSearch,
      prefectures,
      cities,
      chainNames,
      disabled,
      clickSearch,
      close,
      handleEvent,
      forceClearSearchInput,
    };
  },
});
</script>

<style lang="scss" scoped>
.favorite-condition-dialog {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 30px;
  width: 800px;
  min-height: 485px;
  height: 100%;
  border-radius: 6px;
  background: white;

  h1 {
    margin-bottom: 19px;
    text-align: left;
    font-size: 15px;
    font-weight: bold;
    color: #222222;
    width: 100%;

    span {
      font-weight: normal;
      color: #666666;
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
    border-top: 1px solid #dddddd;

    tr {
      height: 60px;
      border-bottom: 1px solid #dddddd;

      .industry {
        padding: 0 10px;
        text-align: left;

        .store-types {
          display: flex;

          .checkbox {
            transform: translateY(3px);
          }

          .name {
            margin-left: 10px;
            margin-right: 35px;
            font-size: 14px;
          }
        }
      }

      th {
        width: 200px;
        font-size: 14px;
        font-weight: bold;
        color: #222222;
        background-color: #f5f5f5;
      }

      td {
        .disabled {
          background-color: #f5f5f5 !important;
        }
        .border-gray {
          border: 1px solid rgba(0, 0, 0, 0.42);
          &:hover {
            outline: none;
            border: 1px solid #666;
          }

          &:focus {
            outline: none;
            border: 1px solid #666;
          }
        }
      }
    }
  }

  .mt-14px {
    margin-top: 14px;
  }

  .mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .p-10px {
    padding: 10px;
  }
}
</style>

<style lang="scss">
.favorite-condition-dialog {

  .v-field {
    font-size: 14px !important;
  }

  .v-field__input {
    padding: 0 12px;

    input::placeholder {
      color: rgba(0,0,0,.38);
      opacity: 1;
    }

    input::focus {
      outline: none !important;
    }
  }

  .v-field.v-field--focused {
    .v-field__outline {
      .v-field__outline__start,
      .v-field__outline__end {
        border-color: rgb(25, 118, 210) !important;
      }
    }
  }
}
</style>
