<template>
  <div class="regional_map_popup" @mouseleave="emit('leavePopup')">
    <img class="close" src="@/assets/svg/close-small.svg" @click="emit('closePopup')" />
    <div class="popup_content">
      <div class="popup_name">
        {{ props.title }}
      </div>
      <div class="popup_description">
        [業種]
        <span
          :class="{
            supermarket: props.storeType === storeTypes.SUPER_MARKET.value,
            drugstore: props.storeType === storeTypes.DRUG_STORE.value,
            homecenter: props.storeType === storeTypes.HOME_CENTER.value,
            cvsstore: props.storeType === storeTypes.CVS_STORE.value
          }"
        >
          ●
        </span>
        <b>{{ props.storeTypeText }}</b>
      </div>
      <div class="popup_description">
        [併用率]
        <b>{{
          props.combinationRatio && !props.isAlert
            ? formatVisitRateValue(props.combinationRatio) + '%'
            : '-'
        }}</b>
      </div>
    </div>
    <div v-if="combinationRatio">
      <button class="popup_move_store_button" @click="emit('moveStore')">この店舗を分析</button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { STORE_TYPE } from '@/commons/enums'

const props = withDefaults(
  defineProps<{
    title: string | undefined
    storeType: number | undefined
    storeTypeText: string | undefined
    combinationRatio?: number
    isAlert?: boolean
    onMouseout?: (payload: MouseEvent) => void
  }>(),
  {
    title: undefined,
    storeType: undefined,
    storeTypeText: undefined,
    combinationRatio: undefined,
    isAlert: false,
    onMouseout: undefined
  }
)
const emit = defineEmits(['closePopup', 'moveStore', 'leavePopup'])
const storeTypes = ref(STORE_TYPE)

// NOTE: 小数点第二位以下を四捨五入したのち、小数点第一位四捨五入で返却
const formatVisitRateValue = (rawValue: number): string => {
  return (Math.round(rawValue * 100 * 10) / 10).toFixed(1)
}
</script>

<style lang="scss" scoped>
.regional_map_popup {
  width: 220px;
  min-height: 120px;
  background: #ffffff 0% 0% no-repeat padding-box;
  text-align: left;
  border-radius: 3.5px;

  .close {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
  }
}
.popup_content {
  min-height: 87.5px;
  padding-top: 12px;
  padding-left: 15px;
  padding-right: 15px;
  font: normal normal normal 12px/26px Noto Sans JP;
}
.popup_change_store_button {
  height: 32.5px;
  width: 50%;
  border-top: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
  font: normal normal normal 12px/26px Noto Sans JP;

  &:focus {
    outline: none;
  }
}
.popup_move_store_button {
  height: 32.5px;
  width: 100%;
  border-top: 1px solid #dddddd;
  font: normal normal normal 12px/26px Noto Sans JP;

  &:focus {
    outline: none;
  }
}
.popup_name {
  font-size: 13px;
  letter-spacing: 0px;
  color: #333333;
  font: normal normal bold 13px/19px Noto Sans JP;
}
.popup_description {
  font-size: 12px;
  letter-spacing: 0px;
}
.supermarket {
  color: #e47075;
}
.drugstore {
  color: #87b8a1;
}
.homecenter {
  color: #cb9e56;
}
.cvsstore {
  color: #9278c3;
}
</style>
