<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="viewBox"
  >
    <g
      id="noun-download-2577615"
      transform="translate(-4 -0.997)"
    >
      <path
        id="パス_1023"
        data-name="パス 1023"
        d="M14.5,22.752V21.586A.583.583,0,0,0,13.915,21H4.583A.583.583,0,0,0,4,21.586v1.166a.583.583,0,0,0,.583.583h9.331A.583.583,0,0,0,14.5,22.752Z"
        transform="translate(0 -8.338)"
        :fill="color"
      />
      <path
        id="パス_1024"
        data-name="パス 1024"
        d="M11.421,1H9.088a.581.581,0,0,0-.583.583V6.83H6.994a.585.585,0,0,0-.414,1l3.26,3.5a.581.581,0,0,0,.822,0l3.26-3.5a.585.585,0,0,0-.414-1H12V1.581A.585.585,0,0,0,11.421,1Z"
        transform="translate(-1.004 0)"
        :fill="color"
      />
    </g>
  </svg>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    width: { type: Number, default: 10.498 },
    height: { type: Number, default: 14 },
    color: { type: String, default: "#4d99d0" },
  },
  computed: {
    viewBox() {
      return `0 0 ${this.width} ${this.height}`;
    },
  },
});
</script>
