<template>
  <div class="favorite-detail-table">
    <table>
      <tr class="header">
        <th>
          <span>
            都道府県
            <SortButton
              class="sort-icon"
              :show-status="{
                up: sortBy.column !== 'prefecture' || !sortBy.desc,
                down: sortBy.column !== 'prefecture' || sortBy.desc,
              }"
              @sort-action="setSortBy('prefecture')"
            />
          </span>
        </th>
        <th>
          <span>
            チェーン名
            <SortButton
              class="sort-icon"
              :show-status="{
                up: sortBy.column !== 'chainName' || !sortBy.desc,
                down: sortBy.column !== 'chainName' || sortBy.desc,
              }"
              @sort-action="setSortBy('chainName')"
            />
          </span>
        </th>
        <th>店舗名</th>
        <th>
          <span>
            業種
            <SortButton
              class="sort-icon"
              :show-status="{
                up: sortBy.column !== 'storeType' || !sortBy.desc,
                down: sortBy.column !== 'storeType' || sortBy.desc,
              }"
              @sort-action="setSortBy('storeType')"
            />
          </span>
        </th>
        <th />
      </tr>
      <tr
        v-for="store in storesSlicedByPage"
        :key="store.storeId"
        class="body"
      >
        <td>{{ getPrefectureName(store.prefectureId) }}</td>
        <td>{{ store.chainName ?? "-" }}</td>
        <td>
          <a @click="clickAnalytics(store.storeId)">{{ store.name }}</a>
          <p>{{ store.address }}</p>
        </td>
        <td>
          <span
            :class="{
              'dot-red': store.storeType === STORE_TYPE.SUPER_MARKET['value'],
              'dot-green': store.storeType === STORE_TYPE.DRUG_STORE['value'],
              'dot-yellow': store.storeType === STORE_TYPE.HOME_CENTER['value'],
              'dot-purple': store.storeType === STORE_TYPE.CVS_STORE['value'],
            }"
          >
            ●
          </span>
          {{ STORE_TYPE.toLocalString(store.storeType) }}
        </td>
        <td>
          <CustomButton
            variant="primary"
            size="sm"
            width="108px"
            height="30px"
            @click="clickAnalytics(store.storeId)"
          >
            分析する
          </CustomButton>
        </td>
      </tr>
    </table>
    <div class="table-footer">
      <v-spacer />
      <span
        v-if="stores.length !== 0"
        class="caption"
      >
        {{ pageFrom }}-{{ pageTo }} 件 / {{ stores.length }}件
      </span>
      <span
        v-else
        class="caption"
      > 0件 </span>
      <CustomSelector
        v-model="itemsPerPage"
        :items="[
          { text: '30件', value: 30 },
          { text: '50件', value: 50 },
        ]"
        size="xs"
        width="75px"
        height="30px"
        class="ml-8px"
      />
      <TableNavigation
        :click-next="next"
        :click-previous="previous"
        :next-disabled="page * itemsPerPage.value >= stores.length"
        :previous-disabled="page === 1"
        class="ml-15px"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, computed, ref, watch } from "vue";
import TableNavigation from "@/features/Dashboard/components/TableNavigation.vue";
import SortButton from "@/commons/components/Elements/SortButton.vue";
import { Store } from "@/commons/interfaces/responses/store";
import { STORE_TYPE as STORE_TYPE_ENUM } from "@/commons/enums";
import { Prefecture } from "@/features/Dashboard/types";
import { convertHyphenDelimiter } from "@/commons/utils/dateUtil";
import { useStore } from "vuex";
import { useRouter } from 'vue-router';

export default defineComponent({
  components: { TableNavigation, SortButton },
  props: {
    stores: {
      type: Array as PropType<Store[]>,
      required: true,
    },
    sortBy: {
      type: Object as PropType<{
        column: "prefecture" | "chainName" | "storeType";
        desc: boolean;
      }>,
      required: true,
    },
  },
  emits: ["setSortBy"],
  setup(props, {emit}) {
    const store = useStore();
    const router = useRouter();
    const page = ref(1);
    const itemsPerPage = ref({ text: "30件", value: 30 });
    const STORE_TYPE = STORE_TYPE_ENUM;

    const storesSlicedByPage = computed((): Store[] => {
      return props.stores.slice(
        (page.value - 1) * itemsPerPage.value.value,
        page.value * itemsPerPage.value.value
      );
    });
    const pageFrom = computed((): number => {
      if (page.value === 1) return 1;
      return (page.value - 1) * itemsPerPage.value.value + 1;
    });
    const pageTo = computed((): number => {
      if (page.value * itemsPerPage.value.value >= props.stores.length)
        return props.stores.length;
      return page.value * itemsPerPage.value.value;
    });

    const setSortBy = (type: "prefecture" | "chainName" | "storeType") => {
      emit("setSortBy", { type: type });
    };
    const next = () => {
      if (page.value * itemsPerPage.value.value < props.stores.length) page.value++;
    };
    const previous = () => {
      if (page.value !== 1) page.value--;
    };
    const getPrefectureName = (id: number) => {      
      return (
        (store.state.prefectures as Prefecture[]).find(
          (prefecture) => prefecture.prefectureId === id
        )?.name ?? ""
      );
    };
    const clickAnalytics = (id: string) => {
      const start = convertHyphenDelimiter(
        store.state.startDate
      );
      const end = convertHyphenDelimiter(store.state.endDate);
      router.push({
        name: "ShopAnalyticsVisitor",
        params: { id: id },
        query: {
          period: `${start}_${end}`,
          unit: "date",
          sub_indicator: "none",
        },
      });
    };

    watch(() => props.stores, () => {
      page.value = 1;
    });    

    watch(itemsPerPage, () => {
      page.value = 1;
    });    
  
    return {
      page,
      itemsPerPage,
      STORE_TYPE,
      storesSlicedByPage,
      pageFrom,
      pageTo,
      setSortBy,
      next,
      previous,
      getPrefectureName,
      clickAnalytics,
    };
  },
});
</script>

<style lang="scss" scoped>
.favorite-detail-table {
  table {
    width: 100%;
    border-collapse: collapse;
    box-shadow: 0px 0px 3px #00000029;

    .header {
      background-color: #0e182e;
      color: #ffffff;
      height: 50px;
      font-size: 13px;

      th {
        span {
          display: flex;
          justify-content: center;
          align-items: center;

          .sort-icon {
            margin-left: 8px;
          }
        }
      }

      th:first-child {
        width: 140px;
      }
      th:nth-child(2) {
        width: 265px;
      }
      th:nth-child(3) {
        padding-left: 16px;
        text-align: left;
        min-width: 410px;
      }
      th:nth-child(4) {
        width: 180px;
      }
      th:nth-child(5) {
        width: 188px;
      }
      th:last-child {
        width: 146.5px;
      }
    }

    .body {
      td {
        padding: 25px 0;
        background-color: #ffffff;
        border: 1px solid #eeeeee;
      }
      td:first-child {
        font-size: 13px;
        color: #222222;
      }
      td:nth-child(2) {
        font-size: 14px;
        padding-left: 20px;
        padding-right: 20px;
        color: #222222;
      }
      td:nth-child(3) {
        padding: 0 14px;
        text-align: left;

        a {
          color: #4d99d0;
          font-size: 14px;
          font-weight: bold;
          cursor: pointer;
        }

        p {
          margin: 4px 0 0;
          color: #666666;
          font-size: 12px;
        }
      }
      td:nth-child(4) {
        font-size: 14px;
        color: #222222;

        .dot-red {
          color: #e66f73;
        }

        .dot-green {
          color: #87b8a1;
        }

        .dot-yellow {
          color: #cb9e56;
        }

        .dot-purple {
          color: #9278c3;
        }
      }
      td:last-child {
        width: 146.5px;
      }
    }

    .checkbox {
      height: 16px;
      width: 16px;
      margin-top: 4px;
      accent-color: #4d99d0;

      &::before {
        border: 1px solid #ccc !important;
      }
    }
  }
  .table-footer {
    display: flex;
    align-items: center;
    margin-top: 21.5px;

    .caption {
      font-size: 13px;
      color: #222222;
    }
  }
}

.mb-8px {
  margin-bottom: 8px;
}

.ml-8px {
  margin-left: 8px;
}

.ml-11px {
  margin-left: 11px;
}

.ml-15px {
  margin-left: 15px;
}
</style>
