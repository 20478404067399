<template>
  <div class="table-container">
    <div class="title">
      <b>ユーザー一覧</b>
      <div class="registered-user-table-action">
        <custom-button
          class="add-user"
          variant="primary"
          width="280px"
          height="46px"
          :disabled="users.length >= userLimit"
          @click="onClickAddButton()"
        >
          ＋ユーザーの新規追加
        </custom-button>
      </div>
    </div>
    <table
      class="registered-user-table"
      cellspacing="0"
    >
      <thead>
        <tr>
          <th class="column-no-top table-head">
            NO.
          </th>
          <th class="column-department table-head">
            部署名
          </th>
          <th class="column-name table-head">
            氏名
          </th>
          <th class="column-mail table-head">
            メールアドレス
          </th>
          <th class="column-action table-head" />
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="n in userLimit"
          :key="n"
        >
          <th class="column-no">
            {{ n }}
          </th>
          <td class="column-department">
            {{ users[n - 1] ? users[n - 1].department : "ー" }}
          </td>
          <td class="column-name">
            {{ users[n - 1] ? users[n - 1].name : "ー" }}
          </td>
          <td class="column-mail">
            {{ users[n - 1] ? users[n - 1].mail : "ー" }}
          </td>
          <td class="column-action">
            <custom-button
              class="delete-button"
              variant="inverse"
              width="80px"
              height="36px"
              :disabled="users.length <= 1 || n > users.length"
              @click="
                onClickDeleteButton(
                  users[n - 1] ? users[n - 1].userId : undefined
                )
              "
            >
              削除
            </custom-button>
            <custom-button
              class="edit-button"
              variant="primary"
              width="80px"
              height="36px"
              :disabled="n > users.length"
              @click="
                onClickEditButton(
                  users[n - 1] ? users[n - 1].userId : undefined
                )
              "
            >
              編集
            </custom-button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { UserResponse } from "@/features/UserSetting/interfaces/response";

export default defineComponent({
  name: "RegisteredUserTable",
  props: {
    users: {
      type: Array as PropType<UserResponse[]>, required: true,
    },
    userLimit: {
      type: Number, required: true,
    },
  },
  emits: ['addUser', 'delete'],
  methods: {
    onClickAddButton() {
      this.$emit("addUser");
    },
    onClickDeleteButton(id: string | undefined) {
      if (this.users.length <= 1 || typeof id === "undefined") return;
      this.$emit("delete", id);
    },
    onClickEditButton(id: string | undefined) {
      if (typeof id === "undefined") return;
      this.$router.push({ name: "UserEdit", params: { id: id } });
    },
  },
});
</script>

<style lang="scss" scoped>
.registered-user-table-action {
  text-align: center;
  .add-user {
    font-size: 15px;
  }
}
.table-container {
  height: 100%;
}
.title {
  text-align: left;
  font-size: 1.25rem!important;
  font-family: Roboto,sans-serif!important;
  font-weight: 500;
  letter-spacing: .0125em!important;
  color: #222222;

  display: flex;
  justify-content: space-between;
  align-items: center;
}
.registered-user-table {
  margin: 30px 0px;
  font-size: 14px;
}
table {
  width: 100%;
  table-layout: fixed;
  thead {
    tr {
      height: 56px;
    }
  }
  tr {
    th {
      background: #f5f5f5 0% 0% no-repeat padding-box;
      height: 56px;
      border: 1px solid #cccccc;
      border-top-width: 0;
      border-left-width: 0;
    }
    td {
      border: 1px solid #cccccc;
      border-top-width: 0;
      border-left-width: 0;
      height: 56px;
    }
    .table-head {
      border-top-width: 1px;
    }
    .column-no-top {
      min-width: 60px;
      width: 60px;
      border-left-width: 1px;
    }
    .column-no {
      min-width: 60px;
      width: 60px;
      border-left-width: 1px;
    }
    .column-department,
    .column-name,
    .column-mail {
      min-width: 260px;
      width: 100％;
    }
    .column-action {
      min-width: 224px;
      width: 224px;
      .delete-button {
        font-size: 14px;
        color: #666666;
        margin-right: 15px;
        padding: 4.33px 24px 4.67px 24px; // borderが付与されている分の調整
      }
      .edit-button {
        font-size: 14px;
        margin-right: 15px;
        padding: 4.33px 24px 4.67px 24px; // borderが付与されている分の調整
      }
    }
  }
}
</style>
