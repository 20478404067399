<template>
  <div
    style="
      display: flex;
      align-items: center;
      justify-content: center;
      width: 74px;
      height: 25px;
      border-radius: 100px;
    "
    :class="{
      'bg-blue': props.type === UPDATE_TYPE['WEEK'],
      'bg-green': props.type === UPDATE_TYPE['MONTH'],
    }"
  >
    <span
      style="
        margin-top: 2px;
        margin-bottom: 4px;
        width: 52px;
        height: 19px;
        color: #ffffff;
        font-size: 13px;
        font-weight: bold;
      "
    >
      {{ text }}
    </span>
  </div>
</template>

<script lang="ts">
const UPDATE_TYPE = {
  WEEK: "week",
  MONTH: "month",
} as const;
type UpdateType = typeof UPDATE_TYPE[keyof typeof UPDATE_TYPE];
</script>

<script setup lang="ts">
import { computed } from "vue";

interface Props {
  type: UpdateType;
}
const props = withDefaults(defineProps<Props>(), {
  type: UPDATE_TYPE["WEEK"]
})
const text = computed(() => {
  if (props.type === UPDATE_TYPE["WEEK"]) return "週次更新";
  if (props.type === UPDATE_TYPE["MONTH"]) return "月次更新";
  return "";
})
</script>

<style scoped>
.bg-blue {
  background: #78a7c9 !important;
}
.bg-green {
  background: #7caf97 !important;
}
</style>
