<template>
  <v-card
    height="'650px'"
    class="card"
  >
    <v-card-title class="pa-0 card-title d-flex align-center">
      <span>{{ title }}</span>
      <alert-tooltip
        v-if="hasAlert"
        class="ml-2"
        text="取得データボリュームが少なく統計上の信頼性が低い日が含まれています。該当日は参考値としてご参照ください。"
      />
      <v-spacer />
      <chart-description-tooltip
        menu-key="compare"
        sub-menu-key="trendVisitor"
        :chart-key="chartDescriptionChartKey"
        class="mr-27px"
      />
      <download-button
        label="CSVデータ"
        :disabled="!$store.state.selectedComparisonGroup"
        :get-file-id="getFileId"
        :csv-name="csvName"
      />
    </v-card-title>
    <v-container
      class="pa-0"
      fluid
    >
      <v-row
        class="my-0"
        dense
      >
        <v-col class="py-0">
          <div
            v-show="
              $store.getters.selectedComparisonGroupVisibleStores
                .length === 0
            "
            class="unselected_card"
          >
            店舗を選択するとチャートが表示されます。
          </div>
          <div
            v-show="
              $store.getters.selectedComparisonGroupVisibleStores.length >
                0
            "
          >
            <loadingImg
              v-if="chartLoading"
              :height="shareChartHeight"
            />
            <no-data-chart v-else-if="isNoData" />
            <div v-else-if="particle === computedParticleType.DAY">
              <share-chart
                :header="header"
                :charts="chartData"
              />
            </div>
            <div v-else>
              <!-- 平日チャート -->
              <share-chart
                :header="weekdayHeader"
                :charts="weekdayChartData"
                :color-styles="heatmapBlueStyles"
              />
              <!-- 土日祝日チャート -->
              <share-chart
                :header="holidayHeader"
                :charts="holidayChartData"
              />
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import ShareChart from "@/features/StoreCompare/trendVisitor/components/ShareChart.vue";
import NoDataChart from "@/features/ShopAnalytics/components/Common/noDataChart.vue";
import {
  ChartData,
  ChartDataValue,
} from "@/features/StoreCompare/trendVisitor/types";
import { DAYS, PARTICLE_TYPE } from "@/commons/enums";
import LoadingImg from "@/commons/components/loadingImg.vue";
import { AxiosResponse } from "axios";

export default defineComponent({
  components: {
    LoadingImg,
    ShareChart,
    NoDataChart,
  },
  props: {
    title: {
      type: String,
      default: undefined,
    },
    header: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    chartData: {
      type: Array as PropType<ChartData>,
      required: true,
    },
    chartLoading: {
      type: Boolean,
    },
    hasAlert: {
      type: Boolean,
    },
    particle: {
      type: Number as PropType<(typeof PARTICLE_TYPE)[keyof typeof PARTICLE_TYPE]>,
      default: undefined,
    },
    getFileId: {
      type: Function as PropType<() => Promise<AxiosResponse<any, any>>>,
      default: undefined,
    },
    csvName: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      heatmapBlueStyles: [
        "background: #B4D7F0",
        "background: #ABD1EC",
        "background: #98C1E0",
        "background: #85B2D5",
        "background: #72A3C9; color: #ffffff;",
        "background: #5F93BD; color: #ffffff;",
        "background: #4C84B1; color: #ffffff;",
        "background: #3A76A7; color: #ffffff;",
        "background: #26669A; color: #ffffff;",
        "background: #14588F; color: #ffffff;",
      ],
    };
  },
  computed: {
    isNoData(): boolean {
      return [...this.chartData]
        .flat()
        .filter((data) => typeof data !== "string")
        .every(
          (data) => {
            const dataValue = data as any as ChartDataValue;
            return dataValue.value === null ||
              dataValue.value === 0 ||
              isNaN(dataValue.value as number);
          }
        );
    },
    shareChartHeight(): string {
      return `${
        105 * this.$store.getters.selectedComparisonGroupVisibleStores.length
      }px`;
    },
    // チャート説明ツールチップの chartKey を曜日・時間で出し分けする算出プロパティ
    chartDescriptionChartKey(): string {
      if (this.particle === PARTICLE_TYPE.HOUR) return "timeOfDayShare";
      if (this.particle === PARTICLE_TYPE.DAY) return "dayOfWeekShare";
      return "";
    },
    computedParticleType() {
      return PARTICLE_TYPE;
    },
    weekdayHeader(): string[] {
      if (this.particle === PARTICLE_TYPE.HOUR) {
        const tmpHeader = JSON.parse(JSON.stringify(this.header));
        tmpHeader[0] = DAYS.WEEKDAYS.text;
        return tmpHeader;
      }
      return [];
    },
    holidayHeader(): string[] {
      if (this.particle === PARTICLE_TYPE.HOUR) {
        const tmpHeader = JSON.parse(JSON.stringify(this.header));
        tmpHeader[0] = DAYS.HOLIDAYS.text;
        return tmpHeader;
      }
      return [];
    },
    weekdayChartData(): ChartData {
      if (this.particle !== PARTICLE_TYPE.HOUR) {
        return [];
      }
      return this.chartData.map((e) =>
        // 奇数のデータは平日データ
        e.filter((_, i) => i === 0 || i % 2 !== 0)
      );
    },
    holidayChartData(): ChartData {
      if (this.particle !== PARTICLE_TYPE.HOUR) {
        return [];
      }
      return this.chartData.map(
        // 偶数のデータは土日祝日データ
        (e) => e.filter((_, i) => i % 2 === 0)
      );
    },
  },
});
</script>

<style lang="scss" scoped>
.mr-27px {
  margin-right: 27px;
}
</style>
