<template>
  <v-container
    class="py-0"
    fluid
  >
    <div
      v-if="hasAlert"
      class="overlay"
    >
      <div class="overlay-message">
        <p>取得データボリュームが少なく、</p>
        <p>統計上の信頼性の低いデータが含まれています。</p>
        <p>参考値としてご参照ください。</p>
        <v-btn
          variant="text"
          style="text-decoration: underline; color: white"
          @click="onClickVOverlay()"
        >
          閉じる
        </v-btn>
      </div>
    </div>
    <v-row>
      <v-col class="pr-0 left-chart_size">
        <GChart
          type="BarChart"
          :data="leftChartData"
          :options="leftOptions"
        />
      </v-col>
      <v-col class="pl-0 right-chart_size">
        <GChart
          type="BarChart"
          :data="rightChartData"
          :options="rightOptions"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { GChart } from "vue-google-charts";

export default defineComponent({
  name: "PyramidChart",
  components: {
    GChart,
  },
  props: {
    chartData: {
      type: Array as PropType<(string | number)[][]>,
      default: () => {
        return [];
      },
    },
    maxValue: {
      type: Number,
      default: undefined,
    },
    hasAlert: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  emits: ["clickApproveAlert"],
  computed: {
    leftChartData(): (string | number)[][] {
      return this.chartData.map((data) => {
        return [data[0], data[1], data[2]].flat();
      });
    },
    leftOptions(): any { // vue-google-chartsの型定義がいいかげんなのでanyで
      return {
        bars: "horizontal",
        chartArea: {
          top: 50,
          left: 50,
          right: 0,
          bottom: 50,
        },
        colors: ["4D99D0"],
        hAxis: {
          direction: -1,
          textStyle: {
            fontSize: 12,
          },
          maxValue: this.maxValue,
          minValue: 0,
        },
        height: 425,
        legend: { position: "top", alignment: "center" },
        tooltip: {
          isHtml: true,
        },
        vAxis: {
          textPosition: "none",
          textStyle: {
            fontSize: 12,
          },
          minValue: 0,
        },
        bar: {
          groupWidth: 27,
        },
      };
    },
    rightChartData(): (string | number)[][] {
      return this.chartData.map((data) => {
        return [data[0], data[3], data[4]].flat();
      });
    },
    rightOptions(): any { // vue-google-chartsの型定義がいいかげんなのでanyで
      return {
        bars: "horizontal",
        chartArea: {
          top: 50,
          left: 50,
          right: 50,
          bottom: 50,
        },
        colors: ["DE5A69"],
        hAxis: {
          textStyle: {
            fontSize: 12,
          },
          maxValue: this.maxValue,
          minValue: 0,
        },
        height: 425,
        legend: { position: "top", alignment: "center" },
        tooltip: {
          isHtml: true,
        },
        vAxis: {
          textStyle: {
            fontSize: 12,
          },
          minValue: 0,
        },
        bar: {
          groupWidth: 27,
        },
      };
    },
  },
  methods: {
    onClickVOverlay() {
      this.$emit("clickApproveAlert");
    },
  },
});
</script>

<style scoped>
.left-chart_size {
  max-width: 47%;
}
.right-chart_size {
  max-width: 53%;
}
.overlay {
  z-index: 3;
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
}
.overlay-message {
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}
.overlay-message p {
  margin-bottom: 11px;
}
</style>
