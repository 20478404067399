import moment from "moment";
import { WEEKS } from "../enums";

/**
 * 指定した日付の週初め(月曜日)を返す
 *
 * @param date - 日付
 * @returns 指定した日付の週初め(YYYY/MM/DD)
 */
export function getStartDateOfWeek(date: string): string {
  // 指定した日付の曜日を算出(日曜日:0, 月曜日:1, ...)
  const dayOfWeekNum = moment(date).day();
  // 日曜日の場合、1週間前の月曜日を返す
  if (dayOfWeekNum === 0)
    return moment(date).subtract(6, "days").format("YYYY/MM/DD");
  // 日曜日以外の場合、その週の月曜日を返す
  return moment(date).startOf("week").add(1, "day").format("YYYY/MM/DD");
}

/**
 * 指定した日付の週終わりを返す
 *
 * @param date - 日付
 * @returns 指定した日付の週終わり(YYYY/MM/DD)
 */
export function getEndDateOfWeek(date: string): string {
  // 指定した日付の曜日を算出(日曜日:0, 月曜日:1, ...)
  const dayOfWeekNum = moment(date).day();
  // 日曜日の場合、その日付をそのまま返す
  if (dayOfWeekNum === 0) return date;
  // 日曜日以外の場合、その週の月曜日を返す
  return moment(date).endOf("week").add(1, "day").format("YYYY/MM/DD");
}

/**
 * 指定した日付の月初を返す
 *
 * @param date - 日付
 * @returns 指定した日付の月初(YYYY/MM/DD)
 */
export function getStartDateOfMonth(date: string): string {
  return moment(date).startOf("month").format("YYYY/MM/DD");
}

/**
 * 指定した日付の月末を返す
 *
 * @param date - 日付
 * @returns 指定した日付の月末(YYYY/MM/DD)
 */
export function getEndDateOfMonth(date: string): string {
  return moment(date).endOf("month").format("YYYY/MM/DD");
}

export function getStartMonthOf13Month(): string {
  if (moment().date() < 8) {
    return moment().subtract(14, "month").startOf("month").format("YYYY/MM/DD");
  } else {
    return moment().subtract(13, "month").startOf("month").format("YYYY/MM/DD");
  }
}
export function getEndMonthOf13Month(): string {
  if (moment().date() < 8) {
    return moment().subtract(2, "month").endOf("month").format("YYYY/MM/DD");
  } else {
    return moment().subtract(1, "month").endOf("month").format("YYYY/MM/DD");
  }
}

/**
 * 日付をハイフン区切りに変換する
 *
 * @param date - 日付
 * @returns ハイフン区切りの日付(YYYY-mm-DD)
 */
export function convertHyphenDelimiter(date: string | undefined) {
  if (!date) return ''
  return moment(date).format("YYYY-MM-DD");
}

/**
 * 日付をスラッシュ区切りに変換する
 *
 * @param date - 日付
 * @returns スラッシュ区切りの日付(YYYY/mm/DD)
 */
export function convertSlashDelimiter(date: string | undefined) {
  if (!date) return "";
  return moment(date).format("YYYY/MM/DD");
}

/**
 * 日付を月ごとのスラッシュ区切りに変換する
 *
 * @param date - 日付
 * @returns 月ごとのスラッシュ区切りの日付(YYYY/mm)
 */
export function convertMonthlySlashDelimiter(date: string) {
  return moment(date).format("YYYY/MM");
}
/**
 * 日付を月ごとのハイフン区切りに変換する
 *
 * @param date - 日付
 * @returns 月ごとのスラッシュ区切りの日付(YYYY-mm)
 */
export function convertMonthlyHyphenDelimiter(date: string) {
  return moment(date).format("YYYY-MM");
}

/**
 * 日付を日時のスラッシュ区切りに変換する
 *
 * @param date - 日付
 * @returns スラッシュ区切りの日時(YYYY/mm/DD HH:mm)
 */
export function convertDateAndTimeSlashDelimiter(date: string) {
  return moment(date).format("YYYY/MM/DD HH:mm");
}

/**
 * 月曜始まりのカレンダーの週の初めを返す
 * @param date 日付
 * @returns 月曜始まりカレンダーの週の初め
 */
export function getStartOfWeek(date: string) {
  const d = moment(date);
  const dayOfWeekNum = d.day();

  if (dayOfWeekNum === WEEKS.SUNDAY["value"])
    return d.subtract(6, "day").format("YYYY-MM-DD");
  else return d.startOf("week").add(1, "day").format("YYYY-MM-DD");
}
