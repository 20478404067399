import { defineRule, configure } from 'vee-validate';

import ja from '@vee-validate/i18n/dist/locale/ja.json';
import { required, email } from '@vee-validate/rules';
import { localize, setLocale } from '@vee-validate/i18n';

configure({
  generateMessage: localize({
    ja,
  }),
});

setLocale('ja');

// preset rules
defineRule("required", required);
defineRule("email", email);

// custom rules
defineRule("passwordSize", (value: string) => {
    return value.length >= 8 && value.length <= 32;
});
