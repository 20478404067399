<template>
  <v-container
    class="pa-0"
    fluid
  >
    <GChart
      type="AreaChart"
      :data="chartData"
      :options="options"
    />
    <v-row
      class="my-0"
      dense
    >
      <v-col>
        <div
          class="my-0"
          style="
            display: flex;
            align-items: center;
            font-size: 12px;
            color: #666;
          "
        >
          <chart-legend :legends="legends" />
          <v-spacer />
          <alert-caption
            v-if="isAlert"
            variants="circle"
          />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";
import { GChart } from "vue-google-charts";
import chartLegend from "@/features/ShopAnalytics/components/Visitor/chartLegend.vue";
import { COLOR } from "@/commons/enums";

export default defineComponent({
  name: "LineChart",
  components: {
    GChart,
    chartLegend,
  },
  props: {
    chartData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    subType: {
      type: Number,
      default: undefined,
    },
    isAlert: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    legends: {
      type: Array as PropType<{ text: string; color: string }[]>,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      defaultChartColors: [COLOR.RED],
      genderChartColors: [COLOR.BLUE, COLOR.RED],
      ageChartColors: [
        COLOR.RED,
        COLOR.BLUE,
        COLOR.GREEN,
        COLOR.ORANGE,
        COLOR.PURPLE,
        COLOR.BROWN,
      ],
    };
  },
  computed: {
    options() {
      let lineWidth = 3;
      let pointSize = 6;
      if (this.chartData.length > 31) {
        lineWidth = 2;
        pointSize = 0;
      }

      let colorArray = [];
      if (this.subType === 1) {
        colorArray = (this as any).genderChartColors;
      } else if (this.subType === 2) {
        colorArray = (this as any).ageChartColors;
      } else colorArray = (this as any).defaultChartColors;

      return {
        areaOpacity: 0.1,
        chartArea: {
          top: 25,
          left: 60,
          right: 10,
          bottom: 90,
        },
        colors: colorArray,
        hAxis: {
          textStyle: {
            fontSize: 12,
          },
          slantedText: true,
          slantedTextAngle: 270,
        },
        height: 550,
        isStacked: true,
        legend: {
          position: "none",
        },
        lineWidth: lineWidth,
        pointSize: pointSize,
        tooltip: {
          isHtml: true,
        },
        vAxis: {
          textStyle: {
            fontSize: 12,
          },
          baselineColor: "#ccc",
          gridlines: {
            color: "#ddd",
          },
          minValue: 0,
        },
      };
    },
  },
});
</script>

<style scoped>
::-webkit-scrollbar {
  -webkit-appearance: none;
  height: 7px;
  width: 7px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
.circle {
  display: inline-block;
  margin-right: 6px;
  width: 10px;
  height: 10px;
  border: 2px solid #666;
  border-radius: 50%;
}
</style>
