<template>
  <div class="map-information-box">
    {{ props.text ?? '---' }}
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(defineProps<{ text?: string }>(), { text: undefined })
</script>

<style scoped>
.map-information-box {
  display: flex;
  align-items: center;
  padding: 0 13px;
  width: 300px;
  height: 36px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 4px;
  opacity: 1;
  text-align: left;
  font: normal normal normal 13px/24px Noto Sans CJK JP;
  letter-spacing: 0px;
  color: #222222;
}
</style>
