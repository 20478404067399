<template>
  <v-container class="pa-0" fluid>
    <v-row v-for="chart in chartData" :key="chart.chain.id" style="align-items: center" no-gutters>
      <v-col :cols="1">
        <div style="font-size: 13px">
          {{ chart.chain.name }}
        </div>
      </v-col>
      <v-col :cols="10">
        <age-gender-bar-chart
          :age-male-chart-data="ageMaleChartData(chart.graph)"
          :age-female-chart-data="ageFemaleChartData(chart.graph)"
        />
      </v-col>
      <v-col :cols="1">
        <div style="font-size: 13px">
          男性：{{
            !isNaN(chart.maleRatio) ? (Math.round(chart.maleRatio * 1000) / 10).toFixed(1) : '--'
          }}%<br />女性：{{
            !isNaN(chart.femaleRatio)
              ? (Math.round(chart.femaleRatio * 1000) / 10).toFixed(1)
              : '--'
          }}%
        </div>
      </v-col>
    </v-row>
    <v-row class="mb-0 mt-4" dense>
      <v-col>
        <div class="my-0" style="display: flex; align-items: center; font-size: 12px; color: #666">
          <chart-legend :legends="legends" />
          <v-spacer />
          <!-- TODO: アラート時のみ表示するよう修正を加える -->
          <alert-caption v-if="hasAlert" variants="square" />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue'
import AgeGenderBarChart from '@/features/StoreCompare/persona/components/AgeGenderBarChart.vue'
import ChartLegend from '@/features/ShopAnalytics/components/Visitor/chartLegend.vue'
import { AgeGendersChartData } from '../types'

export default defineComponent({
  name: 'AgeGenderChart',
  components: {
    AgeGenderBarChart,
    ChartLegend
  },
  props: {
    chartData: {
      type: Array as PropType<AgeGendersChartData>,
      default: () => []
    },
    hasAlert: {
      type: Boolean
    }
  },
  data() {
    return {
      legends: [
        { text: '男性', color: '#4D99D0' },
        { text: '女性', color: '#DE5A69' }
      ]
    }
  },
  methods: {
    ageMaleChartData(graph: (string | number | object | undefined)[]) {
      return [graph[0], graph[1]]
    },
    ageFemaleChartData(graph: (string | number | object | undefined)[]) {
      return [graph[0], graph[2]]
    }
  }
})
</script>

<style scoped>
::-webkit-scrollbar {
  -webkit-appearance: none;
  height: 7px;
  width: 7px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
</style>
