<template>
  <div class="no-list">
    登録がありません
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "NoList",
});
</script>

<style lang="scss" scoped>
.no-list {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 600px;
  width: 100%;
  background-color: #eeeeee;
  color: #000000;
  font-size: 15px;
  font-weight: bold;
}
</style>
