<template>
  <div
    style="
      display: flex;
      align-items: center;
      justify-content: center;
      width: 74px;
      height: 25px;
      border-radius: 100px;
    "
    :class="{
      'bg-blue': type === UPDATE_TYPE['WEEK'],
      'bg-green': type === UPDATE_TYPE['MONTH'],
    }"
  >
    <span
      style="
        margin-top: 2px;
        margin-bottom: 4px;
        width: 52px;
        height: 19px;
        color: #ffffff;
        font-size: 13px;
        font-weight: bold;
      "
    >
      {{ text }}
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

const UPDATE_TYPE = {
  WEEK: "week",
  MONTH: "month",
} as const;

export default defineComponent({
  props: {
    type: {
      type: String as PropType<(typeof UPDATE_TYPE)[keyof typeof UPDATE_TYPE]>,
      default: UPDATE_TYPE["WEEK"],
    },
  },
  data() {
    return {
      UPDATE_TYPE: UPDATE_TYPE,
    };
  },
  computed: {
    text() {
      if (this.type === UPDATE_TYPE["WEEK"]) return "週次更新";
      if (this.type === UPDATE_TYPE["MONTH"]) return "月次更新";
      return "";
    },
  },
});
</script>

<style scoped>
.bg-blue {
  background: #78a7c9 !important;
}
.bg-green {
  background: #7caf97 !important;
}
</style>
