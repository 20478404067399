<template>
  <div class="compare-list-table">
    <table>
      <tr class="header">
        <th>
          <input
            v-model="all"
            type="checkbox"
            class="checkbox"
            @change="clickAll"
          >
        </th>
        <th>
          <span>
            条件名
            <SortButton
              class="sort-icon"
              :show-status="{
                up: sortBy.column !== 'name' || !sortBy.desc,
                down: sortBy.column !== 'name' || sortBy.desc,
              }"
              @sort-action="setSortBy('name')"
            />
          </span>
        </th>
        <th>店舗数</th>
        <th>最終更新者</th>
        <th>
          <span>
            更新日時
            <SortButton
              class="sort-icon"
              :show-status="{
                up: sortBy.column !== 'updatedAt' || !sortBy.desc,
                down: sortBy.column !== 'updatedAt' || sortBy.desc,
              }"
              @sort-action="setSortBy('updatedAt')"
            />
          </span>
        </th>
        <th>更新内容</th>
        <th />
      </tr>
      <tr
        v-for="favorite in favoritesSlicedByPage"
        :key="favorite.id"
        class="body"
        :class="{ selected: isSelected(favorite.id!) }"
      >
        <td>
          <input
            :id="favorite.id"
            v-model="selected"
            type="checkbox"
            :value="favorite.id"
            class="checkbox"
          >
        </td>
        <td>
          <a @click="clickDetail(favorite.id!)">{{ favorite.name }}</a>
        </td>
        <td>
          <LoadingImg
            v-if="storeLoading"
            direct-height="75px;"
          />
          <span v-else>
            {{ filterStoresByFavoriteLength(favorite) }}
          </span>
        </td>
        <td>{{ favorite.updated_by }}</td>
        <td>{{ convertDateAndTimeSlashDelimiter(favorite.updated_at!) }}</td>
        <td>{{ favorite.update_note }}</td>
        <td>
          <CustomButton
            variant="primary"
            size="sm"
            width="108px"
            height="30px"
            class="mb-8px"
            @click="clickDetail(favorite.id!)"
          >
            詳細
          </CustomButton>
          <CustomButton
            variant="inverse"
            size="sm"
            height="30px"
            @click="clickEdit(favorite)"
          >
            編集する
          </CustomButton>
        </td>
      </tr>
    </table>
    <div class="table-footer">
      <span class="caption">チェックした条件を</span>
      <CustomButton
        variant="inverse"
        size="xs"
        class="ml-11px"
        :disabled="selected.length === 0"
        @click="dialog = true"
      >
        削除する
      </CustomButton>
      <v-spacer />
      <span
        v-if="favorites.length !== 0"
        class="caption"
      >
        {{ pageFrom }}-{{ pageTo }} 件 / {{ favorites.length }}件
      </span>
      <span
        v-else
        class="caption"
      > 0件 </span>
      <CustomSelector
        v-model="itemsPerPage"
        :items="[
          { text: '30件', value: 30 },
          { text: '50件', value: 50 },
        ]"
        size="xs"
        width="75px"
        height="30px"
        class="ml-8px"
      />
      <TableNavigation
        :click-next="next"
        :click-previous="previous"
        :next-disabled="page * itemsPerPage.value >= favorites.length"
        :previous-disabled="page === 1"
        class="ml-15px"
      />
    </div>
    <DeleteDialog
      v-if="selected.length >= 1"
      v-model="dialog"
      type="favorite"
      :targets="selectedNames"
      :loading="loading"
      @click-delete="clickDelete"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, computed, ref, watch } from "vue";
import TableNavigation from "@/features/Dashboard/components/TableNavigation.vue";
import DeleteDialog from "@/features/Dashboard/components/DeleteDialog.vue";
import SortButton from "@/commons/components/Elements/SortButton.vue";
import LoadingImg from "@/commons/components/loadingImg.vue";
import { Favorite } from "@/features/Dashboard/types";
import { convertDateAndTimeSlashDelimiter as convertDateAndTimeSlashDelimiterUtil } from "@/commons/utils/dateUtil";
import { filterStoresByFavorite } from "@/features/Dashboard/utils";
import { deleteStoresFavorites } from "@/features/Dashboard/axios";
import * as notify from "@/plugins/notification";
import { useRouter } from 'vue-router';
import { useStore } from "vuex";

export default defineComponent({
  components: { TableNavigation, DeleteDialog, SortButton, LoadingImg },
  props: {
    favorites: {
      type: Array as PropType<Favorite[]>,
      required: true,
    },
    sortBy: {
      type: Object as PropType<{
        column: "name" | "updatedAt";
        desc: boolean;
      }>,
      required: true,
    },
    storeLoading: {
      type: Boolean,
    },
  },
  emits: ["fetchData", "setSortBy"],
  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();
    const loading = ref(false);
    const page = ref(1);
    const itemsPerPage = ref({ text: "30件", value: 30 });
    const all = ref(false);
    const selected = ref([] as string[]);
    const dialog = ref(false);

    const selectedNames = computed((): string[] => {
      return selected.value
        .map((id) => (props.favorites.find((f) => f.id === id) as Favorite).name)
        .filter((name) => name !== undefined) as string[];
    });
    const favoritesSlicedByPage = computed((): Favorite[] => {
      return props.favorites.slice(
        (page.value - 1) * itemsPerPage.value.value,
        page.value * itemsPerPage.value.value,
      );
    });
    const pageFrom = computed(() => {
      if (page.value === 1) return 1;
      return (page.value - 1) * itemsPerPage.value.value + 1;
    });
    const pageTo = computed(() => {
      if (page.value * itemsPerPage.value.value >= props.favorites.length)
        return props.favorites.length;
      return page.value * itemsPerPage.value.value;
    });
    const clickDetail = (id: string) => {
      router.push({
        name: "FavoriteDetail",
        params: { id: id },
      });
    };
    const clickEdit = (favorite: Favorite ) => {
      router.push({
        name: "FavoriteEdit",
        // NOTE: router 経由で props にデータを渡す場合、params は string しか許容されないため any でキャスト
        params: { id: favorite.id as any, favorite: favorite as any },
      });
    };
    const clickDelete = async() => {
      loading.value = true;
      await deleteStoresFavorites({
        favoritesIds: selected.value,
      })
        .then(() => {
          emit("fetchData");
        })
        .catch(() => {
          notify.notifyErrorMessage("お気に入り条件が削除できませんでした。");
        });
      loading.value = false;
    };
    const filterStoresByFavoriteLength = (favorite: Favorite) => {
      if (favorite.store_count !== undefined) return favorite.store_count;
      const filtered = filterStoresByFavorite({
        stores: store.state.stores,
        favorite: favorite,
      });
      favorite.store_count = filtered.length;
      return filtered.length;
    };
    const convertDateAndTimeSlashDelimiter = (date: string) => {
      return convertDateAndTimeSlashDelimiterUtil(date);
    };
    const setSortBy = (type: "name" | "updatedAt") => {
      emit("setSortBy", { type: type });
    };
    const clickAll = () => {
      if (all.value)
        selected.value = favoritesSlicedByPage.value.map(
          (group) => group.id as string
        );
      else selected.value.splice(0);
    };
    const isSelected = (id: string) => {
      return selected.value.includes(id);
    };
    const next = () => {
      if (page.value * itemsPerPage.value.value < props.favorites.length)
        page.value++;
    };
    const previous = () => {
      if (page.value !== 1) page.value--;
    };

    watch(() => props.favorites, () => {
      page.value = 1;
    });

    watch(itemsPerPage, () => {
      page.value = 1;
    });
    
    return {
      loading,
      page,
      itemsPerPage,
      all,
      selected,
      dialog,
      selectedNames,
      favoritesSlicedByPage,
      pageFrom,
      pageTo,
      clickDetail,
      clickEdit,
      clickDelete,
      filterStoresByFavoriteLength,
      convertDateAndTimeSlashDelimiter,
      setSortBy,
      clickAll,
      isSelected,
      next,
      previous,
    };
  },
});
</script>

<style lang="scss" scoped>
.compare-list-table {
  table {
    width: 100%;
    border-collapse: collapse;
    box-shadow: 0px 0px 3px #00000029;

    .header {
      background-color: #0e182e;
      color: #ffffff;
      height: 50px;
      font-size: 13px;

      th {
        span {
          display: flex;
          justify-content: center;
          align-items: center;

          .sort-icon {
            margin-left: 8px;
          }
        }
      }

      th:first-child {
        width: 49.5px;
      }
      th:nth-child(2) {
        padding: 0 14px;
        text-align: left;
        min-width: 505px;
        span {
          justify-content: flex-start;
        }
      }
      th:nth-child(3) {
        width: 100px;
      }
      th:nth-child(4) {
        width: 121px;
      }
      th:nth-child(5) {
        width: 100px;
      }
      th:nth-child(6) {
        width: 188px;
      }
      th:last-child {
        width: 146.5px;
      }
    }

    .body {
      background-color: #ffffff;
      td {
        padding: 25px 0;
        border: 1px solid #dddddd;
      }
      td:first-child {
        width: 49.5px;
      }
      td:nth-child(2) {
        padding: 0 14px;
        text-align: left;

        a {
          color: #4d99d0;
          font-size: 16px;
          font-weight: bold;
          cursor: pointer;
        }
      }
      td:nth-child(3) {
        font-size: 15px;
        font-weight: bold;
        color: #222222;
      }
      td:nth-child(4) {
        padding: 0 10.5px;
        font-size: 13px;
        color: #666666;
      }
      td:nth-child(5) {
        padding: 0 14px;
        font-size: 13px;
        text-align: left;
        color: #666666;
      }
      td:nth-child(6) {
        padding: 0 14px;
        font-size: 12px;
        text-align: left;
        color: #666666;
      }
      td:last-child {
        width: 146.5px;
      }
    }

    .selected {
      background-color: #f4fbff !important;
    }

    .checkbox {
      height: 16px;
      width: 16px;
      margin-top: 4px;
      accent-color: #4d99d0;

      &::before {
        border: 1px solid #ccc !important;
      }
    }
  }
  .table-footer {
    display: flex;
    align-items: center;
    margin-top: 21.5px;

    .caption {      
      font-size: .75rem!important;
      color: #222222;
      letter-spacing: .0333333333em!important;
      line-height: 1.25rem!important;
    }
  }
}

.mb-8px {
  margin-bottom: 8px;
}

.ml-8px {
  margin-left: 8px;
}

.ml-11px {
  margin-left: 11px;
}

.ml-15px {
  margin-left: 15px;
}
</style>
