import { Store } from '@/commons/interfaces/responses/store'
import { BehavioralDNA } from '@/features/StoreCompare/persona/types'

export interface Stores {
  Stores: Array<Store>
}

export interface VisitCountGraph {
  visitCounts: {
    chartType: number
    chartItems: VisitCountGraphItem[]
  }
}

export interface VisitCountGraphItem {
  granularity: string
  subIndex?: number | null // 性別: 1=男性, 2=女性 時間帯別: 1=平日, 2=土日祝日
  value: number
  isAlert?: boolean
}

export interface VisitCountTable {
  visitCounts: VisitorTableItem[]
}
export interface VisitCountComparisonTable {
  visitCounts: {
    period: string
    total: number
    male: number
    female: number
    teen: number
    twenties: number
    thirties: number
    forties: number
    fifties: number
    sixties: number
  }[]
}
export interface VisitorTableItem {
  granularity: string
  total: number
  male: number
  female: number
  teen: number
  twenties: number
  thirties: number
  forties: number
  fifties: number
  sixties: number
}
export interface VisitorComparisonTableItem {
  period: string
  total: number
  male: number
  female: number
  teen: number
  twenties: number
  thirties: number
  forties: number
  fifties: number
  sixties: number
}

export interface AgeGenderResponse {
  visitCounts: ageGender[]
}

export interface ageGender {
  age: string
  chartItems: ageGenderChartItem[]
}

export interface ageGenderChartItem {
  gender: 'male' | 'female'
  value: number
  isAlert: boolean
}

export interface FrequencyResponse {
  visitCounts: {
    date: string
    oncePerMonth: number
    twicePerMonth: number
    threeTimesPerMonth: number
    fourTimesPerMonth: number
    eightTimesPerMonth: number
    isAlert: boolean
  }[]
}

export interface EngagementResponse {
  visitCounts: {
    date: string
    engagementNew: number
    engagementContinue: number
    engagementInactive: number
    engagementLeaveThisMonth: number
    engagementLeaveLastMonth: number
    isAlert: boolean
  }[]
}

export interface CohortResponse {
  visitCounts: {
    yearMonth: string
    visitorCohort: {
      elapsedMonth: number
      visitor: number | null
    }[]
    isAlert: boolean
  }[]
}

export interface BehavioralDnaResponse {
  // NOTE behavioralDnatかぶるが、この型定義は、src/features/StoreCompare/persona/types/index.ts と同じ
  visitCounts: BehavioralDNA[]
}

export const isBehavioralDnaResponse = (
  maybeBehavioralDnaResponse: any
): maybeBehavioralDnaResponse is BehavioralDnaResponse => {
  try {
    return (
      Array.isArray(maybeBehavioralDnaResponse.visitCounts) &&
      maybeBehavioralDnaResponse.visitCounts.every(
        (e: { secondCategory: { deviationValue: { total: number } } }) =>
          typeof e.secondCategory.deviationValue.total === 'number'
      )
    )
  } catch (error) {
    return false
  }
}

export interface behavioralDna {
  firstCategory: {
    name: string
  }
  secondCategory: {
    name: string
    deviationValue: behavioralDnaValue
  }
  thirdCategory: thirdCategory[]
}

export interface thirdCategory {
  name: string
  deviationValue: behavioralDnaValue
}

export interface behavioralDnaValue {
  total: number | null
  totalAlert: boolean | null
  male: number | null
  maleAlert: boolean | null
  female: number | null
  femaleAlert: boolean | null
}

export interface ComparisonBehavioralDnaResponse {
  visitCounts: comparisonBehavioralDna[]
}

export interface comparisonBehavioralDna {
  firstCategory: {
    name: string
  }
  secondCategory: {
    name: string
    deviationValue: comparisonBehavioralDnaObject
  }
  thirdCategory: comparisonThirdCategory[]
}

export interface comparisonBehavioralDnaObject {
  total: [comparisonBehavioralDnaValue, comparisonBehavioralDnaValue]
  male: [comparisonBehavioralDnaValue, comparisonBehavioralDnaValue]
  female: [comparisonBehavioralDnaValue, comparisonBehavioralDnaValue]
}

export interface comparisonBehavioralDnaValue {
  period: string
  value: number | null
  isAlert: boolean | null
}

export interface comparisonThirdCategory {
  name: string
  deviationValue: comparisonBehavioralDnaObject
}

export const isComparisonThirdCategoryArray = (
  maybeComparisonThirdCategoryArray: unknown
): maybeComparisonThirdCategoryArray is comparisonThirdCategory[] => {
  try {
    return (
      Array.isArray(maybeComparisonThirdCategoryArray) &&
      maybeComparisonThirdCategoryArray.every(
        (e: { deviationValue: { total: [{ value: 'number' }] } }) =>
          Object.values(e.deviationValue.total).every((k) => typeof k.value === 'number')
      )
    )
  } catch (error) {
    return false
  }
}

export interface bizAreaShareValue {
  areaName: string
  lat: number
  long: number
  visitCount: number
}
export interface bizAreaMapValue {
  areaName: string
  lat: number
  long: number
  geoData: string
}

export interface DistanceRankingResponse {
  areas: DistanceRankingValue[]
  isAlert: boolean | null
}
export interface DistanceRankingValue {
  distanceRange: string
  visitRate: number
}

export interface BizAreaAnalysisResponse {
  areas: BizAreaValue[]
  isAlert: boolean | null
}
export interface BizAreaValue {
  areaId: string
  areaName: string
  visitRatio: number
}

export interface BizAreaAnalysisMapResponse {
  features: MapBoxFeatures[]
}
export interface MapBoxFeatures {
  type: string
  properties: {
    visitRatio: number
    areaId: string
    areaName: string
    latitude: number
    longitude: number
  }
  geometry: {
    type: string
    coordinates: number[][][] | number[][][][]
  }
}

export interface WeekHourHeatMapResponse {
  heatmap: WeekHourHeatMapItem[]
}

export const WEEK = {
  SUNDAY: 'sunday',
  MONDAY: 'monday',
  TUESDAY: 'tuesday',
  WEDNESDAY: 'wednesday',
  THURSDAY: 'thursday',
  FRIDAY: 'friday',
  SATURDAY: 'saturday'
} as const

export interface WeekHourHeatMapItem {
  timeSlot: string
  monday: WeekHourHeatMapItemData
  tuesday: WeekHourHeatMapItemData
  wednesday: WeekHourHeatMapItemData
  thursday: WeekHourHeatMapItemData
  friday: WeekHourHeatMapItemData
  saturday: WeekHourHeatMapItemData
  sunday: WeekHourHeatMapItemData
  alerts: (typeof WEEK)[keyof typeof WEEK][]
}

export interface WeekHourHeatMapItemData {
  averageCount: number
  averageRatio: number
}

export interface CombinationStoreResponse {
  combinationStores: CombinationStore[],
  isAlert: boolean
}

export interface CombinationStore {
  rank: number
  storeId: string
  name: string
  storeType: number
  address: string
  longitude: number
  latitude: number
  combinationRatio: number
  distance: number
}
