<template>
  <div class="view-contaienr">
    <div
      class="navigation"
      :class="{ inactive: pageIndex === 1 }"
      @click="backPage()"
    >
      BACK
    </div>
    <div class="pagination">
      <div
        class="pagination-circle"
        :class="{
          'pagination-circle-active': pageIndex === 1,
          'single-pagenation': size === 1,
        }"
        @click="$emit('update:pageIndex', 1)"
      >
        1
      </div>
      <div
        v-show="size > 5 && pageIndex > 3"
        class="pagination-ellipsis"
      >
        …
      </div>
      <div
        v-for="n in middleSize"
        :key="n"
        class="pagination-circle"
        :class="{ 'pagination-circle-active': pageIndex === n }"
        @click="$emit('update:pageIndex', n)"
      >
        {{ n }}
      </div>
      <div
        v-show="size > 5 && pageIndex < size - 2"
        class="pagination-ellipsis"
      >
        …
      </div>
      <div
        v-show="size > 1"
        class="pagination-circle"
        :class="{ 'pagination-circle-active': pageIndex === size }"
        @click="$emit('update:pageIndex', size)"
      >
        {{ size }}
      </div>
    </div>
    <div
      class="navigation"
      :class="{ inactive: pageIndex === size }"
      @click="nextPage()"
    >
      NEXT
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "CirclePagination",
  props: {
    size: {
      type: Number,
      default: 0,
    },
    pageIndex: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    middleSize() {
      const me = this as any;
      if (me.size <= 2) return [];
      if (me.size === 3) return [2];
      if (me.size === 4) return [2, 3];
      if (me.size === 5 || (me.size >= 6 && me.pageIndex <= 2))
        return [2, 3, 4];
      if (me.pageIndex === me.size - 1)
        return [me.pageIndex - 2, me.pageIndex - 1, me.pageIndex];
      if (me.pageIndex === me.size)
        return [me.pageIndex - 3, me.pageIndex - 2, me.pageIndex - 1];
      else return [me.pageIndex - 1, me.pageIndex, me.pageIndex + 1];
    },
  },
  methods: {
    backPage() {
      if (this.pageIndex === 1) return;
      this.$emit("update:pageIndex", this.pageIndex - 1);
    },
    nextPage() {
      if (this.pageIndex === this.size) return;
      this.$emit("update:pageIndex", this.pageIndex + 1);
    },
  },
});
</script>

<style lang="scss" scoped>
.view-contaienr {
  display: flex;
  justify-content: space-between;

  .navigation {
    font: normal normal normal 13px/36px Noto Sans JP;
    letter-spacing: 0px;
    color: #4d99d0;
    cursor: pointer;
  }
  .inactive {
    color: #999999;
    pointer-events: none;
  }
  .pagination {
    display: flex;

    .pagination-circle {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 36px;
      height: 36px;
      border: 1px solid #dddddd;
      border-radius: 50%;
      background: #ffffff 0% 0% no-repeat padding-box;
      font: normal normal bold 13px/36px Noto Sans JP;
      cursor: pointer;
      margin: 0px 5px;
    }
    .pagination-circle:first-of-type {
      margin-left: 0px;
    }
    .pagination-circle:last-of-type {
      margin-right: 0px;
    }
    .single-pagenation {
      margin: 0px;
    }
    .pagination-circle-active {
      background: #0e182e 0% 0% no-repeat padding-box;
      color: #ffffff;
      cursor: default;
      border: none;
    }
    .pagination-ellipsis {
      width: 18px;
      height: 24px;
      margin: 0px 8px;
      text-align: center;
      font: normal normal normal 16px/36px Noto Sans JP;
      letter-spacing: 0.16px;
      color: #000000;
    }
  }
}
</style>
