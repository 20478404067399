<template>
  <v-container class="pa-0" fluid>
    <v-row class="title-container" dense no-gutters>
      <v-col style="display: flex; align-items: center">
        <h1 class="title-text">商圏分析</h1>
        <UpdateBadge type="month" style="margin-left: 15px" />
      </v-col>
      <v-spacer />
      <MonthlyDatePicker
        :start-date="startMonth"
        :end-date="endMonth"
        @update-period="
          ({ startMonth, endMonth }) => {
            setPeriodParams({ startMonth, endMonth })
          }
        "
      />
    </v-row>
    <v-row dense no-gutters class="card-container">
      <v-col>
        <MultiChainSelector
          :initial-chain-ids="chainIds"
          :initial-prefecture-ids="prefectureIds.map((id) => Number(id))"
          :handle-update="
            (chains, prefectureIds) =>
              setParams({
                chainIds: chains.map((chain) => chain.id),
                prefectureIds: prefectureIds.map((id) => id.toString())
              })
          "
        />
      </v-col>
    </v-row>
    <v-row dense no-gutters class="card-container mb-0">
      <v-col class="map-column">
        <v-card class="card" tile>
          <BizAreaMapContents
            :base-chain-id="baseChainId"
            :selected-chain-ids="chainIds"
            :selected-prefecture-ids="prefectureIds.map((id) => Number(id))"
            :initial-selected-granularity="granularity"
            :on-alert-area-names-change="(newNames) => (alertAreaNames = newNames)"
            :on-granularity-change="(newGranularity) => (granularity = newGranularity)"
          />
          <div class="divider" />
          <BizAreaTableContents
            :selected-chain-ids="chainIds"
            :selected-prefecture-ids="prefectureIds.map((id) => Number(id))"
            :granularity="granularity"
            :alert-area-names="alertAreaNames"
            :initial-base-chain-id="baseChainId"
            :on-base-chain-id-change="(id) => (baseChainId = id)"
          />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import MultiChainSelector from '@/commons/components/ChainSelector/MultiChainSelector.vue'
import MonthlyDatePicker from '@/commons/components/DatePicker/MonthlyDatePicker.vue'
import UpdateBadge from '@/commons/components/Elements/UpdateBadge.vue'
import useChainsQueryParams from '@/commons/hooks/use-chains-query-params'
import usePeriodQueryParams from '@/commons/hooks/use-period-query-params'
import BizAreaTableContents from './components/BizAreaTableContents.vue'
import BizAreaMapContents from './components/BizAreaMapContents.vue'
import { ref } from 'vue'
import { GRANULARITY } from '@/commons/enums'
import { useRoute } from 'vue-router'
import { getPeriodByRouterQueryMonthPeriod } from '@/commons/utils'
import { useStore } from 'vuex'
import { computed } from 'vue'

/* --------------------------------------------------------------------------
  選択済みチェーン・都道府県
 ---------------------------------------------------------------------------*/

const { chainIds, prefectureIds, setParams } = useChainsQueryParams()
const { setPeriodParams } = usePeriodQueryParams()

/* --------------------------------------------------------------------------
  Vuex
 ---------------------------------------------------------------------------*/

const store = useStore()
const startMonth = computed<string>(() => store.state.startMonth)
const endMonth = computed<string>(() => store.state.endMonth)

/* --------------------------------------------------------------------------
  Vue Router
 ---------------------------------------------------------------------------*/

const route = useRoute()

/* --------------------------------------------------------------------------
  core
 ---------------------------------------------------------------------------*/

// 基準チェーン
const baseChainId = ref<string | undefined>(
  chainIds.value.length > 0 ? chainIds.value[0] : undefined
)

// 表示単位
const granularity = ref<(typeof GRANULARITY)[keyof typeof GRANULARITY]>(GRANULARITY.PREFECTURE)

// アラート
const alertAreaNames = ref<string[]>([])

/* --------------------------------------------------------------------------
  created
 ---------------------------------------------------------------------------*/

if (route.query.period) {
  const period = getPeriodByRouterQueryMonthPeriod(route.query.period as string)
  if (period !== undefined) store.commit('setMonth', period)
}
</script>

<style scoped>
.card {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.divider {
  border-bottom: 5px solid #eee;
  margin: 0 -30px 30px;
}
</style>
