<template>
  <div>
    <LoadingImg
      v-if="loading"
      height="600px"
      class="mt-26px mb-50px"
    />
    <HistoryTable
      v-else-if="histories.length > 0"
      :histories="histories"
      class="mt-26px"
    />
    <NoList
      v-else
      class="mt-26px"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import LoadingImg from "@/commons/components/loadingImg.vue";
import HistoryTable from "@/features/Dashboard/components/HistoryTable.vue";
import NoList from "@/features/Dashboard/components/NoList.vue";
import { ComparisonGroupHistory } from "@/features/Dashboard/types";
import { getStoresHistory } from "@/features/Dashboard/axios";
import { notifyErrorMessage } from "@/plugins/notification";

export default defineComponent({
  components: { HistoryTable, NoList, LoadingImg },
  data() {
    return {
      loading: false,
      histories: [] as ComparisonGroupHistory[],
    };
  },
  created() {
    this.fetchHistories();
  },
  methods: {
    async fetchHistories() {
      this.loading = true;
      await getStoresHistory()
        .then((res) => {
          this.histories = res.data.comparisonGroupHistories.sort((a, b) => {
            return a.updated_at > b.updated_at ? -1 : 1;
          });
        })
        .catch(() => {
          notifyErrorMessage("更新履歴が取得できませんでした。");
        });
      this.loading = false;
    },
  },
});
</script>

<style lang="scss" scoped>
.mt-26px {
  margin-top: 26px;
}

.mb-50px {
  margin-bottom: 50px;
}
</style>
