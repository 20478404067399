<template>
  <v-container class="pa-0" fluid>
    <v-row class="title-container" dense no-gutters>
      <v-col style="display: flex; align-items: center">
        <h1 class="title-text">併用分析</h1>
        <update-badge type="month" style="margin-left: 15px" />
      </v-col>
      <v-spacer />
      <monthly-date-picker
        :start-date="startMonth"
        :end-date="endMonth"
        @update-period="updatePeriod"
      />
    </v-row>
    <v-row dense no-gutters class="card-container">
      <v-col>
        <store-selector :loading="storeLoading" />
      </v-col>
    </v-row>
    <v-row dense no-gutters class="card-container mb-0">
      <v-col style="position: relative">
        <div v-if="selectedStore" class="bizarea-container">
          <no-data-chart v-if="!isLoading && isCombinationMapNoData" />
          <combination-map
            v-else
            style="padding: 30px"
            :base-store="selectedStore"
            :combination-stores="combinationStoreCollection"
            :map-loading="isLoading"
            :has-alert="hasMapAlert"
            @click-approve-alert="approveAlertMapData = true"
          />
        </div>
        <div v-else class="unselected_card">店舗を選択するとマップが表示されます。</div>
        <combination-table
          :loading="isLoading"
          :base-store-data="baseStoreTableItem"
          :combination-store-data="combinationStoreTableItems"
          :sort-by="sortBy"
          :sort-desc="sortDesc"
          :load-finished="tableDataLoadFinished"
          :file-id="getConvinationStoreFileId"
          :csv-name="combinationStoreCSVName"
          :has-alert="hasMapAlert"
          @sort="sortTableData"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import UpdateBadge from '@/commons/components/Elements/UpdateBadge.vue'
import MonthlyDatePicker from '@/commons/components/DatePicker/MonthlyDatePicker.vue'
import StoreSelector from '@/commons/components/StoreSelector/StoreSelector.vue'
import NoDataChart from '@/commons/components/Chart/NoDataChart.vue'
import CombinationMap from '@/features/ShopAnalytics/components/Combination/CombinationMap.vue'
import CombinationTable from '@/features/ShopAnalytics/components/Combination/CombinationTable.vue'
import { useStore } from 'vuex'
import { Store } from '@/commons/interfaces/responses/store'
import {
  downloadCombinationStores,
  getCombinationStores
} from '@/features/ShopAnalytics/axios/combination'
import {
  CombinationStore,
  CombinationStoreResponse
} from '@/features/ShopAnalytics/interfaces/response'
import {
  CombinationMapFeatures,
  CombinationTableItem
} from '@/features/ShopAnalytics/interfaces/component'
import { AxiosResponse } from 'axios'
import { convertSlashDelimiter } from '@/commons/utils/dateUtil'
import { useRoute, useRouter } from 'vue-router'
import * as notify from '@/plugins/notification'
import usePeriodQueryParams from '@/commons/hooks/use-period-query-params'
import { getPeriodByRouterQueryMonthPeriod } from '@/commons/utils'

const storeLoading = ref<boolean>(false)
const selectedStore = computed<Store | null>(() => {
  return store.state.selectedStore
})

const store = useStore()
const { setPeriodParams } = usePeriodQueryParams()

const startDate = computed<string | undefined>(() => {
  return store.state.startMonth
})
const endDate = computed<string | undefined>(() => {
  return store.state.endMonth
})
const startMonth = computed<string>(() => store.state.startMonth)
const endMonth = computed<string>(() => store.state.endMonth)

const updatePeriod = ({ startMonth, endMonth }: { startMonth: string; endMonth: string }) => {
  setPeriodParams({ startMonth, endMonth })
}

// 併用マップ
const isLoading = ref<boolean>(false)
const isCombinationMapNoData = computed<boolean>(() => {
  return (
    combinationStoreCollection.value.length === 0 ||
    (combinationStoreCollection.value?.every(
      (store: CombinationMapFeatures) => !store.properties.combinationRatio
    ) ??
      true)
  )
})
const hasAlertInMap = ref<boolean>(false)
const approveAlertMapData = ref<boolean>(false)
const hasMapAlert = computed<boolean>(() => {
  return !isLoading.value && hasAlertInMap.value && !approveAlertMapData.value
})
const combinationStoreCollection = ref<CombinationMapFeatures[]>([])
// 併用テーブル
const baseStoreTableItem = computed<CombinationTableItem | undefined>(() => {
  if (!selectedStore.value) return undefined
  return {
    rank: 0,
    storeId: selectedStore.value.storeId,
    name: selectedStore.value.name,
    storeType: selectedStore.value.storeType,
    distance: 0,
    address: selectedStore.value.address,
    combinationRatio: 0
  }
})
const combinationStoreTableItems = ref<CombinationTableItem[]>([])
const sortBy = ref<string>('')
const sortDesc = ref<boolean>(false)
const tableDataLoadFinished = ref<boolean>(false)
function sortTableData(sortKey: string) {
  if (sortBy.value === sortKey) sortDesc.value = !sortDesc.value
  else {
    sortBy.value = sortKey
    sortDesc.value = false
  }
  combinationStoreTableItems.value = combinationStoreTableItems.value
    .slice()
    .sort((a: CombinationTableItem, b: CombinationTableItem) => {
      const compareValue = (key: string) => {
        switch (key) {
          case 'storeType':
            return a.storeType - b.storeType
          case 'distance':
            return a.distance - b.distance
          case 'address':
            return a.address.localeCompare(b.address)
          case 'combinationRatio':
            return a.combinationRatio - b.combinationRatio
          default:
            return a.rank - b.rank
        }
      }
      const result = compareValue(sortKey)
      return sortDesc.value ? -result : result
    })
}
const getConvinationStoreFileId = async () => {
  return downloadCombinationStores(selectedStore.value?.storeId, startDate.value, endDate.value)
}
const combinationStoreCSVName = computed((): string => {
  const { start, end } = {
    start: convertSlashDelimiter(startDate.value),
    end: convertSlashDelimiter(endDate.value)
  }
  return `併用店舗_${start}-${end}`
})

function fetchData() {
  if (
    typeof selectedStore.value?.storeId === 'undefined' ||
    typeof startDate.value === 'undefined' ||
    typeof endDate.value === 'undefined' ||
    isLoading.value
  )
    return
  isLoading.value = true
  tableDataLoadFinished.value = false
  combinationStoreCollection.value.splice(0, 0)

  getCombinationStores(selectedStore.value?.storeId, startDate.value, endDate.value)
    .then((res: AxiosResponse<CombinationStoreResponse>) => {
      hasAlertInMap.value = res.data.isAlert
      combinationStoreCollection.value = res.data.combinationStores
        .sort((a, b) => a.rank - b.rank)
        .slice(0, 20)
        .map((combinationStore: CombinationStore) => {
          return {
            type: 'Feature',
            properties: {
              rank: combinationStore.rank,
              storeId: combinationStore.storeId,
              storeName: combinationStore.name,
              storeType: combinationStore.storeType,
              combinationRatio: combinationStore.combinationRatio,
              longitude: combinationStore.longitude,
              latitude: combinationStore.latitude,
              hidePopup: true
            },
            geometry: {
              type: 'Point',
              coordinates: [combinationStore.longitude, combinationStore.latitude]
            }
          }
        })

      combinationStoreTableItems.value = res.data.combinationStores
        .sort((a, b) => a.rank - b.rank)
        .slice(0, 20)
        .map((cStore: CombinationStore) => {
          return {
            rank: cStore.rank,
            storeId: cStore.storeId,
            name: cStore.name,
            storeType: cStore.storeType,
            distance: cStore.distance,
            address: cStore.address,
            combinationRatio: !res.data.isAlert ? cStore.combinationRatio : NaN,
            hidePopup: true
          }
        })
    })
    .catch(() => {
      notify.notifyErrorMessage('併用店舗が表示できませんでした。')
    })
    .finally(() => {
      isLoading.value = false
      tableDataLoadFinished.value = true
    })
}

async function handleCreated() {
  if (route.query.period) {
    const period = getPeriodByRouterQueryMonthPeriod(route.query.period as string)
    if (!!period) store.commit('setMonth', period)
  }

  storeLoading.value = true
  if (!store.state.stores.length) await store.dispatch('fetchStores')
  if (route.params['id']) {
    await store.dispatch('specifiedStore', route.params['id'])
    if (!selectedStore.value) router.push({ name: 'NotFound' })
  } else store.commit('initStore')
  storeLoading.value = false

  await fetchAll()
}
async function fetchAll() {
  approveAlertMapData.value = false
  if (!selectedStore.value) return
  fetchData()
  pushRoute()
}

const route = useRoute(),
  router = useRouter()
const query = computed(() => route?.query)

watch([selectedStore, startMonth, endMonth], () => {
  if (selectedStore.value) fetchAll()
})

// HACK: 店舗選択 UI 側で router.push (replace) すべき。影響範囲が大きいため一旦他の店舗分析系画面と統一する形で実装。
async function pushRoute() {
  if (!selectedStore.value) return

  router.replace({
    name: 'ShopAnalyticsCombination',
    params: { id: selectedStore.value.storeId },
    query: query.value
  })
}

/**
 * created
 */
handleCreated()
</script>
