<template>
  <div
    style="position: relative; display: flex; align-items: center"
    @mouseover="setHover(true)"
    @mouseleave="setHover(false)"
  >
    <img src="@/assets/svg/alert-circle.svg">
    <div
      class="text-area"
      :class="{ visible: hover, hidden: !hover }"
    >
      {{ text }}
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    text: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      hover: false,
    };
  },
  methods: {
    setHover(value: boolean) {
      this.hover = value;
    },
  },
});
</script>

<style scoped>
.text-area {
  position: absolute;
  top: 15px;
  left: 17px;
  padding: 9px 10px 14px 12px;
  border: 1px solid #707070;
  box-shadow: 2px 2px 2px #00000029;
  background: #ffffff;
  width: 380px;
  min-height: 55px;
  font-size: 11px;
  line-height: normal;
  user-select: none;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  z-index: 4;
  text-align: left;
  white-space: normal;
}
.visible {
  opacity: 1;
  visibility: visible;
}
.hidden {
  opacity: 0;
  visibility: hidden;
}
</style>
