// import Vue from "vue";
import { axiosClient } from "@/plugins/axios";
import { AxiosResponse } from "axios";
import { convertHyphenDelimiter } from "@/commons/utils/dateUtil";
import {
  VisitCountGraph,
  VisitCountTable,
  VisitCountComparisonTable,
} from "@/features/ChainAnalytics/visitor/interfaces/response";
import { DownloadResponse } from "@/commons/interfaces/responses/download";
import { PARTICLE_TYPE, SUB_TYPE } from "@/commons/enums";

/**
 * 来店人数チャート取得
 * @param {string} chainId チェーンID
 * @param {string} start 期間開始日(yyyy-MM-dd)
 * @param {string} end 期間終了日(yyyy-MM-dd)
 * @param {PARTICLE_TYPE} particle 表示粒度(1: 日別, 2: 週別, 3: 月別, 4: 時間帯別, 5: 曜日別)
 * @param {SUB_TYPE} subType 内訳(0: 指定なし, 1: 性別, 2: 年代別)
 * @param {Array<number>} prefectureIds 都道府県ID群
 * @param {AbortSignal} signal APIの中断用オブジェクト
 */
export async function getVisitCountGraph(
  chainId: string | undefined,
  start: string,
  end: string,
  particle: (typeof PARTICLE_TYPE)[keyof typeof PARTICLE_TYPE],
  subType: (typeof SUB_TYPE)[keyof typeof SUB_TYPE],
  prefectureIds: number[],
  signal?: AbortSignal
): Promise<AxiosResponse<VisitCountGraph>> {
  if (typeof chainId === "undefined") return Promise.reject();
  return await axiosClient.get<VisitCountGraph>(
    "/chains/chart/visit-count-graph/",
    {
      params: {
        chainId: chainId,
        start: convertHyphenDelimiter(start),
        end: convertHyphenDelimiter(end),
        particle: particle,
        type: subType,
        prefectureIds: prefectureIds,
      },
      signal: signal,
    }
  );
}

/**
 * 来店人数テーブル取得
 * @param {string} chainId チェーンID
 * @param {string} start 期間開始日(yyyy-MM-dd)
 * @param {string} end 期間終了日(yyyy-MM-dd)
 * @param {PARTICLE_TYPE} particle 表示粒度(1: 日別, 2: 週別, 3: 月別, 4: 時間帯別, 5: 曜日別)
 * @param {Array<number>} prefectureIds 都道府県ID群
 */
export function getVisitCountTable(
  chainId: string | undefined,
  start: string,
  end: string,
  particle: (typeof PARTICLE_TYPE)[keyof typeof PARTICLE_TYPE],
  prefectureIds: number[]
): Promise<AxiosResponse> {
  if (typeof chainId === "undefined") return Promise.reject();
  return axiosClient.get<VisitCountTable>("/chains/chart/visit-count-table/", {
    params: {
      chainId: chainId,
      start: convertHyphenDelimiter(start),
      end: convertHyphenDelimiter(end),
      particle: particle,
      prefectureIds: prefectureIds,
    },
  });
}

/**
 * 期間比較来店人数テーブル取得
 * @param {string} string チェーンID
 * @param {Array.<Object>} period 期間
 * @param {string} period.start 期間開始日(yyyy-MM-dd)
 * @param {string} period.end 期間開始日(yyyy-MM-dd)
 * @param {PARTICLE_TYPE} particle 表示粒度(1: 日別, 2: 週別, 3: 月別, 4: 時間帯別, 5: 曜日別)
 * @param {Array<number>} prefectureIds 都道府県ID群
 */
export function getVisitCountComparisonGraph(
  chainId: string | undefined,
  period: {
    start: string;
    end: string;
  }[],
  particle: (typeof PARTICLE_TYPE)[keyof typeof PARTICLE_TYPE],
  prefectureIds: number[]
): Promise<AxiosResponse> {
  if (typeof chainId === "undefined") return Promise.reject();

  period.forEach((item: { start: string; end: string }) => {
    item.start = convertHyphenDelimiter(item.start);
    item.end = convertHyphenDelimiter(item.end);
  });
  return axiosClient.post<VisitCountComparisonTable>(
    "/chains/chart/visit-count-comparison/",
    {
      chainId: chainId,
      period: period,
      particle: particle,
      prefectureIds: prefectureIds
    }
  );
}

/**
 * 来店人数テーブルDL用URL生成
 * @param {string} chainId チェーンID
 * @param {string} start 期間開始日(yyyy-MM-dd)
 * @param {string} end 期間終了日(yyyy-MM-dd)
 * @param {PARTICLE_TYPE} particle 表示粒度(1: 日別, 2: 週別, 3: 月別, 4: 時間帯別, 5: 曜日別)
 * @param {Array<number>} prefectureIds 都道府県ID群
 */
export function downloadVisitCountCsv(
  chainId: string | undefined,
  start: string | undefined,
  end: string | undefined,
  particle: (typeof PARTICLE_TYPE)[keyof typeof PARTICLE_TYPE],
  prefectureIds: number[]
): Promise<AxiosResponse> {
  if (chainId === undefined || start === undefined || end === undefined)
    return Promise.reject();
  return axiosClient.get<DownloadResponse>(
    "/chains/chart/visit-count-table/download/",
    {
      params: {
        chainId: chainId,
        start: convertHyphenDelimiter(start),
        end: convertHyphenDelimiter(end),
        particle: particle,
        prefectureIds: prefectureIds
      },
    }
  );
}

/**
 * 期間比較来店人数テーブルDL用URL生成
 * @param {string} chainId チェーンID
 * @param {Array.<Object>} period 期間
 * @param {string} period.start 期間開始日(yyyy-MM-dd)
 * @param {string} period.end 期間開始日(yyyy-MM-dd)
 * @param {PARTICLE_TYPE} particle 表示粒度(1: 日別, 2: 週別, 3: 月別, 4: 時間帯別, 5: 曜日別)
 * @param {Array<number>} prefectureIds 都道府県ID群
 */
export function downloadVisitCountComparisonCsv(
  chainId: string | undefined,
  period: {
    start: string | undefined;
    end: string | undefined;
  }[],
  particle: (typeof PARTICLE_TYPE)[keyof typeof PARTICLE_TYPE],
  prefectureIds: number[]
): Promise<AxiosResponse> {
  if (typeof chainId === "undefined") return Promise.reject();
  if (period.some((p) => p.start === undefined || p.end === undefined))
    return Promise.reject();

  period.forEach((item) => {
    item.start = convertHyphenDelimiter(item.start as string);
    item.end = convertHyphenDelimiter(item.end as string);
  });
  return axiosClient.post<DownloadResponse>(
    "/chains/chart/visit-count-comparison/download/",
    {
      chainId: chainId,
      period: period,
      particle: particle,
      prefectureIds: prefectureIds
    }
  );
}
