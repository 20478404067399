<template>
  <div class="unselected_card">
    <p>データが不足しています。</p>
    <p>期間または店舗を変更してください。</p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "NoDataChart",
});
</script>
