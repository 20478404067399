<template>
  <v-container
    class="pa-0"
    fluid
    style="min-width: 1180px"
  >
    <div class="dashboard-header">
      <h1 class="title-text">
        ダッシュボード
      </h1>
      <v-spacer />
      <div class="caption">
        各数値は、スマートフォンの位置情報（GPS）などを元に、AIで解析・推定しています。
      </div>
    </div>
    <dashboard-tab />
    <router-view />
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import DashboardTab from "@/features/Dashboard/components/DashboardTab.vue";
export default defineComponent({
  name: "DashboardView",
  components: {
    DashboardTab,
  },
});
</script>

<style lang="scss" scoped>
.dashboard-header {
  display: flex;
  align-items: center;
  margin-top: 32.5px;
  margin-bottom: 45px;

  .caption {
    font: normal normal normal 13px/19px Noto Sans JP;
    letter-spacing: .0333333333em!important;
    color: #666666;
    font-size: .75rem!important;
    font-family: Roboto,sans-serif!important;
  }
}

.dashboard-tab {
  display: flex;

  ul {
    width: 100%;
    padding: 0;
    list-style: none;
    border-bottom: 1px solid #ccc;

    li {
      float: left;
      margin: 0 10px;
      padding-bottom: 16px;
      width: 150px;
      font-size: 15px;
      font-weight: bold;
      font-family: Noto Sans JP;
      letter-spacing: 0px;
      color: #222222;
      border-bottom: 5px solid #519ace;
    }
  }
}
</style>
