<template>
  <div>
    <table class="heatmap">
      <tr>
        <th v-for="(item, index) in header" :key="item" :width="index === 0 ? 100 : ''">
          {{ item }}
        </th>
      </tr>
      <tr v-for="(chart, indexRow) in charts" :key="indexRow" style="height: 100px">
        <td
          v-for="(item, indexColumn) in chart as any as ChartDataValue[]"
          :key="indexColumn"
          :width="indexColumn === 0 ? 100 : ''"
        >
          <!-- 店舗 -->
          <span v-if="indexColumn === 0">
            {{ item }}
          </span>
          <!-- 数値 -->
          <div
            v-else
            :style="setHeatMapColorClass(item.value!, item.minValue, item.maxValue, item.isAlert)"
          >
            <div>
              <b>{{ (Math.round(item.shareRatio * 1000) / 10).toFixed(1) + '%' }}</b>
            </div>
            <div>
              <b>
                （{{
                  isNaN(item.value!) ? 'データがありません' : item.value!.toLocaleString() + '人'
                }}）
              </b>
            </div>
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { ChartData, ChartDataValue } from '@/features/StoreCompare/trendVisitor/types'

export default defineComponent({
  props: {
    // ["", "月曜日", "火曜日", "水曜日", ...]
    header: {
      type: Array as PropType<string[]>,
      default: () => []
    },
    // [["店舗A", { value: 100, isAlert: false }, { value: 200, isAlert: true } ... ] ... ]
    charts: {
      type: Array as PropType<ChartData>,
      default: () => []
    },
    colorStyles: {
      type: Array as PropType<string[]>,
      default: () => []
    }
  },
  methods: {
    setHeatMapColorClass(value: number, minValue: number, maxValue: number, isAlert: boolean) {
      if (isAlert || isNaN(value)) return 'background: #bbbbbb'

      const oneStep = (maxValue - minValue) / 10
      if (value <= oneStep * 1 + minValue) return this.colorStyles[0] ?? 'background: #F8E8E8'
      if (value <= oneStep * 2 + minValue) return this.colorStyles[1] ?? 'background: #F5D9D8'
      if (value <= oneStep * 3 + minValue) return this.colorStyles[2] ?? 'background: #F0C5C3'
      if (value <= oneStep * 4 + minValue) return this.colorStyles[3] ?? 'background: #E8AFAC'
      if (value <= oneStep * 5 + minValue)
        return this.colorStyles[4] ?? 'background: #E09693; color: #ffffff;'
      if (value <= oneStep * 6 + minValue)
        return this.colorStyles[5] ?? 'background: #D77D7A; color: #ffffff;'
      if (value <= oneStep * 7 + minValue)
        return this.colorStyles[6] ?? 'background: #CD6362; color: #ffffff;'
      if (value <= oneStep * 8 + minValue)
        return this.colorStyles[7] ?? 'background: #C24A4C; color: #ffffff;'
      if (value <= oneStep * 9 + minValue)
        return this.colorStyles[8] ?? 'background: #B82F39; color: #ffffff;'
      return this.colorStyles[9] ?? 'background: #B11229; color: #ffffff;'
    }
  }
})
</script>

<style scoped>
.heatmap {
  margin-bottom: 15px;
  height: 100%;
  width: 100%;
  font-size: 12px;
  border: 1px solid #fff;
  border-collapse: collapse;
  table-layout: fixed;
}
.heatmap tr {
  border: 1px solid #fff;
  border-collapse: collapse;
}

.heatmap td {
  border: 1px solid #fff;
  border-collapse: collapse;
}

.heatmap td > div {
  padding: 10px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.font-bold {
  font-weight: bold;
}
</style>
