<template>
  <div class="compare-list-table">
    <table>
      <tr class="header">
        <th>
          <input
            v-model="all"
            type="checkbox"
            class="checkbox"
            @change="clickAll"
          >
        </th>
        <th>
          <span>
            リスト名
            <SortButton
              class="sort-icon"
              :show-status="{
                up: sortBy.column !== 'name' || !sortBy.desc,
                down: sortBy.column !== 'name' || sortBy.desc,
              }"
              @sort-action="setSortBy('name')"
            />
          </span>
        </th>
        <th>最終更新者</th>
        <th>
          <span>
            更新日時
            <SortButton
              class="sort-icon"
              :show-status="{
                up: sortBy.column !== 'updatedAt' || !sortBy.desc,
                down: sortBy.column !== 'updatedAt' || sortBy.desc,
              }"
              @sort-action="setSortBy('updatedAt')"
            />
          </span>
        </th>
        <th>更新内容</th>
        <th />
      </tr>
      <tr
        v-for="group in groupsSlicedByPage"
        :key="group.id"
        class="body"
        :class="{ selected: isSelected(group.id) }"
      >
        <td>
          <input
            :id="group.id"
            v-model="selected"
            type="checkbox"
            :value="group.id"
            class="checkbox"
          >
        </td>
        <td>
          <a @click="clickAnalytics(group.id)">{{ group.name }}</a>
          <p>{{ group.stores.map((store) => store.name).join(", ") }}</p>
        </td>
        <td>{{ group.updated_by }}</td>
        <td>{{ convertDateAndTimeSlashDelimiter(group.updated_at) }}</td>
        <td>{{ group.update_note }}</td>
        <td>
          <CustomButton
            variant="primary"
            size="sm"
            class="mb-8px"
            @click="clickAnalytics(group.id)"
          >
            分析する
          </CustomButton>
          <CustomButton
            variant="inverse"
            size="sm"
            @click="clickEdit(group.id)"
          >
            編集する
          </CustomButton>
        </td>
      </tr>
    </table>
    <div class="table-footer">
      <span class="caption">チェックしたリストを</span>
      <CustomButton
        variant="inverse"
        size="xs"
        class="ml-11px"
        :disabled="selected.length === 0"
        @click="dialog = true"
      >
        削除する
      </CustomButton>
      <v-spacer />
      <span
        v-if="groups.length !== 0"
        class="caption"
      >
        {{ pageFrom }}-{{ pageTo }} 件 / {{ groups.length }}件
      </span>
      <span
        v-else
        class="caption"
      > 0件 </span>
      <CustomSelector
        v-model="itemsPerPage"
        :items="[
          { text: '30件', value: 30 },
          { text: '50件', value: 50 },
        ]"
        size="xs"
        width="75px"
        height="30px"
        class="ml-8px"
      />
      <TableNavigation
        :click-next="next"
        :click-previous="previous"
        :next-disabled="page * itemsPerPage.value >= groups.length"
        :previous-disabled="page === 1"
        class="ml-15px"
      />
    </div>
    <DeleteDialog
      v-if="selected.length >= 1"
      v-model="dialog"
      :loading="loading"
      :targets="selectedNames"
      @click-delete="clickDelete"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, computed, ref, watch } from "vue";
import TableNavigation from "@/features/Dashboard/components/TableNavigation.vue";
import DeleteDialog from "@/features/Dashboard/components/DeleteDialog.vue";
import SortButton from "@/commons/components/Elements/SortButton.vue";
import { ComparisonGroup } from "@/commons/interfaces";
import { deleteStoresComparison } from "@/features/Dashboard/axios";
import {
  convertHyphenDelimiter,
  convertDateAndTimeSlashDelimiter as convertDateAndTimeSlashDelimiterUtil,
} from "@/commons/utils/dateUtil";
import * as notify from "@/plugins/notification";
import { useStore } from "vuex";
import { useRouter } from 'vue-router'

export default defineComponent({
  components: { TableNavigation, DeleteDialog, SortButton },
  props: {
    groups: {
      type: Array as PropType<ComparisonGroup[]>,
      default: () => [],
    },
    sortBy: {
      type: Object as PropType<{
        column: "name" | "updatedAt";
        desc: boolean;
      }>,
      required: true,
    },
  },
  emits: ["setSortBy"],
  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();
    const loading = ref(false);
    const page = ref(1);
    const itemsPerPage = ref({ text: "30件", value: 30 });
    const all = ref(false);
    const selected = ref([] as string[]);
    const dialog = ref(false);

    const selectedNames = computed(() =>
      selected.value
        .map(
          (id) =>
            (props.groups?.find((t) => t.id === id) as ComparisonGroup)?.name
        )
        .filter((name) => name !== undefined)
    );
    const groupsSlicedByPage = computed(() =>
      props.groups?.slice(
        (page.value - 1) * itemsPerPage.value.value,
        page.value * itemsPerPage.value.value
      )
    );
    const pageFrom = computed(() => {
      if (page.value === 1) return 1;
      return (page.value - 1) * itemsPerPage.value.value + 1;
    });
    const pageTo = computed(() => {
      if (props.groups && page.value * itemsPerPage.value.value > props.groups.length)
        return props.groups?.length;
      return page.value * itemsPerPage.value.value;
    });

    const setSortBy = (type: "name" | "updatedAt") => {
      emit("setSortBy", { type: type });
    };
    const clickAnalytics = (id: string) => {
      const start = convertHyphenDelimiter(
        store.state.startDate
      );
      const end = convertHyphenDelimiter(store.state.endDate);

      router.push({
        name: "StoreCompareVisitor",
        params: { id: id },
        query: {
          period: `${start}_${end}`,
          unit: "date",
        },
      });
    };
    const clickEdit = (id: string) => {
      router.push({
        name: "StoreCompareEdit",
        params: { id: id },
      });
    };
    const clickDelete = async() => {
      loading.value = true;
      await deleteStoresComparison(selected.value)
        .then(async () => {
          await store.dispatch("fetchComparisonGroups");
          dialog.value = false;
        })
        .catch(() => {
          notify.notifyErrorMessage("指定の比較リストが削除できませんでした。");
        });
      loading.value = false;
    };
    const clickAll = () => {
      if (all.value && groupsSlicedByPage.value)
        selected.value = groupsSlicedByPage.value?.map((group) => group.id);
      else selected.value.splice(0);
    };
    const isSelected = (id: string) => {
      return selected.value.includes(id);
    };
    const next = () =>{
      if (props.groups && page.value * itemsPerPage.value.value < props.groups.length) page.value++;
    };
    const previous = () => {
      if (page.value !== 1) page.value--;
    };
    const convertDateAndTimeSlashDelimiter = (date: string): string => {
      return convertDateAndTimeSlashDelimiterUtil(date);
    };

    watch(() => props.groups, () => {
      page.value = 1;
    });

    watch(itemsPerPage, () => {
      page.value = 1;
    });

    return {
      loading,
      page,
      itemsPerPage,
      all,
      selected,
      dialog,
      selectedNames,
      groupsSlicedByPage,
      pageFrom,
      pageTo,
      setSortBy,
      clickAnalytics,
      clickEdit,
      clickDelete,
      clickAll,
      isSelected,
      next,
      previous,
      convertDateAndTimeSlashDelimiter,
    };
  },
});
</script>

<style lang="scss" scoped>
.compare-list-table {
  table {
    width: 100%;
    border-collapse: collapse;
    box-shadow: 0px 0px 3px #00000029;

    .header {
      background-color: #0e182e;
      color: #ffffff;
      height: 50px;
      font-size: 13px;

      th {
        span {
          display: flex;
          justify-content: center;
          align-items: center;

          .sort-icon {
            margin-left: 8px;
          }
        }
      }

      th:first-child {
        width: 49.5px;
      }
      th:nth-child(2) {
        padding: 0 14px;
        text-align: left;
        min-width: 505px;
        span {
          justify-content: flex-start;
        }
      }
      th:nth-child(3) {
        width: 121px;
      }
      th:nth-child(4) {
        width: 100px;
      }
      th:nth-child(5) {
        width: 188px;
      }
      th:last-child {
        width: 146.5px;
      }
    }

    .body {
      background-color: #ffffff;
      td {
        padding: 25px 0;
        border: 1px solid #dddddd;
      }
      td:first-child {
        width: 49.5px;
      }
      td:nth-child(2) {
        padding: 0 14px;
        text-align: left;

        a {
          color: #4d99d0;
          font-size: 16px;
          font-weight: bold;
          cursor: pointer;
        }

        p {
          margin: 9px 0 0;
          color: #666666;
          font-size: 12px;
        }
      }
      td:nth-child(3) {
        padding: 0 10.5px;
        font-size: 13px;
        color: #666666;
      }
      td:nth-child(4) {
        padding: 0 14px;
        font-size: 13px;
        text-align: left;
        color: #666666;
      }
      td:nth-child(5) {
        padding: 0 14px;
        font-size: 12px;
        text-align: left;
        color: #666666;
      }
      td:last-child {
        width: 146.5px;
      }
    }

    .selected {
      background-color: #f4fbff !important;
    }

    .checkbox {
      height: 16px;
      width: 16px;
      margin-top: 4px;
      accent-color: #4d99d0;

      &::before {
        border: 1px solid #ccc !important;
      }
    }
  }
  .table-footer {
    display: flex;
    align-items: center;
    margin-top: 21.5px;

    .caption {
      font-size: .75rem!important;
      color: #222222;
      letter-spacing: .0333333333em!important;
      line-height: 1.25rem!important;
    }
  }
}

.mb-8px {
  margin-bottom: 8px;
}

.ml-8px {
  margin-left: 8px;
}

.ml-11px {
  margin-left: 11px;
}

.ml-15px {
  margin-left: 15px;
}
</style>
