<template>
  <div class="chart-legends">
    <div
      v-for="legend in legends"
      :key="legend.text"
      class="chip"
    >
      <span :style="`color: ${legend.color}`">●</span> {{ legend.text }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
  props: {
    legends: {
      type: Array as PropType<{ text: string; color: string }[]>,
      default: () => {
        return [];
      },
    },
  },
});
</script>

<style scoped>
.chart-legends {
  display: flex;
  flex-wrap: wrap;
}

.chart-legends > .chip {
  margin-right: 22px;
  font-size: 13px;
  color: #333333;
  user-select: none;
}
</style>
