<template>
  <v-container
    class="pa-0"
    fluid
  >
    <v-row
      class="title-container"
      dense
      no-gutters
    >
      <v-col style="display: flex; align-items: center">
        <h1 class="title-text">
          来店特性
        </h1>
        <update-badge
          type="month"
          style="margin-left: 15px"
        />
      </v-col>
    </v-row>
    <v-row
      dense
      no-gutters
      class="card-container"
    >
      <v-col>
        <store-selector :loading="storeLoading" />
      </v-col>
    </v-row>
    <v-row
      dense
      no-gutters
      class="card-container"
    >
      <v-col>
        <v-card
          height="'650px'"
          class="card"
        >
          <v-card-title class="pa-0 card-title d-flex align-center">
            <span>来店頻度</span>
            <alert-tooltip
              v-if="hasAlertInFrequencyChartData"
              class="ml-2"
              text="取得データボリュームが少なく、統計上の信頼性の低いデータが含まれています。該当箇所は、参考値としてご参照ください。"
            />
            <v-spacer />
            <chart-description-tooltip
              menu-key="analytics"
              sub-menu-key="visitEngagement"
              chart-key="visitFrequency"
              class="mr-27px"
            />
            <download-button
              label="CSVデータ"
              :disabled="!selectedStore"
              :get-file-id="getVisitFrequencyChartFileId"
              :csv-name="visitFrequencyCSVName"
            />
          </v-card-title>
          <v-container
            class="pa-0"
            fluid
          >
            <v-row>
              <v-col style="position: relative">
                <div
                  v-show="!selectedStore"
                  class="unselected_card"
                >
                  店舗を選択するとチャートが表示されます。
                </div>
                <div v-show="selectedStore">
                  <loadingImg
                    v-if="frequencyChartLoading"
                    :height="'550px'"
                  />
                  <no-data-chart v-else-if="frequencyChartData.length <= 1" />
                  <column-chart
                    v-else
                    :chart-data="frequencyChartData"
                    is-stacked="percent"
                    :legends="frequencyChartLegends()"
                    :is-alert="hasAlertInFrequencyChartData"
                  />
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-row
      dense
      no-gutters
      class="card-container"
    >
      <v-col>
        <v-card
          height="'650px'"
          class="card"
        >
          <v-card-title class="pa-0 card-title d-flex align-center">
            <span>エンゲージメント</span>
            <alert-tooltip
              v-if="hasAlertInEngagementChartData"
              class="ml-2"
              text="取得データボリュームが少なく、統計上の信頼性の低いデータが含まれています。該当箇所は、参考値としてご参照ください。"
            />
            <v-spacer />
            <chart-description-tooltip
              menu-key="analytics"
              sub-menu-key="visitEngagement"
              chart-key="engagement"
              class="mr-27px"
            />
            <download-button
              label="CSVデータ"
              :disabled="!selectedStore"
              :get-file-id="getEngagementFileId"
              :csv-name="engagementCSVName"
            />
          </v-card-title>
          <v-container
            class="pa-0"
            fluid
          >
            <v-row>
              <v-col style="position: relative">
                <div
                  v-show="!selectedStore"
                  class="unselected_card"
                >
                  店舗を選択するとチャートが表示されます。
                </div>
                <div v-show="selectedStore">
                  <loadingImg
                    v-if="engagementChartLoading"
                    :height="'550px'"
                  />
                  <no-data-chart v-else-if="engagementChartData.length <= 1" />
                  <column-chart
                    v-else
                    :chart-data="engagementChartData"
                    is-stacked="percent"
                    :legends="engagementChartLegends()"
                    :is-alert="hasAlertInEngagementChartData"
                  />
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-row
      dense
      no-gutters
      class="card-container mb-0"
    >
      <v-col>
        <v-card
          height="'650px'"
          class="card"
        >
          <v-card-title class="pa-0 card-title d-flex align-center">
            <span>コホート分析</span>
            <alert-tooltip
              v-if="hasAlertInCohortChartData"
              class="ml-2"
              text="取得データボリュームが少なく、統計上の信頼性の低いデータが含まれています。該当箇所は、参考値としてご参照ください。"
            />
            <v-spacer />
            <chart-description-tooltip
              menu-key="analytics"
              sub-menu-key="visitEngagement"
              chart-key="cohort"
              class="mr-27px"
            />
            <download-button
              label="CSVデータ"
              :disabled="!selectedStore"
              :get-file-id="getCohortAnalysisChartFileId"
              :csv-name="cohortAnalysisCSVName"
            />
          </v-card-title>
          <v-container
            class="pa-0"
            fluid
          >
            <v-row>
              <v-col style="position: relative">
                <div
                  v-show="!selectedStore"
                  class="unselected_card"
                >
                  店舗を選択するとテーブルが表示されます。
                </div>
                <div v-show="selectedStore">
                  <LoadingImg
                    v-if="cohortChartLoading"
                    :height="'550px'"
                  />
                  <no-data-chart v-else-if="emptyCohortData" />
                  <cohort-table
                    v-else
                    :items="tableData"
                    :is-alert="hasAlertInCohortChartData"
                  />
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import ColumnChart from "@/features/ShopAnalytics/components/Common/columnChart.vue";
import StoreSelector from "@/commons/components/StoreSelector/StoreSelector.vue";
import CohortTable from "@/features/ShopAnalytics/components/VisitEngagement/cohortTable.vue";
import UpdateBadge from "@/features/ShopAnalytics/components/Common/updateBadge.vue";
import NoDataChart from "@/features/ShopAnalytics/components/Common/noDataChart.vue";
// util
import * as notify from "@/plugins/notification";
import {
  processFrequencyChartData,
  processEngagementChartData,
  processCohortTableData,
  hasAlertInFrequencyChartData,
  hasAlertInEngagementChartData,
  hasAlertInCohortChartData,
} from "@/features/ShopAnalytics/utils/visitPattern";
import {
  getStartMonthOf13Month,
  getEndMonthOf13Month,
} from "@/commons/utils/dateUtil";
// interface
import * as tableItemInterface from "@/features/ShopAnalytics/interfaces/component";
import { AxiosResponse } from "axios";
import { Store } from "@/commons/interfaces/responses/store";
import {
  FrequencyResponse,
  EngagementResponse,
  CohortResponse,
} from "@/features/ShopAnalytics/interfaces/response";

// axios
import {
  getVisitFrequencyChart,
  downloadVisitFrequencyChart,
  getEngagementChart,
  downloadEngagementChart,
  getCohortAnalysisChart,
  downloadCohortAnalysisChart,
} from "@/features/ShopAnalytics/axios/visitEngagement";

// mock
import LoadingImg from "@/commons/components/loadingImg.vue";
import { COLOR } from "@/commons/enums";

export default defineComponent({
  name: "VisitPatternView",
  components: {
    LoadingImg,
    ColumnChart,
    StoreSelector,
    CohortTable,
    UpdateBadge,
    NoDataChart,
  },
  data() {
    return {
      created: false,
      storeLoading: false,
      frequencyChartData: [] as (string | number)[][],
      engagementChartData: [] as (string | number)[][],
      frequencyChartLoading: false,
      engagementChartLoading: false,
      cohortChartLoading: false,
      tableData: [] as tableItemInterface.visitPatternTableItem[],
      tableLoading: false,
      hasAlertInFrequencyChartData: false,
      hasAlertInEngagementChartData: false,
      hasAlertInCohortChartData: false,
      frequencyChartLegends(): { text: string; color: string }[] {
        return [
          { text: "月1回", color: COLOR.RED },
          { text: "月2回", color: COLOR.BLUE },
          { text: "月3回", color: COLOR.GREEN },
          { text: "月4回以上", color: COLOR.ORANGE },
          { text: "月8回以上", color: COLOR.PURPLE },
          { text: "参考値（データ不十分）", color: COLOR.GRAY },
        ];
      },
      engagementChartLegends(): { text: string; color: string }[] {
        return [
          { text: "新規", color: COLOR.RED },
          { text: "継続", color: COLOR.BLUE },
          { text: "復活", color: COLOR.GREEN },
          { text: "離反（当月）", color: COLOR.ORANGE },
          { text: "離反（先月）", color: COLOR.PURPLE },
          { text: "参考値（データ不十分）", color: COLOR.GRAY },
        ];
      },
    };
  },
  computed: {
    selectedStore(): Store | null {
      return this.$store.state.selectedStore;
    },
    emptyCohortData(): boolean {
      if (this.tableData.length === 0) return true;
      for (let i = 0; i < this.tableData.length; i++) {
        if (
          this.tableData[i].one !== 0 ||
          this.tableData[i].two !== 0 ||
          this.tableData[i].three !== 0 ||
          this.tableData[i].four !== 0 ||
          this.tableData[i].five !== 0
        )
          return false;
      }
      return true;
    },
    getVisitFrequencyChartFileId(): () => Promise<AxiosResponse<any, any>> {
      return () => downloadVisitFrequencyChart(this.selectedStore?.storeId);
    },
    getEngagementFileId(): () => Promise<AxiosResponse<any, any>> {
      return () => downloadEngagementChart(this.selectedStore?.storeId);
    },
    getCohortAnalysisChartFileId(): () => Promise<AxiosResponse<any, any>> {
      return () => downloadCohortAnalysisChart(this.selectedStore?.storeId);
    },
    visitFrequencyCSVName(): string {
      return `店舗分析_来店頻度_${getStartMonthOf13Month()}-${getEndMonthOf13Month()}`;
    },
    engagementCSVName(): string {
      return `店舗分析_エンゲージメント_${getStartMonthOf13Month()}-${getEndMonthOf13Month()}`;
    },
    cohortAnalysisCSVName(): string {
      return `店舗分析_コホート分析_${getStartMonthOf13Month()}-${getEndMonthOf13Month()}`;
    },
  },
  watch: {
    async selectedStore() {
      if (this.created) {
        await this.pushRoute();
        await this.fetchAll();
      }
    },
  },
  // TODO: fetch 前にそのまま期間を引き継ぐか、月単位のデフォルト期間(直近6ヶ月)で更新するかの処理を追加
  async created() {
    await this.fetchAll();
    this.created = true;
  },
  methods: {
    async fetchAll() {
      // 店舗一覧取得
      this.storeLoading = true;

      if (!this.$store.state.stores.length)
        await this.$store.dispatch("fetchStores");

      // id があれば選択店舗を設定
      if (this.$route.params["id"]) {
        await this.$store.dispatch("specifiedStore", this.$route.params["id"]);
        if (!this.selectedStore) this.$router.push({ name: "NotFound" });
      } else this.$store.commit("initStore");

      this.storeLoading = false;

      // チャートデータ取得
      if (this.$store.state.selectedStore) this.fetchChartData();
    },
    async fetchChartData() {
      this.frequencyChartLoading = true;
      this.engagementChartLoading = true;
      this.cohortChartLoading = true;

      // 来店頻度
      await getVisitFrequencyChart(this.selectedStore?.storeId)
        .then((res: AxiosResponse<FrequencyResponse>) => {
          this.frequencyChartData = processFrequencyChartData(res.data);
          this.hasAlertInFrequencyChartData = hasAlertInFrequencyChartData(
            res.data
          );
        })
        .catch(() => {
          notify.notifyErrorMessage("来店頻度が表示できませんでした。");
        })
        .finally(() => {
          this.frequencyChartLoading = false;
        });
      // エンゲージメント
      await getEngagementChart(this.selectedStore?.storeId)
        .then((res: AxiosResponse<EngagementResponse>) => {
          this.engagementChartData = processEngagementChartData(res.data);
          this.hasAlertInEngagementChartData = hasAlertInEngagementChartData(
            res.data
          );
        })
        .catch(() => {
          notify.notifyErrorMessage("エンゲージメントが表示できませんでした。");
        })
        .finally(() => {
          this.engagementChartLoading = false;
        });
      // コホート分析
      await getCohortAnalysisChart(this.selectedStore?.storeId)
        .then((res: AxiosResponse<CohortResponse>) => {
          this.tableData = processCohortTableData(res.data);
          this.hasAlertInCohortChartData = hasAlertInCohortChartData(res.data);
        })
        .catch(() => {
          notify.notifyErrorMessage("コホート分析が表示できませんでした。");
        })
        .finally(() => {
          this.cohortChartLoading = false;
        });
    },

    async pushRoute() {
      if (!this.$store.state.selectedStore) return;

      // NOTE: 他ページに遷移後にreplaceが呼び出されると戻される対策
      if (this.$route.name === "ShopAnalyticsVisitEngagement") {
        await this.$router
          .replace({
            name: "ShopAnalyticsVisitEngagement",
            params: { id: this.$store.state.selectedStore.storeId },
          })
          .catch(() => undefined);
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.mr-27px {
  margin-right: 27px;
}
</style>
