<template>
  <MglMap
    :access-token="MAP_ACCESS_TOKEN"
    :map-style="MAP_STYLE"
    :center="center"
    :zoom="initialZoom"
    :attribution-control="false"
    :drag-rotate="false"
    :touch-zoom-rotate="false"
    :pitch-with-rotate="false"
    logo-position="bottom-right"
    class="map"
    @load="handleLoad"
  >
    <!-- ローディング -->
    <v-overlay
      contained
      :model-value="props.isLoading"
      class="align-center justify-center"
      :z-index="4"
      :persistent="true"
      :no-click-animation="true"
    >
      <LoadingImg />
    </v-overlay>

    <!-- 検索ボックス -->
    <SearchAutoComplete
      type="emit"
      width="480px"
      list-width="150%"
      class="map-search-bar"
      @set-store="handleStoreSet"
    />

    <!-- +/- ズームコントローラー -->
    <MglNavigationControl :show-compass="false" position="top-right" />

    <!-- 縮尺 -->
    <MglScaleControl position="bottom-left" />

    <!-- 選択中スポット(店舗)のマーカー -->
    <MglMarker
      v-if="selectedStore"
      :coordinates="[selectedStore.longitude, selectedStore.latitude]"
    >
      <template #marker>
        <div>
          <img src="@/assets/svg/center-pin.svg" />
        </div>
      </template>
    </MglMarker>

    <!-- チェーンポップアップ -->
    <MglPopup
      :showed="popupShowed"
      :coordinates="popupContent?.coordinates"
      anchor="bottom-left"
      :close-button="false"
      :close-on-click="false"
    >
      <div @mousemove="handleMouseMovePopup" @mouseleave="handleMouseLeavePopup">
        <BizAreaMapPopup
          v-if="popupContent"
          :area-name="popupContent.areaName"
          :chain-name="popupContent.chainName"
          :chain-color="popupContent.color"
          :share="popupContent.visitRatio"
          :on-popup-close="() => (popupShowed = true)"
        />
      </div>
    </MglPopup>
  </MglMap>
</template>

<script setup lang="ts">
import {
  MglMap,
  MglMarker,
  MglPopup,
  MglNavigationControl,
  MglScaleControl
} from '@/vendor/vue-mapbox/main'
import { MAP_ACCESS_TOKEN, MAP_STYLE } from '@/config'
import { ref } from 'vue'
import { useStore } from 'vuex'
import { computed } from 'vue'
import {
  COLOR,
  GRANULARITY,
  TOKYO_AREA_ID,
  TOKYO_BBOX_LEFT_DOWN,
  TOKYO_BBOX_RIGHT_TOP
} from '@/commons/enums'
import SearchAutoComplete from '@/commons/components/StoreSelector/SearchAutoComplete.vue'
import { Store } from '@/commons/interfaces/responses/store'
import getDistance from '@/commons/utils/map/get-distance'
import { Point } from '@/commons/types/Mapbox'
import LoadingImg from '@/commons/components/loadingImg.vue'
import { ComparisonMapBoxFeatures } from '../types'
import { watch } from 'vue'
import { processMapData } from '../utils'
import BizAreaMapPopup from './BizAreaMapPopup.vue'
import { getOverallBbox } from '@/commons/utils/geojson/get-overall-bbox'
import { BBox } from '@/commons/types/GeoJSON'
import { debounce } from 'lodash'

// 都道府県選択時のズームレベル
const PREFECTURE_ZOOM_LEVEL = 4

// 縮尺 (表示領域の縦の距離) がおよそ 40km を示すズームレベル。
// 閾値が 40km なので 40 より少し短めに設定。
const FORTY_KM_ZOOM_LEVEL = 10

// 中心座標の初期値
const INITIAL_CENTER = [137.4103805, 38.15155]

/* --------------------------------------------------------------------------
  props
 ---------------------------------------------------------------------------*/

const props = withDefaults(
  defineProps<{
    /** ローディング */
    isLoading: boolean
    /** 選択済み都道府県 */
    selectedPrefectureIds: number[]
    /** 基準チェーンID */
    baseChainId: string | undefined
    /** 表示単位 */
    granularity?: (typeof GRANULARITY)[keyof typeof GRANULARITY]
    /** 表示単位 */
    granularityRadioButtonState: (typeof GRANULARITY)[keyof typeof GRANULARITY]
    /** マップに表示するポリゴンデータ */
    features?: ComparisonMapBoxFeatures[]
    /** 中心座標の初期値 */
    initialCenter: Point | undefined
    /** カメラの中心座標が変更された時に実行されるイベントハンドラ */
    onCenterChange?: (center: Point) => void
    /** 縮尺(表示領域の縦の距離(m)) が変更された時に実行されるイベントハンドラ */
    onScaleChange?: (scale: number) => void
    /** ズーム開始時に実行されるイベントハンドラ */
    onZoomStart?: () => void
    /** ズーム終了時に実行されるイベントハンドラ */
    onZoomEnd?: () => void
    /** Bounds (マップの表示領域のbbox) 変更時に実行されるイベントハンドラ */
    onBoundsChange?: (bbox: BBox) => void
  }>(),
  {
    isLoading: false,
    selectedPrefectureIds: (): number[] => [],
    baseChainId: undefined,
    granularity: GRANULARITY.PREFECTURE,
    granularityRadioButtonState: GRANULARITY.PREFECTURE,
    initialCenter: undefined,
    features: () => [],
    onCenterChange: () => undefined,
    onScaleChange: () => undefined,
    onZoomStart: () => undefined,
    onZoomEnd: () => undefined,
    onBoundsChange: () => undefined
  }
)

/* --------------------------------------------------------------------------
  Vuex
 -------------------------------------------------------------------------- */

const store = useStore()
const chainIdToColorMap = computed<Map<string, string>>(() => store.state.chainIdToColorMap)

/* --------------------------------------------------------------------------
  core
 ---------------------------------------------------------------------------*/

// Mapbox GI JS Map オブジェクト
// ※ vue-mapbox 側で any 定義されているため any で定義
const map = ref<any>(null)

// スポット（店舗）
const selectedStore = ref<Store | undefined>(undefined)

// スポット（店舗）変更イベントハンドラ関数
const handleStoreSet = (event: { store: Store }) => {
  if (map.value) {
    map.value.setZoom(
      Number(props.granularity) === GRANULARITY.PREFECTURE
        ? PREFECTURE_ZOOM_LEVEL
        : FORTY_KM_ZOOM_LEVEL
    )
  }
  selectedStore.value = event.store
  props.onScaleChange(getScale(map.value))
}

// マップの中心座標
const center = computed<number[] | undefined>(() => {
  if (selectedStore.value) {
    return [selectedStore.value.longitude, selectedStore.value.latitude]
  }

  if (props.initialCenter) {
    return [props.initialCenter.lng, props.initialCenter.lat]
  }

  return INITIAL_CENTER
})

// bounds の BBox
const bounds = ref<BBox | undefined>()

// ズーム初期値
const initialZoom = ref<number>(
  // 都道府県、もしくはデータ取得前に市区町村・町丁目を選択している際は本土が全域映るズーム倍率
  // (その上で、市区町村・町丁目のときは縮尺アラートが表示される)
  Number(props.granularity) === GRANULARITY.PREFECTURE || !props.features.length
    ? PREFECTURE_ZOOM_LEVEL
    : FORTY_KM_ZOOM_LEVEL
)

// map オブジェクトから scale を算出する関数
const getScale = (map: any) => {
  const bounds = map.getBounds()
  const northLat = bounds.getNorth()
  const southLat = bounds.getSouth()
  const { lng: centerLng } = bounds.getCenter()
  const scale = getDistance({ lng: centerLng, lat: northLat }, { lng: centerLng, lat: southLat })

  return scale
}

// Map にポリゴンを表示するための source
const geoJsonSource = computed(() => {
  if (props.features.length === 0) return undefined

  return {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: processMapData(props.features, chainIdToColorMap.value)
    }
  }
})

// Map にポリゴンを表示するための layer
const geoJsonLayer = computed(() => {
  if (!geoJsonSource.value) return undefined
  return {
    id: 'chain-compare-biz-area-layer',
    type: 'fill',
    source: geoJsonSource.value,
    layout: {},
    paint: {
      'fill-color': [
        'match',
        ['get', 'maxColor'],
        COLOR.RED,
        COLOR.RED,
        COLOR.BLUE,
        COLOR.BLUE,
        COLOR.GREEN,
        COLOR.GREEN,
        COLOR.ORANGE,
        COLOR.ORANGE,
        COLOR.PURPLE,
        COLOR.PURPLE,
        COLOR.BROWN,
        COLOR.BROWN,
        COLOR.GRAY
      ],
      'fill-opacity': 0.6,
      'fill-outline-color': '#ffffff'
    }
  }
})

// ポリゴンを画面内にフィットさせる関数
const fitOverallBounds = (features: ComparisonMapBoxFeatures[]) => {
  if (!map.value) return

  const prefectureIds = props.selectedPrefectureIds.map((id) => id.toString().padStart(2, '0'))

  const bounds = getOverallBbox(
    features.filter(
      (feature: ComparisonMapBoxFeatures) =>
        !!feature.geometry && prefectureIds.includes(feature.properties.areaId.split('-')[0] ?? '')
    )
  )

  if (bounds) map.value.fitBounds(bounds, { padding: 20 })
}

// GeoJson を更新し、ポリゴンレイヤーをアップデートする関数
const updateGeoJson = async () => {
  if (!map.value || props.features.length === 0) return

  // 既に存在する layer, source を削除
  // addLayer でレイヤーを登録すると、その時の ID で source も登録されるので、layer, source 両方削除する際は登録時の ID で削除する。
  const layerId = geoJsonLayer.value?.id
  const layer = await map.value.getLayer(layerId)

  if (layer) {
    await map.value.removeLayer(layerId)
    await map.value.removeSource(layerId)
  }

  if (geoJsonLayer.value) {
    await map.value.addLayer(geoJsonLayer.value)

    // 中心変更
    if (selectedStore.value) {
      await map.value.flyTo({ center: center.value, essential: true })
    }

    // ポリゴンをフィットさせる
    if (geoJsonSource.value?.data.features) {
      fitOverallBounds(geoJsonSource.value.data.features)
    }

    // エリアホバー時のポップアップを表示する
    map.value.on('mousemove', geoJsonLayer.value?.id, (e: any) => {
      handleMouseMoveArea(e)
    })

    // エリアーホバー解除時にポップアップを非表示にする
    map.value.on(
      'mouseleave',
      geoJsonLayer.value?.id,
      debounce(() => {
        handleMouseLeaveArea()
        if (!hoveringPopup.value && !hoveringArea.value) {
          handlePopupClose()
        }
      }, 50)
    )
  }
}

// Mapbox GI JS Map オブジェクトを格納するための Load イベントハンドラ関数
const handleLoad = (event: { map: any; component: any }) => {
  map.value = event.map

  props.onScaleChange(getScale(map.value))
  props.onCenterChange(map.value.getCenter())

  if (props.features.length > 0) {
    updateGeoJson()
  }

  map.value.on('moveend', () => {
    const center: Point = map.value.getCenter()
    const b = map.value.getBounds().toArray()
    bounds.value = {
      xMin: b[0][0],
      xMax: b[1][0],
      yMin: b[0][1],
      yMax: b[1][1]
    }
    props.onCenterChange(center)
    props.onBoundsChange(bounds.value)
  })

  map.value.on('zoomstart', () => {
    props.onZoomStart()
  })

  map.value.on('zoomend', () => {
    props.onZoomEnd()
    props.onScaleChange(getScale(map.value))
  })
}

/* --------------------------------------------------------------------------
  ポップアップ関連
 -------------------------------------------------------------------------- */

// ポップアップの開閉状態
// vue-mapbox の仕様上、true で閉じ、false で開く
const popupShowed = ref<boolean>(true)

// ポップアップの表示内容
// 座標 number[] は [経度, 緯度] で格納
const popupContent = ref<
  | {
      areaName: string
      chainName: string
      color: string
      visitRatio: number
      coordinates: number[]
    }
  | undefined
>(undefined)

const hoveringArea = ref(false)
const hoveringPopup = ref(false)

const handleMouseMoveArea = (e: any) => {
  const layerId = geoJsonLayer.value?.id
  const features = map.value.queryRenderedFeatures(e.point, { layers: [layerId] })

  if (!features.length) return

  const feature = features[0]

  hoveringArea.value = true
  popupContent.value = {
    areaName: feature.properties.areaName,
    chainName: feature.properties.maxChainName,
    color: feature.properties.maxColor,
    visitRatio: feature.properties.maxVisitRatio,
    coordinates: (() => {
      if (feature.properties.areaId === TOKYO_AREA_ID.toString()) {
        return [
          (TOKYO_BBOX_LEFT_DOWN[0] + TOKYO_BBOX_RIGHT_TOP[0]) / 2,
          (TOKYO_BBOX_LEFT_DOWN[1] + TOKYO_BBOX_RIGHT_TOP[1]) / 2
        ]
      } else {
        return [feature.properties.longitude, feature.properties.latitude]
      }
    })()
  }
  popupShowed.value = false
}

const handleMouseLeaveArea = () => {
  hoveringArea.value = false
}

const handleMouseMovePopup = () => {
  hoveringPopup.value = true
}

const handleMouseLeavePopup = () => {
  hoveringPopup.value = false
  handlePopupClose()
}

const handlePopupClose = () => {
  if (!popupContent.value) return

  popupShowed.value = true
  popupContent.value.coordinates = [0, 0]
  popupContent.value.areaName = ''
  popupContent.value.visitRatio = 0.0
}

watch(
  () => props.features,
  () => {
    updateGeoJson()
  },
  { deep: true }
)
</script>

<style scoped>
.map {
  position: relative;
  height: 500px;
  width: 100%;
}

.map-search-bar {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 3;
}

.map-alert {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  margin: auto;
  padding: 0 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 350px;
  height: 83px;
  border: 1px solid var(---666666);
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #666666;
  text-align: left;
  font: normal normal normal 13px/26px Noto Sans JP;
  letter-spacing: 0px;
  color: #333333;
  opacity: 0.85;
}

.scale-change-button {
  position: absolute;
  right: 10px;
  bottom: 40px;
}
</style>
