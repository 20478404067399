<template>
  <div :style="loginWrapMargin">
    <div class="logo-wrap">
      <mieru-logo-login />
    </div>
    <ValidateForm
      ref="loginForm"
      v-slot="{ errors }"
      :validation-schema="schema"
      @submit="onSubmit"
      @invalid-submit="onInvalidSubmit"
    >
      <div>
        <Field
          v-slot="{ field }"
          v-model="ID"
          vid="email"    
          name="ID"      
        >
          <input
            v-bind="field"            
            type="email"
            class="login-input"
            :class="{ 'login-input-error': errors.ID }"
            placeholder="ID（メールアドレス）"
          >
          <p class="error-message">
            <img
              v-show="errors.ID"
              src="@/assets/svg/alert-circle-danger.svg"
              class="error-message-icon"
            >
            {{ errors.ID }}
          </p>
        </Field>
      </div>
      <div>
        <Field
          v-slot="{ field }"
          v-model="password"
          vid="password"
          name="password"      
        >
          <input
            v-bind="field"         
            type="password"
            class="login-input"
            :class="{ 'login-input-error': errors.password }"
            placeholder="パスワード"
          >
          <p class="error-message">
            <img
              v-show="errors.password"
              src="@/assets/svg/alert-circle-danger.svg"
              class="error-message-icon"
            >
            {{ errors.password }}
          </p>
        </Field>
      </div>
      <div style="margin-bottom: 20px">
        <button
          class="button"
          :class="{ 'button-disabled': loading }"
          type="submit"
          block
          :disabled="loading"
        >
          ログイン
        </button>
      </div>
    </ValidateForm>

    <div>
      <router-link
        class="reset-password-link"
        :to="{
          name: 'ResetPassword',
        }"
      >
        パスワードを忘れた場合
      </router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onMounted, onBeforeUnmount } from "vue";
import * as notify from "@/plugins/notification";
import { login } from "@/commons/axios/auth";
import { Field, Form as ValidateForm } from "vee-validate";

import router from "@/router";
import MieruLogoLogin from "@/commons/components/SVG/MieruLogoLogin.vue";
import { AxiosResponse } from "axios";
import { LoginResponse } from "@/commons/types/Auth";
import { sessionCheck } from "@/commons/axios/auth";
import { AuthResponse } from "@/commons/types/Auth";
import { useStore } from "vuex";
import { PRODUCT_FEATURE } from "@/commons/enums";

export default defineComponent({
  name: "LoginView",
  components: {
    MieruLogoLogin,
    ValidateForm,
    Field,
  },
  setup() {
    const store = useStore();
    const ID = ref("");
    const password = ref("");
    const loading = ref(false);
    const innerHeight = ref(0);
    const loginWrapMargin = computed((): string => {
      const top = Math.round(innerHeight.value / 2) - 267;
      return `
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 288.25px;
        margin: ${top <= 0 ? 0 : top}px auto 0 auto;
      `;
    });
    const schema = {
      ID: 'required|email',
      password: 'required|passwordSize',
    };

    onMounted(() => {
      innerHeight.value = window.innerHeight;
      window.addEventListener("resize", calculateWindowWidth);
    });
    onBeforeUnmount(() => {
      window.removeEventListener("resize", calculateWindowWidth);
    });

    const calculateWindowWidth = () => {
      innerHeight.value = window.innerHeight;
    };
    const onSubmit = () => {
      doLogin();
    };
    const onInvalidSubmit = () => {
      notify.notifyErrorMessage("入力内容に不備があります。");
    };
    const doLogin = () => {
      loading.value = true;
      login({ mail: ID.value, password: password.value })
        .then((res: AxiosResponse<LoginResponse>) => {
          store.commit("initState");
          sessionCheck()
            .then((res: AxiosResponse<AuthResponse>) => {
              window.dataLayer.push({
                event: "user_property_push",
                userId: res.data.userId,
                mail: res.data.mail,
                companyName: res.data.companyName,
              });
              store.dispatch("fetchProductFeatures", res.data.productFeatures);
            })
            .finally(() => {
              moveTopPage(res.data);
            });
        })
        .catch(() => {
          notify.notifyErrorMessage("ログインできませんでした。");
        })
        .finally(() => {
          loading.value = false;
        });
    };
    const moveTopPage = async(response: LoginResponse) => {
      store.dispatch("fetchVersion", response.version);
      // 選択可能な期間を取得しそれを基に日付と月の期間を初期化する
      await store.dispatch("fetchPeriod");
      store.dispatch("initDate");
      store.dispatch("initMonth");
      store.dispatch("fetchViewableCompanies");

      notify.notifySuccessMessage("ログインしました");
      if (store.state.productFeatures.includes(PRODUCT_FEATURE.YOKU))
        router.replace({ name: "Dashboard" });
      else
        router.replace({ name: "ChainAnalyticsVisitor" });
    };

    return {
      ID,
      password,
      loading,
      loginWrapMargin,
      schema,
      onSubmit,
      onInvalidSubmit,
    };
  },
});
</script>

<style scoped>
.logo-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 45.94px;
  width: 288.25px;
  height: 44.14px;
  color: #0e182e;
}
.error-message {
  display: flex;
  align-items: center;
  margin: 9px 0 9px;
  height: 10px;
  font-size: 13px;
  font-weight: bold;
  text-align: left;
  color: #be0000;
}
.error-message-icon {
  transform: translateY(1px);
  margin-right: 4px;
}
.login-input {
  width: 280px;
  height: 46px;
  padding: 11.56px 17px 13.44px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  color: #222222;
  font-size: 15px;
}
.login-input::placeholder {
  color: #999999;
}
.login-input:focus {
  outline: 2px solid #999999 !important;
}
.login-input-error {
  outline: 1px solid #be0000;
}
.button {
  width: 280px;
  height: 46px;
  padding: 4.23px 15px 6.77px;
  background-color: #222;
  color: #fff;
  font-size: 15px;
  font-weight: bold;
  border-radius: 4px;
  box-shadow: 1px 1px 0px 0px rgba(0, 0, 0, 0.16);
}
.button-disabled {
  color: #999;
  background-color: #b5b5b5;
  border-color: #b5b5b5;
  box-shadow: none;
}
.reset-password-link {
  font-size: 13px;
  color: #4d99d0;
  text-decoration-line: none;
}
</style>
