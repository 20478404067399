<template>
  <v-dialog
    :value="dialog"
    max-width="500px"
    @click:outside="close"
  >
    <div class="delete-dialog">
      <h1>
        <span v-if="targets.length === 1">
          {{ targets[0] }}を削除してよろしいですか？
        </span>
        <span v-else-if="targets.length >= 2">
          選択中の{{
            type === "compare" ? "リスト" : "条件"
          }}を削除してよろしいですか？
        </span>
      </h1>

      <CustomButton
        variant="danger"
        size="md"
        class="mt-30px"
        width="280px"
        height="46px"
        :disabled="loading"
        @click="$emit('clickDelete')"
      >
        <span v-if="targets.length === 1">
          この{{ type === "compare" ? "リスト" : "条件" }}を削除
        </span>
        <span v-else-if="targets.length >= 2">
          選択中の{{ type === "compare" ? "リスト" : "条件" }}を削除
        </span>
      </CustomButton>
    </div>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export default defineComponent({
  name: "DeleteDialog",

  model: {
    prop: "dialog",
    event: "change-dialog",
  },

  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    targets: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
    type: {
      type: String as PropType<"compare" | "favorite">,
      default: "compare",
    },
  },

  emits: ["clickDelete", "change-dialog"],

  methods: {
    close() {
      this.$emit("change-dialog", false);
    },
  },
});
</script>

<style lang="scss" scoped>
.delete-dialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 76px;
  width: 500px;
  height: 220px;
  border-radius: 6px;
  background: white;

  h1 {
    display: flex;
    justify-content: center;
    font-size: 15px;
    font-weight: bold;
    color: #be0000;
    width: 100%;
  }

  .mb-26px {
    margin-bottom: 26px;
  }

  .mt-30px {
    margin-top: 30px;
  }

  .mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
