import { Feature } from '@/commons/types/GeoJSON'
import { Point } from '@/commons/types/Mapbox'
import { TOKYO_AREA_ID, TOKYO_BBOX_LEFT_DOWN, TOKYO_BBOX_RIGHT_TOP } from '@/commons/enums'

export function getOverallBbox(features: Feature[]): [[number, number], [number, number]] {
  const allPoints: Point[] = []

  features.forEach((feature) => {
    // NOTE: 東京は離島が含まれているので本州のみの座標で判定
    if (feature.properties.areaId.split('-')[0] === TOKYO_AREA_ID.toString()) {
      allPoints.push({ lng: TOKYO_BBOX_LEFT_DOWN[0], lat: TOKYO_BBOX_LEFT_DOWN[1] })
      allPoints.push({ lng: TOKYO_BBOX_RIGHT_TOP[0], lat: TOKYO_BBOX_RIGHT_TOP[1] })
    } else {
      if (feature.geometry.type === 'Polygon') {
        for (const ring of feature.geometry.coordinates as number[][][]) {
          for (const point of ring) {
            allPoints.push({ lng: point[0], lat: point[1] })
          }
        }
      } else if (feature.geometry.type === 'MultiPolygon') {
        for (const polygon of feature.geometry.coordinates as number[][][][]) {
          for (const ring of polygon) {
            for (const point of ring) {
              allPoints.push({ lng: point[0], lat: point[1] })
            }
          }
        }
      }
    }
  })

  const lngs = allPoints.map((point) => point.lng)
  const lats = allPoints.map((point) => point.lat)

  const xmin = getMin(lngs)
  const ymin = getMin(lats)
  const xmax = getMax(lngs)
  const ymax = getMax(lats)

  return [
    [xmin, ymin],
    [xmax, ymax]
  ]
}

function getMin(arr: number[]) {
  let len = arr.length
  let min = Infinity

  while (len--) {
    min = arr[len] < min ? arr[len] : min
  }
  return min
}

function getMax(arr: number[]) {
  let len = arr.length
  let max = -Infinity

  while (len--) {
    max = arr[len] > max ? arr[len] : max
  }
  return max
}
