<template>
  <div>
    <div
      style="margin-bottom: 40px; display: flex; font-size: 15px; font-weight: bold; color: #000"
    >
      {{ legend }}
    </div>
    <table class="heatmap">
      <tr>
        <th width="100" />
        <th>月曜日</th>
        <th>火曜日</th>
        <th>水曜日</th>
        <th>木曜日</th>
        <th>金曜日</th>
        <th>土曜日</th>
        <th>日曜日</th>
      </tr>
      <tr v-for="(item, index) in customHeatmap" :key="index" style="height: 100px">
        <td width="100" class="font-bold">
          {{ item.timeSlot }}
        </td>
        <td :style="item.monday.style">
          <div>
            <b>{{
              isNaN(item.monday.ratio)
                ? '---'
                : (Math.round(item.monday.ratio * 1000) / 10).toFixed(1) + '%'
            }}</b>
          </div>
          <div>
            <b>
              （{{
                isNaN(item.monday.count)
                  ? 'データがありません'
                  : item.monday.count.toLocaleString() + '人'
              }}）
            </b>
          </div>
        </td>
        <td :style="item.tuesday.style">
          <div>
            <b>{{
              isNaN(item.tuesday.ratio)
                ? '---'
                : (Math.round(item.tuesday.ratio * 1000) / 10).toFixed(1) + '%'
            }}</b>
          </div>
          <div>
            <b>
              （{{
                isNaN(item.tuesday.count)
                  ? 'データがありません'
                  : item.tuesday.count.toLocaleString() + '人'
              }}）
            </b>
          </div>
        </td>
        <td :style="item.wednesday.style">
          <div>
            <b>{{
              isNaN(item.wednesday.ratio)
                ? '---'
                : (Math.round(item.wednesday.ratio * 1000) / 10).toFixed(1) + '%'
            }}</b>
          </div>
          <div>
            <b>
              （{{
                isNaN(item.wednesday.count)
                  ? 'データがありません'
                  : item.wednesday.count.toLocaleString() + '人'
              }}）
            </b>
          </div>
        </td>
        <td :style="item.thursday.style">
          <div>
            <b>{{
              isNaN(item.thursday.ratio)
                ? '---'
                : (Math.round(item.thursday.ratio * 1000) / 10).toFixed(1) + '%'
            }}</b>
          </div>
          <div>
            <b>
              （{{
                isNaN(item.thursday.count)
                  ? 'データがありません'
                  : item.thursday.count.toLocaleString() + '人'
              }}）
            </b>
          </div>
        </td>
        <td :style="item.friday.style">
          <div>
            <b>{{
              isNaN(item.friday.ratio)
                ? '---'
                : (Math.round(item.friday.ratio * 1000) / 10).toFixed(1) + '%'
            }}</b>
          </div>
          <div>
            <b>
              （{{
                isNaN(item.friday.count)
                  ? 'データがありません'
                  : item.friday.count.toLocaleString() + '人'
              }}）
            </b>
          </div>
        </td>
        <td :style="item.saturday.style">
          <div>
            <b>{{
              isNaN(item.saturday.ratio)
                ? '---'
                : (Math.round(item.saturday.ratio * 1000) / 10).toFixed(1) + '%'
            }}</b>
          </div>
          <div>
            <b>
              （{{
                isNaN(item.saturday.count)
                  ? 'データがありません'
                  : item.saturday.count.toLocaleString() + '人'
              }}）
            </b>
          </div>
        </td>
        <td :style="item.sunday.style">
          <div>
            <b>{{
              isNaN(item.sunday.ratio)
                ? '---'
                : (Math.round(item.sunday.ratio * 1000) / 10).toFixed(1) + '%'
            }}</b>
          </div>
          <div>
            <b>
              （{{
                isNaN(item.sunday.count)
                  ? 'データがありません'
                  : item.sunday.count.toLocaleString() + '人'
              }}）
            </b>
          </div>
        </td>
      </tr>
    </table>
    <v-row class="my-0" dense>
      <v-col>
        <div class="my-0" style="display: flex; align-items: center; font-size: 12px; color: #666">
          <v-spacer />
          <alert-caption v-if="isAlert" variants="square" />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { WEEK, WeekHourHeatMapItem } from '@/features/ShopAnalytics/interfaces/response'
import type { PropType } from 'vue'

interface DayElement {
  count: number
  ratio: number
  style: string
}
interface RenderingHeatMap {
  timeSlot: string
  monday: DayElement
  tuesday: DayElement
  wednesday: DayElement
  thursday: DayElement
  friday: DayElement
  saturday: DayElement
  sunday: DayElement
}

// Props
const props = defineProps({
  legend: {
    type: String,
    default: undefined
  },
  heatmap: {
    type: Array as PropType<WeekHourHeatMapItem[]>,
    required: true
  },
  isAlert: Boolean
})

const customHeatmap = computed(() => {
  let maxValue = 0
  props.heatmap.forEach((item: WeekHourHeatMapItem) => {
    const days: (typeof WEEK)[keyof typeof WEEK][] = [
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
      'sunday'
    ]
    days.forEach((day) => {
      if (maxValue <= item[day].averageCount) maxValue = item[day].averageCount
    })
  })
  const tmpArray: RenderingHeatMap[] = props.heatmap.map((item: WeekHourHeatMapItem) => {
    const makeDayElement = (day: (typeof WEEK)[keyof typeof WEEK]) => ({
      count: item[day].averageCount,
      ratio: item[day].averageRatio,
      style: setHeatMapColorClass(item[day].averageCount, maxValue, item.alerts.includes(day))
    })
    return {
      timeSlot: item.timeSlot,
      monday: makeDayElement('monday'),
      tuesday: makeDayElement('tuesday'),
      wednesday: makeDayElement('wednesday'),
      thursday: makeDayElement('thursday'),
      friday: makeDayElement('friday'),
      saturday: makeDayElement('saturday'),
      sunday: makeDayElement('sunday')
    }
  })
  return tmpArray
})

function setHeatMapColorClass(value: number, maxValue: number, isAlert: boolean): string {
  if (isAlert) return 'background: #bbbbbb'

  const rate = Number((value / maxValue).toFixed(2))
  if (rate <= 0) return 'background: #ffffff'
  if (rate < 0.1) return 'background: #EFC3C2; color: #ffffff;'
  if (rate < 0.2) return 'background: #E9B2B2; color: #ffffff;'
  if (rate < 0.3) return 'background: #E4A1A4; color: #ffffff;'
  if (rate < 0.4) return 'background: #DC8E93; color: #ffffff;'
  if (rate < 0.5) return 'background: #D67C84; color: #ffffff;'
  if (rate < 0.6) return 'background: #D67C84; color: #ffffff;'
  if (rate < 0.7) return 'background: #CA5966; color: #ffffff;'
  if (rate < 0.8) return 'background: #C44756; color: #ffffff;'
  if (rate < 0.9) return 'background: #BD3547; color: #ffffff;'
  if (rate < 1.0) return 'background: #B82438; color: #ffffff;'

  return 'background: #B11229; color: #ffffff;'
}
</script>

<style scoped>
.heatmap {
  margin-bottom: 15px;
  width: 100%;
  font-size: 12px;
  border: 1px solid #fff;
  border-collapse: collapse;
  table-layout: fixed;
}
.heatmap tr {
  border: 1px solid #fff;
  border-collapse: collapse;
}

.heatmap td {
  border: 1px solid #fff;
  border-collapse: collapse;
}

.heatmap td > div {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.font-bold {
  font-weight: bold;
}

.square {
  display: inline-block;
  margin-right: 6px;
  width: 10px;
  height: 10px;
  border: 2px solid #666;
  background: #bbbbbb;
}
</style>
