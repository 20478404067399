<template>
  <div class="favorite-create">
    <div class="side-bar">
      <div class="heading">
        <div>条件の作成</div>
        <v-divider class="mx-2" />
      </div>
      <div class="nav">
        <router-link
          :to="{ name: 'FavoriteList' }"
          class="nav-link"
        >
          一覧に戻る
        </router-link>
      </div>
    </div>
    <div class="main">
      <h1>条件の作成</h1>
      <p>
        検索条件に該当する店舗のリストが作成できます。よく検索に使う条件を登録してください。
      </p>

      <article class="result-card">
        <header>
          <h1>表示中の条件</h1>
          <button
            class="update"
            @click="conditionDialog = true"
          >
            <img
              class="update-img"
              src="@/assets/svg/cached.svg"
            >
            条件を変更
          </button>
        </header>
        <FavoriteConditionCard :favorite="selectedFavorite" />

        <header>
          <h1>この条件に含まれる店舗</h1>
          <span class="count">
            {{ filteredStores.length }} 店舗 見つかりました
          </span>
        </header>
        <FavoriteStoreTable :stores="filteredStores" />

        <CustomButton
          variant="primary"
          size="md"
          width="360px"
          height="46px"
          class="custom-button"
          :disabled="filteredStores.length === 0"
          @click="createDialog = true"
        >
          この内容で条件を作成
        </CustomButton>
      </article>
    </div>
    <FavoriteConditionDialog
      v-model="conditionDialog"
      :favorite="selectedFavorite"
      @click-search="clickSearch($event)"
    />
    <CreateDialog
      v-model="createDialog"
      :loading="loading"
      type="favorite"
      @click-create="clickCreate($event)"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from "vue";
import FavoriteStoreTable from "@/features/Dashboard/components/FavoriteStoreTable.vue";
import FavoriteConditionCard from "@/features/Dashboard/components/FavoriteConditionCard.vue";
import FavoriteConditionDialog from "@/features/Dashboard/components/FavoriteConditionDialog.vue";
import CreateDialog from "@/features/Dashboard/components/CreateDialog.vue";
import { Favorite } from "@/features/Dashboard/types";
import { Store } from "@/commons/interfaces/responses/store";
import { filterStoresByFavorite } from "@/features/Dashboard/utils";
import { postStoresFavorites } from "@/features/Dashboard/axios";
import * as notify from "@/plugins/notification";
import { useStore } from "vuex";
import { useRouter} from 'vue-router';
import { deserializeBase64 } from "@/commons/utils";

export default defineComponent({
  name: "FavoriteCreate",
  components: {
    FavoriteStoreTable,
    FavoriteConditionCard,
    FavoriteConditionDialog,
    CreateDialog,
  },
  props: {
    favorite: {
      type: String,
      default: undefined,
    },
  },
  setup(props) {
    const router = useRouter();
    const store = useStore();
    const loading = ref(false);
    const selectedFavorite = ref<Favorite>();
    const conditionDialog = ref(false);
    const createDialog = ref(false);

    const filteredStores = computed((): Store[] => {      
      if (!selectedFavorite.value) return [];
      return filterStoresByFavorite({
        stores: store.state.stores,
        favorite: selectedFavorite.value,
      });
    });
    const created = async () => {
      loading.value = true;
      if (props.favorite) selectedFavorite.value = deserializeBase64(props.favorite) as Favorite;
      
      // 店舗一覧
      if (!store.state.stores.length)
        await store.dispatch("fetchStores");
      // 選択可能な店舗形態一覧
      if (!store.state.storeTypes.length)
        await store.dispatch("fetchStoreTypes");
      // 都道府県一覧
      if (!store.state.prefectures.length)
        await store.dispatch("fetchPrefectures");
      // 市区町村一覧
      if (!store.state.cities.length)
        await store.dispatch("fetchCities");
      
      loading.value = false;
    };
    const clickSearch = (event: { favorite: Favorite }) => {
      selectedFavorite.value = event.favorite;
      conditionDialog.value = false;
    };
    const clickCreate = async (event: { name: string }) => {
      loading.value = true;
      await postStoresFavorites({
        name: event.name.length ? event.name : "無題のお気に入り条件",
        prefectureIds: selectedFavorite.value?.prefectureIds ?? [],
        cityIds: selectedFavorite.value?.cityIds ?? [],
        chainWords: selectedFavorite.value?.chainWords ?? [],
        storeTypes: selectedFavorite.value?.storeTypes ?? [],
        storeNameWords: selectedFavorite.value?.storeNameWords ?? [],
      })
        .then(() => {
          return router.push({ name: "FavoriteList" });
        })
        .catch(() => {
          notify.notifyErrorMessage("お気に入り条件が作成できませんでした。");
        });
      loading.value = false;
    };

    created();

    return {
      loading,
      selectedFavorite,
      conditionDialog,
      createDialog,
      filteredStores,
      clickSearch,
      clickCreate,
    };
  },
});
</script>

<style lang="scss" scoped>
.favorite-create {
  display: flex;
  margin-top: 32.5px;

  .side-bar {
    display: flex;
    flex-direction: column;
    min-width: 206.5px;
    height: 80px;

    .heading {
      display: flex;
      align-items: center;
      width: 100%;
      height: 35px;
      font-size: 14px;
      color: #888;
    }

    .nav {
      margin-top: auto;
      text-align: left;

      .nav-link {
        text-decoration: none;
        font-size: 14px;
        color: #222222;
      }
    }
  }

  .main {
    margin-left: 33.5px;
    width: 100%;

    h1 {
      margin-bottom: 12px;
      text-align: left;
      font-size: 24px;
      font-weight: bold;
      color: #222222;
    }

    p {
      margin-bottom: 34px;
      text-align: left;
      font-size: 14px;
      color: #222222;
    }

    .result-card {
      display: flex;
      flex-direction: column;
      padding: 30px;
      background-color: #ffffff;
      box-shadow: 0px 0px 3px #00000029;

      header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        h1 {
          margin: 0;
          font-size: 15px;
          font-weight: bold;
          color: #222222;
        }

        .update {
          display: flex;
          align-items: center;

          text-align: right;
          font: normal normal normal 14px/28px Noto Sans JP;
          letter-spacing: 0px;
          color: #4d99d0;

          .update-img {
            margin-right: 5px;
          }
        }

        .count {
          font-size: 15px;
          font-weight: bold;
          color: #ebac00;
        }
      }

      .custom-button {
        margin: 0 auto;
      }
    }
  }

  .ml-10px {
    margin-left: 10px;
  }

  .mrb-8px {
    margin-right: 8px;
    margin-bottom: 7px;
  }

  .mb-69px {
    margin-bottom: 69px;
  }

  .justify-center {
    justify-content: center;
  }

  .justify-between {
    justify-content: space-between;
  }
}
</style>
