<template>
  <v-card height="'650px'">
    <v-container
      class="pa-0"
      fluid
    >
      <v-row
        class="d-flex justify-space-between my-0"
        dense
        no-gutters
      >
        <v-col
          class="d-flex align-center text-left"
          style="margin-top: 9px; margin-bottom: 30px"
        >
          <v-btn-toggle
            v-model.number="childParticle"
            mandatory
            :divided="true"
            density="compact"
          >
            <v-btn
              max-height="35px"
              min-height="35px"
              max-width="35px"
              min-width="35px"
              :value="COMPARE_PARTICLE_TYPE.DATE"
              :border="true"
            >
              <span style="font-size: 12px">日</span>
            </v-btn>
            <v-btn
              max-height="35px"
              min-height="35px"
              max-width="35px"
              min-width="35px"
              :value="COMPARE_PARTICLE_TYPE.WEEK"
              :border="true"
            >
              <span style="font-size: 12px">週</span>
            </v-btn>
            <v-btn
              max-height="35px"
              min-height="35px"
              max-width="35px"
              min-width="35px"
              :value="COMPARE_PARTICLE_TYPE.MONTH"
              :border="true"
            >
              <span style="font-size: 12px">月</span>
            </v-btn>
            <v-btn
              max-height="35px"
              min-height="35px"
              max-width="70px"
              min-width="70px"
              :value="COMPARE_PARTICLE_TYPE.MONTHLY_RATE"
              :border="true"
            >
              <span style="font-size: 12px">月別比率</span>
            </v-btn>
          </v-btn-toggle>
          <alert-tooltip
            v-if="hasAlert"
            class="ml-2"
            text="取得データボリュームが少なく統計上の信頼性が低い日が含まれています。該当日は参考値としてご参照ください。"
          />
          <v-spacer />
          <chart-description-tooltip
            menu-key="compare"
            sub-menu-key="visitors"
            chart-key="visitors"
          />
          <download-button
            label="CSVデータ"
            :disabled="!$store.state.selectedComparisonGroup"
            :get-file-id="getVisitCountFileId"
            :csv-name="visitCountCSVName"
            class="download-button"
          />
        </v-col>
      </v-row>
      <v-row
        class="my-0"
        dense
      >
        <v-col class="py-0">
          <div
            v-show="
              $store.getters.selectedComparisonGroupVisibleStores
                .length === 0
            "
            class="unselected_card"
          >
            店舗を選択するとチャートが表示されます。
          </div>
          <div
            v-show="
              $store.getters.selectedComparisonGroupVisibleStores.length >
                0
            "
          >
            <loadingImg
              v-if="chartLoading"
              :height="'580px'"
            />
            <no-data-chart v-else-if="chartData.length <= 1" />
            <div v-else>
              <column-chart
                v-if="particle === COMPARE_PARTICLE_TYPE.MONTHLY_RATE"
                :chart-data="chartData"
                :colors="colors"
                :legends="legends"
                is-stacked="percent"
              />
              <line-chart
                v-else
                :chart-data="chartData"
                :legends="legends"
                :colors="colors"
              />
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import LineChart from "@/features/StoreCompare/visitor/components/LineChart.vue";
import NoDataChart from "@/features/ShopAnalytics/components/Common/noDataChart.vue";
// TODO: commons に移動
import ColumnChart from "@/features/ShopAnalytics/components/Common/columnChart.vue";
import { COMPARE_PARTICLE_TYPE } from "@/features/StoreCompare/visitor/enum";
import LoadingImg from "@/commons/components/loadingImg.vue";
import { AxiosResponse } from "axios";
import { downloadMonthlyVisitRateCSV } from "@/features/StoreCompare/visitor/axios";
import { downloadVisitCountChartCSV } from "@/features/StoreCompare/axios";
import { ComparisonGroupStore } from "@/features/StoreCompare/types";
import { convertSlashDelimiter } from "@/commons/utils/dateUtil";

export default defineComponent({
  components: {
    LoadingImg,
    LineChart,
    ColumnChart,
    NoDataChart,
  },
  props: {
    chartData: {
      type: Array,
      default: () => [],
    },
    chartLoading: {
      type: Boolean,
    },
    legends: {
      type: Array as PropType<{ text: string; color: string }[]>,
      default: () => [],
    },
    hasAlert: {
      type: Boolean,
    },
    downloadLoading: {
      type: Boolean,
    },
    particle: {
      type: Number,
      default: undefined,
    },
    clickCSV: {
      type: Function as PropType<() => void>,
      default: undefined,
    },
    colors: {
      type: Array as PropType<string[]>,
      default: () => [],
    },
  },
  emits: ["update:particle"],
  data() {
    return {
      COMPARE_PARTICLE_TYPE: COMPARE_PARTICLE_TYPE,
    };
  },
  computed: {
    childParticle: {
      get() {
        return (this as any).particle;
      },
      set(val: any) {
        return this.$emit("update:particle", val);
      },
    },
    start(): string | undefined {
      switch (this.particle) {
        case COMPARE_PARTICLE_TYPE.DATE:
          return (this as any).$store.state.startDate as string;
        case COMPARE_PARTICLE_TYPE.WEEK:
          return (this as any).$store.getters.startDateOfWeek as string;
        case COMPARE_PARTICLE_TYPE.MONTH:
          return (this as any).$store.getters.startDateOfMonth as string;
        case COMPARE_PARTICLE_TYPE.MONTHLY_RATE:
          return (this as any).$store.getters.startDateOfMonth as string;
        default:
          return void 0;
      }
    },
    end(): string | undefined {
      switch (this.particle) {
        case COMPARE_PARTICLE_TYPE.DATE:
          return (this as any).$store.state.endDate as string;
        case COMPARE_PARTICLE_TYPE.WEEK:
          return (this as any).$store.getters.endDateOfWeek as string;
        case COMPARE_PARTICLE_TYPE.MONTH:
          return (this as any).$store.getters.endDateOfMonth as string;
        case COMPARE_PARTICLE_TYPE.MONTHLY_RATE:
          return (this as any).$store.getters.endDateOfMonth as string;
        default:
          return void 0;
      }
    },
    getVisitCountFileId(): () => Promise<AxiosResponse<any, any>> {
      return () => {
        if (this.particle === COMPARE_PARTICLE_TYPE.MONTHLY_RATE)
          return downloadMonthlyVisitRateCSV(
            (
              this as any
            ).$store.getters.selectedComparisonGroupVisibleStores.map(
              (s: ComparisonGroupStore) => s.id
            ),
            (this as any).start,
            (this as any).end
          );
        else
          return downloadVisitCountChartCSV(
            (
              this as any
            ).$store.getters.selectedComparisonGroupVisibleStores.map(
              (s: ComparisonGroupStore) => s.id
            ),
            (this as any).particle,
            (this as any).start,
            (this as any).end
          );
      };
    },
    visitCountCSVName(): string {
      const { start, end } = {
        start: convertSlashDelimiter((this as any).start),
        end: convertSlashDelimiter((this as any).end),
      };
      return `店舗比較_来店人数推移_${start}-${end}`;
    },
  },
});
</script>

<style lang="scss" scoped>
.v-btn {
  color: rgba(0, 0, 0, 0.87) !important;
  background: #f5f5f5 !important;
  opacity: 0.8 !important;
}
.v-btn--active {
  color: #fff !important;
  background: #0e182e !important;
  opacity: 1 !important;
}
.download-button {
  display: flex;
  align-items: center;
  margin-left: 29.4px;
}
.mr-27px {
  margin-right: 27px;
}
</style>
