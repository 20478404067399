import { Store } from '@/commons/interfaces/responses/store'
import { Prefecture, PrefectureWithStoreCount } from '@/features/Dashboard/types'

/**
 * 店舗一覧と都道府県から各都道府県の店舗数を追加した都道府県データを返す関数
 *
 * **Usage**
 *
 * ```ts
 * const prefecturesWithStoreCount = getPrefecturesWithStoreCount(prefectures, stores)
 * ```
 *
 * **Details**
 *
 * この関数は、都道府県のリストと店舗のリストを受け取り、それぞれの都道府県に対してその都道府県内の店舗数をカウントして追加した新しいリストを返します。都道府県ごとに店舗数を集計して、それを元の都道府県のデータに追加することで、「PrefectureWithStoreCount」型の配列を生成します。この型は元のPrefecture型にstoreCount（店舗数）プロパティが追加されたものです。
 *
 */
export function getPrefecturesWithStoreCount(
  prefectures: Prefecture[],
  stores: Store[]
): PrefectureWithStoreCount[] {
  // 店舗数を集計するマップを作成
  const storeCountMap = stores.reduce<{ [key: string]: number }>((acc, store) => {
    acc[store.prefectureId] = (acc[store.prefectureId] || 0) + 1
    return acc
  }, {})

  // 都道府県データに店舗数を追加
  return prefectures.map((prefecture) => ({
    ...prefecture,
    storeCount: storeCountMap[prefecture.prefectureId] || 0
  }))
}

/**
 * 都道府県IDから都道府県名を取得するためのマッピングオブジェクトを返す関数
 *
 * **Usage**
 *
 * ```ts
 * const prefectures = [
 *   { prefectureId: 1, name: "北海道" },
 *   { prefectureId: 2, name: "青森県" }
 *   // ...
 * ]
 *
 * const prefectureMap = getPrefectureMap(prefectures)
 *
 * console.log(prefectureMap)    // { 1: { prefectureId: 1, name: "北海道" }, 2: { prefectureId: 2, name: "青森県" }, ... }
 * console.lop(prefectureMap[1]) // { prefectureId: 1, name: "北海道" }
 * console.lop(prefectureMap[2]) // { prefectureId: 2, name: "青森県" }
 * ```
 *
 * **Details**
 *
 * この関数は、都道府県のデータを含む配列から、都道府県IDをキーとし、対応する都道府県オブジェクトを値とするオブジェクトマップを生成します。都道府県データは`{ prefectureId: number, name: string }`の形式であり、`prefectureId`は都道府県の一意の識別子、`name`は都道府県の名前です。このマップを使用することで、都道府県IDから直接、都道府県名を簡単に参照できます。
 *
 */
export function getPrefectureMap(prefectures: Prefecture[]): { [key: number]: Prefecture } {
  const prefectureMap = prefectures.reduce<{ [key: number]: Prefecture }>((acc, prefecture) => {
    acc[prefecture.prefectureId] = prefecture
    return acc
  }, {})

  return prefectureMap
}
