<template>
  <v-container class="pa-0" fluid>
    <v-row class="title-container" dense no-gutters>
      <v-col style="display: flex; align-items: center">
        <h1 class="title-text">来店人数推移</h1>
        <update-badge type="week" style="margin-left: 15px" />
      </v-col>
      <v-spacer />
      <date-picker
        :start-date="store.state.startDate"
        :end-date="store.state.endDate"
        :compare-start-date="store.state.compareStartDate"
        :compare-end-date="store.state.compareEndDate"
        @update-period="(period) => setPeriodParams(period)"
      />
    </v-row>
    <v-row dense no-gutters class="card-container">
      <v-col>
        <chain-selector
          :initial-chain-id="chainId"
          :initial-prefecture-ids="prefectureIds.map((id) => Number(id))"
          :handle-update="
            (chain, prefectureIds) =>
              setParams({
                chainId: chain.id,
                prefectureIds: prefectureIds.map((id) => id.toString())
              })
          "
        />
      </v-col>
    </v-row>
    <v-row dense no-gutters class="card-container mb-0">
      <v-col>
        <v-card height="'650px'">
          <v-container class="pa-0" fluid>
            <v-row class="d-flex justify-space-between my-0" dense no-gutters>
              <v-col
                class="d-flex align-center text-left"
                style="margin-top: 9px; margin-bottom: 30px"
              >
                <v-btn-toggle v-model.number="particle" mandatory :divided="true" density="compact">
                  <v-btn
                    max-height="35px"
                    min-height="35px"
                    max-width="35px"
                    min-width="35px"
                    :value="particleType.DATE"
                    :border="true"
                  >
                    <span style="font-size: 12px">日</span>
                  </v-btn>
                  <v-btn
                    max-height="35px"
                    min-height="35px"
                    max-width="35px"
                    min-width="35px"
                    :value="particleType.WEEK"
                    :border="true"
                  >
                    <span style="font-size: 12px">週</span>
                  </v-btn>
                  <v-btn
                    max-height="35px"
                    min-height="35px"
                    max-width="35px"
                    min-width="35px"
                    :value="particleType.MONTH"
                    :border="true"
                  >
                    <span style="font-size: 12px">月</span>
                  </v-btn>
                </v-btn-toggle>
                <alert-tooltip
                  v-if="hasAlertInChartData"
                  class="ml-2"
                  text="取得データボリュームが少なく、統計上の信頼性の低いデータが含まれています。該当箇所は、参考値としてご参照ください。"
                />
                <v-spacer />
                <span v-if="!isComparison" style="font-size: 12px">内訳</span>
                <div v-if="!isComparison" style="margin-left: 10px; width: 140px">
                  <sub-type-selector
                    v-model="subType"
                    style="margin-left: 8px"
                    :items="subTypes"
                    :disabled="isComparison"
                  />
                </div>
                <chart-description-tooltip
                  menu-key="analytics"
                  sub-menu-key="visitors"
                  chart-key="visitors"
                  class="ml-30px"
                />
                <download-button
                  label="CSVデータ"
                  :disabled="!chainId"
                  :get-file-id="getVisitCountFileId"
                  :csv-name="csvName"
                  class="download-button"
                />
              </v-col>
            </v-row>
            <v-row class="my-0" dense>
              <v-col class="py-0">
                <div v-show="!chainId" class="unselected_card">
                  チェーンを選択するとチャートが表示されます。
                </div>
                <div v-show="chainId">
                  <loadingImg v-if="chartLoading" :height="'550px'" />
                  <no-data-chart v-else-if="isNoChartData" />
                  <div v-else>
                    <line-chart
                      v-if="!isComparison"
                      :chart-data="chartData"
                      :sub-type="lineChartSubType"
                      :is-alert="hasAlertInChartData !== false"
                      :legends="lineChartLegends"
                    />
                    <compare-line-chart
                      v-else
                      :chart-data="compareChartData"
                      :is-alert="hasAlertInChartData !== false"
                      :legends="compareLineChartLegends"
                    />
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-container>
          <div class="divider" />
          <v-container class="pa-0" fluid>
            <div v-show="!chainId" class="unselected_card">
              チェーンを選択するとテーブルが表示されます。
            </div>
            <div v-show="chainId">
              <loadingImg v-if="chartLoading" :height="'550px'" />
              <no-data-chart v-else-if="isNoTableData" />
              <div v-else>
                <visitor-table v-if="!isComparison" :total="totalData" :items="tableData" />
                <compare-table v-else :items="compareTableData" />
              </div>
            </div>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
// component
import UpdateBadge from '@/commons/components/Elements/UpdateBadge.vue'
import DatePicker from '@/commons/components/DatePicker/DatePicker.vue'
import ChainSelector from '@/commons/components/ChainSelector/ChainSelector.vue'
import NoDataChart from '@/commons/components/Chart/NoDataChart.vue'
import SubTypeSelector from '@/features/ChainAnalytics/visitor/components/SubTypeSelector.vue'
import LineChart from '@/features/ChainAnalytics/visitor/components/LineChart.vue'
import VisitorTable from '@/features/ChainAnalytics/visitor/components/VisitorTable.vue'
import CompareLineChart from '@/features/ChainAnalytics/visitor/components/CompareLineChart.vue'
import CompareTable from '@/features/ChainAnalytics/visitor/components/CompareTable.vue'
import loadingImg from '@/commons/components/loadingImg.vue'
// util
import { convertSlashDelimiter } from '@/commons/utils/dateUtil'
import * as notify from '@/plugins/notification'
// enum
import { PARTICLE_TYPE, POINT_STYLE, SUB_TYPE } from '@/commons/enums'
import {
  getVisitCountGraph,
  getVisitCountTable,
  getVisitCountComparisonGraph,
  downloadVisitCountCsv,
  downloadVisitCountComparisonCsv
} from '@/features/ChainAnalytics/visitor/axios'
import {
  VisitCountGraph,
  VisitCountTable,
  VisitCountComparisonTable
} from '@/features/ChainAnalytics/visitor/interfaces/response'
import {
  processVisitorChartData,
  processingVisitorTotalData,
  processingVisitorTableData,
  processVisitorCompareChartData,
  getLegends
} from '@/features/ChainAnalytics/visitor/util'
import { AxiosResponse } from 'axios'
import { Chain } from '@/commons/interfaces'
import { getPeriodByRouterQueryPeriod } from '@/commons/utils'
// hooks
import useChainQueryParams from '@/commons/hooks/use-chain-query-params'
import usePeriodQueryParams from '@/commons/hooks/use-period-query-params'

const QUERY_UNIT = {
  DATE: 'date',
  WEEK: 'week',
  MONTH: 'month'
} as const
const QUERY_SUB_INDICATOR = {
  NONE: 'none',
  GENDER: 'gender',
  AGE: 'age'
} as const

const store = useStore()
const route = useRoute()
const router = useRouter()

const created = ref(false)
const chartData = ref<any[]>([])
const compareChartData = ref<any[]>([])
const particleType = ref(PARTICLE_TYPE)
const particle = ref<(typeof PARTICLE_TYPE)[keyof typeof PARTICLE_TYPE]>(PARTICLE_TYPE.DATE)
const chartLoading = ref(false)
const totalData = ref()
const tableData = ref<any[]>([])
const compareTableData = ref<any[]>([])
const tableLoading = ref(false)
const subTypes = [
  { text: 'なし', value: SUB_TYPE.NONE },
  { text: '性別', value: SUB_TYPE.GENDER },
  { text: '年代', value: SUB_TYPE.AGE }
]
// NOTE: Select で使用する SubType。
const subType = ref<(typeof SUB_TYPE)[keyof typeof SUB_TYPE]>(SUB_TYPE.NONE)
// NOTE: LineChart コンポーネントに渡すための SubType。データの更新が完了した時点で更新される。
const lineChartSubType = ref<(typeof SUB_TYPE)[keyof typeof SUB_TYPE]>(SUB_TYPE.NONE)
const { chainId, prefectureIds, setParams } = useChainQueryParams()
const { setPeriodParams } = usePeriodQueryParams()

// computed
const start = computed<string | undefined>(() => {
  switch (particle.value) {
    case PARTICLE_TYPE.DATE:
      return store.state.startDate as string
    case PARTICLE_TYPE.WEEK:
      return store.getters.startDateOfWeek as string
    case PARTICLE_TYPE.MONTH:
      return store.getters.startDateOfMonth as string
    default:
      return void 0
  }
})
const end = computed<string | undefined>(() => {
  switch (particle.value) {
    case PARTICLE_TYPE.DATE:
      return store.state.endDate as string
    case PARTICLE_TYPE.WEEK:
      return store.getters.endDateOfWeek as string
    case PARTICLE_TYPE.MONTH:
      return store.getters.endDateOfMonth as string
    default:
      return void 0
  }
})
const compareStart = computed<string | undefined>(() => {
  switch (particle.value) {
    case PARTICLE_TYPE.DATE:
      return store.state.compareStartDate as string
    case PARTICLE_TYPE.WEEK:
      return store.getters.comparisonStartDateOfWeek as string
    case PARTICLE_TYPE.MONTH:
      return store.getters.comparisonStartDateOfMonth as string
    default:
      return void 0
  }
})
const compareEnd = computed<string | undefined>(() => {
  switch (particle.value) {
    case PARTICLE_TYPE.DATE:
      return store.state.compareEndDate as string
    case PARTICLE_TYPE.WEEK:
      return store.getters.comparisonEndDateOfWeek as string
    case PARTICLE_TYPE.MONTH:
      return store.getters.comparisonEndDateOfMonth as string
    default:
      return void 0
  }
})
const chains = computed<Chain[]>(() => store.getters.chains)
const isComparison = computed<boolean>(() => {
  return store.getters.isComparePeriod
})
const isNoChartData = computed<boolean>(() => {
  if (isComparison.value) {
    return compareChartData.value.length <= 1
  } else {
    return chartData.value.length <= 1
  }
})
const isNoTableData = computed<boolean>(() => {
  if (isComparison.value) {
    return compareTableData.value.length <= 0
  } else {
    return tableData.value.length <= 0
  }
})
const lineChartLegends = computed<{ text: string; color: string }[]>(() => {
  return getLegends(chartData.value, subType.value, isComparison.value)
})
const compareLineChartLegends = computed<{ text: string; color: string }[]>(() => {
  return getLegends(compareChartData.value, subType.value, isComparison.value)
})
const csvName = computed<string>(() => {
  const convertStart = convertSlashDelimiter(start.value)
  const convertEnd = convertSlashDelimiter(end.value)
  const convertCompareStart = convertSlashDelimiter(compareStart.value)
  const convertCompareEnd = convertSlashDelimiter(compareEnd.value)
  return !isComparison.value
    ? `チェーン分析_来店人数_${convertStart}-${convertEnd}`
    : `チェーン分析_来店人数_${convertStart}-${convertEnd}_${convertCompareStart}-${convertCompareEnd}`
})
const hasAlertInChartData = computed(() => {
  if (isComparison.value) {
    return compareChartData.value.some(
      (value) => value[3] === POINT_STYLE.RED['alert'] || value[6] === POINT_STYLE.BLUE['alert']
    )
  } else {
    switch (subType.value) {
      case SUB_TYPE.NONE:
        return chartData.value.some((value) => value[3] === POINT_STYLE.RED['alert'])
      case SUB_TYPE.GENDER:
        return chartData.value.some(
          (value) => value[3] === POINT_STYLE.BLUE['alert'] || value[6] === POINT_STYLE.RED['alert']
        )
      case SUB_TYPE.AGE:
        return chartData.value.some(
          (value) =>
            value[3] === POINT_STYLE.RED['alert'] ||
            value[6] === POINT_STYLE.BLUE['alert'] ||
            value[9] === POINT_STYLE.GREEN['alert'] ||
            value[12] === POINT_STYLE.ORANGE['alert'] ||
            value[15] === POINT_STYLE.PURPLE['alert'] ||
            value[18] === POINT_STYLE.BROWN['alert']
        )
      default:
        return false
    }
  }
})
const getVisitCountFileId = async () => {
  if (!isComparison.value)
    return downloadVisitCountCsv(
      chainId.value,
      start.value,
      end.value,
      particle.value,
      prefectureIds.value.map((n) => parseInt(n))
    )
  else
    return downloadVisitCountComparisonCsv(
      chainId.value,
      [
        { start: start.value, end: end.value },
        { start: compareStart.value, end: compareEnd.value }
      ],
      particle.value,
      prefectureIds.value.map((n) => parseInt(n))
    )
}

function fetchChartData() {
  if (chartLoading.value || typeof start.value === 'undefined' || typeof end.value === 'undefined')
    return
  chartLoading.value = true
  chartData.value.splice(0, 0)

  getVisitCountGraph(
    chainId.value,
    start.value,
    end.value,
    particle.value,
    subType.value,
    prefectureIds.value.map((n) => parseInt(n))
  )
    .then((res: AxiosResponse<VisitCountGraph>) => {
      chartData.value = processVisitorChartData(res.data, particle.value)
      lineChartSubType.value = subType.value
    })
    .catch(() => {
      notify.notifyErrorMessage('来店人数推移が表示できませんでした。')
    })
    .finally(() => {
      chartLoading.value = false
    })
}
function fetchTableData() {
  if (tableLoading.value || typeof start.value === 'undefined' || typeof end.value === 'undefined')
    return
  tableLoading.value = true
  totalData.value = undefined
  tableData.value.splice(0, 0)

  getVisitCountTable(
    chainId.value,
    start.value,
    end.value,
    particle.value,
    prefectureIds.value.map((n) => parseInt(n))
  )
    .then((res: AxiosResponse<VisitCountTable>) => {
      totalData.value = processingVisitorTotalData(res.data)
      tableData.value = processingVisitorTableData(res.data, particle.value)
    })
    .catch(() => {
      notify.notifyErrorMessage('来店人数推移の表が表示できませんでした。')
    })
    .finally(() => {
      tableLoading.value = false
    })
}
function fetchCompareData() {
  if (
    chartLoading.value ||
    typeof start.value === 'undefined' ||
    typeof end.value === 'undefined' ||
    typeof compareStart.value === 'undefined' ||
    typeof compareEnd.value === 'undefined'
  )
    return
  chartLoading.value = true
  compareChartData.value.splice(0, 0)

  const baseChart = getVisitCountGraph(
    chainId.value,
    start.value,
    end.value,
    particle.value,
    SUB_TYPE.NONE,
    prefectureIds.value.map((n) => parseInt(n))
  )
  const compareChart = getVisitCountGraph(
    chainId.value,
    compareStart.value,
    compareEnd.value,
    particle.value,
    SUB_TYPE.NONE,
    prefectureIds.value.map((n) => parseInt(n))
  )
  Promise.all([baseChart, compareChart])
    .then((res: AxiosResponse<VisitCountGraph>[]) => {
      compareChartData.value = processVisitorCompareChartData(
        res[0].data,
        res[1].data,
        particle.value
      )
    })
    .catch(() => {
      notify.notifyErrorMessage('来店人数推移（期間比較）が表示できませんでした。')
    })
    .finally(() => {
      chartLoading.value = false
    })
}
function fetchCompareTableData() {
  if (
    tableLoading.value ||
    typeof start.value === 'undefined' ||
    typeof end.value === 'undefined' ||
    typeof compareStart.value === 'undefined' ||
    typeof compareEnd.value === 'undefined'
  )
    return
  tableLoading.value = true
  tableData.value.splice(0, 0)

  getVisitCountComparisonGraph(
    chainId.value,
    [
      {
        start: start.value,
        end: end.value
      },
      {
        start: compareStart.value,
        end: compareEnd.value
      }
    ],
    particle.value,
    prefectureIds.value.map((n) => parseInt(n))
  )
    .then((res: AxiosResponse<VisitCountComparisonTable>) => {
      // 初期化
      compareTableData.value.length = 0
      if (
        res.data.visitCounts.some((visitCount) => {
          return (
            [
              visitCount.total,
              visitCount.female,
              visitCount.male,
              visitCount.teen,
              visitCount.twenties,
              visitCount.thirties,
              visitCount.forties,
              visitCount.fifties,
              visitCount.sixties
            ] as unknown[]
          ).includes(null)
        })
      )
        throw new Error('来店人数推移の表（期間比較）の値が不正です')

      compareTableData.value.push({
        index: 1,
        shopId: chainId.value,
        shopName: chains.value.find((chain: Chain) => chain.id === chainId.value)?.name,
        baseData: res.data.visitCounts[0],
        compareData: res.data.visitCounts[1]
      })
    })
    .catch(() => {
      notify.notifyErrorMessage('来店人数推移の表（期間比較）が表示できませんでした。')
    })
    .finally(() => {
      tableLoading.value = false
    })
}

async function initFunctions() {
  // クエリパラメータからデータ更新
  if (route.query.unit) {
    if (route.query.unit === QUERY_UNIT.DATE) {
      particle.value = PARTICLE_TYPE.DATE
    } else if (route.query.unit === QUERY_UNIT.WEEK) {
      particle.value = PARTICLE_TYPE.WEEK
    } else if (route.query.unit === QUERY_UNIT.MONTH) {
      particle.value = PARTICLE_TYPE.MONTH
    }
  }

  if (route.query.subIndicator) {
    if (route.query.subIndicator === QUERY_SUB_INDICATOR.NONE) {
      subType.value = SUB_TYPE.NONE
    } else if (route.query.subIndicator === QUERY_SUB_INDICATOR.GENDER) {
      subType.value = SUB_TYPE.GENDER
    } else if (route.query.subIndicator === QUERY_SUB_INDICATOR.AGE) {
      subType.value = SUB_TYPE.AGE
    }
  }

  if (route.query.period) {
    const tmpDate = getPeriodByRouterQueryPeriod(route.query.period as string)
    if (tmpDate !== undefined) store.commit('setDate', tmpDate)
  }

  if (route.query.c_period) {
    const tmpCDate = getPeriodByRouterQueryPeriod(route.query.c_period as string)
    if (tmpCDate !== undefined) store.commit('setCompareDate', tmpCDate)
  }

  await fetchAll()
  created.value = true
}

async function fetchAll() {
  if (
    !Object.values(PARTICLE_TYPE).includes(particle.value) ||
    typeof start.value === 'undefined' ||
    typeof end.value === 'undefined'
  ) {
    notify.notifyErrorMessage('不正な表示粒度が設定されています。')
    return
  }

  if (chainId.value) {
    if (!isComparison.value) {
      fetchChartData()
      fetchTableData()
    } else {
      fetchCompareData()
      fetchCompareTableData()
    }
  }
}

// watch
watch([chainId, prefectureIds], () => {
  if (chainId.value) {
    fetchAll()
  }
})

watch(particle, () => {
  router.replace({
    name: 'ChainAnalyticsVisitor',
    query: {
      ...route.query,
      unit:
        particle.value === PARTICLE_TYPE.DATE
          ? QUERY_UNIT.DATE
          : particle.value === PARTICLE_TYPE.WEEK
            ? QUERY_UNIT.WEEK
            : QUERY_UNIT.MONTH
    }
  })
})

watch(subType, () => {
  router.replace({
    name: 'ChainAnalyticsVisitor',
    query: {
      ...route.query,
      subIndicator:
        subType.value === SUB_TYPE.NONE
          ? QUERY_SUB_INDICATOR.NONE
          : subType.value === SUB_TYPE.GENDER
            ? QUERY_SUB_INDICATOR.GENDER
            : QUERY_SUB_INDICATOR.AGE
    }
  })
})
// created
initFunctions()
</script>

<style scoped>
.v-btn {
  color: rgba(0, 0, 0, 0.87) !important;
  background: #f5f5f5 !important;
  opacity: 0.8 !important;
}

.v-btn--active {
  color: #fff !important;
  background: #0e182e !important;
  opacity: 1 !important;
}

.divider {
  border-bottom: 5px solid #eee;
  margin: 40px -30px;
}

.download-button {
  display: flex;
  align-items: center;
  margin-left: 29.4px;
}

.ml-30px {
  margin-left: 30px;
}
</style>
