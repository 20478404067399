<template>
  <div>
    <GChart
      type="BarChart"
      :data="ageMaleChartData"
      :options="ageMaleChartOptions()"
    />
    <GChart
      type="BarChart"
      :data="ageFemaleChartData"
      :options="ageFemaleChartOptions()"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { GChart } from "vue-google-charts";

export default defineComponent({
  name: "AgeGenderBarChart",
  components: {
    GChart,
  },
  props: {
    ageMaleChartData: {
      type: Array,
      default() {
        return [];
      },
    },
    ageFemaleChartData: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  methods: {
    ageMaleChartOptions() {
      return {
        areaOpacity: 0.1,
        chartArea: {
          top: 25,
          left: 60,
          right: 25,
          bottom: 0,
        },
        colors: [
          "#4D99D0",
          "#64A6D6",
          "#7BB3DC",
          "#92C0E2",
          "#A9CEE8",
          "#C1DBEF",
        ],
        hAxis: {
          textPosition: "none",
          textStyle: {
            fontSize: 12,
          },
          format: "###%",
          ticks: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0],
        },
        height: 100,
        isStacked: true,
        legend: {
          position: "none",
        },
        bar: { groupWidth: "37.5%" },
        lineWidth: 3,
        pointSize: 6,
        tooltip: {
          isHtml: true,
        },
        vAxis: {
          textStyle: {
            fontSize: 12,
          },
          baselineColor: "#ccc",
          gridlines: {
            color: "#ddd",
          },
          minValue: 0,
        },
      };
    },
    ageFemaleChartOptions() {
      return {
        areaOpacity: 0.1,
        chartArea: {
          top: 0,
          left: 60,
          right: 25,
          bottom: 40,
        },
        colors: [
          "#DE5A69",
          "#E26F7C",
          "#E6848F",
          "#EB9AA3",
          "#EFB0B7",
          "#F4C5CA",
        ],
        hAxis: {
          textStyle: {
            fontSize: 12,
          },
          format: "###%",
          ticks: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0],
        },
        height: 115,
        isStacked: true,
        legend: {
          position: "none",
        },
        bar: { groupWidth: "37.5%" },
        lineWidth: 3,
        pointSize: 6,
        tooltip: {
          isHtml: true,
        },
        vAxis: {
          textStyle: {
            fontSize: 12,
          },
          baselineColor: "#ccc",
          gridlines: {
            color: "#ddd",
          },
          minValue: 0,
        },
      };
    },
  },
});
</script>

<style scoped>
::-webkit-scrollbar {
  -webkit-appearance: none;
  height: 7px;
  width: 7px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
</style>
