<template>
  <v-container class="pa-0" fluid>
    <v-row class="title-container" dense no-gutters>
      <v-col style="display: flex; align-items: center">
        <h1 class="title-text">来店特性</h1>
        <update-badge type="month" style="margin-left: 15px" />
      </v-col>
      <v-spacer />
      <MonthlyDatePicker
        :start-date="startMonth"
        :end-date="endMonth"
        @update-period="updatePeriod"
      />
    </v-row>
    <v-row dense no-gutters class="card-container">
      <v-col>
        <MultiChainSelector
          :initial-chain-ids="chainIds"
          :initial-prefecture-ids="prefectureIds.map((id) => Number(id))"
          :handle-update="
            (chains, prefectureIds) =>
              setParams({
                chainIds: chains.map((chain) => chain.id),
                prefectureIds: prefectureIds.map((id) => id.toString())
              })
          "
        />
      </v-col>
    </v-row>
    <v-row dense no-gutters class="card-container">
      <v-col>
        <chart-card
          :chain-ids="chainIds"
          :prefecture-ids="prefectureIds.map((id) => Number(id))"
          chart-key="visitFrequency"
        />
      </v-col>
    </v-row>
    <v-row dense no-gutters class="card-container">
      <v-col>
        <chart-card
          :chain-ids="chainIds"
          :prefecture-ids="prefectureIds.map((id) => Number(id))"
          chart-key="engagement"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import UpdateBadge from '@/commons/components/Elements/UpdateBadge.vue'
import MonthlyDatePicker from '@/commons/components/DatePicker/MonthlyDatePicker.vue'
import MultiChainSelector from '@/commons/components/ChainSelector/MultiChainSelector.vue'
import ChartCard from '@/features/ChainCompare/visitPattern/components/ChartCard.vue'
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import useChainsQueryParams from '@/commons/hooks/use-chains-query-params'
import usePeriodQueryParams from '@/commons/hooks/use-period-query-params'
import { getPeriodByRouterQueryMonthPeriod } from '@/commons/utils'

const { chainIds, prefectureIds, setParams } = useChainsQueryParams()
const { setPeriodParams } = usePeriodQueryParams()
const updatePeriod = ({ startMonth, endMonth }: { startMonth: string; endMonth: string }) => {
  setPeriodParams({ startMonth, endMonth })
}

const store = useStore()
const route = useRoute()
const startMonth = computed<string>(() => store.state.startMonth)
const endMonth = computed<string>(() => store.state.endMonth)

if (route.query.period) {
  const period = getPeriodByRouterQueryMonthPeriod(route.query.period as string)
  if (period !== undefined) store.commit('setMonth', period)
}
</script>
