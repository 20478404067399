<template>
  <button
    class="download-button"
    :class="{
      'enable-button': !(disabled || loading),
      'disable-button': disabled || loading,
    }"
    :disabled="disabled || loading"
    @click="setFileId"
  >
    <div class="mr-6px">
      <download-icon
        :color="disabled || loading ? '#dcdcdc' : '#4d99d0'"
      />
    </div>
    <span
      :class="{
        'enable-link': !(disabled || loading),
        'disable-link': disabled || loading,
      }"
    >
      {{ label }}
    </span>
  </button>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import DownloadIcon from "@/commons/components/SVG/DownloadIcon.vue";
import { accessToSignedUrl, asyncSleep } from "@/commons/utils";
import { notifyErrorMessage } from "@/plugins/notification";
import { AxiosError, AxiosResponse } from "axios";
import { DownloadResponse } from "@/commons/interfaces/responses/download";
import { downloadFile } from "@/commons/axios/download";

export default defineComponent({
  name: "DownloadButton",
  components: {
    DownloadIcon,
  },
  props: {
    label: {
      type: String,
    },
    disabled: {
      type: Boolean,
    },
    getFileId: {
      type: Function as PropType<
        () => Promise<AxiosResponse<DownloadResponse, any>>
      >,
      //default: undefined,
      required: true
    },
    csvName: {
      type: String, required: true
    },
  },
  data() {
    return {
      loading: false,
      retryCount: {
        fileId: 0,
        download: 0,
      },
      fileId: void 0 as string | undefined,
      signedURL: void 0 as string | undefined,
    };
  },
  methods: {
    initData() {
      this.loading = false;
      this.retryCount.fileId = 0;
      this.retryCount.download = 0;
      this.fileId = void 0;
      this.signedURL = void 0;
    },
    async setFileId() {
      this.loading = true;

      // 5 seconds × retryCount 遅延
      await asyncSleep(this.retryCount.fileId * 5000);
      this.retryCount.fileId++;

      // リトライ回数が10回に到達した時点で中断
      if (this.retryCount.fileId > 10) {
        notifyErrorMessage("CSVファイルがダウンロードできませんでした。");
        this.initData();
        return;
      }

      // FileId を取得
      let status: number | undefined = void 0;
      await this.getFileId()
      .then((res) => {
          if (res.data.file_id) this.fileId = res.data.file_id;
        })
        .catch((error: AxiosError) => {
          status = error.response?.status;
        });

      // 500 が返ってきた時点で中断
      if (status === 500) {
        notifyErrorMessage("CSVファイルがダウンロードできませんでした。");
        this.initData();
        return;
      }

      // リトライ処理
      if (this.fileId === undefined) {
        this.setFileId();
        return;
      }

      // FileId が取得できたら CSV ダウンロード処理を実行
      this.downloadCSV();
    },

    async downloadCSV() {
      // 5 seconds × retryCount 遅延
      await asyncSleep(this.retryCount.download * 5000);
      this.retryCount.download++;

      // signedURL 取得
      let error = false;
      await downloadFile(this.fileId)
        .then((res) => {
          if (res.data.signed_url) this.signedURL = res.data.signed_url;
        })
        .catch(() => {
          error = true;
        });

      // signedURL の取得でエラーが発生した時点で中断
      if (error) {
        notifyErrorMessage("CSVファイルがダウンロードできませんでした。");
        this.initData();
        return;
      }

      // リトライ処理
      if (this.signedURL === undefined) {
        this.downloadCSV();
        return;
      }

      // signedURL が取得できた時点でダウンロードを実行
      accessToSignedUrl(this.signedURL, this.csvName);
      this.initData();
    },
  },
});
</script>

<style lang="scss" scoped>
.download-button {
  display: flex;

  .enable-link {
    color: #4d99d0;
    font: normal normal normal 12px/30px Noto Sans JP;
  }
  .disable-link {
    color: #dcdcdc;
    font: normal normal normal 12px/30px Noto Sans JP;
  }
}
.enable-button {
  cursor: pointer;
}
.disable-button {
  pointer-events: none;
}
.mr-6px {
  margin-right: 6px;
}
</style>
