/**
 * NaNを含む配列から最大値を求める
 * @param values 最大値を求める配列
 * @returns
 */
export const maxWithNaN = (values: number[]) => {
  if (values.length === 0) return NaN;
  if (values.every((v) => isNaN(v))) return NaN;
  return Math.max(...values.filter((v) => !isNaN(v)));
};

/**
 * NaNを含む配列から最小値を求める
 * @param values 最小値を求める配列
 * @returns
 */
export const minWithNaN = (values: number[]) => {
  if (values.length === 0) return NaN;
  if (values.every((v) => isNaN(v))) return NaN;
  return Math.min(...values.filter((v) => !isNaN(v)));
};

/**
 * NaNを含む配列から合計を求める
 * @param values 合計を求める配列
 * @returns
 */
export const sumWithNaN = (values: number[]) => {
  if (values.length === 0) return NaN;
  if (values.every((v) => isNaN(v))) return NaN;
  return values.filter((v) => !isNaN(v)).reduce((a, b) => a + b);
};
