<template>
  <v-container
    class="pa-0"
    fluid
  >
    <v-row
      class="title-container"
      dense
      no-gutters
    >
      <v-col style="display: flex; align-items: center">
        <h1 class="title-text">
          曜日／時間別人数
        </h1>
        <update-badge
          type="week"
          style="margin-left: 15px"
        />
      </v-col>
      <v-spacer />
      <date-picker
        :start-date="$store.state.startDate"
        :end-date="$store.state.endDate"
        :compare-start-date="$store.state.compareStartDate"
        :compare-end-date="$store.state.compareEndDate"
        :enable-comparison="false"
        @update-period="updatePeriod"
      />
    </v-row>
    <v-row
      dense
      no-gutters
      class="card-container"
    >
      <v-col>
        <store-selector :loading="storeLoading" />
      </v-col>
    </v-row>
    <v-row
      dense
      no-gutters
      class="card-container"
    >
      <v-col>
        <share-card
          title="曜日別シェア率"
          :particle="PARTICLE_TYPE.DAY"
          :header="dayChartHeader"
          :chart-data="dayChartData"
          :chart-loading="dayChartLoading"
          :has-alert="hasAlertInDayChartData"
          :get-file-id="getDayChartFileId"
          :csv-name="dayChartCSVName"
        />
      </v-col>
    </v-row>
    <v-row
      dense
      no-gutters
      class="card-container mb-0"
    >
      <v-col>
        <share-card
          title="時間別シェア率"
          :particle="PARTICLE_TYPE.HOUR"
          :header="timeChartHeader"
          :chart-data="timeChartData"
          :chart-loading="timeChartLoading"
          :has-alert="hasAlertInTimeChartData"
          :get-file-id="getTimeChartFileId"
          :csv-name="timeChartCSVName"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script lang="ts">
import { defineComponent } from "vue";
// TODO: commons に寄せる
import DatePicker from "@/features/ShopAnalytics/components/Common/datePicker.vue";
import StoreSelector from "@/features/StoreCompare/components/StoreSelector.vue";
import UpdateBadge from "@/features/ShopAnalytics/components/Common/updateBadge.vue";
import ShareCard from "@/features/StoreCompare/trendVisitor/components/ShareCard.vue";
// TODO: commons に寄せる
import { getVisitCountGraph } from "@/features/ShopAnalytics/axios/visitor";
import { downloadVisitCountChartCSV } from "@/features/StoreCompare/axios";
import { getPeriodByRouterQueryPeriod } from "@/commons/utils";
import {
  convertHyphenDelimiter,
  convertSlashDelimiter,
} from "@/commons/utils/dateUtil";
import {
  ComparisonGroup,
  ComparisonGraph,
  ComparisonGroupStore,
} from "@/features/StoreCompare/types";
import { ChartData } from "@/features/StoreCompare/trendVisitor/types";
import { hasAlertInChartData } from "@/features/StoreCompare/utils";
import {
  processDayChart,
  processTimeChart,
} from "@/features/StoreCompare/trendVisitor/utils";
import { PARTICLE_TYPE, SUB_TYPE } from "@/commons/enums";
import * as notify from "@/plugins/notification";
import { AxiosResponse } from "axios";
import { debounce } from "lodash";

export default defineComponent({
  components: {
    DatePicker,
    StoreSelector,
    ShareCard,
    UpdateBadge,
  },
  data() {
    return {
      created: false,
      storeLoading: false,
      PARTICLE_TYPE: PARTICLE_TYPE,
      dayChartHeader: [
        "",
        "月曜日",
        "火曜日",
        "水曜日",
        "木曜日",
        "金曜日",
        "土曜日",
        "日曜日",
      ],
      dayChartData: [] as ChartData,
      dayChartLoading: false,
      hasAlertInDayChartData: false,
      timeChartHeader: [
        "",
        "08-10時",
        "11-13時",
        "14-16時",
        "17-19時",
        "20-22時",
      ],
      timeChartData: [] as ChartData,
      timeChartLoading: false,
      hasAlertInTimeChartData: false,
      dayChartController: new AbortController(),
      timeChartController: new AbortController(),
    };
  },
  computed: {
    hasRouterQueryPeriod(): boolean {
      return this.$route.query.period !== undefined;
    },
    // NOTE: watch で監視するために定義
    selectedComparisonGroup(): ComparisonGroup {
      return this.$store.state.selectedComparisonGroup;
    },
    selectedStores(): ComparisonGroupStore[] {
      return this.$store.getters.selectedComparisonGroupVisibleStores;
    },
    legends(): { text: string; color: string }[] {
      if (!this.$store.state.selectedComparisonGroup) return [];
      // TODO: 凡例追加
      return [];
    },
    getDayChartFileId(): () => Promise<AxiosResponse<any, any>> {
      return () =>
        downloadVisitCountChartCSV(
          this.selectedStores.map((s) => s.id),
          PARTICLE_TYPE.DAY,
          this.$store.state.startDate,
          this.$store.state.endDate
        );
    },
    getTimeChartFileId(): () => Promise<AxiosResponse<any, any>> {
      return () =>
        downloadVisitCountChartCSV(
          this.selectedStores.map((s) => s.id),
          PARTICLE_TYPE.HOUR,
          this.$store.state.startDate,
          this.$store.state.endDate
        );
    },
    dayChartCSVName(): string {
      const { start, end } = {
        start: convertSlashDelimiter(this.$store.state.startDate),
        end: convertSlashDelimiter(this.$store.state.endDate),
      };
      return `店舗比較_曜日別シェア率_${start}-${end}`;
    },
    timeChartCSVName(): string {
      const { start, end } = {
        start: convertSlashDelimiter(this.$store.state.startDate),
        end: convertSlashDelimiter(this.$store.state.endDate),
      };
      return `店舗比較_時間別シェア率_${start}-${end}`;
    },
  },
  watch: {
    async selectedComparisonGroup() {
      if (this.created) {
        this.dayChartController.abort();
        this.timeChartController.abort();

        await this.fetchDayChart();
        await this.fetchTimeChart();
        await this.pushRoute();
      }
    },
    selectedStores: debounce(async function (this: any) {
      if (this.created) {
        this.dayChartController.abort();
        this.timeChartController.abort();

        await this.fetchDayChart();
        await this.fetchTimeChart();
        await this.pushRoute();
      }
    }, 500),
  },
  async created() {
    // TODO: クエリパラメータからデータ更新
    this.setDateFromRouterQuery();

    this.storeLoading = true;
    // 店舗一覧
    if (!this.$store.state.stores.length)
      await this.$store.dispatch("fetchStores");

    // 比較店舗リスト一覧
    if (!this.$store.state.comparisonGroups.length)
      await this.$store.dispatch("fetchComparisonGroups");

    // id があれば選択比較店舗リストを設定,無ければ初期化
    if (this.$route.params["id"]) {
      await this.$store.dispatch(
        "specifiedComparisonGroup",
        this.$route.params["id"]
      );
      if (!this.selectedStores || this.selectedStores.length === 0)
        this.$router.push({ name: "NotFound" });
    } else this.$store.commit("initComparisonGroup");
    this.storeLoading = false;

    // チャート更新
    await this.fetchDayChart();
    await this.fetchTimeChart();
    await this.pushRoute();

    this.created = true;
  },
  methods: {
    initDayChartData() {
      this.dayChartData.length = 0;
    },
    initTimeChartData() {
      this.timeChartData.length = 0;
    },
    setDateFromRouterQuery() {
      if (this.hasRouterQueryPeriod) {
        let tmp = getPeriodByRouterQueryPeriod(this.$route.query.period as string);
        if (tmp !== undefined) this.$store.commit("setDate", tmp);
      }
    },
    async fetchDayChart() {
      if (!this.selectedComparisonGroup) return;

      this.dayChartLoading = true;
      // チャートデータの初期化
      this.initDayChartData();

      this.dayChartController = new AbortController();
      const graphs: ComparisonGraph[] = [];
      let canceled = false;
      await Promise.all(
        this.selectedStores.map(async (store) => {
          await getVisitCountGraph(
            store.id,
            this.$store.state.startDate,
            this.$store.state.endDate,
            PARTICLE_TYPE["DAY"],
            SUB_TYPE["NONE"],
            this.dayChartController.signal
          )
            .then((res) => {
              graphs.push({
                orderIndex: store.orderIndex,
                storeName: store.name,
                visitCounts: res.data.visitCounts,
                color: store.color ?? "",
              });
            })
            .catch((e) => {
              if (typeof e === "undefined" || e.code !== "ERR_CANCELED") {
                notify.notifyErrorMessage(
                  "曜日別シェア率チャートが表示できませんでした。"
                );
              } else if (e.code === "ERR_CANCELED") {
                canceled = true;
              }
            });
        })
      ).then((f) => {
        if (this.selectedStores.length === f.length && !canceled)
          this.dayChartLoading = false;
      });

      this.dayChartData = processDayChart(graphs);
      this.hasAlertInDayChartData = hasAlertInChartData(graphs);
    },

    async fetchTimeChart() {
      if (!this.selectedComparisonGroup) return;

      this.timeChartLoading = true;
      // チャートデータの初期化
      this.initTimeChartData();

      this.timeChartController = new AbortController();
      const graphs: ComparisonGraph[] = [];
      let canceled = false;
      await Promise.all(
        this.selectedStores.map(async (store) => {
          await getVisitCountGraph(
            store.id,
            this.$store.state.startDate,
            this.$store.state.endDate,
            PARTICLE_TYPE["HOUR"],
            SUB_TYPE["NONE"]
          )
            .then((res) => {
              graphs.push({
                orderIndex: store.orderIndex,
                storeName: store.name,
                visitCounts: res.data.visitCounts,
                color: store.color ?? "",
              });
              /**
               * NOTE: ここで平日チャートデータと土日祝日チャートデータを下記の形式に統合する。
               * [
               *   [店舗1の平日データ],
               *   [店舗1の土日祝日データ],
               *   [店舗2の平日データ],
               *   [店舗2の土日祝日データ], ...
               * ]
               */
              this.timeChartData = processTimeChart(graphs);
            })
            .catch((e) => {
              if (typeof e === "undefined" || e.code !== "ERR_CANCELED") {
                notify.notifyErrorMessage(
                  "時間別シェア率チャートが表示できませんでした。"
                );
              } else if (e.code === "ERR_CANCELED") {
                canceled = true;
              }
            });
        })
      ).then((f) => {
        if (this.selectedStores.length === f.length && !canceled)
          this.timeChartLoading = false;
      });
      this.hasAlertInTimeChartData = hasAlertInChartData(graphs);
    },

    async updatePeriod(period: {
      startDate: string;
      endDate: string;
      compareStartDate: string;
      compareEndDate: string;
    }) {
      this.$store.commit("setDate", {
        startDate: period.startDate,
        endDate: period.endDate,
      });
      this.$store.commit("setCompareDate", {
        startDate: period.compareStartDate,
        endDate: period.compareEndDate,
      });

      await this.fetchDayChart();
      await this.fetchTimeChart();
      await this.pushRoute();
    },

    async pushRoute() {
      if (!this.selectedComparisonGroup) return;

      const tmp = {
        start: convertHyphenDelimiter(this.$store.state.startDate),
        end: convertHyphenDelimiter(this.$store.state.endDate),
      };

      // NOTE: 他ページに遷移後にreplaceが呼び出されると戻される対策
      if (this.$route.name === "StoreCompareTrendVisitor") {
        await this.$router
          .replace({
            name: "StoreCompareTrendVisitor",
            params: { id: this.$store.state.selectedComparisonGroup.id },
            query: { period: `${tmp.start}_${tmp.end}` },
          })
          .catch(() => undefined);
      }
    },
  },
});
</script>
