<template>
  <div class="table-navigation-button">
    <button
      class="previous"
      :class="{ disabled: previousDisabled }"
      :disabled="previousDisabled"
      @click="clickPrevious"
    >
      <img src="@/assets/svg/triangle-left.svg">
    </button>
    <button
      class="next"
      :class="{ disabled: nextDisabled }"
      :disabled="nextDisabled"
      @click="clickNext"
    >
      <img src="@/assets/svg/triangle-right.svg">
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
export default defineComponent({
  props: {
    clickNext: {
      type: Function as PropType<() => void>,
      required: true,
    },
    clickPrevious: {
      type: Function as PropType<() => void>,
      required: true,
    },
    nextDisabled: {
      type: Boolean,
    },
    previousDisabled: {
      type: Boolean,
    },
  },
});
</script>

<style lang="scss" scoped>
.table-navigation-button {
  .previous {
    border: 1px solid #ccc;
    border-right: none;
    height: 30px;
    width: 30px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .next {
    border: 1px solid #ccc;
    height: 30px;
    width: 31px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  .disabled {
    background-color: #cccccc;
  }
}

// TODO: svg 置換後に消す
.mb-1px {
  margin-bottom: 1px;
}
</style>
