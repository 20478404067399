import { ja } from 'vuetify/locale'

import 'vuetify/styles'
import { createVuetify } from 'vuetify'

const vuetify = createVuetify({
  locale: {
    locale: "ja",
    messages: {ja},
  },
  theme: {
    defaultTheme: 'light',
    themes: {
      light: {
        colors: {
          error: "#be0000",
          warning: "#ffb648",
          success: "#4d99d0",
        }        
      },
      dark: {
        colors: {
          error: "#be0000",
          warning: "#ffb648",
          success: "#4d99d0",
        }
      },
    },
  },
});
export default vuetify;