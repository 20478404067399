<template>
  <v-container
    class="view-container"
    fluid
  >
    <div class="view-title">
      <div class="title-text">
        <b>ユーザー情報の編集</b>
      </div>
      <div class="title-sub">
        <router-link
          class="user_setting_link"
          :to="{ name: 'UserSetting' }"
        >
          ユーザー設定 TOPへ
        </router-link>
      </div>
    </div>
    <div class="view-content">
      <div class="description">
        * 印は必須入力事項です。
      </div>
      <form @submit.prevent="edit">
        <div class="form-row">
          <div class="form-title">
            <b>部署名</b>
          </div>
          <custom-input
            v-model="department"
            class="form-input"
            width="100%"
            height="46px"
            min-width="780px"
            placeholder="例）ビーコンバンク事業部"
          />
        </div>
        <div class="form-row">
          <div class="form-title">
            <b>氏名*</b>
          </div>
          <custom-input
            v-model="name"
            class="form-input"
            width="100%"
            height="46px"
            min-width="780px"
            placeholder="例）宇根利 太郎"
          />
        </div>
        <div class="form-row">
          <div class="form-title">
            <b>メールアドレス*（変更不可）</b>
          </div>
          <custom-input
            v-model="mail"
            class="form-input"
            type="email"
            width="100%"
            height="46px"
            min-width="780px"
            placeholder="例）宇根利 太郎"
            readonly="true"
          />
        </div>
        <div class="form-footer">
          <custom-button
            class="button"
            variant="primary"
            width="220px"
            height="46px"
            :diabled="loading"
            @click="edit"
          >
            変更する
          </custom-button>
        </div>
      </form>
    </div>
    <div class="divider" />
    <div class="view-footer">
      <div class="view-footer-head">
        <b>パスワードを変更したい場合</b>
      </div>
      <div class="view-footer-content">
        <div>
          以下のボタンを押すと、ご登録のメールアドレスにパスワード再設定メールが送られます。
        </div>
        <div>
          あらかじめ、shopper-mieru.jpドメインのメールが受け取れるように設定をお願いします。
        </div>
      </div>
      <div class="view-footer-action">
        <custom-button
          class="button"
          variant="primary"
          width="220px"
          height="46px"
          :diabled="loading"
          @click="reset"
        >
          再設定メールを送信
        </custom-button>
      </div>
    </div>
  </v-container>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import * as notify from "@/plugins/notification";
import { getUser, editUser, passwordReset } from "@/features/UserSetting/axios";
import { AxiosResponse, AxiosError } from "axios";
import { UserDetailResponse } from "@/features/UserSetting/interfaces/response";
import { useRouter, useRoute } from 'vue-router'

export default defineComponent({
  name: "UserCreateView",
  setup() {
    const route = useRoute();
    const router = useRouter();
    const loading = ref<boolean>(false);
    const department = ref<string>();
    const name = ref<string>();
    const mail = ref<string>();

    const created = () =>{
      loading.value = true;
      getUser(route.params.id as string)
        .then((res: AxiosResponse<UserDetailResponse>) => {
          name.value = res.data.name;
          mail.value = res.data.mail;
          department.value = res.data.department;
        })
        .catch((err: AxiosError) => {
          notify.notifyErrorMessage("ユーザーの取得に失敗しました。");
          if (err.response?.status === 404)
            router.push({ name: "NotFound" });
        })
        .finally(() => {
          loading.value = false;
        });
    };
    created();

    const edit = () => {
      if (loading.value) return;

      loading.value = true;
      let validate = false;
        
      if (typeof name.value === "undefined" || !name.value) {
        notify.notifyErrorMessage("氏名を入力してください。");
        validate = true;
      }
      if (typeof mail.value === "undefined" || !mail.value) {
        notify.notifyErrorMessage("メールアドレスを入力してください。");
        validate = true;
      } else {
        const regex = new RegExp(
          "^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*.)+[a-zA-Z]{2,}$"
        );
        if (!regex.test(String(mail.value))) {
          notify.notifyErrorMessage("メールアドレスの形式が正しくありません。");
          validate = true;
        }
      }
      if (validate) {
        loading.value = false;
        return;
      }

      editUser(route.params.id as string, String(name.value), department.value)
        .then(() => {
          notify.notifySuccessMessage("ユーザー情報の編集が完了しました。");
          router.push({ name: "UserSetting" });
        })
        .catch(() => {
          notify.notifyErrorMessage("ユーザー情報の編集ができませんでした。");
        })
        .finally(() => {
          loading.value = false;
        });
    };
    const reset = () => {
      loading.value = true;
      passwordReset(String(mail.value))
        .then(() => {
          notify.notifySuccessMessage(
            `${mail.value} 宛に、パスワード再設定メールを送信しました。`
          );
        })
        .finally(() => {
          loading.value = true;
        });
    };
    
    return {
      department,
      name,
      mail,
      loading,
      edit,
      reset,
    };
  },
});
</script>

<style lang="scss" scoped>
.view-container {
  min-width: 1180px;
  margin-top: 30px !important;
  background-color: #ffffff;
  overflow: hidden;
  padding: 40px 60px !important;
  box-shadow: 0px 0px 3px #00000029;
}
.view-title {
  height: 35px;
  display: flex;
  align-items: center;
  .title-text {
    min-width: 925px;
    width: 100%;
    font-size: 24px;
    overflow-wrap: break-word;
    float: left;
  }
  .title-sub {
    display: flex;
    min-width: 120px;
    font-size: 13px;
    align-items: center;
    float: right;
  }
}
.view-content {
  margin: 40px 0px;
  text-align: left;
  .form-row {
    margin-bottom: 30px;
  }
  .description {
    color: #222222;
    font-size: 14px;
    margin-bottom: 30px;
  }
  .form-title {
    color: #222222;
    font-size: 15px;
    letter-spacing: 0px;
    margin-bottom: 10px;
  }
  .form-input {
    padding: 12px 17px;
    font: normal normal normal 15px/20px Noto Sans JP;
  }
  .form-footer {
    text-align: end;
  }
}
.divider {
  border: 1px solid #eee;
  margin: 60px 0px 28px 0px;
}
.view-footer {
  text-align: center;
  letter-spacing: 0px;
  color: #222222;
  .view-footer-head {
    font: normal normal bold 18px/26px Noto Sans JP;
    margin-bottom: 20px;
  }
  .view-footer-content {
    font: normal normal normal 13px/26px Noto Sans JP;
    height: 45px;
    display: flex;
    flex-flow: column;
  }
  .view-footer-action {
    margin-top: 30px;
    margin-bottom: 20px;
  }
}
.user_setting_link {
  text-decoration-line: none;
}
.button {
  font-size: 15px;
}
</style>
