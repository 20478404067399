import { axiosClient } from "@/plugins/axios";
import { AxiosResponse } from "axios";
import { convertHyphenDelimiter } from "@/commons/utils/dateUtil";
import { DownloadResponse } from "@/commons/interfaces/responses/download";
import { MonthlyVisitRateResponse } from "../types";
import qs from "qs";

/**
 * 月別比率の取得
 * @param {string[]} storeIds 対象の店舗IDs
 * @param {string} start 期間開始日(yyyy-MM-dd)
 * @param {string} end 期間終了日(yyyy-MM-dd)
 * @param {AbortSignal} signal APIの中断用オブジェクト
 * @returns
 */
export function getMonthlyVisitRate(
  storeIds: string[],
  start: string,
  end: string,
  signal: AbortSignal
): Promise<AxiosResponse<MonthlyVisitRateResponse>> {
  return axiosClient.get<MonthlyVisitRateResponse>(
    "/stores/chart/monthly-visit-rate/store-comparison/",
    {
      params: {
        store: storeIds,
        start: convertHyphenDelimiter(start),
        end: convertHyphenDelimiter(end),
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
      signal: signal,
    }
  );
}

/**
 * 月別比率DL
 * @param {string[]} storeIds 対象の店舗IDs
 * @param {string} start 期間開始日(yyyy-MM-dd)
 * @param {string} end 期間終了日(yyyy-MM-dd)
 * @returns
 */
export function downloadMonthlyVisitRateCSV(
  storeIds: string[],
  start: string,
  end: string
): Promise<AxiosResponse<DownloadResponse>> {
  return axiosClient.get<DownloadResponse>(
    "/stores/chart/monthly-visit-rate/store-comparison/download/",
    {
      params: {
        store: storeIds,
        start: convertHyphenDelimiter(start),
        end: convertHyphenDelimiter(end),
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    }
  );
}
