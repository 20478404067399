<template>
  <MglMap
    v-if="center"
    :access-token="MAP_ACCESS_TOKEN"
    :map-style="MAP_STYLE"
    :center="center"
    :zoom="initialZoom"
    :attribution-control="false"
    :drag-rotate="false"
    :touch-zoom-rotate="false"
    :pitch-with-rotate="false"
    logo-position="bottom-right"
    class="map"
    @load="handleLoad"
  >
    <!-- ローディング -->
    <v-overlay
      v-if="isGeoJsonLoading || isLoading"
      contained
      :model-value="true"
      class="align-center justify-center"
      :z-index="4"
      :persistent="true"
      :no-click-animation="true"
    >
      <LoadingImg />
    </v-overlay>

    <!-- 検索ボックス -->
    <SearchAutoComplete
      :selected-prefecture-id="selectedPrefectureId"
      :selected-city-ids="selectedCityIds"
      :granularity="granularity"
      :on-region-update="handleRegionUpdate"
      width="480px"
      list-width="150%"
      class="map-search-bar"
    />

    <!-- +/- ズームコントローラー -->
    <MglNavigationControl :show-compass="false" position="top-right" />

    <!-- 縮尺 -->
    <MglScaleControl class="scale-control" position="bottom-left" />

    <!-- 選択中スポット(店舗)のマーカー -->
    <MglMarker v-if="selectedSpot" :coordinates="[selectedSpot.longitude, selectedSpot.latitude]">
      <template #marker>
        <div>
          <img src="@/assets/svg/center-pin.svg" />
        </div>
      </template>
    </MglMarker>

    <!-- ホバー対象のエリア（都道府県・市区町村・町丁目）を表示するためのボックス -->
    <MapInformationBox :text="props.informationText" />
  </MglMap>
</template>

<script setup lang="ts">
import { MglMap, MglMarker, MglScaleControl, MglNavigationControl } from '@/vendor/vue-mapbox/main'
import { MAP_ACCESS_TOKEN, MAP_STYLE } from '@/config'
import { ref, watch } from 'vue'
import { computed } from 'vue'
import {
  COLOR,
  GRANULARITY,
  TOKYO_AREA_ID,
  TOKYO_BBOX_LEFT_DOWN,
  TOKYO_BBOX_RIGHT_TOP
} from '@/commons/enums'
import { Point } from '@/commons/types/Mapbox'
import LoadingImg from '@/commons/components/loadingImg.vue'
import SearchAutoComplete from '@/features/Trend/components/SearchAutoComplete.vue'
import { getTotalBBox } from '@/commons/utils/geojson/filter-geojson'
import { GeoJSON } from '@/commons/types/GeoJSON'
import { asyncSleep } from '@/commons/utils'
import { SearchRegion } from '../types'
import { useStore } from 'vuex'
import MapInformationBox from '@/commons/components/Map/MapInformationBox.vue'

// 都道府県選択時のズームレベル
const PREFECTURE_ZOOM_LEVEL = 4
// 縮尺 (表示領域の縦の距離) がおよそ 40km を示すズームレベル。
// 閾値が 40km なので 40 より少し短めに設定。
const FORTY_KM_ZOOM_LEVEL = 10
// 中心座標の初期値(本土の中心)
const DEFAULT_CENTER = [137.4103805, 38.15155]

/* --------------------------------------------------------------------------
  props
 ---------------------------------------------------------------------------*/

const props = withDefaults(
  defineProps<{
    /** ローディング */
    isLoading: boolean
    /** 表示単位 */
    granularity?: (typeof GRANULARITY)[keyof typeof GRANULARITY]
    /** 縮尺(表示領域の縦の距離(m)) */
    scale?: number
    /** マップに描画する GeoJSON */
    geoJson?: GeoJSON
    /** 選択済みの都道府県 ID  */
    selectedPrefectureId?: number | undefined
    /** 選択済みの市区町村 IDs  */
    selectedCityIds?: string[]
    /** 選択済みの町丁目 IDs */
    selectedTownIds?: string[]
    /** 画面左下に表示するインフォメーションのテキスト */
    informationText?: string
    /** 選択状態の更新 */
    onAreaSelect?: (id: string) => void
    /** カメラの中心座標が変更された時に実行されるイベントハンドラ */
    onCenterChange?: (center: Point) => void
    /** 縮尺(表示領域の縦の距離(m)) が変更された時に実行されるイベントハンドラ */
    onScaleChange?: (scale: number) => void
    /** ズーム開始時に実行されるイベントハンドラ */
    onZoomStart?: () => void
    /** ズーム終了時に実行されるイベントハンドラ */
    onZoomEnd?: () => void
    /** エリアのホバーによって実行されるイベントハンドラ(id は都道府県・市区町村・町丁目 ID) */
    onAreaHover?: (id: string | undefined) => void
  }>(),
  {
    isLoading: false,
    granularity: GRANULARITY.PREFECTURE,
    scale: NaN,
    geoJson: undefined,
    informationText: undefined,
    selectedPrefectureId: undefined,
    selectedCityIds: (): string[] => [],
    selectedTownIds: (): string[] => [],
    onAreaSelect: () => undefined,
    onCenterChange: () => undefined,
    onScaleChange: () => undefined,
    onZoomStart: () => undefined,
    onZoomEnd: () => undefined,
    onAreaHover: () => undefined
  }
)

/* --------------------------------------------------------------------------
  Vuex 
 ---------------------------------------------------------------------------*/

const store = useStore()
const targetTownPoint = computed<Point | undefined>(() => store.state.targetTownPoint)

/* --------------------------------------------------------------------------
  core
 ---------------------------------------------------------------------------*/

// Mapbox GI JS Map オブジェクト
// ※ vue-mapbox 側で any 定義されているため any で定義
const map = ref<any>(null)

// GeoJSON 読み込み用ローディング
const isGeoJsonLoading = ref<boolean>(false)

// マップの中心座標
const center = ref<number[]>(DEFAULT_CENTER)
// スポット（店舗）
const selectedSpot = ref<{ longitude: number; latitude: number } | undefined>(undefined)

// スポット変更イベントハンドラ関数
const handleRegionUpdate = (region: SearchRegion) => {
  if (map.value) {
    center.value = [region.longitude, region.latitude]
    map.value.setZoom(
      props.granularity === GRANULARITY.PREFECTURE ? PREFECTURE_ZOOM_LEVEL : FORTY_KM_ZOOM_LEVEL
    )
  }
  selectedSpot.value = {
    longitude: region.longitude,
    latitude: region.latitude
  }
}

// ズーム初期値（初期は日本地図全域が映る倍率）
const initialZoom = ref<number>(PREFECTURE_ZOOM_LEVEL)

// ホバー中のID（都道府県・市区町村・町丁目）
const hoveredId = ref<string | undefined>()

// Map にポリゴンを表示するための source
const geoJsonSource = ref<
  | undefined
  | {
      type: 'geojson'
      data: GeoJSON
    }
>(undefined)

// Map にポリゴンを表示するための layer
const geoJsonLayer = computed(() => {
  return {
    id: 'area-selector',
    type: 'fill',
    source: geoJsonSource.value,
    layout: {},
    paint: {
      'fill-color': [
        'case',
        ['boolean', ['feature-state', 'selected'], false],
        COLOR.RED, // 選択時の色
        COLOR.BLUE // 未選択時の色
      ],
      'fill-opacity': 0.6,
      'fill-outline-color': '#ffffff'
    }
  }
})

// 最小ズームの設定
const setMinZoom = (level = FORTY_KM_ZOOM_LEVEL) => {
  if (!map.value) return
  map.value.setMinZoom(level)
}

// 最小ズーム設定の解除
const resetMinZoom = () => {
  if (!map.value) return
  map.value.setMinZoom(0)
}

// GeoJson を更新し、ポリゴンレイヤーをアップデートする関数
const updateGeoJson = async (granularity: (typeof GRANULARITY)[keyof typeof GRANULARITY]) => {
  if (!map.value || !props.geoJson) return

  try {
    isGeoJsonLoading.value = true

    geoJsonSource.value = { type: 'geojson', data: props.geoJson }

    // 既に存在する layer, source を削除
    // addLayer でレイヤーを登録すると、その時の ID で source も登録されるので、layer, source 両方削除する際は登録時の ID で削除する。
    const layer = map.value.getLayer(geoJsonLayer.value.id)

    if (layer) {
      map.value.removeLayer(geoJsonLayer.value.id)
      map.value.removeSource(geoJsonLayer.value.id)
    }

    await map.value.addLayer(geoJsonLayer.value)

    // 都道府県の場合はデフォルトを選択された状態にする
    if (granularity === GRANULARITY.PREFECTURE) {
      for (const feature of props.geoJson.features) {
        await map.value.setFeatureState(
          { source: geoJsonLayer.value.id, id: feature.id },
          { selected: true }
        )
      }

      if (props.selectedPrefectureId && props.selectedPrefectureId === TOKYO_AREA_ID) {
        map.value.fitBounds([TOKYO_BBOX_LEFT_DOWN, TOKYO_BBOX_RIGHT_TOP])
      } else {
        const totalBBox = getTotalBBox(props.geoJson.features)
        map.value.fitBounds([
          [totalBBox[0], totalBBox[1]],
          [totalBBox[2], totalBBox[3]]
        ])
      }
    }

    // 市区町村の場合はデフォルトで選択済み市区町村IDに基づいて選択状態を更新
    if (granularity === GRANULARITY.MUNICIPALITIES) {
      for (const feature of props.geoJson.features) {
        await map.value.setFeatureState(
          { source: geoJsonLayer.value.id, id: feature.id },
          { selected: props.selectedCityIds.includes(feature.properties.id) }
        )
      }

      if (props.selectedPrefectureId && props.selectedPrefectureId === TOKYO_AREA_ID) {
        map.value.fitBounds([TOKYO_BBOX_LEFT_DOWN, TOKYO_BBOX_RIGHT_TOP])
      } else {
        const totalBBox = getTotalBBox(props.geoJson.features)
        map.value.fitBounds([
          [totalBBox[0], totalBBox[1]],
          [totalBBox[2], totalBBox[3]]
        ])
      }
    }

    // 町丁目の場合はデフォルトで選択済み町丁目IDに基づいて選択状態を更新
    if (granularity === GRANULARITY.TOWN_AND_AREA) {
      for (const feature of props.geoJson.features) {
        await map.value.setFeatureState(
          { source: geoJsonLayer.value.id, id: feature.id },
          { selected: props.selectedTownIds.includes(feature.properties.id) }
        )
      }
    }
  } finally {
    isGeoJsonLoading.value = false
  }
}

// Mapbox GI JS Map オブジェクトを格納するための Load イベントハンドラ関数
const handleLoad = async (event: { map: any; component: any }) => {
  map.value = event.map

  map.value.on('moveend', () => {
    const currentCenter: Point = map.value.getCenter()
    props.onCenterChange(currentCenter)
  })

  map.value.on('zoomstart', () => {
    props.onZoomStart()
  })

  map.value.on('zoomend', () => {
    props.onZoomEnd()
  })

  // ホバー時にポップオーバーを表示
  map.value.on('mousemove', geoJsonLayer.value.id, (e: any) => {
    if (!e.features?.length) return

    const feature = e.features[0]
    hoveredId.value = feature.properties.id

    if (props.onAreaHover) {
      props.onAreaHover(hoveredId.value)
    }
  })

  // ホバー時にポップオーバーを表示
  map.value.on('mouseleave', geoJsonLayer.value.id, () => {
    hoveredId.value = undefined

    if (props.onAreaHover) {
      props.onAreaHover(hoveredId.value)
    }
  })

  // 選択状態の更新
  map.value.on('click', geoJsonLayer.value.id, (e: any) => {
    // 都道府県は常に選択されている状態のため、選択状態を更新しない
    if (!e.features?.length || props.granularity === GRANULARITY.PREFECTURE) return

    const feature = e.features[0]

    props.onAreaSelect(feature.properties.id)

    map.value.setFeatureState(
      { source: feature.source, id: feature.id },
      { selected: !feature.state?.selected }
    )
  })

  // GeoJson がある場合は描画する
  if (props.geoJson) {
    updateGeoJson(props.granularity)
  }

  // 町丁目のときはズーム
  if (props.granularity === GRANULARITY.TOWN_AND_AREA) {
    map.value.flyTo({
      center: targetTownPoint.value
        ? [targetTownPoint.value.lng, targetTownPoint.value.lat]
        : undefined,
      zoom: FORTY_KM_ZOOM_LEVEL,
      speed: 1.2
    })

    await asyncSleep(1200)
    setMinZoom()
  }
}

// geoJSON が更新されたタイミングでマップに描画
watch(
  () => props.geoJson,
  () => {
    updateGeoJson(props.granularity)
  },
  { deep: true }
)

// selectedCityIds が更新されたタイミングでマップの選択状態を更新
watch(
  () => props.selectedCityIds,
  () => {
    if (!map.value || !props.geoJson) return

    props.geoJson.features.forEach((feature) => {
      map.value.setFeatureState(
        { source: geoJsonLayer.value.id, id: feature.id },
        { selected: props.selectedCityIds.includes(feature.properties.id) }
      )
    })
  },
  { deep: true }
)

// selectedTownIds が更新されたタイミングでマップの選択状態を更新
watch(
  () => props.selectedTownIds,
  () => {
    if (!map.value || !props.geoJson) return

    props.geoJson.features.forEach((feature) => {
      map.value.setFeatureState(
        { source: geoJsonLayer.value.id, id: feature.id },
        { selected: props.selectedTownIds.includes(feature.properties.id) }
      )
    })
  },
  { deep: true }
)

// 表示単位が切り替わったタイミングでズームの有効/無効を切り替える
watch(
  () => props.granularity,
  async () => {
    if (!map.value) return

    if (props.granularity === GRANULARITY.TOWN_AND_AREA) {
      map.value.flyTo({
        center: targetTownPoint.value
          ? [targetTownPoint.value.lng, targetTownPoint.value.lat]
          : undefined,
        zoom: FORTY_KM_ZOOM_LEVEL,
        speed: 1.2
      })

      await asyncSleep(1200)
      setMinZoom()
    } else {
      resetMinZoom()
    }
  }
)
</script>

<style scoped>
.map {
  position: relative;
  height: 100%;
  width: 100%;
}
.map-search-bar {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 3;
}

.map-information-box {
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: 3;
}

.scale-control {
  position: absolute;
  top: 100px;
  left: 10px;
}
.update-button {
  position: absolute;
  right: 10px;
  bottom: 40px;
}
</style>
