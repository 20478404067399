import { COLOR, PARTICLE_TYPE } from "@/commons/enums";

export * from "./processingFetchedData";

/**
 * チャートデータから凡例用データに加工して出力
 * @param chartData チャートのデータ
 * @param isCompare 比較期間があるかどうか
 * @returns { text: string, color: string }[]
 */
export function getLegends(
  chartData: (string | number | any)[][],
  isCompare: boolean,
  chartType?: number
) {
  if (chartData.length === 0) return [];

  if (chartType === PARTICLE_TYPE["HOUR"])
    // [ [ "date", "平日", { "role": "tooltip" }, { "role": "style" },
    //             "土日祝日", { "role": "tooltip" }, { "role": "style" } ]
    return [
      { text: chartData[0][1], color: COLOR.BLUE },
      { text: chartData[0][4], color: COLOR.RED },
    ];

  if (isCompare)
    // [ [ "date", "2022/07/04-2022/07/10", { "role": "tooltip" }, { "role": "style" },
    //             "2022/06/27-2022/07/03", { "role": "tooltip" }, { "role": "style" } ]
    return [
      { text: chartData[0][1], color: COLOR.RED },
      { text: chartData[0][4], color: COLOR.BLUE },
    ];

  // [[ "date", "2022/07/04-2022/07/10", { "role": "tooltip" }, { "role": "style" }] ... ]
  return [{ text: chartData[0][1], color: COLOR.RED }];
}
