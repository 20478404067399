<template>
  <div
    ref="searchPullDown"
    class="selector"
    :class="{ 'selector-disabled': disabled }"
    @click="open = !open"
  >
    <span class="placeholder">
      {{
        $store.state.comparisonGroups.length > 0
          ? "条件を選択"
          : "条件が存在しません"
      }}
    </span>
    <div class="icon-box">
      <img src="@/assets/svg/triangle-down.svg">
    </div>
    <transition name="fade">
      <div
        v-show="open && $store.state.comparisonGroups.length > 0"
        class="list-container"
        :class="{ 'list-container-active': open }"
      >
        <ul>
          <li
            v-for="group in $store.state.comparisonGroups"
            :key="group.id"
            @click="selectItem(group)"
          >
            <span class="name-store">
              <span class="name">{{ group.name }}</span>
              <div class="store">
                （
                <span
                  v-for="(store, index) in sortedStores(group.stores)"
                  :key="store.id"
                >
                  {{ store.name }}
                  <span v-if="index !== group.stores.length - 1">,</span>
                </span>
                ）
              </div>
            </span>
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { ComparisonGroup } from "@/features/StoreCompare/types";

export default defineComponent({
  model: {
    prop: "selectedItem",
    event: "select-item",
  },
  props: {
    selected: { default: null },
    items: { type: Array },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      open: false,
    };
  },
  mounted() {
    window.addEventListener("click", this.close);
  },
  beforeUnmount() {
    window.removeEventListener("click", this.close);
  },
  methods: {
    selectItem(item: ComparisonGroup) {
      this.$store.commit("setComparisonGroup", {
        comparisonGroup: item,
      });
    },
    close(event: any) {
      if (!(this.$refs.searchPullDown as Element).contains(event.target)) {
        this.open = false;
      }
    },
    sortedStores(stores: ComparisonGroup["stores"]): ComparisonGroup["stores"] {
      return [...stores].sort((a, b) => {
        return a.orderIndex - b.orderIndex;
      });
    },
  },
});
</script>

<style scoped>
.selector {
  position: relative;
  display: flex;
  padding: 0;
  width: 100%;
  height: 44px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  background: #ffffff;
  font-size: 14px;
  cursor: pointer;
}

.placeholder {
  padding: 11px 0 13px 15px;
}

.icon-box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  width: 44px;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  outline: 1px solid #cccccc;
  border-radius: 0px 4px 4px 0px;
}

.selector-disabled {
  background: #e5e5e5;
  border-color: #e5e5e5;
  pointer-events: none;
}

.selected-list {
  background: #f5f5f5;
}

.list-container {
  position: absolute;
  top: 0;
  left: 0;
  padding: 14px 16px;
  max-height: 350px;
  width: 100%;
  background: #fff;
  border: 1px solid #cccccc;
  border-radius: 4px;
  overflow-y: auto;
  z-index: 5;
}

.list-container-active {
  outline: 1px solid #cccccc;
}

.list-container ul {
  text-align: left;
  list-style: none;
  padding: 0;
}

.list-container ul li {
  cursor: pointer;
  height: 36px;
  padding: 8px 11px 8px 9px;
}

.list-container ul li:hover {
  background: #f5f5f5;
}

.name-store {
  display: flex;
  align-content: center;
  max-width: 1100px;
  white-space: nowrap;
}

.name-store .name {
  font-weight: bold;
  font-size: 14px;
}

.name-store .store {
  margin-left: 13px;
  font-size: 13px;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* animation */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
