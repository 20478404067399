<template>
  <div
    ref="customSelector"
    class="font selector default-border"
    :class="{
      'selector-disabled': disabled,
      'hover-border': hover,
      'active-border': open,
    }"
    @click="open = !open"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <span>{{ selectedItem?.text }}</span>
    <img
      class="triangle-down"
      src="@/assets/svg/triangle-down.svg"
    >
    <transition name="fade">
      <div
        v-show="open"
        class="list"
      >
        <div
          v-for="item in items"
          :key="item.value"
          style="padding: 8px 10px"
          class="list-item"
          :class="{ 'selected-list': modelValue === item.value }"
          @click="selectItem(item)"
        >
          {{ item.text }}
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

type Item = {
  value: number | string;
  text: string;
};

export default defineComponent({
  props: {
    modelValue: { type: Number, default: undefined },
    items: { type: Array as PropType<Item[]> , required: true},
    disabled: { type: Boolean, default: false },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      open: false,
      hover: false,
    };
  },
  computed: {
    selectedItem(): Item | undefined {
      if (this.modelValue === null) return;
      return this.items.find((value) => value.value === this.modelValue);
    },
  },
  mounted() {
    window.addEventListener("click", this.close);
  },
  beforeUnmount() {
    window.removeEventListener("click", this.close);
  },
  methods: {
    selectItem(item: Item) {
      this.$emit("update:modelValue", item.value);
    },
    close(event: any) {
      if (!(this.$refs.customSelector as Element).contains(event.target)) {
        this.open = false;
      }
    },
  },
});
</script>

<style scoped>
.font {
  font-size: 12px;
  color: #222222;
}

.selector {
  position: relative;
  display: flex;
  align-items: center;
  padding: 3px 5px 5px 10px;
  min-width: 140px;
  height: 35px;
  border-radius: 4px;
  background: #fff;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}

.default-border {
  outline: 1px solid #ccc;
}

.hover-border {
  outline: 1px solid #999;
}

.active-border {
  outline: 2px solid #999;
}

.selector-disabled {
  background: #e5e5e5;
  border-color: #e5e5e5;
  pointer-events: none;
}

.list {
  position: absolute;
  left: 50%;
  top: 40px;
  transform: translateX(-50%);
  min-width: 144px;
  border: 1px solid #ccc;
  border-radius: 4px;
  z-index: 1;
}

.list-item {
  background: #fff;
  transition: all 0.1s ease-in-out;
}

.list-item:hover {
  background: #f5f5f5;
}

.selected-list {
  background: #eaeff2 !important;
}

.triangle-down {
  margin-top: 3px;
  margin-right: 5px;
  margin-left: auto;
}

/* animation */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
