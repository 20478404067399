import moment from "moment";

export const getPeriodByRouterQueryPeriod = (
  value: string | (string | null)[]
) => {
  if (typeof value !== "string") return undefined;

  const tmp = value.split("_");

  if (tmp.length !== 2) return undefined;
  if (!moment(tmp[0], "YYYY-MM-DD").isValid()) return undefined;
  if (!moment(tmp[1], "YYYY-MM-DD").isValid()) return undefined;

  return {
    startDate: moment(tmp[0]).format("YYYY/MM/DD"),
    endDate: moment(tmp[1]).format("YYYY/MM/DD"),
  };
};

export const getPeriodByRouterQueryMonthPeriod = (
  value: string | (string | null)[]
) => {
  if (typeof value !== "string") return undefined;

  const tmp = value.split("_");

  if (tmp.length !== 2) return undefined;
  if (!moment(tmp[0], "YYYY-MM-DD").isValid()) return undefined;
  if (!moment(tmp[1], "YYYY-MM-DD").isValid()) return undefined;

  return {
    startMonth: moment(tmp[0]).format("YYYY/MM/DD"),
    endMonth: moment(tmp[1]).format("YYYY/MM/DD"),
  };
};

export const asyncSleep = (waitTime: number) =>
  new Promise((resolve) => setTimeout(resolve, waitTime));

/**
 * url をもとに CSV ファイルをダウンロードする関数
 *
 * @param signedUrl - ファイル URL
 * @param fileName ダウンロードする際のファイル名
 */
export const accessToSignedUrl = (signedUrl: string, fileName: string) => {
  const url = signedUrl;
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
};

/**
 * 配列をディープコピーする関数
 *
 * @param array - 対象の配列
 * @returns ディープコピーした配列
 */
export const deepCopy = <T>(array: T[]): T[] => {
  return JSON.parse(JSON.stringify(array));
};

/*
 * Base64にシリアライズ
 */
export const serializeBase64 = (jsonableValue: any) => {
  try {
    return btoa(unescape(encodeURIComponent(JSON.stringify(jsonableValue))));
  } catch (e) {
    return undefined;
  }
};

/*
 * Base64からデシリアライズ
 */
export const deserializeBase64 = (base64String: string) => {
  try {
    return JSON.parse(decodeURIComponent(escape(atob(base64String))));
  } catch (e) {
    return undefined;
  }
};
