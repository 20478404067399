<template>
  <div class="favorite-store-table">
    <table>
      <tr class="header">
        <th>店舗名</th>
        <th>業種</th>
        <th>住所</th>
      </tr>
      <tr
        v-for="store in storesSlicedByPage"
        :key="store.storeId"
        class="body"
      >
        <td>{{ store.name }}</td>
        <td>
          <span
            :class="{
              'dot-red': store.storeType === STORE_TYPE.SUPER_MARKET['value'],
              'dot-green': store.storeType === STORE_TYPE.DRUG_STORE['value'],
              'dot-yellow': store.storeType === STORE_TYPE.HOME_CENTER['value'],
              'dot-purple': store.storeType === STORE_TYPE.CVS_STORE['value'],
            }"
          >
            ●
          </span>
          {{ STORE_TYPE.toLocalString(store.storeType) }}
        </td>
        <td>{{ store.address }}</td>
      </tr>
    </table>
    <div class="table-footer">
      <v-spacer />
      <span
        v-if="stores.length !== 0"
        class="caption"
      >
        {{ pageFrom }}-{{ pageTo }} 件 / {{ stores.length }}件
      </span>
      <span
        v-else
        class="caption"
      > 0件 </span>
      <CustomSelector
        v-model="itemsPerPage"
        :items="[
          { text: '20件', value: 20 },
          { text: '40件', value: 40 },
        ]"
        size="xs"
        width="75px"
        height="30px"
        class="ml-8px"
      />
      <TableNavigation
        :click-next="next"
        :click-previous="previous"
        :next-disabled="page * itemsPerPage.value >= stores.length"
        :previous-disabled="page === 1"
        class="ml-15px"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, computed, ref, watch } from "vue";
import TableNavigation from "@/features/Dashboard/components/TableNavigation.vue";
import { Store } from "@/commons/interfaces/responses/store";
import { STORE_TYPE as STORE_TYPE_ENUM } from "@/commons/enums";

export default defineComponent({
  components: {
    TableNavigation,
  },
  props: {
    stores: {
      type: Array as PropType<Store[]>,
      required: true,
    },
  },
  setup(props) {
    const page = ref(1);
    const itemsPerPage = ref({ text: "20件", value: 20 });
    const STORE_TYPE = STORE_TYPE_ENUM;

    const storesSlicedByPage = computed((): Store[] => {
      return props.stores.slice(
        (page.value - 1) * itemsPerPage.value.value,
        page.value * itemsPerPage.value.value
      );
    });
    const pageFrom = computed((): number => {
      if (page.value === 1) return 1;
      return (page.value - 1) * itemsPerPage.value.value + 1;
    });
    const pageTo = computed((): number => {
      if (page.value * itemsPerPage.value.value >= props.stores.length)
        return props.stores.length;
      return page.value * itemsPerPage.value.value;
    });

    watch(() => props.stores, () => {
      page.value = 1;
    });

    watch(itemsPerPage, () => {
      page.value = 1;
    });

    const next = () => {
      if (page.value * itemsPerPage.value.value < props.stores.length) page.value++;
    };
    const previous = () => {
      if (page.value !== 1) page.value--;
    };

    return {
      page,
      itemsPerPage,
      STORE_TYPE,
      storesSlicedByPage,
      pageFrom,
      pageTo,
      next,
      previous,
    };
  },
});
</script>

<style lang="scss" scoped>
.favorite-store-table {
  table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #cccccc;

    .header {
      background-color: #f5f5f5;
      color: #222222;
      height: 46px;
      font-size: 12px;
      border: 1px solid #cccccc;

      th {
        border: 1px solid #cccccc;
      }

      th:first-child {
        padding-left: 10px;
        min-width: 340px;
        text-align: left;
      }
      th:nth-child(2) {
        min-width: 140px;
      }
      th:nth-child(3) {
        padding-left: 10px;
        min-width: 340px;
        text-align: left;
      }
    }

    .body {
      background-color: #ffffff;
      td {
        padding: 14px 0 15px;
        border: 1px solid #cccccc;
        font-size: 12px;
        color: #222222;
        text-align: left;
      }
      td:first-child {
        padding: 0 10px;
      }
      td:nth-child(2) {
        text-align: center;

        .dot-red {
          color: #e66f73;
        }
        .dot-green {
          color: #87b8a1;
        }
        .dot-yellow {
          color: #cb9e56;
        }
        .dot-purple {
          color: #9278c3;
        }
      }
      td:nth-child(3) {
        padding: 0 10px;
      }
    }
  }
  .table-footer {
    display: flex;
    align-items: center;
    margin-top: 21.5px;

    .caption {
      font-size: 13px;
      color: #222222;
    }
  }
}

.ml-8px {
  margin-left: 8px;
}

.ml-11px {
  margin-left: 11px;
}

.ml-15px {
  margin-left: 15px;
}
</style>
