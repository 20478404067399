<template>
  <v-data-table
    :items="props.chartItems"
    class="ranking-table"
  >
    <template #headers>
      <tr class="table-head">
        <th colspan="2" class="rank-head">順位</th>
        <th class="name-head">店舗名</th>
        <th class="type-head">業種</th>
        <th class="address-head">エリア名</th>
        <th class="previous-head">前月</th>
        <th class="current-head">当月</th>
      </tr>
    </template>
    <template #body="items">
      <tr
        v-for="(chartItem, index) in items.items"
        :key="chartItem.currentRank"
        class="table-body"
        :class="{
          'top-three': index <= 2
        }"
      >
        <th
          class="rank"
          :class="{
            'first': index === 0,
            'second': index === 1,
            'third': index === 2,
          }"
        >
          {{ chartItem.currentRank }}
        </th>
        <th
          class="rank"
          :class="{
            'up': !chartItem.prevRank || chartItem.currentRank < chartItem.prevRank,
            'down': chartItem.prevRank && chartItem.currentRank > chartItem.prevRank,
            'stable': chartItem.prevRank && chartItem.currentRank === chartItem.prevRank
          }"
        >
        <img
          v-if="!chartItem.prevRank || chartItem.currentRank < chartItem.prevRank"
          src="@/assets/svg/Trend/long-arrow-alt-up.svg"
          width="15"
          height="15"
        />
        <img
          v-else-if="chartItem.currentRank > chartItem.prevRank"
          src="@/assets/svg/Trend/long-arrow-alt-down.svg"
          width="15"
          height="15"
        />
        <img
          v-else
          src="@/assets/svg/Trend/long-arrow-alt-right.svg"
          width="15"
          height="15"
        />
        </th>
        <th class="name">{{ chartItem.name }}</th>
        <th class="type">
          <span
            :class="{
              supermarket: chartItem.storeTypeId === STORE_TYPE.SUPER_MARKET.value,
              drugstore: chartItem.storeTypeId === STORE_TYPE.DRUG_STORE.value,
              homecenter: chartItem.storeTypeId === STORE_TYPE.HOME_CENTER.value,
              cvsstore: chartItem.storeTypeId === STORE_TYPE.CVS_STORE.value,
            }"
          >
            ●
          </span>
          {{ STORE_TYPE.toLocalString(chartItem.storeTypeId) }}
        </th>
        <th
          class="address"
          style="
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          "
        >
          {{ chartItem.address }}
        </th>
        <th class="previous">
          <span v-if="!chartItem.prevRank">ー</span>
          <span v-else>{{ chartItem.prevRank }}位</span>
        </th>
        <th class="current">{{ chartItem.currentRank }}位</th>
      </tr>
    </template>
    <template #bottom />
  </v-data-table>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { TrendStoreChartItem } from '@/features/Trend/TrendStore/interfaces'
import { STORE_TYPE } from '@/commons/enums';

const props = withDefaults(
  defineProps<{
    chartItems: TrendStoreChartItem[],
  }>(), {
    chartItems: () => []
  }
)

const headers = [
  { title: '順位', align: 'center' },
  { title: '店舗名' },
  { title: '業種', align: 'center' },
  { title: 'エリア名' },
  { title: '前月', align: 'center' },
  { title: '当月', align: 'center' }
]

</script>

<style>
.ranking-table .v-table__wrapper{
  overflow-x: hidden;
}
</style>
<style scoped lang="scss">
.v-data-table :deep(table) {
  border-collapse: collapse !important;
  border: 1px solid #ccc !important;
}
.v-data-table :deep(td) {
  border-collapse: collapse !important;
  border: 1px solid #ccc !important;
}
.v-data-table :deep(th) {
  border-collapse: collapse !important;
  border: 1px solid #ccc !important;
  user-select: text !important;
}
.ranking-table {
  font: normal normal bold 13px/50px Noto Sans JP;

  .rank-head {
    min-width: 88px;
    max-width: 88px;
  }
  .name-head, .name {
    min-width: 250px;
    max-width: 250px;
  }
  .type-head, .type {
    min-width: 135px;
    max-width: 135px;
  }
  .address-head, .address {
    min-width: 243px;
    max-width: 243px;
  }
  .previous-head, .current-head, .previous, .current {
    min-width: 70px;
    max-width: 70px;
  }
  .top-three {
    th {
      font: normal normal bold 13px/50px Noto Sans JP;
    }
  }
  .table-head {
    height: 44px;
  
    th {
      border: 1px solid #CCCCCC;
      background: #F5F5F5 0% 0% no-repeat padding-box;
      font: normal normal bold 13px/50px Noto Sans JP;
    }
    .rank-head, .type-head, .previous-head, .current-head {
      text-align: center;
    }
  }
  .table-body {
    height: 44px;
  
    th {
      border: 1px solid #CCCCCC;
      padding: 0.5em 16px;
    }
    .rank, .previous, .current {
      text-align: center;
    }
    .rank {
      width: 44px;
      padding: 0px;
      font: normal normal bold 13px/50px Noto Sans JP;
    }
    .first {
      background: transparent linear-gradient(134deg, #C58B1F 0%, #E8D6AF 52%, #C58B1F 100%) 0% 0% no-repeat padding-box;
    }
    .second {
      background: transparent linear-gradient(135deg, #BABABA 0%, #F0F0F0 52%, #BABABA 100%) 0% 0% no-repeat padding-box;
    }
    .third {
      background: transparent linear-gradient(135deg, #B16A3A 0%, #E1B69E 52%, #B16A3A 100%) 0% 0% no-repeat padding-box;
    }
    .up, .down, .stable {
      font: normal normal normal 13px/50px Font Awesome 6 Free;
    }
    .up {
      background: #fff5f5 0% 0% no-repeat padding-box;
      color: #BE0000;
    }
    .down {
      background: #F4FBFF 0% 0% no-repeat padding-box;
      color: #4D99D0;
    }
    .stable {
      color: #999999;
    }
    .supermarket {
      color: #e47075;
    }
    .drugstore {
      color: #87b8a1;
    }
    .homecenter {
      color: #cb9e56;
    }
    .cvsstore {
      color: #9278c3;
    }
    .name, .address {
      line-height: 2em;
    }
  }
}
</style>
