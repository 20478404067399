<template>
  <div class="history-table">
    <table>
      <tr class="header">
        <th>更新日時</th>
        <th>対象</th>
        <th>条件名・比較リスト名</th>
        <th>更新者</th>
        <th>種別</th>
        <th>更新内容</th>
      </tr>
      <tr
        v-for="history in historiesSlicedByPage"
        :key="history.groupId + history.updated_at"
        class="body"
      >
        <td>{{ convertDateAndTimeSlashDelimiter(history.updated_at) }}</td>
        <td>
          {{
            history.targetId === TARGET.FAVORITE["value"]
              ? TARGET.FAVORITE["text"]
              : history.targetId === TARGET.COMPARE["value"]
                ? TARGET.COMPARE["text"]
                : ""
          }}
        </td>
        <td>
          <span v-if="history.target_deleted">{{ history.groupName }}</span>
          <router-link
            v-else-if="history.targetId === TARGET.FAVORITE['value']"
            :to="{
              name: 'FavoriteEdit',
              params: { id: history.groupId },
            }"
            class="link"
          >
            {{ history.groupName }}
          </router-link>
          <router-link
            v-else-if="history.targetId === TARGET.COMPARE['value']"
            :to="{
              name: 'StoreCompareEdit',
              params: { id: history.groupId },
            }"
            class="link"
          >
            {{ history.groupName }}
          </router-link>
        </td>
        <td>{{ history.updated_by }}</td>
        <td>
          {{
            history.operationId === OPERATION.CREATE["value"]
              ? OPERATION.CREATE["text"]
              : history.operationId === OPERATION.EDIT["value"]
                ? OPERATION.EDIT["text"]
                : history.operationId === OPERATION.DELETE["value"]
                  ? OPERATION.DELETE["text"]
                  : ""
          }}
        </td>
        <td>{{ history.note.length > 0 ? history.note : "ー" }}</td>
      </tr>
    </table>
    <div class="table-footer">
      <v-spacer />
      <span
        v-if="histories.length !== 0"
        class="caption"
      >
        {{ pageFrom }}-{{ pageTo }} 件 / {{ histories.length }}件
      </span>
      <span
        v-else
        class="caption"
      > 0件 </span>
      <CustomSelector
        v-model="itemsPerPage"
        :items="[
          { text: '30件', value: 30 },
          { text: '50件', value: 50 },
        ]"
        size="xs"
        width="75px"
        height="30px"
        class="ml-8px"
      />
      <TableNavigation
        :click-next="next"
        :click-previous="previous"
        :next-disabled="page * itemsPerPage.value >= histories.length"
        :previous-disabled="page === 1"
        class="ml-15px"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, computed, ref, watch   } from "vue";
import TableNavigation from "@/features/Dashboard/components/TableNavigation.vue";
import { ComparisonGroupHistory } from "@/features/Dashboard/types";
import { OPERATION, TARGET } from "@/features/Dashboard/enums";
import { convertDateAndTimeSlashDelimiter as convertDateAndTimeSlashDelimiterUtil } from "@/commons/utils/dateUtil";

export default defineComponent({
  components: { TableNavigation },
  props: {
    histories: { type: Array as PropType<ComparisonGroupHistory[]>, required: true },
  },
  setup(props) {
    const page = ref(1);
    const itemsPerPage = ref({ text: "30件", value: 30 });

    const historiesSlicedByPage = computed((): ComparisonGroupHistory[] => {
      return props.histories.slice(
        (page.value - 1) * itemsPerPage.value.value,
        page.value * itemsPerPage.value.value
      );
    });
    const pageFrom = computed((): number => {
      if (page.value === 1) return 1;
      return (page.value - 1) * itemsPerPage.value.value + 1;
    });
    const pageTo = computed((): number => {
      if (page.value * itemsPerPage.value.value >= props.histories.length)
        return props.histories.length;
      return page.value * itemsPerPage.value.value;
    });

    watch(() => props.histories, () => {
      page.value = 1;
    });

    watch(itemsPerPage, () => {
      page.value = 1;
    });

    const next = () => {
      if (page.value * itemsPerPage.value.value < props.histories.length)
        page.value++;
    };
    const previous = () => {
      if (page.value !== 1) page.value--;
    };
    const convertDateAndTimeSlashDelimiter = (date: string) => {
      return convertDateAndTimeSlashDelimiterUtil(date);
    };

    return {
      page,
      itemsPerPage,
      OPERATION: OPERATION,
      TARGET: TARGET,
      historiesSlicedByPage,
      pageFrom,
      pageTo,
      next,
      previous,
      convertDateAndTimeSlashDelimiter,
    };
  },
});
</script>

<style lang="scss" scoped>
.history-table {
  table {
    width: 100%;
    border-collapse: collapse;
    box-shadow: 0px 0px 3px #00000029;

    .header {
      background-color: #0e182e;
      color: #ffffff;
      height: 50px;
      font-size: 13px;

      th {
        padding: 0 18px;
        text-align: left;
      }
      th:first-child {
        width: 105px;
      }
      th:nth-child(2) {
        width: 130px;
        text-align: center;
      }
      th:nth-child(3) {
        min-width: 505px;
      }
      th:nth-child(4) {
        width: 236px;
        text-align: center;
      }
      th:nth-child(5) {
        width: 105px;
        text-align: center;
      }
      th:last-child {
        width: 336px;
      }
    }

    .body {
      background-color: #ffffff;
      td {
        padding: 14.5px 18px 17.5px 18px;
        border: 1px solid #eeeeee;
        font-size: 13px;
        color: #666666;
        text-align: left;

        .link {
          color: #4d99d0;
          text-decoration: none;
        }
      }
      td:nth-child(2) {
        text-align: center;
      }
      td:nth-child(4) {
        text-align: center;
      }
      td:nth-child(5) {
        text-align: center;
      }
    }
  }
  .table-footer {
    display: flex;
    align-items: center;
    margin-top: 21.5px;

    .caption {
      font-size: 13px;
      color: #222222;
    }
  }
}

.mb-8px {
  margin-bottom: 8px;
}

.ml-8px {
  margin-left: 8px;
}

.ml-11px {
  margin-left: 11px;
}

.ml-15px {
  margin-left: 15px;
}
</style>
