<template>
  <v-card height="'650px'" class="card">
    <v-card-title class="pa-0 card-title d-flex align-center">
      <span>曜日別シェア率</span>
      <AlertTooltip
        v-if="chartAlert"
        class="ml-2"
        text="取得データボリュームが少なく統計上の信頼性が低い日が含まれています。該当日は参考値としてご参照ください。"
      />
      <v-spacer />
      <ChartDescriptionTooltip
        menu-key="compare"
        sub-menu-key="trendVisitor"
        chart-key="dayOfWeekShare"
        class="mr-27px"
      />
      <DownloadButton
        label="CSVデータ"
        :disabled="props.selectedChainIds.length === 0"
        :get-file-id="
          () =>
            downloadVisitCountChartCSV(
              props.selectedChainIds,
              props.selectedPrefectureIds.map((id) => id.toString()),
              PARTICLE_TYPE.DAY,
              startDate,
              endDate
            )
        "
        :csv-name="`チェーン比較_曜日別シェア率_${convertSlashDelimiter(
          startDate
        )}-${convertSlashDelimiter(endDate)}`"
      />
    </v-card-title>
    <v-container class="pa-0" fluid>
      <v-row class="my-0" dense>
        <v-col class="py-0">
          <div v-show="props.selectedChainIds.length === 0" class="unselected_card">
            チェーンを選択するとチャートが表示されます。
          </div>
          <div v-show="props.selectedChainIds.length > 0">
            <LoadingImg v-if="chartLoading" :height="`${105 * props.selectedChainIds.length}px`" />
            <NoDataChart v-else-if="isNoData" />
            <ShareChart :header="chartHeader" :charts="chartData" />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script setup lang="ts">
import AlertTooltip from '@/commons/components/Elements/AlertTooltip.vue'
import ChartDescriptionTooltip from '@/commons/components/Elements/ChartDescriptionTooltip.vue'
import DownloadButton from '@/commons/components/Elements/DownloadButton.vue'
import LoadingImg from '@/commons/components/loadingImg.vue'
import { PARTICLE_TYPE, SUB_TYPE } from '@/commons/enums'
import { Chain } from '@/commons/interfaces'
import { getVisitCountGraph } from '@/features/ChainAnalytics/visitor/axios'
import { VisitCountGraph } from '@/features/ChainAnalytics/visitor/interfaces/response'
import NoDataChart from '@/features/ShopAnalytics/components/Common/noDataChart.vue'
import ShareChart from '@/features/StoreCompare/trendVisitor/components/ShareChart.vue'
import { ChartData } from '@/features/StoreCompare/trendVisitor/types'
import { processDayChart } from '@/features/StoreCompare/trendVisitor/utils'
import { ref, watch, computed } from 'vue'
import { useStore } from 'vuex'
import * as notify from '@/plugins/notification'
import { convertSlashDelimiter } from '@/commons/utils/dateUtil'
import { downloadVisitCountChartCSV } from '../axios'

/* --------------------------------------------------------------------------
  props
 ---------------------------------------------------------------------------*/

const props = withDefaults(
  defineProps<{
    selectedChainIds?: string[]
    selectedPrefectureIds?: number[]
  }>(),
  {
    selectedChainIds: (): string[] => [],
    selectedPrefectureIds: (): number[] => []
  }
)

/* --------------------------------------------------------------------------
  Vuex
 ---------------------------------------------------------------------------*/

const store = useStore()
const startDate = computed<string>(() => store.state.startDate)
const endDate = computed<string>(() => store.state.endDate)
const chainMap = computed<Map<string, Chain>>(() => store.getters.chainMap)
const chainIdToColorMap = computed<Map<string, string>>(() => store.state.chainIdToColorMap)

/* --------------------------------------------------------------------------
  core
 ---------------------------------------------------------------------------*/

const selectedChains = computed<(Chain & { color: string })[]>(() =>
  props.selectedChainIds.reduce(
    (acc: (Chain & { color: string })[], id: string): (Chain & { color: string })[] => {
      const chain = chainMap.value.get(id)
      if (!chain) return acc
      return [...acc, { ...chain, color: chainIdToColorMap.value.get(id) ?? '#AAA' }]
    },
    []
  )
)

const chartLoading = ref<boolean>(true)
const chartResponses = ref<
  Map<{ orderIndex: number; chainName: string; color: string }, VisitCountGraph>
>(new Map())
const chartData = computed<ChartData>(() =>
  processDayChart(
    Array.from(chartResponses.value).map(([{ orderIndex, chainName, color }, { visitCounts }]) => ({
      storeName: chainName, // HACK: リファクタでチェーン比較用の process 関数を作成する場合は chainName を受け取るよう修正する
      color,
      orderIndex,
      visitCounts
    }))
  )
)
const chartAlert = computed<boolean>(() => {
  if (chartResponses.value.size === 0) return false
  return Array.from(chartResponses.value.values()).some(({ visitCounts }) =>
    visitCounts.chartItems.some(({ isAlert }) => isAlert)
  )
})
const isNoData = computed<boolean>(() => {
  if (chartResponses.value.size === 0) return true
  return Array.from(chartResponses.value.values()).every(({ visitCounts }) =>
    visitCounts.chartItems.every(({ value }) => !value)
  )
})
const chartHeader = ['', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日', '日曜日']

const fetchChart = async () => {
  chartLoading.value = true

  chartResponses.value.clear()

  const fetchings = selectedChains.value.map((chain, index) =>
    getVisitCountGraph(
      chain.id,
      startDate.value,
      endDate.value,
      PARTICLE_TYPE.DAY,
      SUB_TYPE.NONE,
      props.selectedPrefectureIds
    ).then((res) => {
      chartResponses.value.set(
        {
          // NOTE: チェーンのランク順ではなく選択順とする。
          orderIndex: index,
          chainName: chain.name,
          color: chain.color
        },
        res.data
      )
    })
  )

  try {
    await Promise.all(fetchings)
  } catch {
    notify.notifyErrorMessage('曜日別シェア率チャートが表示できませんでした。')
  } finally {
    chartLoading.value = false
  }
}

/* --------------------------------------------------------------------------
  created
 ---------------------------------------------------------------------------*/

if (props.selectedChainIds.length > 0) {
  fetchChart()
}

/* --------------------------------------------------------------------------
  watch
 ---------------------------------------------------------------------------*/

watch(
  () => [props.selectedChainIds, props.selectedPrefectureIds],
  () => {
    if (props.selectedChainIds.length > 0 && props.selectedPrefectureIds.length > 0) {
      fetchChart()
    }
  },
  { deep: true }
)
</script>

<style lang="scss" scoped>
.mr-27px {
  margin-right: 27px;
}
</style>
