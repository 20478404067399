import { axiosClient } from '@/plugins/axios'
import { AxiosResponse } from 'axios'
import { convertHyphenDelimiter } from '@/commons/utils/dateUtil'
import { CombinationStoreResponse } from '@/features/ShopAnalytics/interfaces/response'
import qs from "qs";
import { DownloadResponse } from "@/commons/interfaces/responses/download";

/**
 * 店舗に対する併用店舗取得
 * @param {string} storeId 店舗ID
 * @param {string} start 期間開始日(yyyy-MM-dd)
 * @param {string} end 期間終了日(yyyy-MM-dd)
 */
export function getCombinationStores(storeId: string | undefined, start: string, end: string): Promise<AxiosResponse> {
  if (typeof storeId === "undefined") return Promise.reject();
  return axiosClient.get<CombinationStoreResponse>(
    '/stores/chart/combination-analysis/',
    {
      params: {
        store: storeId,
        start: convertHyphenDelimiter(start),
        end: convertHyphenDelimiter(end)
      }
    }
  )
}
export function downloadCombinationStores(
  storeId: string | undefined,
  start: string | undefined,
  end: string | undefined
): Promise<AxiosResponse> {
  if (!storeId || !start || !end) return Promise.reject();
  return axiosClient.get<DownloadResponse>(
    '/stores/chart/combination-analysis/download/',
    {
      params: {
        store: storeId,
        start: convertHyphenDelimiter(start),
        end: convertHyphenDelimiter(end)
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "repeat" });
      },
    }
  )
}
