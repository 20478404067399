import { notify } from "@kyvg/vue3-notification";

export function notifySuccessMessage(text: string) {
  notify({
    type: "success",
    text: text,
    duration: 3000,
    speed: 1000,
  });
}

export function notifyErrorMessage(text: string) {
  notify({
    type: "error",
    text: text,
    duration: 3000,
    speed: 1000,
  });
}

