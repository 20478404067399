<template>
  <v-container class="pa-0" fluid>
    <GChart type="LineChart" :data="chartData" :options="options" />
    <v-row class="my-0" dense>
      <v-col>
        <div class="my-0" style="display: flex; align-items: center; font-size: 12px; color: #666">
          <chart-legend :legends="legends" />
          <v-spacer />
          <!-- TODO: アラート時のみ表示するよう修正を加える -->
          <alert-caption variants="circle" />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { GChart } from 'vue-google-charts'
import chartLegend from '@/features/ShopAnalytics/components/Visitor/chartLegend.vue'

export default defineComponent({
  name: 'LineChart',
  components: {
    GChart,
    chartLegend
  },
  props: {
    chartData: {
      type: Array,
      default() {
        return []
      }
    },
    legends: {
      type: Array as PropType<{ text: string; color: string }[]>,
      default() {
        return []
      }
    },
    colors: {
      type: Array as PropType<string[]>,
      default() {
        return []
      }
    }
  },
  computed: {
    options() {
      let lineWidth = 3
      let pointSize = 6
      if (this.chartData.length > 31) {
        lineWidth = 2
        pointSize = 0
      }

      let colorArray = []
      colorArray = (this as any).colors

      return {
        chartArea: {
          top: 25,
          left: 80,
          right: 10,
          bottom: 90
        },
        colors: colorArray,
        hAxis: {
          textStyle: {
            fontSize: 12
          },
          slantedText: true,
          slantedTextAngle: 270
        },
        height: 550,
        isStacked: false,
        legend: {
          position: 'none'
        },
        lineWidth: lineWidth,
        pointSize: pointSize,
        tooltip: {
          isHtml: true
        },
        vAxis: {
          textStyle: {
            fontSize: 12
          },
          baselineColor: '#ccc',
          gridlines: {
            color: '#ddd'
          },
          minValue: 0
        }
      }
    }
  }
})
</script>

<style scoped>
::-webkit-scrollbar {
  -webkit-appearance: none;
  height: 7px;
  width: 7px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
</style>
