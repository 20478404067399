<template>
  <v-card height="'650px'">
    <v-card-title
      :class="
        $store.getters.selectedComparisonGroupVisibleStores.length === 0 ||
          visitPatternChartData.length <= 1
          ? 'pa-0 card-title d-flex align-center'
          : 'pa-0 mb-0 card-title d-flex align-center'
      "
    >
      <span>{{ title }}</span>
      <alert-tooltip
        v-if="hasAlert"
        class="ml-2"
        text="取得データボリュームが少なく統計上の信頼性が低い日が含まれています。該当日は参考値としてご参照ください。"
      />
      <v-spacer />
      <chart-description-tooltip
        menu-key="compare"
        sub-menu-key="visitEngagement"
        :chart-key="chartKey"
      />
      <download-button
        label="CSVデータ"
        :disabled="!$store.state.selectedComparisonGroup"
        :get-file-id="getFileId"
        :csv-name="csvName"
        class="download-button"
      />
    </v-card-title>
    <v-container
      class="pa-0"
      fluid
    >
      <v-row
        class="my-0"
        dense
      >
        <v-col class="py-0">
          <div
            v-show="
              $store.getters.selectedComparisonGroupVisibleStores
                .length === 0
            "
            class="unselected_card"
          >
            店舗を選択するとチャートが表示されます。
          </div>
          <div
            v-show="
              $store.getters.selectedComparisonGroupVisibleStores.length >
                0
            "
          >
            <loadingImg
              v-if="chartLoading"
              :height="'200px'"
            />
            <no-data-chart v-else-if="visitPatternChartData.length <= 1" />
            <div v-else>
              <GChart
                ref="centerBarChart"
                type="BarChart"
                :data="visitPatternChartData"
                :options="visitPatternChartOptions"
              />
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row
        v-if="
          $store.getters.selectedComparisonGroupVisibleStores.length > 0
        "
        class="my-2"
        dense
      >
        <v-col>
          <div class="my-0 chart-legend">
            <chart-legend :legends="legends" />
            <v-spacer />
            <alert-caption
              v-if="hasAlert"
              variants="square"
            />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { GChart } from "vue-google-charts";
import NoDataChart from "@/features/ShopAnalytics/components/Common/noDataChart.vue";
import { COLOR } from "@/commons/enums";
import LoadingImg from "@/commons/components/loadingImg.vue";
import chartLegend from "@/features/ShopAnalytics/components/Visitor/chartLegend.vue";
import { AxiosResponse } from "axios";

export default defineComponent({
  components: {
    LoadingImg,
    NoDataChart,
    GChart,
    chartLegend,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    // 店舗ごとにアラート判定をするため、アラート情報も含まれている
    visitPatternData: {
      type: Array as PropType<unknown[][]>,
      required: true,
    },
    chartLoading: {
      type: Boolean,
    },
    legends: {
      type: Array as PropType<{ text: string; color: string }[]>,
      default: () => [],
    },
    downloadLoading: {
      type: Boolean,
    },
    getFileId: {
      type: Function as PropType<() => Promise<AxiosResponse<any, any>>>,
      required: true,
    },
    chartKey: {
      type: String as PropType<"visitFrequency" | "engagement">,
      required: true,
    },
    csvName: {
      type: String,
      required: true,
    },
  },
  computed: {
    visitPatternChartData() {
      // 各店舗ごとの配列の最後にはアラートフラグが入っているので除く
      return this.visitPatternData.map((storeVisitPattern: unknown[]) => {
        return storeVisitPattern.slice(0, -1);
      });
    },
    hasAlert() {
      return this.visitPatternData
        .map(
          (storeVisitPattern: unknown[]) =>
            storeVisitPattern.slice(-1)[0] as boolean
        )
        .reduce((previous, current) => previous || current, false);
    },
    visitPatternChartOptions() {
      return {
        chartArea: {
          top: 30,
          left: 140,
          right: 20,
          bottom: 30,
        },
        colors: [
          COLOR.RED,
          COLOR.BLUE,
          COLOR.GREEN,
          COLOR.ORANGE,
          COLOR.PURPLE,
          COLOR.GRAY,
        ],
        hAxis: {
          textStyle: {
            fontSize: 13,
          },
          format: "###.#%",
        },
        legend: { position: "none" },
        isStacked: "percent",
        tooltip: {
          isHtml: true,
        },
        height:
          80 +
          39 * this.$store.getters.selectedComparisonGroupVisibleStores.length,
        bar: { groupWidth: "17" },
        vAxis: {
          textStyle: {
            fontSize: 12,
          },
        },
      };
    },
  },
});
</script>

<style lang="scss" scoped>
.v-btn--active {
  color: #fff !important;
  background: #0e182e !important;
}

/* TODO: 一箇所で統一する */
.square {
  display: inline-block;
  margin-right: 6px;
  width: 10px;
  height: 10px;
  border: 2px solid #666;
  background: #eeeeee;
}

.chart-legend {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #666;
}

.download-button {
  display: flex;
  align-items: center;
  margin-left: 29.4px;
}
</style>
