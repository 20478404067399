<template>
  <v-container
    class="pa-1 mb-1 mt-20px"
    fluid
  >
    <div class="grid-wrap">
      <div class="cols-5">
        <v-container
          class="pa-0 mb-2"
          style="text-align: center"
        >
          <p
            style="font-size: 12px"
            class="mb-0"
          >
            <span
              class="legend"
              style="background-color: #55a07c"
            />
            {{ chartData[0][1] }}
          </p>
          <p
            style="font-size: 12px"
            class="mb-0"
          >
            <span
              class="legend"
              style="background-color: #a9cfbd"
            />
            {{ chartData[0][4] }}
          </p>
        </v-container>
        <GChart
          type="BarChart"
          :data="leftChartData"
          :options="
            option({ height: height, position: 'left', max: maxValue + 5 })
          "
          :events="leftEvent"
          @ready="(chart, google) => {
            leftChart = chart;
            if (!leftChart) return;
            leftEvent = google.visualization.events as GoogleVizEvents;
            leftEvent.addListener(leftChart, 'select', () => leftSelect());
          }"
        />
      </div>
      <div class="cols-2">
        <v-container
          class="pa-0 mb-2"
          style="text-align: center"
        >
          <p
            style="font-size: 12px"
            class="mb-0"
          >
            <span
              class="legend"
              style="background-color: #4d99d0"
            />
            {{ chartData[0][7] }}
          </p>
          <p
            style="font-size: 12px"
            class="mb-0"
          >
            <span
              class="legend"
              style="background-color: #a5cce7"
            />
            {{ chartData[0][10] }}
          </p>
        </v-container>
        <GChart
          type="BarChart"
          :data="centerChartData"
          :options="
            option({
              height: height,
              position: 'center',
              max: maxValue + 5,
            })
          "
          :events="centerEvent"
          @ready="(chart, google) => {
            centerChart = chart;
            if (!centerChart) return;
            centerEvent = google.visualization.events as GoogleVizEvents;
            centerEvent.addListener(centerChart, 'select', () => centerSelect());
          }"
        />
      </div>
      <div class="cols-2">
        <v-container
          class="pa-0 mb-2"
          style="text-align: center"
        >
          <p
            style="font-size: 12px"
            class="mb-0"
          >
            <span
              class="legend"
              style="background-color: #de5a69"
            />
            {{ chartData[0][13] }}
          </p>
          <p
            style="font-size: 12px"
            class="mb-0"
          >
            <span
              class="legend"
              style="background-color: #eeacb3"
            />
            {{ chartData[0][16] }}
          </p>
        </v-container>
        <GChart
          type="BarChart"
          :data="rightChartData"
          :options="
            option({
              height: height,
              position: 'right',
              max: maxValue + 5,
            })
          "
          :events="rightEvent"
          @ready="(chart, google) => {
            rightChart = chart;
            if (!rightChart) return;
            rightEvent = google.visualization.events as GoogleVizEvents;
            rightEvent.addListener(rightChart, 'select', () => rightSelect());
          }"
        />
      </div>
    </div>
  </v-container>
</template>

<script setup lang="ts">
import { COLOR } from "@/commons/enums";
import { computed, ref } from "vue";
import { GChart } from "vue-google-charts";
import { GoogleChartOptions, GoogleChartWrapper, GoogleVizEvents } from "vue-google-charts/dist/types";

const props = withDefaults(
  defineProps<{
    chartData: (string | number)[][],
    height: number,
    min: number,
    max: number
  }>(), {
    chartData: () => [],
    height: 500,
    min: 0,
    max: 0
  }
)

const maxValue = computed<number>(() => {
  return Math.max(
    ...(props.chartData
      .flat()
      .filter(
        (value) => typeof value === "number" && !isNaN(value)
      ) as number[])
  );
})
const leftChartData = computed<(string | number)[][]>(() => {
  return props.chartData.map((data) => {
    return [data[0], data[1], data[2], data[3], data[4], data[5], data[6]].flat();
  });
})
const centerChartData = computed<(string | number)[][]>(() => {
  return props.chartData.map((data) => {
    return [data[0], data[7], data[8], data[9], data[10], data[11], data[12]].flat();
  });
})
const rightChartData = computed<(string | number)[][]>(() => {
  return props.chartData.map((data) => {
    return [data[0], data[13], data[14], data[15], data[16], data[17], data[18]].flat();
  });
})

const leftChart = ref<GoogleChartWrapper>()
const centerChart = ref<GoogleChartWrapper>()
const rightChart = ref<GoogleChartWrapper>()
const option = ref(
  (
    args: {
      height: number;
      position: "left" | "center" | "right";
      max: number;
    }
  ): GoogleChartOptions => {
    const { height, position } = args;
    const ticks = [...Array(20)]
      .map((_, i) => i * 5)
      .filter((n) => {
        if (props.min % 5 < 2.5)
          return props.min - 10 < n && n < props.max + 5;
        return props.min - 5 < n && n < props.max + 5;
      });
    return {
      chartArea: {
        top: 10,
        left: position === "left" ? 175 : 20,
        right: 20,
        bottom: 50,
        backgroundColor: {
          fill: (() => {
            if (position === "left") return "#fff";
            if (position === "center") return "#f5f8fd";
            if (position === "right") return "#fff6f7";
          })(),
        },
      },
      colors: (() => {
        if (position === "left") return [COLOR.TOTAL, COLOR.TOTAL2];
        if (position === "center") return [COLOR.BLUE, COLOR.BLUE2];
        if (position === "right") return [COLOR.RED, COLOR.RED2];
      })(),
      hAxis: {
        textStyle: {
          fontSize: 12,
        },
        viewWindow: {
          max: ticks[ticks.length - 1],
          min: ticks[0],
        },
        ticks: ticks,
      },
      height: height,
      legend: { position: "none" },
      tooltip: {
        isHtml: true,
      },
      vAxis: {
        textStyle: {
          fontSize: 10,
        },
        textPosition: args.position !== "left" ? "none" : "out",
      },
      bar: {
        groupWidth: '17',
      },
    };
})

const leftEvent = ref<GoogleVizEvents>()
const centerEvent = ref<GoogleVizEvents>()
const rightEvent = ref<GoogleVizEvents>()

const emit = defineEmits(['select-column'])
function leftSelect() {
  if (leftChart.value?.getSelection().length) {
    centerChart.value?.setSelection([
      leftChart.value?.getSelection()[0],
    ]);
    rightChart.value?.setSelection([
      leftChart.value?.getSelection()[0],
    ]);
    emit(
      'select-column',
      leftChart.value?.getSelection()[0]
    );
  } else {
    centerChart.value?.setSelection(null);
    rightChart.value?.setSelection(null);
    emit("select-column", null);
  }
}
function centerSelect() {
  if (centerChart.value?.getSelection().length) {
    leftChart.value?.setSelection([
      centerChart.value?.getSelection()[0],
    ]);
    rightChart.value?.setSelection([
      centerChart.value?.getSelection()[0],
    ]);
    emit(
      "select-column",
      centerChart.value?.getSelection()[0]
    );
  } else {
    leftChart.value?.setSelection(null);
    rightChart.value?.setSelection(null);
    emit("select-column", null);
  }
}
function rightSelect() {
  if (rightChart.value?.getSelection().length) {
    leftChart.value?.setSelection([
      rightChart.value?.getSelection()[0],
    ]);
    centerChart.value?.setSelection([
      rightChart.value?.getSelection()[0],
    ]);
    emit(
      "select-column",
      rightChart.value?.getSelection()[0]
    );
  } else {
    leftChart.value?.setSelection(null);
    centerChart.value?.setSelection(null);
    emit("select-column", null);
  }
}
</script>

<style lang="scss" scoped>
.grid-wrap {
  display: grid;
  grid-template-columns: repeat(9, minmax(0, 1fr));

  .cols-5 {
    grid-column: span 5 / span 5;
  }

  .cols-2 {
    grid-column: span 2 / span 2;
  }
}
.chart_ {
  width: 100%;
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  height: 7px;
  width: 7px;
}
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

.legend {
  display: inline-block;
  margin-right: 6px;
  width: 24px;
  height: 12px;
}
.mt-20px {
  margin-top: 20px !important;
}
</style>
