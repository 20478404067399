import { axiosClient } from "@/plugins/axios";
import { AxiosResponse } from "axios";
import { convertHyphenDelimiter } from "@/commons/utils/dateUtil";
import {
  AgeGenderResponse,
  BehavioralDnaResponse,
} from "@/features/ShopAnalytics/interfaces/response";
import { DownloadResponse } from "@/commons/interfaces/responses/download";

/**
 * 性別年代構成チャート取得
 * @param {string} storeId 店舗ID
 * @param {string} start 期間開始日(yyyy-MM-dd)
 * @param {string} end 期間終了日(yyyy-MM-dd)
 * @param {AbortSignal} signal APIの中断用オブジェクト
 */
export function getAgeGenderChart(
  storeId: string | undefined,
  start: string,
  end: string,
  signal?: AbortSignal
): Promise<AxiosResponse<AgeGenderResponse>> {
  if (typeof storeId === "undefined") return Promise.reject();
  return axiosClient.get<AgeGenderResponse>("/stores/chart/age-gender/", {
    params: {
      store: storeId,
      start: convertHyphenDelimiter(start),
      end: convertHyphenDelimiter(end),
    },
    signal: signal,
  });
}

/**
 * 性別年代構成チャートDL用URL生成
 * @param {string} storeId 店舗ID
 * @param {string} start 期間開始日(yyyy-MM-dd)
 * @param {string} end 期間終了日(yyyy-MM-dd)
 */
export function downloadAgeGenderChart(
  storeId: string | undefined,
  start: string,
  end: string
): Promise<AxiosResponse> {
  if (typeof storeId === "undefined") return Promise.reject();
  return axiosClient.get<DownloadResponse>(
    "/stores/chart/age-gender/download/",
    {
      params: {
        store: storeId,
        start: convertHyphenDelimiter(start),
        end: convertHyphenDelimiter(end),
      },
    }
  );
}

/**
 * 期間比較年代構成チャートDL用URL生成
 * @param {string} storeId 店舗ID
 * @param {Array.<Object>} period 期間
 * @param {string} period.start 期間開始日(yyyy-MM-dd)
 * @param {string} period.end 期間開始日(yyyy-MM-dd)
 */
export function downloadAgeGenderComparisonChart(
  storeId: string | undefined,
  period: {
    start: string;
    end: string;
  }[]
): Promise<AxiosResponse> {
  if (typeof storeId === "undefined") return Promise.reject();

  period.forEach((item: { start: string; end: string }) => {
    item.start = convertHyphenDelimiter(item.start);
    item.end = convertHyphenDelimiter(item.end);
  });
  return axiosClient.post<DownloadResponse>(
    "/stores/chart/age-gender-comparison/download/",
    {
      store: storeId,
      period: period,
    }
  );
}

/**
 * 行動DNAチャート取得
 * @param {string} storeId 店舗ID
 * @param {string} start 期間開始日(yyyy-MM-dd)
 * @param {string} end 期間終了日(yyyy-MM-dd)
 * @param {AbortSignal} signal APIの中断用オブジェクト
 */
export function getBehavioralDnaChart(
  storeId: string | undefined,
  start: string,
  end: string,
  signal?: AbortSignal
): Promise<AxiosResponse<BehavioralDnaResponse>> {
  if (typeof storeId === "undefined") return Promise.reject();
  return axiosClient.get<BehavioralDnaResponse>(
    "/stores/chart/behavioral-dna/",
    {
      params: {
        store: storeId,
        start: convertHyphenDelimiter(start),
        end: convertHyphenDelimiter(end),
      },
      signal: signal,
    }
  );
}

/**
 * 期間比較行動DNAチャート取得
 * @param {string} storeId 店舗ID
 * @param {Array.<Object>} period 期間
 * @param {string} period.start 期間開始日(yyyy-MM-dd)
 * @param {string} period.end 期間開始日(yyyy-MM-dd)
 */
export function getBehavioralDnaComparisonChart(
  storeId: string | undefined,
  period: {
    start: string;
    end: string;
  }[]
): Promise<AxiosResponse> {
  if (typeof storeId === "undefined") return Promise.reject();

  period.forEach((item: { start: string; end: string }) => {
    item.start = convertHyphenDelimiter(item.start);
    item.end = convertHyphenDelimiter(item.end);
  });
  return axiosClient.post<BehavioralDnaResponse>(
    "/stores/chart/behavioral-dna-comparison/",
    {
      store: storeId,
      period: period,
    }
  );
}

/**
 * 行動DNAチャートDL用URL生成
 * @param {string} storeId 店舗ID
 * @param {string} start 期間開始日(yyyy-MM-dd)
 * @param {string} end 期間終了日(yyyy-MM-dd)
 * @param {number} level 取得する行動DNAの粒度
 */
export function downloadBehavioralDnaChart(
  storeId: string | undefined,
  start: string,
  end: string,
  level: number
): Promise<AxiosResponse> {
  if (typeof storeId === "undefined") return Promise.reject();
  return axiosClient.get<DownloadResponse>(
    "/stores/chart/behavioral-dna/download/",
    {
      params: {
        store: storeId,
        start: convertHyphenDelimiter(start),
        end: convertHyphenDelimiter(end),
        level: level,
      },
    }
  );
}

/**
 * 期間比較行動DNAチャートDL用URL生成
 * @param {string} storeId 店舗ID
 * @param {Array.<Object>} period 期間
 * @param {string} period.start 期間開始日(yyyy-MM-dd)
 * @param {string} period.end 期間開始日(yyyy-MM-dd)
 * @param {number} level 取得する行動DNAの粒度
 */
export function downloadBehavioralDnaComparisonChart(
  storeId: string | undefined,
  period: {
    start: string;
    end: string;
  }[],
  level: number
): Promise<AxiosResponse> {
  if (typeof storeId === "undefined") return Promise.reject();

  period.forEach((item: { start: string; end: string }) => {
    item.start = convertHyphenDelimiter(item.start);
    item.end = convertHyphenDelimiter(item.end);
  });
  return axiosClient.post<DownloadResponse>(
    "/stores/chart/behavioral-dna-comparison/download/",
    {
      store: storeId,
      period: period,
      level: level,
    }
  );
}
